import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import FileMappingDefineComponent from './file.mapping.define.component.js'

import FileMappingCreateComponent from './file.mapping.create.component'
import { mappingModel } from '../../Bean/mapping.js';



const FileMappingHomeComponent = ({ dispatchGetMappingDetails }) => {
    const [updationStatus, setUpdationStatus] = useState(false)
    const [cursor, setCursor] = useState('')
    const [sourceType, setSourceType] = useState(0);
    const [destinationType, setDestinationType] = useState(0);
    const [lineBreakType, setLineBreakType] = useState("LF");
    const [mappingName,setMappingName]=useState("")
    const [mappingNotes,setMappingNotes]=useState("")
    const [mappingConfig,setMappingConfig]=useState({})

    switch (cursor) {
        case "define":
            return <FileMappingDefineComponent mappingConfig={mappingConfig} setMappingConfig={setMappingConfig} updationStatus={updationStatus} setUpdationStatus={setUpdationStatus} cursor={cursor} setCursor={setCursor} setSourceType={setSourceType} setDestinationType={setDestinationType} setLineBreakType={setLineBreakType}/>
        case "create":
            return <FileMappingCreateComponent mappingConfig={mappingConfig} setMappingConfig={setMappingConfig} mappingConfigsetUpdationStatus={setUpdationStatus} lineBreakType={lineBreakType} cursor={cursor} setCursor={setCursor} sourceType={sourceType} destinationType={destinationType} setSourceType={setSourceType} setDestinationType={setDestinationType} updationStatus={updationStatus} setMappingNotes={setMappingNotes} mappingNotes={mappingNotes} setMappingName={setMappingName} mappingName={mappingName}/>
        case "edit":
            return <FileMappingCreateComponent mappingConfig={mappingConfig} setMappingConfig={setMappingConfig} mappingConfigsetUpdationStatus={setUpdationStatus} lineBreakType={lineBreakType} cursor={cursor} setCursor={setCursor} sourceType={sourceType} destinationType={destinationType} updationStatus={updationStatus} setSourceType={setSourceType} setDestinationType={setDestinationType} setMappingNotes={setMappingNotes} mappingNotes={mappingNotes} setMappingName={setMappingName} mappingName={mappingName}/>
        case "copy":
            return <FileMappingCreateComponent mappingConfig={mappingConfig} setMappingConfig={setMappingConfig} mappingConfigsetUpdationStatus={setUpdationStatus} lineBreakType={lineBreakType} cursor={cursor} setCursor={setCursor} sourceType={sourceType} destinationType={destinationType} updationStatus={updationStatus} setSourceType={setSourceType} setDestinationType={setDestinationType} setMappingNotes={setMappingNotes} mappingNotes={mappingNotes} setMappingName={setMappingName} mappingName={mappingName}/>
        default:
            return <FileMappingDefineComponent mappingConfig={mappingConfig} setMappingConfig={setMappingConfig} mappingConfigupdationStatus={updationStatus} setUpdationStatus={setUpdationStatus} cursor={cursor} setCursor={setCursor} setSourceType={setSourceType} setDestinationType={setDestinationType} setLineBreakType={setLineBreakType}/>
    }

};

const mapDispatchToProps = dispatch => ({
    dispatchGetMappingDetails: (partnerId, onSuccess, onError) =>
        dispatch(fetchPartnerDetailsById(partnerId, onSuccess, onError))
})

export default connect(null, mapDispatchToProps)(FileMappingHomeComponent)