import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import * as constants from '../redux/constants';

import { setPassword } from '../redux/actions/auth.action.creators';
import AlertFailure from '../utils/alert-failure.component';
import AlertSuccess from '../utils/alert-success.component';
const appConf = require(process.env.REACT_APP_CONFIG_URL);
const logoName = appConf.constants.logoName;
const companyLogo  = require(`../assets/images/${logoName}`).default
var CryptoJS = require("crypto-js");


const SetPasswordForm = ({ dispatchSetPasswordAction }) => {

    const [request, setRequest] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [failMsg, setFailMsg] = useState('');
    const [succMsg, setSuccMsg] = useState('');
    const [errorConst, setErrorConst] = useState({});

    useEffect(() => {
        let token = new URLSearchParams(window.location.search).get('token');
        let decryptedToken = "";
        if (token) {
            try {
                const bytes = CryptoJS.AES.decrypt(token, CryptoJS.enc.Utf8.parse(appConf.switch.aesKey), {
                    mode: CryptoJS.mode.ECB,
                    padding: CryptoJS.pad.Pkcs7
                });
                decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
            } catch (e) {
                console.log(e);
                setTimeout(() => {
                    setFailMsg('Invalid Token!')
                    window.location = '/auth';
                }, 1000);
            };
        }
        setRequest(decryptedToken);

      }, [])

    const onSave = (event) => {
        event.preventDefault();
        if (isFormValid()){
            request.password = password;
            const encrypted = CryptoJS.AES.encrypt(JSON.stringify(request), CryptoJS.enc.Utf8.parse(appConf.switch.aesKey), {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
              }).toString();

            dispatchSetPasswordAction({'token': encrypted}, ({data, message}) => {
                if(data){
                    console.log(data)
                    setSuccMsg(data);
                    setPassword('');
                    setConfPassword('');
                    setTimeout(() => {
                        setSuccMsg('')
                        window.location = '/auth';
                    }, 3000);
                }
            },
            (message) => {
                setFailMsg(message);
                setTimeout(() => {
                    setFailMsg('')
                }, 3000);
            });
        }            
    };

    const isFormValid = () => {
        const tempError = _.cloneDeep(errorConst);        
        tempError.pwd = !password ? "Enter new password" : (!(new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(password))? "Invalid password pattern" : '');
        tempError.pwdMismatch = !confPassword ? "Re-enter new password" : ((password != confPassword) ? "Please ensure passwords match" : '');
        setErrorConst(tempError);

        return (!tempError.pwd && !tempError.pwdMismatch);
    };


    return (
        <React.Fragment>
            <a className="sr-only sr-only-focusable" href="#content">Skip to main content</a>
            <div className="login">
                <div className="login__overlay">
                    <div className="login__block">
                    <div className="login__desc">
                    <h1><div>{appConf.constants.loginPage.loginTitle} <strong>{appConf.constants.loginPage.loginTitle2}</strong></div><span>File Exchange Portal</span></h1>
                            <br />
                            <p>
                            {appConf.constants.loginPage.loginDisclaimer}
                            </p>
                        </div>
                        <div className="login__content">
                            <div className="logo-login">
                                <img src={companyLogo} alt="Company Logo" />
                            </div>
                            {failMsg ? 
                            <AlertFailure message={failMsg}/> : ''}
                            {succMsg ? 
                            <AlertSuccess message={succMsg}/> : ''}
                            <form className="login__form w-100" noValidate onSubmit={(e) => onSave(e)}>
                            <div className="box box--password">
                                <div className="input-wrap password-wrap">
                                    <input autoComplete="off" type="password" id="password1"
                                    value={password} onChange={(e) => setPassword(e.target.value)} 
                                    className={`txt ${errorConst.pwd ? 'is-invalid' : ''}`} required />
                                    <label className="input-label" htmlFor="password1">Password</label>
                                    {errorConst.pwd ?
                                    (<div className="error-message">{errorConst.pwd}</div>)
                                    : ('')} 
                                    <div className="policy">
                                        <ul>
                                            <li>Minimum password length: 8 </li>  
                                            <li> Maximum password length: 20</li>  
                                            <li>The password should contain atleast 1 number</li>  
                                            <li> The password should contain atleast 1 capital letter</li>  
                                            <li> The password should contain atleast 1 small letter</li>  
                                            <li> The password should contain atleast 1 special character</li>  
                                        </ul>
                                    </div>
                                </div>
                                <div className="input-wrap password-wrap">
                                    <input autoComplete="off" type="password" id="repassword1"
                                     value={confPassword} onChange={(e) => setConfPassword(e.target.value)} 
                                     className={`txt ${errorConst.pwdMismatch ? 'is-invalid' : ''}`}required />
                                    <label className="input-label" htmlFor="repassword1">Re-enter Password</label> 
                                    {errorConst.pwdMismatch ?
                                    (<div className="error-message">{errorConst.pwdMismatch}</div>)
                                    : ('')}
                                </div>
                                <div className="btn-wrap w-100 mt-3">
                                    <button type="submit" className="button">Save password</button>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapDispatchToProps = dispatch => ({
    dispatchSetPasswordAction: (headers, onSuccess, onError) =>
        dispatch(setPassword(headers, onSuccess, onError))
});

export default connect(null, mapDispatchToProps)(SetPasswordForm);