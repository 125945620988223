import React, { useEffect, useState } from 'react';
import { PartnerProtocolModel } from '../../Bean/partner';
import { connect } from 'react-redux';
import { UploadPublicKey, getPublicKeyList, downloadPublicKey } from "../../redux/actions/auth.action.creators";
import CustomDropdownWidget from '../dropdown/CustomDropdownWidget'

const PartnerOnboardingProtocolComponent = (props, { dispatchDownloadPublicKey, dispatchFileUploadAction, dispatchSpinnerAction, dispatchPublicKeyList }) => {

  const [publicKey, setPublickKey] = useState([{}])
  const [inputErrorWarning, setInputErrorWarning] = useState('')
  const [constrains, setConstrains] = useState({ "fieldPlaceholder": "Choose Public Key Ring", "isMandatory": true, "fieldLabel": "Router Public Key Ring", "inputErrorMessage": "" })
  const [responseData, setResponseData] = useState({})
  const [definedPublicRing, setDefinedPublicRing] = useState({});
  const [publicKeynameToDownload, setPublicKeynameToDownload] = useState('')

  useEffect(() => {
    console.log(props.inputDataForProtocol.routerPublicKeyringDropdown);
    setValuesToVariable("routerPublicKeyringDropdown", responseData.value ? responseData.value : "")
    setPublicKeynameToDownload(responseData.label ? responseData.label : "")
  }, [responseData]) 
  

  useEffect(() => {
    // debugger;
    if (!props.inputDataForProtocol.partnerSendFilesAsEncrypted) {
      console.log("ggggg");
      setValuesToVariable("routerPublicKeyringDropdown", "");
      setResponseData({})
    }
    if (!props.inputDataForProtocol.partnerRequireFilesToBeEncrypted) setValuesToVariable("partnerPublicKeyringName", "")
  }, [props.inputDataForProtocol.partnerSendFilesAsEncrypted, props.inputDataForProtocol.partnerRequireFilesToBeEncrypted])

  const setValuesToVariable = (category, keyword) => {
    props.setInputDataForProtocol({
      "partnerSendFilesAsEncrypted": category === "partnerSendFilesAsEncrypted" ? keyword : props.inputDataForProtocol.partnerSendFilesAsEncrypted,
      "routerPublicKeyringDropdown": category === "routerPublicKeyringDropdown" ? keyword : props.inputDataForProtocol.routerPublicKeyringDropdown,
      "verifyEncryptedFile": category === "verifyEncryptedFile" ? keyword : props.inputDataForProtocol.verifyEncryptedFile,

      "partnerRequireFilesToBeEncrypted": category === "partnerRequireFilesToBeEncrypted" ? keyword : props.inputDataForProtocol.partnerRequireFilesToBeEncrypted,
      "partnerProtocolUserId": category === "partnerProtocolUserId" ? keyword : props.inputDataForProtocol.partnerProtocolUserId,
      "partnerProtocolEmail": category === "partnerProtocolEmail" ? keyword : props.inputDataForProtocol.partnerProtocolEmail,
      "partnerPublicKeyringName": category === "partnerPublicKeyringName" ? keyword : props.inputDataForProtocol.partnerPublicKeyringName,
      "signFile": category === "signFile" ? keyword : props.inputDataForProtocol.signFile,

      "sendPublicFileAsEmail": false//category === "sendPublicFileAsEmail" ? keyword : props.inputDataForProtocol.sendPublicFileAsEmail

    })
  }

  const emailValidation = () => {
    if (props.inputDataForProtocol.partnerProtocolEmail !== null && props.inputDataForProtocol.partnerProtocolEmail !== "" && new RegExp(/^(\d{9}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4}))$/).test(props.inputDataForProtocol.partnerProtocolEmail)) {
      return true;
    } return false
  }


  const inputFieldValidation = () => {
    setInputErrorWarning({
      "partnerPublicKeyringName": props.inputDataForProtocol.partnerRequireFilesToBeEncrypted && (props.inputDataForProtocol.partnerPublicKeyringName === null || props.inputDataForProtocol.partnerPublicKeyringName === "") ? "Upload Public Key Ring" : "",
      // "partnerProtocolUserId": props.inputDataForProtocol.partnerRequireFilesToBeEncrypted && (props.inputDataForProtocol.partnerProtocolUserId === null || props.inputDataForProtocol.partnerProtocolUserId === "") ? "Enter User Id" : null,
      // "partnerProtocolEmail": props.inputDataForProtocol.partnerRequireFilesToBeEncrypted ? (props.inputDataForProtocol.partnerProtocolEmail === null || props.inputDataForProtocol.partnerProtocolEmail === "" ? null : emailValidation() ? null : "Enter Valid Email") : null,

    })
    // console.log( props.inputDataForProtocol.partnerProtocolEmail);
    console.log(props.inputDataForProtocol);
    console.log(inputErrorWarning);
    if (props.inputDataForProtocol.partnerSendFilesAsEncrypted && (props.inputDataForProtocol.routerPublicKeyringDropdown === null || props.inputDataForProtocol.routerPublicKeyringDropdown === "")) {
      setConstrains({ "fieldPlaceholder": "Choose Public Key Ring", "isMandatory": true, "fieldLabel": "Router Public Key Ring", "inputErrorMessage": "Choose any" })
    } else {
      setConstrains({ "fieldPlaceholder": "Choose Public Key Ring", "isMandatory": true, "fieldLabel": "Router Public Key Ring", "inputErrorMessage": "" })
    }

    if (!props.inputDataForProtocol.partnerSendFilesAsEncrypted && !props.inputDataForProtocol.partnerRequireFilesToBeEncrypted) {
      return true
    }
    else if ((props.inputDataForProtocol.partnerSendFilesAsEncrypted && (props.inputDataForProtocol.routerPublicKeyringDropdown !== null && props.inputDataForProtocol.routerPublicKeyringDropdown !== "")) && (!props.inputDataForProtocol.partnerRequireFilesToBeEncrypted)) {
      return true
    }
    else if ((!props.inputDataForProtocol.partnerSendFilesAsEncrypted) && (props.inputDataForProtocol.partnerRequireFilesToBeEncrypted && (props.inputDataForProtocol.partnerPublicKeyringName !== null && props.inputDataForProtocol.partnerPublicKeyringName !== ""))) {
      if ((props.inputDataForProtocol.partnerProtocolUserId !== null && props.inputDataForProtocol.partnerProtocolUserId !== "") || (props.inputDataForProtocol.partnerProtocolEmail !== null && props.inputDataForProtocol.partnerProtocolEmail !== "" )) {
        return true
      }
    } else if ((props.inputDataForProtocol.partnerRequireFilesToBeEncrypted && (props.inputDataForProtocol.partnerPublicKeyringName !== null && props.inputDataForProtocol.partnerPublicKeyringName !== "")) && (props.inputDataForProtocol.partnerSendFilesAsEncrypted && (props.inputDataForProtocol.routerPublicKeyringDropdown !== null && props.inputDataForProtocol.routerPublicKeyringDropdown !== ""))) {
      if ((props.inputDataForProtocol.partnerProtocolUserId !== null && props.inputDataForProtocol.partnerProtocolUserId !== "") || (props.inputDataForProtocol.partnerProtocolEmail !== null && props.inputDataForProtocol.partnerProtocolEmail !== "" )) {
        return true
      }
    }
    else {
      return false
    }

  }
    // Declare state publicKeynameToDownload (copy from above)
    //  Copy this  downloadPublicKey function to Dashboard
    // create a button, on click call the downloadPublicKey

    // find the value of the public key assigned to the partner on page load
        // call public Key list API on page load 
        // Get hubKey_id in partner API -> edit the repository of list_partner API to include hubkey_id
      
        // filter listkeys to get the keyname corresponding to the hubKey_id from partner API
        //  set the publicKeynameToDownload as this keyname

  // click the donload button to test

  const downloadPublicKey = () => {
    if (publicKeynameToDownload !== null && publicKeynameToDownload !== "") {
      var tempData = {
        "fileName": "filehandler/application/pgpkeys/hubkeys/" + publicKeynameToDownload,
        "restorationDays": ""
      }

      props.dispatchDownloadPublicKey(tempData, ({ data, message }) => {
        if (data["fileDownloadLink"]) {
          const link = document.createElement('a');
          link.href = data["fileDownloadLink"];
          link.setAttribute(
              'download',
              `${data['fileName']}`,
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
      }
      }, (message) => {
        console.log(message);
      });
    }
  }

  useEffect(() => {
    props.dispatchPublicKeyList(({ data, message, status }) => {
      if (data) {
        let publicList = []
        data.map((item, i) => { publicList.push(item) })
        setPublickKey(publicList)
        data.map((d)=> { 
          if(props.inputDataForProtocol.routerPublicKeyringDropdown != "" &&props.inputDataForProtocol.routerPublicKeyringDropdown == d.value){ 
            setDefinedPublicRing({"value":d.value,"label":d.label})
            setResponseData({"value":d.value,"label":d.label})
          }
        });   
      }
    })
  }, [])

  const onSubmit = (event) => {
    event.preventDefault();
    if (inputFieldValidation()) {
      console.log(props.inputDataForProtocol);
      props.setCursor('Partner_Summary')
    }
  }

  const onReset = () => {
    props.setInputDataForProtocol(PartnerProtocolModel)
    setResponseData({})
  }


  return (
    <React.Fragment>
      <main>
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb__item">
              <a className="breadcrumb__link" href="#">Manage Partner</a>
            </li>
            <li className="breadcrumb__item">
              <span className="breadcrumb__link">Partner</span>
            </li>
          </ul>
          <div className="title">
            <h1>Partner Onboarding</h1>
          </div>
          <div className="tab tab--partner">
            <ul className="tab--partner__list">
              <li className="tab--partner__item is-completed">
                <div className="bar"></div>
                <div className="content">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill=""><rect fill="none" height="24" width="24" /><g><path d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,2.73L18,18H6l0-1.61c0-1.18,0.68-2.26,1.76-2.73 C8.93,13.14,10.37,12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1 C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85 C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M12,6c1.66,0,3,1.34,3,3 c0,1.66-1.34,3-3,3s-3-1.34-3-3C9,7.34,10.34,6,12,6z" /></g></svg>
                  </div>
                  <div className="text">Partner Profile</div>
                </div>
              </li>
              <li className="tab--partner__item is-completed">
                <div className="bar"></div>
                <div className="content">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill=""><rect fill="none" height="24" width="24" /><path d="M12,3L2,21h20L12,3z M13,8.92L18.6,19H13V8.92z M11,8.92V19H5.4L11,8.92z" /></svg>
                  </div>
                  <div className="text">User Details</div>
                </div>
              </li>
              <li className="tab--partner__item is-completed">
                <div className="bar"></div>
                <div className="content">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill=""><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><g><circle cx="10" cy="8" r="4" /><path d="M10.67,13.02C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26 C10.47,18.87,10,17.49,10,16C10,14.93,10.25,13.93,10.67,13.02z" /><path d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z" /></g></g></svg>
                  </div>
                  <div className="text">Partner Role</div>
                </div>
              </li>
              <li className="tab--partner__item is-active">
                <div className="bar"></div>
                <div className="content">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill=""><path d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="19" r="2" /><path d="M10 3h4v12h-4z" /></svg>
                  </div>
                  <div className="text">Protocol</div>
                </div>
              </li>
            </ul>
          </div>
          <h2 className="mb-3">Protocol</h2>
          <form onSubmit={onSubmit} >
            <div className="questionnaire mb-5">
              <div className="questionnaire__blcok" >
                <div className="question">
                  <div className="question-text">Does partner send files as encrypted? </div>
                  <div className="chcek-wrap">
                    <div className="form-check">
                      <input type="checkbox" id="yes1" checked={props.inputDataForProtocol.partnerSendFilesAsEncrypted ? true : false} onChange={(() => { setValuesToVariable("partnerSendFilesAsEncrypted", true) })} />
                      <label for="yes1">Yes</label>
                    </div>
                    <div className="form-check">
                      <input type="checkbox" id="no1" checked={props.inputDataForProtocol.partnerSendFilesAsEncrypted ? false : true} onChange={(() => { setValuesToVariable("partnerSendFilesAsEncrypted", false) })} />
                      <label for="no1">No</label>
                    </div>
                  </div>
                </div>

                {props.inputDataForProtocol.partnerSendFilesAsEncrypted ?
                  <div>
                    <br></br>
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <div className="input-wrap txt-wrap">
                            <CustomDropdownWidget dropdownList={publicKey} constrains={constrains} editValue={definedPublicRing} setResponseData={setResponseData} ></CustomDropdownWidget>
                          </div>
                        </div>
                      </div>
                      {true ?
                        <div className="question-text col-lg-3">
                          <a href='#' className='link' onClick={(e) => downloadPublicKey()}>Download Public Key</a>
                        </div>
                        :
                        ""
                      }
                      {/* <div className="chcek-wrap">
                        <div className="form-check">
                          <input type="checkbox" id="ifDownload" checked={props.inputDataForProtocol.sendPublicFileAsEmail ? true : false} onChange={(() => { setValuesToVariable("sendPublicFileAsEmail", props.inputDataForProtocol.sendPublicFileAsEmail ? false : true) })} />
                          <label for="ifDownload">Send As Email </label>
                        </div>
                      </div> */}
                    </div>

                    <br></br>
                    <div className="question">
                      <div className="question-text"> Verify encrypted file?</div>
                      <div className="chcek-wrap">
                        <div className="form-check">
                          <input type="checkbox" id="yes2" checked={props.inputDataForProtocol.verifyEncryptedFile ? true : false} onChange={(() => { setValuesToVariable("verifyEncryptedFile", true) })} />
                          <label for="yes2">Yes</label>
                        </div>
                        <div className="form-check">
                          <input type="checkbox" id="no2" checked={props.inputDataForProtocol.verifyEncryptedFile ? false : true} onChange={(() => { setValuesToVariable("verifyEncryptedFile", false) })} />
                          <label for="no2">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  ''}

              </div>

            </div>
            <div className="questionnaire mb-5">
              <div className="questionnaire__blcok"   >
                <div className="question">
                  <div className="question-text">Does partner require files to be encrypted and send? </div>
                  <div className="chcek-wrap">
                    <div className="form-check">
                      <input type="checkbox" id="yes3" checked={props.inputDataForProtocol.partnerRequireFilesToBeEncrypted ? true : false} onChange={(() => { setValuesToVariable("partnerRequireFilesToBeEncrypted", true) })} />
                      <label for="yes3">Yes</label>
                    </div>
                    <div className="form-check">
                      <input type="checkbox" id="no3" checked={props.inputDataForProtocol.partnerRequireFilesToBeEncrypted ? false : true} onChange={(() => { setValuesToVariable("partnerRequireFilesToBeEncrypted", false) })} />
                      <label for="no3">No</label>
                    </div>
                  </div>
                </div>

                {props.inputDataForProtocol.partnerRequireFilesToBeEncrypted ?

                  <div>
                    <br></br>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label for="userid_protocol" className="input-label">Enter User Id For Encryption/Decryption<span style={{ color: 'red' }}>*</span></label>
                          <div className="input-wrap txt-wrap">
                            <input className="txt"/*{`txt ${inputErrorWarning.partnerProtocolUserId ? 'is-invalid' : ''}`}*/ type="text" autoComplete="off" value={props.inputDataForProtocol.partnerProtocolUserId} onChange={((e) => { setValuesToVariable("partnerProtocolUserId", e.target.value) })} placeholder="Enter User Id" id="userid_protocol" />
                            {/* {inputErrorWarning.partnerProtocolUserId ?
                              (<div className="error-message">{inputErrorWarning.partnerProtocolUserId}</div>)
                              : ('')} */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label for="email_protocol" className="input-label">Enter Email Id For Encryption/Decryption</label>
                          <div className="input-wrap txt-wrap">
                            <input className="txt"/*{`txt ${inputErrorWarning.partnerProtocolEmail ? 'is-invalid' : ''}`}*/ type="text" autoComplete="off" value={props.inputDataForProtocol.partnerProtocolEmail} onChange={((e) => { setValuesToVariable("partnerProtocolEmail", e.target.value) })} placeholder="Enter Email" id="email_protocol" />
                            {/* {inputErrorWarning.partnerProtocolEmail ?
                              (<div className="error-message">{inputErrorWarning.partnerProtocolEmail}</div>)
                              : ('')} */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label for="f2" className="d-block input-label">Partner PGP Public Key Ring<span style={{ color: 'red' }}>*</span></label>
                          <div className="input-wrap upload-wrap">
                            <input type="text" id="uploadFile" autoComplete="off" className="txt" value={props.inputDataForProtocol.partnerPublicKeyringName ? props.inputDataForProtocol.partnerPublicKeyringName.name ? props.inputDataForProtocol.partnerPublicKeyringName.name : props.inputDataForProtocol.partnerPublicKeyringName : ''} />
                            {!props.inputDataForProtocol.partnerPublicKeyringName ?
                              <div className="fileupload-custom">
                                <span> Upload Key</span>
                                <input id="uploadBtn" type="file" className="upload-input"
                                  onChange={(e) => { setValuesToVariable("partnerPublicKeyringName", e.target.files[0]) }} />
                              </div>
                              :
                              <button class="button button--delete" onClick={((e) => { setValuesToVariable("partnerPublicKeyringName", "") })}>Remove</button>
                            }



                          </div>
                          {inputErrorWarning.partnerPublicKeyringName ?
                            (<div className="error-message">{inputErrorWarning.partnerPublicKeyringName}</div>)
                            : ('')}
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <div className="question">
                      <div className="question-text"> Sign file?</div>
                      <div className="chcek-wrap">
                        <div className="form-check">
                          <input type="checkbox" id="yes4" checked={props.inputDataForProtocol.signFile ? true : false} onChange={(() => setValuesToVariable("signFile", true))} />
                          <label for="yes4">Yes</label>
                        </div>
                        <div className="form-check">
                          <input type="checkbox" id="no4" checked={props.inputDataForProtocol.signFile ? false : true} onChange={(() => setValuesToVariable("signFile", false))} />
                          <label for="no4">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  ''}
              </div>
            </div>





            <div className="btn-wrap btn-wrap--grp mt-5 justify-content-end">
              <button className="button button--outline" type='reset' onClick={(() => { onReset() })}>Reset</button>
              <button className="button button--outline" onClick={(() => { props.setCursor('Partner_Role') })} >Previous</button>
              <button className="button" type='submit'>Next</button>
            </div>
          </form>
        </div>
      </main>



    </React.Fragment>


  );

}

const mapDispatchToProps = dispatch => ({
  dispatchFileUploadAction: (data, uploadProgress, onSuccess, onError) =>
    dispatch(UploadPublicKey(data, uploadProgress, onSuccess, onError)),

  dispatchDownloadPublicKey: (tempData, onSuccess, onError) =>
    dispatch(downloadPublicKey(tempData, onSuccess, onError)),

  dispatchPublicKeyList: (onSuccess, onError) =>
    dispatch(getPublicKeyList(onSuccess, onError)),
  dispatchSpinnerAction: () =>
    dispatch({ type: constants.TOGGLE_LOADER })
});

export default connect(null, mapDispatchToProps)(PartnerOnboardingProtocolComponent);

