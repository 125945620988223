import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { statisticsForSR } from '../redux/actions/auth.action.creators'



const StatisticsComponent = ({ dispatchStatisticsForSR }) => {

    const [feildData, setFeildData] = useState([{ "username": "--", "totalemails": 0 }])
    const [reloader, setReloader] = useState(true)


    useEffect(() => {
        dispatchStatisticsForSR(({ data, message }) => {
            if (data) {
                setFeildData(data);
            }
        }, (message) => {
        });
    }, [reloader])

    return (
        <React.Fragment>
            <main>
                <div className="container col-md-6">
                    <div className="table-block">
                        <div className="table-block__head">
                            <h2>SR Manager Email Count</h2>
                            <div className="txt-wrap">
                                <button type="submit" onClick={() => setReloader(reloader ? false : true)} className="button">Reload</button>
                            </div>
                        </div>
                        <br></br><br></br>
                        <div className="grid"  >
                            <table className="table"  >
                                <thead>
                                    <tr>
                                        <th style={{zIndex:0}} scope="col">Username</th>
                                        <th style={{zIndex:0}} scope="col">Email Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {feildData.map(data => (
                                        <tr>
                                            <td>{data.username}</td>
                                            <td>{data.totalemails}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment >
    );
};


const mapDispatchToProps = dispatch => ({
    dispatchStatisticsForSR: (onSuccess, onError) =>
        dispatch(statisticsForSR(onSuccess, onError))
});

export default connect(null, mapDispatchToProps)(StatisticsComponent);