import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { displayMappingData } from '../../redux/actions/auth.action.creators'
import FileMappingPopupFlatComponent from './PopUps/file.mapping.popup.flat.component';
import { confirmDialog } from 'primereact/confirmdialog';
import Xarrow from 'react-xarrows';
import FileMappingPopupCsvComponent from './PopUps/file.mapping.popup.csv.component';
import FileMappingSidebarComponent from './file.mapping.sidebar.component';
import FileMappingFunctionRecordcountComponent from './MappingFunctions/file.mapping.function.recordcount.component';
import FileMappingFunctionZerosComponent from './MappingFunctions/file.mapping.function.zeros.component';
import FileMappingFunctionDatetimenowComponent from './MappingFunctions/file.mapping.function.datetimenow.component';
import FileMappingFunctionTrimComponent from './MappingFunctions/file.mapping.function.trim.component';
import AlertFailure from '../../utils/alert-failure.component';
import AlertSuccess from '../../utils/alert-success.component';
import { createMapping, updateMapping, modifyMapping } from '../../redux/actions/auth.action.creators'
import FileMappingFunctionReplaceComponent from './MappingFunctions/file.mapping.function.replace.component';

const FileMappingCreateComponent = (props, { dispatchCreateMapping, dispatchListMappingAction, dispatchModifyMapping }) => {
  const [allFields, setAllFields] = useState({ "input_fields": [], "output_fields": [], "mapping_fields": [] })
  const [fieldType, setFieldType] = useState("Source");
  const [addFieldPopUP, setAddFieldPopUP] = useState(false);
  const [arrows, setArrows] = useState([]);
  const fieldlocations = { "1": "H", "2": "B", "3": "F" }
  const [isEditing, setIsEditing] = useState({ "state": false, "item": "", "index": "", "type": "" });
  const [arrowKeys, setarrowKeys] = useState(0)
  const colors = ["#FFB6C1", "#FFC0CB", "#FF69B4", "#FF1493", "#DB7093", "#C71585", "#FFA07A", "#FA8072", "#E9967A", "#F08080", "#CD5C5C", "#DC143C", "#FF7F50", "#FF6347", "#FF4500", "#FF8C00", "#FFA500", "#FFD700", "#FFB400", "#FFFF00", "#ADFF2F", "#7FFF00", "#32CD32", "#228B22", "#008000"]
  const [fileType, setFileType] = useState({ "Source": "flat", "Destination": "flat" });
  const [draggedIndex, setDraggedIndex] = useState({ "list": "", "index": "" });
  const [draggedOverIndex, setDraggedOverIndex] = useState({ "list": "", "index": "" });
  const [inputRefresh, setInputRefresh] = useState(false);
  const [outputRefresh, setOutputRefresh] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [itemDetails, setItemDetails] = useState({ "item": "", "index": "", "details": "", "fileType": "" })
  const [selectedFunction, setSelectedFunction] = useState({ "item": "", "function": "", "details": "", "fileType": "","parameters": "" })
  const [inputDependenetFunctions,] = useState(["trim"])
  const [inputInDependenetFunctions,] = useState(["recordcount", "datetimenow"])
  const [popupVisibility, setPopupVisibility] = useState({ success: false, failed: false, message: null })
  const [existingMappingNames, setExistingMappingNames] = useState([])

  useEffect(() => {
    let defaults = getDefaults()
    setAllFields({ ...allFields, input_fields: defaults[0], output_fields: defaults[1], mapping_fields: defaults[2] });
  }, [props.destinationType, props.sourceType]);

  useEffect(() => {
    updateFields(inputRefresh, false);
  }, [inputRefresh]);

  useEffect(() => {
    updateFields(false, outputRefresh);
  }, [outputRefresh]);



  useEffect(() => {
    setarrowKeys(arrowKeys + 1)
    // console.log("arrows", arrows);
    // console.log("MappingFields ", allFields.mapping_fields);
    // console.log("All Fields ", allFields);
  }, [allFields]);

  useEffect(() => {
    console.log(selectedFunction);
  }, [selectedFunction]);

  useEffect(() => {
    if (props.cursor == 'edit'||props.cursor == 'copy') {
      
      let mappingData = JSON.parse(props.mappingConfig);
      setAllFields(mappingData.allFields);
      setArrows(mappingData.arrows)

      props.setMappingName(mappingData.mapping_name)
      props.setMappingNotes(mappingData.mapping_notes)
      setFileType({"Source":mappingData.fileType.Source,"Destination":mappingData.fileType.Destination})
      window.scrollTo(0, 0)
    }

  }, [props.mappingConfig]);



  function getDefaults() {
    // console.log(fileType, "TyPE");
    let source_header_entity = {}
    let source_body_entity = {}
    let source_footer_entity = {}
    let destination_header_entity = {}
    let destination_body_entity = {}
    let destination_footer_entity = {}
    let mapping_header_entity = {}
    let mapping_body_entity = {}
    let mapping_footer_entity = {}


    if (fileType.Source == "flat") {
      source_header_entity = { "field_length": "1", "field_location": "1", "field_name": "header_flat", "field_pattern": "", "field_type": "String", "position_from": "1" }
      source_body_entity = { "field_length": "1", "field_location": "2", "field_name": "body_flat", "field_pattern": "", "field_type": "String", "position_from": "1" }
      source_footer_entity = { "field_length": "1", "field_location": "3", "field_name": "footer_flat", "field_pattern": "", "field_type": "String", "position_from": "1" }
    } else if (fileType.Source == "csv") {
      source_header_entity = { "field_location": "1", "field_name": "header_csv", "field_pattern": "", "field_type": "String" }
      source_body_entity = { "field_location": "2", "field_name": "body_csv", "field_pattern": "", "field_type": "String" }
      source_footer_entity = { "field_location": "3", "field_name": "footer_csv", "field_pattern": "", "field_type": "String" }
    }
    if (fileType.Destination == "flat") {
      destination_header_entity = { "field_length": "1", "field_location": "1", "field_name": "header_flat", "field_pattern": "", "field_type": "String", "position_from": "1" }
      destination_body_entity = { "field_length": "1", "field_location": "2", "field_name": "body_flat", "field_pattern": "", "field_type": "String", "position_from": "1" }
      destination_footer_entity = { "field_length": "1", "field_location": "3", "field_name": "footer_flat", "field_pattern": "", "field_type": "String", "position_from": "1" }
      mapping_header_entity = { "field_type": fieldlocations["1"], "map_to": "header_flat", "function_params": "", "replace": "", "pattern": "", "function_name": "" }
      mapping_body_entity = { "field_type": fieldlocations["2"], "map_to": "body_flat", "function_params": "", "replace": "", "pattern": "", "function_name": "" }
      mapping_footer_entity = { "field_type": fieldlocations["3"], "map_to": "footer_flat", "function_params": "", "replace": "", "pattern": "", "function_name": "" }

    } else if (fileType.Destination == "csv") {
      destination_header_entity = { "field_location": "1", "field_name": "header_csv", "field_pattern": "", "field_type": "String" }
      destination_body_entity = { "field_location": "2", "field_name": "body_csv", "field_pattern": "", "field_type": "String" }
      destination_footer_entity = { "field_location": "3", "field_name": "footer_csv", "field_pattern": "", "field_type": "String" }
      mapping_header_entity = { "field_type": fieldlocations["1"], "map_to": "header_csv", "function_params": "", "replace": "", "pattern": "", "function_name": "" }
      mapping_body_entity = { "field_type": fieldlocations["2"], "map_to": "body_csv", "function_params": "", "replace": "", "pattern": "", "function_name": "" }
      mapping_footer_entity = { "field_type": fieldlocations["3"], "map_to": "footer_csv", "function_params": "", "replace": "", "pattern": "", "function_name": "" }
    }

    const inputFields = allFields.input_fields || [];
    const outputFields = allFields.output_fields || [];
    const mappingFields = allFields.mapping_fields || [];
    let newInputFields = [...inputFields];
    let newOutputFields = [...outputFields];
    let newMappingFields = [...mappingFields];
    if (props.sourceType === 0) {
      newInputFields = [source_header_entity, source_body_entity, source_footer_entity];
    }
    if (props.sourceType === 1) {
      newInputFields = [source_header_entity, source_body_entity];
    }

    if (props.sourceType === 2) {
      newInputFields = [source_body_entity, source_footer_entity];
    }
    if (props.sourceType === 3) {
      newInputFields = [source_body_entity];
    }
    if (props.destinationType === 0) {
      newOutputFields = [destination_header_entity, destination_body_entity, destination_footer_entity];
      newMappingFields = [mapping_header_entity, mapping_body_entity, mapping_footer_entity]
    }
    if (props.destinationType === 1) {
      newOutputFields = [destination_header_entity, destination_body_entity];
      newMappingFields = [mapping_header_entity, mapping_body_entity]
    }
    if (props.destinationType === 2) {
      newOutputFields = [destination_body_entity, destination_footer_entity];
      newMappingFields = [mapping_body_entity, mapping_footer_entity]
    }

    if (props.destinationType === 3) {
      newOutputFields = [destination_body_entity];
      newMappingFields = [mapping_body_entity]
    }


    return [newInputFields, newOutputFields, newMappingFields]
  }

  useEffect(() => {
    mappingTableList()
  }, []);

  const mappingTableList = () => {
    props.dispatchListMappingAction(({ data, message }) => {
      if (data) {
        setExistingMappingNames(data.map(element => element.mapping_name));
      }
    },
      (message) => {

        console.log("Mapping list error--  ", message);
      });
  }


  const handleSidebarToggle = (item, index) => {
    setShowSidebar(true);
    // console.log(item.field_name, allFields.mapping_fields[0]);
    const outputMappingField = allFields.mapping_fields.find(field => field.map_to === item.field_name);
    // console.log("MAPPING Field", outputMappingField);
    setItemDetails({ "item": outputMappingField, "index": index, "details": item, "fileType": fileType })
  };

  const handleArrowClick = (input, output, index) => {
    const newArrows = [...arrows];
    newArrows.splice(index, 1);
    setArrows(newArrows);
    setAllFields(prevFields => {
      const updatedMappingFields = prevFields.mapping_fields.map(field => {
        if (field.map_to === output) {
          if (inputDependenetFunctions.includes(field.function_name)) {
            return { ...field, function_name: '', function_params: '', pattern: '', replace: '' };
          } else {
            return { ...field, function_params: '' };
          }
        } else {
          return field;
        }
      });
      return { ...prevFields, mapping_fields: updatedMappingFields };
    });
    setShowSidebar(false)
  };

  const updateFields = (inputRefresh, outputRefresh) => {
    const defaults = getDefaults();
    const inputFields = allFields.input_fields;
    const outputFields = allFields.output_fields;

    if (inputRefresh) {
      let newOutputFields = [...outputFields];
      setAllFields({
        ...allFields,
        input_fields: defaults[0],
        output_fields: newOutputFields,
      });
      setAllFields((prevState) => ({
        ...prevState,
        mapping_fields: resetMappingFields,
      }));
      setArrows([])
      setarrowKeys(0)
    } else if (outputRefresh) {
      let newInputFields = [...inputFields];
      setAllFields({
        ...allFields,
        input_fields: newInputFields,
        output_fields: defaults[1],
        mapping_fields: defaults[2],
      });
      setArrows([])
      setarrowKeys(0)
    }
    setInputRefresh(false)
    setOutputRefresh(false)
  };

  const handlefiletypechange = (file_type, location) => {

    if (location == "Source") {
      setFileType({ "Source": file_type, "Destination": fileType.Destination });
      setInputRefresh(true);
    } else if (location == "Destination") {
      setFileType({ "Source": fileType.Source, "Destination": file_type });
      setOutputRefresh(true);
    }
  };

  const handleaddFieldPopUP = (field_type) => {
    setAddFieldPopUP(true)
    setShowSidebar(false)
    // console.log("f", field_type);
    setFieldType(field_type)
  };

  const handleDeleteField = (index, type) => {
    if (type == "Source") {
      const newInputFields = [...allFields.input_fields];
      let x = newInputFields.splice(index, 1);
      setAllFields({ ...allFields, input_fields: newInputFields });
      let mappingFiltered = allFields.mapping_fields.filter((field) => field.function_params !== x[0].field_name)
      setAllFields((prevFields) => ({ ...prevFields, mapping_fields: mappingFiltered }));
      let start = "I_" + fieldlocations[x[0].field_location] + "_" + x[0].field_name
      setArrows(arrows.filter((arrow) => arrow.start !== start))

    }
    else if (type == "Destination") {
      const newOutputFields = [...allFields.output_fields];
      let x = newOutputFields.splice(index, 1);
      setAllFields({ ...allFields, output_fields: newOutputFields });
      let end = "O_" + fieldlocations[x[0].field_location] + "_" + x[0].field_name
      // console.log("X", x);
      setArrows(arrows.filter((arrow) => arrow.end !== end))
      let mappingFiltered = allFields.mapping_fields.filter((field) => field.map_to !== x[0].field_name)
      setAllFields((prevFields) => ({ ...prevFields, mapping_fields: mappingFiltered }));

    }

  };

  const onPopupActionsDeleteArrows = (input_field, output_field, index) => {

    let arr = input_field.split("_");
    arr.splice(0, 2);
    const input = arr.join("_");
    arr = output_field.split("_");
    arr.splice(0, 2);
    const output = arr.join("_");

    confirmDialog({
      message: 'Do you want to delete the mapping from ' + input + ' to ' + output + ' ?',
      header: 'Submit Confirmation',
      button: "button",
      acceptClassName: 'p-button-danger',
      accept() {
        handleArrowClick(input, output, index)
      },
      reject() {


      },
      acceptLabel: "Confirm",
      rejectLabel: "Cancel"
    });
  };

  const handleMapNameChange = (event) => {
    // console.log("HERE");
    const value = event.target.value
      .toLowerCase()
      .replace(/ /g, "_")
      .replace(/[^a-z_0-9]/g, ""); // only allow lowercase letters and underscores
    props.setMappingName(value);
  };

  const onPopupActions = (index, item, type) => {
    confirmDialog({
      message: 'Do you want to delete ' + item.field_name + ' field ?',
      header: 'Submit Confirmation',
      button: "button",
      acceptClassName: 'p-button-danger',
      accept() {
        handleDeleteField(index, type)
      },
      reject() {


      },
      acceptLabel: "Confirm",
      rejectLabel: "Cancel"
    });
  };

  const onPopupActionsTypeChange = (value, type) => {
    confirmDialog({
      message: 'Do you want to switch to ' + value.toUpperCase() + ' for ' + type + ' Definition? This action will clear all existing fields and mappings.',
      header: 'Submit Confirmation',
      button: "button",
      acceptClassName: 'p-button-danger',
      accept() {
        handlefiletypechange(value, type)
      },
      reject() {


      },
      acceptLabel: "Confirm",
      rejectLabel: "Cancel"
    });
  };

  const handleEditAction = (index, item, type) => {
    setIsEditing({ "state": true, "item": item, "index": index, "type": type })
    setFieldType(type)
    setAddFieldPopUP(true)
  };

  const resetMappingFields = allFields.mapping_fields.map((mapping) => {
    return {
      ...mapping,
      function_name: "",
      function_params: "",
      pattern: "",
      replace: "",
    };
  });


  const handleDragStart = (event, item, index, side) => {
    event.dataTransfer.setData("item", JSON.stringify(item));
    event.dataTransfer.setData("index", JSON.stringify(index));
    event.dataTransfer.dropEffect = "move";
    // set the data to show near the mouse pointer while dragging the ellipsis
    if (side == 'Source') {
      event.dataTransfer.setDragImage(document.getElementById(`I_${fieldlocations[item.field_location]}_${item.field_name}`), 0, 0);
    } else if (side == 'Destination') {
      event.dataTransfer.setDragImage(document.getElementById(`O_${fieldlocations[item.field_location]}_${item.field_name}`), 0, 0);
    }
    setDraggedIndex({ "list": side, "index": index });
    event.dataTransfer.effectAllowed = "move";

  }

  const handleDrop = (event, index, outitem) => {
    event.preventDefault();
    // console.log(draggedIndex, draggedOverIndex, "YESS");

    if (draggedIndex.list == draggedOverIndex.list && draggedIndex.list == "Source" && fileType.Source == 'csv') {
      const items = [...allFields.input_fields];
      const draggedItem = items[draggedIndex.index];
      items.splice(draggedIndex.index, 1);
      items.splice(draggedOverIndex.index, 0, draggedItem);
      // console.log(items, "items");
      items.sort((a, b) => {
        if (a.field_location !== b.field_location) {
          return a.field_location.localeCompare(b.field_location);
        }
      });
      setAllFields({ ...allFields, input_fields: items });
      setDraggedIndex({ "list": "", "index": "" });
      setDraggedOverIndex({ "list": "", "index": "" });


    } else if (draggedIndex.list == draggedOverIndex.list && draggedIndex.list == "Destination" && fileType.Destination == 'csv') {
      const items = [...allFields.output_fields];
      const draggedItem = items[draggedIndex.index];
      items.splice(draggedIndex.index, 1);
      items.splice(draggedOverIndex.index, 0, draggedItem);
      // console.log(items, "items");
      items.sort((a, b) => {
        if (a.field_location !== b.field_location) {
          return a.field_location.localeCompare(b.field_location);
        }
      });
      setAllFields({ ...allFields, output_fields: items });
      setDraggedIndex({ "list": "", "index": "" });
      setDraggedOverIndex({ "list": "", "index": "" });
    } else if (draggedIndex.list != draggedOverIndex.list && draggedIndex.list == 'Source') {
      const inItem = JSON.parse(event.dataTransfer.getData("item"));
      const newEntity = {
        field_type: fieldlocations[inItem.field_location],
        map_to: outitem.field_name,
        function_params: inItem.field_name,
        replace: "",
        pattern: "",
        function_name: ""
      };
      let in_id = "I" + "_" + fieldlocations[inItem.field_location] + "_" + inItem.field_name
      let out_id = "O" + "_" + fieldlocations[outitem.field_location] + "_" + outitem.field_name

      const existingEntityIndex = allFields.mapping_fields.findIndex(
        (entity) => entity.map_to === outitem.field_name
      );
      const existingArrowIndex = arrows.findIndex(
        (arrow) => arrow.end === out_id
      );

      if (existingEntityIndex === -1) {
        const newMappingFields = [...allFields.mapping_fields, newEntity];
        setAllFields((prevFields) => ({ ...prevFields, mapping_fields: newMappingFields }));
      } else {
        const updatedMappingFields = [...allFields.mapping_fields];
        updatedMappingFields[existingEntityIndex].function_params = inItem.field_name;
        if (inputInDependenetFunctions.includes(updatedMappingFields[existingEntityIndex].function_name)) {
          updatedMappingFields[existingEntityIndex].function_name = "";
          updatedMappingFields[existingEntityIndex].pattern = "";
          updatedMappingFields[existingEntityIndex].replace = "";
        }
        setAllFields((prevFields) => ({ ...prevFields, mapping_fields: updatedMappingFields }));
      }

      if (existingArrowIndex === -1) {
        setArrows((arrows) => [...arrows, { start: in_id, end: out_id }]);
      } else {
        setArrows((arrows) => {
          const updatedArrows = [...arrows];
          updatedArrows[existingArrowIndex].start = in_id;
          return updatedArrows;
        });
      }
    }
    setShowSidebar(false)
  };

  const enablePopup = (ctgry, msg) => {
    if (ctgry) {
      setPopupVisibility({ success: true, failed: false, message: msg })
      setTimeout(() => {
        setPopupVisibility({ success: false, failed: false, message: null })
      }, 7000);
    } else {
      setPopupVisibility({ success: false, failed: true, message: msg })
      setTimeout(() => {
        setPopupVisibility({ success: false, failed: false, message: null })
      }, 7000);
    }
  }



  const onSaveMapping = () => {
    var requestbody = {
      //Data From First Page
      "allFields": allFields,
      "fileType": fileType,
      "lineBreakType": props.lineBreakType,
      "mapping_name": props.mappingName,
      "mapping_notes": props.mappingNotes,
      "arrows": arrows
    }
    // console.log("requestbody", requestbody);

    let fileFormData = new FormData();
    var dataToStringify = JSON.stringify(requestbody)
    fileFormData.append("requestBody", dataToStringify);

    if (requestbody.mapping_name === "" || requestbody.mapping_name === null) {
      window.scrollTo(0, 0)
      enablePopup(false, "Mapping Name Is Empty")
    } else if (requestbody.mapping_notes === "" || requestbody.mapping_notes === null) {
      window.scrollTo(0, 0)
      enablePopup(false, "Mapping Notes Is Empty")
    } else if (props.cursor == 'edit') {
      props.dispatchModifyMapping(fileFormData, ({ data, status ,code ,description }) => {
        if (status === 'SUCCESS') {
          window.scrollTo(0, 0)
          enablePopup(true, "Mapping Updation Success!")
          setTimeout(() => {
            props.setCursor('define')
          }, 1000);
          props.setMappingName("")
          props.setMappingNotes("")

        }else if(status === 'FAILURE'){
          window.scrollTo(0, 0)
          enablePopup(false, "Mapping Creation Failed! "+description);
        }
      }, (message) => {
        if (message) {
          window.scrollTo(0, 0)
          enablePopup(false, "Mapping Updation Failed ")
          console.log(message);
        }
      });
    }
    else if (existingMappingNames.includes(requestbody.mapping_name)) {
      window.scrollTo(0, 0)
      enablePopup(false, "Mapping Name Already Exists")
    }
    else {
      props.dispatchCreateMapping(fileFormData, ({ data, status ,code ,description}) => {
        if (status === 'SUCCESS') {
          window.scrollTo(0, 0)
          enablePopup(true, "Mapping Creation Success!")
          setTimeout(() => {
            props.setCursor('define')
          }, 1000);

        }else if(status === 'FAILURE'){
          window.scrollTo(0, 0)
          enablePopup(false, "Mapping Creation Failed! "+ description);
        }
      }, (message) => {
        if (message) {
          window.scrollTo(0, 0)
          enablePopup(false, "Mapping Creation Failed")
          console.log(message);
        }
      });
    }

  }
  const handleDragOver = (event, index, side) => {
    event.preventDefault();
    setDraggedOverIndex({ "list": side, "index": index });
  };

  return (
    <React.Fragment>
      <div className={`container ${addFieldPopUP ? 'modal-open' : ''}`}>
        <ul className="breadcrumb">
          <li className="breadcrumb__item">
            <a className="breadcrumb__link" href=''>Configurations</a>
          </li>
          <li className="breadcrumb__item">
            <a className="breadcrumb__link" href="/FileMapping">File Mapping</a>
          </li>
          <li className="breadcrumb__item">
          {props.cursor=="edit"?<a className="breadcrumb__link" href='/FileMapping'>Edit Mapping</a>:<a className="breadcrumb__link" href='/FileMapping'>Create Mapping</a>}
            
          </li>
        </ul>
        <div >
          {popupVisibility.failed ?
            <AlertFailure message={popupVisibility.message} /> : ''}
          {popupVisibility.success ?
            <AlertSuccess message={popupVisibility.message} /> : ''}
        </div>
        <div className="title">
          {props.cursor=="edit"?<h1>Edit Mapping</h1>:<h1>Create Mapping</h1>}
        </div>
        <div class="file-mapping">
          <div class='form-group'>
            <div class='grey-block form-row' style={{ display: 'flex' }}>
              <div class='form-row' style={{ width: '100%', display: 'flex' }}>
                <div class='form-row' style={{ width: '50%', paddingRight: '10px' }}>
                  <label for="mapping_name" style={{ padding: '25px', paddingBottom: "0px" }} className="input-label">Mapping Name<span style={{ color: 'red' }}>*</span></label>
                  <div className="input-wrap txt-wrap" style={{ padding: '10px', paddingBottom: "0px", flexGrow: 1 }}>
                    <input style={{ padding: '5px' }} value={props.mappingName} disabled={props.updationStatus ? true : props.cursor == 'edit' ? true : false} type="text" autoComplete="off" onChange={handleMapNameChange} placeholder="Enter Mapping Name" id="mapping_name" />
                  </div>
                </div>
                <div class='form-row' style={{ width: '50%', paddingLeft: '10px' }}>
                  <label for="mapping_notes" style={{ padding: '25px', paddingBottom: "0px" }} className="input-label">Notes<span style={{ color: 'red' }}>*</span></label>
                  <div className="input-wrap txt-wrap" style={{ padding: '10px', paddingBottom: "5px", flexGrow: 1 }}>
                    <input style={{ padding: '5px' }} value={props.mappingNotes} type="text" autoComplete="off" onChange={((e) => { props.setMappingNotes(e.target.value) })} placeholder="Enter Mapping Notes" id="mapping_notes" />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-4 h-100 " >

                <div class="widget widget--access widget--mapping senders " style={{ zIndex: 2 }}>


                  <div class="form-group">
                    <label for="f2" class="d-block input-label">File type</label>
                    <div class="input-wrap date-wrap">
                      {/* <select id="f1" onChange={handleInputChange} name="field_location" value={fieldEntity.field_location}>
                         */}
                      <select id="f2" onChange={((e) => { onPopupActionsTypeChange(e.target.value, "Source") })} value={fileType.Source} name="file_type" >
                        <option value="flat" >FLAT</option>
                        <option value="csv">CSV</option>
                      </select>
                    </div>
                  </div>
                  <div class="head">Source  Definition</div>
                  <ul id="input">
                    {allFields.input_fields.map((item, index) => (
                      <li
                        key={index}
                        id={"I" + "_" + fieldlocations[item.field_location] + "_" + item.field_name}
                        style={{ backgroundColor: "#fff", transition: "background-color 0.2s" }}
                        onMouseEnter={(event) => event.target.style.backgroundColor = "#f5f5f5"}
                        onMouseLeave={(event) => event.target.style.backgroundColor = "#fff"}
                        draggable
                        onDragStart={(event) => handleDragStart(event, item, index, "Source")}
                        onDragOver={(event) => handleDragOver(event, index, "Source")}
                        onDrop={(event) =>
                          handleDrop(event, index, item)
                        }
                      >
                        <span class="path">{item.field_name.slice(0, 20)}</span>
                        {item.position_from ? (
                          <span className="text" style={{ color: 'red' }}>
                            {item.position_from}({fieldlocations[item.field_location]})
                          </span>
                        ) : <span className="text" style={{ color: 'red' }}>
                          ({fieldlocations[item.field_location]})
                        </span>}
                        <span class="fn-circle"></span>
                        <a class="dropdown--mapping" href="#">
                          <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          <span class="sr-only">ellipsis</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-left dropdown-menu--mapping">
                          <a class="dropdown-item" href="#" onClick={() => handleEditAction(index, item, "Source")}>
                            <span class="sr-only">Edit</span>Edit Field</a>
                          <a class="dropdown-item" href="#" onClick={() => onPopupActions(index, item, "Source")}>
                            <span class="sr-only">Trash</span>Delete Field
                          </a>
                        </div>
                        <a class="dropdown--mapping" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          <span class="sr-only">ellipsis</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                  <div class="btn-wrap text-right mt-4">
                    <a class="link link--sm" href="#" onClick={(() => { handleaddFieldPopUP("Source") })} data-toggle="modal" >
                      <i class="fa fa-plus-circle mr-1" aria-hidden="true"></i>Add new field</a>
                  </div>

                </div>

                <div class="btn-wrap text-right mt-4">
                  <div class="btn-wrap mb-3">
                    {/* <button class="button button--sm button--outline w-100" data-toggle="modal" data-target="#staticmapping">Load Source XML Template</button> */}
                  </div>
                </div>
              </div>

              <div class="col-md-4 offset-md-4 h-100">

                <div class="widget widget--access widget--mapping receivers">
                  <div class="form-group">
                    <label for="f2" class="d-block input-label">File type</label>
                    <div class="input-wrap date-wrap">
                      <select id="f1" onChange={((e) => { onPopupActionsTypeChange(e.target.value, "Destination") })} value={fileType.Destination} name="file_type" >
                        <option value="flat">FLAT</option>
                        <option value="csv">CSV</option>
                      </select>
                    </div>
                  </div>
                  <div class="head">Destination Definition</div>
                  <ul id="output">
                    {allFields.output_fields.map((item, index) => {
                      const mappingField = allFields.mapping_fields.find(
                        (mapping) => mapping.map_to === item.field_name
                      );
                      const isFunctionNamePresent = mappingField?.function_name != "" && mappingField?.function_name != null;

                      return (
                        <li
                          key={index}
                          id={"O" + "_" + fieldlocations[item.field_location] + "_" + item.field_name}
                          draggable
                          onDragStart={(event) => handleDragStart(event, item, index, "Destination")}
                          onDragOver={(event) => handleDragOver(event, index, "Destination")}
                          onDrop={(event) => handleDrop(event, index, item)}
                          style={{ color: isFunctionNamePresent ? "green" : "inherit", fontWeight: isFunctionNamePresent ? "bold" : "inherit" }}
                        >
                          <span
                            class="path"
                            onClick={() => {
                              handleSidebarToggle(item, index);
                            }}
                          >
                            {item.field_name.slice(0, 20)}
                          </span>
                          <span class="text" style={{ color: "red" }}>
                            {item.position_from}({fieldlocations[item.field_location]})
                          </span>
                          <span class="fn-circle"></span>
                          <div class="dropdown-menu dropdown-menu-left dropdown-menu--mapping">

                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={() => handleEditAction(index, item, "Destination")}
                            >
                              <span class="sr-only">Edit</span>Edit Field
                            </a>
                            <a class="dropdown-item" href="#" onClick={() => handleSidebarToggle(item, index)}>
                              <span class="sr-only">Functions</span>Add Functions
                            </a>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={() => onPopupActions(index, item, "Destination")}
                            >
                              <span class="sr-only">Trash</span>Delete Field
                            </a>
                          </div>
                          <a
                            class="dropdown--mapping"
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                            <span class="sr-only">ellipsis</span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                  <div class="btn-wrap text-right mt-4">
                    <a class="link link--sm" onClick={(() => { handleaddFieldPopUP("Destination") })} href="#">
                      <i class="fa fa-plus-circle mr-1" aria-hidden="true"></i>Add new field</a>
                  </div>
                </div>
                <div class="btn-wrap text-right mt-4">
                  <div class="btn-wrap mb-3">
                    {/* <button class="button button--sm button--outline w-100">Load Destination XML Template</button> */}
                  </div>
                </div>

              </div>
              {arrows.map((arrow, index) => (
                <div onClick={() => onPopupActionsDeleteArrows(arrow.start, arrow.end, index)} style={{ "cursor": "pointer" }}>
                  <Xarrow key={`${arrow.start}-${arrow.end}` + arrowKeys}
                    zIndex={0}
                    color={colors[index % 25 + 1]}
                    strokeWidth={3} animateDrawing={true} start={arrow.start} end={arrow.end}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div class="btn-wrap btn-wrap--grp mt-5 justify-content-end" style={{ "marginBottom": "12px" }}>
          <button onClick={(() => { props.setCursor('define'); })} class="button button--outline">Cancel</button>
          <button onClick={(() => { onSaveMapping() })} class="button">Save</button>
        </div>
        {addFieldPopUP && fileType[fieldType] == 'flat' && <FileMappingPopupFlatComponent setArrows={setArrows} arrows={arrows} addFieldPopUP={addFieldPopUP} setAddFieldPopUP={setAddFieldPopUP} isEditing={isEditing} setIsEditing={setIsEditing} fieldType={fieldType} setFieldType={setFieldType} allFields={allFields} setAllFields={setAllFields} fileType={fileType} setFileType={setFileType} />}
        {addFieldPopUP && fileType[fieldType] == 'csv' && <FileMappingPopupCsvComponent setArrows={setArrows} arrows={arrows} addFieldPopUP={addFieldPopUP} setAddFieldPopUP={setAddFieldPopUP} isEditing={isEditing} setIsEditing={setIsEditing} fieldType={fieldType} setFieldType={setFieldType} allFields={allFields} setAllFields={setAllFields} fileType={fileType} setFileType={setFileType} />}
        {showSidebar && <FileMappingSidebarComponent showSidebar={showSidebar} itemDetails={itemDetails} setSelectedFunction={setSelectedFunction} selectedFunction={selectedFunction} setShowSidebar={setShowSidebar} allFields={allFields} setAllFields={setAllFields} />}
        {selectedFunction.function == "recordcount" && <FileMappingFunctionRecordcountComponent allFields={allFields} setAllFields={setAllFields} selectedFunction={selectedFunction} setSelectedFunction={setSelectedFunction} />}
        {selectedFunction.function == "zeros" && <FileMappingFunctionZerosComponent allFields={allFields} setAllFields={setAllFields} selectedFunction={selectedFunction} setSelectedFunction={setSelectedFunction} />}
        {selectedFunction.function == "datetimenow" && <FileMappingFunctionDatetimenowComponent allFields={allFields} setAllFields={setAllFields} selectedFunction={selectedFunction} setSelectedFunction={setSelectedFunction} />}
        {selectedFunction.function == "trim" && <FileMappingFunctionTrimComponent allFields={allFields} setAllFields={setAllFields} selectedFunction={selectedFunction} setSelectedFunction={setSelectedFunction} />}
        {selectedFunction.function == "replace" && <FileMappingFunctionReplaceComponent fileType={fileType} allFields={allFields} setAllFields={setAllFields} selectedFunction={selectedFunction} setSelectedFunction={setSelectedFunction} />}

      </div>

    </React.Fragment >


  );

}

const mapDispatchToProps = dispatch => ({
  dispatchCreateMapping: (requestbody, onSuccess, onError) =>
    dispatch(createMapping(requestbody, onSuccess, onError)),
  dispatchModifyMapping: (requestbody, onSuccess, onError) =>
    dispatch(modifyMapping(requestbody, onSuccess, onError)),
  dispatchListMappingAction: (onSuccess, onError) =>
    dispatch(displayMappingData(onSuccess, onError))
})

export default connect(null, mapDispatchToProps)(FileMappingCreateComponent);

