import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createPartner, updatePartner } from '../../redux/actions/auth.action.creators'
import AlertFailure from '../../utils/alert-failure.component';
import AlertSuccess from '../../utils/alert-success.component';
import BackIcon from '../../assets/images/icon-back.png'


const PartnerOnboardingSummaryComponent = (props, { dispatchUpdatePartner, dispatchCreatePartner }) => {

    const [popupVisibility, setPopupVisibility] = useState({ success: false, failed: false, message: null })

    const getNotificationString = (items) => {
        // console.log(items)
        let notification_string = ''
        if (typeof items !== 'string') {
            for (let i = 0; i < items.length; i++) {
                notification_string += items[i].value
                if (i != items.length - 1) {
                    notification_string += ','
                }
            }
        }else{
            notification_string=items
        }
        // console.log('notification_string', props.inputDataForUserDetails.notifications = notification_string)
        props.inputDataForUserDetails.notifications = notification_string
    }




    const onSavePartner = () => {
        var userPasswordValue = ""
        var inboundPasswordValue = ""
        var outboundPasswordValue = ""
        var tempArrayForSSHProfileDetailsRequest = []
        const valueIsNotNull = (v1) => { return v1 !== null && v1 !== "" ? true : false; }
        const passwordRegCheck = (pass) => {
            if (new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(pass)) { return true }
            else { return false }
        }
        if (!props.updationStatus) {
            userPasswordValue = props.inputDataForUserDetails.isAccessPortal ? props.inputDataForUserDetails.ud_Password : (props.sshInBound.sshInBoundAuthenticationType === "Password" ? props.sshInBound.password : " ")
            inboundPasswordValue = props.inputDataForUserDetails.isAccessPortal ? props.inputDataForUserDetails.ud_Password : (props.sshInBound.sshInBoundAuthenticationType === "Password" ? props.sshInBound.password : " ")
            outboundPasswordValue = props.sshOutBound.sshOutBoundAuthenticationType === "Password" ? props.sshOutBound.sshOutBoundPassword : " "
        } else if (props.updationStatus) {
            // if ((props.inputDataForUserDetails.isAccessPortal && props.passwordUpdateFlag) || (props.inputDataForUserDetails.isAccessPortal && props.inputDataForUserDetails.ud_Password !== null && props.inputDataForUserDetails.ud_Password !== "")) {
            //     userPasswordValue = props.inputDataForUserDetails.ud_Password
            //     inboundPasswordValue =  props.inputDataForUserDetails.ud_Password }
            userPasswordValue = valueIsNotNull(props.inputDataForUserDetails.ud_Password) && passwordRegCheck(props.inputDataForUserDetails.ud_Password) ? props.inputDataForUserDetails.ud_Password : (valueIsNotNull(props.sshInBound.password) && passwordRegCheck(props.sshInBound.password)) ? props.sshInBound.password : ""
            inboundPasswordValue = valueIsNotNull(props.inputDataForUserDetails.ud_Password) && passwordRegCheck(props.inputDataForUserDetails.ud_Password) ? props.inputDataForUserDetails.ud_Password : (valueIsNotNull(props.sshInBound.password) && passwordRegCheck(props.sshInBound.password)) ? props.sshInBound.password : ""
            outboundPasswordValue = props.sshOutBound.sshOutBoundAuthenticationType === "Password" && props.inOutPasswordUpdateFlag.outbnd ? props.sshOutBound.sshOutBoundPassword : ""
        }
        if ((props.inputDataForRole.partnerRoleConsumer && props.inputDataForRole.partnerRoleConsumerListen) || (props.inputDataForRole.partnerRoleProducer && props.inputDataForRole.partnerRoleProducerListen)) {
            var tempArray = [...props.spdElements];
            tempArrayForSSHProfileDetailsRequest = []
            var tempJSONForRequest = {}
            tempArray.forEach(element => {
                tempJSONForRequest = {
                    filePattern: element.f_pattern,
                    filePermission: element.f_permission,
                    operationFlag: element.operationFlag,
                    renamePrefix: element.r_prefix,
                    renameSuffix: element.r_suffix,
                    sshDetailsId: element.sshDetailsId,
                    sshId: element.sshId
                }
                console.log("From Summary");
                console.log(tempJSONForRequest);
                tempArrayForSSHProfileDetailsRequest.push(tempJSONForRequest)
            });
        }
        getNotificationString(props.inputDataForUserDetails.notifications)
        var requestbody = {
            //Data From First Page
            "partnerCode": props.inputDataProfile.PartnerCode ? props.inputDataProfile.PartnerCode : "",
            "partnerID": props.updationStatus ? parseInt(window.location.pathname.replace('/partnerOnboarding/', "")) : 0,
            "partnerName": props.inputDataProfile.PartnerName,
            "outboundConnectionType": props.inputDataForRole.outboundConnectionType,
            "address": props.inputDataProfile.Address,
            "city": props.inputDataProfile.City,
            "country": props.inputDataProfile.Country ? props.inputDataProfile.Country : " ",
            "email": props.inputDataProfile.Email,
            "phoneNumber": props.inputDataProfile.Mobile,
            "pinCode": props.inputDataProfile.Pincode,
            "state": props.inputDataProfile.State,
            "partnerTag": props.inputDataProfile.PartnerTag != "" && props.inputDataProfile.PartnerTag != null ? props.inputDataProfile.PartnerTag : 0,
            "sendReceiveFiles": props.inputDataForUserDetails.isSendOrReceiveEmails,
            "acceptEmails": props.inputDataForUserDetails.isSendOrReceiveEmails ? props.inputDataForUserDetails.acceptEmails : "",
            "sendEmails": props.inputDataForUserDetails.isSendOrReceiveEmails ? props.inputDataForUserDetails.sendEmails : "",
            "notifications": props.inputDataForUserDetails.notifications ? props.inputDataForUserDetails.notifications : "",
            //Data From Second Page
            "userDetails": [
                {
                    "email": props.inputDataForUserDetails.ud_Email !== "" ? props.inputDataForUserDetails.ud_Email.toLowerCase() : " ",
                    "givenName": props.inputDataForUserDetails.ud_GivenName !== "" ? props.inputDataForUserDetails.ud_GivenName : " ",
                    "operationFlag": props.updationStatus ? "M" : "I",
                    //"password": props.inputDataForUserDetails.isAccessPortal ? props.inputDataForUserDetails.ud_Password : props.sshInBound.password,
                    "password": userPasswordValue,
                    "accessPortal": props.inputDataForUserDetails.isAccessPortal,
                    "surName": props.inputDataForUserDetails.ud_SurName !== "" ? props.inputDataForUserDetails.ud_SurName : " ",
                    "userId": props.userIdForPartnerUpdate,
                    "username": props.inputDataForUserDetails.ud_Username !== "" ? props.inputDataForUserDetails.ud_Username : " "
                }
            ],
            //Data From Third Page
            "partnerRoleConsumer": props.inputDataForRole.partnerRoleConsumer,
            "partnerRoleConsumerInitiate": props.inputDataForRole.partnerRoleConsumerInitiate,
            "partnerRoleConsumerListen": props.inputDataForRole.partnerRoleConsumerListen,
            "partnerRoleProducer": props.inputDataForRole.partnerRoleProducer,
            "partnerRoleProducerInitiate": props.inputDataForRole.partnerRoleProducerInitiate,
            "partnerRoleProducerListen": props.inputDataForRole.partnerRoleProducerListen,
            "setMaxFileSize": props.inputDataForRole.setMaxFileSize,
            "maxFileSize": props.inputDataForRole.maxFileSize,
            //Data For SSHProfile Details
            "sshProfileDetails":
            {
                "inboundAuthenticationType": props.sshInBound.sshInBoundAuthenticationType,
                "inboundPassword": inboundPasswordValue,
                "inboundPublicKey": props.sshInBound.userPublicKey,
                "outboundAuthenticationType": props.sshOutBound.sshOutBoundAuthenticationType,
                "outboundCharacterEncoding": props.sshOutBound.characterEncoding,
                "outboundCompression": props.sshOutBound.compression,
                "outboundConnectionRetryCount": props.sshOutBound.connectionRetryCount,
                "outboundDownloadDirectory": props.sshOutBound.downloadDirectory,
                "outboundKnownHostKey": props.sshOutBound.knownHostKey,
                "outboundLocalPortRange": parseInt(props.sshOutBound.localPortRange) ? parseInt(props.sshOutBound.localPortRange) : 0,
                "passPhrase": props.sshOutBound.passPhrase,
                "outboundPassword": outboundPasswordValue,
                "outboundRoot": props.sshOutBound.root,
                "outboundPort": parseInt(props.sshOutBound.port) ? parseInt(props.sshOutBound.port) : null,
                "outboundPreferredCipher": props.sshOutBound.preferredCipher,
                "outboundPreferredMAC": props.sshOutBound.preferredMAC,
                "outboundRemoteHost": props.sshOutBound.remoteHost,
                "outboundRemoteUser": props.sshOutBound.remoteUser,
                "outboundResponseTimeout": parseInt(props.sshOutBound.responseTimeout) ? props.sshOutBound.responseTimeout : null,
                "outboundRetryDelay": parseInt(props.sshOutBound.retryDelay) ? props.sshOutBound.retryDelay : null,
                "outboundUploadDirectory": props.sshOutBound.uploadDirectory,
                "outboundUserIdentityKey": "filehandler/application/sftp_keys/" + props.sshOutBound.shhOutUserIdentityKey,
                "s3OutboundBucket": props.sshOutBound.bucketname,
                "s3OutboundRegion": props.sshOutBound.region,
                "s3OutboundFolderPath": props.sshOutBound.folderpath,
                "s3OutboundUploadFolderPath": props.sshOutBound.uploadFolderPath,
                "s3OutboundIAMRole": props.sshOutBound.s3OutboundIAMRole

            },
            //Data For SSH PROFILE DETaisl
            "sshProfileDetailsList": tempArrayForSSHProfileDetailsRequest,
            //Data For PGP Details

            "partnerSendFilesAsEncrypted": props.inputDataForProtocol.partnerSendFilesAsEncrypted,
            "routerPublicKeyringDropdown": props.inputDataForProtocol.routerPublicKeyringDropdown,
            "verifyEncryptedFile": props.inputDataForProtocol.verifyEncryptedFile,

            "partnerRequireFilesToBeEncrypted": props.inputDataForProtocol.partnerRequireFilesToBeEncrypted,
            "partnerPublicKeyringName": props.inputDataForProtocol.partnerPublicKeyringName ? props.inputDataForProtocol.partnerPublicKeyringName.name : "",
            "partnerProtocolEmail": props.inputDataForProtocol.partnerProtocolEmail,
            "partnerProtocolUserId": props.inputDataForProtocol.partnerProtocolUserId,
            "signFile": props.inputDataForProtocol.signFile,

            "sendPublicFileAsEmail": props.inputDataForProtocol.sendPublicFileAsEmail


        }
        console.log(requestbody);

        let fileFormData = new FormData();
        // if(props.inputDataForProtocol.partnerPublicKeyringName.type?)
        if (props.inputDataForProtocol.partnerRequireFilesToBeEncrypted && props.inputDataForProtocol.partnerPublicKeyringName.name && props.inputDataForProtocol.partnerPublicKeyringName.name !== null && props.inputDataForProtocol.partnerPublicKeyringName.name !== "") {
            console.log(props.inputDataForProtocol.partnerPublicKeyringName.name);
            fileFormData.append('hasPartnerFile', true);
            fileFormData.append('partnerUploadKeyFile', props.inputDataForProtocol.partnerPublicKeyringName);
        } else {
            console.log(props.inputDataForProtocol.partnerPublicKeyringName);
            fileFormData.append('hasPartnerFile', false);
        }
        var dataToStringify = JSON.stringify(requestbody)
        fileFormData.append("requestBody", dataToStringify);




        if (props.updationStatus) {
            props.dispatchUpdatePartner(fileFormData, ({ data, message, status,description }) => {
                if (status === 'SUCCESS') {
                    window.scrollTo(0, 0)
                    enablePopup(true, "Partner Updation Success!")
                }else if( status === 'FAILURE'){
                    window.scrollTo(0, 0)
                    enablePopup(false, "Partner Creation Failed! " + description)
                }
            }, (message) => {
                if (message) {
                    window.scrollTo(0, 0)
                    enablePopup(false, "Partner Updation Failed ")
                    console.log(message);
                }
            });
        }
        else if (requestbody.PartnerCode === "Nil" || requestbody.PartnerCode === "") {
            window.scrollTo(0, 0)
            enablePopup(false, "Partner Code Is Empty")
        } else {
            props.dispatchCreatePartner(fileFormData, ({ data, message, status, description }) => {
                if (status === 'SUCCESS') {
                    window.scrollTo(0, 0)
                    enablePopup(true, "Partner Creation Success!")
                }else if( status === 'FAILURE'){
                    window.scrollTo(0, 0)
                    enablePopup(false, "Partner Creation Failed! " + description)
                }
            }, (message) => {
                if (message) {
                    window.scrollTo(0, 0)
                    enablePopup(false, "Partner Creation Failed")
                    console.log(message);
                }
            });
        }

    }



    const enablePopup = (ctgry, msg) => {
        if (ctgry) {
            setPopupVisibility({ success: true, failed: false, message: msg })
            setTimeout(() => {
                setPopupVisibility({ success: false, failed: false, message: null })
            }, 7000);
        } else {
            setPopupVisibility({ success: false, failed: true, message: msg })
            setTimeout(() => {
                setPopupVisibility({ success: false, failed: false, message: null })
            }, 7000);
        }
    }

    return (
        <React.Fragment>
            <main>
                <div class="container">
                    <ul class="breadcrumb">
                        <li class="breadcrumb__item">
                            <a class="breadcrumb__link" href="#">Manage Partner </a>
                        </li>
                        <li class="breadcrumb__item">
                            <a class="breadcrumb__link" href='/partner'>Partner</a>
                        </li>
                        <li class="breadcrumb__item">
                            <span class="breadcrumb__link">Onboarding</span>
                        </li>
                    </ul>
                    <div >
                        {popupVisibility.failed ?
                            <AlertFailure message={popupVisibility.message} /> : ''}
                        {popupVisibility.success ?
                            <AlertSuccess message={popupVisibility.message} /> : ''}
                    </div>
                    <div class="title">
                        <h1>Partner Onboarding Summary</h1>
                        <a href='/partner' class="link link--back"><i> <img src={BackIcon} alt="Back" /></i>  Back to Partner</a>
                    </div>
                    <div class="summary mb-5">
                        <div class="summary__block">
                            <div class="summary__head">
                                <h2>Partner Profile</h2>
                                <div class="edit-block">
                                    <a href="#" onClick={(() => { props.setCursor('profile') })} class="edit">Edit</a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="summary__item">
                                        <span class="type">Partner Code</span>
                                        <span class="text">{props.inputDataProfile.PartnerCode}</span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="summary__item">
                                        <span class="type">Partner Name</span>
                                        <span class="text">{props.inputDataProfile.PartnerName}</span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="summary__item">
                                        <span class="type">Address</span>
                                        <span class="text">{props.inputDataProfile.Address}</span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="summary__item">
                                        <span class="type">City</span>
                                        <span class="text">{props.inputDataProfile.City}</span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="summary__item">
                                        <span class="type">State</span>
                                        <span class="text">{props.inputDataProfile.State}</span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="summary__item">
                                        <span class="type">Country</span>
                                        <span class="text">{props.inputDataProfile.Country}</span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="summary__item">
                                        <span class="type">Pincode</span>
                                        <span class="text">{props.inputDataProfile.Pincode}</span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="summary__item">
                                        <span class="type">Phone Number</span>
                                        <span class="text">{props.inputDataProfile.Mobile}</span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="summary__item">
                                        <span class="type">Email</span>
                                        <span class="text">{props.inputDataProfile.Email}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="summary__block">
                            <div class="summary__head">
                                <h2>User Details</h2>
                                <div class="edit-block">
                                    <a href="#" onClick={(() => { props.setCursor('Partner_UserProfile') })} class="edit">Edit</a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="summary__item">
                                        <span class="type">Username</span>
                                        <span class="text">{props.inputDataForUserDetails.ud_Username}</span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="summary__item">
                                        <span class="type">Email</span>
                                        <span class="text">{props.inputDataForUserDetails.ud_Email}</span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="summary__item">
                                        <span class="type">Given Name</span>
                                        <span class="text">{props.inputDataForUserDetails.ud_GivenName}</span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="summary__item">
                                        <span class="type">Surname</span>
                                        <span class="text">{props.inputDataForUserDetails.ud_SurName}</span>
                                    </div>
                                </div>
                                <div class="question">
                                    <div class="question-text">Enable portal access?</div>
                                    <div class="chcek-wrap">
                                        <div class="form-check">
                                            <input type="checkbox" checked id="2" />
                                            <label for="2">{props.inputDataForUserDetails.isAccessPortal ? "Yes" : "No"}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="questionnaire questionnaire--summary mb-5">
                        <div class="questionnaire__blcok">
                            <div class="questionnaire--summary__head">
                                <h2>Partner Role</h2>
                                <div class="edit-block">
                                    <a href="#" onClick={(() => { props.setCursor('Partner_Role') })} class="edit">Edit</a>
                                </div>
                            </div>
                            <div class="question">
                                <span class="number">1</span>
                                <div class="question-text">Does partner like to receive data?</div>
                                <div class="chcek-wrap">
                                    <div class="form-check">
                                        <input type="checkbox" checked id="1" />
                                        <label for="1">{props.inputDataForRole.partnerRoleConsumer ? "Yes" : "No"}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="question">
                                <span class="number">2</span>
                                <div class="question-text">Does partner like to send data?</div>
                                <div class="chcek-wrap">
                                    <div class="form-check">
                                        <input type="checkbox" checked id="2" />
                                        <label for="2">{props.inputDataForRole.partnerRoleProducer ? "Yes" : "No"}</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="questionnaire__blcok">
                            <div class="questionnaire--summary__head">
                                <h2>Protocol</h2>
                                <div class="edit-block">
                                    <a href="#" onClick={(() => { props.setCursor('Partner_Protocol') })} class="edit">Edit</a>
                                </div>
                            </div>


                            <div class="question">
                                <span class="number">1</span>
                                <div class="question-text">Does partner send files as encrypted?</div>
                                <div class="chcek-wrap">
                                    <div class="form-check">
                                        <input type="checkbox" checked id="4" />
                                        <label for="4">{props.inputDataForProtocol.partnerSendFilesAsEncrypted ? "Yes" : "No"}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="question">
                                <span class="number">2</span>
                                <div class="question-text">Does partner require files to be encrypted and send?</div>
                                <div class="chcek-wrap">
                                    <div class="form-check">
                                        <input type="checkbox" checked id="5" />
                                        <label for="5">{props.inputDataForProtocol.partnerRequireFilesToBeEncrypted ? "Yes" : "No"}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btn-wrap btn-wrap--grp mt-5 justify-content-end">
                            <button class="button" onClick={(() => { onSavePartner() })} >{props.updationStatus ? "Update" : "Save"}</button>
                        </div>
                    </div>
                </div>
            </main>

        </React.Fragment>);

}


const mapDispatchToProps = dispatch => ({
    dispatchCreatePartner: (requestbody, onSuccess, onError) =>
        dispatch(createPartner(requestbody, onSuccess, onError)),
    dispatchUpdatePartner: (requestbody, onSuccess, onError) =>
        dispatch(updatePartner(requestbody, onSuccess, onError))
})
export default connect(null, mapDispatchToProps)(PartnerOnboardingSummaryComponent);

