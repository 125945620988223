import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import unlock_icon from '../assets/images/unlock.png';
import lock_icon from '../assets/images/lock.png';
import { confirmDialog } from 'primereact/confirmdialog';
import AlertFailure from '../utils/alert-failure.component';
import AlertSuccess from '../utils/alert-success.component';
import { Link } from 'react-router-dom';
import _ from "lodash";
import { getAllUsers, getAllLockedUsers, changeUserState, getLockandActivePartnerUsersCount, isAdmin } from '../redux/actions/auth.action.creators'



const UserLockAndUnlockComponent = ({ dispatchGetLockandActivePartnerUsersCount, dispatchGetAllUsersList, dispatchGetAllLockedUsersList, dispatchChangeUserState }) => {

    const [feildData_org, setFeildData_org] = useState([{}])
    const [feildData, setFeildData] = useState([{}])
    const [partnerListingCategory, setPartnerListingCategory] = useState('AllUsers') // 'LockedUser'  , 'AllUsers'
    const [onSearchKeyword, setOnSearchKeyword] = useState('')
    const [succMsg, setSuccMsg] = useState('');
    const [failMsg, setFailMsg] = useState('');
    const [countInfo, setCountInfo] = useState({ allpartnerusercount: 0, alllockedpartnerusercount: 0 })
    const [sortAsc, setSortAsc] = useState({ "username": false, "email": false, "partner_name": false, "status": false, is_access_portal: false });

    useEffect(() => {

        if (partnerListingCategory === 'AllUsers') {
            dispatchGetAllUsersList(({ data, message }) => {
                if (data) {
                    setFeildData(data);
                    setFeildData_org(data)
                    getCountInfo()
                }
            }, (message) => {
                console.error(message)
            });

        } else if (partnerListingCategory === 'LockedUser') {
            dispatchGetAllLockedUsersList(({ data, message }) => {
                if (data) {
                    setFeildData(data);
                    setFeildData_org(data);
                    getCountInfo()
                }
            }, (message) => {
                console.error(message)
            });
        }
    }, [partnerListingCategory])


    const getCountInfo = () => {
        dispatchGetLockandActivePartnerUsersCount(({ data, msg }) => {
            if (data) {
                setCountInfo(data)
            }
        }, (msg) => {
            console.log(msg);
        });
    }

    useEffect(() => {
        try {
            if (feildData_org.length > 0) {
                var tempMemory = feildData_org.filter(a =>
                    a.username.toLowerCase().includes(onSearchKeyword.toLowerCase()) ||
                    a.email.toLowerCase().includes(onSearchKeyword.toLowerCase()) ||
                    a.partner_name.toLowerCase().includes(onSearchKeyword.toLowerCase())
                );
                setFeildData(tempMemory)
            }
        } catch (error) {
            console.log(error);
        }
    }, [onSearchKeyword])

    function compareBy(key, dir) {
        return function (a, b) {
            if (a[key] < b[key]) return dir == true ? -1 : 1;
            if (a[key] > b[key]) return dir == true ? 1 : -1;
            return 0;
        };
    }

    function sortBy(key) {
        let arrayCopy = _.cloneDeep(feildData);
        let dirCopy = _.cloneDeep(sortAsc);
        dirCopy[key] = !dirCopy[key];
        setSortAsc(dirCopy);
        arrayCopy.sort(compareBy(key, dirCopy[key]));
        setFeildData(arrayCopy);
    }
    
    const changeStateConfirmationDialog = (c_id, c_username, c_status) => {

        confirmDialog({
            message: c_status === 'LOCK' ? 'Do you want to unlock ' + c_username + '?' : 'Do you want to lock ' + c_username + '?',
            header: c_status === 'LOCK' ? 'Unlock Confirmation' : 'Lock Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept() {
                dispatchChangeUserState(c_id, ({ data, message }) => {
                    if (partnerListingCategory === 'AllUsers') {
                        dispatchGetAllUsersList(({ data, message }) => {
                            if (data) {
                                setFeildData(data);
                                setFeildData_org(data)
                                setSuccMsg("Updated successfully");
                                setTimeout(() => {
                                    setSuccMsg('')
                                }, 3000);
                                window.scrollTo(0, 0);

                            }
                        }, (message) => {
                            window.scrollTo(0, 0);
                            setFailMsg("Updation Failed");
                            console.log(message);
                            setTimeout(() => {
                                setFailMsg('')
                            }, 3000);

                        });
                        getCountInfo()
                    } else if (partnerListingCategory === 'LockedUser') {
                        dispatchGetAllLockedUsersList(({ data, message }) => {
                            if (data) {
                                setFeildData(data);
                                setFeildData_org(data)
                                setSuccMsg("Updated successfully");
                                setTimeout(() => {
                                    setSuccMsg('')
                                }, 3000);
                                window.scrollTo(0, 0);

                            }
                        }, (message) => {
                            window.scrollTo(0, 0);
                            setFailMsg("Updation Failed");
                            console.log(message);
                            setTimeout(() => {
                                setFailMsg('')
                            }, 3000);
                        });
                        getCountInfo()
                    }
                }, (message) => {
                    console.error(message)
                });
            },
            reject() {

            },
            acceptLabel: "Confirm",
            rejectLabel: "Cancel"
        });
    };

    return (
        <React.Fragment>
            <main>
                <div style={{ paddingInline: '10%' }} className="container col-md-12">
                    <ul className="breadcrumb">
                        <li className="breadcrumb__item">
                        <Link to="/user"><a className="breadcrumb__link" href="#">Home</a></Link>
                        </li>
                        {!isAdmin()?<li className="breadcrumb__item">
                        <Link to="/user"><a className="breadcrumb__link" href="#">Manage User</a></Link>
                        </li>:''}
                    </ul>
                    {/*  style={{ paddingTop: '5%' }} */}
                    <div className="table-block">
                        <deleteConfirm></deleteConfirm>
                        <div className="table-block__head">
                            <h2>List of users &nbsp;  [<span style={{ fontSize: "small" }}>&nbsp;All Partner Users :  <b>{countInfo.allpartnerusercount}</b>, Locked Users  :  <b>{countInfo.alllockedpartnerusercount}</b></span>]</h2>
                            <div className="txt-wrap">
                                <input type="text" className="txt" placeholder="Search..." onChange={(e) => setOnSearchKeyword(e.target.value)} />
                                <i className="fa fa-search"></i>
                            </div>
                        </div>
                        {failMsg ?
                            <AlertFailure message={failMsg} /> : ''}
                        {succMsg ?
                            <AlertSuccess message={succMsg} /> : ''}
                        <div style={{ paddingTop: '2%' }} className="txt-wrap">
                            <div className="radio-wrap">
                                <input checked={partnerListingCategory === 'AllUsers' ? true : false} type="radio" id="list1" name="radio-group" />
                                <label onClick={(e) => setPartnerListingCategory('AllUsers')} >All Users</label>
                                &nbsp;&nbsp;&nbsp;
                                <input checked={partnerListingCategory === 'LockedUser' ? true : false} type="radio" id="list1" name="radio-group" />
                                <label onClick={(e) => setPartnerListingCategory('LockedUser')} >Locked Users</label>
                            </div>
                        </div>
                        <br></br><br></br>
                        <div className="grid"  >
                            <table className="table"  >
                                <thead>
                                    <tr>
                                        {/* <th style={{ zIndex: 0 }} scope="col">Sl.No</th> */}
                                        <th onClick={() => sortBy('username')} style={{ zIndex: 0 }} scope="col">Username <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th onClick={() => sortBy('email')} style={{ zIndex: 0 }} scope="col">Email <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th onClick={() => sortBy('partner_name')} style={{ zIndex: 0 }} scope="col">Partner Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th onClick={() => sortBy('status')} style={{ zIndex: 0 }} scope="col">Status <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th onClick={() => sortBy('is_access_portal')} style={{ zIndex: 0 }} scope="col">Portal User <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th style={{ zIndex: 0 }} scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {feildData && feildData.length > 0 ?
                                        feildData.map((data, index) => (
                                            <tr>
                                                {/* <td>{index + 1}</td> */}
                                                <td>{data.username}</td>
                                                <td>{data.email}</td>
                                                <td>{data.partner_name}</td>
                                                <td>{data.status === 'ACTV'?'Active':data.status==='INACTV'?'Inactive':data.status === 'LOCK'?'Lock':data.status}</td>
                                                <td style={{ color: data.is_access_portal ? 'green' : 'red' }}>{data.is_access_portal ? 'True' : 'False'}</td>
                                                <td style={{ cursor: "pointer" }} onClick={() => changeStateConfirmationDialog(data.id, data.username, data.status)}>{data.status === 'LOCK' ? <img src={lock_icon} alt="locked icon" /> : <img src={unlock_icon} alt="unlocked icon" />}</td>
                                            </tr>
                                        ))
                                        : <p>No records avaiable</p>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment >
    );
};


const mapDispatchToProps = dispatch => ({
    dispatchGetAllUsersList: (onSuccess, onError) =>
        dispatch(getAllUsers(onSuccess, onError)),
    dispatchGetAllLockedUsersList: (onSuccess, onError) =>
        dispatch(getAllLockedUsers(onSuccess, onError)),
    dispatchChangeUserState: (id, onSuccess, onError) =>
        dispatch(changeUserState({ userId: id }, onSuccess, onError)),
    dispatchGetLockandActivePartnerUsersCount: (onSuccess, onError) =>
        dispatch(getLockandActivePartnerUsersCount(onSuccess, onError)),
});

export default connect(null, mapDispatchToProps)(UserLockAndUnlockComponent);
