import React, { useState, useEffect, Component } from 'react';
import UserImg from '../assets/images/image-user-1.png';
import ActiveImg from '../assets/images/icon-active.png';
import PortalImg from '../assets/images/icon-portal.png';
import ChannelImg from '../assets/images/icon-channel.png';
import ProcessImg from '../assets/images/icon-file-process.png';
import MoreIcon from '../assets/images/icon-back.png';
import DashboardIcon from '../assets/images/icon_dashboard.png';
import FileHealthIcon from '../assets/images/icon_fileHealth.png';
import IconRefresh from '../assets/images/icon-retry.png';
import ArwDown from '../assets/images/arw-red-down.png';
import ArwUp from '../assets/images/arw-green.png';
import iconClose from '../assets/images/icon-close.svg';
import iconInfo from '../assets/images/icon-info-round.png';
import { Chart } from 'primereact/chart';
import * as constants from '../redux/constants';
import _ from "lodash";
import { fetchWidgetBoxDataCount, listPartners, fetchFileVolumeStatistics, fetchFileMissingStatistics, fetchChartMonthDataCount, fetchQueueChartCount, fetchDateMonthChartCount, fetchSourceChartCount, getSrUnreadEmailReportData, getSrUnprocessedEmailCount, isJson, sessionErrorCheck, fetchSystemParams, fetchPartnerDetailsById, downloadFile, isAdmin, isOperationalUser, isPartnerUser, isPartnerAdmin, isCustomUser } from '../redux/actions/auth.action.creators'
import { connect } from 'react-redux';
import { SortObjectArray } from "../utils/ArraySort";
import { GroupArrayObject } from "../utils/GroupArrayObject";
import { formatFileSize } from "../utils/formatFileSize";
import { Tooltip } from 'primereact/tooltip';
var CryptoJS = require("crypto-js");
var secretKey = require(process.env.REACT_APP_CONFIG_URL);
import { monthList } from "../Bean/filestructure.js";


const reportConf = secretKey.switch.reports;
var appConf  = require(process.env.REACT_APP_CONFIG_URL);

const DashboardComponent = ({ dispatchGetSrUnreadEmailReportData, dispatchGetSrUnprocessedEmailCountData, dispatchListPartnersAction, dispatchFetchFileVolumeStatistics, dispatchWidgetBoxDataCount, dispatchQueueChartCount, dispatchMissingChartCount, dispatchDateMonthChartCount, dispatchSourceChartCount, dispatchSystemParamsAction, dispatchGetPartnerDetails, dispatchDownloadAction }) => {
  const userInfos = sessionErrorCheck() !== null ? sessionErrorCheck() : window.location = '/auth';
  var decryptByte = CryptoJS.AES.decrypt(userInfos, secretKey.switch.secretKey); const userInfo = userInfos;
  const currentDate = Intl.DateTimeFormat("en-AU", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short"
  }).format(new Date());

  const currentMonth = Intl.DateTimeFormat("en-AU", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, month: "short", }).format(new Date());
  const currentYear = Intl.DateTimeFormat("en-AU", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, year: "numeric" }).format(new Date());

  const [widgetBoxDataCount, setWidgetBoxDataCount] = useState([{}]);
  const [queueSmallCount, setQueueSmallCount] = useState("");
  const [queueMediumCount, setQueueMediumCount] = useState("");
  const [queueLargeCount, setQueueLargeCount] = useState("");
  const [monthOrDateCount, setMonthOrDateCount] = useState([]);
  const [failedCount, setFailedCount] = useState([]);
  const [successCount, setSuccessCount] = useState([]);
  const [processCount, setProcessCount] = useState([]);
  const [uploadCount, setUploadCount] = useState([]);
  const [sourceCount, setSourceCount] = useState([]);
  const [sourceEmailCount, setSourceEmailCount] = useState("");
  const [sourceSftpCount, setSourceSftpCount] = useState("");
  const [sourceWebCount, setSourceWebCount] = useState("");
  const [processedCount, setProcessedCount] = useState([]);
  const [customFileProcessedCount, setCustomFileProcessedCount] = useState([]);
  const [unreadEmailData, setUnreadEmailData] = useState([]);
  const [unProcessedEmailCountData, setUnProcessedEmailCountData] = useState([]);
  const [deliveryFailedCount, setDeliveryFailedCount] = useState([]);
  const [filemissingPeriod, setFilemissingPeriod] = useState("Month");
  const [fileMissingPartners, setFileMissingPartners] = useState([]);
  const [partnerList, setPartnerList] = useState([])
  const [fileMissingCountData, setFileMissingCountData] = useState([]);
  const [fileMissingpartnerCount, setFileMissingpartnerCount] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [enableSshDownload, setEnableSshDownload] = useState(false);
  const [enablePgpDownload, setEnablePgpDownload] = useState(false);
  var today_forfilevolume = new Date();
  const [fileVolumeFilterRequest, setFileVolumeFilterRequest] = useState({ filterBy: "MonthAndYear", year: today_forfilevolume.getFullYear(), month: today_forfilevolume.getMonth() + 1, sdate: new Date().toLocaleDateString("en-AU") + " " + "00:00:00", edate: new Date().toLocaleDateString("en-AU") + " " + "00:00:00" });

  const [fileVolumeListData, setFileVolumeListData] = useState([{ QueueName: "", FilesSize: 0, NoOfFiles: 0 }]);

  const [dropdown_yearList, setDropdown_yearList] = useState([]);
  var configInfo = require(process.env.REACT_APP_CONFIG_URL);
  const colors = ["#FFB6C1", "#FFC0CB", "#FF69B4", "#FF1493", "#DB7093", "#C71585", "#FFA07A", "#FA8072", "#E9967A", "#F08080", "#CD5C5C", "#DC143C", "#FF7F50", "#FF6347", "#FF4500", "#FF8C00", "#FFA500", "#FFD700", "#FFB400", "#FFFF00", "#ADFF2F", "#7FFF00", "#32CD32", "#228B22", "#008000"];
  var fileprocessingQueueMappingInfo = configInfo.switch.fileprocessingQueueMapping;
  var fileVolumeStatsViewEnabled = configInfo.switch.fileVolumeStatsView;
  var companyCode = configInfo.switch.companyCode;
  const [partnerName, setPartnerName] = useState();

  useEffect(() => {
    if (isAdmin() || isPartnerAdmin() || isOperationalUser()) {
      dispatchListPartnersAction(({ data, message }) => {
        if (data) {
          if (isPartnerUser()) {
            let currentPartner = data.filter(p => (p.partnerid == userInfo.partnerId))[0].partnercode;
            setPartnerList([currentPartner]);
          }
          else {
            let arr = [];
            data.forEach(p => {
              arr.push(p.partnercode)
            })
            setPartnerList(arr);
          }
        }
      },
        (message) => {
          console.log(message);
        });
    }
  }, [])

  useEffect(() => {
    if (isAdmin() || isPartnerAdmin() || isOperationalUser()) {
      dispatchWidgetBoxDataCount(({ data, message }) => {
        if (data) {
          setWidgetBoxDataCount(data)
        }
      });
      getBarChartDetails("0");
      getQueueCount();
      getSourceCount();
    }
  }, [])

  useEffect(() => {
    getMissingCount("Month");
  }, [partnerList])

  useEffect(() => {
    if (isAdmin()&& reportConf.ComponentForSRUnreadEmail ) {
      dispatchGetSrUnreadEmailReportData(({ data, message }) => {
        if (data) {
          setUnreadEmailData(SortObjectArray("mail_count", true, false, data))
        }
      })
    }
  }, [])

  useEffect(() => {
    dispatchGetSrUnprocessedEmailCountData(({ data, message }) => {
      if (data) {
        setUnProcessedEmailCountData(data);
      }
    })
  }, [])



  useEffect(() => {
    if ((isAdmin()) && fileVolumeStatsViewEnabled) {
      if (fileVolumeFilterRequest.filterBy === "Year") {
        if (fileVolumeFilterRequest.year != "Choose Year" && fileVolumeFilterRequest.year != "") {
          console.log("calling year wise records");
          console.log(fileVolumeFilterRequest);
          dispatchFetchFileVolumeStatistics({ dateFrom: sdateFormater(fileVolumeFilterRequest.year, 1), dateTo: edateFormater(fileVolumeFilterRequest.year, 12), filterBy: "Year" }, ({ data, message, status }) => {
            if (data && data.length > 0) {
              console.log(data);
              formattingFileVolumeData(data)
            } else {
              formattingFileVolumeData(data)
            }
          })
        }
      } else if (fileVolumeFilterRequest.filterBy === "MonthAndYear") {
        if (valueIsNotNull(fileVolumeFilterRequest.year) && valueIsNotNull(fileVolumeFilterRequest.month) && valueIsNotNull(fileVolumeFilterRequest.sdate) && valueIsNotNull(fileVolumeFilterRequest.edate)) {
          console.log("calling year and month wise records");
          console.log(fileVolumeFilterRequest);
          dispatchFetchFileVolumeStatistics({ dateFrom: sdateFormater(fileVolumeFilterRequest.year, fileVolumeFilterRequest.month), dateTo: edateFormater(fileVolumeFilterRequest.year, fileVolumeFilterRequest.month), filterBy: "MonthAndYear" }, ({ data, message, status }) => {
            if (data && data.length > 0) {
              console.log(data);
              formattingFileVolumeData(data)
            } else {
              dummyModelCreation();
            }
          })
        }
      }
    }
  }, [fileVolumeFilterRequest])

  useEffect(() => {
    if (isPartnerUser()) {
      dispatchSystemParamsAction({ "keyList": [constants.SSH_KEY_PATH, constants.PGP_KEY_PATH] }, ({ data, message }) => {
        if (data) {
          let sshParamVal = data[constants.SSH_KEY_PATH].paramValue;
          let pgpParamVal = data[constants.PGP_KEY_PATH].paramValue;
          dispatchGetPartnerDetails(parseInt(userInfo.partnerId), ({ data, message }) => {
            if (data) {
              let sshKeyPath = data.sshProfileResponse.outboundUserIdentityKey;
              if ((data.partnerRoleConsumer == true) && (data.partnerRoleConsumerListen == true) && (data.sshProfileResponse.outboundAuthenticationType.trim() == "SSH") && sshKeyPath.includes(sshParamVal)) {
                let arr = sshKeyPath.split(sshParamVal)[1]
                if (arr.includes('/') && arr.split('/')[1].includes(arr.split('/')[0])) {
                  var n = sshKeyPath.lastIndexOf("private_key");
                  sshKeyPath = sshKeyPath.slice(0, n) + sshKeyPath.slice(n).replace("private_key", "public_key");
                  setEnableSshDownload(sshKeyPath);
                }
              }
              if (data.partnerSendFilesAsEncrypted == true && data.hubPublicKeyringName != "" && data.hubPublicKeyringName != null) {
                setEnablePgpDownload(pgpParamVal + data.hubPublicKeyringName);
              }
              setPartnerName(data.partnerName);
            }
          }, (message) => {

          });
        }
      }, (message) => {
        console.log(message);
      });
    }
  }, [dispatchSystemParamsAction, dispatchGetPartnerDetails]);

  const valueIsNotNull = (v1) => { return v1 !== null && v1 !== "" && v1 != undefined ? true : false; }

  const formattingFileVolumeData = (responsedata) => {
    //queue name list for display, queue arn 
    var fileprocessingQueueKeysList = Object.keys(fileprocessingQueueMappingInfo)
    var fileprocessingQueueValueslist = Object.values(fileprocessingQueueMappingInfo)

    //parsing queue arn with queuename
    responsedata.map((response) => {
      if (fileprocessingQueueKeysList.includes(response["queue_name"])) {
        console.log(fileprocessingQueueMappingInfo[response["queue_name"]]);
        response.queue_name = fileprocessingQueueMappingInfo[response["queue_name"]]
      }
    })

    //group by queue name
    const groupedresponsedata = GroupArrayObject(responsedata, response => response.queue_name);

    //create model
    var temp_for_nooffiles = 0;
    var temp_for_filesize = 0;
    var FileVolumeListData_temp = []
    fileprocessingQueueValueslist.forEach(data => {
      temp_for_nooffiles = 0;
      temp_for_filesize = 0;
      if (groupedresponsedata.get(data)) {
        groupedresponsedata.get(data).map(g_res => {
          temp_for_nooffiles = temp_for_nooffiles + g_res['nooffiles']
          temp_for_filesize = temp_for_filesize + g_res['filesizebytes']
        })
      }
      FileVolumeListData_temp.push({ QueueName: data, FilesSize: temp_for_filesize, NoOfFiles: temp_for_nooffiles })
    })
    console.log(FileVolumeListData_temp);
    setFileVolumeListData(FileVolumeListData_temp);
  }

  const dummyModelCreation = () => {
    var fileprocessingQueueValueslist = Object.values(fileprocessingQueueMappingInfo)
    var temp_for_nooffiles = 0;
    var temp_for_filesize = 0;
    var FileVolumeListData_temp = []
    fileprocessingQueueValueslist.forEach(data => {
      temp_for_nooffiles = 0;
      temp_for_filesize = 0;
      FileVolumeListData_temp.push({ QueueName: data, FilesSize: temp_for_filesize, NoOfFiles: temp_for_nooffiles })
    })
    setFileVolumeListData(FileVolumeListData_temp);
  }


  const assignFilterValue = (category, targetValue) => {
    var temp = _.cloneDeep(fileVolumeFilterRequest);
    console.log(category, targetValue);
    temp.filterBy = category === "filterBy" ? targetValue : fileVolumeFilterRequest.filterBy
    temp.year = category === "year" ? targetValue : fileVolumeFilterRequest.year
    temp.month = category === "month" ? targetValue : fileVolumeFilterRequest.month
    temp.sdate = category === "month" ? sdateFormater(fileVolumeFilterRequest.year, parseInt(targetValue)) : fileVolumeFilterRequest.sdate
    temp.edate = category === "month" ? edateFormater(fileVolumeFilterRequest.year, parseInt(targetValue)) : fileVolumeFilterRequest.edate
    setFileVolumeFilterRequest(temp)
  }

  const sdateFormater = (s_year, s_value) => {
    if (valueIsNotNull(s_year) && valueIsNotNull(s_value)) {
      var sd = new Date();
      sd.setFullYear(s_year, s_value - 1, 1)
      sd = sd.toLocaleDateString("en-CA") + " " + "00:00:00"
      return sd;
    }
  }

  const edateFormater = (e_year, e_value) => {
    if (valueIsNotNull(e_year) && valueIsNotNull(e_value) && e_value != 0) {
      var ed = new Date(e_year, e_value, 0)
      ed = ed.toLocaleDateString("en-CA") + " " + "23:59:59"
      return ed;
    }
  }


  useEffect(() => {
    var date = new Date();
    var dd = date.getFullYear() - 3;
    var temp = []
    temp.push("Choose Year")
    for (var i = 0; i < 10; i++) { temp.push(dd + i) }
    setDropdown_yearList(temp)
  }, [])

  useEffect(() => {
    getMissingCount(filemissingPeriod)
  }, [filemissingPeriod])


  const getQueueCount = () => {
    dispatchQueueChartCount(({ data, message }) => {
      if (data) {
        setQueueSmallCount(data[0].smallfilehandlerqueue !== null ? data[0].smallfilehandlerqueue : 0);
        setQueueMediumCount(data[0].mediumfilehandlerqueue !== null ? data[0].mediumfilehandlerqueue : 0);
        setQueueLargeCount(data[0].largefilehandlerqueue !== null ? data[0].largefilehandlerqueue : 0);
      }
    });
  }

  const getMissingCount = (last) => {
    if (isAdmin() || isPartnerAdmin() || isOperationalUser()) {
      dispatchMissingChartCount(last, ({ data, message }) => {
        if (data) {
          // Assuming setFileMissingPartners is a function that sets the missing partners data
          setFileMissingPartners(data);
          var missingData = []
          var missingPartners = []
          var missingCount = []
          data.map((i) => {
            // Assuming partnerList is an array or iterable object containing partner codes
            if (partnerList.includes(i.partner_code)) {
              // Assuming setFileMissingCountData is a function that sets the missing count data
              missingCount.push(i.file_count)
              // Assuming setFileMissingpartnerCount is a function that sets the partner count data
              missingPartners.push(i.partner_name)

              missingData.push(i)
            }
          });

          setFileMissingCountData(missingCount)
          setFileMissingPartners(missingData)
          setFileMissingpartnerCount(missingPartners)
        }
        else {
          setFileMissingCountData([])
          setFileMissingPartners([])
          setFileMissingpartnerCount([])
        }
      });
    }
  }

  const getSourceCount = () => {
    var sourceList = []
    dispatchSourceChartCount(({ data, message }) => {
      if (data) {
        setSourceEmailCount(data[0].email_count !== null ? data[0].email_count : 0);
        setSourceSftpCount(data[0].sftp_count !== null ? data[0].sftp_count : 0);
        setSourceWebCount(data[0].web_count !== null ? data[0].web_count : 0);
        sourceList.push(data[0].email_count !== null ? data[0].email_count : 0, data[0].sftp_count !== null ? data[0].sftp_count : 0, data[0].web_count !== null ? data[0].web_count : 0)
        setSourceCount(sourceList);
      }
    });
  }

  const onChangeBarChartDetails = (e) => {
    getBarChartDetails(e.target.value);
  }

  const getBarChartDetails = (date) => {
    var monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    var monthOrDateStore = []
    var monthsList = []
    var yearList = []
    var statusList = []
    var pushTotalList = []
    var uploadedList = []
    var successList = []
    var failedList = []
    var shift_count = 0
    var processingList = []
    var processedList = []
    var customFileProcessList = []
    var deliveryFailedList = []
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    var shift_count = month
    dispatchDateMonthChartCount(date, ({ data, message }) => {
      if (data) {

        for (var i = 0; i < data.length; i++) {
          if (date === "0") {
            var difference = (year - data[i].createdyear) * 12 + month - data[i].createdmonth
            if (difference >= 0 && difference <= 11) {
              pushTotalList.push({ "createdmonth": data[i].createdmonth, "createdyear": data[i].createdyear, "count": data[i].count, "status": data[i].status, "checkStatus": data[i].status + ' ' + data[i].createdmonth + ' ' + data[i].createdyear, "checkDate": data[i].createdmonth + '' + data[i].createdyear });
              if (!monthsList.includes(data[i].createdmonth)) {
                monthsList.push(data[i].createdmonth);
              }
              if (!yearList.includes(data[i].createdyear)) {
                yearList.push(data[i].createdyear);
              }
              if (!statusList.includes(data[i].status + ' ' + data[i].createdmonth + ' ' + data[i].createdyear)) {
                statusList.push(data[i].status + ' ' + data[i].createdmonth + ' ' + data[i].createdyear);
              }
              if (!monthOrDateStore.includes(monthList[data[i].createdmonth - 1] + " " + data[i].createdyear)) {
                monthOrDateStore.push(monthList[data[i].createdmonth - 1] + " " + data[i].createdyear);
              }
            }

          } else {
            pushTotalList.push({ "createdmonth": data[i].uploadeddate, "createdyear": data[i].uploadeddate, "count": data[i].statuscount, "status": data[i].status, "checkStatus": data[i].status + ' ' + data[i].uploadeddate, "checkDate": data[i].uploadeddate });
            if (!monthsList.includes(data[i].uploadeddate)) {
              monthsList.push(data[i].uploadeddate);
            }
            if (!yearList.includes(data[i].uploadeddate)) {
              yearList.push(data[i].uploadeddate);
            }
            if (!statusList.includes(data[i].status + ' ' + data[i].uploadeddate)) {
              statusList.push(data[i].status + ' ' + data[i].uploadeddate);
            }
            if (!monthOrDateStore.includes(data[i].uploadeddate)) {
              monthOrDateStore.push(data[i].uploadeddate);
            }
          }
        }
        if (date === "0") {
          for (var j = 0; j < pushTotalList.length; j++) {
            if (!statusList.includes("FAILED" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear) && monthsList.includes(pushTotalList[j].createdmonth) && yearList.includes(pushTotalList[j].createdyear)) {
              statusList.push("FAILED" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear);
              pushTotalList.push({ createdmonth: pushTotalList[j].createdmonth, createdyear: pushTotalList[j].createdyear, count: 0, status: "FAILED", checkStatus: "FAILED" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear, "checkDate": pushTotalList[j].checkDate });
            }
            if (!statusList.includes("PROCESSING" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear) && monthsList.includes(pushTotalList[j].createdmonth) && yearList.includes(pushTotalList[j].createdyear)) {
              statusList.push("PROCESSING" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear);
              pushTotalList.push({ createdmonth: pushTotalList[j].createdmonth, createdyear: pushTotalList[j].createdyear, count: 0, status: "PROCESSING", checkStatus: "PROCESSING" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear, "checkDate": pushTotalList[j].checkDate });
            }
            if (!statusList.includes("SUCCESS" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear) && monthsList.includes(pushTotalList[j].createdmonth) && yearList.includes(pushTotalList[j].createdyear)) {
              statusList.push("SUCCESS" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear);
              pushTotalList.push({ createdmonth: pushTotalList[j].createdmonth, createdyear: pushTotalList[j].createdyear, count: 0, status: "SUCCESS", checkStatus: "SUCCESS" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear, "checkDate": pushTotalList[j].checkDate });
            }
            if (!statusList.includes("UPLOADED" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear) && monthsList.includes(pushTotalList[j].createdmonth) && yearList.includes(pushTotalList[j].createdyear)) {
              statusList.push("UPLOADED" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear);
              pushTotalList.push({ createdmonth: pushTotalList[j].createdmonth, createdyear: pushTotalList[j].createdyear, count: 0, status: "UPLOADED", checkStatus: "UPLOADED" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear, "checkDate": pushTotalList[j].checkDate });
            }
            if (!statusList.includes("PROCESSED" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear) && monthsList.includes(pushTotalList[j].createdmonth) && yearList.includes(pushTotalList[j].createdyear)) {
              statusList.push("PROCESSED" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear);
              pushTotalList.push({ createdmonth: pushTotalList[j].createdmonth, createdyear: pushTotalList[j].createdyear, count: 0, status: "PROCESSED", checkStatus: "PROCESSED" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear, "checkDate": pushTotalList[j].checkDate });
            }
            if (!statusList.includes("CUSTOM FILE PROCESSED" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear) && monthsList.includes(pushTotalList[j].createdmonth) && yearList.includes(pushTotalList[j].createdyear)) {
              statusList.push("CUSTOM FILE PROCESSED" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear);
              pushTotalList.push({ createdmonth: pushTotalList[j].createdmonth, createdyear: pushTotalList[j].createdyear, count: 0, status: "CUSTOM FILE PROCESSED", checkStatus: "CUSTOM FILE PROCESSED" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear, "checkDate": pushTotalList[j].checkDate });
            }
            if (!statusList.includes("DELIVERY FAILED" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear) && monthsList.includes(pushTotalList[j].createdmonth) && yearList.includes(pushTotalList[j].createdyear)) {
              statusList.push("DELIVERY FAILED" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear);
              pushTotalList.push({ createdmonth: pushTotalList[j].createdmonth, createdyear: pushTotalList[j].createdyear, count: 0, status: "DELIVERY FAILED", checkStatus: "DELIVERY FAILED" + ' ' + pushTotalList[j].createdmonth + ' ' + pushTotalList[j].createdyear, "checkDate": pushTotalList[j].checkDate });
            }

          }
        }
        else {
          for (var j = 0; j < pushTotalList.length; j++) {
            if (!statusList.includes("FAILED" + ' ' + pushTotalList[j].createdmonth) && monthsList.includes(pushTotalList[j].createdmonth) && yearList.includes(pushTotalList[j].createdyear)) {
              statusList.push("FAILED" + ' ' + pushTotalList[j].createdmonth);
              pushTotalList.push({ createdmonth: pushTotalList[j].createdmonth, createdyear: pushTotalList[j].createdyear, count: 0, status: "FAILED", checkStatus: "FAILED" + ' ' + pushTotalList[j].createdmonth, "checkDate": pushTotalList[j].checkDate });
            }
            if (!statusList.includes("PROCESSING" + ' ' + pushTotalList[j].createdmonth) && monthsList.includes(pushTotalList[j].createdmonth) && yearList.includes(pushTotalList[j].createdyear)) {
              statusList.push("PROCESSING" + ' ' + pushTotalList[j].createdmonth);
              pushTotalList.push({ createdmonth: pushTotalList[j].createdmonth, createdyear: pushTotalList[j].createdyear, count: 0, status: "PROCESSING", checkStatus: "PROCESSING" + ' ' + pushTotalList[j].createdmonth, "checkDate": pushTotalList[j].checkDate });
            }
            if (!statusList.includes("SUCCESS" + ' ' + pushTotalList[j].createdmonth) && monthsList.includes(pushTotalList[j].createdmonth) && yearList.includes(pushTotalList[j].createdyear)) {
              statusList.push("SUCCESS" + ' ' + pushTotalList[j].createdmonth);
              pushTotalList.push({ createdmonth: pushTotalList[j].createdmonth, createdyear: pushTotalList[j].createdyear, count: 0, status: "SUCCESS", checkStatus: "SUCCESS" + ' ' + pushTotalList[j].createdmonth, "checkDate": pushTotalList[j].checkDate });
            }
            if (!statusList.includes("UPLOADED" + ' ' + pushTotalList[j].createdmonth) && monthsList.includes(pushTotalList[j].createdmonth) && yearList.includes(pushTotalList[j].createdyear)) {
              statusList.push("UPLOADED" + ' ' + pushTotalList[j].createdmonth);
              pushTotalList.push({ createdmonth: pushTotalList[j].createdmonth, createdyear: pushTotalList[j].createdyear, count: 0, status: "UPLOADED", checkStatus: "UPLOADED" + ' ' + pushTotalList[j].createdmonth, "checkDate": pushTotalList[j].checkDate });
            }
            if (!statusList.includes("PROCESSED" + ' ' + pushTotalList[j].createdmonth) && monthsList.includes(pushTotalList[j].createdmonth) && yearList.includes(pushTotalList[j].createdyear)) {
              statusList.push("PROCESSED" + ' ' + pushTotalList[j].createdmonth);
              pushTotalList.push({ createdmonth: pushTotalList[j].createdmonth, createdyear: pushTotalList[j].createdyear, count: 0, status: "PROCESSED", checkStatus: "PROCESSED" + ' ' + pushTotalList[j].createdmonth, "checkDate": pushTotalList[j].checkDate });
            }
            if (!statusList.includes("CUSTOM FILE PROCESSED" + ' ' + pushTotalList[j].createdmonth) && monthsList.includes(pushTotalList[j].createdmonth) && yearList.includes(pushTotalList[j].createdyear)) {
              statusList.push("CUSTOM FILE PROCESSED" + ' ' + pushTotalList[j].createdmonth);
              pushTotalList.push({ createdmonth: pushTotalList[j].createdmonth, createdyear: pushTotalList[j].createdyear, count: 0, status: "CUSTOM FILE PROCESSED", checkStatus: "CUSTOM FILE PROCESSED" + ' ' + pushTotalList[j].createdmonth, "checkDate": pushTotalList[j].checkDate });
            }
            if (!statusList.includes("DELIVERY FAILED" + ' ' + pushTotalList[j].createdmonth) && monthsList.includes(pushTotalList[j].createdmonth) && yearList.includes(pushTotalList[j].createdyear)) {
              statusList.push("DELIVERY FAILED" + ' ' + pushTotalList[j].createdmonth);
              pushTotalList.push({ createdmonth: pushTotalList[j].createdmonth, createdyear: pushTotalList[j].createdyear, count: 0, status: "DELIVERY FAILED", checkStatus: "DELIVERY FAILED" + ' ' + pushTotalList[j].createdmonth, "checkDate": pushTotalList[j].checkDate });
            }
          }
        }
        if (date !== "0") {
          pushTotalList.sort((a, b) => { return a.checkStatus.localeCompare(b.checkStatus); });
        } else {
          pushTotalList.sort((a, b) => { return a.checkDate - b.checkDate });
        }
        var sortStatusList = statusList.sort()
        for (var k = 0; k < pushTotalList.length; k++) {
          for (var s = 0; s < sortStatusList.length; s++) {
            if (pushTotalList[k].status === "FAILED" && pushTotalList[k].checkStatus === statusList[s]) {
              failedList.push(pushTotalList[k].count);
            }
            if (pushTotalList[k].status === "PROCESSING" && pushTotalList[k].checkStatus === statusList[s]) {
              processingList.push(pushTotalList[k].count);
            }
            if (pushTotalList[k].status === "SUCCESS" && pushTotalList[k].checkStatus === statusList[s]) {
              successList.push(pushTotalList[k].count);
            }
            if (pushTotalList[k].status === "UPLOADED" && pushTotalList[k].checkStatus === statusList[s]) {
              uploadedList.push(pushTotalList[k].count);
            }
            if (pushTotalList[k].status === "PROCESSED" && pushTotalList[k].checkStatus === statusList[s]) {
              processedList.push(pushTotalList[k].count);
            }
            if (pushTotalList[k].status === "CUSTOM FILE PROCESSED" && pushTotalList[k].checkStatus === statusList[s]) {
              customFileProcessList.push(pushTotalList[k].count);
            }
            if (pushTotalList[k].status === "DELIVERY FAILED" && pushTotalList[k].checkStatus === statusList[s]) {
              deliveryFailedList.push(pushTotalList[k].count);
            }
          }
        }
        if (date === "0") {
          while (shift_count > 0) {
            shift_count -= 1;
            if (new Date(monthOrDateStore[0]) > new Date(monthOrDateStore[monthOrDateStore.length - 1])) {
              var first = failedList.shift()
              var second = monthOrDateStore.shift()
              failedList.push(first)
              monthOrDateStore.push(second)

              first = processingList.shift()
              processingList.push(first)
              first = successList.shift()
              successList.push(first)
              first = uploadedList.shift()
              uploadedList.push(first)
              first = processedList.shift()
              processedList.push(first)
              first = customFileProcessList.shift()
              customFileProcessList.push(first)
              first = deliveryFailedList.shift()
              deliveryFailedList.push(first)
            }
          }
        }
        setMonthOrDateCount(monthOrDateStore);
        setFailedCount(failedList);
        setProcessCount(processingList);
        setSuccessCount(successList);
        setUploadCount(uploadedList);
        setProcessedCount(processedList);
        setCustomFileProcessedCount(customFileProcessList);
        setDeliveryFailedCount(deliveryFailedList)
      }
    });

  }

  const chartData = {
    labels: ['Email' + '(' + sourceEmailCount + ')', 'SFTP' + '(' + sourceSftpCount + ')', 'Web' + '(' + sourceWebCount + ')'],
    datasets: [
      {
        data: sourceCount,
        backgroundColor: ["#4d5154", "#e40000", "#edeff1"],
        cutout: "76%",
      }]
  };

  const lightOptions = {
    legend: {
      display: false
    },
    tooltips: {
      backgroundColor: '#697A70',
      titleFontSize: 10,
      titleFontColor: '#0066ff',
      bodyFontColor: '#000',
      bodyFontSize: 8,
      tooltipTemplate: "<%if (label){%><%=label%>: <%}%><%= value %>hrs",
      displayColors: false,
      enabled: false
    },
    elements: {
      center: {
        maxText: '100%',
        text: '67%',
        fontColor: '#FF6684',
        fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        fontStyle: 'normal',
        // fontSize: 12,
        minFontSize: 1,
        maxFontSize: 256,
      }
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: '#495057'
        }
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem, data) {
            if (tooltipItem.label === "Email(" + sourceEmailCount + ")") {
              return "Email: " + tooltipItem.formattedValue;
            } else if (tooltipItem.label === "SFTP(" + sourceSftpCount + ")") {
              return "SFTP: " + tooltipItem.formattedValue;
            } else if (tooltipItem.label === "Web(" + sourceWebCount + ")") {
              return "Web: " + tooltipItem.formattedValue;
            }
          }
        }
      }

    }
  };

  const stackedData = {
    labels: monthOrDateCount,

    datasets: [

      {
        type: 'bar',
        label: 'Successfully Delivered',
        backgroundColor: '#6AF590',
        data: successCount,
        borderColor: '#fff',
        borderWidth: { top: 3, },
      },
      {
        type: 'bar',
        label: 'Uploaded',
        backgroundColor: '#FFC300',
        data: uploadCount,
        borderColor: '#fff',
        borderWidth: { top: 3, },
      },
      {
        type: 'bar',
        label: 'Processing',
        backgroundColor: '#DAF7A6', // 8de2e0
        data: processCount,
        borderColor: '#fff',
        borderWidth: { top: 3, },
      },
      {
        type: 'bar',
        label: 'Processed',
        backgroundColor: '#F56AD5',
        data: processedCount,
        borderColor: '#fff',
        borderWidth: { top: 3, },
      },
      {
        type: 'bar',
        label: 'Custom File Processed',
        backgroundColor: '#98F56A',
        data: customFileProcessedCount,
        borderColor: '#fff',
        borderWidth: { top: 3, },
      },
      {
        type: 'bar',
        label: 'Delivery Failed',
        backgroundColor: '#CE7777',
        data: deliveryFailedCount,
        borderColor: '#fff',
        borderWidth: { top: 3, },
      },
      {
        type: 'bar',
        label: 'Failed',
        backgroundColor: '#e40000', // e40000
        data: failedCount,
        borderColor: '#fff',
        borderWidth: { top: 3, },
      }
    ]
  };

  const missingStackedData = {
    labels: fileMissingpartnerCount.map((partner) => partner?partner.toString():""), // Convert labels to strings if necessary
    datasets: [
      {
        type: 'bar',
        backgroundColor: colors[7],
        data: fileMissingCountData,
        borderColor: '#fff',
        borderWidth: { top: 3 },
      },
    ],
  };

  let missedStackedOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    barPercentage: 0.9,
    plugins: {
      tooltips: {
        mode: 'index',
        intersect: false
      },
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#323232'
        },
        grid: {
          color: '#ebedef'
        }
      },
      y: {
        stacked: true,
        bar: 1,
        ticks: {
          color: '#323232',
          stepSize: 1,
          precision: 0
        },
        grid: {
          color: '#ebedef'
        },

      }
    }
  };



  let stackedOptions = {
    maintainAspectRatio: false,
    aspectRatio: .8,
    barPercentage: 0.9, // 0.35
    plugins: {
      tooltips: {
        mode: 'index',
        intersect: false
      },
      legend: {
        labels: {
          color: '#495057',
          boxWidth: 15,
          boxHeight: 15,
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#323232'
        },
        grid: {
          color: '#ebedef'
        }
      },
      y: {
        stacked: true,
        bar: 0.1,
        ticks: {
          color: '#323232'
        },
        grid: {
          color: '#ebedef'
        }
      }
    }
  };

  function findTotalCount(array, property) {
    const total = array.reduce((accumulator, object) => {
      return accumulator + object[property];
    }, 0);

    return total;
  }

  function initiateDownload(folderPath) {

    var file_name = folderPath
    file_name = CryptoJS.AES.encrypt(file_name, CryptoJS.enc.Utf8.parse(appConf.switch.aesKey), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString();
    dispatchDownloadAction({
      "fileName": file_name,
      "restorationDays": ""
    }, ({ data, message }) => {
      if (data["fileDownloadLink"]) {
        const link = document.createElement('a');

        link.href = data["fileDownloadLink"];
        link.target = "_self";
        link.setAttribute(
          'download',
          `${data['fileName']}`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else if (data["message"] && data["statusCode"] == 202) {
        console.log("You don't have permission to download an archived file. Please contact the support team");
      } else if (data["message"] && data["statusCode"] == 404 || data["statusCode"] == 500) {
        console.log(data["message"]);
      } else {
        console.log(data["message"]);
      }
    }, (message) => {
      console.log(message);
    });
  }

  return (
    <React.Fragment>
      <main>
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb__item">
              <a className="breadcrumb__link" href="/dashboard">Home</a>
            </li>
            <li className="breadcrumb__item">
              <a className="breadcrumb__link" href="/dashboard">Dashboard</a>
            </li>
          </ul>
          <div className="title">
          {(!isPartnerUser())?
            <div class="widget-container widget--box">

              <a class="widget widget --dashboard dashboard dashboardSelected" href={"/dashboard"}> <span><img src={DashboardIcon} alt="More" /></span> Dashboard</a>
              <a class="widget widget --dashboard dashboard dashboardNotSelected" href={"/filehealthDashboard"}><span><img src={FileHealthIcon} alt="More" /></span> File Health Monitor</a>
            </div>:
            <h1>Dashboard</h1>
            }
          </div>
          <br />
          <div className="form-row">
            
            <div className="col-lg-12 mb-4">

              <div className="widget widget--box widget--profile">
                <div class="head d-flex justify-between">
                User Profile
                  {/* <button className="button button--outline button--sm ml-0 is-active" onClick={(e) => window.location = '/fileTransferStatus'}>File Transfer Status</button> */}
                </div>
                <div className="widget__content d-flex">
                  <div className="widget--profile__info">
                    <div className="user-thumb">
                      <img src={UserImg} alt="User" />
                    </div>
                  </div>
                  <div className="widget--profile__details">
                    {(isPartnerUser() ? <div className="items">
                      <small>Partner Name</small>
                      <span>{partnerName}</span>
                    </div> :"")}
                    <div className="items">
                      <small>User Name</small>
                      <span>{userInfo.fullName}</span>
                    </div>
                    <div className="items">
                      <small>Email</small>
                      <span>{userInfo.email}</span>
                    </div>
                    <div className="items items--links">
                      <a className="link" href="/changepwd"><i className="fa fa-lock"></i><span className="text">Change Password</span></a>
                      {isCustomUser() ? '' : <a className="link" href="/fileTransferStatus"><i className="fa fa-file-export"></i><span className="text">File Transfer Status</span></a>}
                      {(isPartnerUser() && companyCode != 'EK') ?
                        <a className="link" href={"/fileMissingAlert"}><i className="fa fa-question-circle"></i><span className="text">Missing Files</span></a>
                        : ""}
                      {((isPartnerUser()) && (enablePgpDownload || enableSshDownload)) ?
                        <a className="link" onClick={() => setOpenModal(true)} href="javascript:void(0)"><i className="fa fa-key"></i><span className="text">Download Keys</span></a> : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          {(isAdmin() || isPartnerAdmin() || isOperationalUser()) ?
            <div className="form-row">
              {widgetBoxDataCount.map((element, i) => (
                <div className="col-lg-3 mb-4" key={i}>
                  <div className="widget widget--box widget--chart">
                    <div className="widget--chart__count">
                      <div className="icon icon--active">
                        <img src={
                          element.hubdashboard === "ActivePartners" ? ActiveImg :
                            element.hubdashboard === "PortalUsers" ? PortalImg :
                              element.hubdashboard === "ActiveChannels" ? ChannelImg :
                                element.hubdashboard === "FilesProcessedToday" ? ProcessImg : ""}
                          alt="Active" /> </div>
                      <div className="number">{element.total}</div>
                    </div>
                    <div className="widget--chart__details">
                      <div className="text">
                        {
                          element.hubdashboard === "ActivePartners" ? "Active partners" :
                            element.hubdashboard === "PortalUsers" ? "Web portal Users" :
                              element.hubdashboard === "ActiveChannels" ? "Channels" :
                                element.hubdashboard === "FilesProcessedToday" ? "Files Processed Today" : ""}
                      </div>
                      <a className="link link--arw" href={
                        element.hubdashboard === "ActivePartners" ? "/partner" :
                          element.hubdashboard === "PortalUsers" ? "/user" :
                            element.hubdashboard === "ActiveChannels" ? "/channels" :
                              element.hubdashboard === "FilesProcessedToday" ? "fileMonitoring" : "#"}>More Details
                        <i>
                          <img src={MoreIcon} alt="More" />
                        </i>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            :
            ""}

          {(isAdmin() || isPartnerAdmin() || isOperationalUser()) ?
            <div className="form-row">
              <div className="col-lg-6 mb-4">
                <div className="widget widget--box widget--list">
                  <div className="head">
                    <h2>File Processing Queues</h2>
                    <a className="refresh" onClick={() => getQueueCount()} style={{ cursor: "pointer" }}>
                      <i>
                        <img src={IconRefresh} alt="refresh" />
                      </i>
                    </a>
                  </div>
                  <div className="widget--list__head">
                    <span>Description</span>
                    <span>Count</span>
                  </div>
                  <ul>
                    <li>
                      <span className="tag tag-1">Q1</span>
                      <div className="info">
                        <span>Large File Handler Queue</span>
                        {/* <small className="date">25-12-2021 11:45</small> */}
                      </div>
                      <strong className="count">{queueLargeCount}</strong>
                    </li>
                    <li>
                      <span className="tag tag-2">Q2</span>
                      <div className="info">
                        <span>Medium File Handler Queue</span>
                        {/* <small className="date">25-12-2021 11:45</small> */}
                      </div>
                      <strong className="count">{queueMediumCount}</strong>
                    </li>
                    <li>
                      <span className="tag tag-3">Q3</span>
                      <div className="info">
                        <span>Small File Handler Queue</span>
                        {/* <small className="date">25-12-2021 11:45</small> */}
                      </div>
                      <strong className="count">{queueSmallCount}</strong>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <div className="widget widget--box widget--roundgraph">
                  <div className="head">
                    <h2>Files Processed Today</h2>
                  </div>
                  <div className="graph">
                    <div className="d-flex justify-content-center">
                      <Chart type="doughnut" data={chartData} options={lightOptions} plugins={lightOptions} style={{ position: 'relative', width: '73%' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
          }

          {(isAdmin())&& reportConf.ComponentForSRUnreadEmail && ((unreadEmailData && unreadEmailData.length >= 0) || (unProcessedEmailCountData && unProcessedEmailCountData.length > 0 )  ) ?
            <div className="form-row">
              <div className="col-lg-12 mb-4">
                <div className="widget widget--box widget--list">

                  <div className="head">
                    <h2>Unread Email Count</h2>
                  </div>
                  <div className="widget--list__head">
                    <span>Email</span>
                    <span>Count</span>
                  </div>
                  <ul>
                    {
                      unreadEmailData.map((data, i) => (
                        (i < 5 ? (
                          <li>
                            <span className="tag tag-1"><i class="fa fa-envelope"></i></span>
                            <div className="info">{data.email_address}
                            </div>
                            <strong className="count">{data.mail_count}</strong>
                          </li>) : '')
                      ))
                    }
                  </ul>
                 
                  {(isAdmin()) && companyCode != 'EK' && unProcessedEmailCountData && unProcessedEmailCountData.length >= 1 ?
                    <div class="unprocessed-email-count">
                      <br></br>
                      <div className="head">
                        <h2>SR Unprocessed Email Count</h2>
                      </div>
                      <div className="widget--list__head">
                        <span>Email</span>
                        <span>Count</span>
                      </div>
                      <ul>
                        {
                          unProcessedEmailCountData.map((data, i) => (
                            (i < 5 ? (
                              <li>
                                <span className="tag tag-1"><i class="fa fa-envelope"></i></span>
                                <div className="info">{data.mailbox}
                                </div>
                                <strong className="count">{data.unprocessed_emails_count}</strong>
                              </li>) : '')
                          ))
                        }
                      </ul>
                    </div>
                  :
                  ""
                  }
                  
                </div>
              </div>
            </div>
            :
            ""
          }
          {(isAdmin() || isPartnerAdmin() || isOperationalUser()) ?
            <div className="form-row">
              <div className="col-lg-4 mb-3">
                <div className="widget widget--box widget--list">
                  <div className="head ">
                    <h2>Missing File count</h2>
                    <a className="refresh" onClick={() => getMissingCount(filemissingPeriod)} style={{ cursor: "pointer" }}>
                      <i>
                        <img src={IconRefresh} alt="refresh" />
                      </i>
                    </a>
                  </div>
                  <div className="widget--list__head">
                    <span>Partner</span>
                    <span>Count</span>
                  </div>
                  <ul>
                    {(fileMissingPartners.length > 0 ? fileMissingPartners.map((data) => (
                      (
                        <li>
                          <span className="tag tag-1">{data.partner_code.substring(0, 2)}</span>
                          <div className="info">
                            <span>{data.partner_name}</span>
                            {/* <small className="date">25-12-2021 11:45</small> */}
                          </div>
                          <strong className="count">{data.file_count}</strong>
                        </li>))
                    ) : (filemissingPeriod == "Week") ? <li className="text-center text-danger">No files are missing this week</li> :
                      (filemissingPeriod == "Month") ? <li className="text-center text-danger">No files are missing this month</li> :
                        <li className="text-center text-danger">No files are missing today</li>)
                    }
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 mb-3">
                <div className="widget widget--box widget--bargraph">
                  <div className="head d-flex justify-between" >
                    {filemissingPeriod == "Week" ? <h2>Files missing this week</h2> : filemissingPeriod == "Month" ? <h2>Files missing this month</h2> : <h2>Files missing today</h2>}
                    <div className="graph-info">
                      <div className="display-by">
                        <strong>This :</strong>
                        <div className="input-wrap select-wrap">
                          <select id="display" style={{ cursor: "pointer" }} onChange={(e) => setFilemissingPeriod(e.target.value)}>
                            <option value="Month">Month</option>
                            <option value="Week">Week</option>
                            <option value="Day">Day</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <a className=" text-center link--arw" href={"/fileMissingAlert"}>More Details
                      <i>
                        <img src={MoreIcon} alt="More" />
                      </i>
                    </a>
                  </div>
                  <div className="graph">
                    <Chart type="bar" data={missingStackedData} options={missedStackedOptions} />
                  </div>
                </div>

              </div>
            </div>
            :
            ""
          }
          {isAdmin() || isPartnerAdmin() ?
            <div className="form-row">
              <div className="col-lg-12 mb-4">
                <div className="widget widget--box widget--bargraph">
                  <div className="head d-flex justify-between" >
                    <h2>File Processing Trends</h2>
                    <div className="graph-info">
                      <div className="display-by">
                        <strong>Display By :</strong>
                        <div className="input-wrap select-wrap">
                          <select id="display" style={{ cursor: "pointer" }} onChange={(e) => onChangeBarChartDetails(e)}>
                            <option value="0">Month</option>
                            <option value="7">Day</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="graph">
                    <Chart type="bar" data={stackedData} options={stackedOptions} />
                  </div>
                </div>
              </div>
            </div>
            :
            ""}


          {fileVolumeStatsViewEnabled && (isAdmin()) ?
            <div className="form-row">
              <div className="col-lg-12 mb-4">
                <div className="widget widget--box widget--bargraph widget--list">
                  <div className="head d-flex justify-between" >
                    <h2>File Volumes  &nbsp;<span style={{ fontSize: "small" }}>{currentDate}</span></h2>
                    <div className="graph-info"  >
                      <div className="display-by">
                        <strong>Filter By :</strong>
                        <div className="input-wrap select-wrap" >
                          <select id="display1" style={{ cursor: "pointer" }} onChange={(e) => assignFilterValue("filterBy", e.target.value)}>
                            <option selected={fileVolumeFilterRequest.filterBy === "Year" ? 'selected' : ''} value="Year">Year</option>
                            <option selected={fileVolumeFilterRequest.filterBy === "MonthAndYear" ? 'selected' : ''} value="MonthAndYear">Month and Year</option>
                          </select>
                        </div>
                      </div>
                      <div className="d-flex justify-between" >

                        <div className="graph-info display-by input-wrap select-wrap">
                          <select id="display3" style={{ cursor: "pointer" }} onChange={(e) => assignFilterValue("year", e.target.value)} >
                            {dropdown_yearList.map((data, i) => (

                              <option value={data} selected={fileVolumeFilterRequest.year === data ? 'selected' : ''} >{data}</option>
                            ))}
                          </select>
                        </div>
                        {fileVolumeFilterRequest.filterBy === 'MonthAndYear' ?
                          <div className=" display-by input-wrap select-wrap">
                            <select id="display2" style={{ cursor: "pointer" }} onChange={(e) => assignFilterValue("month", e.target.value)} >
                              {monthList.map((data, i) => (
                                <option value={data.value} selected={fileVolumeFilterRequest.month === data.value ? 'selected' : ''}>{data.label}</option>
                              ))}

                            </select>
                          </div>
                          :
                          ''}

                      </div>
                    </div>
                  </div>
                  <div className="table-responsive grid">
                    <table class="table">
                      <thead>
                        <tr>
                          <th style={{ "whiteSpace": "nowrap" }} scope="col" >Queue Name</th>
                          <th style={{ "whiteSpace": "nowrap" }} scope="col" >File size</th>
                          <th style={{ "whiteSpace": "nowrap" }} scope="col" >Number of files</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fileVolumeListData.map(data => (
                          <tr>
                            <td style={{ "whiteSpace": "nowrap" }}>{data.QueueName}</td>
                            <td style={{ "whiteSpace": "nowrap" }}>{formatFileSize(data.FilesSize)}</td>
                            <td style={{ "whiteSpace": "nowrap" }}>{data.NoOfFiles}</td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ "whiteSpace": "nowrap" }}><b>Total</b></td>
                          <td style={{ "whiteSpace": "nowrap" }}><b>{formatFileSize(findTotalCount(fileVolumeListData, "FilesSize"))}</b></td>
                          <td style={{ "whiteSpace": "nowrap" }}><b>{findTotalCount(fileVolumeListData, "NoOfFiles")}</b></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            :
            ""}

        </div>
      </main>
      <div class={`modal fade modal--monitoring ${openModal ? 'show' : ''}`} id="staticBackdrop" style={{ display: openModal ? 'block' : 'none' }}
        data-backdrop="static" data-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Download Keys</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={(e) => { setOpenModal(false); }}>
                <img src={iconClose} alt="Close" />
              </button>
            </div>
            <div class="modal-body">
              <div className="form-row">
                {(enablePgpDownload) ? <div className="col-lg-12 mb-4 d-flex justify-content-center">
                  <button type="button" class="button button--outline button--sm ml-0 is-active" onClick={(e) => { initiateDownload(enablePgpDownload); setOpenModal(false); }}>PGP KEY DOWNLOAD</button>
                  <span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} />
                    <i>
                      <img className="toolTip" data-pr-tooltip={"Download the PGP public key you should use while encrypting the file sent to PI-Hub"} data-pr-position="bottom" src={iconInfo} alt="info" />
                    </i></span>
                </div> : ""}
                {(enableSshDownload) ? <div className="col-lg-12 mb-4 d-flex justify-content-center">
                  <button type="button" class="button button--outline button--sm ml-0 is-active" onClick={(e) => { initiateDownload(enableSshDownload); setOpenModal(false); }}>SSH KEY DOWNLOAD</button>
                  <span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} />
                    <i>
                      <img className="toolTip" data-pr-tooltip={"Download the SSH public key you should use while connecting to PI-Hub"} data-pr-position="bottom" src={iconInfo} alt="info" />
                    </i></span>
                </div> : ""
                }
              </div>
            </div>
            {/* <div class="modal-footer">
                            <button type="button" class="button button--outline button--sm" data-dismiss="modal" onClick={(e) => { setOpenModal(false);}}>Cancel</button>
                            <button type="button" class="button button--sm" onClick={(e) => { sendInvalidateRequest(); setOpenModal(false); }}>Submit</button>
                        </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}

const mapDispatchToProps = dispatch => ({
  dispatchListPartnersAction: (onSuccess, onError) => dispatch(listPartners(onSuccess, onError)),
  dispatchWidgetBoxDataCount: (onSuccess, onError) => dispatch(fetchWidgetBoxDataCount(onSuccess, onError)),
  dispatchChartMonthDataCount: (onSuccess, onError) => dispatch(fetchChartMonthDataCount(onSuccess, onError)),
  dispatchSourceChartCount: (onSuccess, onError) => dispatch(fetchSourceChartCount(onSuccess, onError)),
  dispatchQueueChartCount: (onSuccess, onError) => dispatch(fetchQueueChartCount(onSuccess, onError)),
  dispatchDateMonthChartCount: (date, onSuccess, onError) => dispatch(fetchDateMonthChartCount(date, onSuccess, onError)),
  dispatchGetSrUnreadEmailReportData: (onSuccess, onError) => dispatch(getSrUnreadEmailReportData(onSuccess, onError)),
  dispatchGetSrUnprocessedEmailCountData: (onSuccess, onError) => dispatch(getSrUnprocessedEmailCount(onSuccess, onError)),
  dispatchFetchFileVolumeStatistics: (date, onSuccess, onError) => dispatch(fetchFileVolumeStatistics(date, onSuccess, onError)),
  dispatchMissingChartCount: (last, onSuccess, onError) => dispatch(fetchFileMissingStatistics(last, onSuccess, onError)),
  dispatchSystemParamsAction: (data, onSuccess, onError) => dispatch(fetchSystemParams(data, onSuccess, onError)),
  dispatchGetPartnerDetails: (partnerId, onSuccess, onError) => dispatch(fetchPartnerDetailsById(partnerId, onSuccess, onError)),
  dispatchDownloadAction: (data, onSuccess, onError) => dispatch(downloadFile(data, onSuccess, onError))
})

export default connect(null, mapDispatchToProps)(DashboardComponent)
