import * as constants from '../constants';
var CryptoJS = require("crypto-js");
var secretKey = require(process.env.REACT_APP_CONFIG_URLS);

export const loginUser = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'user/login',
        data,
        success: (response) => (setUserInfo(response)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const isAdmin = () => {
    const userInfos = sessionErrorCheck() !== null ? sessionErrorCheck() : window.location = '/auth';
    const userInfo = userInfos;
    const INITIAL_STATE = userInfo ? userInfo : defaultState;
    return INITIAL_STATE.role === "Administrator" ? true : false;
}
export const isOperationalUser = () => {
    const userInfos = sessionErrorCheck() !== null ? sessionErrorCheck() : window.location = '/auth';
    const userInfo = userInfos;
    const INITIAL_STATE = userInfo ? userInfo : defaultState;
    return INITIAL_STATE.role === "OperationsUser" ? true : false;
}
export const isPartnerUser = () => {
    const userInfos = sessionErrorCheck() !== null ? sessionErrorCheck() : window.location = '/auth';
    const userInfo = userInfos;
    const INITIAL_STATE = userInfo ? userInfo : defaultState;
    return INITIAL_STATE.role === "Partner" ? true : false;
}
export const isPartnerAdmin = () => {
    const userInfos = sessionErrorCheck() !== null ? sessionErrorCheck() : window.location = '/auth';
    const userInfo = userInfos;
    const INITIAL_STATE = userInfo ? userInfo : defaultState;
    return INITIAL_STATE.role === "PartnerAdmin" ? true : false;
}
export const isCustomUser = () => {
    const userInfos = sessionErrorCheck() !== null ? sessionErrorCheck() : window.location = '/auth';
    const userInfo = userInfos;
    const INITIAL_STATE = userInfo ? userInfo : defaultState;
    return INITIAL_STATE.role === "Custom" ? true : false;
}

export const getUserId = () => {
    const userInfos = sessionErrorCheck() !== null ? sessionErrorCheck() : window.location = '/auth';
    const userInfo = userInfos;
    return userInfo.userId;
}

export const sessionErrorCheck = () => {
    try {
        const userInfos = localStorage.getItem('USER_INFO');
        var decryptByte = CryptoJS.AES.decrypt(userInfos, secretKey.switch.secretKey);
        return JSON.parse(decryptByte.toString(CryptoJS.enc.Utf8));
    } catch (error) {
        localStorage.removeItem("USER_INFO");
        console.log("sessionErrorCheck catch  ",error)
        // return window.location = '/auth';
        return null;
    }
}

export function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const changePassword = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'user/changePwd',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const forgotPassword = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'user/forgotPwd',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const createUser = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'user/createUser',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const updateUser = (data, headers, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'user/updateUser',
        data,
        headers: headers,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})



export const deleteUser = (headers, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'user/deleteUser',
        headers: headers,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})


export const unlockUser = (headers, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'user/unlockuser',
        headers: headers,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})


export const newUserPasswordSetup = (headers, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'user/setUserPassword',
        headers: headers,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})


export const fetchAllUsers = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'user/fetchAllUsers',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})
export const partnerCount = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'partner/partnerCount',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const channelCount = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'channel/channelCount',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const schedulerCount = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'scheduler/schedulerCount',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const partnerOverrideCount = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'partnerOverride/partnerOverrideCount',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const userStatusCount = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'user/userCount',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const fetchRolesList = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'user/rolesList',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const fetchCacheKeys = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'onetimeparams/getAllCacheKeys',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const validateOtp = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'user/validateOtp?otp=' + data.otp + '&token=' + data.token,
        success: (response) => (setUserInfo(response)),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const resendOtp = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'user/resendOtp?token=' + data.token,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const validateCaptcha = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'user/recaptcha',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const deleteCache = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'rediscache/deletecachekey',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const logoutUser = () => ({

    type: constants.API,
    payload: {
        method: 'PUT',
        url: 'user/logout',
        success: () => (resetUserInfo())
    }

});

export const setPassword = (headers, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'PUT',
        url: 'user/setPwd',
        headers,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const listGroups = (onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'GET',
        url: 'group/findAllGroup',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const getGroupNames = (onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'GET',
        url: 'group/getGroupNames',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const createGroup = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'group/createGroup',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const modifyGroup = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'group/modifyGroup',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const deleteGroup = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'group/deleteGroup?groupCode=' + data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const findByGroupName = (groupName, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'group/findPartnersByGroupName?groupName=' + groupName,
        groupName,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

const setUserInfo = (data) => {
    const encryptedData = data.data;
    var parsedToken = null;

    if (encryptedData) {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Utf8.parse("ssh1234srsqa00ee"), {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            });
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            parsedToken = decryptedData;
        } catch (e) {
            console.log(e);
        };
    }

    if (parsedToken.authToken) {
        const userInfo = {
            userId: parsedToken.id,
            fullName: parsedToken.username,
            email: parsedToken.email,
            token: parsedToken.authToken,
            jwtToken: parsedToken.authToken,
            role: parsedToken.role,
            screens: parsedToken.screens,
            partnerId: parsedToken.partnerId,
            isLoggedIn: true,
            isSSOLogin: parsedToken.ssouser
        };
        var userInformation = CryptoJS.AES.encrypt(JSON.stringify(userInfo), secretKey.switch.secretKey).toString();

        localStorage.setItem('USER_INFO', userInformation);
        // localStorage.setItem('USER_INFO', window.btoa(JSON.stringify(userInfo)));

        // localStorage.setItem('USER_INFO', JSON.stringify(userInfo));
        return { type: constants.SET_USER_INFO, payload: userInfo };
    } else {
        return { type: "" };
    }
};

export const setToken = (data) => {
    const userInfo = sessionErrorCheck() !== null ? sessionErrorCheck() : window.location = '/auth';
    userInfo.token = data;
    var userInformation = CryptoJS.AES.encrypt(JSON.stringify(userInfo), secretKey.switch.secretKey).toString();
    localStorage.setItem('USER_INFO', userInformation);       
}

export const resetUserInfo = () => {
    localStorage.removeItem('USER_INFO');
    return { type: constants.RESET_USER_INFO };
}


export const listPartners = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'partner/listPartner',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const listActiveInactivePartners = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'partner/listActiveInactivePartners',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const deletePartners = (partnerId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'partner/deletePartner?partnerId=' + partnerId,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const changePartnerStatus = (partnerId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'partner/changeStatus?partnerId=' + partnerId,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const listTemplates = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'channel/listAllTemplate',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const listPartnersByTemplate = (templateId, groupType, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'channel/listPartnerByTemplate?groupType=' + groupType + '&templateID=' + templateId,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const listChannels = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'channel/findAllChannel',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const listAllBusinessFunctions = (companyCode, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'PUT',
        url: 'businessfunction/getBusinessFunctionNameByCompanyCode?companyCode='+companyCode,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const listAllPartnerTags = (companyCode, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'partnerTag/getPartnerTagByCompanyCode?companyCode='+companyCode,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const deleteChannel = (channelId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'channel/delete?channelId=' + channelId,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const getChannelFrequency = (channelId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'channel/channelFrequency?channelId=' + channelId,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const changeChannelStatus = (channelId, status, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'channel/changeStatus?channelId=' + channelId + '&status=' + status,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const createChannel = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'channel/create',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const editChannel = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'channel/modify',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});



export const isPartnerCodeExist = (partnerCode, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'partner/isPartnerCodeExist?partnerCode=' + partnerCode,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const createPartner = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'partner/createPartner',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const createMapping = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'fileMappingTemplate/createMapping',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const modifyAttachedChannels = (channels,mappingId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'fileMappingTemplate/modifyAttachedChannels?Id='+mappingId+'&Attached='+channels,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const modifyMapping = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'fileMappingTemplate/modifyMapping',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const deleteMapping = (mappingId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'fileMappingTemplate/deleteMapping?Id='+mappingId,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const getMappingConfig = (mappingId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'fileMappingTemplate/getMappingConfig?Id='+mappingId,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const getCountryList = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'partner/countryList',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchOneTimeParams = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        data: data,
        url: 'onetimeparams/fetchParams',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchSystemParams = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        data: data,
        url: 'systemparams/fetchParams',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fileUpload = (data, onSuccess, onError, uploadProgress) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'fileupload/upload',
        data,
        onUploadProgress: (val) => { uploadProgress(val) },
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const UploadPublicKey = (data, onSuccess, onError, uploadProgress) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'fileupload/uploadPublicKey',
        data,
        onUploadProgress: (val) => { uploadProgress(val) },
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchPartnerDetailsById = (partnerId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'partner/findPartnerByID?partnerId=' + partnerId,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})
export const updatePartner = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'partner/modifyPartner',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const updateMapping = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'fileMappingTemplate/modifyMapping',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});




export const findUserByUsernameAndEmail = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'user/listUserByUsernameAndEmail',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const getPublicKeyList = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'managepgpkey/publicKeyList',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fileMonitorSearch = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'file-monitoring/search',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const getFileMonitoringDetailsById = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'GET',
        url: 'file-monitoring/fetchDetailsById?id=' + data,
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const downloadFile = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'file-monitoring/download',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const templateCreation = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'template/createTemplate',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const getTemplateDetailsList = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'template/findAllTemplate',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const deleteTemplate = (templateId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'template/deleteTemplate?templateId=' + templateId,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const fetchTemplateExportData = (templateId,onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'exportimport/exportTemplate?templateId='+templateId,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const downloadPublicKey = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'file-monitoring/download',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const fetchTemplateDetailsById = (templateId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'template/findByTemplateId?templateId=' + templateId,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const templateNameIsPresent = (templateName, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'template/templateNameIsExist?templateName=' + templateName,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})


export const templateModification = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'template/modifyTemplate',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const generateKeypair = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'managepgpkey/generateKeyPair',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const uploadKeypair = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'managepgpkey/uploadKeyPair',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const getKeypairList = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'managepgpkey/keyPairList',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const deletePgpKey = (keyId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'managepgpkey/deletePublicKey?hubkeyId=' + keyId,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const fetchWidgetBoxDataCount = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'dashboard/dashboardCardCount',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const fetchChartMonthDataCount = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'dashboard/fetchGraphDataMonthWise',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const fetchChartDateDataCount = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'dashboard/fetchGraphDataByDate?date=7',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const fetchQueueChartCount = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'dashboard/listQueueChartCount',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const fetchDateMonthChartCount = (date, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'dashboard/fetchGraphDataByDateAndMonth?date=' + date,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const fetchSourceChartCount = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'dashboard/listSourceChartCount',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const statisticsForSR = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'onetimeparams/srEmailCount',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const createScheduler = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'scheduler/create',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});
export const createPartnerOverride = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'partnerOverride/create',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const displaySchedulerData = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'scheduler/schedulerList',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const displayOverrideTableData = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'partnerOverride/partnerOverrideList',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const deleteSchedulerData = (payloadObj, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'scheduler/delete?schedulerId=' + payloadObj.id + '&status=' + payloadObj.status,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const deleteOverrideTableData = (configId, status, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'partnerOverride/delete?configId=' + configId + '&status=' + status,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const editOverrideTableData = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'partnerOverride/modify',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});
export const editSchedulerData = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'scheduler/modify',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const deleteFileMonitoringRecords = (monitoringId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'file-monitoring/deleteFileMonitoringRecordsById?monitoringId=' + monitoringId,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})


export const retryFileProcessing = (monitoringId, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'GET',
        url: 'file-monitoring/retryFileProcess?monitoringId=' + monitoringId, 
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const getDownloadFailedReportData = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'report/downloadFailedRecords',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const downloadFailedRecordsAsExcelFile = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'report/downloadFailedRecordsAsExcelFile',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})


export const downlaodAuditReportAsExcelFile = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'file-monitoring/downloadAuditRecords',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const lastFailedDownloadOccursAt = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'report/lastFailedDownloadOccursAt',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})


export const getSrUnreadEmailReportData = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'onetimeparams/srUnreadEmailCount',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const downloadClogReportAsExcelFile = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'file-clog-report/search',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const failedCloggedFileCount = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'file-clog-report/failedCloggedFileCount',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const getSrUnprocessedEmailCount = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'file-clog-report/srUnprocessedEmailCount',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})


export const getUploadReportData = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'report/uploadFailedRecords',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }

})
 
export const downloadUploadFailedRecordsAsExcelFile = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'report/downloadUploadFailedRecordsAsExcelFile',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})


export const downloadHandbackReportData = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'report/generatePointsToHandbackMappingData',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const getAllActivePartnerCodeList = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'partner/getAllActivePartnerCodeList',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})


// Onetimeparams

export const displayOneTimeParamsTableData = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'onetimeparams/oneTimeParamsList',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const createOneTimeParams = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'onetimeparams/create',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const editOneTimeParamsTableData = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'onetimeparams/modify',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});



export const displaySystemParamsTableData = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'systemparams/systemParamsList',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const createSystemParams = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'systemparams/create',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});



export const editSystemParamsTableData = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'systemparams/modify',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const getAllActiveChannelsList = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'channel/getAllActiveChannelsList',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})
export const createFilenameConfiguration = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'filenameconverter/create',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})


export const getAllFilenameConverterList = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'filenameconverter/listAllFilenameConverter',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})
export const getFilenameConverterInfo = (id, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'GET',
        url: 'filenameconverter/findByFilenameConverterID?filenameId=' + id, 
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const deleteByFilenameConverterID = (id, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'GET',
        url: 'filenameconverter/deleteByFilenameConverterID?filenameId=' + id, 
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const displaySequenceNumberExcoData = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'sequenceNumber/excoDetailList',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const displayMappingData = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'fileMappingTemplate/mappingList',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});





export const editSequenceNumberTableData = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'sequenceNumber/modify',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const auditReportForDownloadedFiles = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'report/auditReportForDownloadedFiles',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const channelSearch = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'channel/searchChannels',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchFileVolumeStatistics = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {  
        method: 'POST',
        url: 'dashboard/fetchFileVolumeStatistics',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchFileMissingStatistics = (last,onSuccess, onError) => ({

    type: constants.API,
    payload: {  
        method: 'GET',
        url: 'dashboard/fetchFileMissingStatistics?last='+ last,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const getAllUsers = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'user/getAllUsers',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const getAllLockedUsers = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'user/getAllLockedUsers',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});
export const changeUserState = (headers, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'user/changeUserState',
        headers: headers,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})


export const getLockandActivePartnerUsersCount = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'user/getLockandActivePartnerUsersCount',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


////filetransferstatus

export const fileTransferSearch = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'filetransfer-statusreport/fileTransferSearch',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

/////file missing alert

export const fileMissingSearch = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'filemissingalert/fileMissingAlertSearch',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const displayFileMissingAlert = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'filemissingalert/fileMissingAlertList',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});



// export const  displayfileMissingAlertNotificationList= (data,onSuccess, onError) => ({
//     type: constants.API,
//     payload: {
//         method: 'POST',
//         url: 'filemissingalert/fileMissingAlertNotificationList',
//         data,
//         postProcessSuccess: onSuccess,
//         postProcessError: onError
//     }
// });


export const displayfileMissingAlertNotificationList = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'GET',
        url: 'filemissingalert/fileMissingAlertNotificationList?fileMissingTrackId=' + data,
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const closeIssueButton = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'filemissingalert/closeIssue',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});



export const webPortalLoginReportForDownloadedFiles = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'report/webPortalLoginReportForDownloadedFiles',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const createSSHKeygen = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'sshkeygen/generateSshKeyPair',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const listSSHKeygen = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'sshkeygen/getSSHDetails',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const deleteSshKey = (keyId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'sshkeygen/deleteSshKeyPair?hubkeyId=' + keyId,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
})

export const displaySchedulerStatusData = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'scheduler/schedulerStatusList',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const idleChannelsAndPartnersReport = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'report/idleChannelsAndPartnersReport',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const listEncryptKeys = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'managepgpkey/publicKeysForEncrypt',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const listDecryptKeys = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: 'managepgpkey/privateDecryptKeyList',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fileEncryptDecryptAction = (data,onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'fileupload/encryptDecryptFile',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const listGroupDetails = (data,onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'filehealthDashboard/groupDetails',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const channelFileDetails = (data,onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'filehealthDashboard/fetchChannelFileDetails',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const noChannelFileDetails = (data,onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'filehealthDashboard/fetchNoChannelFileDetails',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const failedFileDetails = (data,onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: 'filehealthDashboard/fetchFailedFileDetails',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});



export const getexportChannelDetails = (channelId, onSuccess, onError) => ({
    
    type: constants.API,
    payload: {
        method: 'GET', 
        url: `exportimport/exportChannel?channelId=${channelId}`,
        //url: 'exportimport/exportChannel?channelId=' + channelId,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});


export const getexportPartnerDetails = (partnerId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: `exportimport/exportPartner?partnerId=${partnerId}`,
        //url: 'exportimport/exportPartner?partnerId=' + partnerId,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const partnerFileSizeDetailsReport = (data, onSuccess, onError) => ({

    type: constants.API,
    payload: {
        method: 'POST',
        url: 'report/partnerFileSizeDetailsReport',
        data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});