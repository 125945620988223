import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import AlertFailure from '../utils/alert-failure.component';
import AlertSuccess from '../utils/alert-success.component';
import iconInfo from '../assets/images/icon-info-round.png';
import iconRetry from '../assets/images/icon-retry.png';
import { confirmDialog } from 'primereact/confirmdialog';
import delete_icon from '../assets/images/icon-delete.png';
// import edit_icon from '../assets/images/icon-edit.png';
// import iconEdit from '../assets/images/icon-edit.png';
import iconEdit from '../assets/images/icon-edit.png';

import { Tooltip } from 'primereact/tooltip';
var CryptoJS = require("crypto-js");
var secretKey = require(process.env.REACT_APP_CONFIG_URL);
import CustomDropdownWidget from './dropdown/CustomDropdownWidget';
import { listPartners, fetchOneTimeParams, editOverrideTableData, displayOverrideTableData, deleteOverrideTableData, createPartnerOverride, isJson, partnerOverrideCount } from '../redux/actions/auth.action.creators';
import * as constants from '../redux/constants'
import { data } from 'jquery';


const PartnerOverrideScreenComponent = ({ dispatchListPartnersAction, dispatchEditPartnerOverride, dispatchOverrideTableData, dispatchDeleteOverrideTableData, dispatchOneTimeParamsAction, dispatchCreatePartnerOverride, dispatchPartnerOverrideCount }) => {
    const userInfos = localStorage.getItem('USER_INFO');
    var decryptByte = CryptoJS.AES.decrypt(userInfos, secretKey.switch.secretKey);
    const userInfo = localStorage.getItem('USER_INFO') === null ? null : (!isJson(localStorage.getItem('USER_INFO')) ? JSON.parse(decryptByte.toString(CryptoJS.enc.Utf8)) : null);
    const [overrideTableList, setOverrideTableList] = useState({ "loading": true, "data": [] });
    const [partnerList, setPartnerList] = useState([]);

    const [filterOverrideTableList, setFilterOverrideTableList] = useState([]);
    const [overrideSearchKey, setOverrideSearchKey] = useState('');
    const [sortDirection, setSortDirection] = useState({ "partner_code": '', "operation_type": '', "attribute_name": '', "attribute_value": '', "operation": '', "operation_value": '' });
    const [editTempVal, setEditTempVal] = useState({});
    const [editVal, setEditVal] = useState({});
    const [incrementValue, setIncrementValue] = useState(1);
    const [failMsg, setFailMsg] = useState('');
    const [statusRefresh, setStatusRefresh] = useState(true)
    const [succMsg, setSuccMsg] = useState('');
    const [createAndUpdateStatus, setCreateAndUpdateStatus] = useState("");
    const [constrains, setConstrains] = useState({ "fieldPlaceholder": "Select List", "isMandatory": true, "fieldLabel": "Partner Code", "inputErrorMessage": "" });
    const [constrainsAttr, setConstrainsAttr] = useState({ "fieldPlaceholder": "Select Attribute", "isMandatory": true, "fieldLabel": "Attribute Name", "inputErrorMessage": "" });
    const [constrainsOppr, setConstrainsOppr] = useState({ "fieldPlaceholder": "Select Operation ", "isMandatory": true, "fieldLabel": "Operation", "inputErrorMessage": "" });
    const [constrainsOpprType,] = useState({ "fieldPlaceholder": "Select Operation Type", "isMandatory": true, "fieldLabel": "Operation Type", "inputErrorMessage": "" });
    const [responseData, setResponseData] = useState("false");
    const [responseAttr, setResponseAttr] = useState("false");
    const [responseOppr, setResponseOppr] = useState("false");
    const [responseOpprType, setResponseOpprType] = useState("false");
    const [status, setStatus] = useState("");
    const [configId, setConfigId] = useState("");
    const [partnerId, setPartnerId] = useState("");//new
    const [errorDetails, setErrorDetails] = useState({ "errorPartnerCode": "", "errorOperationType": "", "errorAttributeName": "", "errorAttributeValue": "", "errorOperation": "", "errorOperationValue": "" })
    const [requestBody, setRequestBody] = useState({ "partnerId": "", "partnerCode": "", "operationType": "", "operation": "", "opeartionValue": "", "attributeName": "", "attributeValue": "", "status": "" });
    const [editInfo, setEditInfo] = useState(null)
    const [operationType, setOperationType] = useState({ "loading": true, "data": [] });
    const [attributeName, setAttributeName] = useState({ "loading": true, "data": [] });
    const [operation, setOperation] = useState({ "loading": true, "data": [] });
    const [dropdownRefresher, setdropdownRefresher] = useState(true);
    const [statusCount, setStatusCount] = useState({})
    const [operationType_edit, setOperationType_edit] = useState({});


    // const [operationType_edit, setOperationType_edit] = useState({ label: "", value: "" })
    const [partner_edit, setPartner_edit] = useState({ label: "", value: "" })
    const [operation_edit, setOperation_edit] = useState({ label: "", value: "" })
    const [attributeName_edit, setAttributeName_edit] = useState({ label: "", value: "" })

    // const maxLength = 30; // Set the maximum length before truncation

    const [maxLength,] = useState(constants.TRUNCATE_TEXT);




    useEffect(() => {
        dispatchOneTimeParamsAction({ "keyList": [constants.OPERATIONTYPES_OVERRIDE] }, ({ data, message }) => {
            setOperationType({ "data": data[constants.OPERATIONTYPES_OVERRIDE], "loading": false });
        }, (message) => {
            console.log(message);
        });
    }, [dispatchOneTimeParamsAction])

    useEffect(() => {
        dispatchOneTimeParamsAction({ "keyList": [constants.ATTRIBUTE_NAME_PARTNER_OVERRIDE] }, ({ data, message }) => {
            setAttributeName({ "data": data[constants.ATTRIBUTE_NAME_PARTNER_OVERRIDE], "loading": false });
        }, (message) => {
            console.log(message);
        });
    }, [dispatchOneTimeParamsAction])


    useEffect(() => {
        dispatchOneTimeParamsAction({ "keyList": [constants.OPERATION_PARTNER_OVERRIDE] }, ({ data, message }) => {
            setOperation({ "data": data[constants.OPERATION_PARTNER_OVERRIDE], "loading": false });
        }, (message) => {
            console.log(message);
        });
    }, [dispatchOneTimeParamsAction])

    useEffect(() => {
        dispatchListPartnersAction(({ data, message }) => {
            if (data) {
                // if (userInfo.role == 'Partner') {
                //     setPartnerList(data.filter(p => (p.partnerid == userInfo.partnerId)));
                // }
                // else {
                var pushData = [];
                for (let index = 0; index < data.length; index++) {
                    pushData.push({ "value": data[index].partnerid, "label": data[index].partnercode })
                }
                setPartnerList([{ "value": "", "label": "Select partner" }].concat(pushData));
                // }
            }
        },
            (message) => {
                console.log("partner list error--  ", message);
            });
        overrideTableDataList();
        console.log("partnerid", data)
    }, [statusRefresh])

    useEffect(() => {
        dispatchPartnerOverrideCount(({ data, message }) => {
            if (data) {
                setStatusCount(data)
            }
        }, (message) => { });
    }, [])

    //     useEffect(() => {dispatchOverrideTableData(({data, message}) => {
    //         if(data){
    //             setOverrideTableList(data);
    //         }
    //     },
    //     (message) => {
    //         // console.log("data",data)
    //         console.log("Override list error--  ",message);
    //     });
    // },[statusRefresh])

    const overrideTableDataList = () => {
        dispatchOverrideTableData(({ data, message }) => {
            console.log("data", data)
            if (data) {
                setOverrideTableList({ "loading": false, "data": data });
                setFilterOverrideTableList(data);
            }
        },
            (message) => {
                // console.log("data",data)
                console.log("Override list error--  ", message);
            });
    }
    const deleteOverrideTableData = (id, partner) => {
        confirmDialog({
            message: 'Do you want to delete this configuration for' + partner + '?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept() {
                dispatchDeleteOverrideTableData(id, 'D', ({ data, message }) => {
                    console.log("delete")
                    overrideTableDataList();
                }, (message) => {
                    console.log("error")
                    window.scrollTo(0, 0);
                });
            },
            reject() {

            },
            acceptLabel: "Confirm",
            rejectLabel: "Cancel"
        });
    }

    const changeStatusOverrideEntry = (data) => {
        var statusSet = ""
        if (data.status === 'A') {
            setStatusRefresh(statusRefresh ? false : true)
            statusSet = "I"
        } else {
            setStatusRefresh(statusRefresh ? false : true)
            statusSet = "A"
        }
        dispatchDeleteOverrideTableData(data.config_id, statusSet, ({ data, message }) => {
            // setStatusRefresh(statusRefresh ? false : true)
            overrideTableDataList();

        }, (message) => {
            window.scrollTo(0, 0);
        });
    }
    const clickSubmitDetails = () => {
        console.log("requestBody: ", requestBody)
        var request = new FormData();
        var operValue = (document.getElementById("operValue" + 0) || {}).value;
        var attValue = (document.getElementById("attValue" + 0) || {}).value;
        // var attName = (document.getElementById("attName"+0) || {}).value;
        if (responseOpprType.value === "" || responseOpprType.value === undefined) {
            setErrorDetails({ "errorPartnerCode": "", "errorOperationType": "Enter Operation type", "errorAttributeName": "", "errorAttributeValue": "", "errorOperation": "", "errorOperationValue": "" });
        } else if (responseData.value === undefined || responseData.value === "") {
            setErrorDetails({ "errorPartnerCode": "Enter Partner code", "errorOperationType": "", "errorAttributeName": "", "errorAttributeValue": "", "errorOperation": "", "errorOperationValue": "" });
        } else if (responseAttr.value === "" || responseAttr.value === undefined) {
            setErrorDetails({ "errorPartnerCode": "", "errorOperationType": "", "errorAttributeName": "Enter Attribute name", "errorAttributeValue": "", "errorOperation": "", "errorOperationValue": "" });
        } else if (attValue === "") {
            setErrorDetails({ "errorPartnerCode": "", "errorOperationType": "", "errorAttributeName": "", "errorAttributeValue": "Enter Attribute value", "errorOperation": "", "errorOperationValue": "" });
        } else if (responseOppr.value === "" || responseOppr.value === undefined) {
            setErrorDetails({ "errorPartnerCode": "", "errorOperationType": "", "errorAttributeName": "", "errorAttributeValue": "", "errorOperation": "Enter Operation", "errorOperationValue": "" });
        } else if (operValue == "") {
            setErrorDetails({ "errorPartnerCode": "", "errorOperationType": "", "errorAttributeName": "", "errorAttributeValue": "", "errorOperation": "", "errorOperationValue": "Enter Operation Value" });
        }
        else {
            setErrorDetails({ "errorPartnerCode": "", "errorOperationType": "", "errorAttributeName": "", "errorAttributeValue": "", "errorOperation": "", "errorOperationValue": "" });

            var pushValue = [];
            var pushOper = [];
            var status = "I"
            var pushopprAndattr = [];
            for (let index = 0; index < incrementValue; index++) {
                var attValue = (document.getElementById("attValue" + index) || {}).value;
                var operValue = (document.getElementById("operValue" + index) || {}).value;
                if (attValue !== undefined && operValue !== undefined) {
                    pushOper.push(operValue);
                    // pushOper.push(operValue.toUpperCase());
                    pushValue.push(attValue);
                    pushopprAndattr.push({ 'OperationValue': operValue, 'attributeValue': attValue });
                }
            }
            request.append("status", status)
            request.append("operation", responseOppr.value)
            request.append("operationType", responseOpprType.value)
            request.append("partnerId", responseData.value)
            request.append("attributeName", responseAttr.value)
            request.append("operationValue", pushOper.toString())
            request.append("attributeValue", pushValue.toString())
            if (createAndUpdateStatus === "UPDATE") {
                request.append("configId", configId)
                dispatchEditPartnerOverride(request, ({ data, message }) => {
                    overrideTableDataList();
                    // overrideTableList();
                    setSuccMsg("Configuration Updated successfully");
                    resetSubmitDetails();
                    window.scrollTo(0, 0);
                    setTimeout(() => {
                        setSuccMsg('')
                    }, 3000);
                },
                    (message) => {
                        console.log("request-message--  ", message)
                        window.scrollTo(0, 0);
                        setFailMsg(message);
                        setTimeout(() => {
                            setFailMsg('')
                        }, 3000);
                    });
            } else {
                dispatchCreatePartnerOverride(request, ({ data, message }) => {
                    overrideTableDataList();
                    setSuccMsg("Configuration created successfully");
                    resetSubmitDetails();
                    window.scrollTo(0, 0);
                    setTimeout(() => {
                        setSuccMsg('')
                    }, 3000);
                },
                    (message) => {
                        console.log("request-message-create-  ", message)
                        window.scrollTo(0, 0);
                        setFailMsg(message);
                        setTimeout(() => {
                            setFailMsg('')
                        }, 3000);
                    });
            }
        }



    }

    function editOverrideTableData(data) {
        window.scrollTo(0, 290);
        setCreateAndUpdateStatus("UPDATE");
        setStatus(data.status);
        setConfigId(data.config_id);
        document.getElementById("attValue" + 0).value = data.attribute_value;

        document.getElementById("operValue" + 0).value = data.operation_value;

        setOperationType_edit({ "label": data.operation_type, "value": data.operation_type })
        setPartner_edit({ "label": data.partner_code, "value": data.partner_code })
        setOperation_edit({ "label": data.operation, "value": data.operation })
        setAttributeName_edit({ "label": data.attribute_name, "value": data.attribute_name })
        // setRequestBody({ "partnerCode": partnerList.filter(p => (p.label == data.partner_code))[0].value, "operationType": data.operation_type, "operation": data.operation, "opeartionValue": data.operation_value, "attributeName": data.attribute_name, "attributeValue": data.attribute_value, "status": data.status });
        setResponseOpprType({ "label": data.operation_type, "value": data.operation_type });
        setResponseOppr({ "label": data.operation, "value": data.operation });
        setResponseData({ "label": data.partner_code, "value": partnerList.filter(p => (p.label == data.partner_code))[0].value });
        setResponseAttr({ "label": data.attribute_name, "value": data.attribute_name });

    }




    const resetSubmitDetails = () => {
        setdropdownRefresher(dropdownRefresher ? false : true)
        setStatus("");
        setStatusRefresh(statusRefresh ? false : true)
        setEditTempVal({});
        setOperationType_edit({});
        setPartner_edit({ "label": "", "value": "" });
        setOperation_edit({});
        setAttributeName_edit({});
        setResponseOpprType("false");
        setResponseOppr({});
        setResponseAttr({});
        setResponseData("false");
        setCreateAndUpdateStatus("");

        // setRequestBody({"partnerCode": data.partner_code, "operationType": data.operation_type, "operation":data.operation, "opeartionValue": data.operation_value, "attributeName": data.attribute_name , "attributeValue": data.attribute_value ,  "status": data.status });

        for (let index = 0; index < incrementValue; index++) {
            if (document.getElementById("deleteAttValue" + (index + 1)) !== null) {
                document.getElementById("deleteAttValue" + (index + 1)).remove();
                document.getElementById("deleteOperValue" + (index + 1)).remove();
                document.getElementById("deleteButton" + (index + 1)).remove();
            }
            if (document.getElementById("operValue" + index) !== null && document.getElementById("attValue" + index) !== null) {
                document.getElementById("attValue" + index).value = "";
                document.getElementById("operValue" + index).value = "";

            }
        }
        console.log("resetSubmitDetails")
    }

    useEffect(() => {
        let filtered = _.cloneDeep(overrideTableList.data);
        if (!overrideTableList.loading) {
            filtered = filterOverrideTableList.filter(c => {
                return (((c.partner_code == null || c.partner_code == '') ? false : c.partner_code.toLowerCase().includes(overrideSearchKey.toLowerCase()))
                    || ((c.operation_type == null || c.operation_type == '') ? false : c.operation_type.toLowerCase().includes(overrideSearchKey.toLowerCase()))
                    || ((c.attribute_name == null || c.attribute_name == '') ? false : c.attribute_name.toLowerCase().includes(overrideSearchKey.toLowerCase()))
                    || ((c.attribute_value == null || c.attribute_value == '') ? false : c.attribute_value.toLowerCase().includes(overrideSearchKey.toLowerCase()))
                    || ((c.operation == null || c.operation == '') ? false : c.operation.toLowerCase().includes(overrideSearchKey.toLowerCase()))
                    || ((c.operation_value == null || c.operation_value == '') ? false : c.operation_value.toLowerCase().includes(overrideSearchKey.toLowerCase())))
            });
        }
        setOverrideTableList({ "loading": false, "data": filtered });
    }, [overrideSearchKey])

    function sortFn(dir, sortArr, type) {
        let result = sortArr.sort((a, b) => {
            if (a[type] < b[type]) {
                return dir === "asc" ? -1 : 1;
            }
            if (a[type] > b[type]) {
                return dir === "asc" ? 1 : -1;
            }
            return 0;
        });
        console.log("result: ", result)
        console.log("override list: ", overrideTableList)
        return result;
    }

    function onSort(type) {
        console.log("type:", type)
        let dir = "asc";
        if (sortDirection[type] === "asc") {
            dir = "desc";
        }
        if (type === 'partner_code') {
            setSortDirection({ "partner_code": dir, "operation_type": sortDirection.operationType, "attribute_name": sortDirection.attribute_name, "attribute_value": sortDirection.attribute_value, "operation": sortDirection.operation, "operation_value": sortDirection.operation_value });
        } else if (type === 'operation_type') {
            setSortDirection({ "partner_code": sortDirection.partner_code, "operation_type": dir, "attribute_name": sortDirection.attribute_name, "attribute_value": sortDirection.attribute_value, "operation": sortDirection.operation, "operation_value": sortDirection.operation_value });
        } else if (type === 'attribute_name') {
            setSortDirection({ "partner_code": sortDirection.partner_code, "operation_type": sortDirection.operationType, "attribute_name": dir, "attribute_value": sortDirection.attribute_value, "operation": sortDirection.operation, "operation_value": sortDirection.operation_value });
        } else if (type === 'attribute_value') {
            setSortDirection({ "partner_code": sortDirection.partner_code, "operation_type": sortDirection.operationType, "attribute_name": sortDirection.attribute_name, "attribute_value": dir, "operation": sortDirection.operation, "operation_value": sortDirection.operation_value });
        } else if (type === 'operation') {
            setSortDirection({ "partner_code": sortDirection.partner_code, "operation_type": sortDirection.operationType, "attribute_name": sortDirection.attribute_name, "attribute_value": sortDirection.attribute_value, "operation": dir, "operation_value": sortDirection.operation_value });
        } else if (type === 'operation_value') {
            setSortDirection({ "partner_code": sortDirection.partner_code, "operation_type": sortDirection.operationType, "attribute_name": sortDirection.attribute_name, "attribute_value": sortDirection.attribute_value, "operation": sortDirection.operation, "operation_value": dir });
        }
        setOverrideTableList({ "loading": false, "data": sortFn(dir, overrideTableList.data, type) })
    }

    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';  //Truncate the text
        }
        return text;
    }

    const tooltipOptions = {
        position: 'bottom',
        tooltipEvent: 'hover',


    };

    // const truncateText = (text, maxLength) => {
    //     if (text.length <= maxLength) {
    //         return text;
    //     } else {
    //         return text.slice(0, maxLength) + '...';
    //     }
    // };



    console.log(overrideTableList, "overrideTableList");
    return (
        <React.Fragment>
            <main>
                <div class="container">
                    <ul class="breadcrumb">
                        <li class="breadcrumb__item">
                            <a class="breadcrumb__link" href="#">Configurations</a>
                        </li>

                        <li class="breadcrumb__item">
                            <span class="breadcrumb__link">Partner Override Config</span>
                        </li>
                    </ul>
                    <div class="title">
                        <h1>Partner Override Details</h1>
                    </div>
                    {failMsg ?
                        <AlertFailure message={failMsg} /> : ''}
                    {succMsg ?
                        <AlertSuccess message={succMsg} /> : ''}
                </div>
                <div class="grey-block">
                    <div class="container">
                        <div class="form mb-5">
                            <h2 class="mb-4">Add Partner Override</h2>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        {/* <label for="f1" class="input-label"> Partner</label> */}
                                        <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={partnerList} constrains={constrains} setResponseData={setResponseData} editValue={partner_edit}></CustomDropdownWidget>
                                        {errorDetails.errorPartnerCode !== "" ?
                                            <div className="error-message">{errorDetails.errorPartnerCode}</div>
                                            : ''}
                                    </div>
                                </div>


                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        {/* <label for={"operationtype"+0} class="d-block input-label">Operation Type<span style={{ color: 'red' }}>*</span></label> */}
                                        <div class="input-wrap date-wrap">
                                            <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={operationType.data} constrains={constrainsOpprType} setResponseData={setResponseOpprType} editValue={operationType_edit}></CustomDropdownWidget>
                                            {errorDetails.errorOperationType !== "" ?
                                                <div className="error-message">{errorDetails.errorOperationType}</div>
                                                : ''}
                                            <div id={"erroroperationtype" + 0}></div>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        {/* <label for={"Operation"+0} class="d-block input-label">Operation <span style={{ color: 'red' }}>*</span></label> */}
                                        <div class="input-wrap date-wrap">
                                            <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={operation.data} constrains={constrainsOppr} setResponseData={setResponseOppr} editValue={operation_edit}></CustomDropdownWidget>
                                            {errorDetails.errorOperation !== "" ?
                                                <div className="error-message">{errorDetails.errorOperation}</div>
                                                : ''}
                                            <div id={"errorOperation" + 0}></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for={"operValue" + 0} class="input-label">Operation Value <span style={{ color: 'red' }}>*</span>
                                            <span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTip" style={{ maxWidth: "768px" }} />
                                                <i>
                                                    <img className="toolTip" data-pr-tooltip="If operationtype 'EMAIL' and operation 'SEND_EMAIL', sample operationvalue is 'abc@email.com'.
                                                    
                                                   If operationtype 'S3' and operation 'PUSH_FILE', sample operationvalue is 'arn:aws:iam::xxxxxxxx:role/iam_role;s3_bucket_name;folderpath/;'.

                                                If operationtype 'S3' and operation 'PULL_FILE', sample operationvalue is 'arn:aws:iam::xxxxxxxx:role/iam_role;s3_bucket_name;folderpath/;DELETE;'.

                                               If operationtype 'SFTP' and operation 'SEND_NOTIFICATION', sample operationvalue is 'abc@email.com'" data-pr-position="right" src={iconInfo} alt="info" />
                                                </i>
                                            </span>
                                        </label>
                                        <div class="input-wrap date-wrap">
                                            <input type="text" class="txt" id={"operValue" + 0} placeholder="Operation Value" />
                                            {errorDetails.errorOperationValue !== "" ?
                                                <div className="error-message">{errorDetails.errorOperationValue}</div>
                                                : ''}
                                            <div id={"errorOperationValue" + 0}></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        {/* <label for={"attName"+0} class="d-block input-label">Attribute Name <span style={{ color: 'red' }}>*</span></label> */}
                                        <div class="input-wrap date-wrap">
                                            <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={attributeName.data} constrains={constrainsAttr} setResponseData={setResponseAttr} editValue={attributeName_edit}></CustomDropdownWidget>
                                            {errorDetails.errorAttributeName !== "" ?
                                                <div className="error-message">{errorDetails.errorAttributeName}</div>
                                                : ''}
                                            <div id={"errorAttName" + 0}></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for={"attValue" + 0} class="input-label">Attribute Value <span style={{ color: 'red' }}>*</span>
                                            <span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTip2" mouseTrack mouseTrackLeft={10} style={{ maxWidth: "768px" }} />
                                                <i>
                                                    <img className="toolTip2" data-pr-tooltip="If attribute name 'PATTERN', sample operationvalue is '^Car.+|^Home.+'.                                                                                                                                                                          
                                                       If attribute name 'SEND_NOTIFICATION', sample operationvalue is 'SEND_NOTIFICATION'.
                                                       If attribute name 'TIME_THRESHOLD', sample operationvalue is '15'" data-pr-position="right" src={iconInfo} alt="info" />
                                                </i>
                                            </span>
                                        </label>
                                        <div class="input-wrap date-wrap">
                                            {/* <input type="text" class="txt" id={"attValue" + 0} placeholder="Attribute Value" /> */}
                                            <textarea className='textarea textarea-resize' type="text" id={"attValue" + 0} placeholder="Attribute Value" />

                                            {errorDetails.errorAttributeValue !== "" ?
                                                <div className="error-message">{errorDetails.errorAttributeValue}</div>
                                                : ''}
                                            <div id={"errorAttValue" + 0}></div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>

                        <div class="btn-wrap btn-wrap--grp mt-5 justify-content-end">
                            <button class="button button--outline" onClick={() => resetSubmitDetails()}>Reset</button>
                            <button class="button" onClick={() => clickSubmitDetails()}>Submit</button>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="table-block">
                        <div class="table-block__head">
                            <h2>List of Partner Override
                                <span style={{ "fontWeight": "bold", "fontSize": "13px", "color": "#666" }} >
                                    &nbsp; [ Total: {statusCount.active_count + statusCount.inactive_count ? statusCount.active_count + statusCount.inactive_count : 0} &nbsp; Active: {statusCount.active_count ? statusCount.active_count : 0} &nbsp; Inactive: {statusCount.inactive_count ? statusCount.inactive_count : 0} ]
                                </span></h2>
                            <div class="txt-wrap">
                                <input type="text" class="txt" placeholder="Search..."
                                    value={overrideSearchKey} onChange={(e) => setOverrideSearchKey(e.target.value)} />
                                <i class="fa fa-search"></i>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive grid">
                        <table class="table" id="OverrideTable">
                            <thead>
                                <tr>
                                    <th scope="col" onClick={() => onSort('partner_code')} style={{ cursor: "pointer" }}>Partner <i className="fa fa-sort" aria-hidden="true"></i></th>
                                    <th scope="col" onClick={() => onSort('operation_type')} style={{ cursor: "pointer" }}>Operation Type <i className="fa fa-sort" aria-hidden="true"></i></th>
                                    <th scope="col" onClick={() => onSort('operation')} style={{ cursor: "pointer" }}>Operation <i className="fa fa-sort" aria-hidden="true"></i></th>
                                    <th scope="col" onClick={() => onSort('operation_value')} style={{ cursor: "pointer" }}>Operation Value <i className="fa fa-sort" aria-hidden="true"></i></th>
                                    <th scope="col" onClick={() => onSort('attribute_name')} style={{ cursor: "pointer" }}>Attribute Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                                    <th scope="col" onClick={() => onSort('attribute_value')} style={{ "wordWrap": "break-word" }}>Attribute Value <i className="fa fa-sort" aria-hidden="true"></i></th>

                                    <th scope="col" className="no-sort">Status</th>
                                    <th scope="col" className="no-sort">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(overrideTableList.loading || overrideTableList.data.length == 0) ? (<tr><td colSpan="6"><div className="text-center text-danger">No search results found</div></td></tr>) :
                                    overrideTableList.data.map(data => (
                                        <tr>
                                            <td>{data.partner_code}</td>
                                            <td>{data.operation_type}</td>
                                            <td>{data.operation}</td>
                                            <td>{data.operation_value}</td>
                                            <td>{data.attribute_name}</td>

                                            {/* <td>{truncateText(data.attribute_value, maxLength)}</td> */}
                                            {/* <td>
                                                    <Tooltip target=".truncated-text" content={data.attribute_value} options={tooltipOptions} />
                                                    <span className="truncated-text">
                                                        {truncateText(data.attribute_value, maxLength)}
                                                    </span>
                                                </td> */}

                                            <Tooltip target=".truncated-text" className="toolTipGroup" mouseTrack mouseTrackLeft={10} />
                                            <td className="truncated-text" data-pr-tooltip={data.attribute_value} data-pr-position="bottom"> {truncateText(data.attribute_value, maxLength)}</td>

                                            <td>
                                                <label class="switch" style={{ marginTop: '12px' }}>
                                                    <input type="checkbox" checked={data.status === "A" ? true : false} onChange={(e) => changeStatusOverrideEntry(data)} />
                                                    <span class="slider round" ></span>
                                                </label>
                                            </td>
                                            <td>
                                                <div className="table__action">
                                                    <a href="javascript:void(0);" className="utils utils--outline" onClick={(e) => editOverrideTableData(data)} style={{ cursor: 'pointer' }}><img src={iconEdit} alt="edit" /></a>
                                                    <a onClick={(e) => deleteOverrideTableData(data.config_id, data.partner_code)} className="utils utils--outline ml-2" style={{ cursor: 'pointer' }}><img src={delete_icon} alt="delete" /></a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
        </React.Fragment>

    )

}


const mapDispatchToProps = dispatch => ({
    dispatchListPartnersAction: (onSuccess, onError) => dispatch(listPartners(onSuccess, onError)),
    dispatchDeleteOverrideTableData: (id, status, onSuccess, onError) => dispatch(deleteOverrideTableData(id, status, onSuccess, onError)),
    dispatchOverrideTableData: (onSuccess, onError) => dispatch(displayOverrideTableData(onSuccess, onError)),
    dispatchOneTimeParamsAction: (data, onSuccess, onError) => dispatch(fetchOneTimeParams(data, onSuccess, onError)),
    dispatchCreatePartnerOverride: (data, onSuccess, onError) => dispatch(createPartnerOverride(data, onSuccess, onError)),
    dispatchEditPartnerOverride: (data, onSuccess, onError) => dispatch(editOverrideTableData(data, onSuccess, onError)),
    dispatchPartnerOverrideCount: (onSuccess, onError) =>
        dispatch(partnerOverrideCount(onSuccess, onError))

});

export default connect(null, mapDispatchToProps)(PartnerOverrideScreenComponent)