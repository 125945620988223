import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import close_icon from '../../assets/images/icon-close.png';
import * as constants from '../../redux/constants'
import { auditReportForDownloadedFiles } from '../../redux/actions/auth.action.creators'
import { DateRangePicker } from '../../utils/date-range-picker.component'
import { DownloadFileAsXLFromEncodedString } from '../../utils/DecodeToXL';

const ComponentForFileDownloadAuditReport = ({ dispatchAuditReportForDownloadedFiles }) => {

   const [isPopupEnable, setIsPopupEnable] = useState(false)
   const [dateRange, setDateRange] = useState(365);
   const [resetCalender, setResetCalender] = useState(false);
   const [errorsForDateFeild, setErrorsForDateFeild] = useState('');
   const [requestBody, setRequestBody] = useState({ "dateFrom": "", "dateTo": "" })
   const [dateResponseData, setDateResponseData] = useState("false");
   const [waiting, setWaiting] = useState(false)



   const waitingSpinLoader = () => {
      var ls_styles = {
         border: '5px solid red',
         borderRadius: '50%',
         borderTop: '5px solid grey',
         width: '30px',
         marginRight: '50px',
         marginBottom:'20px',
         height: '30px',
         animation: `downloadLoadingspin 1s linear infinite`
      };

      return <div><div style={ls_styles}></div><p>Downloading...</p></div>;
   }




   const reset = () => {
      setRequestBody({ "dateFrom": "", "dateTo": "" });
      setResetCalender(true);
      setTimeout(() => {
         setResetCalender(false)
      }, 1000);
   }



   const valueIsNull = (v1) => { return v1 === null || v1 === "" || v1.length < 1 ? true : false; }
   const valueIsNotNull = (v1) => { return v1 !== null && v1 !== "" ? true : false; }

   const isValid = () => {
      if (valueIsNull(requestBody.dateFrom)) {
         return false;
      } else if (valueIsNull(requestBody.dateTo)) {
         return false;
      } else if (valueIsNotNull(requestBody.dateFrom) && valueIsNotNull(requestBody.dateTo)) {
         var tempError = Math.ceil(Math.abs(new Date(requestBody.dateTo) - new Date(requestBody.dateFrom)) / (1000 * 60 * 60 * 24)) > dateRange ? "Maximum date range is " + dateRange : "";
         console.log(tempError);
         setErrorsForDateFeild(tempError)
         if (valueIsNull(tempError)) {
            return true;
         } else {
            return false;
         }
      }
   }

   const onSubmit = () => {
      if (isValid()) {
         console.log(requestBody);
         setWaiting(true)
         dispatchAuditReportForDownloadedFiles(requestBody, ({ data, message, status }) => {
            if (data && data.length > 1) {
               console.log("encoded String length :", data.length);
               DownloadFileAsXLFromEncodedString(data, constants.AUDIT_REPORT_DOWNLOADEDFILES);
            }
            setWaiting(false)
         }) 
      }
   }

   useEffect(() => {
      let dateTo = new Date();
      let dateFrom = new Date();
      if (dateResponseData != "false" && dateResponseData != null) {
         dateFrom = new Date(dateResponseData[0]);
         dateTo = new Date(dateResponseData[1]);
      } else dateFrom.setDate(dateTo.getDate() - (dateRange - 1));
      dateTo = dateTo.toLocaleDateString("en-CA") + " " + "23:59:59";
      dateFrom = dateFrom.toLocaleDateString("en-CA") + " " + "00:00:00";
      setRequestBody({ "dateFrom": dateFrom, "dateTo": dateTo })

   }, [dateResponseData])

   return (
      <div class="col-lg-4 mb-4">
         <div class="widget widget--box widget--report" data-toggle="modal" data-target="#filedownloadauditreport" id="rprt_faileddwnldaudit">
            <div class="widget--report__details" onClick={(() => { setIsPopupEnable(true) })}>
               <div class="widget--report__title">
                  <p><strong> File Download Audit Report</strong></p>
               </div>
               <div class="widget--report__desc">
                  <p> This report gives details of files downloaded  using the portal</p>
               </div>
            </div>
         </div>
         <div class="modal fade modal--addnew show" aria-modal="true" style={{ display: isPopupEnable ? 'block' : 'none' }} id="filedownloadauditreport" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel">
            <div class="modal-dialog modal-dialog-centered">
               <div class="modal-content">
                  <div class="modal-header">
                     <h5 class="modal-title" id="staticBackdropLabel"> File Download Audit Report</h5>
                     <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <img src={close_icon} alt="Close" />
                     </button>
                  </div>
                  <div class="modal-body">
                     <div class="form mb-5">
                        <div class="row">
                           <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                 <label for="f2" class="d-block input-label">Date<span style={{ color: 'red' }}>*</span></label>
                                 <div class="input-wrap date-wrap">
                                    <i class="far fa-calendar-alt"></i>
                                    <span className='spinner-fade' style={{ zIndex: 2000 }}>
                                       <DateRangePicker customRange={dateRange} setDateResponseData={setDateResponseData} reset={resetCalender} />
                                    </span>
                                    {errorsForDateFeild && errorsForDateFeild != "" ?
                                       (<div className="error-message">{errorsForDateFeild}</div>)
                                       : ('')}

                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
                  {waiting ?
                     <div class="modal-footer">
                       {waiting ? waitingSpinLoader() : ''}
                     </div>
                     :
                     <div class="modal-footer">
                        <button type="button" onClick={(() => { reset() })} class="button button--outline button--sm" data-dismiss="modal">Cancel</button>
                        <button type="button" onClick={(() => { onSubmit() })} class="button button--sm">Download Report</button>
                     </div>
                  }
               </div>
            </div>
         </div>
      </div>

   )
}


const mapDispatchToProps = dispatch => ({
   dispatchAuditReportForDownloadedFiles: (data, onSuccess, onError) =>
      dispatch(auditReportForDownloadedFiles(data, onSuccess, onError))

});

export default connect(null, mapDispatchToProps)(ComponentForFileDownloadAuditReport);