import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import '@fortawesome/fontawesome-free/js/all';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'font-awesome/css/font-awesome.min.css';
import '@fontsource/open-sans';
import './styles/scss/main.scss';
import App from './App';
import configureStore from './redux/configure.store';

import { setFavicon } from './utils/setFavicon';

setFavicon();   
const store = configureStore();

const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;
console.log(primaryColor)
if (primaryColor) {
  console.log(primaryColor)
  document.documentElement.style.setProperty('--primary-color', primaryColor);
}

function UnsecuredPage(){
    return (
        <div>
            <h2>Please inform the team with the reference of the application from where you clicked this link.</h2>
            <h2>Click <a href={window.self.location.href} title='File Exchange Portal' target='blank'>here</a> to access File Exchange Portal safely.</h2>
        </div>
        );
}
 

if(window.self === window.top) {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
        , document.getElementById('root'));
} else{ // Won't render the application if WebApp is under Clickjacking attack
    ReactDOM.render(<UnsecuredPage />, document.getElementById('root'));
}
