

// file structure configuration bean modal
export const FileStructureConfigurationModel = () => {
    return {
        filename_converter_id: 0,
        channel: "",
        partner_type: "",
        partner_name: "",
        to_file_structure: "",
        filename_converter_detail: [
            {
                content_type: "",
                content_value: "",
                variable_name: "temp",
                start: "",
                end: "",
                group_name: "",
                group_type: "",
                group_value: "",
                dataType: "",
                operation: "",
                widget_operation: "I",
                filename_converter_detail_Id: "",
            },
        ],
    };
};


export const dummyDataValue = [

];


export const monthList = [
    { label: "Choose Month", value: 0 },
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 }
]

