import * as XLSX from 'xlsx';
import React from 'react';

export const DownloadJsonAsExcel = (jsonData, outputFileName) => {
    //Modify data
    const modifiedData = jsonData.map(item => {
        const modifiedItem = {};
        for (const key in item) {
            const modifiedKey = key.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()); // Remove underscores and capitalize first letter of each word
            modifiedItem[modifiedKey] = item[key];
        }
        console.log(modifiedItem);

        return modifiedItem;
    });

    // Convert modified JSON data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(modifiedData);
    // Apply bold styling to header row

    const boldStyle = { font: { bold: true } };
    for (const key in worksheet) {
        if (key.startsWith('A1')) {
            const headerCell = worksheet[key];
            headerCell.s = boldStyle;
        }
    }
    // Automatically adjust column widths based on content
    // const columnWidths = [];
    // modifiedData.forEach(item => {
    //     for (const key in item) {
    //         const columnIndex = XLSX.utils.decode_col(XLSX.utils.encode_col(key));
    //         const cellContent = item[key] !== null && item[key] !== undefined ? item[key].toString() : ''; // Check if the value is null or undefined
    //         const contentWidth = cellContent.length * 1.2; // Adjust this multiplier according to your needs
    //         columnWidths[columnIndex] = Math.max(columnWidths[columnIndex] || 0, contentWidth);
    //     }
    // });

    // Set the column widths in the worksheet
    // worksheet['!cols'] = columnWidths.map(width => ({ width }));


    // Create a new workbook and add the worksheet to it
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Convert workbook to binary XLSX data
    const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });

    // Create a Blob from the binary XLSX data
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    // Create a download link for the Blob
    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = outputFileName + ".xlsx"; // Change file extension to .xlsx

    // Trigger file download
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // Revoke the Object URL to free up resources
    window.URL.revokeObjectURL(url);
};