import React, { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';
import _ from "lodash";
const appConf = require(process.env.REACT_APP_CONFIG_URL);

export const DateRangePicker = (props) => {
  const [dates, setDates] = useState(new Date(new Date().toLocaleString("en-US", {timeZone: appConf.TIMEZONE})), new Date(new Date().toLocaleString("en-US", {timeZone: appConf.TIMEZONE})));
  const [maxdate, SetMaxdate] = useState(new Date(new Date().toLocaleString("en-US", {timeZone: appConf.TIMEZONE})));

  useEffect(() => {
    if(props.defaultDates!=null){
      setDates(props.defaultDates);
    }else{
      setDates(new Date(new Date().toLocaleString("en-US", {timeZone: appConf.TIMEZONE})), new Date(new Date().toLocaleString("en-US", {timeZone: appConf.TIMEZONE})))
    }
  }, [])

  useEffect(() => {
    if (props.reset == true) {
      if (props.defaultDates != null) {
        setDates(props.defaultDates);
      } else {
        setDates([
          new Date(new Date().toLocaleString("en-US", { timeZone: appConf.TIMEZONE })),
          new Date(new Date().toLocaleString("en-US", { timeZone: appConf.TIMEZONE })),
        ]);
      }
    }
  }, [props.reset]);

  useEffect(() => {
    if (dates!=''){
      props.setDateResponseData(dates);
    }
    
  }, [dates])


  
  return (
    <Calendar className="input-date" baseZIndex="1051" id="range"
    showOnFocus="true" dateFormat="dd/mm/yy" value={dates} onChange={(e) => setDates(e.value)}
    selectionMode="range" readOnlyInput monthNavigator yearNavigator yearRange="2021:2060" hourFormat="24"
    maxDate={maxdate} placeholder="Select Date Range" />
  );
};

