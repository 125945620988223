import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PartnerProfileInputModel, PartnerProtocolModel, PartnerRoleModel, PartnerSSHInBoundModel, PartnerSSHOutBoundModel, PartnerUserDetailsModel } from '../../Bean/partner';

import PartnerOnboardingComponent from '../partnerOnboarding/partner.onboarding.component';
import PartnerUserDetailsComponent from '../partnerOnboarding/partner.userdetails.component';
import PartnerOnboardingRoleComponent from '../partnerOnboarding/partner.onboarding.role.component';
import PartnerOnboardingProtocolComponent from '../partnerOnboarding/partner.onboarding.protocol.component';
import PartnerOnboardingSummaryComponent from '../partnerOnboarding/partner.onboarding.summary.component';

import { fetchPartnerDetailsById } from '../../redux/actions/auth.action.creators';



const PartnerOnboardingHomeComponent = ({ dispatchGetPartnerDetails }) => {
    const [cursor, setCursor] = useState('')
    const [inputDataProfile, setInputDataProfile] = useState(PartnerProfileInputModel)
    const [partnerTagDropdownlist, setPartnerTagDropdownlist] = useState([])
    const [inputDataForUserDetails, setInputDataForUserDetails] = useState(PartnerUserDetailsModel)
    const [inputDataForRole, setInputDataForRole] = useState(PartnerRoleModel)
    const [inputDataForProtocol, setInputDataForProtocol] = useState(PartnerProtocolModel)

    const [sshInBound, setSSHInBound] = useState(PartnerSSHInBoundModel)
    const [sshOutBound, setSHHOutBound] = useState(PartnerSSHOutBoundModel)

    /* for ssh profile details loop render by add item*/
    const [spdElements, setSpdElements] = useState([{ f_permission: "", f_pattern: "", r_suffix: "", r_prefix: "", sshDetailsId: 0, sshId: 0, operationFlag: "I" }])

    const [updationStatus, setUpdationStatus] = useState(false)
    const [userIdForPartnerUpdate, setUserIdForPartnerUpdate] = useState(0)
    const [passwordUpdateFlag, setpasswordUpdateFlag] = useState(false)
    const [isAccessPortalValueFromDB, setIsAccessPortalValueFromDB] = useState(false)
    const [inOutPasswordUpdateFlag, setInOutPasswordUpdateFlag] = useState({ "inbnd": false, "outbnd": false })
    const [outboundAuthTypeFromDB, setOutboundAuthTypeFromDB] = useState('')
    const [inboundAuthTypeFromDB, setInboundAuthTypeFromDB] = useState('')


    useEffect(() => {
        var partnerId = window.location.pathname.replace('/partnerOnboarding/', "")
        if (partnerId !== "" && partnerId !== "new") {
            dispatchGetPartnerDetails(parseInt(partnerId), ({ data, message }) => {
                if (data !== null) {
                    setInputDataProfile({
                        "PartnerCode": data.partnerCode,
                        "PartnerID": data.partnerID,
                        "PartnerName": data.partnerName,
                        "Address": data.address,
                        "City": data.city,
                        "Country": data.country,
                        "Email": data.email,
                        "Mobile": data.phoneNumber,
                        "Pincode": data.pinCode,
                        "State": data.state,
                        "PartnerTag":data.partnerTag
                    })
                    setInputDataForUserDetails({
                        "ud_Username": data.userDetails[0].username,
                        "ud_Email": data.userDetails[0].email,
                        "ud_GivenName": data.userDetails[0].givenName,
                        "ud_SurName": data.userDetails[0].surName,
                        "ud_Password": "",
                        "ud_ConfirmPassword": "",
                        "isAccessPortal": data.userDetails[0].accessPortal,
                        "isSendOrReceiveEmails": data.sendReceiveFiles ? data.sendReceiveFiles : false,
                        "acceptEmails": data.acceptEmails,
                        "sendEmails": data.sendEmails,
                        "notifications":data.notifications
                    })
                    setUserIdForPartnerUpdate(data.userDetails[0].userId)
                    setIsAccessPortalValueFromDB(data.userDetails[0].accessPortal)
                    setInboundAuthTypeFromDB(data.sshProfileResponse.inboundAuthenticationType !== null ? data.sshProfileResponse.inboundAuthenticationType.trim() : "")
                    setOutboundAuthTypeFromDB(data.sshProfileResponse.outboundAuthenticationType !== null ? data.sshProfileResponse.outboundAuthenticationType.trim() : "")
                    setInputDataForRole({
                        "partnerRoleConsumer": data.partnerRoleConsumer,
                        "partnerRoleConsumerInitiate": data.partnerRoleConsumerInitiate,
                        "partnerRoleConsumerListen": data.partnerRoleConsumerListen,
                        "partnerRoleProducer": data.partnerRoleProducer,
                        "partnerRoleProducerInitiate": data.partnerRoleProducerInitiate,
                        "partnerRoleProducerListen": data.partnerRoleProducerListen,
                        "outboundConnectionType": data.outboundConnectionType,
                        "setMaxFileSize": data.setMaxFileSize,
                        "maxFileSize": data.maxFileSize
                    }),
                    setInputDataForProtocol({
                        "partnerSendFilesAsEncrypted": data.partnerSendFilesAsEncrypted,
                        "routerPublicKeyringDropdown": data.routerPublicKeyringDropdown,
                        "verifyEncryptedFile": data.verifyEncryptedFile,

                        "partnerRequireFilesToBeEncrypted": data.partnerRequireFilesToBeEncrypted,
                        "partnerProtocolUserId": data.partnerProtocolUserId,
                        "partnerProtocolEmail": data.partnerProtocolEmail,
                        "partnerPublicKeyringName": data.partnerPublicKeyringName,
                        "signFile": data.signFile, 
                        "sendPublicFileAsEmail": data.sendPublicFileAsEmail

                    })
                    setSSHInBound({
                        "sshInBoundAuthenticationType": data.sshProfileResponse.inboundAuthenticationType !== null ? data.sshProfileResponse.inboundAuthenticationType.trim() : "",
                        "userPublicKey": data.sshProfileResponse.inboundPublicKey,
                        "password": "",
                        "confirmPassword": ""
                    })
                    setSHHOutBound({
                        "remoteHost": data.sshProfileResponse.outboundRemoteHost,
                        "root":data.sshProfileResponse.outboundRoot,
                        "port": data.sshProfileResponse.outboundPort,
                        "knownHostKey": data.sshProfileResponse.outboundKnownHostKey,
                        "remoteUser": data.sshProfileResponse.outboundRemoteUser,
                        "downloadDirectory": data.sshProfileResponse.outboundDownloadDirectory,
                        "uploadDirectory": data.sshProfileResponse.outboundUploadDirectory,
                        "sshOutBoundAuthenticationType": data.sshProfileResponse.outboundAuthenticationType !== null ? data.sshProfileResponse.outboundAuthenticationType.trim() : "",
                        "sshOutBoundPassword": "",
                        "sshOutBoundConfirmPassword": "",
                        "shhOutUserIdentityKey": data.sshProfileResponse.outboundUserIdentityKey.replace("filehandler/application/sftp_keys/",''),
                        "responseTimeout": data.sshProfileResponse.outboundResponseTimeout,
                        "characterEncoding": data.sshProfileResponse.outboundCharacterEncoding,
                        "preferredCipher": data.sshProfileResponse.outboundPreferredCipher,
                        "preferredMAC": data.sshProfileResponse.outboundPreferredMAC,
                        "compression": data.sshProfileResponse.outboundCompression,
                        "connectionRetryCount": data.sshProfileResponse.outboundConnectionRetryCount,
                        "retryDelay": data.sshProfileResponse.outboundRetryDelay,
                        "localPortRange": data.sshProfileResponse.outboundLocalPortRange,
                        "passPhrase":data.sshProfileResponse.passPhrase,
                        "bucketname": data.sshProfileResponse.s3OutboundBucket,
                        "region": data.sshProfileResponse.s3OutboundRegion,
                        "folderpath": data.sshProfileResponse.s3OutboundFolderPath,
                        "uploadFolderPath":data.sshProfileResponse.s3OutboundUploadFolderPath,
                        "s3OutboundIAMRole":data.sshProfileResponse.s3OutboundIAMRole
                    })
                    setUpdationStatus(true)
                    console.log(data.sshProfileDetailsList.length);
                    if (data.sshProfileDetailsList && data.sshProfileDetailsList.length) {
                        var sshProfileDetailsList = data.sshProfileDetailsList;
                        var tempArray = []
                        var tempJSON = {}
                        sshProfileDetailsList.forEach(element => {
                            tempJSON = { f_permission: element.filePermission, f_pattern: element.filePattern, r_suffix: element.renameSuffix, r_prefix: element.renamePrefix, sshDetailsId: element.sshDetailsId, sshId: element.sshId, operationFlag: "M" }
                            tempArray.push(tempJSON)
                        });
                        setSpdElements(tempArray)
                    }
                }
            })
        }
    }, [])


    switch (cursor) {
        case "Partner_UserProfile":
            return <PartnerUserDetailsComponent isAccessPortalValueFromDB={isAccessPortalValueFromDB} passwordUpdateFlag={passwordUpdateFlag} setpasswordUpdateFlag={setpasswordUpdateFlag} updationStatus={updationStatus} setCursor={setCursor} inputDataProfile={inputDataProfile} inputDataForUserDetails={inputDataForUserDetails} setInputDataForUserDetails={setInputDataForUserDetails} />
        case "Partner_Role":
            return <PartnerOnboardingRoleComponent spdElements={spdElements} setSpdElements={setSpdElements} isAccessPortalValueFromDB={isAccessPortalValueFromDB} inboundAuthTypeFromDB={inboundAuthTypeFromDB} outboundAuthTypeFromDB={outboundAuthTypeFromDB} inOutPasswordUpdateFlag={inOutPasswordUpdateFlag} setInOutPasswordUpdateFlag={setInOutPasswordUpdateFlag} updationStatus={updationStatus} setCursor={setCursor} sshInBound={sshInBound} setSSHInBound={setSSHInBound} sshOutBound={sshOutBound} setSHHOutBound={setSHHOutBound} inputDataProfile={inputDataProfile} inputDataForUserDetails={inputDataForUserDetails} inputDataForRole={inputDataForRole} setInputDataForRole={setInputDataForRole} />
        case "Partner_Protocol":
            return <PartnerOnboardingProtocolComponent updationStatus={updationStatus} setCursor={setCursor} inputDataForProtocol={inputDataForProtocol} setInputDataForProtocol={setInputDataForProtocol} />
        case "Partner_Summary":
            return <PartnerOnboardingSummaryComponent spdElements={spdElements} setSpdElements={setSpdElements} inOutPasswordUpdateFlag={inOutPasswordUpdateFlag} passwordUpdateFlag={passwordUpdateFlag} setpasswordUpdateFlag={setpasswordUpdateFlag} userIdForPartnerUpdate={userIdForPartnerUpdate} updationStatus={updationStatus} setCursor={setCursor} sshInBound={sshInBound} sshOutBound={sshOutBound} inputDataProfile={inputDataProfile} inputDataForUserDetails={inputDataForUserDetails} inputDataForRole={inputDataForRole} inputDataForProtocol={inputDataForProtocol} />
        default:
            return <PartnerOnboardingComponent updationStatus={updationStatus} setCursor={setCursor} inputDataProfile={inputDataProfile} setInputDataProfile={setInputDataProfile} partnerTagDropdownlist={partnerTagDropdownlist} setPartnerTagDropdownlist = {setPartnerTagDropdownlist} />
    }

};

const mapDispatchToProps = dispatch => ({
    dispatchGetPartnerDetails: (partnerId, onSuccess, onError) =>
        dispatch(fetchPartnerDetailsById(partnerId, onSuccess, onError))
})

export default connect(null, mapDispatchToProps)(PartnerOnboardingHomeComponent)