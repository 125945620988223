import * as constants from '../constants';
import { isJson, sessionErrorCheck } from '../actions/auth.action.creators';
var CryptoJS = require("crypto-js");
var secretKey = require(process.env.REACT_APP_CONFIG_URLS);

const defaultState = {
    userId: null,
    fullName: null,    
    email: null,
    token: null,
    jwtToken: null,
    role: null,
    screens: null,
    partnerId: null,
    isLoggedIn: false,
    // isRefreshToken: false
};

const userInfos = sessionErrorCheck();
// const userInfos = localStorage.getItem('USER_INFO');
// var decryptByte = userInfos === null ? null : CryptoJS.AES.decrypt(userInfos, secretKey.switch.secretKey);
// const userInfo = decryptByte === null ? null : JSON.parse(decryptByte.toString(CryptoJS.enc.Utf8));
// const userInfo = localStorage.getItem('USER_INFO') === null ? null : window.atob(localStorage.getItem('USER_INFO'));
// const INITIAL_STATE = userInfo ? JSON.parse(userInfo) : defaultState;
const userInfo = userInfos;
// const userInfo = localStorage.getItem('USER_INFO') === null ? null : (!isJson(localStorage.getItem('USER_INFO')) ? JSON.parse(decryptByte.toString(CryptoJS.enc.Utf8)) : null) ;
const INITIAL_STATE = userInfo ? userInfo : defaultState;

export default function userReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case constants.SET_USER_INFO:
            return { ...action.payload };
        case constants.RESET_USER_INFO:
            return { ...defaultState };
        // case constants.SET_REFRESH_TOKEN:
        //     return { ...action.payload };
        default:
            return state;
    }
};
