import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import iconDelete from '../assets/images/icon-delete.png';
import iconClose from '../assets/images/icon-close.svg';
import _ from "lodash";
import AlertFailure from '../utils/alert-failure.component';
import AlertSuccess from '../utils/alert-success.component';
import { confirmDialog } from 'primereact/confirmdialog';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { generateKeypair, getKeypairList, deletePgpKey, downloadFile, uploadKeypair, isAdmin } from '../../src/redux/actions/auth.action.creators';
var appConf  = require(process.env.REACT_APP_CONFIG_URL);
var CryptoJS = require("crypto-js");
import iconExport from '../assets/images/icon-export.png';
import iconExcel from '../assets/images/icon-excel.png';
import { DownloadListAsExcel } from '../utils/list-export-excel';
const PGPConfigurationComponent = ({ dispatchListKeypairAction, dispatchDownloadAction, dispatchgenerateKeypairAction, dispatchDeleteKeyAction, dispatchUploadKeypairAction }) => {

    const [keypairReq, setKeypairReq] = useState({ "userId": "", "password": "", "keystrength": "", "emailId": "", "publickey": "", "privatekey": "" , "expiryDate":""});
    const [errorConst, setErrorConst] = useState({});
    const [keypairTableData, setKeypairTableData] = useState({ "data": "", "loading": true });
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [failMsg, setFailMsg] = useState('');
    const [succMsg, setSuccMsg] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [fileName, setFileName] = useState('');
    const [restorationDays, setRestorationDays] = useState('');
    const [sortAsc, setSortAsc] = useState({ "user_id": false, "key_path": false });
    const [searchKey, setSearchKey] = useState('');
    const [eyeSlash, setEyeSlash] = useState(true);
    const [keyCreation, setKeyCreation] = useState('new') ;//new, exist 
    const [startDate, setStartDate] = useState(new Date());
    const years = loadYears() ;
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const [isExportVisible, setIsExportVisible] = useState(false);

    useEffect(() => {
        loadKeyPairList();
    }, [])

    function loadKeyPairList() {
        dispatchListKeypairAction(({ data, message }) => {
            if (data) {
                let temp = [];
                data.forEach(val => {
                    val.key_path = val.public_keyring_path + val.public_keyring_name;
                    const currentDate = new Date();
                    let expDate = new Date(val.exp_date);
                    if(val.exp_date!='' && val.exp_date!=null) {
                        const dateOptions = { timeZone: 'UTC', month: 'short', day: 'numeric', year: 'numeric' };
                        const dateFormatter = new Intl.DateTimeFormat('en-CA', dateOptions);
                        const dateAsFormattedString = dateFormatter.format(expDate);
                        if(expDate>=currentDate) {
                            let differenceInDays = (expDate - currentDate) / (1000 * 60 * 60 * 24);
                            differenceInDays = Math.floor(differenceInDays);
                            if (differenceInDays > 90) {
                                val.exp_date = dateAsFormattedString;
                                val.exp_date_tooltip = dateAsFormattedString;
                            } else if (differenceInDays <= 90 && differenceInDays > 0) {
                                val.exp_date =differenceInDays + " days";
                                val.exp_date_tooltip = dateAsFormattedString;
                            }
                        }else if (null != val.exp_date && expDate<currentDate) {
                            val.exp_date="Expired";
                            val.exp_date_tooltip = dateAsFormattedString;
                        }
                    } else{
                        val.exp_date="Never Expires";
                        val.exp_date_tooltip = "Never Expires";
                    }
                    temp.push(val);
                })
                setKeypairTableData({ "data": temp, "loading": false });
                setFilteredTableData(temp);
            }
        }, (message) => {
            console.log(message)
        })
    }

    function saveForm() {
        if (isFormValid() && keyCreation === "new") {
            dispatchgenerateKeypairAction(keypairReq, ({ data, message }) => {
                if (data) {
                    resetForm();
                    loadKeyPairList();
                    window.scrollTo(0, 0);
                    setSuccMsg("Keypair created successfully");
                    setTimeout(() => {
                        setSuccMsg('')
                    }, 3000);
                } else {
                    window.scrollTo(0, 0);
                    setFailMsg("Keypair creation failed");
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                }
            },
                (message) => {
                    window.scrollTo(0, 0);
                    console.log("fail message.........."+message);
                    setFailMsg(message); 
                    setTimeout(() => {
                    setFailMsg('')
                    }, 3000);
                });
        } else if (isFormValid() && keyCreation === "exist") {
            var requestData = new FormData();
            requestData.append('password', keypairReq.password)
            requestData.append('emailId', keypairReq.emailId)
            requestData.append('privateKey', keypairReq.privatekey)
            requestData.append('publicKey', keypairReq.publickey)
            requestData.append('userId', keypairReq.userId)
            dispatchUploadKeypairAction(requestData, ({ data, message }) => {
                if (data) {
                    resetForm();
                    loadKeyPairList();
                    window.scrollTo(0, 0);
                    setSuccMsg("Keypair Upload successfully");
                    setTimeout(() => {
                        setSuccMsg('')
                    }, 3000);
                } else {
                    window.scrollTo(0, 0);
                    setFailMsg("Keypair Upload failed");
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                }
            },
                (message) => {
                    window.scrollTo(0, 0);
                    setFailMsg(message);
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                });
        }
    }

    function isFormValid() {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        let tempError = _.cloneDeep(errorConst);
        tempError.userId = (keypairReq.userId || keypairReq.emailId) ? "" : "Please Enter User ID";
        tempError.keystrength = keypairReq.keystrength ? "" : "Please Choose A Keysize";
        tempError.password = keypairReq.password ? "" : "Please Enter Password";
        tempError.emailId = (keypairReq.emailId || keypairReq.userId) ? "" : "Please Enter Email"
        // (!regex.test(keypairReq.emailId) ? "Invalid email pattern" : "") - rm - new req
        tempError.publickey = keyCreation === "exist" ? (!keypairReq.publickey ? "Choose Public Key" : "") : ""
        tempError.privatekey = keyCreation === "exist" ? (!keypairReq.privatekey ? "Choose Private Key" : "") : ""

        setErrorConst(tempError);

        return (keyCreation === "new" && (!tempError.userId || !tempError.emailId) && !tempError.keystrength && !tempError.password) || (keyCreation === "exist" && !tempError.publickey && !tempError.privatekey && !tempError.password && (!tempError.emailId || !tempError.userId))
    }

    function resetForm() {
        setKeypairReq({ "userId": "", "password": "", "keystrength": "", "emailId": "" });
    }

    const deleteConfirm = (keyId, keyName) => {

        confirmDialog({
            message: 'Do you want to delete ' + keyName + '?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept() {
                dispatchDeleteKeyAction(keyId, ({ data, message }) => {
                    resetForm();
                    loadKeyPairList();
                    window.scrollTo(0, 0);
                    setSuccMsg("Keypair deleted successfully");
                    setTimeout(() => {
                        setSuccMsg('')
                    }, 3000);
                }, (message) => {
                    window.scrollTo(0, 0);
                    setFailMsg(message);
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                });
            },
            reject() {

            },
            acceptLabel: "Confirm",
            rejectLabel: "Cancel"
        });
    };

    function initiateDownload(folderPath) {

        var file_name = folderPath ? folderPath : fileName
        file_name = CryptoJS.AES.encrypt(file_name, CryptoJS.enc.Utf8.parse(appConf.switch.aesKey), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          }).toString();
        dispatchDownloadAction({
            "fileName": file_name,
            "restorationDays": restorationDays ? restorationDays : ""
        }, ({ data, message }) => {
            setRestorationDays('');
            if (data["fileDownloadLink"]) {
                // let parsed = Buffer.from(data["file"], "base64").toString();
                // const url = window.URL.createObjectURL(
                //     new Blob([parsed]),
                // );
                const link = document.createElement('a');
                link.href = data["fileDownloadLink"];
                link.setAttribute(
                    'download',
                    `${data['fileName']}`,
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else if (data["message"] && data["statusCode"] == 202) {
                if(constants.PERMIT_ARCHIVE_DWNLD.includes(userInfo.role)){
                    setOpenModal(true);
                }else{
                    window.scrollTo(0, 0);
                    setFailMsg("You don't have permission to download an archived file. Please contact the support team");
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                }                
                // modalRef.setAttribute("data-toggle", "modal");
                // modalRef.setAttribute("data-target", "#staticBackdrop");
                // setTimeout(() => {
                //    console.log(modalRef.current);
                //    modalRef.click();
                // }, 1000);
            } else if (data["message"] && data["statusCode"] == 404 || data["statusCode"] == 500) {
                window.scrollTo(0, 0);
                setFailMsg(data["message"]);
                setTimeout(() => {
                    setFailMsg('')
                }, 3000);
            } else {
                window.scrollTo(0, 0);
                setSuccMsg(data["message"]);
                setTimeout(() => {
                    setSuccMsg('')
                }, 3000);
            }
        }, (message) => {
            setFailMsg(message);
            setTimeout(() => {
                setFailMsg('')
            }, 3000);
        });
    }

    function compareBy(key, dir) {
        return function (a, b) {
            if (a[key] < b[key]) return dir == true ? -1 : 1;
            if (a[key] > b[key]) return dir == true ? 1 : -1;
            return 0;
        };
    }

    function sortBy(key) {
        let arrayCopy = _.cloneDeep(filteredTableData);
        let dirCopy = _.cloneDeep(sortAsc);
        dirCopy[key] = !dirCopy[key];
        setSortAsc(dirCopy);
        arrayCopy.sort(compareBy(key, dirCopy[key]));
        setFilteredTableData(arrayCopy);
    }

    useEffect(() => {
        let filtered = _.cloneDeep(filteredTableData);
        if (!keypairTableData.loading) {
            filtered = keypairTableData.data.filter(p => {
                return (p.user_id.toLowerCase().includes(searchKey.toLowerCase())
                    || p.key_path.toLowerCase().includes(searchKey.toLowerCase()))
            });
        }
        setFilteredTableData(filtered);
    }, [searchKey])

    function setExpiryDate(expiryDate){
        if(expiryDate){
            let formattedExpiryDate=expiryDate.toLocaleDateString("en-CA") + " " + "23:59:59";
            setKeypairReq({ "privatekey": keypairReq.privatekey, "publickey": keypairReq.publickey, "userId": keypairReq.userId, "password": keypairReq.password, "keystrength": keypairReq.keystrength,"expiryDate": formattedExpiryDate, "emailId": keypairReq.emailId })
        }
     }

     function getYear(date){
        return date.getFullYear();
     }

     
     function getMonth(date){
        return date.getMonth();
     }

     function loadYears(){
        var years=[];
        for(let i=new Date().getFullYear()-1;i<=new Date().getFullYear()+5;i++){
            years.push(i) ;
        }
        return years;
     }

    function exportClickExcelDownload() {
        var modifiedList =[{}];
        filteredTableData.forEach(element => {
            let temp ={};
            temp.user_id=element.user_id
            temp.key_path=element.key_path?element.key_path.split('/').pop() : '';
            temp.exp_date=element.exp_date;
            modifiedList.push(temp);
        });
        DownloadListAsExcel(modifiedList,'ListOfPgpKeys');
    }

    return (
        <React.Fragment>
            <main>
                <div class="container">
                    <ul class="breadcrumb">
                        <li class="breadcrumb__item">
                            <a class="breadcrumb__link" href="#">Configurations</a>
                        </li>
                        <li class="breadcrumb__item">
                            <span class="breadcrumb__link">PGP Key Management</span>
                        </li>
                    </ul>
                    <div class="title">
                        <h1>PGP Key Management</h1>
                    </div>
                    {failMsg ?
                        <AlertFailure message={failMsg} /> : ''}
                    {succMsg ?
                        <AlertSuccess message={succMsg} /> : ''}
                </div>
                <div class="grey-block">
                    <div class="container">
                        <div class="form mb-5">
                            <h2 class="mb-4">Create Keys</h2>
                            <div className="row">
                                <div className="col-lg-12 mb-5">
                                    <br></br>
                                    <ul className="radio-wrap">
                                        <li>
                                            <input type="radio" id="test2" name="radio-group" checked={keyCreation === "new" ? true : false} onChange={(e) => setKeyCreation('new')} />
                                            <label className="input-label" for="test2">New Key Generation</label>
                                        </li>
                                        <li>
                                            <input type="radio" id="test1" name="radio-group" checked={keyCreation === "exist" ? true : false} onChange={(e) => setKeyCreation('exist')} />
                                            <label className="input-label" for="test1">Migrate An Existing Key</label>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f1" class="input-label">User ID</label>
                                        <div class="input-wrap txt-wrap">
                                            <input autoComplete="off" type="text" placeholder="Enter user id" id="f1" className={`txt ${errorConst.userId ? 'is-invalid' : ''}`} value={keypairReq.userId}
                                                onChange={(e) => setKeypairReq({ "privatekey": keypairReq.privatekey, "publickey": keypairReq.publickey, "userId": e.target.value.replace(" ", "_").toLowerCase(), "password": keypairReq.password, "keystrength": keypairReq.keystrength, "emailId": keypairReq.emailId,"expiryDate":keypairReq.expiryDate })} />
                                            {errorConst.userId ?
                                                (<div className="error-message">{errorConst.userId}</div>)
                                                : ('')}
                                        </div>
                                    </div>
                                </div>
                                {keyCreation === "new" ?
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="key" class="input-label">Keystrength</label>
                                            <div class="input-wrap select-wrap">
                                                <select id="key" value={keypairReq.keystrength} className={`txt ${errorConst.keystrength ? 'is-invalid' : ''}`}
                                                    onChange={(e) => setKeypairReq({ "privatekey": keypairReq.privatekey, "publickey": keypairReq.publickey, "userId": keypairReq.userId, "password": keypairReq.password, "keystrength": e.target.value, "emailId": keypairReq.emailId ,"expiryDate":keypairReq.expiryDate})}>
                                                    <option>Keystrength</option>
                                                    <option>1024</option>
                                                    <option>2048</option>
                                                    <option>3072</option>
                                                    <option>4096</option>
                                                </select>
                                                {errorConst.keystrength ?
                                                    (<div className="error-message">{errorConst.keystrength}</div>)
                                                    : ('')}
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                                }
                                {keyCreation === "exist" ?
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="f2" className="d-block input-label">Upload Public Key<span style={{ color: 'red' }}>*</span></label>
                                            <div className="input-wrap upload-wrap">
                                                <input type="text" id="uploadFile" autoComplete="off" className={`txt ${errorConst.publickey ? 'is-invalid' : ''}`} value={keypairReq.publickey ? keypairReq.publickey.name : ""} />
                                                {!keypairReq.publickey ?
                                                    <div className="fileupload-custom">
                                                        <span> Upload Key</span>
                                                        <input id="uploadBtn" type="file" className="upload-input"
                                                            onChange={(e) => setKeypairReq({ "publickey": e.target.files[0], "privatekey": keypairReq.privatekey, "userId": keypairReq.userId, "password": keypairReq.password, "keystrength": keypairReq.keystrength, "emailId": keypairReq.emailId })}
                                                        />
                                                    </div>
                                                    :
                                                    <button class="button button--delete" onClick={((e) => {
                                                        setKeypairReq({ "publickey": "", "privatekey": keypairReq.privatekey, "userId": keypairReq.userId, "password": keypairReq.password, "keystrength": keypairReq.keystrength, "emailId": keypairReq.emailId })
                                                    })}>Remove</button>
                                                }
                                                {errorConst.publickey ?
                                                    (<div className="error-message">{errorConst.publickey}</div>)
                                                    : ('')}
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                                {keyCreation === "exist" ?
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="f2" className="d-block input-label">Upload Private Key<span style={{ color: 'red' }}>*</span></label>
                                            <div className="input-wrap upload-wrap">
                                                <input type="text" id="uploadFile" autoComplete="off" className={`txt ${errorConst.privatekey ? 'is-invalid' : ''}`} value={keypairReq.privatekey ? keypairReq.privatekey.name : ""} />
                                                {!keypairReq.privatekey ?
                                                    <div className="fileupload-custom">
                                                        <span> Upload Key</span>
                                                        <input id="uploadBtn" type="file" className="upload-input"
                                                            onChange={(e) => { setKeypairReq({ "publickey": keypairReq.publickey, "privatekey": e.target.files[0], "userId": keypairReq.userId, "password": keypairReq.password, "keystrength": keypairReq.keystrength, "emailId": keypairReq.emailId }) }}
                                                        />
                                                    </div>
                                                    :
                                                    <button class="button button--delete" onClick={((e) => {
                                                        setKeypairReq({ "publickey": keypairReq.publickey, "privatekey": "", "userId": keypairReq.userId, "password": keypairReq.password, "keystrength": keypairReq.keystrength, "emailId": keypairReq.emailId })
                                                    })}>Remove</button>
                                                }
                                                {errorConst.privatekey ?
                                                    (<div className="error-message">{errorConst.privatekey}</div>)
                                                    : ('')}
                                            </div>
                                        </div>
                                    </div> : ""}

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f1" class="input-label">Password</label>
                                        <div class="input-wrap password-wrap password-toggle">
                                            <input autoComplete="off" type={eyeSlash ? "password" : "text"} placeholder="Enter password" id="f1" className={`txt ${errorConst.password ? 'is-invalid' : ''}`} value={keypairReq.password}
                                                onChange={(e) => setKeypairReq({ "privatekey": keypairReq.privatekey, "publickey": keypairReq.publickey, "userId": keypairReq.userId, "password": e.target.value, "keystrength": keypairReq.keystrength, "emailId": keypairReq.emailId,"expiryDate":keypairReq.expiryDate })} />
                                            <div onClick={(() => setEyeSlash(eyeSlash ? false : true))}>
                                                <i className={`toggle-eye fa fa-fw ${eyeSlash ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                                            </div>
                                            {errorConst.password ?
                                                (<div className="error-message">{errorConst.password}</div>)
                                                : ('')}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f1" class="input-label">Email ID</label>
                                        <div class="input-wrap txt-wrap">
                                            <input autoComplete="off" type="text" placeholder="Enter email id" id="f1" className={`txt ${errorConst.emailId ? 'is-invalid' : ''}`} value={keypairReq.emailId}
                                                onChange={(e) => setKeypairReq({ "privatekey": keypairReq.privatekey, "publickey": keypairReq.publickey, "userId": keypairReq.userId, "password": keypairReq.password, "keystrength": keypairReq.keystrength, "emailId": e.target.value,"expiryDate":keypairReq.expiryDate })} />
                                            {errorConst.emailId ?
                                                (<div className="error-message">{errorConst.emailId}</div>)
                                                : ('')}
                                        </div>
                                    </div>
                                </div>
                                {keyCreation === "new" ?
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="pgp-expiryDate" class="input-label">Expiry Date</label>
                                        <div class="input-wrap date-wrap" style={{width:'100%'}}>
                                        <DatePicker dateFormat="dd/MM/yyyy"
                                                    style={{width:'100%'}}
                                                    className="pgpExpiryDate"
                                                    wrapperClassName="col-sm-12"
                                                    selected={(keypairReq.expiryDate)?new Date(keypairReq.expiryDate):''}
                                                    onChange={(date) => setExpiryDate(date)}
                                                    minDate={new Date()}
                                                    placeholderText="Select Expiry Date"
                                                    onfocus="(this.type='date')" 
                                                    // isClearable={true}
                                                    renderCustomHeader={({
                                                        date,
                                                        changeYear,
                                                        changeMonth,
                                                        decreaseMonth,
                                                        increaseMonth,
                                                        prevMonthButtonDisabled,
                                                        nextMonthButtonDisabled,
                                                      }) => (
                                                        <div
                                                          style={{
                                                            margin: 20,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            fontSize:14
                                                          }}
                                                        >
                                                          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                            {"<"}
                                                          </button>
                                                          <select
                                                            value={getYear(date)}
                                                            onChange={({ target: { value } }) => changeYear(value)}
                                                          >
                                                            {years.map((option) => (
                                                              <option key={option} value={option}>
                                                                {option}
                                                              </option>
                                                            ))}
                                                          </select>
                                                
                                                          <select style={{width:175}}
                                                            value={months[getMonth(date)]}
                                                            onChange={({ target: { value } }) =>
                                                              changeMonth(months.indexOf(value))
                                                            }
                                                          >
                                                            {months.map((option) => (
                                                              <option key={option} value={option}>
                                                                {option}
                                                              </option>
                                                            ))}
                                                          </select>
                                                
                                                          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                            {">"}
                                                          </button>
                                                        </div>
                                                      )}
                                                     />

                                            {errorConst.emailId ?
                                                (<div className="error-message">{errorConst.emailId}</div>)
                                                : ('')}
                                                

                                        </div>
                                        
                                    </div>
                                </div> : ""}


                            </div>
                        </div>
                        <div class="btn-wrap btn-wrap--grp mt-5 justify-content-end">
                            <button class="button button--outline" onClick={(e) => resetForm()}>Reset</button>
                            <button class="button" onClick={(e) => saveForm()}>Save</button>
                        </div>
                    </div>
                </div>
                <deleteConfirm></deleteConfirm>
                <div class="container">
                    <div class="table-block">
                        <div class="table-block__head">
                            <h2>List of Keys
                            <span style={{ "fontWeight": "bold", "fontSize": "13px", "color": "#666" }} >
                                    &nbsp; [ Total: {filteredTableData ? filteredTableData.length : 0} ]
                                </span>
                            </h2>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <button type="button" style={{ background: '#F8F8F8', color: 'black', border: 'none' }} className="btn btn-secondary btn-sm" onClick={(e) => isExportVisible ? setIsExportVisible(false) : setIsExportVisible(true)}>
                                    <img src={iconExport} alt="Success" />  Export
                                </button>
                                <div>
                                {isExportVisible ? 
                                    <div class='fh-legend widget exportXl'>
                                    <p style={{ color: '#000' }}><strong>Export As</strong></p>
                                    <ul>
                                        <li style={{ cursor: 'help'}} onClick={(e) => exportClickExcelDownload()}><img src={iconExcel} alt="Success" /> Excel</li>
                                    </ul>
                                    </div> 
                                : ''}
                                </div> 
                                <div class="txt-wrap">
                                    <input type="text" class="txt" placeholder="Search..."
                                        value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                    <i class="fa fa-search"></i>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive grid">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" onClick={() => sortBy('user_id')} style={{ cursor: "pointer", zIndex: 0 }}>User ID <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => sortBy('key_path')} style={{ cursor: "pointer", zIndex: 0 }}>Public Key Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" style={{ "whiteSpace": "nowrap" }} >Days To Expire </th>
                                        <th scope="col" >Downloads</th>
                                        {isAdmin() ? <th scope="col">Action</th> : ""}
                                    </tr>
                                </thead>
                                <tbody>
                                    {keypairTableData.loading ? (<tr><td colSpan="6"><div className="text-center text-danger">No PGP keys found</div></td></tr>) :
                                        (filteredTableData.map(k => (<tr>
                                            <td>{k.user_id}</td>
                                            <td>{k.key_path?k.key_path.split('/').pop() : ''}</td>
                                            <td style={{ "whiteSpace": "nowrap" }} title={k.exp_date_tooltip}>{k.exp_date}</td>
                                            <td>
                                                <a
                                                    // innerRef={modalRef}
                                                    class="link" href="javascript:void(0);"
                                                    // data-toggle="modal"
                                                    // data-target="#staticBackdrop"
                                                    onClick={(e) => {
                                                        setFileName(k.public_keyring_path + k.public_keyring_name);
                                                        initiateDownload(k.public_keyring_path + k.public_keyring_name);
                                                    }}
                                                >Download Here</a>
                                            </td>
                                            {isAdmin() ? <td>
                                                <div class="table__action">
                                                    <a class="utils utils--outline ml-2" onClick={(e) => deleteConfirm(k.id, k.user_id)}> <img src={iconDelete} alt="delete" /></a>
                                                </div>
                                            </td> : ""}
                                        </tr>)))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
            <div class={`modal fade modal--monitoring ${openModal ? 'show' : ''}`} id="staticBackdrop" style={{ display: openModal ? 'block' : 'none' }}
                data-backdrop="static" data-keyboard="false" tabindex="-1"
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Restoration Request</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={(e) => { setOpenModal(false); setRestorationDays(''); }}>
                                <img src={iconClose} alt="Close" />
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label for="num" class="d-block input-label">Number of days that the restored copy should be available</label>
                                        <div class="input-wrap number-wrap">
                                            <input type="number" id="num" name="num" min="0" placeholder="Type Number here..." onChange={(e) => setRestorationDays(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text">
                                <strong>Note :</strong>
                                <span>- standard retrival process - this will take around 3-5 hr</span>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="button button--outline button--sm" data-dismiss="modal" onClick={(e) => { setOpenModal(false); setRestorationDays(''); }}>Cancel</button>
                            <button type="button" class="button button--sm" onClick={(e) => { initiateDownload(); setOpenModal(false) }}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapDispatchToProps = dispatch => ({
    dispatchgenerateKeypairAction: (data, onSuccess, onError) =>
        dispatch(generateKeypair(data, onSuccess, onError)),
    dispatchUploadKeypairAction: (data, onSuccess, onError) =>
        dispatch(uploadKeypair(data, onSuccess, onError)),
    dispatchListKeypairAction: (onSuccess, onError) =>
        dispatch(getKeypairList(onSuccess, onError)),
    dispatchDeleteKeyAction: (data, onSuccess, onError) =>
        dispatch(deletePgpKey(data, onSuccess, onError)),
    dispatchDownloadAction: (data, onSuccess, onError) =>
        dispatch(downloadFile(data, onSuccess, onError))
});

export default connect(null, mapDispatchToProps)(PGPConfigurationComponent);