import _ from "lodash";
import { confirmDialog } from 'primereact/confirmdialog';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createSSHKeygen, deleteSshKey, downloadFile, getUserId, isAdmin, isOperationalUser, listSSHKeygen } from '../../src/redux/actions/auth.action.creators';
import iconDelete from '../assets/images/icon-delete.png';
import AlertFailure from '../utils/alert-failure.component';
import AlertSuccess from '../utils/alert-success.component';
var CryptoJS = require("crypto-js");
import iconExport from '../assets/images/icon-export.png';
import iconExcel from '../assets/images/icon-excel.png';
import { DownloadListAsExcel } from '../utils/list-export-excel';

const SSHKeygenerationComponent = ({ dispatchCreateSSHKeygenAction, dispatchListSSHKeygenAction ,dispatchDeleteKeyAction,dispatchDownloadAction}) => {

    const [SSHKeygenData, setSSHKeygenData] = useState({"keyName": "", "sshKeygenAlgorithm": "RSA", "passPhrase": "", "rsaKeySize": 2048, "keyDescription": ""})
    const [errorConst, setErrorConst] = useState({});
    const [keypairTableData, setKeypairTableData] = useState({ "data": "", "loading": true });
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [sortAsc, setSortAsc] = useState({ "key_name": false, "public_key_path": false, "private_key_path": false });
    const [searchKey, setSearchKey] = useState('');
    const [failMsg, setFailMsg] = useState('');
    const [succMsg, setSuccMsg] = useState('');
    const [fileName, setFileName] = useState('');
    const [eyeSlash, setEyeSlash] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [isExportVisible, setIsExportVisible] = useState(false);
   
    var appConf  = require(process.env.REACT_APP_CONFIG_URL);
    
    
    useEffect(() => {
        document.getElementById("RSAKeySize1").checked = true;
        listSSHKeygen();
    }, [])

    const RSAKeySizeCheck = (keySize) => {
        if (keySize === '1024') {
            document.getElementById("RSAKeySize").checked = true;
            document.getElementById("RSAKeySize1").checked = false;
            document.getElementById("RSAKeySize2").checked = false;
        }else if (keySize === '2048') {
            document.getElementById("RSAKeySize").checked = false;
            document.getElementById("RSAKeySize1").checked = true;
            document.getElementById("RSAKeySize2").checked = false;
        }else if (keySize === '4096') {
            document.getElementById("RSAKeySize").checked = false;
            document.getElementById("RSAKeySize1").checked = false;
            document.getElementById("RSAKeySize2").checked = true;
        }
        setSSHKeygenData({"keyName": SSHKeygenData.keyName, "sshKeygenAlgorithm": SSHKeygenData.sshKeygenAlgorithm, "passPhrase": SSHKeygenData.passPhrase, "rsaKeySize": keySize, "keyDescription": SSHKeygenData.keyDescription})
    }

    const listSSHKeygen = () => {
        dispatchListSSHKeygenAction(({ data, message }) => {
            if (data) {
                console.log("dispatchListSSHKeygenAction-->  ",data)
                setKeypairTableData({ "data": data, "loading": false });
                setFilteredTableData(data);
            }
        }, (message) => {
            console.log(message)
        })
    }

    function resetForm() {
        document.getElementById("RSAKeySize").checked = false;
        document.getElementById("RSAKeySize1").checked = true;
        document.getElementById("RSAKeySize2").checked = false;
        setSSHKeygenData({"keyName": "", "sshKeygenAlgorithm": "RSA", "passPhrase": "", "rsaKeySize": "2048", "keyDescription": ""});
    }

    const deleteConfirm = (keyId, keyName) => {

        confirmDialog({
            message: 'Do you want to delete ' + keyName + '?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept() {
                dispatchDeleteKeyAction(keyId, ({ data, message }) => {
                    resetForm();
                    listSSHKeygen();
                    window.scrollTo(0, 0);
                    setSuccMsg("Keypair deleted successfully");
                    setTimeout(() => {
                        setSuccMsg('')
                    }, 3000);
                }, (message) => {
                    window.scrollTo(0, 0);
                    setFailMsg(message);
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                });
            },
            reject() {

            },
            acceptLabel: "Confirm",
            rejectLabel: "Cancel"
        });
    };

    function initiateDownload(folderPath) {

        var file_name = folderPath ? folderPath : fileName
        file_name = CryptoJS.AES.encrypt(file_name, CryptoJS.enc.Utf8.parse(appConf.switch.aesKey), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          }).toString();
        dispatchDownloadAction({
            "fileName": file_name,
            
        }, ({ data, message }) => {

            if (data["fileDownloadLink"]) {
               
                const link = document.createElement('a');
                link.href = data["fileDownloadLink"];
                link.setAttribute(
                    'download',
                    `${data['fileName']}`,
                   
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else if (data["message"] && data["statusCode"] == 202) {
                if(constants.PERMIT_ARCHIVE_DWNLD.includes(userInfo.role)){
                    setOpenModal(true);
                }else{
                    window.scrollTo(0, 0);
                    setFailMsg("You don't have permission to download an archived file. Please contact the support team");
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                }                
               
            } else if (data["message"] && data["statusCode"] == 404 || data["statusCode"] == 500) {
                window.scrollTo(0, 0);
                setFailMsg(data["message"]);
                setTimeout(() => {
                    setFailMsg('')
                }, 3000);
            } else {
                window.scrollTo(0, 0);
                setSuccMsg(data["message"]);
                setTimeout(() => {
                    setSuccMsg('')
                }, 3000);
            }
        }, (message) => {
            window.scrollTo(0, 0);
            setFailMsg(message);
            setTimeout(() => {
                setFailMsg('')
            }, 3000);
        });
    }

    const validateSpace = (event, keys) => {
        var key = event.keyCode;
        if (key === 32) {
            event.preventDefault();
        }
        isFormValid()
    }

    function isFormValid() {
        let tempError = _.cloneDeep(errorConst);
        tempError.keyName = SSHKeygenData.keyName ? "" : "Please Enter key name";
        tempError.passPhrase = SSHKeygenData.passPhrase ? SSHKeygenData.passPhrase.length < 8 ? "Must contain at least 8 or more characters" : "" : "Please Enter passPhrase";
        
        setErrorConst(tempError);
        return !tempError.keyName && !tempError.passPhrase
    }

    function saveForm() {
      
        if(isFormValid()) {
            let tempSshRequest  = _.cloneDeep(SSHKeygenData);

            tempSshRequest.passPhrase = btoa(tempSshRequest.passPhrase).toString()
            tempSshRequest.rsaKeySize = parseInt(tempSshRequest.rsaKeySize);
            console.log("SSHKeygenData test--->>  ",tempSshRequest)
            dispatchCreateSSHKeygenAction(tempSshRequest, ({ data, message }) => {
                if (data) {
                    resetForm();
                    listSSHKeygen();
                    window.scrollTo(0, 0);
                    setSuccMsg("Keypair created successfully");
                    setTimeout(() => {
                        setSuccMsg('')
                    }, 3000);
                    console.log("data success-->  ",data)
                }else {
                    window.scrollTo(0, 0);
                    setFailMsg("Keypair creation failed");
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                    console.log("data faild-->  ",data)
                }
            },
                (message) => {
                    window.scrollTo(0, 0);
                    console.log("fail message.........."+message);
                    setFailMsg(message); 
                    setTimeout(() => {
                    setFailMsg('')
                    }, 3000);
                });
        }
        
    }

    function compareBy(key, dir) {
        return function (a, b) {
            if (a[key] < b[key]) return dir == true ? -1 : 1;
            if (a[key] > b[key]) return dir == true ? 1 : -1;
            return 0;
        };
    }

    function sortBy(key) {
        let arrayCopy = _.cloneDeep(filteredTableData);
        let dirCopy = _.cloneDeep(sortAsc);
        dirCopy[key] = !dirCopy[key];
        setSortAsc(dirCopy);
        arrayCopy.sort(compareBy(key, dirCopy[key]));
        setFilteredTableData(arrayCopy);
    }

    function checkIfUserHaveDownloadAccess(createdBy) {
        if (!isOperationalUser() || (isOperationalUser() && createdBy == getUserId())) {
            return true;
        } else if (isOperationalUser()) {
            window.scrollTo(0, 0);
            setFailMsg("You are not the owner of the selected Key.");
            setTimeout(() => {
                setFailMsg('')
            }, 3000);
        }
        return false;
    }

    useEffect(() => {
        let filtered = _.cloneDeep(filteredTableData);
        if (!keypairTableData.loading) {
            filtered = keypairTableData.data.filter(p => {
                return (p.key_name.toLowerCase().includes(searchKey.toLowerCase())
                    || p.public_key_path.toLowerCase().includes(searchKey.toLowerCase())
                    || p.private_key_path.toLowerCase().includes(searchKey.toLowerCase()))
            });
        }
        setFilteredTableData(filtered);
    }, [searchKey])

    function exportClickExcelDownload() {
        var modifiedList =[{}];
        filteredTableData.forEach(element => {
            let temp ={};
            temp.key_name=element.key_name;
            temp.public_key_path=element.public_key_path?element.public_key_path.split('/').pop() : '';
            temp.private_key_path=element.private_key_path?element.private_key_path.split('/').pop() : '';
            temp.ssh_file_size=element.ssh_file_size;
            modifiedList.push(temp);
        });
        DownloadListAsExcel(modifiedList,'ListOfSshKeys');
    }

    return (
        <React.Fragment>
            <main>
                <div class="container">
                    <ul class="breadcrumb">
                        <li class="breadcrumb__item">
                            <a class="breadcrumb__link" href="#">Configurations</a>
                        </li>
                        <li class="breadcrumb__item">
                            <span class="breadcrumb__link">SSH Key Management</span>
                        </li>
                    </ul>
                    <div class="title">
                        <h1>SSH Key Management</h1>
                    </div>
                    {failMsg ?
                        <AlertFailure message={failMsg} /> : ''}
                    {succMsg ?
                        <AlertSuccess message={succMsg} /> : ''}
                </div>
                <div class="grey-block">
                    <div class="container">
                        <div class="form mb-5">
                            <h2 class="mb-4">Create SSH Key</h2>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f1" class="input-label">Key Name <span style={{ color: 'red' }}>*</span></label>
                                        <div class="input-wrap txt-wrap">
                                            <input autoComplete="off" type="text" maxLength={20} placeholder="Enter Key Name" id="f1" className={`txt ${errorConst.keyName ? 'is-invalid' : ''}`} value={SSHKeygenData.keyName} onKeyDown={(e) => validateSpace(e, 'KEYNAME')}
                                                onChange={(e) => setSSHKeygenData({"keyName": e.target.value, "sshKeygenAlgorithm": SSHKeygenData.sshKeygenAlgorithm, "passPhrase": SSHKeygenData.passPhrase, "rsaKeySize": SSHKeygenData.rsaKeySize, "keyDescription": SSHKeygenData.keyDescription})} />
                                            {errorConst.keyName ?
                                                (<div className="error-message">{errorConst.keyName}</div>)
                                                : ('')}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f1" class="input-label">Passphrase <span style={{ color: 'red' }}>*</span></label>
                                        <div class="input-wrap password-wrap password-toggle">
                                            <input autoComplete="off" type={eyeSlash ? "password" : "text"} placeholder="Enter passphrase" id="f1" className={`txt ${errorConst.passPhrase || (SSHKeygenData.passPhrase.length > 0 && SSHKeygenData.passPhrase.length < 8) ? 'is-invalid' : ''}`} value={SSHKeygenData.passPhrase} 
                                                onChange={(e) => setSSHKeygenData({"keyName": SSHKeygenData.keyName, "sshKeygenAlgorithm": SSHKeygenData.sshKeygenAlgorithm, "passPhrase": e.target.value, "rsaKeySize": SSHKeygenData.rsaKeySize, "keyDescription": SSHKeygenData.keyDescription})} />
                                            <div onClick={(() => setEyeSlash(eyeSlash ? false : true))}>
                                                <i className={`toggle-eye fa fa-fw ${eyeSlash ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                                            </div>
                                            {errorConst.passPhrase || (SSHKeygenData.passPhrase.length > 0 && SSHKeygenData.passPhrase.length < 8) ?
                                                (<div className="error-message">{errorConst.passPhrase}</div>)
                                                : ('')}
                                        </div>
                                    </div>
                                </div>  
                              
                               
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label for="f1" class="input-label">Description</label>
                                        <div class="input-wrap txt-wrap">
                                            <input autoComplete="off" type="text" maxLength={20} placeholder="Enter Key Description" id="f1" className="txt" value={SSHKeygenData.keyDescription} style={{height: '75px'}}
                                                onChange={(e) => setSSHKeygenData({"keyName": SSHKeygenData.keyName, "sshKeygenAlgorithm": SSHKeygenData.sshKeygenAlgorithm, "passPhrase": SSHKeygenData.passPhrase, "rsaKeySize": SSHKeygenData.rsaKeySize, "keyDescription": e.target.value})} />
                                            {/* {errorConst.emailId ?
                                                (<div className="error-message">{errorConst.emailId}</div>)
                                                : ('')} */}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f1" class="input-label">SSH Keygen Algorithm <span style={{ color: 'red' }}>*</span></label>
                                        <div class="input-wrap txt-wrap" style={{paddingTop: '10px'}}>
                                            <ul className="radio-wrap">
                                                {/* <li style={{paddingRight: '10px'}}>SSH Keygen Algorithm</li> */}
                                                <li>
                                                    <input type="radio" id="RSAKeygen" name="RSAKeygenAlgorithm" checked={true} onChange={(e) => setSSHKeygenData({"keyName": SSHKeygenData.keyName, "sshKeygenAlgorithm": SSHKeygenData.sshKeygenAlgorithm, "passPhrase": SSHKeygenData.passPhrase, "rsaKeySize": SSHKeygenData.rsaKeySize, "keyDescription": SSHKeygenData.keyDescription})} />
                                                    <label className="input-label" for="RSAKeygen" style={{paddingLeft: '23px'}}>RSA</label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f1" class="input-label">RSA Key Size <span style={{ color: 'red' }}>*</span></label>
                                        <div class="input-wrap txt-wrap" style={{paddingTop: '10px'}}>
                                            <ul className="radio-wrap">
                                                {/* <li style={{paddingRight: '10px'}}>RSA Key Size</li> */}
                                                <li>
                                                    <input type="radio" id="RSAKeySize" name="radio-group" onClick={(e) => RSAKeySizeCheck('1024')} />
                                                    <label className="input-label" for="RSAKeySize" style={{paddingLeft: '23px'}}>1024</label>
                                                </li>
                                                <li>
                                                    <input type="radio" id="RSAKeySize1" name="radio-group" onClick={(e) => RSAKeySizeCheck('2048')} />
                                                    <label className="input-label" for="RSAKeySize1" style={{paddingLeft: '23px'}}>2048</label>
                                                </li>
                                                <li>
                                                    <input type="radio" id="RSAKeySize2" name="radio-group" onClick={(e) => RSAKeySizeCheck('4096')} />
                                                    <label className="input-label" for="RSAKeySize2" style={{paddingLeft: '23px'}}>4096</label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btn-wrap btn-wrap--grp mt-5 justify-content-end">
                            <button class="button button--outline" onClick={(e) => resetForm()}>Reset</button>
                            <button class="button" onClick={(e) => saveForm()}>Generate-SSH-Keys</button>
                            
                        </div>
                    </div>
                </div>
                <deleteConfirm></deleteConfirm>
                <div class="container">
                    <div class="table-block">
                        <div class="table-block__head">
                            <h2>List of SSH Keys
                            { <span style={{ "fontWeight": "bold", "fontSize": "13px", "color": "#666" }} >
                                    &nbsp; [ Total: {filteredTableData ? filteredTableData.length : 0} ]
                                </span> }
                            </h2>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <button type="button" style={{ background: '#F8F8F8', color: 'black', border: 'none' }} className="btn btn-secondary btn-sm" onClick={(e) => isExportVisible ? setIsExportVisible(false) : setIsExportVisible(true)}>
                                    <img src={iconExport} alt="Success" />  Export
                                </button>
                                <div>
                                {isExportVisible ? 
                                    <div class='fh-legend widget exportXl'>
                                    <p style={{ color: '#000' }}><strong>Export As</strong></p>
                                    <ul>
                                        <li style={{ cursor: 'help'}} onClick={(e) => exportClickExcelDownload()}><img src={iconExcel} alt="Success" /> Excel</li>
                                    </ul>
                                    </div> 
                                : ''}
                                </div> 
                                <div class="txt-wrap">
                                    <input type="text" class="txt" placeholder="Search..."
                                        value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                    <i class="fa fa-search"></i>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" onClick={() => sortBy('key_name')} style={{ cursor: "pointer", zIndex: 0 }}>Key Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => sortBy('public_key_path')} style={{ cursor: "pointer", zIndex: 0 }}> Public Key <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => sortBy('private_key_path')} style={{ cursor: "pointer", zIndex: 0 }}> Private Key <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" >Key Size</th>
                                        
                                        {isAdmin() || isOperationalUser() ? <th scope="col">Action</th> : ""}
                                    </tr>
                                </thead>
                                <tbody>
                                    {keypairTableData.loading ? (<tr><td colSpan="6"><div className="text-center text-danger">No SSH keys found</div></td></tr>) :
                                        (filteredTableData.map(k => (<tr>
                                            <td>{k.key_name}</td>
                                            <Tooltip target=".publicKeyPath" className="toolTipGroup" mouseTrack mouseTrackLeft={10} />
                                            <Tooltip target=".privateKeyPath" className="toolTipGroup" mouseTrack mouseTrackLeft={10} />
                                            <td className="publicKeyPath" data-pr-tooltip={k.public_key_path?k.public_key_path.split('/').pop() : ''} data-pr-position="bottom">
                                                <a
                                                    class="link" href="javascript:void(0);" 
                                                    onClick={(e) => {
                                                        setFileName(k.public_key_path);
                                                        console.log("SSHKeygenData public path--->>  ",k.public_key_pathh)
                                                         if(checkIfUserHaveDownloadAccess(k.created_user) ){
                                                            initiateDownload(k.public_key_path);
                                                         }
                                                    }}
                                                >Download Public key</a></td>
                                            <td className="privateKeyPath" data-pr-tooltip={k.private_key_path?k.private_key_path.split('/').pop() : ''} data-pr-position="bottom">
                                                <a
                                                    class="link" href="javascript:void(0);" 
                                                    onClick={(e) => {
                                                        setFileName(k.private_key_path);
                                                        console.log("SSHKeygenData private path--->>  ",k.private_key_path)
                                                        if(checkIfUserHaveDownloadAccess(k.created_user) ){
                                                            initiateDownload(k.private_key_path);
                                                        }
                                                    }}
                                                >Download Private Key</a></td>
                                            <td>{k.ssh_file_size}</td>
                                            
                                            {isAdmin()  || isOperationalUser() ? <td>
                                                <div class="table__action">

                                                    <a className="utils utils--outline ml-2" onClick={(e) => deleteConfirm(k.id, k.key_name)}><img src={iconDelete} alt="delete" style={{ cursor: 'pointer' }} /> </a>
 
                                                    
                                                </div>
                                            </td> : ""}
                                        </tr>)))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    );
}

const mapDispatchToProps = dispatch => ({
    dispatchCreateSSHKeygenAction: (data, onSuccess, onError) =>
        dispatch(createSSHKeygen(data, onSuccess, onError)),
    dispatchListSSHKeygenAction: (onSuccess, onError) =>
        dispatch(listSSHKeygen(onSuccess, onError)),
    dispatchDeleteKeyAction: (data, onSuccess, onError) =>
        dispatch(deleteSshKey(data, onSuccess, onError)),
    dispatchDownloadAction: (data, onSuccess, onError) =>
        dispatch(downloadFile(data, onSuccess, onError)),
});

export default connect(null, mapDispatchToProps)(SSHKeygenerationComponent);