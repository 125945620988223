import React from 'react';
 import PartnerOverrideScreenComponent from '../components/partner-override-screen.component';

const PartnerOverridePage = () => {
    return (
        <PartnerOverrideScreenComponent />
    )
}

export default PartnerOverridePage;

