import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import iconEdit from '../assets/images/icon-edit.png';
import iconDelete from '../assets/images/icon-delete.png';
import { listTemplates, displayMappingData, getChannelFrequency, channelSearch, listPartnersByTemplate, channelCount, listChannels, deleteChannel, listAllBusinessFunctions, createChannel, isAdmin, isOperationalUser, isPartnerUser, isPartnerAdmin, editChannel, changeChannelStatus, fetchSystemParams, fetchOneTimeParams, listPartners, getAllActiveChannelsList, getexportChannelDetails,isCustomUser } from '../redux/actions/auth.action.creators';
import _ from "lodash";
import AlertFailure from '../utils/alert-failure.component';
import AlertSuccess from '../utils/alert-success.component';
import { confirmDialog } from 'primereact/confirmdialog';
import CustomDropdownWidget from './dropdown/CustomDropdownWidget';
import { Tooltip } from 'primereact/tooltip';
import iconInfo from '../assets/images/icon-info-round.png';
import * as constants from '../redux/constants';
import { event } from 'jquery';
import iconExport from '../assets/images/export-red.png';
import iconImport from '../assets/images/import-red.png';
import { default as ReactSelect, components } from "react-select";
import iconListExport from '../assets/images/icon-export.png';
import iconExcel from '../assets/images/icon-excel.png';
import { DownloadListAsExcel } from '../utils/list-export-excel';

const ChannelComponent = ({ dispatchGetAllBusinessFunctionList, dispatchChannelCount, dispatchGetChannelFrequencyAction, dispatchListMappingAction, dispatchListTemplatesAction, dispatchListPartnersByTemplateAction, dispatchListChannelsAction, dispatchDeleteChannelAction, dispatchCreateChannelAction, dispatchEditChannelAction, dispatchStatusChangeAction, dispatchChannelSearchAction, dispatchOneTimeParamsAction, dispatchListPartnersAction, dispatchGetAllActiveChannelsList, dispatchSystemParamsAction,dispatchChannelExportDetails }) => {
    const [isExpanded, setIsExpanded] = useState({ "file_transformation": false, "file_content_validation": false, "file_frequency": false });
    const [templateDetails, setTemplateDetails] = useState({ "data": "", "loading": true });
    const [channelData, setChannelData] = useState({ "channelId": "", "handBackChannel": null, "channelName": "", "templateId": "", "producerPartnerCode": "", "consumerPartnerCode": "", "producerFile": "", "consumerFile": "", "mappingFile": "", "mappingName": "", "validationFile": "", "exco": "", "fileName": "", "status": "", "consumerFolderPath": "", "sequenceValidation": false, "businessFunction": null, "frequencyDetails": { "status": "", "details": "" } });
    const [errorConst, setErrorConst] = useState({ "channelName": "", "templateId": "", "producerPartnerCode": "", "consumerPartnerCode": "", "producerFile": "", "consumerFile": "", "mappingFile": "", "validationFile": "", "exco": "", "fileName": "", "consumerFolderPath": "", "threshold": "", "setFrequency": "" });
    const [mode, setMode] = useState("create");
    const [inputDef, setInputDef] = useState({ "producerInpuDef": "", "consumerInpuDef": "", "mappingInpuDef": "" });
    const [partners, setPartners] = useState({ "producers": {}, "consumers": {}, "loading": true });
    const [channelList, setChannelList] = useState([]);
    const [filteredChannelList, setFilteredChannelList] = useState({ "loading": true, "data": [] });
    const [channelSearchKey, setChannelSearchKey] = useState('');
    const [sortDirection, setSortDirection] = useState({ "channelName": '', "templateName": '', "producerPartnerCode": '', "consumerPartnerCode": '' });
    const [constrains,] = useState({ "fieldPlaceholder": "Select template", "isMandatory": true, "fieldLabel": "Template", "inputErrorMessage": "" });
    const [responseData, setResponseData] = useState("false");
    const [failMsg, setFailMsg] = useState('');
    const [succMsg, setSuccMsg] = useState('');
    const [templateArr, setTemplateArr] = useState([{ "value": "", "label": "Select template" }]);
    const [editTempVal, setEditTempVal] = useState({});
    const [statusCount, setStatusCount] = useState({})
    const [menuMode, setMenuMode] = useState("manage")
    const [requestBody, setRequestBody] = useState({ "fileName": "", "partnerCode": "", "status": "", "channelName": "", "limit": "30" });
    const [statusList,] = useState({ "dafrequencyStatusta": "", "loading": true });
    const [dropdownRefresher, setdropdownRefresher] = useState(true)
    const [partnerList, setPartnerList] = useState([]);
    const [mappingList, setMappingList] = useState([]);
    const [retreiveLimitList, setRetreiveLimitList] = useState({ "data": "", "loading": true });
    const [partnerConstrains,] = useState({ "fieldPlaceholder": "Select partner", "isMandatory": false, "fieldLabel": "Partner Name", "inputErrorMessage": "" })
    const [responsePartnerData, setResponsePartnerData] = useState({});
    const [channelListForDropDown, setChannelListForDropDown] = useState([{}])
    const [constrainsChannel,] = useState({ "fieldPlaceholder": "Select Channel", "isMandatory": true, "fieldLabel": "Channel Name", "inputErrorMessage": "" })
    const [retreieveLimit,] = useState(constants.FILEMONITOR_RETREIVELIMIT_DEFAULT);
    const [responseChannelData, setResponseChannelData] = useState({});
    const [, setChannelListWithPartner] = useState([{}])
    const [uploadMode, setUploadMode] = useState(false)
    const [selectedMapping, setSelectedMapping] = useState({})
    const [selectTime, setSelectTime] = useState(false);
    const [frequencyStatus, setFrequencyStatus] = useState(false)
    const [frequencyDetails, setFrequencyDetails] = useState({ "frequencyType": "daily", "details": "", "excludeWeekends": false, "threshold": 24, "fileCount": 1 })
    const [selectedTimeList, setSelectedTimeList] = useState([{ "time": "", "AmPm": "" }]);
    const [frequencyThreshold, setFrequencyThreshold] = useState(24);
    const [fileCount, setFilecount] = useState("1")
    const [excludeWeekends, setExcludeWeekends] = useState(false);
    const [businessFunctionDropdownlist, setBusinessFunctionDropdownlist] = useState([{}])
    const [businessFunctionConstrains,] = useState({ "fieldPlaceholder": "Select Business Function", "isMandatory": false, "fieldLabel": "Business Function", "inputErrorMessage": "" })
    const [businessFunctiondropdownRefresher, setBusinessFunctiondropdownRefresher] = useState(true)
    const [businessFunctionResponseData, setBusinessFunctionResponseData] = useState({});
    const [editBusinessFunctionVal, setEditBusinessFunctionVal] = useState({});
    var configInfo = require(process.env.REACT_APP_CONFIG_URL);
    var companyCodeForBusinessFunction = configInfo.switch.companyCode;

    const [handBackChannelListForDropDown, setHandBackChannelListForDropDown] = useState([{}])
    const [constrainsForHandBackChannel,] = useState({ "fieldPlaceholder": "Select HandBack Channel For Mapping", "isMandatory": false, "fieldLabel": "Handback Channel", "inputErrorMessage": "" });
    const [handBackChannelResponseData, setHandBackChannelResponseData] = useState({});
    const [editHandBackChannelVal, setEditHandBackChannelVal] = useState({});
    const [activeChannelList, setActiveChannelList] = useState([{}])
    const [importStatus, setImportStatus] = useState(0);

    const [errorDetails, setErrorDetails] = useState({ "errorStatus": "", "errorDays": "", "errorTime": "", "errorTimeAMPM": "", });
    const [dayList, setDayList] = useState([{ 'id': 0, 'day': 'S', 'fullDay': 'Sunday' }, { 'id': 1, 'day': 'M', 'fullDay': 'Monday' },
    { 'id': 2, 'day': 'T', 'fullDay': 'Tuesday' }, { 'id': 3, 'day': 'W', 'fullDay': 'Wednesday' },
    { 'id': 4, 'day': 'T', 'fullDay': 'Thursday' }, { 'id': 5, 'day': 'F', 'fullDay': 'Friday' }, { 'id': 6, 'day': 'S', 'fullDay': 'Saturday' }]);
    const [timeList, setTimeList] = useState(['01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30',
        '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00',
        '11:30', '12:00', '12:30']);

    const daysOptions = Array.from({ length: 31 }, (_, index) => {
        const day = (index + 1).toString().padStart(2, '0');
        return { value: day, label: day };
    });
    const [isExportVisible, setIsExportVisible] = useState(false);

    useEffect(() => {
        setChannelData({ "channelId": channelData.channelId, "handBackChannel": channelData.handBackChannel, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": channelData.producerFile, "consumerFile": channelData.consumerFile, "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "businessFunction": channelData.businessFunction, "frequencyDetails": { "status": frequencyStatus, "details": frequencyDetails } });
    }, [frequencyDetails, frequencyStatus]);

    useEffect(() => {
        dispatchSystemParamsAction({ "keyList": [constants.FILEMONITOR_RETREIVELIMIT_LIST] }, ({ data, message }) => {
            if (data) {
                setRetreiveLimitList({ "data": data[constants.FILEMONITOR_RETREIVELIMIT_LIST].paramValue.split(','), "loading": false });
            }
        }, (message) => {
            console.log(message);
        });
    }, [dispatchSystemParamsAction]);

    useEffect(() => {
        if (JSON.stringify(responsePartnerData) !== '{}') {
            setRequestBody({ "fileName": requestBody.fileName, "partnerCode": responsePartnerData.value, "status": requestBody.status, "channelName": requestBody.channelName, "limit": requestBody.limit });
        }
    }, [responsePartnerData])

    useEffect(() => {
        console.log(handBackChannelResponseData);
        if (!handBackChannelResponseData) {
            setChannelData({ "handBackChannel": handBackChannelResponseData.value, "channelId": channelData.channelId, "business_function": channelData.business_function, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": channelData.producerFile, "consumerFile": channelData.consumerFile, "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
        }
    }, [handBackChannelResponseData])

    useEffect(() => {
        if (JSON.stringify(responseChannelData) !== '{}') {
            setRequestBody({ "fileName": requestBody.fileName, "partnerCode": requestBody.partnerCode, "status": requestBody.status, "channelName": responseChannelData.value, "limit": requestBody.limit });
        }
    }, [responseChannelData])


    useEffect(() => {
        if (channelData.consumerPartnerCode != "") {
            var temp1 = [{ "value": 0, "label": "None" }]
            activeChannelList.map((info) => {
                if (info.producer_partnercode == channelData.consumerPartnerCode) {
                    temp1.push({ value: info.channel_id, label: info.channelname })
                }
            })
            console.log(temp1);
            setHandBackChannelListForDropDown(temp1);

            if (channelData.handBackChannel != null && channelData.handBackChannel != 0) {
                const tempRCH = temp1.filter(obj => obj.value == channelData.handBackChannel)
                console.log(tempRCH);
                if (tempRCH.length > 0) {
                    setHandBackChannelResponseData({
                        "value": tempRCH[0].value,
                        "label": tempRCH[0].label
                    })
                    setEditHandBackChannelVal({
                        "value": tempRCH[0].value,
                        "label": tempRCH[0].label
                    })
                } else {
                    setHandBackChannelResponseData({
                        "value": null,
                        "label": null
                    })
                    setEditHandBackChannelVal({
                        "value": null,
                        "label": null
                    })
                }
            } else {
                setHandBackChannelResponseData({
                    "value": null,
                    "label": null
                })
                setEditHandBackChannelVal({
                    "value": null,
                    "label": null
                })
            }
        }
    }, [channelData.consumerPartnerCode])

    useEffect(() => {
        if (!templateDetails.loading && channelData.templateId && channelData.templateId != 0) {
            dispatchListPartnersByTemplateAction(channelData.templateId, "PRD", ({ data, message }) => {
                if (data) {
                    let producers = data;
                    dispatchListPartnersByTemplateAction(channelData.templateId, "CNS", ({ data, message }) => {
                        if (data) {
                            setPartners({ "producers": sortFn('asc', producers, 'partnerCode'), "consumers": sortFn('asc', data, 'partnerCode'), "loading": false });
                        }else {
                            console.log("enter if else loop");
                            // Handle case where data is null
                            setPartners({ "producers": sortFn('asc', producers, 'partnerCode'), "consumers": {}, "loading": false, "error": "No consumer data found" });
                        }
                    }, (message) => {
                        console.log("enter if else loop   1");
                        setPartners({ "producers": sortFn('asc', producers, 'partnerCode'), "consumers": {}, "loading": true });
                    })

                }
            }, (message) => {
                setPartners({ "producers": {}, "consumers": {}, "loading": true });
            })
        }
    }, [channelData.templateId])

    useEffect(() => {
        dispatchListMappingAction(({ data, message }) => {
            if (data) {
                setMappingList(data);
            }
        },
            (message) => {
                console.log(message);
            });
    }, [mode])

    useEffect(() => {
        dispatchListPartnersAction(({ data, message }) => {
            if (data) {
                let arr = [{ "value": "", "label": "Any" }];
                data.forEach(p => {
                    arr.push({
                        "value": p.partnercode,
                        "label": `${p.partnercode} (${p.partnername})`
                    })
                })
                setPartnerList(arr);

            }
        },
            (message) => {
                console.log(message);
            });
    }, [dispatchListPartnersAction])

    useEffect(() => {
        dispatchGetAllActiveChannelsList(({ data, message, status }) => {
            if (data) {
                setChannelListWithPartner(data)
                var temp1 = [{ "value": 0, "label": "None" }]
                var temp = [{ "value": "", "label": "Any" }]
                data.map((ch) =>
                    temp.push({ value: ch.channelname, label: ch.channelname })
                )
                data.map((ch) =>
                    temp1.push({ value: ch.channel_id, label: ch.channelname })
                )
                setChannelListForDropDown(temp)
                setActiveChannelList(data)
                setHandBackChannelListForDropDown(temp1)
            }
        });
    }, [dispatchGetAllActiveChannelsList]);

    useEffect(() => {
        dispatchGetAllBusinessFunctionList(companyCodeForBusinessFunction, ({ data, message, status }) => {
            var temp = []
            if (data) {
                data.map((ch) =>
                    temp.push({ value: ch.id, label: ch.function_name })
                )
                setBusinessFunctionDropdownlist(temp);
            }
        });
    }, []);

    useEffect(() => {
        dispatchListTemplatesAction(({ data, message }) => {
            if (data) {
                loadChannels(data);
                setTemplateDetails({ "data": data, "loading": false });
                let arr = _.cloneDeep(templateArr);
                data.forEach(t => {
                    arr.push({
                        "value": t.templateId,
                        "label": t.templateName
                    });
                })
                setTemplateArr(arr);
            }
        }, (message) => {
            console.log(message)
        })
    }, [])

    useEffect(() => {
        updateChannelStatusCount()
    }, [])

    useEffect(() => {
        if (!selectTime) {
            setSelectedTimeList([{ "time": "", "AmPm": "" }]);
        }
    }, [selectTime])



    useEffect(() => {
        if (responseData != "false") {
            setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "businessFunction": channelData.businessFunction, "channelName": channelData.channelName, "templateId": responseData.value, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": channelData.producerFile, "consumerFile": channelData.consumerFile, "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
            if (!responseData.value) setErrorConst({ "channelName": errorConst.channelName, "templateId": "Please choose a template", "producerPartnerCode": errorConst.producerPartnerCode, "mappingName": channelData.mappingName, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "mappingName": channelData.mappingName, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency });
            else setErrorConst({ "channelName": errorConst.channelName, "templateId": "", "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency });
        }
    }, [responseData])

    useEffect(() => {
        if (!handBackChannelResponseData) {
            setChannelData({ "handBackChannel": handBackChannelResponseData.value, "channelId": channelData.channelId, "businessFunction": channelData.businessFunction, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": channelData.producerFile, "consumerFile": channelData.consumerFile, "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
        }
    }, [handBackChannelResponseData])

    useEffect(() => {
        if (!templateDetails.loading && channelData.consumerPartnerCode && mode === "create" && channelData.templateId != 0) {
            var cnsPath = templateDetails.data.filter(t => (t.templateId == channelData.templateId))[0].templateConsumer[0].filePath.replace("${consumer}", channelData.consumerPartnerCode)
            setChannelData({ "handBackChannel":channelData.handBackChannel, "channelId": channelData.channelId,"businessFunction":channelData.businessFunction, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": channelData.producerFile, "consumerFile": channelData.consumerFile, "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": cnsPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
            
        }
    }, [channelData.consumerPartnerCode])

    useEffect(() => {
        let filtered = _.cloneDeep(filteredChannelList.data);
        if (!filteredChannelList.loading) {
            filtered = channelList.filter(c => {
                return (c.channelname.toLowerCase().includes(channelSearchKey.toLowerCase())
                    || c.templateName.toLowerCase().includes(channelSearchKey.toLowerCase())
                    || c.producerpartnercode.toLowerCase().includes(channelSearchKey.toLowerCase()))
                    || c.consumerpartnercode.toLowerCase().includes(channelSearchKey.toLowerCase())
                    || c.filename.toLowerCase().includes(channelSearchKey.toLocaleLowerCase())
                    || c.status.toLowerCase().includes(channelSearchKey.toLocaleLowerCase())
            });
        }
        setFilteredChannelList({ "loading": false, "data": filtered });
    }, [channelSearchKey])

    function resetForm() {
        window.scrollTo(0, 0);
        setdropdownRefresher(dropdownRefresher ? false : true)
        // setdropdownChannelRefresher(dropdownChannelRefresher ? false : true)
        loadChannels(templateDetails.data);
        setEditTempVal({});
        setResponseData("false");
        setResponsePartnerData({});
        setBusinessFunctionResponseData({ "value": 0, "label": "Choose Any" });
        setBusinessFunctiondropdownRefresher(businessFunctiondropdownRefresher ? false : true)
        setResponseChannelData({});
        setUploadMode(false)
        setSelectedMapping({})
        setChannelData({ "channelId": "", "handBackChannel": null, "businessFunction": null, "channelName": "", "templateId": "", "producerPartnerCode": "", "consumerPartnerCode": "", "producerFile": "", "consumerFile": "", "mappingFile": "", "mappingName": "", "validationFile": "", "exco": "", "fileName": "", "status": "", "consumerFolderPath": "", "sequenceValidation": false });
        setErrorConst({ "channelName": "", "templateId": "", "producerPartnerCode": "", "consumerPartnerCode": "", "producerFile": "", "consumerFile": "", "mappingFile": "", "validationFile": "", "exco": "", "fileName": "", "consumerFolderPath": "", "threshold": "", "setFrequency": "" });
        setInputDef({ "producerInpuDef": "", "consumerInpuDef": "", "mappingInpuDef": "", "fileValidationDef": "" });
        setRequestBody({ "fileName": "", "partnerCode": "", "status": "", "channelName": "", "limit": "30" });
        setMode("create");
        setFrequencyDetails({ "frequencyType": "daily", "details": "", "excludeWeekends": false, "threshold": "24", "fileCount": "1" })
        setFrequencyStatus(false)
        setSelectedTimeList([{ "time": "", "AmPm": "" }])
        setFrequencyThreshold(24)
        setFilecount(1)

        setExcludeWeekends(false);
    }

    function onSaveForm() {
        if (isFormValid()) {
            var request = new FormData();
            if (channelData.channelId) request.append('channelId', channelData.channelId);
            request.append('channelName', channelData.channelName);
            request.append('consumerPartnerCode', channelData.consumerPartnerCode);
            request.append('producerPartnerCode', channelData.producerPartnerCode);
            request.append('templateId', channelData.templateId);
            request.append('fileName', channelData.fileName === "*" ? "ALL" : channelData.fileName);
            request.append('consumerFolderPath', channelData.consumerFolderPath);
            if (channelData.sequenceValidation != null) request.append('sequenceValidation', channelData.sequenceValidation);
            if (channelData.status) request.append('status', channelData.status);
            if (channelData.producerFile) request.append('producerFile', channelData.producerFile);
            if (channelData.consumerFile) request.append('consumerFile', channelData.consumerFile);
            if (channelData.mappingFile) request.append('mappingFile', channelData.mappingFile);
            if (channelData.mappingName) request.append('mappingName', channelData.mappingName);
            if (channelData.validationFile) request.append('validationFile', channelData.validationFile);
            if (channelData.exco) request.append('exco', channelData.exco);
            if (inputDef.producerInpuDef) request.append('producerInputDefinition', inputDef.producerInpuDef);
            if (inputDef.consumerInpuDef) request.append('consumerOutDefinition', inputDef.consumerInpuDef);
            if (inputDef.mappingInpuDef) request.append('fileMapping', inputDef.mappingInpuDef);
            if (inputDef.fileValidationDef) request.append('fileValidation', inputDef.fileValidationDef);
            if (channelData.frequencyDetails) request.append('frequencyDetails', JSON.stringify(channelData.frequencyDetails))
            if (businessFunctionResponseData) request.append('businessFunction', businessFunctionResponseData.value ? businessFunctionResponseData.value : 0)
            if (handBackChannelResponseData) request.append('handBackChannel', handBackChannelResponseData.value ? handBackChannelResponseData.value : 0)
            if (mode === "create") {
                dispatchCreateChannelAction(request, ({ data, status, code, description }) => {
                    window.scrollTo(0, 0);
                    if (status === 'SUCCESS') {
                        setSuccMsg("Channel created successfully");
                        resetForm();
                        setTimeout(() => {
                            setSuccMsg('')
                        }, 3000);
                    } else if (status === 'FAILURE') {
                        setFailMsg("Channel Creation Failed " + description);
                        setTimeout(() => {
                            setFailMsg('')
                        }, 3000);
                    }
                },
                (message) => {
                    window.scrollTo(0, 0);
                    setFailMsg(message);
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                });
            } else {
                dispatchEditChannelAction(request, ({ data, status, code, description  }) => {
                    window.scrollTo(0, 0);
                    if (status === 'SUCCESS') {
                        setSuccMsg("Channel modified successfully");
                        resetForm();
                        setTimeout(() => {
                            setSuccMsg('')
                        }, 3000);
                    } else if (status === 'FAILURE') {
                        setFailMsg("Channel Updation Failed " + description);
                        setTimeout(() => {
                            setFailMsg('')
                        }, 3000);
                    } 
                },
                (message) => {
                    window.scrollTo(0, 0);
                    setFailMsg(message);
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                });
            }



        } else {
            setErrorVals();
        }
    }

    function customMessageAlertMessage(category, message) {
        window.scrollTo(0, 0);
        if (category) {
            setSuccMsg(message);
            setTimeout(() => {
                setSuccMsg('')
            }, 3000);
        } else {
            setFailMsg(message);
            setTimeout(() => {
                setFailMsg('')
            }, 3000);
        }
    }

    function isFormValid() {
        if (channelData.sequenceValidation == "true") {
            return (channelData.channelName && channelData.templateId && channelData.producerPartnerCode && channelData.consumerPartnerCode && channelData.exco && channelData.fileName && !errorConst.producerFile && !errorConst.consumerFile && !errorConst.mappingFile && !errorConst.threshold && !errorConst.setFrequency && !errorConst.validationFile && channelData.consumerFolderPath.split("/sftp/")[1])

        }
        else {
            return (channelData.channelName && channelData.templateId && channelData.producerPartnerCode && channelData.consumerPartnerCode && channelData.fileName && !errorConst.producerFile && !errorConst.consumerFile && !errorConst.mappingFile && !errorConst.validationFile && !errorConst.threshold && !errorConst.setFrequency && channelData.consumerFolderPath.split("/sftp/")[1])
        }

    }

    function exportJsonData(channel) {
        console.log('exportJsonData inside exportJsonData function:', channel);
    
        if (isAdmin() || isPartnerAdmin()) {
            
            const channelId = channel.channelid.toString();
            console.log('channelId inside exportJsonData function:', channelId);
    
            
            dispatchChannelExportDetails(channelId, ({ data, message }) => {
                if (data) {
                    console.log('data inside exportJsonData function:', data);
                    const currentDate = new Date().toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                    }).replace(/\//g, '');
    
                    const fileName = `${channel.channelname}.${currentDate}.pihub`;
                    console.log('fileName inside exportJsonData function:', fileName);
    
                    
                    //const jsonData = JSON.stringify(data);
                    //const jsonData = JSON.stringify(data,null,'\t');
                    //console.log(jsonData);
                    
                    const blob = new Blob([data], { type: 'application/json' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                } else {
                    console.error('Error fetching channel details:', message);
                   
                }
            });
        }
    }

    function onEditClick(channel) {
        console.log(channel);
        if (isAdmin() || isPartnerAdmin()) {
            window.scrollTo(0, 0);
            setMode("edit");
            setFrequencyStatus(false)
            var cId = channel.channelid.toString()
            dispatchGetChannelFrequencyAction(cId, ({ data, message }) => {
                if (data) {
                    setFrequencyStatus(true)
                    // console.log(data.thresholdTimeHours)
                    setFrequencyThreshold(data.thresholdTimeHours)
                    setFilecount(data.frequency)
                    setExcludeWeekends(data.excludeWeekendDays);
                    setFrequencyStatus(data.status == "A" ? true : false)
                    var times = []
                    if (data.frequencyTypeDetails != '') {
                        setSelectTime(true)
                    }
                    if (data.frequencyType == "WEEKLY") {
                        if (data.frequencyTypeDetails.length > 0) {
                            var entries = data.frequencyTypeDetails.split(";")
                            for (var i = 0; i < entries.length; i++) {
                                const timeString = entries[i].split("-")[1] + ':00'
                                const timeString12hr = new Date('1970-01-01T' + timeString + 'Z')
                                    .toLocaleTimeString('en-US',
                                        { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
                                    );
                                times.push({ "day": entries[i].split("-")[0], "time": timeString12hr.split(" ")[0].length != 5 ? '0' + timeString12hr.split(" ")[0] : timeString12hr.split(" ")[0], "AmPm": timeString12hr.split(" ")[1] })
                            }
                            setSelectedTimeList(times)
                        } else {
                            setSelectedTimeList([{ "day": "", "time": "", "AmPm": "" }])
                        }

                    } else if (data.frequencyType == "DAILY") {

                        if (data.frequencyTypeDetails.length > 0) {
                            var entries = data.frequencyTypeDetails.split(";")
                            for (var i = 0; i < entries.length; i++) {
                                const timeString = entries[i] + ':00'
                                const timeString12hr = new Date('1970-01-01T' + timeString + 'Z')
                                    .toLocaleTimeString('en-US',
                                        { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
                                    );
                                times.push({ "time": timeString12hr.split(" ")[0].length != 5 ? '0' + timeString12hr.split(" ")[0] : timeString12hr.split(" ")[0], "AmPm": timeString12hr.split(" ")[1] })
                            }
                            setSelectedTimeList(times)
                        } else {
                            setSelectedTimeList([{ "time": "", "AmPm": "" }])
                        }
                    } else if (data.frequencyType == "MONTHLY") {

                        if (data.frequencyTypeDetails.length > 0) {
                            var entries = data.frequencyTypeDetails.split(";")
                            for (var i = 0; i < entries.length; i++) {
                                const timeString = entries[i].split("-")[1] + ':00'
                                const timeString12hr = new Date('1970-01-01T' + timeString + 'Z')
                                    .toLocaleTimeString('en-US',
                                        { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
                                    );
                                times.push({ "day": entries[i].split("-")[0], "time": timeString12hr.split(" ")[0].length != 5 ? '0' + timeString12hr.split(" ")[0] : timeString12hr.split(" ")[0], "AmPm": timeString12hr.split(" ")[1] })
                            }
                            setSelectedTimeList(times)
                        } else {
                            setSelectedTimeList([{ "day": "", "time": "", "AmPm": "" }])
                        }
                    }
                    setFrequencyDetails({ "frequencyType": data.frequencyType.toLowerCase(), "details": times, "excludeWeekends": data.excludeWeekendDays, "threshold": data.thresholdTimeHours, "fileCount": data.frequency })
                
                
                }
            }, (message) => {
                window.scrollTo(0, 0);
            }
            )
            const mapping = mappingList.filter((field) => field.attached_channels.split(",").includes(channel.channelid.toString()));
            if ((mapping.length == 0) && (channel.filemapping != "" && channel.filemapping != null)) {
                setUploadMode(true)
            } else {
                setUploadMode(false)
            }
            if (mapping.length > 0) {
                setSelectedMapping(mapping[0])
                setChannelData({ "handBackChannel": channel.handbackchannel, "channelId": channel.channelid, "channelName": channel.channelname, "templateId": channel.templateid, "producerPartnerCode": channel.producerpartnercode, "consumerPartnerCode": channel.consumerpartnercode, "producerFile": "", "consumerFile": "", "mappingFile": "", "mappingName": JSON.stringify(mapping[0]), "validationFile": "", "exco": channel.exco, "fileName": channel.filename === 'ALL' ? "*" : channel.filename, "status": channel.status, "consumerFolderPath": channel.consumerfolderpath, "sequenceValidation": channel.sequencevalidation, "frequencyDetails": channelData.frequencyDetails });
            } else {
                setChannelData({ "handBackChannel": channel.handbackchannel, "channelId": channel.channelid, "channelName": channel.channelname, "templateId": channel.templateid, "producerPartnerCode": channel.producerpartnercode, "consumerPartnerCode": channel.consumerpartnercode, "producerFile": "", "consumerFile": "", "mappingFile": "", "mappingName": "", "validationFile": "", "exco": channel.exco, "fileName": channel.filename === 'ALL' ? "*" : channel.filename, "status": channel.status, "consumerFolderPath": channel.consumerfolderpath, "sequenceValidation": channel.sequencevalidation, "frequencyDetails": channelData.frequencyDetails });
                setInputDef({ "producerInpuDef": channel.producerinputdefinition, "consumerInpuDef": channel.consumeroutputdefinition, "mappingInpuDef": channel.filemapping, "fileValidationDef": channel.filevalidation });
            }

            setEditTempVal({
                "value": channel.templateid,
                "label": channel.templateName
            })
            console.log(handBackChannelListForDropDown);
            console.log("channel",channel);

            if (channel.businessfunction != null) {
                const tempvalue = businessFunctionDropdownlist.filter(obj => obj.value == channel.businessfunction)
                if (tempvalue.length > 0) {
                    setBusinessFunctionResponseData({
                        "value": tempvalue[0].value,
                        "label": tempvalue[0].label
                    })
                    setEditBusinessFunctionVal({
                        "value": tempvalue[0].value,
                        "label": tempvalue[0].label
                    })
                } else {
                    setBusinessFunctionResponseData({
                        "value": null,
                        "label": null
                    })
                    setEditBusinessFunctionVal({
                        "value": null,
                        "label": null
                    })
                }

            } else {
                setBusinessFunctionResponseData({
                    "value": null,
                    "label": null
                })
                setEditBusinessFunctionVal({
                    "value": null,
                    "label": null
                })
            }
            setMenuMode("create");
        }
    }

    function loadChannels(templates) {
        dispatchListChannelsAction(({ data, message }) => {
            if (data) {
                let tempList = [];
                data.forEach(val => {
                    if (val && val.status === "A" || val.status === "I") {
                        val['templateName'] = templates.filter(t => (t.templateId === val.templateid)).map(m => m.templateName)[0];
                        tempList.push(val);
                    }
                });
                tempList.sort((a, b) => {
                    return a['updateddate'] < b['updateddate'] ? 1 : -1;
                })
                console.log(tempList);
                setFilteredChannelList({ "loading": false, "data": tempList });
                setChannelList(tempList);
            }
        },
            (message) => {
                console.log(message);
            }
        );
    }

    function sortFn(dir, sortArr, type) {
        let result = sortArr.sort((a, b) => {
            if (a[type] < b[type]) {
                return dir === "asc" ? -1 : 1;
            }
            if (a[type] > b[type]) {
                return dir === "asc" ? 1 : -1;
            }
            return 0;
        });
        return result;
    }

    function onSort(type) {
        let dir = "asc";
        if (sortDirection[type] === "asc") {
            dir = "desc";
        }
        if (type === 'channelname') {
            setFilteredChannelList({ "loading": false, "data": sortFn(dir, filteredChannelList.data, type) });
            setSortDirection({ "channelName": dir, "templateName": sortDirection.templateName, "producerPartnerCode": sortDirection.producerPartnerCode, "consumerPartnerCode": sortDirection.consumerPartnerCode, "fileName": sortDirection.fileName });
        } else if (type === 'templateName') {
            setFilteredChannelList({ "loading": false, "data": sortFn(dir, filteredChannelList.data, type) });
            setSortDirection({ "channelName": sortDirection.channelName, "templateName": dir, "producerPartnerCode": sortDirection.producerPartnerCode, "consumerPartnerCode": sortDirection.consumerPartnerCode, "fileName": sortDirection.fileName });
        } else if (type === 'producerpartnercode') {
            setFilteredChannelList({ "loading": false, "data": sortFn(dir, filteredChannelList.data, type) });
            setSortDirection({ "channelName": sortDirection.channelName, "templateName": sortDirection.templateName, "producerPartnerCode": dir, "consumerPartnerCode": sortDirection.consumerPartnerCode, "fileName": sortDirection.fileName });
        } else if (type === 'consumerpartnercode') {
            setFilteredChannelList({ "loading": false, "data": sortFn(dir, filteredChannelList.data, type) });
            setSortDirection({ "channelName": sortDirection.channelName, "templateName": sortDirection.templateName, "producerPartnerCode": sortDirection.producerPartnerCode, "consumerPartnerCode": dir, "fileName": sortDirection.fileName });
        } else if (type === 'filename') {
            setFilteredChannelList({ "loading": false, "data": sortFn(dir, filteredChannelList.data, type) });
            setSortDirection({ "channelName": sortDirection.channelName, "templateName": sortDirection.templateName, "producerPartnerCode": sortDirection.producerPartnerCode, "consumerPartnerCode": sortDirection.consumerPartnerCode, "fileName": dir });
        }
    }
    function CollapsibleH5(props) {
        var operation = props.title.replace(/ /g, "_").toLowerCase()
        const handleToggle = () => {
            if (operation == 'file_transformation') {
                setIsExpanded({ 'file_transformation': !isExpanded['file_transformation'], "file_content_validation": isExpanded['file_content_validation'], 'file_frequency': isExpanded['file_frequency'] })
            } else if (operation == 'file_content_validation') {
                setIsExpanded({ 'file_transformation': isExpanded['file_transformation'], "file_content_validation": !isExpanded['file_content_validation'], 'file_frequency': isExpanded['file_frequency'] })
            } else if (operation == 'file_frequency') {
                setIsExpanded({ 'file_transformation': isExpanded['file_transformation'], "file_content_validation": isExpanded['file_content_validation'], 'file_frequency': !isExpanded['file_frequency'] })
            }
        };
        const contentStyle = {
            paddingLeft: '10px'
        };
        const downArrowStyle = {
            paddingTop: '2px',
            fontSize: '8px',
            display: isExpanded[operation] ? 'none' : 'inline-block',
            transition: 'transform 0.3s ease-in-out'
        };

        const upArrowStyle = {
            paddingTop: '2px',
            fontSize: '8px',
            display: isExpanded[operation] ? 'inline-block' : 'none',
            transition: 'transform 0.3s ease-in-out'
        };

        return (
            <div class='widget widget--partner ' style={contentStyle} >
                <div class='row' onClick={handleToggle} style={contentStyle}>
                    <span style={downArrowStyle}><i class='fa fa-chevron-down' aria-hidden="true"></i></span>
                    <span style={upArrowStyle}><i class='fa fa-chevron-up' aria-hidden="true"></i></span>

                    <h6 style={contentStyle}>{props.title}</h6>
                </div>
                {isExpanded[operation] && <div style={contentStyle}>{props.children}</div>}
            </div>
        );
    }

    function setErrorVals() {
        let tempError = _.cloneDeep(errorConst);
        if (!channelData.channelName) { tempError.channelName = "Enter channel name" }
        else { tempError.channelName = "" }
        if (!channelData.templateId) { tempError.templateId = "Please choose a template" }
        else { tempError.templateId = "" }
        if (!channelData.producerPartnerCode) { tempError.producerPartnerCode = "Select a producer" }
        else { tempError.producerPartnerCode = "" }
        if (!channelData.consumerPartnerCode) { tempError.consumerPartnerCode = "Select a consumer" }
        else { tempError.consumerPartnerCode = "" }
        if (channelData.sequenceValidation == "true" && !channelData.exco) { tempError.exco = "Enter EXCO for Sequence Validation" }
        else { tempError.exco = "" }
        if (channelData.consumerFolderPath.split("/sftp/")[1] == "") { tempError.consumerFolderPath = "Consumer folder path cannot be null" }
        else { tempError.consumerFolderPath = "" }
        if (channelData.producerFile) {
            if (channelData.producerFile.size / 1024 > 200) { errorConst.producerFile = "Please choose a file with size less than 200KB" }
            else { errorConst.producerFile = "" }
        }
        if (channelData.consumerFile) {
            if (channelData.consumerFile.size / 1024 > 200) { errorConst.consumerFile = "Please choose a file with size less than 200KB" }
            else { errorConst.consumerFile = "" }
        }
        if (channelData.mappingFile) {
            if (channelData.mappingFile.size / 1024 > 200) { errorConst.mappingFile = "Please choose a file with size less than 200KB" }
            else { errorConst.mappingFile = "" }
        }
        if (channelData.validationFile) {
            if (channelData.validationFile.size / 1024 > 200) { errorConst.validationFile = "Please choose a file with size less than 200KB" }
            else { errorConst.validationFile = "" }
        }
        if (!channelData.fileName) { tempError.fileName = "Enter file name" }
        else { tempError.fileName = "" }
        if (frequencyStatus && frequencyDetails.details == '') {
            tempError.setFrequency = "Set Frequency"
        } else {
            tempError.setFrequency = ""
        }
        if (frequencyStatus && (parseInt(frequencyThreshold) > 24 || parseInt(frequencyThreshold) < 1)) {
            tempError.threshold = "Wait time threshold must be between from 1 and 24 hours"
        } else {
            tempError.threshold = ""
        }
        setErrorConst(tempError);

    }

    const deleteConfirm = (cId, cName) => {

        confirmDialog({
            message: 'Do you want to delete ' + cName + '?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept() {
                dispatchDeleteChannelAction(cId, ({ data, message }) => {
                    resetForm();
                    setSuccMsg("Channel deleted successfully");
                    setTimeout(() => {
                        setSuccMsg('')
                    }, 3000);
                }, (message) => {
                    window.scrollTo(0, 0);
                    setFailMsg(message);
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                });
            },
            reject() {

            },
            acceptLabel: "Confirm",
            rejectLabel: "Cancel"
        });
    };

    const folderConfirm = (fName) => {

        confirmDialog({
            message: 'The files will be placed at : ' + fName + '',
            header: 'Confirmation  ',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept() {
                onSaveForm()
            },
            reject() {

            },
            acceptLabel: "Confirm",
            rejectLabel: "Cancel"
        });
    };

    const changeMode = () => {

        confirmDialog({
            message: 'The exisiting mapping configurations will be discarded, do you wish to continue?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept() {
                handleUploadModeChange()
            },
            reject() {

            },
            acceptLabel: "Confirm",
            rejectLabel: "Cancel"
        });
    };

    const changeStatus = (channelId) => {
        let status = 'A';
        let filtered = _.cloneDeep(filteredChannelList.data);
        if (!filteredChannelList.loading) {
            filtered = channelList.filter(c => {
                if (c.channelid == channelId) {
                    c.status = c.status == 'A' ? 'I' : 'A';
                    status = c.status;
                }
                return c;
            });
        }
        setFilteredChannelList({ "loading": false, "data": filtered });
        dispatchStatusChangeAction(channelId, status, ({ data, message }) => {
            //no success message display
            updateChannelStatusCount()
        }, (message) => {
            resetForm();
            setFailMsg("Status change failed");
            setTimeout(() => {
                setFailMsg('')
            }, 3000);
        });

    }
    const clickEditingdetails = () => {
        if (mode == 'edit') {
            confirmDialog({


                message: 'Do you want discard these changes' + '?',
                header: 'Submit Confirmation',
                button: "button",
                acceptClassName: 'p-button-danger',
                accept() {
                    setMenuMode('manage');
                    resetForm()

                },
                reject() {


                },
                acceptLabel: "Yes",
                rejectLabel: "Cancel"

            });
        } else {
            setMenuMode('manage');
            resetForm()

        }

    };

    const updateChannelStatusCount = () => {
        dispatchChannelCount(({ data, message }) => {
            if (data) {
                setStatusCount(data)
            }
        }, (message) => { });
    }

    const handleUploadModeChange = () => {
        setUploadMode(!uploadMode)
        handleMappingChange('')
        if (uploadMode) {
            setSelectedMapping({})
        } else {
            setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": "", "consumerFile": "", "mappingFile": "", "mappingName": "", "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
        }
    }

    const mappingModeSwitch = () => {
        if (inputDef.mappingInpuDef != '' || inputDef.consumerInpuDef != '' || inputDef.producerInpuDef != '' || channelData.producerFile != '' || channelData.consumerFile != '' || channelData.mappingFile != '' || channelData.mappingName != '') {
            changeMode()
        } else {
            handleUploadModeChange()
        }

    }

    const handleMappingChange = (value) => {
        let mapping;
        setInputDef({ "producerInpuDef": "", "consumerInpuDef": "", "mappingInpuDef": "", "fileValidationDef": inputDef.filevalidation });
        if (value != "") {
            mapping = mappingList.filter((field) => field.mapping_name === value)
            setSelectedMapping(mapping[0])
            // setInputDef({ "producerInpuDef": "", "consumerInpuDef": "", "mappingInpuDef": "", "fileValidationDef": inputDef.filevalidation });
            setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": "", "consumerFile": "", "mappingFile": "", "mappingName": JSON.stringify(mapping[0]), "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
        } else {
            setSelectedMapping({})

            setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": "", "consumerFile": "", "mappingFile": "", "mappingName": "", "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
        }
    }

    const setLimitToResponsedataAndRetrivelimit = (value) => {
        // setRetreiveLimit(value);
        setRequestBody({ "fileName": requestBody.fileName, "partnerCode": requestBody.partnerCode, "status": requestBody.status, "channelName": requestBody.channelName, "limit": value });
    }

    const onSearch = () => {
        dispatchChannelSearchAction(requestBody, ({ data, message }) => {
            if (data) {
                let tempList = [];
                data.forEach(val => {
                    if (val && val.status === "A" || val.status === "I") {
                        val['templateName'] = templateDetails.data.filter(t => (t.templateId === val.templateid)).map(m => m.templateName)[0];
                        tempList.push(val);
                    }
                });
                tempList.sort((a, b) => {
                    return a['updateddate'] < b['updateddate'] ? 1 : -1;
                })
                setFilteredChannelList({ "loading": false, "data": tempList });
                setChannelList(tempList);
            }
        }, (message) => {
            console.log(message);
        });
    }

    const changeFrequencyType = (type) => {
        setFrequencyDetails({ "frequencyType": type, "details": frequencyDetails.details, "excludeWeekends": false, "threshold": frequencyDetails.frequencyThreshold, "fileCount": "1" })
        // setFrequencyDetails({ "frequencyType": type, "details": frequencyDetails.details, "excludeWeekends": false, "threshold": frequencyDetails.frequencyThreshold, "fileCount": "1" })
        if (type == "weekly") {
            setFilecount(1)

            setSelectTime(false)

            setExcludeWeekends(false)
            setSelectedTimeList([{ "day": "", "time": "", "AmPm": "" }])
        } else if (type == "daily") {
            setFilecount(1)

            setSelectTime(false)

            setExcludeWeekends(false)
            setSelectedTimeList([{ "time": "", "AmPm": "" }])
        } else if (type == "monthly") {
            setFilecount(1)
            setExcludeWeekends(false)
            setSelectTime(false)

            setSelectedTimeList([{ "day": "", "time": "", "AmPm": "" }])
        }
    }
    function isFrequencySaveValid() {

        if (!selectTime) {
            return true
        }
        var last_element = selectedTimeList[selectedTimeList.length - 1]
        if (last_element != null) {
            if (frequencyDetails.frequencyType == 'weekly'||frequencyDetails.frequencyType == 'monthly') {
                if (last_element.time === "" || last_element.AmPm === "" || last_element.day === "") {
                    return false
                }

            } else if (frequencyDetails.frequencyType == 'daily') {
                if (last_element.time === "" || last_element.AmPm === "") {
                    return false
                }
            }
            return true
        }
        return false
    }

    function isFrequencyValid() {
        if (!selectTime) {
            return true
        }
        var index = selectedTimeList.length
        var last_element = selectedTimeList[selectedTimeList.length - 1]
        if (last_element !== null) {
            if (frequencyDetails.frequencyType == 'weekly' || frequencyDetails.frequencyType == 'monthly') {
                if (last_element.time === "" || last_element.AmPm === "" || last_element.day === "") {
                    if (last_element.time === "") {
                        document.getElementById("errorAddTime" + (index - 1)).classList.add("error-message");
                        document.getElementById("errorAddTime" + (index - 1)).innerHTML = "Select Time";
                    }
                    if (last_element.AmPm === "") {
                        document.getElementById("errorAddTimeAmPm" + (index - 1)).classList.add("error-message");

                        document.getElementById("errorAddTimeAmPm" + (index - 1)).innerHTML = "Select AM or PM";
                    }
                    if (last_element.day === "") {
                        document.getElementById("errorAddDay" + (index - 1)).classList.add("error-message");
                        document.getElementById("errorAddDay" + (index - 1)).innerHTML = "Select Day";
                    }
                    return false
                } else {
                    if (document.getElementById("errorAddTimeAmPm" + (index - 1)) !== null) {
                        if (last_element.AmPm !== "") {
                            document.getElementById("errorAddTimeAmPm" + (index - 1)).classList.remove("error-message");
                            document.getElementById("errorAddTimeAmPm" + (index - 1)).innerHTML = "";
                        }
                    }
                    if (document.getElementById("errorAddTime" + (index - 1)) !== null) {
                        if (last_element.time !== "") {
                            document.getElementById("errorAddTime" + (index - 1)).classList.remove("error-message");
                            document.getElementById("errorAddTime" + (index - 1)).innerHTML = "";
                        }
                    }
                    if (document.getElementById("errorAddDay" + (index - 1)) !== null) {
                        if (last_element.day !== "") {
                            document.getElementById("errorAddDay" + (index - 1)).classList.remove("error-message");
                            document.getElementById("errorAddDay" + (index - 1)).innerHTML = "";
                        }
                    }
                    return true

                }
            } else if (frequencyDetails.frequencyType == 'daily') {
                if (last_element.time === "" || last_element.AmPm === "") {
                    if (last_element.time === "") {
                        document.getElementById("errorAddTime" + (index - 1)).classList.add("error-message");
                        document.getElementById("errorAddTime" + (index - 1)).innerHTML = "Select Time";
                    }
                    if (last_element.time !== "" && last_element.AmPm === "") {
                        document.getElementById("errorAddTimeAmPm" + (index - 1)).classList.add("error-message");
                        document.getElementById("errorAddTimeAmPm" + (index - 1)).innerHTML = "Select AM or PM";
                    }
                    return false
                } else {
                    if (document.getElementById("errorAddTime" + (index - 1)) !== null) {
                        if (last_element.AmPm !== "") {
                            document.getElementById("errorAddTimeAmPm" + (index - 1)).classList.remove("error-message");
                            document.getElementById("errorAddTimeAmPm" + (index - 1)).innerHTML = "";
                        }
                    }
                    if (document.getElementById("errorAddTimeAmPm" + (index - 1)) !== null) {
                        if (last_element.time !== "") {
                            document.getElementById("errorAddTime" + (index - 1)).classList.remove("error-message");
                            document.getElementById("errorAddTime" + (index - 1)).innerHTML = "";
                        }
                    }
                    return true
                }
            }
        } else {
            return false;
        }

    }
    const getSavedDetails = () => {
        var ready = isFrequencyValid()
        if (ready) {
            setFrequencyDetails({ "frequencyType": frequencyDetails.frequencyType, "details": selectedTimeList, "excludeWeekends": excludeWeekends, "threshold": frequencyThreshold, "fileCount": fileCount })
        }
    }
    const addTimeList = () => {
        var ready = isFrequencyValid()
        var newBlock
        if (frequencyDetails.frequencyType == 'weekly' || frequencyDetails.frequencyType == 'monthly') {
            newBlock = { day: "", time: "", AmPm: "" };
        } else if (frequencyDetails.frequencyType == 'daily') {
            newBlock = { time: "", AmPm: "" };
        }

        if (ready) {
            setSelectedTimeList((prevList) => [...prevList, newBlock]);
        }
    }

    const removeTimeList = (index) => {
        setSelectedTimeList(selectedTimeList.filter((_, i) => i !== index));
    };
    const handleDayChange = (event, index) => {
        const updatedSelectedTimeList = [...selectedTimeList];
        updatedSelectedTimeList[index].day = event.target.value;
        setSelectedTimeList(updatedSelectedTimeList);
    };
    const handleTimeChange = (event, index) => {
        const updatedSelectedTimeList = [...selectedTimeList];
        updatedSelectedTimeList[index].time = event.target.value;
        setSelectedTimeList(updatedSelectedTimeList);
    };
    const handleAmPmChange = (event, index) => {
        const updatedSelectedTimeList = [...selectedTimeList];
        updatedSelectedTimeList[index].AmPm = event.target.value;
        setSelectedTimeList(updatedSelectedTimeList);
    };


    const validateTemplateName = (templateName) => {
        console.log('enter in to validateTemplateName method:', templateName);
        console.log('enter in to validateTemplateName method templateDetailsis:', templateDetails.data);
        var filterTemplateName = null;
        if (templateDetails) {
            filterTemplateName = templateDetails.data.filter(temname => temname.templateName === templateName);
            if (filterTemplateName && filterTemplateName.length > 0) {
                console.log('filterTemplateName is:', filterTemplateName);
                console.log('exist validateAndSetProducerName method:', filterTemplateName[0].templateId );
                return filterTemplateName[0].templateId ;
            }
        }
        return 0;
    }

    
    useEffect(() => {
        if (importStatus === 'applyValidation') {
            if (!isFormValid()) {
                setErrorVals();
            }
        }
      }, [importStatus]);
    
    const handleFileImport = (event) => {
        const file = event.target.files[0];
        console.log('File is:', file);
        
        const reader = new FileReader();
        reader.onload = (e) => {
            const fileContent = reader.result;
            console.log('File content:', fileContent);
            
            
            
            try {
                const jsonData = JSON.parse(fileContent);
                console.log('Parsed JSON:', jsonData);
                console.log('Parsed JSON channelid is  :', jsonData.channelId);
                console.log("consumer folder path",jsonData.consumerFolderPath.split("/sftp/")[1]?jsonData.consumerFolderPath.split("/sftp/")[1]:"")
                //console.log("consumer folder path",jsonData.consumerFolderPath.split("/sftp/")[1]?jsonData.consumerFolderPath.split("/sftp/")[1]:"")
               
                setFrequencyStatus(true)
                setFrequencyThreshold(jsonData.thresholdTimeHours)
                setFilecount(jsonData.frequency)
                setExcludeWeekends(jsonData.excludeWeekendDays);
                setFrequencyStatus(jsonData.channelFrequencyStatus == "A" ? true : false)
                var times = []
                if (jsonData.frequencyTypeDetails != '') {
                   setSelectTime(true)
                }
                if (jsonData.frequencyType == "WEEKLY") {
                    var entries = jsonData.frequencyTypeDetails.split(";")
                    for (var i = 0; i < entries.length; i++) {
                        const timeString = entries[i].split("-")[1] + ':00'
                        const timeString12hr = new Date('1970-01-01T' + timeString + 'Z')
                            .toLocaleTimeString('en-US',
                                { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
                            );
                        times.push({ "day": entries[i].split("-")[0], "time": timeString12hr.split(" ")[0].length != 5 ? '0' + timeString12hr.split(" ")[0] : timeString12hr.split(" ")[0], "AmPm": timeString12hr.split(" ")[1] })
                    }
                    setSelectedTimeList(times)
                } else if (jsonData.frequencyType == "DAILY") {
                    var entries = jsonData.frequencyTypeDetails.split(";")
                    for (var i = 0; i < entries.length; i++) {
                        const timeString = entries[i] + ':00'
                        const timeString12hr = new Date('1970-01-01T' + timeString + 'Z')
                            .toLocaleTimeString('en-US',
                                { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
                            );
                        times.push({ "time": timeString12hr.split(" ")[0].length != 5 ? '0' + timeString12hr.split(" ")[0] : timeString12hr.split(" ")[0], "AmPm": timeString12hr.split(" ")[1] })
                    }
                    setSelectedTimeList(times)
                } else if (jsonData.frequencyType == "MONTHLY") {
                    var entries = jsonData.frequencyTypeDetails.split(";")
                    for (var i = 0; i < entries.length; i++) {
                        const timeString = entries[i].split("-")[1] + ':00'
                        const timeString12hr = new Date('1970-01-01T' + timeString + 'Z')
                            .toLocaleTimeString('en-US',
                                { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
                            );
                        times.push({ "day": entries[i].split("-")[0], "time": timeString12hr.split(" ")[0].length != 5 ? '0' + timeString12hr.split(" ")[0] : timeString12hr.split(" ")[0], "AmPm": timeString12hr.split(" ")[1] })
                    }
                }
                const frequencyType = jsonData.frequencyType ? jsonData.frequencyType.toLowerCase() : ''; // Using an empty string as a default
                setFrequencyDetails({ "frequencyType": frequencyType, "details": times, "excludeWeekends": jsonData.excludeWeekendDays, "threshold": jsonData.thresholdTimeHours, "fileCount": jsonData.frequency })
                setSelectedTimeList(times)
                const mapping = mappingList.filter((field) => field.attached_channels.includes(jsonData.channelName));
                //if ((mapping.length == 0) && (jsonData.fileMapping != "" && jsonData.fileMapping != null)) {
                  //  setUploadMode(true)
                //} else {
                //    setUploadMode(false)
                //}
                const templateId = validateTemplateName(jsonData.templateName);
                if (templateId === 0) {

                    setChannelData({  "handBackChannel":jsonData.handbackChannel, "channelId": jsonData.channelId, "channelName": jsonData.channelName, "templateId": templateId, "producerPartnerCode": "", "consumerPartnerCode": "", "producerFile": "", "consumerFile": "", "mappingFile": "",  "validationFile": "", "exco": jsonData.exco, "fileName": jsonData.fileName === 'ALL'?"*":jsonData.fileName, "status": jsonData.status, "consumerFolderPath": jsonData.consumerFolderPath, "sequenceValidation": jsonData.sequenceValidation, "frequencyDetails": jsonData.frequencyTypeDetails });
                }else{

                    setChannelData({  "handBackChannel":jsonData.handbackChannel, "channelId": jsonData.channelId, "channelName": jsonData.channelName, "templateId": templateId, "producerPartnerCode": jsonData.producerPartnerCode, "consumerPartnerCode": jsonData.consumerPartnerCode, "producerFile": "", "consumerFile": "", "mappingFile": "", "mappingName": "", "validationFile": "", "exco": jsonData.exco, "fileName": jsonData.fileName === 'ALL'?"*":jsonData.fileName, "status": jsonData.status, "consumerFolderPath":jsonData.consumerFolderPath, "sequenceValidation": jsonData.sequenceValidation, "frequencyDetails": jsonData.frequencyTypeDetails });
                }
                /*if (mapping.length > 0) {
                    
                   // setSelectedMapping(mapping[0])
                    setChannelData({  "handBackChannel":jsonData.handbackChannel, "channelId": jsonData.channelId, "channelName": jsonData.channelName, "templateId": validateTemplateName(jsonData.templateName), "producerPartnerCode":jsonData.producerPartnerCode, "consumerPartnerCode": jsonData.consumerPartnerCode, "producerFile": "", "consumerFile": "", "mappingFile": "",  "validationFile": "", "exco": jsonData.exco, "fileName": jsonData.fileName === 'ALL'?"*":jsonData.fileName, "status": jsonData.status, "consumerFolderPath": jsonData.consumerFolderPath, "sequenceValidation": jsonData.sequenceValidation, "frequencyDetails": channelData.frequencyTypeDetails });
                } else {
                    setChannelData({  "handBackChannel":jsonData.handbackChannel, "channelId": jsonData.channelId, "channelName": jsonData.channelName, "templateId": validateTemplateName(jsonData.templateName), "producerPartnerCode": jsonData.producerPartnerCode, "consumerPartnerCode": jsonData.consumerPartnerCode, "producerFile": "", "consumerFile": "", "mappingFile": "", "mappingName": "", "validationFile": "", "exco": jsonData.exco, "fileName": jsonData.fileName === 'ALL'?"*":jsonData.fileName, "status": jsonData.status, "consumerFolderPath":jsonData.consumerFolderPath, "sequenceValidation": jsonData.sequenceValidation, "frequencyDetails": channelData.frequencyTypeDetails });
                    setInputDef({ "producerInpuDef": jsonData.producerInputDefinition, "consumerInpuDef": jsonData.consumerOutputDefinition, "fileValidationDef": jsonData.fileValidation });
                }*/
    
                setEditTempVal({
                    //"value": jsonData.templateId,
                    "label": jsonData.templateName
                })
                console.log(handBackChannelListForDropDown);
                console.log(jsonData);
                if (jsonData.handbackChannel != null && jsonData.handbackChannel != 0) {
                    const tempRCH = handBackChannelListForDropDown.filter(obj => obj.value == jsonData.handbackChannel)
                    console.log(tempRCH);
                    if (tempRCH.length > 0) {
                        setHandBackChannelResponseData({
                            "value": tempRCH[0].value,
                            "label": tempRCH[0].label
                        })
                        setEditHandBackChannelVal({
                            "value": tempRCH[0].value,
                            "label": tempRCH[0].label
                        })
                    } else {
                        setHandBackChannelResponseData({
                            "value": null,
                            "label": null
                        })
                        setEditHandBackChannelVal({
                            "value": null,
                            "label": null
                        })
                    }
                }else {
                    setHandBackChannelResponseData({
                        "value": null,
                        "label": null
                    })
                    setEditHandBackChannelVal({
                        "value": null,
                        "label": null
                    })
                }
                if (jsonData.BusinessFunctionId != null) {
                    const tempvalue = businessFunctionDropdownlist.filter(obj => obj.value == jsonData.BusinessFunctionId)
                    if (tempvalue.length > 0) {
                        setBusinessFunctionResponseData({
                            "value": tempvalue[0].value,
                            "label": tempvalue[0].label
                        })
                        setEditBusinessFunctionVal({
                            "value": tempvalue[0].value,
                            "label": tempvalue[0].label
                        })   
                    } else {
                        setBusinessFunctionResponseData({
                            "value": null,
                            "label": null
                        })
                        setEditBusinessFunctionVal({
                            "value": null,
                            "label": null
                        })
                    }
                
                } else {
                    setBusinessFunctionResponseData({
                        "value": null,
                        "label": null
                    })
                    setEditBusinessFunctionVal({
                        "value": null,
                        "label": null
                    })
                }
                setMenuMode("create");
                
                
                setImportStatus('applyValidation');
                setChannelData({  "handBackChannel":jsonData.handbackChannel, "channelId": jsonData.channelId, "channelName": jsonData.channelName, "templateId": templateId, "producerPartnerCode": jsonData.producerPartnerCode, "consumerPartnerCode": jsonData.consumerPartnerCode, "producerFile": "", "consumerFile": "", "mappingFile": "", "mappingName": "", "validationFile": "", "exco": jsonData.exco, "fileName": jsonData.fileName === 'ALL'?"*":jsonData.fileName, "status": jsonData.status, "consumerFolderPath":jsonData.consumerFolderPath, "sequenceValidation": jsonData.sequenceValidation, "frequencyDetails": jsonData.frequencyTypeDetails });
                
            } catch (error) {
                console.error('Error parsing JSON:', error);
                
            }
        };
        
        reader.readAsText(file);
    };

    function exportClickExcelDownload() {
        var modifiedList =[{}];
        filteredChannelList.data.forEach(element => {
            let temp ={};
            temp.channel_name=element.channelname;
            temp.template_name=element.templateName;
            temp.producer_partner=element.producerpartnercode;
            temp.consumer_partner=element.consumerpartnercode;
            temp.filename=element.filename;
            temp.status=element.status;
            modifiedList.push(temp);
        }); 
        DownloadListAsExcel(modifiedList,'ListOfChannels');
    }

    return (
        <React.Fragment>
            <main>
                <div class="container">
                    <ul class="breadcrumb">
                        <li class="breadcrumb__item">
                            <span class="breadcrumb__link">Manage Partner</span>
                        </li>
                        <li class="breadcrumb__item">
                            <span class="breadcrumb__link">Channels</span>
                        </li>
                    </ul>
                    <div class="title form-row" >
                        {menuMode == 'manage' ? <h1>Manage Channels</h1> : (mode == 'edit') ? <h1>Edit Channel</h1> : <h1>Create Channel</h1>}
                        {isAdmin() || isPartnerAdmin() ?
                            <div>
                                {menuMode == 'manage' ? <button class='button button--outline' onClick={(() => { setMenuMode('create'); })}>Create Channel</button> : <button class='button--outline button' onClick={(() => { clickEditingdetails('manage'); })}>Manage Channel</button>}
                            </div> : ''}
                    </div>

                    {failMsg ?
                        <AlertFailure message={failMsg} /> : ''}
                    {succMsg ?
                        <AlertSuccess message={succMsg} /> : ''}
                </div>
                {menuMode == 'create' ?
                    <div class="grey-block">
                        <div class="container">
                            <div class="form mb-5">
                                <div class="row">
                                    <div className="col-lg-8 col-md-8">
                                        {mode == 'edit' ? <h2 class="mb-4">Edit Channel</h2> : <h2 class="mb-4">Add Channel</h2>}
                                    </div>
                                    <div className="col-lg-4 col-md-4 text-right">
                                        <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileImport} accept=".pihub" />
                                        <label htmlFor="fileInput" className="btn btn-secondary btn-sm" style={{ background: '#FFF', color: 'black', borderColor: 'red', width: '30%', justifyContent: 'right' }}>
                                            <img src={iconImport} alt="Import" /> Import
                                        </label>
                                    </div>
                                </div>
                                <div class="row">
                                    {/* channel name */}
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="f1" class="input-label"> Channel Name <span style={{ color: 'red' }}>*</span></label>
                                            <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Enter a unique channel name" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                            <div class="input-wrap select-wrap">
                                                <input type="text" className={`txt ${errorConst.channelName ? 'is-invalid' : ''}`} value={channelData.channelName}
                                                    onChange={(e) => {
                                                        setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": e.target.value.replace(" ", "_").toLowerCase(), "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": channelData.producerFile, "consumerFile": channelData.consumerFile, "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
                                                        if (!e.target.value) setErrorConst({ "channelName": "Enter channel name", "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency });
                                                        else setErrorConst({ "channelName": "", "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency });

                                                    }} />
                                                {errorConst.channelName ?
                                                    (<div className="error-message">{errorConst.channelName}</div>)
                                                    : ('')}
                                            </div>
                                        </div>
                                    </div>
                                    {/* template */}
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={templateArr} constrains={constrains} setResponseData={setResponseData} editValue={editTempVal}></CustomDropdownWidget>
                                            {errorConst.templateId ?
                                                (<div className="error-message">{errorConst.templateId}</div>)
                                                : ('')}
                                        </div>
                                    </div>
                                    {/* producer */}
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="f2" class="d-block input-label">Producer <span style={{ color: 'red' }}>*</span></label>
                                            <div class="input-wrap select-wrap">
                                                <select id="f1"
                                                    onChange={(e) => {
                                                        setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": e.target.value, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": channelData.producerFile, "consumerFile": channelData.consumerFile, "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
                                                        if (!e.target.value) setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": "Select a producer", "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency });
                                                        else setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": "", "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency });
                                                    }} value={channelData.producerPartnerCode}>
                                                    <option value="">Select Producer</option>
                                                    {(partners.loading) ? '' :
                                                        (partners.producers.map(p => (
                                                            <option value={p.partnerCode}>{p.partnerCode}</option>
                                                        )))}
                                                </select>
                                                {errorConst.producerPartnerCode ?
                                                    (<div className="error-message">{errorConst.producerPartnerCode}</div>)
                                                    : ('')}
                                            </div>
                                        </div>
                                    </div>
                                    {/* consumer */}
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="f2" class="d-block input-label">Consumer <span style={{ color: 'red' }}>*</span></label>
                                            <div class="input-wrap select-wrap">
                                                <select id="f1"
                                                    onChange={(e) => {
                                                        setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": e.target.value, "producerFile": channelData.producerFile, "consumerFile": channelData.consumerFile, "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath.split('/').map((part, index) => index === 2 ? e.target.value : part).join('/'), "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
                                                        if (!e.target.value) setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": "Select a consumer", "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency });
                                                        else setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": "", "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency });
                                                    }} value={channelData.consumerPartnerCode}>
                                                    <option value="">Select Consumer</option>
                                                    {(partners.loading) ? '' :
                                                        (partners.consumers.map(p => (
                                                            <option value={p.partnerCode}>{p.partnerCode}</option>
                                                        )))}
                                                </select>
                                                {errorConst.consumerPartnerCode ?
                                                    (<div className="error-message">{errorConst.consumerPartnerCode}</div>)
                                                    : ('')}
                                            </div>
                                        </div>
                                    </div>
                                    {/* file name  */}
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="f1" class="input-label">File name <span style={{ color: 'red' }}>*</span></label>
                                            <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Enter the prefix/suffix of the file to be routed through this channel. (All Caps)" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                            <div class="input-wrap select-wrap">
                                                <input type="text" className={`txt ${errorConst.fileName ? 'is-invalid' : ''}`} value={channelData.fileName}
                                                    onChange={(e) => {
                                                        setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": channelData.producerFile, "consumerFile": channelData.consumerFile, "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": e.target.value, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
                                                        if (!e.target.value) setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": "Enter file name", "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency });
                                                        else setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": "", "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency });

                                                    }} />
                                                {errorConst.fileName ?
                                                    (<div className="error-message">{errorConst.fileName}</div>)
                                                    : ('')}
                                            </div>
                                        </div>
                                    </div>
                                    {/* folder path  */}
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="f1" class="input-label">Consumer Folder Path</label>
                                            <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="The Pihub consumer output path of files routed through this channel" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                            <div class="input-wrap select-wrap">
                                                <input className="txt" disabled type="text" placeholder={channelData.consumerFolderPath.substring(0, channelData.consumerFolderPath.indexOf("/sftp/") + 6)} style={{ width: "58%", borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }} autoComplete="off" id="f1" />
                                                <input type="text" className={`txt ${errorConst.consumerFolderPath ? 'is-invalid' : ''}`} value={channelData.consumerFolderPath.split("/sftp/")[1] ? channelData.consumerFolderPath.split("/sftp/")[1] : ""} style={{ width: "42%", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }} autoComplete="off" id="f1"
                                                    onChange={(e) => {
                                                        setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "frequencyDetails": channelData.frequencyDetails, "producerFile": channelData.producerFile, "consumerFile": channelData.consumerFile, "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "sequenceValidation": channelData.sequenceValidation, "consumerFolderPath": channelData.consumerFolderPath.substring(0, channelData.consumerFolderPath.indexOf("/sftp/") + 6) + e.target.value });
                                                        if (!e.target.value) setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": "Consumer folder path cannot be null", "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency });
                                                        else setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": "" });
                                                    }} />
                                                {errorConst.consumerFolderPath ?
                                                    (<div className="error-message">{errorConst.consumerFolderPath}</div>)
                                                    : ('')}
                                            </div>
                                        </div>
                                    </div>
                                    {/* checked={props.inputDataForRole.partnerRoleConsumer ? false : true} id="no" onChange={(() => { setValuesToVariable("partnerRoleConsumer", false) })} */}

                                    {/* exco */}
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="f1" class="input-label">File Prefix</label>
                                            <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Enter the prefix of sequence files to keep track and validate the sequence of these files.Example: if the File name is “TURK01P.015”. The prefix should be given as “TURK01P”." data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                            <div class="input-wrap select-wrap">
                                                <input type="text" className={`txt ${errorConst.exco ? 'is-invalid' : ''}`} value={channelData.exco}
                                                    onChange={(e) => {
                                                        channelData.sequenceValidation = channelData.sequenceValidation ? channelData.sequenceValidation : false
                                                        if (!e.target.value) {
                                                            channelData.sequenceValidation = false
                                                        }

                                                        else setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "mappingName": channelData.mappingName, "validationFile": errorConst.validationFile, "exco": "", "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency });

                                                        setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": channelData.producerFile, "consumerFile": channelData.consumerFile, "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": e.target.value, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
                                                    }} />
                                                {errorConst.exco ?
                                                    (<div className="error-message">{errorConst.exco}</div>)
                                                    : ('')}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="f2" class="d-block input-label">Sequence Number Validation <span style={{ color: 'red' }}>*</span></label>
                                            <div class="input-wrap select-wrap">
                                                <select id="f1"
                                                    disabled={channelData.exco != "" && channelData.exco != null ? false : true}
                                                    onChange={(e) => {
                                                        setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": channelData.producerFile, "consumerFile": channelData.consumerFile, "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": e.target.value });


                                                    }} value={channelData.sequenceValidation == "" || channelData.sequenceValidation == null ? false : channelData.sequenceValidation}>
                                                    <option value={false}>Disabled</option>
                                                    <option value={true}>Enabled</option>
                                                </select>
                                                {/* {errorConst.exco ?
                                                (<div className="error-message">{errorConst.exco}</div>)
                                                : ('')} */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* business function */}
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <CustomDropdownWidget dropdownRefresher={businessFunctiondropdownRefresher} dropdownList={businessFunctionDropdownlist} constrains={businessFunctionConstrains} setResponseData={setBusinessFunctionResponseData} editValue={editBusinessFunctionVal}></CustomDropdownWidget>
                                        </div>
                                    </div>
                                    {/* handback channel mapping */}
                                    {
                                        channelData.producerPartnerCode
                                            ?
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={handBackChannelListForDropDown} constrains={constrainsForHandBackChannel} setResponseData={setHandBackChannelResponseData} editValue={editHandBackChannelVal}></CustomDropdownWidget>

                                                </div>
                                            </div>
                                            :
                                            ''}
                                </div>
                                <div style={{ padding: "10px" }}></div>
                                <h2 class="mb-4">File Operations</h2>
                                <CollapsibleH5 title="File Transformation">
                                    <div style={{ padding: "10px" }}></div>
                                    <div className="form" style={{ paddingLeft: "5px" }}>
                                        <input type="checkbox" id="yes1" checked={uploadMode ? true : false} onChange={(() => { mappingModeSwitch() })} />
                                        <label for="yes1"> Do you want to upload the mapping XML files? </label>
                                    </div>
                                    <div style={{ padding: "10px", maxWidth: "30%" }}><center>Or</center></div>
                                    <div class=" form-row row" >
                                        {!uploadMode ?
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class='row' style={{ paddingLeft: "18px" }}>
                                                        <label for="f2" class="d-block input-label">Select Mapping</label>
                                                        <span style={{ paddingLeft: "10px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Select a predefined file mapping template" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                                    </div>
                                                    <select id="f1"
                                                        onChange={(e) => {
                                                            handleMappingChange(e.target.value)
                                                        }} value={selectedMapping ? selectedMapping.mapping_name != {} ? selectedMapping.mapping_name : "" : ""}>
                                                        <option value={""}>No Mapping</option>
                                                        {(mappingList.map(p => (
                                                            <option value={p.mapping_name}>{p.mapping_name}</option>
                                                        )))}
                                                    </select>

                                                </div>
                                            </div> :
                                            <div class=" form-row row">
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <div class='row' style={{ paddingLeft: "18px" }}>
                                                            <label for="f2" class="d-block input-label">Producer Definition File Name (*.xml)</label>
                                                            <span style={{ paddingLeft: "10px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Upload an XML file containing information on the content of the input file" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                                        </div>
                                                        <div class="input-wrap upload-wrap">
                                                            <input type="text" id="uploadFile" className={`txt ${errorConst.producerFile ? 'is-invalid' : ''}`} value={channelData.producerFile ? channelData.producerFile.name : (inputDef.producerInpuDef ? inputDef.producerInpuDef : '')} />
                                                            {channelData.producerFile || inputDef.producerInpuDef ?
                                                                (<button class="button button--delete" onClick={(e) => {
                                                                    setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": "", "consumerFile": channelData.consumerFile, "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
                                                                    setInputDef({ "producerInpuDef": '', "consumerInpuDef": inputDef.consumerInpuDef, "mappingInpuDef": inputDef.mappingInpuDef, "fileValidationDef": inputDef.fileValidationDef });
                                                                    setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": "", "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency });
                                                                }}>Remove</button>) :
                                                                (<div class="fileupload-custom">
                                                                    <span>File Upload</span>
                                                                    <input id="uploadBtn" type="file" class="upload-input" accept="text/xml"
                                                                        onChange={(e) => {
                                                                            setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": e.target.files[0], "consumerFile": channelData.consumerFile, "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
                                                                            if (e.target.files[0]) {
                                                                                if (e.target.files[0].size / 1024 > 200) setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": "Please choose a file with size less than 200KB", "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency })
                                                                                else setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": "", "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency });
                                                                            }
                                                                        }} />
                                                                </div>)}
                                                            {errorConst.producerFile ?
                                                                (<div className="error-message">{errorConst.producerFile}</div>)
                                                                : ('')}
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* mapping output file */}
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <div class='row' style={{ paddingLeft: "18px" }}>
                                                            <label for="f2" class="d-block input-label">Consumer Definition File Name (*.xml)</label>
                                                            <span style={{ paddingLeft: "10px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Upload an XML file containing information on the content of the expected output file" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                                        </div>
                                                        <div class="input-wrap upload-wrap">
                                                            <input type="text" id="uploadFile" className={`txt ${errorConst.consumerFile ? 'is-invalid' : ''}`} value={channelData.consumerFile ? channelData.consumerFile.name : (inputDef.consumerInpuDef ? inputDef.consumerInpuDef : '')} />
                                                            {channelData.consumerFile || inputDef.consumerInpuDef ?
                                                                (<button class="button button--delete" onClick={(e) => {
                                                                    setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": channelData.producerFile, "consumerFile": '', "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
                                                                    setInputDef({ "producerInpuDef": inputDef.producerInpuDef, "consumerInpuDef": '', "mappingInpuDef": inputDef.mappingInpuDef, "fileValidationDef": inputDef.fileValidationDef });
                                                                    setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": "", "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency })
                                                                }}>Remove</button>) :
                                                                (<div class="fileupload-custom">
                                                                    <span>File Upload</span>
                                                                    <input id="uploadBtn" type="file" class="upload-input" accept="text/xml"
                                                                        onChange={(e) => {
                                                                            setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": channelData.producerFile, "consumerFile": e.target.files[0], "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
                                                                            if (e.target.files[0]) {
                                                                                if (e.target.files[0].size / 1024 > 200) setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": "Please choose a file with size less than 200KB", "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency })
                                                                                else setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": "", "mappingFile": errorConst.mappingFile, "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency })
                                                                            }
                                                                        }} />
                                                                </div>)}
                                                            {errorConst.consumerFile ?
                                                                (<div className="error-message">{errorConst.consumerFile}</div>)
                                                                : ('')}
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* mapping file  */}
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <div class='row' style={{ paddingLeft: "18px" }}>
                                                            <label for="f2" class="d-block input-label">Producer to Consumer Definition mapping File Name (*.xml)</label>
                                                            <span style={{ paddingLeft: "10px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Upload an XML file containing the mapping relation between the input and expected output file" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                                        </div>
                                                        <div class="input-wrap upload-wrap">
                                                            <input type="text" id="uploadFile" className={`txt ${errorConst.mappingFile ? 'is-invalid' : ''}`} value={channelData.mappingFile ? channelData.mappingFile.name : (inputDef.mappingInpuDef ? inputDef.mappingInpuDef : '')} />
                                                            {channelData.mappingFile || inputDef.mappingInpuDef ?
                                                                (<button class="button button--delete" onClick={(e) => {
                                                                    setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": channelData.producerFile, "consumerFile": channelData.consumerFile, "mappingFile": '', "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
                                                                    setInputDef({ "producerInpuDef": inputDef.producerInpuDef, "consumerInpuDef": inputDef.consumerInpuDef, "mappingInpuDef": '' });
                                                                    setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": "", "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency })
                                                                }}>Remove</button>) :
                                                                (<div class="fileupload-custom">
                                                                    <span>File Upload</span>
                                                                    <input id="uploadBtn" type="file" class="upload-input" accept="text/xml"
                                                                        onChange={(e) => {
                                                                            setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": channelData.producerFile, "consumerFile": channelData.consumerFile, "mappingFile": e.target.files[0], "mappingName": channelData.mappingName, "validationFile": channelData.validationFile, "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
                                                                            if (e.target.files[0]) {
                                                                                if (e.target.files[0].size / 1024 > 200) setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": "Please choose a file with size less than 200KB", "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency })
                                                                                else setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": "", "validationFile": errorConst.validationFile, "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency })
                                                                            }
                                                                        }} />
                                                                </div>)}
                                                            {errorConst.mappingFile ?
                                                                (<div className="error-message">{errorConst.mappingFile}</div>)
                                                                : ('')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                </CollapsibleH5>
                                <div style={{ padding: "10px" }}></div>
                                <CollapsibleH5 title="File Frequency">
                                    <div style={{ padding: "10px" }}></div>
                                    <div class="col-lg-6 col-md-6">
                                        <div className="form" style={{ paddingLeft: "5px" }}>
                                            <input type="checkbox" id="frequency" checked={frequencyStatus ? true : false} onChange={(() => { setFrequencyStatus(!frequencyStatus) })} />
                                            <label for="frequency"> Do you want to set a file frequency for this channel? </label>
                                            <span style={{ paddingLeft: "10px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Sets an expected file frequency to this channel to automatically notify the producer regarding any missing files." data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                        </div>
                                    </div>
                                    <div style={{ padding: "10px" }}></div>
                                    {frequencyStatus ? <div class="row">
                                        <div style={{ padding: "10px" }}></div>
                                        {!(frequencyDetails.frequencyType == 'daily' && frequencyDetails.details == '') ? <div class="col-lg-3 col-md-3">
                                            <div class="form-group">
                                                <label for="f1" class="input-label">Frequency Type</label>
                                                <div class="form-row">
                                                    <input value={frequencyDetails.frequencyType[0].toUpperCase() + frequencyDetails.frequencyType.slice(1)} type="text" name="frequency_type" disabled="true" placeholder="Frequency Type" id="cc" />
                                                </div>
                                                <div style={{ "padding": "7px" }}></div>
                                                <label for="f1" class="input-label">Expected file count {frequencyDetails.frequencyType}</label>
                                                <div class="form-row">
                                                    <input value={frequencyDetails.fileCount} type="text" name="frequency_type" disabled="true" placeholder="Frequency Count" id="cc" />
                                                </div>
                                            </div>
                                        </div> : ""}
                                        <div class="col-lg-4 col-md-4">
                                            <div class="form-group" style={{ padding: '2px' }}>
                                                <label for="cc" class="input-label">Threshold wait time in Hour(s)<span style={{ color: 'red' }}>*</span></label>
                                                <span style={{ paddingLeft: "10px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Wait-time before a notification is sent to the producer, incase of a missing file" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                                <div class="form-row">
                                                    <input value={frequencyThreshold} disabled type="number" name="field_length" min={1} step={1} max={24} onChange={(event) => setFrequencyThreshold(event.target.value)} placeholder="Wait time in hours" id="cc" />
                                                </div>
                                                {errorConst.threshold ?
                                                    (<div className="error-message">{errorConst.threshold}</div>)
                                                    : ('')}
                                                <div style={{ "padding": "7px" }}></div>
                                                <label for="f1" class="input-label">{frequencyDetails.frequencyType == '' ? "Set " : "Change "}expected frequency<span style={{ color: 'red' }}>*</span></label>
                                                <span style={{ paddingLeft: "10px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Wait-time before a notification is sent to the producer, incase of a missing file" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                                <div class="input-wrap select-wrap">
                                                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter" style={{ color: "#5f5e5e", backgroundColor: "#f6f7f9", borderColor: "#e1e3e4", width: "100%", height: "47px" }}>
                                                        {frequencyDetails.frequencyType == '' ? "Set " : "Change "} frequency
                                                    </button>
                                                    {errorDetails.errorDays !== "" ?
                                                        <div className="error-message">{errorDetails.errorDays}</div>
                                                        : ''}
                                                    {errorDetails.errorTime !== "" ?
                                                        <div className="error-message">{errorDetails.errorTime}</div>
                                                        : ''}
                                                    {errorDetails.errorTimeAMPM !== "" ?
                                                        <div className="error-message">{errorDetails.errorTimeAMPM}</div>
                                                        : ''}
                                                    {errorConst.setFrequency ?
                                                        (<div className="error-message">{errorConst.setFrequency}</div>)
                                                        : ('')}
                                                </div>

                                            </div>
                                        </div>

                                    </div> : ""}
                                </CollapsibleH5>
                                {/* Modal */}
                                <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" style={{ "maxWidth": "920px" }} role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalCenterTitle">Set frequency</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-lg-4 col-md-4">
                                                        <div class="form-group">
                                                            <label for={"frequencyType"} class="d-block input-label">Select frequency type</label>
                                                            <div class="input-wrap date-wrap">
                                                                <select value={frequencyDetails.frequencyType} onChange={(e) => changeFrequencyType(e.target.value)} id={"frequencyType"}>
                                                                    <option value="daily">Daily</option>
                                                                    <option value="weekly">Weekly</option>
                                                                    <option value="monthly">Monthly</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4">
                                                        <div class="form-group">
                                                            <label for="cc" class="input-label">Threshold wait time in Hour(s)<span style={{ color: 'red' }}>*</span></label>
                                                            <span style={{ paddingLeft: "10px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Wait-time before a notification is sent to the producer, incase of a missing file" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                                            <div class="form-row">
                                                                <input value={frequencyThreshold} type="number" name="field_length" min={1} step={1} max={24} onChange={(event) => setFrequencyThreshold(event.target.value)} placeholder="Wait time in hours" id="cc" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4">
                                                        <div class="form-group">
                                                            <label for={"fileCount"} class="d-block input-label">Expected file count per {frequencyDetails.frequencyType == 'daily' ? "day" : frequencyDetails.frequencyType == 'weekly' ? "week" : "day"}</label>
                                                            <div class="input-wrap date-wrap">
                                                                <input value={fileCount} type="number" name="fileCount" min={1} step={1} max={5000} onChange={(event) => setFilecount(event.target.value)} placeholder="Expected number of files" id="cc" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {frequencyDetails.frequencyType == "daily" ?
                                                        <div class="col-lg-3 col-md-3" style={{ paddingTop: "35px" }}>
                                                            <div className="form" style={{ paddingLeft: "5px" }}>
                                                                <input type="checkbox" id="excludeweekends" checked={excludeWeekends ? true : false} onChange={(() => { setExcludeWeekends(!excludeWeekends) })} />
                                                                <label for="excludeweekends"> Exclude Weekends? </label>
                                                            </div>
                                                        </div> : null}
                                                    {frequencyDetails.frequencyType != "monthly" ?
                                                        (<div class="col-lg-4 col-md-4" style={{ paddingTop: "35px" }}>
                                                            <div className="form" style={{ paddingLeft: "5px" }}>
                                                                <input type="checkbox" id="selectTime" checked={selectTime ? true : false} onChange={(() => { setSelectTime(!selectTime) })} />
                                                                <label for="selectTime" style={{ 'paddingBottom': '10px' }}>Schedule Time ? <span style={{ paddingLeft: "10px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Select the estimated times of the arrival of files. Threshold wait-time will be calculated from the latest selected time, if nothing is selected a default time will be used" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span></label>
                                                            </div>
                                                        </div>) : (<div class="col-lg-4 col-md-4" style={{ paddingTop: "35px" }}>
                                                            <div className="form" style={{ paddingLeft: "5px" }}>
                                                                <input type="checkbox" id="selectDay" checked={selectTime ? true : false} onChange={(() => { setSelectTime(!selectTime) })} />
                                                                <label for="selectDay" style={{ 'paddingBottom': '10px' }}>Schedule Day ? <span style={{ paddingLeft: "10px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Select the estimated day of the arrival of files. Threshold wait-time will be calculated from the latest selected Day, if nothing is selected a default day will be used. If a day is unavailable in given a month the last day of that month will be automatically selected in place." data-pr-position="right" src={iconInfo} alt="info" /> </i> </span></label>
                                                            </div>
                                                        </div>)}


                                                    <div class="col-lg-12 col-md-12" >
                                                        {(selectTime) ? (selectedTimeList.map((block, index) => (
                                                            <div class="row" id={"SetTIme"}>
                                                                {frequencyDetails.frequencyType == "weekly" ?
                                                                    <div key={index} class="col-lg-4 col-md-4">
                                                                        <div class="form-group">
                                                                            <label for={"day" + index} class="d-block input-label"></label>
                                                                            <div class="input-wrap date-wrap">
                                                                                <select value={block.day} onChange={(event) => handleDayChange(event, index)}>
                                                                                    <option value="">Select Day</option>
                                                                                    {dayList.map((days) =>
                                                                                        <option value={days.fullDay}>{days.fullDay}</option>
                                                                                    )}
                                                                                </select>
                                                                                {errorDetails.errorDays !== "" ?
                                                                                    <div className="error-message">{errorDetails.errorDays}</div>
                                                                                    : ''}
                                                                                <div id={"errorAddDay" + index}></div>
                                                                            </div>
                                                                        </div>
                                                                    </div> : ''}
                                                                {frequencyDetails.frequencyType == "monthly" ?
                                                                    <div key={index} class="col-lg-4 col-md-4">
                                                                        <div class="form-group">
                                                                            <label for={"day" + index} class="d-block input-label"></label>
                                                                            <div class="input-wrap date-wrap">
                                                                                <select value={block.day} onChange={(event) => handleDayChange(event, index)}>
                                                                                    <option value="">Select Day</option>
                                                                                    {daysOptions.map((days) =>
                                                                                        <option value={days.value}>{days.value}</option>
                                                                                    )}
                                                                                </select>
                                                                                {errorDetails.errorDays !== "" ?
                                                                                    <div className="error-message">{errorDetails.errorDays}</div>
                                                                                    : ''}
                                                                                <div id={"errorAddDay" + index}></div>
                                                                            </div>
                                                                        </div>
                                                                    </div> : ''}

                                                                <div class="col-lg-2 col-md-2">
                                                                    <div class="form-group">
                                                                        <label for={"time" + 0} class="d-block input-label"></label>
                                                                        <div class="input-wrap date-wrap">
                                                                            <select value={block.time} onChange={(event) => handleTimeChange(event, index)}>
                                                                                <option value="">Select Time</option>
                                                                                {timeList.map((time) =>
                                                                                    <option value={time}>{time}</option>
                                                                                )}
                                                                            </select>
                                                                            {errorDetails.errorTime !== "" ?
                                                                                <div className="error-message">{errorDetails.errorTime}</div>
                                                                                : ''}
                                                                            <div id={"errorAddTime" + index}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-2 col-md-2">
                                                                    <div class="form-group">
                                                                        <label for={"timeAMPM" + 0} class="d-block input-label"></label>
                                                                        <div class="input-wrap date-wrap">
                                                                            <select value={block.AmPm} onChange={(event) => handleAmPmChange(event, index)}>
                                                                                <option value="">AM/PM</option>
                                                                                <option value="AM">AM</option>
                                                                                <option value="PM">PM</option>
                                                                            </select>
                                                                            {errorDetails.errorTimeAMPM !== "" ?
                                                                                <div className="error-message">{errorDetails.errorTimeAMPM}</div>
                                                                                : ''}
                                                                            <div id={"errorAddTimeAmPm" + index}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <div class="form-group">
                                                                        <label class="input-label"></label>
                                                                        <div class="input-wrap select-wrap">
                                                                            {selectedTimeList.length - 1 == index ?
                                                                                <input type="button" class="txt" style={{ width: "100%", height: "25px", backgroundColor: '#cd0000', border: '1px solid #cd0000', color: '#fff', borderRadius: '4px', fontWeight: 'bold' }} onClick={() => addTimeList()} value="Add" />
                                                                                : <input type="button" class="txt" style={{ width: "100%", height: "25px", backgroundColor: '#fff', border: '1px solid #cd0000', color: '#000', borderRadius: '4px', fontWeight: 'bold' }} onClick={() => removeTimeList(index)} value="Remove" />}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        ))
                                                        ) : null}
                                                    </div>
                                                    <div class="col-lg-12 col-md-12" id="appendTimeList"></div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="button" onClick={() => getSavedDetails()} data-dismiss={isFrequencySaveValid() ? "modal" : ""}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ padding: "10px" }}></div>
                                <CollapsibleH5 title="File Content Validation">
                                    <div style={{ padding: "10px" }}></div>
                                    {/* <h2 class="mb-4">File Content Validation</h2> */}
                                    <div class="row">
                                        {/* validation file  */}
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <div class='row' style={{ paddingLeft: "18px" }}>
                                                    <label for="f2" class="d-block input-label">Validation File Name (*.xml)</label>
                                                    <span style={{ paddingLeft: "10px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Upload an XML file containing information on the expected content of the file for content validation" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                                </div>
                                                <div class="input-wrap upload-wrap">
                                                    <input type="text" id="uploadFile" className={`txt ${errorConst.validationFile ? 'is-invalid' : ''}`} value={channelData.validationFile ? channelData.validationFile.name : (inputDef.fileValidationDef ? inputDef.fileValidationDef : '')} />
                                                    {channelData.validationFile || inputDef.fileValidationDef ?
                                                        (<button class="button button--delete" onClick={(e) => {
                                                            setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": channelData.producerFile, "consumerFile": channelData.consumerFile, "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": '', "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath });
                                                            setInputDef({ "producerInpuDef": inputDef.producerInpuDef, "consumerInpuDef": inputDef.consumerInpuDef, "mappingInpuDef": inputDef.mappingInpuDef, "fileValidationDef": '' });
                                                            setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": "", "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency })
                                                        }}>Remove</button>) :
                                                        (<div class="fileupload-custom">
                                                            <span>File Upload</span>
                                                            <input id="uploadBtn" type="file" class="upload-input" accept="text/xml"
                                                                onChange={(e) => {
                                                                    setChannelData({ "handBackChannel": channelData.handBackChannel, "channelId": channelData.channelId, "channelName": channelData.channelName, "templateId": channelData.templateId, "producerPartnerCode": channelData.producerPartnerCode, "consumerPartnerCode": channelData.consumerPartnerCode, "producerFile": channelData.producerFile, "consumerFile": channelData.consumerFile, "mappingFile": channelData.mappingFile, "mappingName": channelData.mappingName, "validationFile": e.target.files[0], "exco": channelData.exco, "fileName": channelData.fileName, "status": channelData.status, "consumerFolderPath": channelData.consumerFolderPath, "sequenceValidation": channelData.sequenceValidation, "frequencyDetails": channelData.frequencyDetails });
                                                                    if (e.target.files[0]) {
                                                                        if (e.target.files[0].size / 1024 > 200) setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": "Please choose a file with size less than 200KB", "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency })
                                                                        else setErrorConst({ "channelName": errorConst.channelName, "templateId": errorConst.templateId, "producerPartnerCode": errorConst.producerPartnerCode, "consumerPartnerCode": errorConst.consumerPartnerCode, "producerFile": errorConst.producerFile, "consumerFile": errorConst.consumerFile, "mappingFile": errorConst.mappingFile, "validationFile": "", "exco": errorConst.exco, "fileName": errorConst.fileName, "consumerFolderPath": errorConst.consumerFolderPath, "threshold": errorConst.threshold, "setFrequency": errorConst.setFrequency })
                                                                    }
                                                                }} />
                                                        </div>)}
                                                    {errorConst.validationFile ?
                                                        (<div className="error-message">{errorConst.validationFile}</div>)
                                                        : ('')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CollapsibleH5>
                            </div>
                            <div class="btn-wrap btn-wrap--grp mt-5 justify-content-end">
                                <button class="button button--outline" onClick={() => resetForm()}>Reset</button>
                                <button className="button" onClick={() => folderConfirm(channelData.consumerFolderPath)}>Save</button>
                            </div>
                        </div>
                    </div>
                    : null}
                {
                    menuMode == 'manage' ?
                        <div class="grey-block">
                            <div class="container">
                                <div class="form mb-5">
                                    <h2 class="mb-4">Search Channels</h2>
                                    <div class="row">

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">

                                                <label for="f1" class="input-label">File Name <span style={{ color: 'red' }}>*</span></label>
                                                <div class="input-wrap select-wrap">
                                                    <input type="text" class="txt" placeholder="File Name" value={requestBody.fileName} onChange={(e) => setRequestBody({ "fileName": e.target.value, "partnerCode": requestBody.partnerCode, "status": requestBody.status, "channelName": requestBody.channelName, "limit": requestBody.limit })} />
                                                </div>
                                                {/* <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={FileNameList} constrains={constrainsFileName} setResponseData={setResponseFileNameData}></CustomDropdownWidget> */}
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={partnerList} constrains={partnerConstrains} setResponseData={setResponsePartnerData}></CustomDropdownWidget>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={channelListForDropDown} constrains={constrainsChannel} setResponseData={setResponseChannelData}></CustomDropdownWidget>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label for="f1" class="d-block input-label">Status</label>
                                                <div class="input-wrap select-wrap">
                                                    <select id="f1" onChange={(e) => { setRequestBody({ "fileName": requestBody.fileName, "partnerCode": requestBody.partnerCode, "status": e.target.value, "channelName": requestBody.channelName, "limit": requestBody.limit }) }}
                                                        value={requestBody.status}>
                                                        <option value="">Any</option>
                                                        <option value="A">Enabled</option>
                                                        <option value="I">Disabled</option>
                                                        {(statusList.loading) ? '' :
                                                            statusList.data.map(s => (
                                                                <option value={s.value}>{s.value}</option>
                                                            ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="f2" class="d-block input-label">Search Result Limit</label>
                                                <div class="input-wrap date-wrap">
                                                    <select id="limit" onChange={(e) => { setLimitToResponsedataAndRetrivelimit(e.target.value) }}>
                                                        {(retreiveLimitList.loading) ? '' :
                                                            retreiveLimitList.data.map(s => (
                                                                <option value={s} selected={retreieveLimit === s ? 'selected' : ''}>{s} Records</option>
                                                            ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-wrap btn-wrap--grp mt-3 justify-content-end">
                                    <button class="button button--outline" onClick={() => resetForm()}>Reset</button>
                                    <button class="button" onClick={() => onSearch()}>Search</button>
                                </div>
                            </div>
                        </div> : ""
                }
                <div class="container">
                    <div class="table-block">
                        <div class="table-block__head">
                            <h2>List of Channels
                                <span style={{ "fontWeight": "bold", "fontSize": "13px", "color": "#666" }} >
                                    &nbsp; [ Total: {statusCount.active_count + statusCount.inactive_count ? statusCount.active_count + statusCount.inactive_count : 0} &nbsp; Active: {statusCount.active_count ? statusCount.active_count : 0} &nbsp; Inactive: {statusCount.inactive_count ? statusCount.inactive_count : 0} ]
                                </span> </h2>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <button type="button" style={{ background: '#F8F8F8', color: 'black', border: 'none' }} className="btn btn-secondary btn-sm" onClick={(e) => isExportVisible ? setIsExportVisible(false) : setIsExportVisible(true)}>
                                <img src={iconListExport} alt="Success" />  Export
                                </button>
                                <div>
                                {isExportVisible ? 
                                    <div class='fh-legend widget exportXl'>
                                    <p style={{ color: '#000' }}><strong>Export As</strong></p>
                                    <ul>
                                        <li style={{ cursor: 'help'}} onClick={(e) => exportClickExcelDownload()}><img src={iconExcel} alt="Success" /> Excel</li>
                                    </ul>
                                    </div> 
                                : ''}
                                </div>
                                <div class="txt-wrap">
                                    <input type="text" class="txt" placeholder="Search..."
                                        value={channelSearchKey} onChange={(e) => setChannelSearchKey(e.target.value)} />
                                    <i class="fa fa-search"></i>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive grid">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" onClick={() => onSort('channelname')} style={{ cursor: "pointer" }}>Channel Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => onSort('templateName')} style={{ cursor: "pointer" }}>Template Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => onSort('producerpartnercode')} style={{ cursor: "pointer" }}>Producer <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => onSort('consumerpartnercode')} style={{ cursor: "pointer" }}>Consumer <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => onSort('filename')} style={{ cursor: "pointer" }}>File Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        {/* <th scope="col" onClick={() => onSort('status')} style={{ cursor: "pointer" }}>Status <i className="fa fa-sort" aria-hidden="true"></i></th> */}

                                        {isAdmin() || isPartnerAdmin() ? <th scope="col">Action</th> : ""}
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {(filteredChannelList.loading || filteredChannelList.data.length == 0) ? (<tr><td colSpan="100"><div className="text-center text-danger">No channel data available</div></td></tr>) :
                                        filteredChannelList.data.map((channel) =>
                                        (<tr>
                                            <td>{channel.channelname}</td>
                                            <td>{channel.templateName}</td>
                                            <td>{channel.producerpartnercode}</td>
                                            <td>{channel.consumerpartnercode}</td>
                                            <td>{channel.filename}</td>
                                            {isAdmin() || isPartnerAdmin() ? <td>
                                                <div className="table__action">
                                                    <a href="javascript:void(0);" className="utils utils--outline" onClick={(e) => exportJsonData(channel)} title="Export Channel"> <img src={iconExport} alt="edit" /></a>
                                                    <a href="javascript:void(0);" className="utils utils--outline ml-1" onClick={(e) => onEditClick(channel)} title="Edit Channel"> <img src={iconEdit} alt="edit" /></a>
                                                    <a href="javascript:void(0);" className="utils utils--outline ml-2" onClick={(e) => deleteConfirm(channel.channelid, channel.channelname)} title="Delete Channel"> <img src={iconDelete} alt="edit" /></a>
                                                </div>
                                            </td> : ""}
                                            {
                                                (isAdmin() || isPartnerAdmin())
                                                    ?
                                                    <td>
                                                        <label class="switch" >
                                                            <input type="checkbox" checked={channel.status === "A" ? true : false}
                                                                onChange={(e) => isAdmin() || isPartnerAdmin() ? changeStatus(channel.channelid) : customMessageAlertMessage(false, "Access Denied!!!")} />
                                                            <span class="slider round" ></span>
                                                        </label>
                                                    </td>
                                                    :
                                                    <td>
                                                        <label class="switch" >
                                                            <td style={{ paddingLeft: "0px" }}>{channel.status === "A" ? <b style={{ color: "green" }}>Active</b> : <b style={{ color: "red" }}>Inactive</b>}</td>

                                                        </label>

                                                    </td>
                                            }

                                        </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </main >
        </React.Fragment >
    )
};

const mapDispatchToProps = dispatch => ({
    dispatchListTemplatesAction: (onSuccess, onError) =>
        dispatch(listTemplates(onSuccess, onError)),
    dispatchListPartnersByTemplateAction: (templateId, groupType, onSuccess, onError) =>
        dispatch(listPartnersByTemplate(templateId, groupType, onSuccess, onError)),
    dispatchListChannelsAction: (onSuccess, onError) =>
        dispatch(listChannels(onSuccess, onError)),
    dispatchDeleteChannelAction: (data, onSuccess, onError) =>
        dispatch(deleteChannel(data, onSuccess, onError)),
    dispatchCreateChannelAction: (data, onSuccess, onError) =>
        dispatch(createChannel(data, onSuccess, onError)),
    dispatchEditChannelAction: (data, onSuccess, onError) =>
        dispatch(editChannel(data, onSuccess, onError)),
    dispatchStatusChangeAction: (channelId, status, onSuccess, onError) =>
        dispatch(changeChannelStatus(channelId, status, onSuccess, onError)),
    dispatchChannelCount: (onSuccess, onError) =>
        dispatch(channelCount(onSuccess, onError)),
    dispatchSystemParamsAction: (data, onSuccess, onError) =>
        dispatch(fetchSystemParams(data, onSuccess, onError)),
    dispatchChannelSearchAction: (data, onSuccess, onError) =>
        dispatch(channelSearch(data, onSuccess, onError)),
    dispatchOneTimeParamsAction: (data, onSuccess, onError) => -
        dispatch(fetchOneTimeParams(data, onSuccess, onError)),
    dispatchListPartnersAction: (onSuccess, onError) =>
        dispatch(listPartners(onSuccess, onError)),
    dispatchGetAllActiveChannelsList: (onSuccess, onError) =>
        dispatch(getAllActiveChannelsList(onSuccess, onError)),
    dispatchListMappingAction: (onSuccess, onError) =>
        dispatch(displayMappingData(onSuccess, onError)),
    dispatchGetChannelFrequencyAction: (onSuccess, onError) =>
        dispatch(getChannelFrequency(onSuccess, onError)),
    dispatchChannelExportDetails: (onSuccess, onError) =>
        dispatch(getexportChannelDetails(onSuccess, onError)),
    dispatchGetAllBusinessFunctionList: (companyCodeForBusinessFunction, onSuccess, onError) =>
        dispatch(listAllBusinessFunctions(companyCodeForBusinessFunction, onSuccess, onError)),
});

export default connect(null, mapDispatchToProps)(ChannelComponent);
