import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { listPartners, displaySequenceNumberExcoData, editSequenceNumberTableData, isJson } from '../redux/actions/auth.action.creators';
import iconRetry from '../assets/images/icon-retry.png';
import iconSave from '../assets/images/save.png';
import iconEdit from '../assets/images/icon-edit.png';
import iconDelete from '../assets/images/icon-delete.png';

import { Tooltip } from 'primereact/tooltip';
import { dummyDataValue } from '../Bean/filestructure.js'






const SequenceNumberExcoComponent = ({ dispatchSequenceNumberExcoTableData, dispatchEditSequenceNumber }) => {

    const [sequenceSearchKey, setSequenceSearchKey] = useState('');
    const [sequenceNumberExcoTableList, setSequenceNumberExcoTableList] = useState({ "loading": true, "data": dummyDataValue });
    const [filterSequenceNumberExcoTableList, setFilterSequenceNumberExcoTableList] = useState([]);
    const [sortDirection, setSortDirection] = useState({ "channel_name": '', "exco": '', "sequence_number": '', "exco_version": '' });

    const [editRowInfo, setEditRowInfo] = useState({})







    const sequenceNumberExcoTableDataList = () => {
        dispatchSequenceNumberExcoTableData(({ data, message }) => {
            if (data) {
                setSequenceNumberExcoTableList({ "loading": false, "data": data });
                setFilterSequenceNumberExcoTableList(data);
            }
        },
            (message) => {
                // console.log("data",data)
                console.log("sequence number list error--  ", message);
            });
    }

    useEffect(() => {
        sequenceNumberExcoTableDataList()
    }, [])

    useEffect(() => {
        resetRowDetails()
        let filtered = _.cloneDeep(sequenceNumberExcoTableList.data);
        if (!sequenceNumberExcoTableList.loading) {
            filtered = filterSequenceNumberExcoTableList.filter(c => {
                return (((c.channel_name == null || c.channel_name == '') ? false : c.channel_name.toLowerCase().includes(sequenceSearchKey.toLowerCase()))
                    || ((c.exco == null || c.exco == '') ? false : c.exco.toLowerCase().includes(sequenceSearchKey.toLowerCase()))
                )
            });
        }
        setSequenceNumberExcoTableList({ "loading": false, "data": filtered });
    }, [sequenceSearchKey])


    function sortFn(dir, sortArr, type) {
        let result = sortArr.sort((a, b) => {
            if (a[type] < b[type]) {
                return dir === "asc" ? -1 : 1;
            }
            if (a[type] > b[type]) {
                return dir === "asc" ? 1 : -1;
            }
            return 0;
        });
        console.log("result: ", result)
        console.log("sequencenpm start list: ", sequenceNumberExcoTableList)
        return result;
    }

    function onSort(type) {
        resetRowDetails()
        let dir = "asc";
        if (sortDirection[type] === "asc") {
            dir = "desc";
        }

        if (type === 'channel_name') {
            setSortDirection({ "channel_name": dir, "exco": sortDirection.exco, "sequence_number": sortDirection.sequence_number, "exco_version": sortDirection.exco_version });
        } else if (type === 'exco') {
            setSortDirection({ "channel_name": sortDirection.channel_name, "exco": dir, "sequence_number": sortDirection.sequence_number, "exco_version": sortDirection.exco_version });
        } else if (type === 'sequence_number') {
            setSortDirection({ "channel_name": sortDirection.channel_name, "exco": sortDirection.exco, "sequence_number": dir, "exco_version": sortDirection.exco_version });
        } else if (type === 'exco_version') {
            setSortDirection({ "channel_name": sortDirection.channel_name, "exco": sortDirection.exco, "sequence_number": sortDirection.sequence_number, "exco_version": dir });
        }
        setSequenceNumberExcoTableList({ "loading": false, "data": sortFn(dir, sequenceNumberExcoTableList.data, type) })
    }



    const resetRowDetails = () => {
        console.log("rowreset")
        setEditRowInfo({})

    }

    const saveRowDetails = () => {

        console.log("rowsave");
        var requestRowData = { 'id': editRowInfo.id, 'channelId': 0, 'sequenceNumber': editRowInfo.sequence_number, 'exco': editRowInfo.exco, 'excoVersion': editRowInfo.exco_version, 'sequenceCheck': editRowInfo.sequence_check }

        dispatchEditSequenceNumber(requestRowData, ({ data, message }) => {
            window.scrollTo(0, 0);
            resetRowDetails();
            sequenceNumberExcoTableDataList();


        },
            (message) => {
                console.log("request-message--  ", message)
                window.scrollTo(0, 0);
                resetRowDetails();

            });


    }




    const editRowDetails = (rowData, rowIndex) => {
        console.log(rowData);
        rowData.rowIndex = rowIndex
        setEditRowInfo(rowData)


    }

    const updatedRowInfoDetails = (rowKey, rowValue) => {
        console.log(rowValue);
var temp = {
            sequence_number: rowKey === "sequence_number" ? rowValue : editRowInfo.sequence_number,
            exco: editRowInfo.exco,
            channel_name: editRowInfo.channel_name,
            exco_version: rowKey === "exco_version" ? rowValue : editRowInfo.exco_version,
            rowIndex: editRowInfo.rowIndex,
            id: editRowInfo.id,
            sequence_check: rowKey === "sequence_check" ? rowValue === "false"?true:false : editRowInfo.sequence_check,

        }
        setEditRowInfo(temp)
    }



    return (

        <main>
            <div class="container">
                <ul class="breadcrumb">
                    <li class="breadcrumb__item">
                        <a class="breadcrumb__link" href="#">Configurations</a>
                    </li>

                    <li class="breadcrumb__item">
                        <span class="breadcrumb__link">Sequence Number Update</span>
                    </li>
                </ul>
                <div class="title">
                    <h1>Sequence Number Update</h1>
                </div>
            </div>


            <div class="container">
                <div class="table-block">
                    <div class="table-block__head">
                        <h2>List of Sequence Numbers
                            <span style={{ "fontWeight": "bold", "fontSize": "13px", "color": "#666" }} >
                                &nbsp; [ Total: {filterSequenceNumberExcoTableList.length ? filterSequenceNumberExcoTableList.length : 0} &nbsp;]
                            </span>
                        </h2>
                        <div class="txt-wrap">
                            <input type="text" class="txt" placeholder="search..."
                                value={sequenceSearchKey} onChange={(e) => setSequenceSearchKey(e.target.value)} />
                            <i class="fa fa-search"></i>
                        </div>
                    </div>
                </div>

                <div className="table-responsive grid">
                    <table class="table" id="SequenceNumberTable">
                        <thead>
                            <tr>
                                <th scope="col" style={{ zIndex: "0" }} onClick={() => onSort('channel_name')} style={{ cursor: "pointer" }}>Channel Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                                <th scope="col" onClick={() => onSort('exco')} style={{ cursor: "pointer" }}>Exco <i className="fa fa-sort" aria-hidden="true"></i></th>
                                <th scope="col" onClick={() => onSort('exco_version')} style={{ cursor: "pointer" }}>Exco Version <i className="fa fa-sort" aria-hidden="true"></i></th>
                                <th scope="col" onClick={() => onSort('sequence_number')} style={{ cursor: "pointer" }}>Sequence Number <i className="fa fa-sort" aria-hidden="true"></i></th>
                                <th scope="col" className="no-sort">Sequence Validation </th>

                                <th scope="col" className="no-sort">Action</th>

                            </tr>
                        </thead>

                        <tbody>
                            {(sequenceNumberExcoTableList.loading || sequenceNumberExcoTableList.data.length == 0) ? (<tr><td colSpan="6"><div className="text-center text-danger">No search results found</div></td></tr>) :
                                sequenceNumberExcoTableList.data.map((data, index) => (
                                    <tr>
                                        <td scope="col">{data.channel_name}</td>
                                        <td scope="col">{data.exco}</td>


                                        <td scope='col'>{editRowInfo && editRowInfo.rowIndex === index ? <input className='txt' type="number" onChange={(e) => updatedRowInfoDetails('exco_version', e.target.value)} value={editRowInfo.exco_version} name="sne-exco-version" id="sne-exco-version"></input> : data.exco_version}</td>
                                        <td scope='col'>{editRowInfo && editRowInfo.rowIndex === index ? <input className='txt' type="number" onChange={(e) => updatedRowInfoDetails('sequence_number', e.target.value)} value={editRowInfo.sequence_number} name = "sne-sequence-number" id = "sne-sequence-number"></input> : data.sequence_number}</td>

                                        <td scope='col'>
                                            {editRowInfo && editRowInfo.rowIndex === index ? 
                                            <label class="switch" ><input className='txt'  type="checkbox"  
                                            onChange={(e) => updatedRowInfoDetails('sequence_check', e.target.value)} 
                                            value={editRowInfo.sequence_check}
                                            checked={editRowInfo.sequence_check}></input>
                                             <span class="slider round"  checked={editRowInfo.sequence_check} ></span>  </label> :
                                         <label class="switch" ><input className='txt' disabled = 'true' type="checkbox"  
                                         checked={data.sequence_check}></input> <span class="slider round" ></span>  </label> }
                                         </td>
                                       



                                        <td scope='col'>
                                            <div className="table__action">
                                                <a href="javascript:void(0);" className="utils utils--outline" onClick={(e) => { editRowInfo && editRowInfo.rowIndex === index ? saveRowDetails() : editRowDetails(data, index) }}> <img src={editRowInfo && editRowInfo.rowIndex === index ? iconSave : iconEdit} alt={editRowInfo && editRowInfo.rowIndex === index ? "save" : "edit"} title={editRowInfo && editRowInfo.rowIndex === index ? "Save Sequence Number" : "Edit Sequence Number"} /></a>
                                                {editRowInfo && editRowInfo.rowIndex === index ?
                                                    <a href="javascript:void(0);" className="utils utils--outline ml-2" onClick={(e) => resetRowDetails()}> <img src={iconRetry} alt={"Reset"} title = "Reset Row"/></a>


                                                    : ""}

                                                <td>

                                                </td>


                                            </div>
                                        </td>

                                       

                                    </tr>
                                )
                                )}
                        </tbody>
                    </table>
                </div>
            </div>
        </main>

    )
}



const mapDispatchToProps = dispatch => ({


    dispatchSequenceNumberExcoTableData: (onSuccess, onError) => dispatch(displaySequenceNumberExcoData(onSuccess, onError)),
    dispatchEditSequenceNumber: (data, onSuccess, onError) => dispatch(editSequenceNumberTableData(data, onSuccess, onError)),

})


export default connect(null, mapDispatchToProps)(SequenceNumberExcoComponent)


