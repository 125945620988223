import React, {  useState } from 'react';
import { connect } from 'react-redux';
import _ from "lodash";

import { changePassword, isJson, sessionErrorCheck, logoutUser } from '../redux/actions/auth.action.creators';
import AlertFailure from '../utils/alert-failure.component';
import AlertSuccess from '../utils/alert-success.component';
import * as constants from '../redux/constants';
var CryptoJS = require("crypto-js");
const appConf = require(process.env.REACT_APP_CONFIG_URL);

const ChangePwdForm = ({ dispatchChangePwdAction, history, dispatchLogoutAction }) => {

    // const userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
    // const userInfos = localStorage.getItem('USER_INFO');
    const userInfos = sessionErrorCheck() !== null ? sessionErrorCheck() : window.location = '/auth';
    // var decryptByte = CryptoJS.AES.decrypt(userInfos, secretKey.switch.secretKey);
    // const userInfo = JSON.parse(decryptByte.toString(CryptoJS.enc.Utf8));
    // const userInfo = JSON.parse(window.atob(localStorage.getItem('USER_INFO')));
    // const userInfo = localStorage.getItem('USER_INFO') === null ? null : (!isJson(localStorage.getItem('USER_INFO')) ? JSON.parse(decryptByte.toString(CryptoJS.enc.Utf8)) : null) ;
    const userInfo = userInfos;
    const [currPwd, setCurrPwd] = useState('');
    const [newPwd, setNewPwd] = useState('');
    const [confPwd, setConfPwd] = useState('');
    const [errorConst, setErrorConst] = useState({});
    const [failMsg, setFailMsg] = useState('');
    const [succMsg, setSuccMsg] = useState('');

    const onSubmit = (event) => {
        event.preventDefault();
        if (isFormValid()) {
            var json = { "currentPassword": currPwd, "newPassword": newPwd, "email": userInfo.email };
            console.log("Test json created ==> ", json);
            const data = CryptoJS.AES.encrypt(JSON.stringify(json), CryptoJS.enc.Utf8.parse(appConf.switch.aesKey), {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            }).toString();

            console.log("Encrypted data => ", data)

            dispatchChangePwdAction(data, ({ data, message }) => {
                if (data) {
                    window.scrollTo(0, 0);
                    setSuccMsg(data);
                    setTimeout(() => {
                        dispatchLogoutAction(() => { });
                    }, 1500);
                }
            },
                (message) => {
                    window.scrollTo(0, 0);
                    setFailMsg(message);
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                });
        }
    };

    const isFormValid = () => {
        const tempError = _.cloneDeep(errorConst);        
        tempError.currPwd = !currPwd ? "Enter current password" : (!(new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(currPwd))? "Invalid password pattern" : '');
        tempError.newPwd = !newPwd ? "Enter new password" : (!(new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(newPwd))? "Invalid password pattern" : '');
        tempError.pwdMismatch = !confPwd ? "Re-enter new password" : ((newPwd !== confPwd) ? "Please ensure passwords match" : '');
        setErrorConst(tempError);
        return (!tempError.currPwd && !tempError.newPwd && !tempError.pwdMismatch);
    };

    return (
        <React.Fragment>
            <main>
                <div className="container">
                    <ul className="breadcrumb">
                    <li className="breadcrumb__item">
                    <a className="breadcrumb__link" href="/dashboard">Home</a>
                    </li>
                    <li className="breadcrumb__item">
                    <a className="breadcrumb__link" href="/changepwd">Change Password</a>
                    </li>
                    </ul>
                    {failMsg ? 
                    <AlertFailure message={failMsg}/> : ''}
                    {succMsg ? 
                    <AlertSuccess message={succMsg}/> : ''}
                </div>
                <div className="password password--change">
                    <h1>Change Password</h1>
                    <form noValidate onSubmit={onSubmit}>
                        <label className="label">Current Password</label>
                        <div className="input-wrap txt-wrap">
                            <input autoComplete="off" value={currPwd} onChange={(e) => setCurrPwd(e.target.value)} 
                            type="password" className={`txt ${errorConst.currPwd ? 'is-invalid' : ''}`} required />
                            {errorConst.currPwd ?
                            (<div className="error-message">{errorConst.currPwd}</div>)
                            : ('')}
                        </div>
                        <label className="label">New Password</label>
                        <div className="input-wrap txt-wrap">
                            <input autoComplete="off" value={newPwd} onChange={(e) => setNewPwd(e.target.value)} 
                            type="password" className={`txt ${errorConst.newPwd ? 'is-invalid' : ''}`} required />
                            {errorConst.newPwd ?
                            (<div className="error-message">{errorConst.newPwd}</div>)
                            : ('')}
                            <div className="policy">
                                <ul>
                                    <li>Minimum password length: 8 </li>  
                                    <li> Maximum password length: 20</li>  
                                    <li>The password should contain atleast 1 number</li>  
                                    <li> The password should contain atleast 1 capital letter</li>  
                                    <li> The password should contain atleast 1 small letter</li>  
                                    <li> The password should contain atleast 1 special character</li>  
                                </ul>
                            </div>
                        </div>
                        <label className="label">Re-enter new Password</label>
                        <div className="input-wrap txt-wrap">
                            <input autoComplete="off" value={confPwd} onChange={(e) => setConfPwd(e.target.value)} 
                            type="password" className={`txt ${errorConst.pwdMismatch ? 'is-invalid' : ''}`} required />
                            {errorConst.pwdMismatch ?
                            (<div className="error-message">{errorConst.pwdMismatch}</div>)
                            : ('')}
                        </div>
                        <div className="btn-wrap">
                            <button type="submit" className="button">Save Password</button>
                        </div>
                    </form>                    
                </div>
            </main>
        </React.Fragment>
    );
};

const mapDispatchToProps = dispatch => ({
    dispatchChangePwdAction: (data, onSuccess, onError) =>
        dispatch(changePassword({data }, onSuccess, onError)),
    dispatchLogoutAction: () => dispatch(logoutUser())
});

export default connect(null, mapDispatchToProps)(ChangePwdForm);