import React, { useEffect, useState } from "react";
import sort_icon from '../../assets/images/icon-sort.png';
import close_icon from '../../assets/images/icon-close.png';


import { getDownloadFailedReportData, downloadFailedRecordsAsExcelFile, lastFailedDownloadOccursAt } from '../../redux/actions/auth.action.creators'
import { DownloadFileAsXLFromEncodedString } from '../../utils/DecodeToXL'
import { connect } from 'react-redux'
import * as constants from '../../redux/constants'
import { SortObjectArray } from "../../utils/ArraySort";

const appConf = require('../'+process.env.REACT_APP_CONFIG_URL);



const ComponentForFailedReport = (props, { dispatchGetDownloadFailedReportData, dispatchGetDownloadFailedRecordsAsFile, dispatchLastFailedDownloadOccursAt }) => {

   const [isPopupEnable, setIsPopupEnable] = useState(false)
   const [failedDownloadRecords, setFailedDownloadRecords] = useState([])
   const [lastDay, setLastDay] = useState('0')
   const [isSortByAsc, setIsSortByAsc] = useState({ "count": true, "code": true })


   useEffect(() => {
      props.dispatchLastFailedDownloadOccursAt(({ data, onSuccess, onError }) => {
         if (data) {
            let runDate = new Date();
            let currentDate = new Date();
            console.log(data);
            runDate = new Date(data);
            runDate = runDate.toLocaleString("en-US", { timeZone: appConf.TIMEZONE });
            currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: appConf.TIMEZONE}));
            var day = (Math.abs(new Date(currentDate) - new Date(runDate)) / (1000 * 60 * 60 * 24))
            setLastDay(day | 0)
         }
      });
   }, [])


   const getReportData = () => {
      props.dispatchGetDownloadFailedReportData(({ data, onSuccess, onError }) => {
         if (data) {
            setFailedDownloadRecords(data)
            setIsPopupEnable(true)
         } else {
            setIsPopupEnable(false)
         }
      })
   }


   const downloadReportDataAsFile = () => {
      props.dispatchGetDownloadFailedRecordsAsFile(({ data, onSuccess, onError }) => {
         if (data && data.length > 1) {
            DownloadFileAsXLFromEncodedString(data, constants.FAILED_DOWNLOAD_REPORT_PREFIX)
            props.enablePopup(true, "Report Download Successfully")
         } else {
            props.enablePopup(false, "Report Download Failed.")
         }
         setIsPopupEnable(false)
      })
   }



   const sortData = (isSortByConsumerCode) => {
      if (isSortByConsumerCode) {
         setFailedDownloadRecords(SortObjectArray("consumer_code", false, isSortByAsc.code ? false : true, failedDownloadRecords))
         setIsSortByAsc({ "count": isSortByAsc.count, "code": isSortByAsc.code ? false : true })
      } else {
         setFailedDownloadRecords(SortObjectArray("count", true, isSortByAsc.count ? false : true, failedDownloadRecords))
         setIsSortByAsc({ "count": isSortByAsc.count ? false : true, "code": isSortByAsc.code })
      }
   }

   return (
      <div class="col-lg-4 mb-4">
         <div class="widget widget--box widget--report" data-toggle="modal" data-target="#failedReportPopup" id="rprt_faileddwnld">
            <div class="widget--report__details" onClick={(() => { getReportData() })}>
               <div class="widget--report__title">
                  <p><strong>Failed Download Report</strong></p>
               </div>
               <div class="widget--report__desc">
                  <p>This report gives the lists of files which are not downloaded by partner.
                  </p>
               </div>
               <div class="widget--report__date">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                  <span> {lastDay} days ago</span>
               </div>
            </div>
         </div>

         <div className={`modal fade modal--addnew ${isPopupEnable ? 'show' : ''}`}
            id="failedReportPopup" data-backdrop="static" data-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-modal="true" data-toggle="modal">
            <div class="modal-dialog modal-dialog-centered">
               <div class="modal-content">
                  <div class="modal-body">
                     <div class="container">
                        <div class="table-block">
                           <div class="table-block__head">
                              <h2>Failed Download Report</h2>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                 <img src={close_icon} alt="Close" />
                              </button>
                           </div>
                           <div class="table-responsive grid">
                              <table class="table">
                                 <thead>
                                    <tr>
                                       <th scope="col">Consumer Code <span onClick={(() => { sortData(true) })}><i className="sort"><img src={sort_icon} /></i></span></th>
                                       <th scope="col">Count <span onClick={(() => { sortData(false) })}><i className="sort"><img src={sort_icon} /></i></span> </th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {isPopupEnable && failedDownloadRecords.length > 0 ? failedDownloadRecords.map((reportInfo) => (
                                       <tr> <td>{reportInfo.consumer_code}</td> <td>{reportInfo.count}</td> </tr>
                                    )) : ""}

                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="modal-footer">
                     <button type="button" class="button button--outline button--sm" onClick={(() => { setIsPopupEnable(false) })} data-dismiss="modal">Cancel</button>
                     <button type="button" class="button button button--sm" onClick={(() => { downloadReportDataAsFile() })} data-dismiss="modal">Download Report</button>
                  </div>
               </div>
            </div>
         </div>
      </div>

   )



}


const mapDispatchToProps = dispatch => ({
   dispatchGetDownloadFailedReportData: (onSuccess, onError) =>
      dispatch(getDownloadFailedReportData(onSuccess, onError)),
   dispatchGetDownloadFailedRecordsAsFile: (onSuccess, onError) =>
      dispatch(downloadFailedRecordsAsExcelFile(onSuccess, onError)),
   dispatchLastFailedDownloadOccursAt: (onSuccess, onError) =>
      dispatch(lastFailedDownloadOccursAt(onSuccess, onError))
})

export default connect(null, mapDispatchToProps)(ComponentForFailedReport)