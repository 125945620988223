import React, { useState, useEffect } from "react"; 

// import { getDownloadFailedReportData, downloadFailedRecordsAsExcelFile } from '../../redux/actions/auth.action.creators'
import { DownloadFileAsXLFromEncodedString } from '../../utils/DecodeToXL'
import { fetchOneTimeParams, downloadClogReportAsExcelFile, fetchSystemParams, failedCloggedFileCount } from '../../redux/actions/auth.action.creators'
import { connect } from 'react-redux' 
import * as constants from '../../redux/constants'
import { DateRangePicker } from '../../utils/date-range-picker.component'
import close_icon from '../../assets/images/icon-close.png';


const ComponentForSrEmailCloggingReport = ({ dispatchOneTimeParamsAction, dispatchClogReport, dispatchSystemParamsAction, dispatchgetFailureCount }) => {
   const [failureCount, setFailureCount] = useState('0')
   const [isPopupEnable, setIsPopupEnable] = useState(false)
   const [errorsForDateFeild, setErrorsForDateFeild] = useState('');
   const [statusList, setStatusList] = useState({ "data": "", "loading": true });  
   const [mailboxList, setMailBoxList] = useState({ "data": "", "loading": true });  
   const [dateResponseData, setDateResponseData] = useState("false");
   const [dateRange, setDateRange] = useState(3);
   const [resetCalender, setResetCalender] = useState(false);
   const [requestBody, setRequestBody] = useState({ "mailbox": "","status": "", "startDate": "", "endDate": "" })

   const valueIsNull = (v1) => { return v1 === null || v1 === "" || v1.length < 1 ? true : false; }
   const valueIsNotNull = (v1) => { return v1 !== null && v1 !== "" ? true : false; }
  
   const setValueToRequestBody = (target, targetValue) => {
         setRequestBody({
            "mailbox": target === "mailbox" ? targetValue : requestBody.mailbox,
            "status": target === "status" ? targetValue : requestBody.status,
            "startDate": target === "startDate" ? targetValue : requestBody.startDate,
            "endDate": target === "endDate" ? targetValue : requestBody.endDate
         })
      }


   const isValid = () => {
         if(requestBody.status=='SUCCESS'){
            requestBody.status="1";
         }
         else if(requestBody.status=="FAILED"){
            requestBody.status="2";
         }
         if (valueIsNull(requestBody.startDate)) {
            return false;
         } else if (valueIsNull(requestBody.endDate)) {
            return false;
         } else if (valueIsNotNull(requestBody.startDate) && valueIsNotNull(requestBody.endDate)) { 
            var tempError = Math.ceil(Math.abs(new Date(requestBody.endDate) - new Date(requestBody.startDate)) / (1000 * 60 * 60 * 24)) > dateRange ? "Maximum date range is " + dateRange : ""; 
            console.log(tempError);
            setErrorsForDateFeild(tempError)
            if (valueIsNull(tempError)) {
               return true;
            } else {
               return false;
            }
         }
      }

   useEffect(() => {
      dispatchOneTimeParamsAction({ "keyList": [constants.FILE_CLOG_STATUS] }, ({ data, message }) => {
         setStatusList({ "data": data[constants.FILE_CLOG_STATUS], "loading": false });
      }, (message) => {
         console.log(message);
      });
   }, [dispatchOneTimeParamsAction])
   
   useEffect(() => {
      dispatchOneTimeParamsAction({ "keyList": [constants.MAILBOXES] }, ({ data, message }) => {
         setMailBoxList({ "data": data[constants.MAILBOXES], "loading": false });
      }, (message) => {
         console.log(message);
      });
   }, [dispatchOneTimeParamsAction])


   useEffect(() => {
      dispatchSystemParamsAction({ "keyList": [constants.AUDIT_REPORT_FILTER_LIMIT_DAY] }, ({ data, message }) => {
         if (data) {
            setDateRange(data[constants.AUDIT_REPORT_FILTER_LIMIT_DAY].paramValue);
         }
      }, (message) => {
         console.log(message);
      });
   }, [dispatchSystemParamsAction]);

   const reset = () => {
      setRequestBody({  "mailbox": "", "status": "", "startDate": "", "endDate": "" });
      setResetCalender(true);
      setTimeout(() => {
         setResetCalender(false)
      }, 1000);
   }
   
   const onSubmit = () => { 
      console.log("validating.....");
      if (isValid()) {
         console.log("validation pass");
         console.log(requestBody);
         dispatchClogReport(requestBody, ({ data, message, status }) => {
            if (data && data.length > 1) {
               console.log("encoded String length :", data.length);
               DownloadFileAsXLFromEncodedString(data, constants.FILE_CLOG_REPORT_PREFIX);
            }
         })
      }
   }
   useEffect(() => {
      let endDate = new Date();
      let startDate = new Date();
      if (dateResponseData != "false" && dateResponseData != null) {
         startDate = new Date(dateResponseData[0]);
         endDate = new Date(dateResponseData[1]);
      } else startDate.setDate(endDate.getDate() - (dateRange - 1));
      endDate = endDate.toLocaleDateString("en-CA") + " " + "23:59:59";
      startDate = startDate.toLocaleDateString("en-CA") + " " + "00:00:00";
      setRequestBody({ "mailbox": requestBody.mailbox, "status": requestBody.status, "startDate": startDate, "endDate": endDate })

   }, [dateResponseData])

   useEffect(() => {
      dispatchgetFailureCount(({data, onSuccess, onError}) => {
         if(data){
            setFailureCount(data | 0)
         }
      });
   }, [])



   return (
      <div class="col-lg-4 mb-4">
         <div class="widget widget--box widget--report" data-toggle="modal" data-target="#srCloggingReportPopup" id="rprt_emailclog"> 
            <div class="widget--report__details" onClick={(() => { "" })}>
               <div class="widget--report__title">
                  <p><strong>SR Manager Email Clogging Report</strong></p>
               </div>
               <div class="widget--report__desc">
                  <p>This report lists all the emails clogged in SR Manager mailboxes.</p>
               </div>
            </div>
            <div class="number">{failureCount}</div>
         </div>
         <div class="modal fade modal--addnew show" id="srCloggingReportPopup" data-backdrop="static" data-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" style={{ display: isPopupEnable ? 'block' : 'none' }} aria-modal="true">
            <div class="modal-dialog modal-dialog-centered">
               <div class="modal-content">
                  <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Email Clogging Report</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                 <img src={close_icon} alt="Close" />
                        </button>
                     </div>
                     <div class="modal-body">
                     <div class="form mb-5">
                        <div class="row">
                           <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                 <label for="pc" class="input-label">Mailbox</label>
                                 <div class="input-wrap date-wrap">
                                    <select id="f1" onChange={((e) => setValueToRequestBody("mailbox", e.target.value))} >
                                       <option value="">Any</option>
                                       {(mailboxList.loading) ? '' :
                                          mailboxList.data.map(s => (
                                             <option value={s.value}>{s.value}</option>
                                          ))}
                                    </select>
                                 </div>
                              </div>
                           </div>

                           <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                 <label for="f2" class="d-block input-label">Reprocessing Status</label>
                                 <div class="input-wrap date-wrap">
                                    <select id="f1" onChange={((e) => setValueToRequestBody("status", e.target.value))} >
                                       <option value="">Any</option>
                                       {(statusList.loading) ? '' :
                                          statusList.data.map(s => (
                                             <option value={s.value}>{s.value}</option>
                                          ))}
                                    </select>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                 <label for="f2" class="d-block input-label">Date<span style={{ color: 'red' }}>*</span></label>
                                 <div class="input-wrap date-wrap">
                                    <i class="far fa-calendar-alt"></i>
                                    <span className='spinner-fade' style={{ zIndex: 2000 }}>
                                       <DateRangePicker customRange={dateRange} setDateResponseData={setDateResponseData} reset={resetCalender} />
                                    </span>
                                    {errorsForDateFeild && errorsForDateFeild != "" ?
                                       (<div className="error-message">{errorsForDateFeild}</div>)
                                       : ('')}

                                 </div>
                              </div>
                           </div>


                        </div>
                     </div>
                  </div>
                  <div class="modal-footer">
                     <button type="button" class="button button--outline button--sm" onClick={(() => { setIsPopupEnable(isPopupEnable ? false : true) })} data-dismiss="modal">Cancel</button>
                     <button type="button" onClick={(() => { onSubmit() })} class="button button--sm">Download Report</button>
                  </div>
               </div>
            </div>
         </div>
      </div>

   )



}


const mapDispatchToProps = dispatch => ({
   dispatchOneTimeParamsAction: (data, onSuccess, onError) =>
      dispatch(fetchOneTimeParams(data, onSuccess, onError)),
   dispatchClogReport: (data, onSuccess, onError) =>
      dispatch(downloadClogReportAsExcelFile(data, onSuccess, onError)),
   dispatchSystemParamsAction: (data, onSuccess, onError) =>
      dispatch(fetchSystemParams(data, onSuccess, onError)),
   dispatchgetFailureCount: (onSuccess, onError) =>
      dispatch(failedCloggedFileCount(onSuccess, onError))

});

export default connect(null, mapDispatchToProps)(ComponentForSrEmailCloggingReport)