
//template bean
export const TemplateDetailsModel = () => {
    return {
        "templateId": 0,
        "templateName": "",
        "templateType": "Static",

        "partnerReceiveVia": "",
        "templateProducerFileType": "",
        "templateProducerFilePath": "Upload",
        "templateProducerFileNamePattern": "",

        "partnerDeliverVia": "",
        "deliverOverConsumerListen": false,
        "deliverOverConsumerDirectory": "",
        "templateConsumerFileType": "",
        "templateConsumerFilePath": "Download",
        "templateConsumerFileNamePattern": "",
        "templateProducerDetailsId": 0,
        "templateConsumerDetailsId": 0

    }
}


export const TemplateGcpCollection = () => {
    return {
        "templateProducerCollection": [{ "groupCode": "", "groupName": "" }, { "groupCode": "", "groupName": "" }],
        "templateConsumerCollection": [{ "groupCode": "", "groupName": "" }, { "groupCode": "", "groupName": "" }],
        "templateGroupCollection": [{ "groupCode": "", "groupName": "" }, { "groupCode": "", "groupName": "" }]
    }
}