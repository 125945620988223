import React, { useState, Suspense, lazy, memo } from 'react';
import AlertFailure from '../utils/alert-failure.component';
import AlertSuccess from '../utils/alert-success.component';
import { useHistory } from "react-router";
import { isAdmin, isPartnerAdmin } from '../redux/actions/auth.action.creators';
import info_icon from '../assets/images/icon-info-round.png';
const LazyLoader_PartnerGrid = lazy(() => import("./lazy/partnerGrid.lazy"));
import iconExport from '../assets/images/icon-export.png';
import iconExcel from '../assets/images/icon-excel.png';
import { DownloadListAsExcel } from '../utils/list-export-excel';

const ManagePartnerComponent = (history) => {

  const [onSearchKeyword, setOnSearchKeyword] = useState('')
  const [popupVisibility, setPopupVisibility] = useState({ success: false, failed: false, message: null })
  const [statusCount, setStatusCount] = useState({})
  const [isExportVisible, setIsExportVisible] = useState(false);
  const [partnerData, setPartnerData] = useState({ status: false, info: '' })

  function exportClickExcelDownload() {
    var modifiedList =[{}];
    partnerData.forEach(element => {
      let temp ={};
      temp.partner_code=element.partnercode;
      temp.partner_name=element.partnername;
      temp.username=element.username;
      temp.status=element.status;
      modifiedList.push(temp);
    }); 
    DownloadListAsExcel(modifiedList,'ListOfPartners');
  }

  return (
    <React.Fragment>

      <main>
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb__item">
              <a className="breadcrumb__link" href="#">Manage Partner</a>
            </li>
            <li className="breadcrumb__item">
              <span className="breadcrumb__link">Partners</span>
            </li>
          </ul>
          <div className="title">
            <h1>Partners</h1>
          </div>
          <div >
            {popupVisibility.failed ?
              <AlertFailure message={popupVisibility.message} /> : ''}
            {popupVisibility.success ?
              <AlertSuccess message={popupVisibility.message} /> : ''}
          </div>
          {isAdmin() || isPartnerAdmin() ?
            <div className="btn-wrap btn-wrap--grp mt-5" style={{ justifyContent: "space-between" }}>
              <div>
              <button  type='button'  onClick={(() => {  window.location = '/partnerOnboarding/new';  })} className="button button--outline button--sm ml-0 is-active">Create Partner</button>
              </div>
              <div>
              <a style={{ textDecoration: "none" }} href="/UserLockAndUnlock">
                    <button type='button' className="button button--outline button--sm ml-0 is-active">Partner Users</button>&nbsp;<img title='Use this feature to Lock or UnLock a partner user.' src={info_icon} alt="info" />
                  </a>
              </div>
            </div>
            : ''}
        </div>
        <br></br>
        <div className="container">
          <div className="table-block">
            <div className="table-block__head">
            <h2>List of Partners 
              <span style={{"fontWeight":"bold","fontSize":"13px","color":"#666"}} >
              &nbsp; [ Total: {statusCount.active_count + statusCount.inactive_count?statusCount.active_count + statusCount.inactive_count:0} &nbsp; Active: {statusCount.active_count?statusCount.active_count:0} &nbsp; Inactive: {statusCount.inactive_count?statusCount.inactive_count:0} ] 
                </span> </h2>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <button type="button" style={{ background: '#F8F8F8', color: 'black', border: 'none' }} className="btn btn-secondary btn-sm" onClick={(e) => isExportVisible ? setIsExportVisible(false) : setIsExportVisible(true)}>
                  <img src={iconExport} alt="Success" />  Export
                </button>
                <div>
                  {isExportVisible ? 
                    <div class='fh-legend widget exportXl'>
                      <p style={{ color: '#000' }}><strong>Export As</strong></p>
                      <ul>
                        <li style={{ cursor: 'help'}} onClick={(e) => exportClickExcelDownload()}><img src={iconExcel} alt="Success" /> Excel</li>
                      </ul>
                    </div> 
                  : ''}
                </div> 
              {/* </div>  */}
                <div className="txt-wrap">
                  <input type="text" className="txt" placeholder="Search..." onChange={((e) => setOnSearchKeyword(e.target.value))} />
                  <i className="fa fa-search"></i>
                </div>
              </div>
            </div>

            <Suspense fallback={
              <div>Loading....</div>
            }>
              <LazyLoader_PartnerGrid setStatusCount={setStatusCount} onSearchKeyword={onSearchKeyword} setPopupVisibility={setPopupVisibility} setPartnerData={setPartnerData} />
            </Suspense>
          </div>
        </div>
      </main>


    </React.Fragment>


  );

}

export default memo(ManagePartnerComponent);

