import React from 'react';
import FilehealthDashboardComponent from '../components/filehealth-dashboard.component';


const FilehealthDashboardPage = () => (
        
    <FilehealthDashboardComponent />
);

export default FilehealthDashboardPage;
