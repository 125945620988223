import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const fileMappingSidebarComponent = (props, { dispatchGetMappingDetails }) => {

  const [functionList, setFunctionList] = useState([])
  const functionTitleList = { 'zeros': 'Zero padding', 'trim': 'Trim', 'recordcount': 'Record Count', 'datetimenow': 'Current Date', 'replace': 'Replace' }
  const [functionListReset, setFunctionListReset] = useState(0);

  useEffect(() => {
    const filteredFields = props.allFields.mapping_fields.filter((field) => field.map_to === props.itemDetails.item.map_to);
    const newFunctionList = filteredFields.map((field) => field.function_name);
    setFunctionList(newFunctionList);
    // console.log(functionList);
    // console.log("ITEM", props.itemDetails.item);
  }, [functionListReset]);

  const handleDelete = (field) => {
    if (functionList.length === 1) {
      const { mapping_fields } = props.allFields;
      const mapfieldIndex = mapping_fields.findIndex((mapfield) => mapfield.map_to === field.map_to);

      if (mapfieldIndex > -1) {
        const newMappingFields = [...mapping_fields];
        newMappingFields[mapfieldIndex].function_name = '';

        props.setAllFields((prevState) => ({
          ...prevState,
          mapping_fields: newMappingFields,
        }));
      }
    } else if (functionList.length > 1) {
      const { mapping_fields } = props.allFields;
      const newMappingFields = [...mapping_fields];
      const mapfieldIndex = newMappingFields.findIndex((mapfield) => mapfield.map_to === field.map_to && mapfield.function_name === field.function_name);

      if (mapfieldIndex > -1) {
        if (functionList.indexOf(field.function_name) === 0) {
          const nextMapfieldIndex = newMappingFields.findIndex((mapfield) => mapfield.map_to === field.map_to && mapfield.function_name !== field.function_name);

          if (nextMapfieldIndex > -1) {
            newMappingFields[mapfieldIndex].function_name = newMappingFields[nextMapfieldIndex].function_name;
            newMappingFields[mapfieldIndex].replace = newMappingFields[nextMapfieldIndex].replace;
            newMappingFields[mapfieldIndex].pattern = newMappingFields[nextMapfieldIndex].pattern;

            // Delete the second element
            newMappingFields.splice(nextMapfieldIndex, 1);
          }
        } else {
          newMappingFields.splice(mapfieldIndex, 1);
        }

        props.setAllFields((prevState) => ({
          ...prevState,
          mapping_fields: newMappingFields,
        }));
      }

    }
    setFunctionListReset(functionListReset + 1)
  }


  const handleFunction = (item, func) => {
    let parameter = ''
    let mapping = props.allFields.mapping_fields.filter((field) => ((field.function_name === func) && (field.map_to === props.itemDetails.item.map_to)))[0]

    
    if (mapping != null) {
      // console.log("MAPPINF",mapping.replace);
      if (func != 'datetimenow') {
        parameter = mapping.replace
      } else {
        parameter = mapping.pattern
      }

    }

    props.setSelectedFunction({ "item": item, "function": func, "details": props.itemDetails.details, "fileType": props.itemDetails.fileType.Destination, "parameters": parameter })
    handleSideBarClose()

  };
  const handleSideBarClose = () => {
    props.setShowSidebar(false);
  };
  return (
    <div
      className={"sidebar" + (props.showSidebar ? " open" : "")}
    >
      <div className="sidebar-content widget">
        <h2 class="head">Functions</h2>
        <p>Add functions to <span style={{ "color": "red" }}>{props.itemDetails.item.map_to}</span></p>
        <div class="form-group ">
          <ul style={{ cursor: "pointer" }}>
            { }
            {props.itemDetails.item.function_params !== "" ? (
              <>
                {functionList.filter((field) => (field === 'trim')).length == 0 ?
                  <li class="path widget" onClick={(() => { handleFunction(props.itemDetails.item, "trim") })} style={{ "textAlign": "left" }}>
                    <span class="path" >Trim</span>
                  </li>
                  : null}
              </>
            ) : null}
            {props.itemDetails.item.function_params === "" ? (
              <>
                {functionList.filter((field) => (field === 'recordcount')).length == 0 ?
                  <li class="path widget" onClick={(() => { handleFunction(props.itemDetails.item, "recordcount") })} style={{ "textAlign": "left" }}>
                    <span class="path" >Record Count</span>
                  </li>
                  : null}
                {functionList.filter((field) => (field === 'datetimenow')).length == 0 ?
                  <li class="path widget" onClick={(() => { handleFunction(props.itemDetails.item, "datetimenow") })} style={{ "textAlign": "left" }}>
                    <span class="path" >Current Date</span>
                  </li>
                  : null}
              </>
            ) : null}
            {functionList.filter((field) => (field === 'replace')).length == 0 ?
              <li class="path widget" onClick={(() => { handleFunction(props.itemDetails.item, "replace") })} style={{ "textAlign": "left" }}>
                <span class="path" >Replace</span>
              </li> : null}
            {functionList.filter((field) => (field === 'zeros')).length == 0 ?
              <li class="path widget" onClick={(() => { handleFunction(props.itemDetails.item, "zeros") })} style={{ "textAlign": "left" }}>
                <span class="path" >Zero padding</span>
              </li>
              : null}
          </ul>

          {functionList.length > 0 && props.itemDetails.item.function_name !== '' ? (
            <div className='form-group'>
              <p>Applied Functions</p>
              <ul style={{ cursor: "pointer" }}>
                {props.allFields.mapping_fields
                  .filter((field) => field.map_to === props.itemDetails.item.map_to)
                  .map((field, index) => (
                    <li
                      key={index}
                      className="path widget"
                      onClick={() => handleFunction(props.itemDetails.item, field.function_name)}
                      style={{ textAlign: "left" }}
                    >
                      <span className="path">{functionTitleList[field.function_name]}</span>
                      <span
                        className="delete-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(field);
                        }}
                      >
                        <span className="close">X</span>
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          ) : null}
        </div>
        <div onClick={handleSideBarClose}>
          <button className="button btn-wrap" style={{ "width": "50%" }} >
            Close
          </button>
        </div>

      </div>
    </div >

  )
};

const mapDispatchToProps = dispatch => ({
  dispatchGetMappingDetails: (partnerId, onSuccess, onError) =>
    dispatch(fetchPartnerDetailsById(partnerId, onSuccess, onError))
})

export default connect(null, mapDispatchToProps)(fileMappingSidebarComponent)