

// modals
export const userDataModel = () => {
    return {
        "data": [

            {
                "one_time_value": "",
                "id": null,
                "created_by": null,
                "email": "",
                "username": "",
                "status": "",
                "groups": [],
                "delete_if_inactive": false,
                "is_sso_user": false
            }
        ]
    }
}
export const rolesModel = () => {
    return {
        "data":
        {
            "Administrator": [],
            "Partner": [],
            "OperationUsers": [],
            "PartnerAdmin": [],
            "Custom":[]
        }
    }
}