import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { DateRangePicker } from "../utils/date-range-picker.component";
import iconInfo from '../assets/images/icon-info-round.png';
import iconClose from '../assets/images/icon-close.svg';
import iconRetry from '../assets/images/icon-retry.png';
import iconDelete from '../assets/images/icon-delete.png';
import { Tooltip } from 'primereact/tooltip';
import { fetchOneTimeParams, fetchSystemParams, listPartners, isAdmin, isOperationalUser, fileMonitorSearch, deleteFileMonitoringRecords, downloadFile, getFileMonitoringDetailsById, retryFileProcessing, isJson, sessionErrorCheck, isPartnerAdmin } from '../../src/redux/actions/auth.action.creators';
import * as constants from '../redux/constants';
import { data } from 'jquery';
import { confirmDialog } from 'primereact/confirmdialog';
import AlertFailure from '../utils/alert-failure.component';
import AlertSuccess from '../utils/alert-success.component';
import _ from "lodash";
import CustomDropdownWidget from './dropdown/CustomDropdownWidget';
var CryptoJS = require("crypto-js");
var appConf  = require(process.env.REACT_APP_CONFIG_URL);
import { TIMEZONE } from '../redux/constants';
import iconExport from '../assets/images/icon-export.png';
import iconExcel from '../assets/images/icon-excel.png';
import { DownloadListAsExcel } from '../utils/list-export-excel';

const FileMonitoringComponent = ({ dispatchDeleteFileRecordsById, dispatchRetryFileProcessing, dispatchListPartnersAction, dispatchSystemParamsAction, dispatchOneTimeParamsAction, dispatchSearchAction, dispatchDownloadAction, dispatchGetDetailByIdAction }) => {
    const [partner, setPartner] = useState('');
    const [partnerList, setPartnerList] = useState([]);
    const [statusList, setStatusList] = useState({ "data": "", "loading": true });
    const [dateRange, setDateRange] = useState(30);
    const [retreieveLimit, setRetreiveLimit] = useState(constants.FILEMONITOR_RETREIVELIMIT_DEFAULT);
    const [dateResponseData, setDateResponseData] = useState("false");
    const [requestBody, setRequestBody] = useState({ "fileName": "", "partnerCode": "", "status": "", "startDate": "", "endDate": "", "limit": constants.FILEMONITOR_RETREIVELIMIT_DEFAULT });
    const [tableData, setTableData] = useState([]);
    const [filteredTableData, setFilteredTableData] = useState({ "loading": true, "data": [] });
    const [constrains, setConstrains] = useState({ "fieldPlaceholder": "Select partner", "isMandatory": false, "fieldLabel": "Partner", "inputErrorMessage": "" })
    const [responseData, setResponseData] = useState({});
    const [searchKey, setSearchKey] = useState('');
    const [fileName, setFileName] = useState('');
    const [restorationDays, setRestorationDays] = useState('');
    // const userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
    // const userInfos = localStorage.getItem('USER_INFO');
    const userInfos = sessionErrorCheck() !== null ? sessionErrorCheck() : window.location = '/auth';
    // var decryptByte = CryptoJS.AES.decrypt(userInfos, secretKey.switch.secretKey);
    // const userInfo = JSON.parse(decryptByte.toString(CryptoJS.enc.Utf8));
    // const userInfo = JSON.parse(window.atob(localStorage.getItem('USER_INFO')));
    // const userInfo = localStorage.getItem('USER_INFO') === null ? null : (!isJson(localStorage.getItem('USER_INFO')) ? JSON.parse(decryptByte.toString(CryptoJS.enc.Utf8)) : null) ;
    const userInfo = userInfos;
    const [sortAsc, setSortAsc] = useState({ "file_name": false, "uploaded_date": false, "status": false, "source": false, "partner_code": false });
    const [errorConst, setErrorConst] = useState({ "date": "" });
    const [openModal, setOpenModal] = useState(false);
    const [resetCalender, setResetCalender] = useState(false);
    const [failMsg, setFailMsg] = useState('');
    const [succMsg, setSuccMsg] = useState('');
    const [fileDetails, setFileDetails] = useState({ "data": [], "loading": true });
    const [togglePopup, setTogglePopup] = useState(false);
    const [loggedin_Ptr, setLoggedin_Ptr] = useState('');
    const ref = useRef();
    const modalRef = React.createRef();
    const [dropdownRefresher, setdropdownRefresher] = useState(true)
    const [retreiveLimitList, setRetreiveLimitList] = useState({ "data": "", "loading": true });
    const [isExportVisible, setIsExportVisible] = useState(false);

    useEffect(() => {
        dispatchSystemParamsAction({ "keyList": [constants.FILEMONITOR_DATERANGE, constants.FILEMONITOR_RETREIVELIMIT_LIST] }, ({ data, message }) => {
            if (data) {
                setDateRange(data[constants.FILEMONITOR_DATERANGE].paramValue);
                setRetreiveLimitList({ "data": data[constants.FILEMONITOR_RETREIVELIMIT_LIST].paramValue.split(','), "loading": false });
            }
        }, (message) => {
            console.log(message);
        });
    }, [dispatchSystemParamsAction]);


    useEffect(() => {
        dispatchOneTimeParamsAction({ "keyList": [constants.FILE_STATUS] }, ({ data, message }) => {
            setStatusList({ "data": data[constants.FILE_STATUS], "loading": false });
        }, (message) => {
            console.log(message);
        });
    }, [dispatchOneTimeParamsAction])

    useEffect(() => {
        dispatchListPartnersAction(({ data, message }) => {
            if (data) {
                if (userInfo.role == 'Partner') {
                    let currentPartnerWithName = `${data.filter(p => (p.partnerid == userInfo.partnerId))[0].partnercode}  (${data.filter(p => (p.partnerid == userInfo.partnerId))[0].partnername})`;
                    let currentPartner = data.filter(p => (p.partnerid == userInfo.partnerId))[0].partnercode;
                    setPartnerList([{ "value": currentPartner, "label": currentPartnerWithName }]);
                    setLoggedin_Ptr(currentPartner);
                    setRequestBody({ "fileName": requestBody.fileName, "partnerCode": currentPartner, "status": requestBody.status, "startDate": requestBody.startDate, "endDate": requestBody.endDate, "limit": requestBody.limit })
                }
                else {
                    let arr = [{ "value": "", "label": "Any" }];
                    data.forEach(p => {
                        arr.push({
                            "value": p.partnercode,
                            "label": `${p.partnercode} (${p.partnername})`
                        })
                    })
                    setPartnerList(arr);
                }
            }
        },
            (message) => {
                console.log(message);
            });
    }, [dispatchListPartnersAction])

    useEffect(() => {
        let endDate = new Date();
        let startDate = new Date();
        if (dateResponseData != "false" && dateResponseData != null) {
            startDate = new Date(dateResponseData[0]);
            endDate = new Date(dateResponseData[1]);
        } else startDate.setDate(endDate.getDate() - (dateRange - 1));
        endDate = endDate.toLocaleDateString("en-CA") + " " + "23:59:59";
        startDate = startDate.toLocaleDateString("en-CA") + " " + "00:00:00";
        setRequestBody({ "fileName": requestBody.fileName, "partnerCode": requestBody.partnerCode, "status": requestBody.status, "startDate": startDate, "endDate": endDate, "limit": requestBody.limit })

    }, [dateResponseData])

    useEffect(() => {
        if(JSON.stringify(responseData) !== '{}'){
            setRequestBody({ "fileName": requestBody.fileName, "partnerCode": responseData.value, "status": requestBody.status, "startDate": requestBody.startDate, "endDate": requestBody.endDate, "limit": requestBody.limit }); 
        }
    }, [responseData])

    useEffect(() => {
        let filtered = _.cloneDeep(filteredTableData);
        if (!filteredTableData.loading) {
            filtered.data = tableData.filter(p => {
                return (p.file_name.toLowerCase().includes(searchKey.toLowerCase())
                    || p.status.toLowerCase().includes(searchKey.toLowerCase())
                    || p.source.toLowerCase().includes(searchKey.toLowerCase())
                    || p.partner_code.toLowerCase().includes(searchKey.toLowerCase())
                    || p.consumer_code.toLowerCase().includes(searchKey.toLowerCase())
                    || p.uploaded_date.toLowerCase().includes(searchKey.toLowerCase()))
            });
        }
        setFilteredTableData(filtered);
    }, [searchKey])

    const onSearch = () => {
        if (isFormValid()) {
            dispatchSearchAction(requestBody, ({ data, message }) => {
                setTableData(JSON.parse(data));
                let tempList = [];
                let index = 0;
                JSON.parse(data).forEach((val, i) => {
                    tempList.push(val);
                    index++;
                });
                setFilteredTableData({ "loading": false, "data": tempList });
                if (index == retreieveLimit) {
                    window.scrollTo(0, 0);
                    setSuccMsg("We have set a limit of " + retreieveLimit + " results per search. Please refine your search to get more accurate results.");
                    setTimeout(() => {
                        setSuccMsg('')
                    }, 5000);
                }
            }, (message) => {
                console.log(message);
            });
        }
    }

    const doRetry = (monitoringId, filename) => {


        dispatchRetryFileProcessing(monitoringId, ({ data, message, status }) => {
            if (data) {
                window.scrollTo(0, 0)
                setSuccMsg("File reprocessing Initiated.")
                setTimeout(() => {
                    setSuccMsg('')
                }, 5000);
                setTimeout(() => {
                    onSearch()
                }, 10);
            } else {
                window.scrollTo(0, 0)
                setFailMsg("File reprocessing Failed.")
                setTimeout(() => { setFailMsg('') }, 5000);
            }
        }, (message) => {
            console.log("filename : " + filename + " message :" + message);
        });
    }


    const onPopupActions = (monitoringId, filename, isRetry) => {
        confirmDialog({
            message: isRetry ? 'Do you want to retry ' + filename + ' ?' : 'Do you want to delete ' + filename + ' ?',
            header: isRetry ? 'Retry Confirmation' : 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept() {
                if (isRetry) {
                    doRetry(monitoringId, filename)
                } else {
                    deleteFileRecords(monitoringId, filename)
                }
            },
            reject() {

            },
            acceptLabel: "Confirm",
            rejectLabel: "Cancel"
        });
    };


    const deleteFileRecords = (monitoringId, filename) => {

        dispatchDeleteFileRecordsById(monitoringId, ({ data, message, status }) => {
            if (data) {
                window.scrollTo(0, 0)
                setSuccMsg("File processing records delete successfully.")
                setTimeout(() => {
                    setSuccMsg('')
                }, 5000);
                setTimeout(() => {
                    onSearch()
                }, 10);
            } else {
                window.scrollTo(0, 0)
                setFailMsg("File processing records deletion failed.")
                setTimeout(() => { setFailMsg('') }, 5000);
            }
        }, (message) => {
            console.log("filename : " + filename + " message :" + message);
        });

    }

    const getDetailsById = (id) => {
        dispatchGetDetailByIdAction(id, ({ data, message }) => {
            if (data.length != 0) {
                setTogglePopup(true)
                // console.log(data);
                let Jlogs = [];
                let arrs = [];
                data.forEach(d => {
                    if (isJson(d.logs)) {
                        arrs = eval(JSON.parse(d.logs))
                        Jlogs.push.apply(Jlogs, arrs)
                    }
                    else {
                        let new_log = { "updatedAt": d.updatedAt.split("T")[0] + " " + d.updatedAt.split("T")[1], "logs": d.logs }
                        Jlogs.push(new_log)
                    }
                })
                  Jlogs.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                //   console.log(Jlogs);
                for (let d = 0; d < Jlogs.length; d++) {
                    Jlogs[d].updatedAt = Jlogs[d].updatedAt.split(" ")[0] + " " + Jlogs[d].updatedAt.split(" ")[1].substring(0, 8);
                }
                setFileDetails({ "data": Jlogs, "loading": false })
            }
        }, (message) => {
            console.log(message);
        });
    }

    useEffect(() => {
        if (!togglePopup) setFileDetails({ "data": [], "loading": true })
        const checkIfClickedOutside = e => {
            if (togglePopup && ref.current && !ref.current.contains(e.target)) {
                setTogglePopup(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [togglePopup])

    const setLimitToResponsedataAndRetrivelimit = (value) => {
        setRetreiveLimit(value);
        setRequestBody({ "fileName": requestBody.fileName, "partnerCode": requestBody.partnerCode, "status": requestBody.status, "startDate": requestBody.startDate, "endDate": requestBody.endDate, "limit": value });
    }
 
    function isFormValid() {
        let tempError = _.cloneDeep(errorConst);
        tempError.date = Math.ceil(Math.abs(new Date(requestBody.endDate) - new Date(requestBody.startDate)) / (1000 * 60 * 60 * 24)) > dateRange ? "Maximum date range is " + dateRange : "";
        setErrorConst(tempError);
        return !tempError.date;
    }

    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    function initiateDownload(folderPath) { 
        var file_name = folderPath ? folderPath : fileName
        file_name = CryptoJS.AES.encrypt(file_name, CryptoJS.enc.Utf8.parse(appConf.switch.aesKey), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          }).toString();
        dispatchDownloadAction({
            "fileName": file_name,
            "restorationDays": restorationDays ? restorationDays : ""
        }, ({ data, message }) => {
            setRestorationDays('');
            if (data["fileDownloadLink"]) {
                const link = document.createElement('a');

                link.href = data["fileDownloadLink"];
                link.target = "_blank";
                link.setAttribute(
                    'download',
                    `${data['fileName']}`,
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else if (data["message"] && data["statusCode"] == 202) {
                if (constants.PERMIT_ARCHIVE_DWNLD.includes(userInfo.role)) {
                    setOpenModal(true);
                } else {
                    window.scrollTo(0, 0);
                    setFailMsg("You don't have permission to download an archived file. Please contact the support team");
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                }
            } else if (data["message"] && data["statusCode"] == 404 || data["statusCode"] == 500) {
                window.scrollTo(0, 0);
                setFailMsg(data["message"]);
                setTimeout(() => {
                    setFailMsg('')
                }, 3000);
            } else {
                window.scrollTo(0, 0);
                setSuccMsg(data["message"]);
                setTimeout(() => {
                    setSuccMsg('')
                }, 3000);
            }
        }, (message) => {
            window.scrollTo(0, 0);
            setFailMsg(message);
            setTimeout(() => {
                setFailMsg('')
            }, 3000);
        });
    }

    function resetForm() {
        setdropdownRefresher(dropdownRefresher ? false : true)
        console.log("loggedin_Ptr", loggedin_Ptr)
        setRequestBody({ "fileName": "", "partnerCode": loggedin_Ptr, "status": "", "startDate": "", "endDate": "", "limit": constants.FILEMONITOR_RETREIVELIMIT_DEFAULT });
        setRetreiveLimit(constants.FILEMONITOR_RETREIVELIMIT_DEFAULT)
        setFileName('');
        setResponseData({});
        setRestorationDays('');
        setResetCalender(true);
        setTimeout(() => {
            setResetCalender(false)
        }, 1000);
    }

    function compareBy(key, dir) {
        return function (a, b) {
            if(key == "uploaded_date"){
                if (new Date(a[key]) < new Date(b[key])) return dir == true ? -1 : 1;
                if (new Date(a[key]) > new Date(b[key])) return dir == true ? 1 : -1;
            }else{
                if (a[key] < b[key]) return dir == true ? -1 : 1;
                if (a[key] > b[key]) return dir == true ? 1 : -1;                
            }
            return 0;            
        };
    }

    function sortBy(key) {
        let arrayCopy = _.cloneDeep(filteredTableData);
        let dirCopy = _.cloneDeep(sortAsc);
        dirCopy[key] = !dirCopy[key];
        setSortAsc(dirCopy);
        arrayCopy.data.sort(compareBy(key, dirCopy[key]));
        setFilteredTableData(arrayCopy);
    }

    function getProducerProcessedFolder(data) {
        let producer_file_path = new String();
        let newDate = new String();
        let fileName = new String();
        let fileNamefromPath = new String();
        const newDateList = data.uploaded_date.split(" ");
        newDate = newDateList[0];
        const newDateList2 = newDate.split("-");

        newDate = newDateList2[1] + newDateList2[2];
        fileName = data.file_name.replace(/(_reprocessed_)+\d+$/g, '');
        fileName = fileName.replace(/(\.pgp|\.gpg)$/g, '');
        fileNamefromPath =  data.file_path.split("/").pop().replace(/(\.pgp|\.gpg)$/g, '');
        console.log("Filename " + fileName.toLowerCase())
        console.log("Pathname " + fileNamefromPath.toLowerCase())

        if(fileName.toLowerCase()==fileNamefromPath.toLowerCase()){
            fileName= fileNamefromPath;
        }

        producer_file_path = 'filehandler/working/' + data.partner_code + '/' + 'processed/' + newDate + '/' + fileName;
        console.log('File_name',fileName);
        // console.log('Final_ProducerPath',producer_file_path);
        return producer_file_path;
    }

    function exportClickExcelDownload() {
        var modifiedList =[{}];
        filteredTableData.data.forEach(element => {
            let temp ={};
            temp.file_name=element.file_name;
            temp.partner_code=element.partner_code;
            temp.consumer_code=element.consumer_code;
            temp.uploaded_date=element.uploaded_date;
            temp.status=element.status;
            modifiedList.push(temp);
        });
        DownloadListAsExcel(modifiedList,'ListOfFiles');
    }

    return (
        <React.Fragment>
            <main>
                <div class="container">
                    <ul class="breadcrumb">
                        <li class="breadcrumb__item">
                            <a class="breadcrumb__link" href="#">Home</a>
                        </li>
                        <li class="breadcrumb__item">
                            <span class="breadcrumb__link">File Monitoring</span>
                        </li>
                    </ul>
                    <div class="title">
                        <h1>File Monitoring</h1>
                    </div>
                    {failMsg ?
                        <AlertFailure message={failMsg} /> : ''}
                    {succMsg ?
                        <AlertSuccess message={succMsg} /> : ''}
                </div>
                <div class="grey-block">
                    <div class="container">
                        <div class="form mb-5">
                            <h2 class="mb-4">Search File</h2>

                            <div style={{ textAlign: 'right' }}>
                                <button class="button">
                                    <a className="link" style={{ color: 'white' }} href="/fileTransferStatus">File Transfer Status</a>
                                </button>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f1" class="input-label"> File Name</label>
                                        <div class="input-wrap select-wrap">
                                            <input type="text" class="txt" placeholder="File name" value={requestBody.fileName} onChange={(e) => setRequestBody({ "fileName": e.target.value, "partnerCode": requestBody.partnerCode, "status": requestBody.status, "startDate": requestBody.startDate, "endDate": requestBody.endDate, "limit": retreieveLimit })} />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f2" class="d-block input-label">Date</label>
                                        <div class="input-wrap date-wrap">
                                            <i class="far fa-calendar-alt"></i>
                                            <DateRangePicker customRange={dateRange} defaultDates={[new Date(),new Date()]} setDateResponseData={setDateResponseData} reset={resetCalender} />
                                            {errorConst.date ?
                                                (<div className="error-message">{errorConst.date}</div>)
                                                : ('')}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f2" class="d-block input-label">Status</label>
                                        <div class="input-wrap date-wrap">
                                            <select id="f1" onChange={(e) => { setRequestBody({ "fileName": requestBody.fileName, "partnerCode": requestBody.partnerCode, "status": e.target.value, "startDate": requestBody.startDate, "endDate": requestBody.endDate, "limit": retreieveLimit }) }}
                                                value={requestBody.status}>
                                                <option value="">Any</option>
                                                {(statusList.loading) ? '' :
                                                    statusList.data.map(s => (
                                                        <option value={s.value}>{s.value}</option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        {/* <label for="f1" class="input-label"> Partner</label> */}
                                        {/* <div class="input-wrap select-wrap"> */}
                                        {/* <select id="f1" onChange={(e) => {setRequestBody({"fileName": requestBody.fileName, "partnerCode": e.target.value, "status": requestBody.status, "startDate": requestBody.startDate, "endDate": requestBody.endDate})}}
                            value={requestBody.partnerCode}>
                                {partnerList.map(p => (
                                <option value={p.partnerCode == "Any" ? "" : p.partnerCode}>{p.partnerCode}</option>   
                                ))}
                            </select> */}
                                        <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={partnerList} constrains={constrains} setResponseData={setResponseData}></CustomDropdownWidget>
                                        {/* </div> */}
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="f2" class="d-block input-label">Search Result Limit</label>
                                        <div class="input-wrap date-wrap">
                                            <select id="limit" onChange={(e) => { setLimitToResponsedataAndRetrivelimit(e.target.value) }}>
                                                {(retreiveLimitList.loading) ? '' :
                                                    retreiveLimitList.data.map(s => (
                                                        <option value={s} selected={retreieveLimit === s? 'selected' : ''}>{s} Records</option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="btn-wrap btn-wrap--grp mt-5 justify-content-end">
                            <button class="button button--outline" onClick={() => resetForm()}>Reset</button>
                            <button class="button" onClick={() => onSearch()}>Search</button>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="table-block">
                        <div class="table-block__head">
                            <h2>List of files 
                            <span style={{ "fontWeight": "bold", "fontSize": "13px", "color": "#666" }} >
                                    &nbsp; [ Total: {filteredTableData ? filteredTableData.data.length : 0} ]
                                </span>
                            </h2>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <button type="button" style={{ background: '#F8F8F8', color: 'black', border: 'none' }} className="btn btn-secondary btn-sm" onClick={(e) => isExportVisible ? setIsExportVisible(false) : setIsExportVisible(true)}>
                                    <img src={iconExport} alt="Success" />  Export
                                </button>
                                <div>
                                {isExportVisible ? 
                                    <div class='fh-legend widget exportXl'>
                                    <p style={{ color: '#000' }}><strong>Export As</strong></p>
                                    <ul>
                                        <li style={{ cursor: 'help'}} onClick={(e) => exportClickExcelDownload()}><img src={iconExcel} alt="Success" /> Excel</li>
                                    </ul>
                                    </div> 
                                : ''}
                                </div> 
                                <div class="txt-wrap">
                                    <input type="text" class="txt" placeholder="Search..."
                                        value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                    <i class="fa fa-search"></i>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive grid">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" onClick={() => sortBy('file_name')}>File Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => sortBy('partner_code')}>Producer <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => sortBy('consumer_code')}>Consumer <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th style={{ "whiteSpace": "nowrap" }} scope="col" onClick={() => sortBy('uploaded_date')}>Uploaded Date <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th style={{ "whiteSpace": "nowrap" }} scope="col" onClick={() => sortBy('status')}>Status <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th style={{ "whiteSpace": "nowrap" }} scope="col" onClick={() => sortBy('source')}>Source <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th style={{ "whiteSpace": "nowrap" }} scope="col" className="no-sort">Downloads</th>
                                        {isAdmin() || isOperationalUser() || isPartnerAdmin() ? <th scope="col">Decrypted File</th> : ""}
                                        {isAdmin() || isPartnerAdmin() ? <th scope="col">Action</th> : ""}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredTableData.loading || (!filteredTableData.loading && filteredTableData.data.length == 0) ? (<tr><td colSpan="6"><div className="text-center text-danger">No search results found</div></td></tr>) :
                                        filteredTableData.data.map(data => (
                                            <tr>
                                                <td>{data.file_name}</td>
                                                <td>{data.partner_code}</td>
                                                <td>{data.consumer_code}</td>
                                                <td style={{ "whiteSpace": "nowrap" }}>{data.uploaded_date}</td>
                                                <td className="position-relative">
                                                    <span class={data.status == "FAILED" || data.status == "UPLOAD_FAILED" ? "danger" : "success"} onClick={(e) => getDetailsById(data.id)} style={{ cursor: "pointer" }}><u>{data.status.charAt(0)}{data.status.slice(1).toLowerCase()}</u></span>

                                                    {data.log && (data.status == "FAILED" || data.status == "CUSTOM FILE PROCESSED" || data.status == "CUSTOM PROCESS FAILED") ? (<span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} />
                                                        <i>
                                                            <img className="toolTip" data-pr-tooltip={data.log} data-pr-position="bottom" src={iconInfo} alt="info" />
                                                        </i></span>) : ''}
                                                </td>
                                                <td>{data.source}</td>
                                                <td style={{ "whiteSpace": "nowrap" }}>
                                                    {data.status == "SUCCESS" || data.status == "PROCESSED" || data.status == "CUSTOM FILE PROCESSED" ?
                                                        (<a
                                                            class="link" href="javascript:void(0);"
                                                            onClick={(e) => {
                                                                setFileName(data.file_path);
                                                                initiateDownload(data.file_path);
                                                            }}
                                                        >Download Here</a>) : "Not available"}

                                                </td>
                                                {isAdmin() || isOperationalUser()|| isPartnerAdmin() ? <td style={{ "whiteSpace": "nowrap" }}>
                                                    {(data.status == "SUCCESS" || data.status == "PROCESSED" || data.status == "CUSTOM FILE PROCESSED") && (data.file_path.includes(".pgp") || data.file_path.includes(".gpg")) ?
                                                        (<a
                                                            class="link" href="javascript:void(0);"
                                                            onClick={(e) => {
                                                                let producer_file_path = new String();
                                                                producer_file_path = getProducerProcessedFolder(data);
                                                                setFileName(producer_file_path);
                                                                initiateDownload(producer_file_path);
                                                            }}
                                                        >Download Here</a>) : "Not applicable"}

                                                </td> : ""}
                                                {isAdmin()|| isPartnerAdmin() ? <td>
                                                    <div className="table__action" >
                                                        {constants.FILEMONITOR_RETRY_STATUS.includes(data.status) ? <a href="javascript:void(0);" className="utils utils--outline" onClick={(e) => onPopupActions(data.id, data.file_name, true)}>
                                                            <span>
                                                                <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} />
                                                                <img className="toolTip" data-pr-tooltip="Retry" data-pr-position="bottom" src={iconRetry} alt="retry" />
                                                            </span></a> : " "}
                                                        {constants.FILEMONITOR_DELETE_STATUS.includes(data.status) ? <a href="javascript:void(0);" className="utils utils--outline ml-2" onClick={(e) => onPopupActions(data.id, data.file_name, false)}>
                                                            <span>
                                                                <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} />
                                                                <img className="toolTip" data-pr-tooltip="Delete" data-pr-position="bottom" src={iconDelete} alt="delete" />
                                                            </span></a> : " "}

                                                    </div>
                                                </td> : ""}
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
            <div class={`modal fade modal--monitoring ${openModal ? 'show' : ''}`} id="staticBackdrop" style={{ display: openModal ? 'block' : 'none' }}
                data-backdrop="static" data-keyboard="false" tabindex="-1"
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Restoration Request</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={(e) => { setOpenModal(false); setRestorationDays(''); }}>
                                <img src={iconClose} alt="Close" />
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label for="num" class="d-block input-label">Number of days that the restored copy should be available<img title='Max limit upto 90' src={iconInfo} alt="info" /></label>
                                        <div class="input-wrap number-wrap">
                                            <input type="number" id="num" name="num" min="0" placeholder="Type Number here..." onChange={(e) => 1 <= e.target.value && e.target.value < 91 ?  setRestorationDays(e.target.value):restorationDays} value={restorationDays} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text">
                                <strong>Note :</strong>
                                <span>- standard retrival process - this will take around 3-5 hr</span>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="button button--outline button--sm" data-dismiss="modal" onClick={(e) => { setOpenModal(false); setRestorationDays(''); }}>Cancel</button>
                            <button type="button" class="button button--sm" onClick={(e) => { initiateDownload(); setOpenModal(false); }}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal for file history tracking popup JSX begins */}
            <div class={`modal fade modal--monitoring ${!fileDetails.loading ? 'show' : ''}`} id="staticBackdrop" style={{ display: !fileDetails.loading ? 'block' : 'none' }}
                data-backdrop="static" data-keyboard="false" tabindex="-1"
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">File Details</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={(e) => setFileDetails({ "data": [], "loading": true })}>
                                <img src={iconClose} alt="Close" />
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row ">
                                <div class="col-lg-12 col-md-12">
                                    <table className="table grid" style={{ display: "block" }}>
                                        <thead>
                                            <tr>
                                                <th class="col-lg-6" scope="col">Date</th>
                                                <th class="col-lg-12" scope="col">Logs</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fileDetails.data.map(data => (
                                                <tr>
                                                    <td class="col-lg-6" >{data.updatedAt}</td>
                                                    <td class="col-lg-12" >{data.logs}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        {/* <div class="modal-footer">
                            <button type="button" class="button button--outline button--sm" data-dismiss="modal" onClick={(e) => setFileDetails({ "data": [], "loading": true })}>Close</button>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* Modal for file history tracking popup JSX ends */}
        </React.Fragment>
    )
}

const mapDispatchToProps = dispatch => ({
    dispatchListPartnersAction: (onSuccess, onError) =>
        dispatch(listPartners(onSuccess, onError)),
    dispatchSystemParamsAction: (data, onSuccess, onError) =>
        dispatch(fetchSystemParams(data, onSuccess, onError)),
    dispatchOneTimeParamsAction: (data, onSuccess, onError) =>
        dispatch(fetchOneTimeParams(data, onSuccess, onError)),
    dispatchSearchAction: (data, onSuccess, onError) =>
        dispatch(fileMonitorSearch(data, onSuccess, onError)),
    dispatchDownloadAction: (data, onSuccess, onError) =>
        dispatch(downloadFile(data, onSuccess, onError)),
    dispatchGetDetailByIdAction: (data, onSuccess, onError) =>
        dispatch(getFileMonitoringDetailsById(data, onSuccess, onError)),
    dispatchDeleteFileRecordsById: (monitoringId, onSuccess, onError) =>
        dispatch(deleteFileMonitoringRecords(monitoringId, onSuccess, onError)),
    dispatchRetryFileProcessing: (monitoringId, onSuccess, onError) =>
        dispatch(retryFileProcessing(monitoringId, onSuccess, onError))
});

export default connect(null, mapDispatchToProps)(FileMonitoringComponent);