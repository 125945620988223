import React, { useEffect, useState } from 'react';
import { connect, useStore } from 'react-redux';
import { fetchOneTimeParams } from '../../../redux/actions/auth.action.creators'

import close_icon from '../../../assets/images/icon-close.png';


const FileMappingPopupFlatComponent = (props, { dispatchOneTimeParams }) => {
  const [count, setCount] = useState(0);
  const [fieldEntity, setFieldEntity] = useState({ "field_location": "2", "field_name": "", "position_from": "", "field_length": "", "field_type": "String", "field_pattern": "" })
  const [inputErrorWarning, setInputErrorWarning] = useState('')
  const [fieldExist, setFieldExist] = useState(false)
  const [fieldOverlaps, setFieldOverlaps] = useState({ "state": false, "field": null })
  const fieldlocations = { "1": "H", "2": "B", "3": "F" }

  useEffect(() => {
    if (props.isEditing.state) {
      const item = props.isEditing.item
      const index = props.isEditing.index
      setFieldEntity({ "field_location": item.field_location, "field_name": item.field_name, "position_from": item.position_from, "field_length": item.field_length, "field_type": item.field_type, "field_pattern": item.field_pattern })
    }
  }, [count]);



  const inputFieldValidation = () => {

    setInputErrorWarning({
      "field_name": fieldEntity.field_name.trim() === "" ? "Enter Field Name" : inputFieldLengthValidation(fieldEntity.field_name, 100) ? null : "Field name length should be between 1 - 100 ",
      "field_pattern": inputFieldLengthValidation(fieldEntity.field_pattern, 50) ? null : "Pattern length should be between 1 - 50 ",
      "field_length": fieldEntity.field_length === null || fieldEntity.field_length.trim() === "" ? "Enter field length" : inputFieldLengthValidation(fieldEntity.field_length, 4) ? null : "Max Length is 9999",
      "position_from": fieldEntity.position_from === null || fieldEntity.position_from.trim() === "" ? "Enter field start position" : inputFieldLengthValidation(fieldEntity.field_length, 4) ? null : "Max position is 9999",
    })
    if (
      fieldEntity.field_name.trim() !== ""
      && fieldEntity.field_length !== null && fieldEntity.field_length.trim() !== ""
      && fieldEntity.position_from !== null && fieldEntity.position_from.trim() !== "" &&
      inputFieldLengthValidation(fieldEntity.field_name, 100) &&
      inputFieldLengthValidation(fieldEntity.field_pattern, 50) &&
      inputFieldLengthValidation(fieldEntity.field_length, 4) &&
      inputFieldLengthValidation(fieldEntity.position_from, 4)
    ) {
      return true
    }
    return false
  }

  const inputFieldLengthValidation = (data, requiredLength) => {

    let dataLength = data.length;
    if (dataLength <= requiredLength) {
      return true
    } else return false
  }
  function overlaps(fieldA, fieldB) {
    return (
      parseInt(fieldA.position_from) >= parseInt(fieldB.position_from) &&
      parseInt(fieldA.position_from) <=
      parseInt(fieldB.position_from) + parseInt(fieldB.field_length) - 1
    );
  }



  const handleaddFieldPopUP = (activity) => {
    // console.log("fieldEntity", fieldEntity);

    if (activity == "close") {
      props.setIsEditing({ "state": false, "item": "", "index": "", "type": "" })
      setFieldEntity({ "field_location": "2", "field_name": "", "field_type": "String", "field_pattern": "" })
      props.setAddFieldPopUP(false)
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFieldEntity((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // console.log(fieldEntity);
  };

  const handleFieldNameChange = (event) => {
    // console.log("HERE");
    const value = event.target.value
      .toLowerCase()
      .replace(/ /g, "_")
      .replace(/[^a-z_0-9]/g, ""); // only allow lowercase letters and underscores
    setFieldEntity((prevState) => ({
      ...prevState,
      field_name: value,
    }));
  };

  const handleSubmit = () => {

    if (inputFieldValidation()) {
      if (props.fieldType == "Source") {
        const exists = props.allFields.input_fields.some(
          (field, index) =>
            field.field_name === fieldEntity.field_name &&
            field.field_location === fieldEntity.field_location &&
            index !== props.isEditing.index
        );
        if (!exists) {
          const overlapsExisting = props.allFields.input_fields.find(
            (field, index) =>
              index !== props.isEditing.index &&
              overlaps(fieldEntity, field) &&
              field.field_location === fieldEntity.field_location
          );

          if (!overlapsExisting) {
            let newInputFields;
            let newMappingFields;
            let newArrows;
            if (props.isEditing.state) {
              newInputFields = [...props.allFields.input_fields];
              if ((newInputFields[props.isEditing.index].field_name != fieldEntity.field_name)||(newInputFields[props.isEditing.index].field_location != fieldlocations[fieldEntity.field_location])) 
              {
                newMappingFields = props.allFields.mapping_fields.map(mappingField => {
                  if (mappingField.function_params === newInputFields[props.isEditing.index].field_name) {
                    mappingField = { ...mappingField, function_params: fieldEntity.field_name, field_type: fieldlocations[fieldEntity.field_location] }
                  }
                  return mappingField;

                });
                newArrows = props.arrows.map(arrow => {
                  if (arrow.start.includes(newInputFields[props.isEditing.index].field_name)) {
                    let startArr = arrow.start.split("_")
                    startArr[1] = fieldlocations[fieldEntity.field_location]
                    startArr.splice(2);
                    let newStart = startArr.join("_") + "_" + fieldEntity.field_name;
                    return { ...arrow, start: newStart };
                  }
                  return arrow;
                });
                props.setArrows(newArrows)
              }
              else {
                newMappingFields = [...props.allFields.mapping_fields]

              }
              newInputFields[props.isEditing.index] = fieldEntity;
            } else {
              newInputFields = [...props.allFields.input_fields, fieldEntity];
            }
            newInputFields.sort((a, b) => {
              if (a.field_location !== b.field_location) {
                return a.field_location.localeCompare(b.field_location);
              } else {
                return parseInt(a.position_from) - parseInt(b.position_from);
              }
            });

            const updatedFields = { ...props.allFields, input_fields: newInputFields };
            // updateArrows();
            // console.log("updatedFields", updatedFields);
            props.setAllFields(updatedFields);
            props.setAddFieldPopUP(false)
            props.setIsEditing({ "state": false, "item": "", "index": "", "type": "" })
            setFieldEntity({ "field_location": "2", "field_name": "", "position_from": "", "field_length": "", "field_type": "String", "field_pattern": "" })
          } else {
            setFieldOverlaps({ "state": true, "field": overlapsExisting })
          }
        }
        else {
          setFieldExist(true)
        }

      }
      else if (props.fieldType == "Destination") {
        const exists = props.allFields.output_fields.some(
          (field, index) =>
            field.field_name === fieldEntity.field_name &&
            field.field_location === fieldEntity.field_location &&
            index !== props.isEditing.index
        );
        if (!exists) {
          const overlapsExisting = props.allFields.output_fields.find(
            (field, index) =>
              index !== props.isEditing.index &&
              overlaps(fieldEntity, field) &&
              field.field_location === fieldEntity.field_location
          );
          if (!overlapsExisting) {
            let newOutputFields;
            let newMappingFields;
            let newArrows;
            if (props.isEditing.state) {
              // console.log("PROPS", props);
              newOutputFields = [...props.allFields.output_fields];
              if ((newOutputFields[props.isEditing.index].field_name != fieldEntity.field_name)||(newOutputFields[props.isEditing.index].field_location != fieldlocations[fieldEntity.field_location])) 
              {
                newMappingFields = props.allFields.mapping_fields.map(mappingField => {
                  if (mappingField.map_to === newOutputFields[props.isEditing.index].field_name) {
                    mappingField = { ...mappingField, map_to: fieldEntity.field_name, field_type: fieldlocations[fieldEntity.field_location] }
                  }
                  return mappingField;

                });
                
                newArrows = props.arrows.map(arrow => {
                  if (arrow.end.includes(newOutputFields[props.isEditing.index].field_name)) {
                    let endArr = arrow.end.split("_")
                    if (endArr[1] != fieldlocations[fieldEntity.field_location]) {
                      endArr[1] = fieldlocations[fieldEntity.field_location]
                    }
                    endArr.splice(2);
                    let newEnd = endArr.join("_") + "_" + fieldEntity.field_name;
                    return { ...arrow, end: newEnd };
                  }
                  return arrow;
                });
                props.setArrows(newArrows)
              }
              else {
                newMappingFields = [...props.allFields.mapping_fields]

              }
              newOutputFields[props.isEditing.index] = fieldEntity;

            } else {
              const mappingEntity = {
                field_type: fieldlocations[fieldEntity.field_location],
                map_to: fieldEntity.field_name,
                function_params: "",
                replace: "",
                pattern: "",
                function_name: ""
              };
              newMappingFields = [...props.allFields.mapping_fields, mappingEntity];
              newOutputFields = [...props.allFields.output_fields, fieldEntity];
            }
            newOutputFields.sort((a, b) => {
              if (a.field_location !== b.field_location) {
                return a.field_location.localeCompare(b.field_location);
              } else {
                return parseInt(a.position_from) - parseInt(b.position_from);
              }
            });
            const updatedFields = { ...props.allFields, output_fields: newOutputFields, mapping_fields: newMappingFields };
            // updateArrows();
            // console.log("outupdatedFields", updatedFields);
            props.setAllFields(updatedFields);
            props.setAddFieldPopUP(false)
            props.setIsEditing({ "state": false, "item": "", "index": "", "type": "" })
            setFieldEntity({ "field_location": "2", "field_name": "", "position_from": "", "field_length": "", "field_type": "String", "field_pattern": "" })
          } else {
            setFieldOverlaps({ "state": true, "field": overlapsExisting })
          }
        } else {
          setFieldExist(true)
        }
      }

    }
  }

  return (
    <React.Fragment>
      <div className={`modal fade modal--addnew ${props.addFieldPopUP ? 'show' : ''}`} aria-modal="true" data-toggle="modal" style={{ display: props.addFieldPopUP ? 'block' : 'none' }} id="addField" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">New {props.fieldType}  Field</h5>
              <button type="button" onClick={(() => { handleaddFieldPopUP("close") })} class="close" data-dismiss="modal" aria-label="Close">
                <img src={close_icon} alt="Close" />
              </button>
            </div>
            <div class="modal-body ">
              <div class="form mb-5" >
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label for="f2" class="d-block input-label">Field Location<span style={{ color: 'red' }}>*</span></label>
                      <div class="input-wrap date-wrap">
                        <select id="f1" onChange={handleInputChange} name="field_location" value={fieldEntity.field_location}>
                          <option value="2">Body</option>
                          <option value="1">Header</option>
                          <option value="3">Footer</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label for="pc" class="input-label">Field Name<span style={{ color: 'red' }}>*</span></label>
                      <input className={`txt ${inputErrorWarning.field_name ? 'is-invalid' : ''}`} value={fieldEntity.field_name} name="field_name" type="text" onChange={handleFieldNameChange} placeholder="Field Name" id="pc" />
                    </div>
                    {inputErrorWarning.field_name ?
                      (<div className="error-message">{inputErrorWarning.field_name}</div>)
                      : ('')}
                    {fieldExist ?
                      (<div className="error-message">Field already exist</div>)
                      : ('')}
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label for="cc" class="input-label">Start Position<span style={{ color: 'red' }}>*</span></label>
                      <input className={`txt ${inputErrorWarning.position_from ? 'is-invalid' : ''}`} value={fieldEntity.position_from} type="number" name="position_from" onChange={handleInputChange} min={0} step={1} placeholder="Start Position" id="cc" />
                    </div>
                    {inputErrorWarning.position_from ?
                      (<div className="error-message">{inputErrorWarning.position_from}</div>)
                      : ('')}
                    {fieldOverlaps.state ?
                      (<div className="error-message">Overlaps {fieldOverlaps.field.field_name} field</div>)
                      : ('')}

                  </div>

                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label for="cc" class="input-label">Field length<span style={{ color: 'red' }}>*</span></label>
                      <input className={`txt ${inputErrorWarning.field_length ? 'is-invalid' : ''}`} value={fieldEntity.field_length} type="number" name="field_length" min={0} step={1} onChange={handleInputChange} placeholder="Field Length" id="cc" />
                    </div>
                    {inputErrorWarning.field_length ?
                      (<div className="error-message">{inputErrorWarning.field_length}</div>)
                      : ('')}
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label for="f2" class="d-block input-label">Field Type<span style={{ color: 'red' }}>*</span></label>
                      <div class="input-wrap date-wrap">
                        <select id="f1" selected="String" onChange={handleInputChange} name="field_type" value={fieldEntity.field_type}>
                          <option value="String">String</option>
                          <option value="DATE/TIME">Date/Time</option>
                          <option value="Numeric">numeric</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label for="cc" class="input-label">Field Pattern</label>
                      <input class="txt" type="text" value={fieldEntity.field_pattern} name="field_pattern" onChange={handleInputChange} placeholder="Field Pattern" id="cc" />
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" onClick={(() => { handleaddFieldPopUP("close") })} class="button button--outline button--sm" data-dismiss="modal">Cancel</button>
                  <button type="button" onClick={(() => { handleSubmit() })} data-dismiss="modal" class="button button--sm">Save</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {props.addFieldPopUP && <div className="modal-backdrop fade show"></div>}
    </React.Fragment >
  );

}

const mapDispatchToProps = dispatch => ({
  dispatchOneTimeParams: (onSuccess, onError) =>
    dispatch(fetchOneTimeParams({ "keyList": ["preferred_mac", "preferred_cipher", "compression"] }, onSuccess, onError))
})

export default connect(null, mapDispatchToProps)(FileMappingPopupFlatComponent);

