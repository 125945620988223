import React from 'react';
import close_icon from '../assets/images/icon-close.png';

const Help = (props, { helpPopup, writeHelpPopup }) => {

    var configInfo = require(process.env.REACT_APP_CONFIG_URL);
    var helpEmail = configInfo.constants.helpEmail;

    const closeHelpPopup = (e) => {
        e.preventDefault();
        props.writeHelpPopup(props.helpPopup?false:true);
        window.scrollTo(0, 0);
    }
    
return (
    <React.Fragment>
        {
            props.helpPopup ? (
            
                <div class="modal fade modal--addnew show" aria-modal="true" style={{ display: props.helpPopup ? 'block' : 'none' }} data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel">
                   <div class="modal-dialog modal-dialog-centered" style={{ "maxWidth": "500px" }}>
                      <div class="modal-content">
                         <div class="modal-header">
                            <h1 class="modal-title" id="staticBackdropLabel">Need a Help ? </h1>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={(e) => closeHelpPopup(e)}>
                                        <img src={close_icon} alt="Close" />
                            </button>
                         </div>
                         <div class="modal-body">
                             <p><strong>Please contact {helpEmail} for additional assistance.</strong></p>
                             
                         </div>

       
                      </div>
                   </div>
                </div>
            ) : null}
    </React.Fragment>)
};

export default Help;

