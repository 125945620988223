import React, { useEffect, useState } from 'react';
import TemplateCreationOrUpdationComponent from "../template/template.new.component";
import TemplateListingComponent from "../template/template.list.component"
import { isAdmin, isPartnerAdmin } from '../../redux/actions/auth.action.creators';



const ManageTemplateComponent = () => {


    switch (window.location.pathname.replace('/template/', "")) {
        case "listing":
            return <TemplateListingComponent></TemplateListingComponent>
        default:
            return isPartnerAdmin() || isAdmin() ?
                <TemplateCreationOrUpdationComponent></TemplateCreationOrUpdationComponent>
                :
                <TemplateListingComponent></TemplateListingComponent>
    }

}


export default ManageTemplateComponent;