import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isPartnerCodeExist, getCountryList, listAllPartnerTags } from '../../redux/actions/auth.action.creators';
import CustomDropdownWidget from '../dropdown/CustomDropdownWidget'
import {sanitizedInput} from '../../utils/stringUtils'
import iconImport from '../../assets/images/import-red.png';
import { PartnerProfileInputModel, PartnerProtocolModel, PartnerRoleModel, PartnerSSHInBoundModel, PartnerSSHOutBoundModel, PartnerUserDetailsModel } from '../../Bean/partner';

const PartnerOnboardingComponent = (props, {dispatchGetAllPartnerTagList, dispatchPartnerCodeDuplicateChecker, dispatchCountryList, history }) => {


  const [inputErrorWarning, setInputErrorWarning] = useState('')
  const [pCodeExist, setPCodeExist] = useState(false)
  const [constrains, setConstrains] = useState({ "fieldPlaceholder": "Enter Country", "isMandatory": false, "fieldLabel": "Country", "inputErrorMessage": "Enter country" })
  const [responseData, setResponseData] = useState({})
  const [dropdownList, setDropdownList] = useState()
  const [definedCountry, setDefinedCountry] = useState({});

  const [partnerTagDropdownlist, setPartnerTagDropdownlist] = useState()
  const [partnerTagConstrains,] = useState({ "fieldPlaceholder": "Select Partner Tag", "isMandatory": false, "fieldLabel": "Partner Tag", "inputErrorMessage": "" })
  const [PartnertagDropdownRefresher, setPartnerTagDropdownRefresher] = useState(true)
  const [partnerTagResponseData, setPartnerTagResponseData] = useState({});
  const [editPartnerTagVal, setEditPartnerTagVal] = useState({});
  const appConf = require(process.env.REACT_APP_CONFIG_URLS);
  var companyCodeForPartnerTag = appConf.switch.companyCode;
  const [partnerIdExport, setPartnerIdExport] = useState('')

  const [inputDataProfile, setInputDataProfile] = useState(PartnerProfileInputModel)
  const [inputDataForUserDetails, setInputDataForUserDetails] = useState(PartnerUserDetailsModel)
  const [inputDataForRole, setInputDataForRole] = useState(PartnerRoleModel)
  const [inputDataForProtocol, setInputDataForProtocol] = useState(PartnerProtocolModel)

  const [sshInBound, setSSHInBound] = useState(PartnerSSHInBoundModel)
  const [sshOutBound, setSHHOutBound] = useState(PartnerSSHOutBoundModel)

  /* for ssh profile details loop render by add item*/
  const [spdElements, setSpdElements] = useState([{ f_permission: "", f_pattern: "", r_suffix: "", r_prefix: "", sshDetailsId: 0, sshId: 0, operationFlag: "I" }])

  const [updationStatus, setUpdationStatus] = useState(false)
  const [userIdForPartnerUpdate, setUserIdForPartnerUpdate] = useState(0)
  const [passwordUpdateFlag, setpasswordUpdateFlag] = useState(false)
  const [isAccessPortalValueFromDB, setIsAccessPortalValueFromDB] = useState(false)
  const [inOutPasswordUpdateFlag, setInOutPasswordUpdateFlag] = useState({ "inbnd": false, "outbnd": false })
  const [outboundAuthTypeFromDB, setOutboundAuthTypeFromDB] = useState('')
  const [inboundAuthTypeFromDB, setInboundAuthTypeFromDB] = useState('')

  let partnerCodeExist = false

  useEffect(() => {
    props.dispatchCountryList(({ data, message, status }) => {
      if (data) {
        let countryDataList = []
        data.map((item, i) => { countryDataList.push(item) })
        setDropdownList(countryDataList)
      }
    })
  }, [])

  useEffect(() => {
    if (!props.partnerTagDropdownlist || props.partnerTagDropdownlist.length == 0 ) {
      props.dispatchGetAllPartnerTagList(companyCodeForPartnerTag, ({ data, message, status }) => {
        var temp = []
        if (data) {
          data.map((pt) =>
            temp.push({ value: pt.id, label: pt.tag_name })
          )
          setPartnerTagDropdownlist(temp);
          props.setPartnerTagDropdownlist(temp);
        }
      });
    }else{
      setPartnerTagDropdownlist(props.partnerTagDropdownlist);
      setEditPartnerTagVal({
        "value": props.inputDataProfile.PartnerTag,
        "label": props.inputDataProfile.PartnerTagLabel
      })
    }
  }, []);
  
  useEffect(() => {
    if (props.updationStatus) {
      setDefinedCountry({
        "value": props.inputDataProfile.Country,
        "label": props.inputDataProfile.Country
      })
    }
    if (props.inputDataProfile.PartnerTag != 0 && props.inputDataProfile.PartnerTag != "") {
      console.log(props.inputDataProfile.PartnerTag);
      const tempvalue = props.partnerTagDropdownlist.filter(obj => obj.value == props.inputDataProfile.PartnerTag)
      console.log(props.partnerTagDropdownlist);
      console.log(tempvalue);
      if (tempvalue.length > 0) {
        props.inputDataProfile.PartnerTagLabel = tempvalue[0].label;
      }
      setEditPartnerTagVal({
        "value": props.inputDataProfile.PartnerTag,
        "label": props.inputDataProfile.PartnerTagLabel
      })

    }
  }, [props.updationStatus])

  useEffect(() => {
    setValuesToVariable("PartnerCountry", responseData.label)
  }, [responseData.label])

  useEffect(() => {
    if (partnerTagResponseData.value) {
      props.inputDataProfile.PartnerTag = partnerTagResponseData.value;
      props.inputDataProfile.PartnerTagLabel = partnerTagResponseData.label;
    }
  }, [partnerTagResponseData])




  const setValuesToVariable = (category, keyword) => {
    props.setInputDataProfile({
      "PartnerCode": category === "PartnerCode" ? keyword.replace(" ", "_").toLowerCase() : props.inputDataProfile.PartnerCode,
      "PartnerName": category === "PartnerName" ? keyword : props.inputDataProfile.PartnerName,
      "Address": category === "PartnerAddress" ? keyword : props.inputDataProfile.Address,
      "City": category === "PartnerCity" ? keyword : props.inputDataProfile.City,
      "State": category === "PartnerState" ? keyword : props.inputDataProfile.State,
      "Country": category === "PartnerCountry" ? keyword : props.inputDataProfile.Country,
      "Pincode": category === "PartnerPincode" ? keyword : props.inputDataProfile.Pincode,
      "Mobile": category === "PartnerMobile" ? keyword : props.inputDataProfile.Mobile,
      "Email": category === "PartnerEmail" ? keyword : props.inputDataProfile.Email,
      "PartnerTag": category === "PartnerTag" ? keyword : props.inputDataProfile.PartnerTag,
      "PartnerTagLabel": category === "PartnerTagLabel" ? keyword : props.inputDataProfile.PartnerTagLabel
    })
  }


  const resetInputVariableValues = () => {
    props.setInputDataProfile({
      "PartnerCode": props.updationStatus ? props.inputDataProfile.PartnerCode : "",
      "PartnerName": "",
      "Address": "",
      "City": "",
      "State": "",
      "Country": "",
      "Pincode": "",
      "Mobile": "",
      "Email": "",
      "PartnerTag":0,
      "PartnerTagLabel":""
    })
    setInputErrorWarning('')
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (inputFieldValidation()) {
      if (props.updationStatus) {
        props.setCursor('Partner_UserProfile')
      }
      else if (props.inputDataProfile.PartnerCode !== null && props.inputDataProfile.PartnerCode !== "" && props.inputDataProfile.PartnerCode.trim() !== "") {
        props.dispatchPartnerCodeDuplicateChecker(props.inputDataProfile.PartnerCode, ({ data, message, status }) => {
          if (data) {
            setPCodeExist(true)
          } else {
            setPCodeExist(false)
            props.setCursor('Partner_UserProfile')
          }
        })
      }
    }
  }

  const inputFieldValidation = () => {

    setInputErrorWarning({
      "PartnerCode": props.inputDataProfile.PartnerCode.trim() === "" ? "Enter Partner Code" : !inputFieldLengthValidation(props.inputDataProfile.PartnerCode, 100) ? "Length should be between 1 - 100 " : !sanitizedInput(props.inputDataProfile.PartnerCode,"partnerCode") ? "Invalid characters found!":"",
      "PartnerName": props.inputDataProfile.PartnerName.trim() === "" ? "Enter Partner Name" : !inputFieldLengthValidation(props.inputDataProfile.PartnerName, 100) ? "Length should be between 1 - 100 " : !sanitizedInput(props.inputDataProfile.PartnerName,"partnerName") ? "Invalid characters found!":"",
      "Address": !inputFieldLengthValidation(props.inputDataProfile.Address, 500) ? "Length should be between 1 - 500 " : !sanitizedInput(props.inputDataProfile.Address,"address") ? "Invalid characters found!":"",
      "City": !inputFieldLengthValidation(props.inputDataProfile.City, 50) ? "Length should be between 1 - 50 " : !sanitizedInput(props.inputDataProfile.City,"city" )? "Invalid characters found!":"",
      "State": !inputFieldLengthValidation(props.inputDataProfile.State, 50) ? "Length should be between 1 - 50 " : !sanitizedInput(props.inputDataProfile.State,"state") ? "Invalid characters found!":"",
      "Pincode": ((props.inputDataProfile.Pincode === null || props.inputDataProfile.Pincode.trim() === "") || inputFieldLengthValidation(props.inputDataProfile.Pincode, 20)) ? null : "Length should be between 1 - 20 ",
      "Mobile": props.inputDataProfile.Mobile === null || props.inputDataProfile.Mobile.trim() === "" ? "Enter Phone/Mobile Number" : !inputFieldLengthValidation(props.inputDataProfile.Mobile, 15) ? "Length should be between 1 - 15 ": !sanitizedInput(props.inputDataProfile.Mobile,"mobile") ? "Invalid characters found!":"",
      "Email": props.inputDataProfile.Email === null || props.inputDataProfile.Email.trim() === "" ? "Enter Email Address" : emailValidation() ? null : "Enter Valid Email ",
    })
    console.log(inputErrorWarning);
    if (
      props.inputDataProfile.PartnerCode.trim() !== ""
      && props.inputDataProfile.PartnerName.trim() !== ""
      && props.inputDataProfile.Mobile !== null && props.inputDataProfile.Mobile.trim() !== ""
      && props.inputDataProfile.Email !== null && props.inputDataProfile.Email.trim() !== "" && sanitizedInput(props.inputDataProfile.Mobile,"mobile")  &&
      emailValidation() &&
      inputFieldLengthValidation(props.inputDataProfile.PartnerCode, 100) && sanitizedInput(props.inputDataProfile.PartnerCode,"partnerCode") &&
      inputFieldLengthValidation(props.inputDataProfile.PartnerName, 100) && sanitizedInput(props.inputDataProfile.PartnerName,"partnerName") &&
      inputFieldLengthValidation(props.inputDataProfile.Address, 500) && sanitizedInput(props.inputDataProfile.Address,"address") &&
      inputFieldLengthValidation(props.inputDataProfile.City, 50) && sanitizedInput(props.inputDataProfile.City,"city") &&
      inputFieldLengthValidation(props.inputDataProfile.State, 50) && sanitizedInput(props.inputDataProfile.State,"state") &&
      inputFieldLengthValidation(props.inputDataProfile.Pincode, 50) &&
      (props.inputDataProfile.Mobile === null || props.inputDataProfile.Mobile.trim() === "" || inputFieldLengthValidation(props.inputDataProfile.Mobile, 15))
    ) {
      return true
    }
    return false
  }

  const inputFieldLengthValidation = (data, requiredLength) => {    
    let dataLength = data.length;
    if (dataLength <= requiredLength) {
      return true
    } else return false    
  }

  const emailValidation = () => {
    // commenting for new reqiurement : multiple email feature : 15-07-22 Nimal
    // if (new RegExp(/^(\d{9}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4}))$/).test(props.inputDataProfile.Email)) {
    //   return true;
    // } return false 
    //add multi email validation instead of single email
    var multipleEmailValidationResponse =  multiEmailvalidation(props.inputDataProfile.Email);
    console.log("multiple email validation status : ",multipleEmailValidationResponse);
    return multipleEmailValidationResponse;
  }

  const multiEmailvalidation = (emails) => {
    if (emails) {
      emails.trim()
      var s = emails.lastIndexOf(';');
      var emailArray = emails.split(';'); var resultArray = [];
      emailArray.forEach(email => {
        if (!new RegExp(/^(\d{9}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4}))$/).test(email)) {
          resultArray.push(email)
        }
      });
      if (resultArray.length >= 1) {
        return false
      } else {
        return true
      }
    }

  }

  useEffect(() => {
    
    const path = window.location.pathname;
    const id = path.replace('/partnerOnboarding/', '');
    setPartnerIdExport(id);
  }, []); 

  const handleFileImport = (event) => {
    const file = event.target.files[0];
    console.log('File is:', file);
    
    const reader = new FileReader();
    reader.onload = (e) => {
      
      const fileContent = reader.result;
      const jsonData = JSON.parse(fileContent);
      console.log("jsonData.partnerTag",);
      props.setInputDataProfile('');
      props.setInputDataProfile({
        "PartnerCode": jsonData.partnerCode,
        "PartnerID": jsonData.partnerID,
        "PartnerName": jsonData.partnerName,
        "Address": jsonData.address,
        "City": jsonData.city,
        "Country": jsonData.country,
        "Email": jsonData.email,
        "Mobile": jsonData.phoneNumber,
        "Pincode": jsonData.pinCode,
        "State": jsonData.state,
        "PartnerTag":jsonData.partnerTag
      })
      setInputDataForUserDetails('');
      setInputDataForUserDetails({
        "ud_Username": jsonData.userDetails[0].username,
        "ud_Email": jsonData.userDetails[0].email,
        "ud_GivenName": jsonData.userDetails[0].givenName,
        "ud_SurName": jsonData.userDetails[0].surName,
        "ud_Password": "",
        "ud_ConfirmPassword": "",
        "isAccessPortal": jsonData.userDetails[0].accessPortal,
        "isSendOrReceiveEmails": jsonData.sendReceiveFiles ? jsonData.sendReceiveFiles : false,
        "acceptEmails": jsonData.acceptEmails,
        "sendEmails": jsonData.sendEmails,
        "notifications":jsonData.notifications
      })
      setUserIdForPartnerUpdate(jsonData.userDetails[0].userId)
      setIsAccessPortalValueFromDB(jsonData.userDetails[0].accessPortal)
      setInboundAuthTypeFromDB(jsonData.sshProfileResponse.inboundAuthenticationType !== null ? jsonData.sshProfileResponse.inboundAuthenticationType.trim() : "")
      setOutboundAuthTypeFromDB(jsonData.sshProfileResponse.outboundAuthenticationType !== null ? jsonData.sshProfileResponse.outboundAuthenticationType.trim() : "")
      setInputDataForRole({
        "partnerRoleConsumer": jsonData.partnerRoleConsumer,
        "partnerRoleConsumerInitiate": jsonData.partnerRoleConsumerInitiate,
        "partnerRoleConsumerListen": jsonData.partnerRoleConsumerListen,
        "partnerRoleProducer": jsonData.partnerRoleProducer,
        "partnerRoleProducerInitiate": jsonData.partnerRoleProducerInitiate,
        "partnerRoleProducerListen": jsonData.partnerRoleProducerListen,
        "outboundConnectionType": jsonData.outboundConnectionType,
        "setMaxFileSize": jsonData.setMaxFileSize,
        "maxFileSize": jsonData.maxFileSize
      }),
      setInputDataForProtocol({
        "partnerSendFilesAsEncrypted": jsonData.partnerSendFilesAsEncrypted,
        "routerPublicKeyringDropdown": jsonData.routerPublicKeyringDropdown,
        "verifyEncryptedFile": jsonData.verifyEncryptedFile,

        "partnerRequireFilesToBeEncrypted": jsonData.partnerRequireFilesToBeEncrypted,
        "partnerProtocolUserId": jsonData.partnerProtocolUserId,
        "partnerProtocolEmail": jsonData.partnerProtocolEmail,
        "partnerPublicKeyringName": jsonData.partnerPublicKeyringName,
        "signFile": jsonData.signFile, 
        "sendPublicFileAsEmail": jsonData.sendPublicFileAsEmail

      })
      setSSHInBound({
        "sshInBoundAuthenticationType": jsonData.sshProfileResponse.inboundAuthenticationType !== null ? jsonData.sshProfileResponse.inboundAuthenticationType.trim() : "",
        "userPublicKey": jsonData.sshProfileResponse.inboundPublicKey,
        "password": "",
        "confirmPassword": ""
      })
      setSHHOutBound({
        "remoteHost": jsonData.sshProfileResponse.outboundRemoteHost,
        "root":jsonData.sshProfileResponse.outboundRoot,
        "port": jsonData.sshProfileResponse.outboundPort,
        "knownHostKey": jsonData.sshProfileResponse.outboundKnownHostKey,
        "remoteUser": jsonData.sshProfileResponse.outboundRemoteUser,
        "downloadDirectory": jsonData.sshProfileResponse.outboundDownloadDirectory,
        "uploadDirectory": jsonData.sshProfileResponse.outboundUploadDirectory,
        "sshOutBoundAuthenticationType": jsonData.sshProfileResponse.outboundAuthenticationType !== null ? jsonData.sshProfileResponse.outboundAuthenticationType.trim() : "",
        "sshOutBoundPassword": "",
        "sshOutBoundConfirmPassword": "",
        "shhOutUserIdentityKey": jsonData.sshProfileResponse.outboundUserIdentityKey.replace("filehandler/application/sftp_keys/",''),
        "responseTimeout": jsonData.sshProfileResponse.outboundResponseTimeout,
        "characterEncoding": jsonData.sshProfileResponse.outboundCharacterEncoding,
        "preferredCipher": jsonData.sshProfileResponse.outboundPreferredCipher,
        "preferredMAC": jsonData.sshProfileResponse.outboundPreferredMAC,
        "compression": jsonData.sshProfileResponse.outboundCompression,
        "connectionRetryCount": jsonData.sshProfileResponse.outboundConnectionRetryCount,
        "retryDelay": jsonData.sshProfileResponse.outboundRetryDelay,
        "localPortRange": jsonData.sshProfileResponse.outboundLocalPortRange,
        "passPhrase":jsonData.sshProfileResponse.passPhrase,
        "bucketname": jsonData.sshProfileResponse.s3OutboundBucket,
        "region": jsonData.sshProfileResponse.s3OutboundRegion,
        "folderpath": jsonData.sshProfileResponse.s3OutboundFolderPath,
        "uploadFolderPath":jsonData.sshProfileResponse.s3OutboundUploadFolderPath,
        "s3OutboundIAMRole":jsonData.sshProfileResponse.s3OutboundIAMRole
      })
      setUpdationStatus(true)
      console.log(jsonData.sshProfileDetailsList.length);
      if (jsonData.sshProfileDetailsList && jsonData.sshProfileDetailsList.length) {
        var sshProfileDetailsList = jsonData.sshProfileDetailsList;
        var tempArray = []
        var tempJSON = {}
        sshProfileDetailsList.forEach(element => {
          tempJSON = { f_permission: element.filePermission, f_pattern: element.filePattern, r_suffix: element.renameSuffix, r_prefix: element.renamePrefix, sshDetailsId: element.sshDetailsId, sshId: element.sshId, operationFlag: "M" }
          tempArray.push(tempJSON)
        });
        setSpdElements(tempArray)
      }
    };
        
    reader.readAsText(file);
  };


  return (
    <React.Fragment>

      <main>
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb__item">
              <a className="breadcrumb__link" href="">Manage Partner</a>
            </li>
            <li className="breadcrumb__item">
              <a className="breadcrumb__link" href='/partner'>Partner</a>
            </li>
            <li className="breadcrumb__item">
              <span className="breadcrumb__link" >Onboarding</span>
            </li>
          </ul>
          <div className="title">
            <h1>Partner Onboarding</h1>
            {partnerIdExport === "new" && (
            <div className="col-lg-4 col-md-4 text-right">
              
              <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileImport} accept=".pihub" />
              <label htmlFor="fileInput" className="btn btn-secondary btn-sm" style={{ background: '#FFF', color: 'black', borderColor: 'red', width: '30%', justifyContent: 'right' }}>
                <img src={iconImport} alt="Import" /> Import
              </label>
            </div>
          )}
            
          </div>
          {/* tab starts here */}
          <div className="tab tab--partner">
            <ul className="tab--partner__list">
              <li className="tab--partner__item is-active">
                <div className="bar"></div>
                <div className="content">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill=""><rect fill="none" height="24" width="24" /><g><path d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,2.73L18,18H6l0-1.61c0-1.18,0.68-2.26,1.76-2.73 C8.93,13.14,10.37,12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1 C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85 C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M12,6c1.66,0,3,1.34,3,3 c0,1.66-1.34,3-3,3s-3-1.34-3-3C9,7.34,10.34,6,12,6z" /></g></svg>
                  </div>
                  <div className="text">Partner Profile</div>
                </div>
              </li>
              <li className="tab--partner__item">
                <div className="bar"></div>
                <div className="content">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill=""><rect fill="none" height="24" width="24" /><path d="M12,3L2,21h20L12,3z M13,8.92L18.6,19H13V8.92z M11,8.92V19H5.4L11,8.92z" /></svg>
                  </div>
                  <div className="text">User Details</div>
                </div>
              </li>
              <li className="tab--partner__item">
                <div className="bar"></div>
                <div className="content">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill=""><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><g><circle cx="10" cy="8" r="4" /><path d="M10.67,13.02C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26 C10.47,18.87,10,17.49,10,16C10,14.93,10.25,13.93,10.67,13.02z" /><path d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z" /></g></g></svg>
                  </div>
                  <div className="text">Partner Role</div>
                </div>
              </li>
              <li className="tab--partner__item">
                <div className="bar"></div>
                <div className="content">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill=""><path d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="19" r="2" /><path d="M10 3h4v12h-4z" /></svg>
                  </div>
                  <div className="text">Protocol</div>
                </div>
              </li>
            </ul>
          </div>
          {/* tab ends here  */}
          <h2 className="mb-3">Partner Profile </h2>
          {/* form starts here */}
          <form noValidate onSubmit={onSubmit}>
            <div className="form mb-5">
              <div className="row">
                <div className="col-lg-6 col-md-6 ">
                  <div className="form-group">
                    <label for="f1" className="input-label">Partner Code<span style={{ color: 'red' }}>*</span></label>
                    <div className="input-wrap txt-wrap">
                      <input disabled={props.updationStatus ? true : false} className={`txt ${inputErrorWarning.PartnerCode ? 'is-invalid' : ''}`} type="text" value={props.inputDataProfile.PartnerCode} autoComplete="off" onChange={((e) => { setValuesToVariable("PartnerCode", e.target.value) })} placeholder="Enter Partner Code" id="f1" />
                      {inputErrorWarning.PartnerCode ?
                        (<div className="error-message">{inputErrorWarning.PartnerCode}</div>)
                        : ('')}
                      {pCodeExist && !props.updationStatus ?
                        (<div className="error-message">Partner code already exist</div>)
                        : ('')}
                    </div>

                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label for="f1" className="input-label"> Partner Name<span style={{ color: 'red' }}>*</span></label>
                    <div className="input-wrap txt-wrap">
                      <input className={`txt ${inputErrorWarning.PartnerName ? 'is-invalid' : ''}`} type="text" value={props.inputDataProfile.PartnerName} autoComplete="off" onChange={((e) => { setValuesToVariable("PartnerName", e.target.value) })} placeholder="Enter Partner Name" id="f1" />
                      {inputErrorWarning.PartnerName ?
                        (<div className="error-message">{inputErrorWarning.PartnerName}</div>)
                        : ('')}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label for="f1" className="input-label">Address</label>
                    <div className="input-wrap txt-wrap">
                      <input className={`txt ${inputErrorWarning.Address ? 'is-invalid' : ''}`} type="text" value={props.inputDataProfile.Address} autoComplete="off" onChange={((e) => { setValuesToVariable("PartnerAddress", e.target.value) })} placeholder="Enter Address" id="f1" />
                      {inputErrorWarning.Address ?
                        (<div className="error-message">{inputErrorWarning.Address}</div>)
                        : ('')}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label for="f1" className="input-label">City</label>
                    <div className="input-wrap txt-wrap">
                      <input className={`txt ${inputErrorWarning.City ? 'is-invalid' : ''}`} type="text" value={props.inputDataProfile.City} autoComplete="off" onChange={((e) => { setValuesToVariable("PartnerCity", e.target.value) })} placeholder="Enter City" id="f1" />
                      {inputErrorWarning.City ?
                        (<div className="error-message">{inputErrorWarning.City}</div>)
                        : ('')}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label for="f1" className="input-label">State</label>
                    <div className="input-wrap txt-wrap">
                      <input className={`txt ${inputErrorWarning.State ? 'is-invalid' : ''}`} type="text" value={props.inputDataProfile.State} autoComplete="off" onChange={((e) => { setValuesToVariable("PartnerState", e.target.value) })} placeholder="Enter State" id="f1" />
                      {inputErrorWarning.State ?
                        (<div className="error-message">{inputErrorWarning.State}</div>)
                        : ('')}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <div className="input-wrap txt-wrap">
                      <CustomDropdownWidget dropdownList={dropdownList} constrains={constrains} editValue={definedCountry} setResponseData={setResponseData} ></CustomDropdownWidget>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label for="f1" className="input-label">Pincode</label>
                    <div className="input-wrap txt-wrap">
                      <input className={`txt ${inputErrorWarning.Pincode ? 'is-invalid' : ''}`} min="0" type="number" value={props.inputDataProfile.Pincode} autoComplete="off" onChange={((e) => { setValuesToVariable("PartnerPincode", e.target.value) })} placeholder="Enter Pincode" id="f1" />
                      {inputErrorWarning.Pincode ?
                        (<div className="error-message">{inputErrorWarning.Pincode}</div>)
                        : ('')}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label for="f1" className="input-label">Phone/Mobile<span style={{ color: 'red' }}>*</span></label>
                    <div className="input-wrap txt-wrap">
                      <input className={`txt ${inputErrorWarning.Mobile ? 'is-invalid' : ''}`} min="0" type="text" value={props.inputDataProfile.Mobile} autoComplete="off" onChange={((e) => { setValuesToVariable("PartnerMobile", e.target.value) })} placeholder="Enter Phone/Mobile" id="f1" />
                      {inputErrorWarning.Mobile ?
                        (<div className="error-message">{inputErrorWarning.Mobile}</div>)
                        : ('')}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label for="f1" className="input-label">Email<span style={{ color: 'red' }}>*</span></label>
                    <div className="input-wrap txt-wrap">
                      <input className={`txt ${inputErrorWarning.Email ? 'is-invalid' : ''}`} type="text" value={props.inputDataProfile.Email} autoComplete="off" onChange={((e) => { setValuesToVariable("PartnerEmail", e.target.value) })} placeholder="Enter Email" id="f1" />
                      <span style={{ fontSize: "10px", color: 'grey' }}>(Enter Emails With Semicolons Separate)</span>
                      {inputErrorWarning.Email ?
                        (<div className="error-message">{inputErrorWarning.Email}</div>)
                        : ('')}
                    </div>
                  </div>
                </div>
                {/* Partner tag */}
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                      <CustomDropdownWidget dropdownRefresher={PartnertagDropdownRefresher} dropdownList={partnerTagDropdownlist} constrains={partnerTagConstrains} setResponseData={setPartnerTagResponseData} editValue={editPartnerTagVal}></CustomDropdownWidget>
                  </div>
                </div>
              </div>
            </div>


            <div className="btn-wrap btn-wrap--grp mt-5 justify-content-end">
              <button type="reset" onClick={(() => resetInputVariableValues())} className="button button--outline">Reset</button>
              <button type="submit" className="button">Next</button>
            </div>
          </form>
          {/* Form ends here */}
        </div>
      </main>


    </React.Fragment>


  );

}

const mapDispatchToProps = dispatch => ({
  dispatchPartnerCodeDuplicateChecker: (partnerCode, onSuccess, onError) =>
    dispatch(isPartnerCodeExist(partnerCode, onSuccess, onError)),

  dispatchCountryList: (onSuccess, onError) =>
    dispatch(getCountryList(onSuccess, onError)),
    dispatchGetAllPartnerTagList: (companyCodeForPartnerTag, onSuccess, onError) =>
    dispatch(listAllPartnerTags(companyCodeForPartnerTag, onSuccess, onError)),
})

export default connect(null, mapDispatchToProps)(PartnerOnboardingComponent);
