import { useEffect } from "react"



// partner bean modal 
export const PartnerProfileInputModel = () => {
    return {
        "PartnerCode": "",
        "PartnerName": "",
        "Address": "",
        "City": "",
        "State": "",
        "Country": "",
        "Pincode": "",
        "Mobile": "",
        "Email": "",
        "PartnerTag":0,
        "PartnerTagLabel":""
    }
}

export const PartnerUserDetailsModel = () => {
    return {
        "ud_Username": "",
        "ud_Email": "",
        "ud_GivenName": "",
        "ud_SurName": "",
        "ud_Password": "",
        "ud_ConfirmPassword": "",
        "isAccessPortal": false,
        "isSendOrReceiveEmails": false,
        "acceptEmails": "",
        "sendEmails": "",
        "notifications":""
    }
}


export const PartnerRoleModel = () => {
    return {
        "partnerRoleConsumer": false,
        "partnerRoleConsumerInitiate": false,
        "partnerRoleConsumerListen": false,
        "partnerRoleProducer": false,
        "partnerRoleProducerInitiate": false,
        "partnerRoleProducerListen": false,
        "setMaxFileSize": false,
        "maxFileSize": ""
    }
}



export const PartnerProtocolModel = () => {
    return {
        "partnerSendFilesAsEncrypted": false,
        "routerPublicKeyringDropdown": "",
        "verifyEncryptedFile": false,

        "partnerRequireFilesToBeEncrypted": false,
        "partnerProtocolUserId": "",
        "partnerProtocolEmail": "",
        "partnerPublicKeyringName": "",
        "signFile": false,

        "sendPublicFileAsEmail": false
    }
}


export const PartnerSSHInBoundModel = () => {
    return {
        "sshInBoundAuthenticationType": "",
        "userPublicKey": "",
        "password": "",
        "confirmPassword": ""
    }
}

export const PartnerSSHOutBoundModel = () => {

    return {
        "remoteHost": "",
        "root":"/",
        "port": null,
        "knownHostKey": "",
        "remoteUser": "",
        "downloadDirectory": "",
        "uploadDirectory": "",
        "sshOutBoundAuthenticationType": "",
        "sshOutBoundPassword": "",
        "sshOutBoundConfirmPassword": "",
        "shhOutUserIdentityKey": "",
        "responseTimeout": "",
        "characterEncoding": "",
        "preferredCipher": "",
        "preferredMAC": "",
        "compression": "",
        "connectionRetryCount": "",
        "retryDelay": "",
        "localPortRange": "",
        "bucketname": "",
        "region": "",
        "folderpath": "",
        "uploadFolderPath":"",
        "s3OutboundIAMRole":""
    }
}


