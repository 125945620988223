import React from 'react';
import FileMissingAlertComponent from '../components/file.missing.alert.component';



const FileMissingAlertPage = ()=> {
    return(
        <FileMissingAlertComponent/>
    )
}

export default FileMissingAlertPage;
