import React from 'react';
import IconTick from '../assets/images/icon-tick.png';

const AlertSuccess = ({ message }) => (
    <div className="alert alert--success">
        <div className="alert__icon"> <img src={IconTick} alt="Success" /></div>
        <div className="alert__message">
            {/* <strong>Success!</strong> */}
            {message}
        </div>
        {/* <a href="#" className="close">
            <img src={IconClose} alt="close" />
        </a> */}
  </div>
);

export default AlertSuccess;
