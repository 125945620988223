import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import sort_icon from '../../assets/images/icon-sort.png';
import { listGroups } from '../../redux/actions/auth.action.creators'
import { TemplateDetailsModel } from '../../Bean/template';
import { fetchOneTimeParams, templateCreation, fetchTemplateDetailsById, templateNameIsPresent, templateModification } from '../../redux/actions/auth.action.creators';
import AlertFailure from '../../utils/alert-failure.component';
import AlertSuccess from '../../utils/alert-success.component';
import BackIcon from '../../assets/images/icon-back.png'
import iconImport from '../../assets/images/import-red.png';
import {sanitizedInput} from '../../utils/stringUtils'

const TemplateCreationOrUpdationComponent = ({ dispatchGetGroupList, dispatchOneTimeParams, dispatchModifyTemplate, dispatchCreateTemplate, dispatchGetTemplateById, dispatchTemplateNameExist }) => {


    const [groupCollection, setGroupCollection] = useState([])
    const [onSearchKeyword, setOnSearchKeyword] = useState({ "group": "", "producer": "", "consumer": "" })
    const [onSortParams, setOnSortParams] = useState({ "group": true, "producer": true, "consumer": true })
    const [templateDetails, setTemplateDetails] = useState(TemplateDetailsModel)
    const [fileTypePAC, setFileTypePAC] = useState({ "producer": [], "consumer": [] })
    const [filePattern, setFilePattern] = useState([])
    const [inputErrorWarning, setInputErrorWarning] = useState('')
    const [groupCollectionRequest, setGroupCollectionRequest] = useState({ "p_count": 0, "c_count": 0, "request": [{}] })
    const [popupVisibility, setPopupVisibility] = useState({ success: false, failed: false, message: null })
    const [editMode, setEditMode] = useState(false)
    const [onReseting, setOnReseting] = useState(true)
    const [importStatus, setImportStatus] = useState(0);

    let producer_group_code = 'PRD'
    let consumer_group_code = 'CNS'
    let templateIdForEdit = window.location.pathname.replace('/template/', "")

    useEffect(() => {
        var templateId = window.location.pathname.replace('/template/', "")
        if (templateId !== "" && templateId !== "new") {
            try {
                templateIdForEdit = templateId;
                templateId = parseInt(templateId);
            } catch (error) { console.log(error); }
            dispatchGetTemplateById(templateId, ({ data, message }) => {
                if (data) {
                    setEditMode(true)
                    var tempList = []
                    const tempArray = []
                    data.templateGroups.forEach(g_templateData => {
                        tempList.push(
                            {
                                "groupCode": g_templateData.groupCode,
                                "groupName": g_templateData.groupName,
                                "category": g_templateData.groupType === producer_group_code ? 'producer' : g_templateData.groupType === consumer_group_code ? 'consumer' : 'group',
                                "selected": false,
                                "operationFlag": "M",
                                "templateGroupId": g_templateData.templateGroupId
                            }
                        );
                        tempArray.push(g_templateData.groupCode)
                    });
                    setTemplateDetails({
                        "templateId": data.templateId,
                        "templateName": data.templateName,
                        "templateType": data.templateType,

                        "templateProducerDetailsId": data.templateProducer[0].templateDetailId,
                        "partnerReceiveVia": data.templateProducer[0].receiveOverEmail
                            ? "EMAIL" : data.templateProducer[0].receiveOverSftp
                                ? "SFTP" : data.templateProducer[0].receiveOverWeb
                                    ? "WEB" : data.templateProducer[0].receiveOverS3
                                        ? "S3BUCKET" : "",
                        "templateProducerFileType": data.templateProducer[0].fileType,
                        "templateProducerFilePath": data.templateProducer[0].filePath.substring(data.templateProducer[0].filePath.lastIndexOf("/") + 1, data.templateProducer[0].filePath.length),
                        "templateProducerFileNamePattern": data.templateProducer[0].fileNamePattern,


                        "templateConsumerDetailsId": data.templateConsumer[0].templateDetailId,
                        "partnerDeliverVia": data.templateConsumer[0].receiveOverEmail ?
                            "EMAIL" : data.templateConsumer[0].receiveOverSftp ?
                                "SFTP" : data.templateConsumer[0].receiveOverWeb ?
                                    "WEB" : data.templateProducer[0].receiveOverS3
                                        ? "S3BUCKET" : "",
                        "deliverOverConsumerListen": data.templateConsumer[0].deliverOverConsumerListen,
                        "templateConsumerFileType": data.templateConsumer[0].fileType,
                        "templateConsumerFilePath": data.templateConsumer[0].filePath.substring(data.templateConsumer[0].filePath.lastIndexOf("/") + 1, data.templateConsumer[0].filePath.length),
                        "templateConsumerFileNamePattern": data.templateConsumer[0].fileNamePattern
                    })
                    dispatchGetGroupList(({ data, message }) => {
                        if (data) {
                            data.forEach(record => {
                                if (record && record.groupStatus === "A") {
                                    if (!tempArray.includes(record.groupCode)) {
                                        tempList.push(
                                            {
                                                "groupCode": record.groupCode,
                                                "groupName": record.groupName,
                                                "category": "group",
                                                "operationFlag": "I",
                                                "selected": false,
                                                "templateGroupId": null
                                            }
                                        );
                                    }
                                }
                            });
                            setGroupCollection(tempList)
                        }
                    },
                        (message) => { console.log(message); }
                    );

                }
            })
        } else if (templateId === "new") {
            setEditMode(false)
            dispatchGetGroupList(({ data, message }) => {
                if (data) {
                    var tempList = []
                    data.forEach(record => {
                        if (record && record.groupStatus === "A") {
                            tempList.push(
                                {
                                    "groupCode": record.groupCode,
                                    "groupName": record.groupName,
                                    "category": "group",
                                    "operationFlag": "I",
                                    "selected": false,
                                    "templateGroupId": 0
                                }
                            );
                        }
                    });
                    setGroupCollection(tempList)
                }
            },
                (message) => { console.log(message); }
            );
        }
    }, [onReseting])


    useEffect(() => {
        dispatchOneTimeParams(({ data, message, status }) => {
            let c = []; let p = []; let f = []
            if (data) {
                p.push({ "label": "", "value": "Choose Any" })
                // f.push({ "label": "", "value": "Choose Any" })
                c.push({ "label": "", "value": "Choose Any" })
                c.push({ "label": "UNK", "value": "Unknown" })
                data.template_consumer_file_type.forEach(element => {
                    c.push(element)
                });
                data.template_producer_file_type.forEach(element => {
                    p.push(element)
                });
                setFileTypePAC({ "producer": p, "consumer": c })
                data.template_file_pattern.forEach(element => {
                    f.push(element)
                });
                setFilePattern(f)
            }
        })
    }, [])

    useEffect(() => {
        let p_count = 0; let c_count = 0;
        let requestArray = []
        groupCollection.forEach(g_data => {
            if (g_data.category === 'producer') {
                let attrFeild1 =
                {
                    "groupCode": g_data.groupCode,
                    "groupName": g_data.groupName,
                    "groupType": producer_group_code,
                    "operationFlag": g_data.operationFlag,
                    "templateGroupId": g_data.templateGroupId
                }
                p_count = p_count + 1;
                requestArray.push(attrFeild1)
            } else if (g_data.category === 'consumer') {
                let attrFeild2 =
                {
                    "groupCode": g_data.groupCode,
                    "groupName": g_data.groupName,
                    "groupType": consumer_group_code,
                    "operationFlag": g_data.operationFlag,
                    "templateGroupId": g_data.templateGroupId
                }
                c_count = c_count + 1;
                requestArray.push(attrFeild2)
            } else if (g_data.templateGroupId !== null) {
                let attrFeild3 =
                {
                    "groupCode": g_data.groupCode,
                    "groupName": g_data.groupName,
                    "groupType": consumer_group_code,
                    "operationFlag": "D",
                    "templateGroupId": g_data.templateGroupId
                }
                requestArray.push(attrFeild3)
            }
        });
        setGroupCollectionRequest({ "p_count": p_count, "c_count": c_count, "request": requestArray })
    }, [groupCollection])

    function sortOperation(order) {
        var property = "groupName"
        if (order === "asc") {
            return function (a, b) {
                if (a[property].toLowerCase() > b[property].toLowerCase())
                    return 1;
                else if (a[property].toLowerCase() < b[property].toLowerCase())
                    return -1;
                return 0;
            }
        } else if (order === "desc") {
            return function (b, a) {
                if (a[property].toLowerCase() > b[property].toLowerCase())
                    return 1;
                else if (a[property].toLowerCase() < b[property].toLowerCase())
                    return -1;
                return 0;
            }
        }

    }

    const comeSortAndGo = (block, order) => {
        var sortedGroup = []
        var sortedProducer = []
        var sortedConsumer = []
        var tempArray = []
        groupCollection.forEach(element => {
            if (element.category === "group") {
                sortedGroup.push(element)
            } else if (element.category === "producer") {
                sortedProducer.push(element)
            } else if (element.category === "consumer") {
                sortedConsumer.push(element)
            }
        });
        if (block === "group") {
            sortedGroup.sort(sortOperation(order));
            setOnSortParams({ "group": !onSortParams.group, "consumer": onSortParams.consumer, "producer": onSortParams.producer })
        } else if (block === "consumer") {
            sortedConsumer.sort(sortOperation(order));
            setOnSortParams({ "group": onSortParams.group, "consumer": !onSortParams.consumer, "producer": onSortParams.producer })
        } else if (block === "producer") {
            sortedProducer.sort(sortOperation(order));
            setOnSortParams({ "group": onSortParams.group, "consumer": onSortParams.consumer, "producer": !onSortParams.producer })
        }

        sortedGroup.forEach(element => {
            tempArray.push(element)
        });
        sortedConsumer.forEach(element => {
            tempArray.push(element)
        });
        sortedProducer.forEach(element => {
            tempArray.push(element)
        });
        setGroupCollection(tempArray)
    }

    const rowSelector = (e, g_code) => {
        e.preventDefault();
        var tempArray = [];
        groupCollection.forEach(group_row_data => {
            if (group_row_data.groupCode === g_code) {
                tempArray.push(
                    {
                        "groupCode": group_row_data.groupCode,
                        "groupName": group_row_data.groupName,
                        "category": group_row_data.category,
                        "selected": !group_row_data.selected,
                        "operationFlag": group_row_data.operationFlag,
                        "templateGroupId": group_row_data.templateGroupId
                    }
                );
            } else { tempArray.push(group_row_data); }
        });
        setGroupCollection(tempArray)
    }

    function elementDropping(e, category) {
        e.preventDefault();
        var tempArray = [];
        groupCollection.forEach(group_row_data => {
            if (group_row_data.selected) {
                tempArray.push(
                    {
                        "groupCode": group_row_data.groupCode,
                        "groupName": group_row_data.groupName,
                        "category": category,
                        "selected": !group_row_data.selected,
                        "operationFlag": group_row_data.operationFlag,
                        "templateGroupId": group_row_data.templateGroupId
                    }
                );
            } else { tempArray.push(group_row_data); }
        });
        setGroupCollection(tempArray)
    }

    // prefix + input.substr(prefix.length)

    const setValuesToVariable = (category, keyword) => {
        setTemplateDetails({
            "templateId": editMode ? templateDetails.templateId : 0,
            "templateName": category === "templateName" ? keyword.replace(" ", "_").toLowerCase() : templateDetails.templateName,
            "templateType": category === "templateType" ? keyword : templateDetails.templateType,

            //producer
            "partnerReceiveVia": category === "partnerReceiveVia" ? keyword : templateDetails.partnerReceiveVia,
            "templateProducerFileType": category === "templateProducerFileType" ? keyword : templateDetails.templateProducerFileType,
            "templateProducerFilePath": category === "templateProducerFilePath" ? keyword.replace("/", "") : templateDetails.templateProducerFilePath,
            "templateProducerFileNamePattern": category === "templateProducerFileNamePattern" ? keyword : templateDetails.templateProducerFileNamePattern,

            //consumer
            "partnerDeliverVia": category === "partnerDeliverVia" ? keyword : templateDetails.partnerDeliverVia,
            "deliverOverConsumerListen": category === "deliverOverConsumerListen" ? keyword : templateDetails.deliverOverConsumerListen,
            "templateConsumerFileType": category === "templateConsumerFileType" ? keyword : templateDetails.templateConsumerFileType,
            "templateConsumerFilePath": category === "templateConsumerFilePath" ? keyword : templateDetails.templateConsumerFilePath,
            "templateConsumerFileNamePattern": category === "templateConsumerFileNamePattern" ? keyword : templateDetails.templateConsumerFileNamePattern,
            "templateProducerDetailsId": editMode ? templateDetails.templateProducerDetailsId : 0,
            "templateConsumerDetailsId": editMode ? templateDetails.templateConsumerDetailsId : 0,
        })
    }
    const producerInfoValidation = () => {
        if (templateDetails.templateProducerFileType !== null && templateDetails.templateProducerFileType !== "" &&
            templateDetails.templateProducerFileNamePattern !== null && templateDetails.templateProducerFileNamePattern !== "" &&
            templateDetails.partnerReceiveVia !== null && templateDetails.partnerReceiveVia !== "") {
            if (templateDetails.partnerReceiveVia !== "SFTP") {
                return true
            } else if (templateDetails.partnerReceiveVia === "SFTP" && templateDetails.templateProducerFilePath !== null && templateDetails.templateProducerFilePath !== "") {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const consumerInfoValidation = () => {
        if (templateDetails.templateConsumerFileType !== null && templateDetails.templateConsumerFileType !== "" &&
            templateDetails.templateConsumerFileNamePattern !== null && templateDetails.templateConsumerFileNamePattern !== "" &&
            templateDetails.partnerDeliverVia !== null && templateDetails.partnerDeliverVia !== "") {
            if (templateDetails.partnerDeliverVia !== "SFTP") {
                return true
            } else if (templateDetails.partnerDeliverVia === "SFTP" && templateDetails.templateConsumerFilePath !== null && templateDetails.templateConsumerFilePath !== "") {
                return true
            } else {
                return false
            }
        } else {
            return false;
        }
    }

    const groupInfoValidation = () => {
        if (groupCollectionRequest.p_count > 0 && groupCollectionRequest.c_count > 0) {
            return true
        } else {
            return false
        }
    }

    const inputFieldValidation = () => {
        setInputErrorWarning({
            "templateName": templateDetails.templateName === null || templateDetails.templateName === "" ? "Enter Template Name" : !sanitizedInput(templateDetails.templateName,"templateName") ? "Invalid characters found!":"",
            "templateType": templateDetails.templateType === null || templateDetails.templateType === "" ? "Choose Any" : null,

            "partnerReceiveVia": templateDetails.partnerReceiveVia === null || templateDetails.partnerReceiveVia === "" ? "Choose Receive Method" : null,
            "templateProducerFileType": templateDetails.templateProducerFileType === null || templateDetails.templateProducerFileType === "" ? "Choose File Type" : null,
            "templateProducerFilePath": templateDetails.templateProducerFilePath === null || templateDetails.templateProducerFilePath === "" ? templateDetails.partnerReceiveVia === "SFTP" ? "Enter Upload File Path" : null : null,
            "templateProducerFileNamePattern": templateDetails.templateProducerFileNamePattern === null || templateDetails.templateProducerFileNamePattern === "" ? "Choose File Name Pattern" : null,

            "partnerDeliverVia": templateDetails.partnerDeliverVia === null || templateDetails.partnerDeliverVia === "" ? "Choose Deliver Method" : null,
            "templateConsumerFileType": templateDetails.templateConsumerFileType === null || templateDetails.templateConsumerFileType === "" ? "Choose File Type" : null,
            "templateConsumerFilePath": templateDetails.templateConsumerFilePath === null || templateDetails.templateConsumerFilePath === "" ? templateDetails.partnerDeliverVia === "SFTP" ? "Enter Download File Path" : null : null,
            "templateConsumerFileNamePattern": templateDetails.templateConsumerFileNamePattern === null || templateDetails.templateConsumerFileNamePattern === "" ? "Choose File Name Pattern" : null,

            "producerBlockWarning": groupCollectionRequest.p_count === 0 ? "Choose Any Group" : null,
            "consumerBlockWarning": groupCollectionRequest.c_count === 0 ? "Choose Any Group" : null
        })
        if (
            (templateDetails.templateName !== null && templateDetails.templateName !== "") &&
            (templateDetails.templateType !== null && templateDetails.templateType !== "") &&
            sanitizedInput(templateDetails.templateName,"templateName") &&
            producerInfoValidation() && consumerInfoValidation() && groupInfoValidation()
        ) {
            return true
        } else {
            return false;
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (inputFieldValidation()) {
            var producerFolderPath = templateDetails.partnerReceiveVia === "EMAIL"
                ? "filehandler/partners/${producer}/email/" : templateDetails.partnerReceiveVia === "WEB"
                    ? "filehandler/partners/${producer}/web/" : templateDetails.partnerReceiveVia === "SFTP"
                        ? "filehandler/partners/${producer}/sftp/" : templateDetails.partnerReceiveVia === "S3BUCKET"
                            ? "filehandler/partners/${producer}/sftp/Upload" : "";
            var consumerFolderPath = templateDetails.partnerDeliverVia === "EMAIL"
                ? "filehandler/partners/${consumer}/sftp/Download" : templateDetails.partnerDeliverVia === "WEB"
                    ? "filehandler/partners/${consumer}/sftp/Download" : templateDetails.partnerDeliverVia === "SFTP"
                        ? "filehandler/partners/${consumer}/sftp/" : templateDetails.partnerDeliverVia === "S3BUCKET"
                            ? "filehandler/partners/${consumer}/sftp/Download" : "";
            var requestBody = {
                "templateId": templateDetails.templateId,
                "templateName": templateDetails.templateName,
                "templateType": templateDetails.templateType,
                "templateGroups": groupCollectionRequest.request,
                "templateProducerRequests": [
                    {
                        "decryptionRequired": false,
                        "fileNamePattern": templateDetails.templateProducerFileNamePattern,
                        "filePath": templateDetails.partnerReceiveVia === "SFTP" ? producerFolderPath + templateDetails.templateProducerFilePath : producerFolderPath.slice(0, -1),
                        "fileType": templateDetails.templateProducerFileType,
                        "gpgDirectory": "",
                        "gpgPath": "",
                        "groupType": producer_group_code,
                        "operationFlag": editMode ? "M" : "I",
                        "receiveOverEmail": templateDetails.partnerReceiveVia === "EMAIL" ? true : false,
                        "receiveOverSftp": templateDetails.partnerReceiveVia === "SFTP" ? true : false,
                        "receiveOverWeb": templateDetails.partnerReceiveVia === "WEB" ? true : false,
                        "receiveOverS3": templateDetails.partnerReceiveVia === "S3BUCKET" ? true : false,
                        "templateDetailId": templateDetails.templateProducerDetailsId
                    }
                ],
                "templateConsumerRequests": [
                    {
                        "deliverOverConsumerListen": templateDetails.deliverOverConsumerListen,
                        "encryptionRequired": false,
                        "fileNamePattern": templateDetails.templateConsumerFileNamePattern,
                        "filePath": templateDetails.partnerDeliverVia === "SFTP" ? consumerFolderPath + templateDetails.templateConsumerFilePath : consumerFolderPath, /*consumerFolderPath.slice(0, -1),*/
                        "fileType": templateDetails.templateConsumerFileType,
                        "gpgDirectory": "",
                        "gpgPath": "",
                        "groupType": consumer_group_code,
                        "operationFlag": editMode ? "M" : "I",
                        "receiveOverEmail": templateDetails.partnerDeliverVia === "EMAIL" ? true : false,
                        "receiveOverSftp": templateDetails.partnerDeliverVia === "SFTP" ? true : false,
                        "receiveOverWeb": templateDetails.partnerDeliverVia === "WEB" ? true : false,
                        "receiveOverS3": templateDetails.partnerDeliverVia === "S3BUCKET" ? true : false,
                        "templateDetailId": templateDetails.templateConsumerDetailsId
                    }
                ]
            }
            dispatchTemplateNameExist(templateDetails.templateName, ({ data, message, status }) => {
                if (data && !editMode) {
                    window.scrollTo(0, 0)
                    enablePopup(false, "Template Name Already Exist")
                } else {
                    if (!editMode) {
                        dispatchCreateTemplate(requestBody, ({ data, message, status }) => {
                            if (status === 'SUCCESS') {
                                window.scrollTo(0, 0)
                                enablePopup(true, "Template Creation Success!")
                            }
                        }, (message) => {
                            if (message) {
                                window.scrollTo(0, 0)
                                enablePopup(false, "Template Creation Failed! "+message)
                            }
                        });
                    } else {
                        dispatchModifyTemplate(requestBody, ({ data, message, status }) => {
                            if (status === 'SUCCESS') {
                                window.scrollTo(0, 0)
                                enablePopup(true, "Template Updation Success!")
                            }
                        }, (message) => {
                            if (message) {
                                window.scrollTo(0, 0)
                                enablePopup(false, "Template Updation Failed!")
                            }
                        });
                    }
                }
            })
        }
    }

    const enablePopup = (ctgry, msg) => {
        if (ctgry) {
            setPopupVisibility({ success: true, failed: false, message: msg })
            setTimeout(() => {
                setPopupVisibility({ success: false, failed: false, message: null })
            }, 6000);
        } else {
            setPopupVisibility({ success: false, failed: true, message: msg })
            setTimeout(() => {
                setPopupVisibility({ success: false, failed: false, message: null })
            }, 6000);
        }
    }

    const onReset = () => {
        setTemplateDetails(TemplateDetailsModel)
        setInputErrorWarning('')
        setGroupCollectionRequest({ "p_count": 0, "c_count": 0, "request": [{}] })
        setOnReseting(onReseting ? false : true)
    }

    const handleTemplateImport = (event) => {
        const file = event.target.files[0];
        setImportStatus('');
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const data = JSON.parse(e.target.result);
                    console.log('Parsed JSON:', data);
                    setEditMode(false)
                    var tempList = []
                    const tempArray = []
                    var templateGroups = data.templateGroups;
                    onReset();
                    setTemplateDetails({
                        "templateName": data.templateName,
                        "templateType": data.templateType,

                        "templateProducerDetailsId": data.templateProducer[0].templateDetailId,
                        "partnerReceiveVia": data.templateProducer[0].receiveOverEmail
                            ? "EMAIL" : data.templateProducer[0].receiveOverSftp
                                ? "SFTP" : data.templateProducer[0].receiveOverWeb
                                    ? "WEB" : data.templateProducer[0].receiveOverS3
                                        ? "S3BUCKET" : "",
                        "templateProducerFileType": validateAndSetFileType(data.templateProducer[0].fileType,'producer'),
                        "templateProducerFilePath": data.templateProducer[0].filePath.substring(data.templateProducer[0].filePath.lastIndexOf("/") + 1, data.templateProducer[0].filePath.length),
                        "templateProducerFileNamePattern": validateAndSetFileNamePattern(data.templateProducer[0].fileNamePattern),


                        "templateConsumerDetailsId": data.templateConsumer[0].templateDetailId,
                        "partnerDeliverVia": data.templateConsumer[0].receiveOverEmail ?
                            "EMAIL" : data.templateConsumer[0].receiveOverSftp ?
                                "SFTP" : data.templateConsumer[0].receiveOverWeb ?
                                    "WEB" : data.templateProducer[0].receiveOverS3
                                        ? "S3BUCKET" : "",
                        "deliverOverConsumerListen": data.templateConsumer[0].deliverOverConsumerListen,
                        "templateConsumerFileType": validateAndSetFileType(data.templateConsumer[0].fileType,'consumer'),
                        "templateConsumerFilePath": data.templateConsumer[0].filePath.substring(data.templateConsumer[0].filePath.lastIndexOf("/") + 1, data.templateConsumer[0].filePath.length),
                        "templateConsumerFileNamePattern": validateAndSetFileNamePattern(data.templateConsumer[0].fileNamePattern)
                    })

                    dispatchGetGroupList(({ data, message }) => {
                        if (data) {
                            data.forEach(record => {
                                if (record && record.groupStatus === "A") {
                                    var filteredGroups=null;
                                    if (templateGroups) {
                                        filteredGroups = templateGroups.filter(g => g.groupName === record.groupName)
                                    }
                                    var category = null;
                                    var operationFlag = null;
                                    if (filteredGroups && filteredGroups.length > 0) {
                                        category = filteredGroups[0].groupType === producer_group_code ? 'producer' : filteredGroups[0].groupType === consumer_group_code ? 'consumer' : 'group';
                                        operationFlag = "I"
                                    }else{
                                        category = "group"
                                        operationFlag = "M"
                                    }
                                    tempList.push(
                                        {
                                            "groupCode": record.groupCode,
                                            "groupName": record.groupName,
                                            "category": category,
                                            "operationFlag": operationFlag,
                                            "selected": false,
                                            "templateGroupId": 0
                                        }
                                    ); 
                                }
                            });
                            setGroupCollection(tempList);
                            setImportStatus('applyValidation');
                        }
                    },
                        (message) => { console.log(message);  }
                    );
                    
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                    window.scrollTo(0, 0)
                    enablePopup(false, "Invalid file!")
                }
            };
            reader.readAsText(file);
            // Reset the file input value
            event.target.value = null;
        }
    };

    useEffect(() => {
        if (importStatus === 'applyValidation') {
            if(!inputFieldValidation()){
                window.scrollTo(0, 0)
                enablePopup(false, "Please fix the errors in the form!")
            }
        }
      }, [importStatus]);

    const validateAndSetFileNamePattern = (pattern) => {
        var filterPattern = null;
        if (filePattern) {
            filterPattern = filePattern.filter(existingPattern => existingPattern.label === pattern);
            if (filterPattern && filterPattern.length > 0) {
                return pattern;
            }
        }
        return null;
    }

    const validateAndSetFileType = (fileType, type) => {
        var filteredFileType = null;
        console.log("File Type ",fileTypePAC);
        if (fileTypePAC && type == 'producer') {
            filteredFileType = fileTypePAC.producer.filter(existingFileType => existingFileType.label === fileType);
        } else if (fileTypePAC && type == 'consumer') {
            filteredFileType = fileTypePAC.consumer.filter(existingFileType => existingFileType.label === fileType);
        }
        if (filteredFileType && filteredFileType.length > 0) {
            return fileType;
        }
        return null;
    }

    return (
        <React.Fragment>
            <main>
                <form onSubmit={onSubmit}>
                    <div>
                        <div className="container">
                            <ul className="breadcrumb">
                                <li className="breadcrumb__item">
                                    <a className="breadcrumb__link" >Manage Partner</a>
                                </li>
                                <li className="breadcrumb__item">
                                    <a className="breadcrumb__link" href="/template/listing">Templates</a>
                                </li>
                                <li className="breadcrumb__item">
                                    <span className="breadcrumb__link">New</span>
                                </li>
                            </ul>
                            <div >
                                {popupVisibility.failed ?
                                    <AlertFailure message={popupVisibility.message} /> : ''}
                                {popupVisibility.success ?
                                    <AlertSuccess message={popupVisibility.message} /> : ''}
                            </div>
                            <div className="title">
                                <h1>Template</h1>
                                <a href='/template/listing' class="link link--back"><i> <img src={BackIcon} alt="Back" /></i>  Back to Templates</a>
                            </div>

                            <div className="form mb-1">
                               
                                {/* <div className="row">
                                    <div className="col-lg-12 mb-5">
                                        <ul className="radio-wrap">
                                            <li>Type<span style={{ color: 'red' }}>*</span> : </li>
                                            <li>
                                                <input type="radio" id="test1" name="radio-group" checked={templateDetails.templateType === "Static" ? true : false} onChange={(e) => setValuesToVariable("templateType", "Static")} />
                                                <label for="test1">Static</label>
                                            </li>
                                            <li>
                                                <input type="radio" id="test2" name="radio-group" checked={templateDetails.templateType === "Dynamic" ? true : false} onChange={(e) => setValuesToVariable("templateType", "Dynamic")} />
                                                <label for="test2">Dynamic</label>
                                            </li>
                                            {inputErrorWarning.templateType ?
                                                (<div style={{ position: "relative" }} className="error-message">{inputErrorWarning.templateType}</div>)
                                                : ('')}
                                        </ul>

                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* <!--groups starts here--> */}
                        <div className="grey-block">
                            <div className="container">
                                <div className="group group--template">
                                    <div class="row">
                                        <div className="col-lg-8 col-md-8">
                                           {(editMode)?<h2 class="mb-4">Edit Template</h2>:<h2 class="mb-4">Add Template</h2>} 
                                        </div>
                                        <div className="col-lg-4 col-md-4 text-right">
                                            <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleTemplateImport} accept=".pihub" />
                                            <label htmlFor="fileInput" disabled={editMode} className="btn btn-secondary btn-sm" style={{ background: '#FFF', color: 'black', borderColor: 'red', width: '30%', justifyContent: 'right' , display: (editMode) ? 'none':'inline-block' }}>
                                                <img src={iconImport} alt="Import" /> Import
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label for="f1" className="input-label">Template Name<span style={{ color: 'red' }}>*</span></label>
                                                <div className="input-wrap txt-wrap">
                                                    <input disabled={editMode} className="txt" type="text" value={templateDetails.templateName} autoComplete="off" onChange={(e) => setValuesToVariable("templateName", e.target.value)} placeholder="Enter Template Name" id="f1" />
                                                    {inputErrorWarning.templateName ?
                                                        (<div className="error-message">{inputErrorWarning.templateName}</div>)
                                                        : ('')}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4 text-right">
                                            <div className="form-group">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="widget widget--partner widget--template mb-3">
                                                <div className="head">
                                                    <h2>Producer Group
                                                        <sup className="asterisk">*</sup>
                                                    </h2>
                                                </div>
                                                <div className="input-wrap txt-wrap">
                                                    <input type="text" className="txt" value={onSearchKeyword.producer} onChange={(e) => setOnSearchKeyword({ "group": onSearchKeyword.group, "producer": e.target.value, "consumer": onSearchKeyword.consumer })} placeholder="Search..." />
                                                    <i className="fa fa-search"></i>
                                                </div>
                                                <div className="widget--partner__title" onClick={(e) => comeSortAndGo("producer", onSortParams.producer ? "asc" : "desc")}  >Sort
                                                    <i className="fa fa-sort" src={sort_icon} aria-hidden="true"></i>
                                                </div>
                                                <div className="widget--partner__list">
                                                    <ul id="sortable1" className='droptrue' onDragOver={(e) => e.preventDefault()} onDrop={(e) => elementDropping(e, "producer")} style={{ minHeight: '200px' }}>
                                                        {
                                                            (groupCollection.map((rowData) => (
                                                                rowData.category === "producer" ?
                                                                    (
                                                                        onSearchKeyword.producer !== "" && rowData.groupName.toLowerCase().includes(onSearchKeyword.producer) ?
                                                                            <li onClick={(e) => rowSelector(e, rowData.groupCode)} className={`${rowData.selected ? 'selected' : ''}`} onDragStart={(e) => ""} draggable key={rowData.groupCode}>
                                                                                <div className="drag"> <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 23 23" width="20px"> <path d="M0 0h24v24H0V0z" fill="none" />  <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /> </svg> </div>
                                                                                <span className="text">{rowData.groupName}</span>
                                                                            </li>
                                                                            :
                                                                            (
                                                                                onSearchKeyword.producer === "" ?
                                                                                    <li onClick={(e) => rowSelector(e, rowData.groupCode)} className={`${rowData.selected ? 'selected' : ''}`} onDragStart={(e) => ""} draggable key={rowData.groupCode}>
                                                                                        <div className="drag"> <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 23 23" width="20px"> <path d="M0 0h24v24H0V0z" fill="none" />  <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /> </svg> </div>
                                                                                        <span className="text">{rowData.groupName}</span>
                                                                                    </li>
                                                                                    :
                                                                                    ""
                                                                            )
                                                                    )
                                                                    : ""
                                                            )))
                                                        }
                                                    </ul>
                                                    {inputErrorWarning.producerBlockWarning ?
                                                        (<div className="error-message">{inputErrorWarning.producerBlockWarning}</div>)
                                                        : ('')}
                                                </div>
                                            </div>
                                            <div className="widget widget--partner widget--template">
                                                <div className="head">
                                                    <h2>Consumer Group
                                                        <sup className="asterisk">*</sup>
                                                    </h2>
                                                </div>
                                                <div className="input-wrap txt-wrap">
                                                    <input type="text" autoComplete="off" value={onSearchKeyword.consumer} onChange={(e) => setOnSearchKeyword({ "group": onSearchKeyword.group, "producer": onSearchKeyword.producer, "consumer": e.target.value })} className="txt" placeholder="Search..." />
                                                    <i className="fa fa-search"></i>
                                                </div>
                                                <div className="widget--partner__title" onClick={(e) => comeSortAndGo("consumer", onSortParams.consumer ? "asc" : "desc")}  >Sort
                                                    <i className="fa fa-sort " aria-hidden="true"></i>
                                                </div>
                                                <div className="widget--partner__list">
                                                    <ul id="sortable1" className='droptrue' onDragOver={(e) => e.preventDefault()} onDrop={(e) => elementDropping(e, "consumer")} style={{ minHeight: '200px' }}>
                                                        {
                                                            (groupCollection.map((rowData) => (
                                                                rowData.category === "consumer" ?
                                                                    (
                                                                        onSearchKeyword.consumer !== "" && rowData.groupName.toLowerCase().includes(onSearchKeyword.consumer) ?
                                                                            <li onClick={(e) => rowSelector(e, rowData.groupCode)} className={`${rowData.selected ? 'selected' : ''}`} onDragStart={(e) => ""} draggable key={rowData.groupCode}>
                                                                                <div className="drag"> <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 23 23" width="20px"> <path d="M0 0h24v24H0V0z" fill="none" />  <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /> </svg> </div>
                                                                                <span className="text">{rowData.groupName}</span>
                                                                            </li>
                                                                            :
                                                                            (
                                                                                onSearchKeyword.consumer === "" ?
                                                                                    <li onClick={(e) => rowSelector(e, rowData.groupCode)} className={`${rowData.selected ? 'selected' : ''}`} onDragStart={(e) => ""} draggable key={rowData.groupCode}>
                                                                                        <div className="drag"> <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 23 23" width="20px"> <path d="M0 0h24v24H0V0z" fill="none" />  <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /> </svg> </div>
                                                                                        <span className="text">{rowData.groupName}</span>
                                                                                    </li>
                                                                                    :
                                                                                    ""
                                                                            )
                                                                    )
                                                                    : ""
                                                            )))
                                                        }
                                                    </ul>
                                                    {inputErrorWarning.consumerBlockWarning ?
                                                        (<div className="error-message">{inputErrorWarning.consumerBlockWarning}</div>)
                                                        : ('')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="widget widget--partner widget--template h-100">
                                                <div className="head">
                                                    <h2>All Groups </h2>
                                                </div>
                                                <div className="input-wrap txt-wrap">
                                                    <input autoComplete="off" type="text" value={onSearchKeyword.group} className="txt" onChange={(e) => setOnSearchKeyword({ "group": e.target.value, "producer": onSearchKeyword.producer, "consumer": onSearchKeyword.consumer })} placeholder="Search..." />
                                                    <i className="fa fa-search"></i>
                                                </div>
                                                <div className="widget--partner__title" onClick={(e) => comeSortAndGo("group", onSortParams.group ? "asc" : "desc")}  >Sort
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                                <div className="widget--partner__list">
                                                    <ul id="sortable2" className='droptrue' onDragOver={(e) => e.preventDefault()} onDrop={(e) => elementDropping(e, "group")} style={{ minHeight: '400px' }}>
                                                        {
                                                            (groupCollection.map((rowData) => (
                                                                rowData.category === "group" ?
                                                                    (
                                                                        onSearchKeyword.group !== "" && rowData.groupName.toLowerCase().includes(onSearchKeyword.group) ?
                                                                            <li onClick={(e) => rowSelector(e, rowData.groupCode)} className={`${rowData.selected ? 'selected' : ''}`} onDragStart={(e) => ""} draggable key={rowData.groupCode}>
                                                                                <div className="drag"> <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 23 23" width="20px"> <path d="M0 0h24v24H0V0z" fill="none" />  <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /> </svg> </div>
                                                                                <span className="text">{rowData.groupName}</span>
                                                                            </li>
                                                                            :
                                                                            (
                                                                                onSearchKeyword.group === "" ?
                                                                                    <li onClick={(e) => rowSelector(e, rowData.groupCode)} className={`${rowData.selected ? 'selected' : ''}`} onDragStart={(e) => ""} draggable key={rowData.groupCode}>
                                                                                        <div className="drag"> <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 23 23" width="20px"> <path d="M0 0h24v24H0V0z" fill="none" />  <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /> </svg> </div>
                                                                                        <span className="text">{rowData.groupName}</span>
                                                                                    </li>
                                                                                    :
                                                                                    ""
                                                                            )
                                                                    )
                                                                    : ""
                                                            )))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--groups ends here--> */}
                        <div className="container">
                            {/* <!--form starts here--> */}
                            <div className="form form--template mb-5">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 mb-5 mb-md-0">
                                        <div className="form__head">
                                            <h2>Producer</h2>
                                        </div>
                                        <div class="form-group">
                                            <label for="f1" class="input-label"> Partner Sends Files Through</label>
                                            <div class="input-wrap select-wrap">
                                                <select id="fileThrough" value={templateDetails.partnerReceiveVia} onChange={(e) => setValuesToVariable("partnerReceiveVia", e.target.value)} >
                                                    <option value=''>Choose Any</option>
                                                    <option value='EMAIL'>Email</option>
                                                    <option value='SFTP'>SFTP</option>
                                                    <option value='WEB'>Web</option>
                                                    <option value='S3BUCKET'>AWS S3 Bucket</option>
                                                </select>
                                                {inputErrorWarning.partnerReceiveVia ?
                                                    (<div className="error-message">{inputErrorWarning.partnerReceiveVia}</div>)
                                                    : ('')}
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label for="templateProducerFileType" className="input-label"> File Type<span style={{ color: 'red' }}>*</span></label>
                                            <div className="input-wrap select-wrap">
                                                <select id="templateProducerFileType" onChange={(e) => setValuesToVariable("templateProducerFileType", e.target.value)} value={templateDetails.templateProducerFileType} >
                                                    {fileTypePAC.producer.map(row => (
                                                        <option value={row.label}>{row.value}</option>
                                                    ))}
                                                </select>
                                                {inputErrorWarning.templateProducerFileType ?
                                                    (<div className="error-message">{inputErrorWarning.templateProducerFileType}</div>)
                                                    : ('')}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label for="templateProducerFileNamePattern" className="input-label"> File Name Pattern<span style={{ color: 'red' }}>*</span></label>
                                            <div className="input-wrap select-wrap">
                                                <select id="templateProducerFileNamePattern" onChange={(e) => setValuesToVariable("templateProducerFileNamePattern", e.target.value)} value={templateDetails.templateProducerFileNamePattern} >
                                                    <option value="">Choose Any</option>
                                                    {filePattern.map(row => (
                                                        // templateDetails.templateProducerFileType === "DECRPT" && ((row.label.toLowerCase().includes("pgp") || row.label.toLowerCase().includes("gpg"))) ?
                                                            <option value={row.label}>{row.value}</option>
                                                            // :
                                                            // templateDetails.templateProducerFileType === "UNK" && (!(row.label.toLowerCase().includes("pgp")) && !(row.label.toLowerCase().includes("gpg"))) ?
                                                            //     <option value={row.label}>{row.value}</option>
                                                            //     :
                                                            //     ""
                                                    ))}
                                                </select>
                                                {inputErrorWarning.templateProducerFileNamePattern ?
                                                    (<div className="error-message">{inputErrorWarning.templateProducerFileNamePattern}</div>)
                                                    : ('')}
                                            </div>
                                        </div>
                                        {templateDetails.partnerReceiveVia === "SFTP" ?
                                            <div className="form-group">
                                                <label for="templateProducerFilePath" className="input-label">Path<span style={{ color: 'red' }}>*</span></label>
                                                <div className="input-wrap select-wrap">
                                                    <input className="txt" disabled type="text" placeholder="filehandler/partners/${producer}/sftp/" style={{ width: "53%", borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }} autoComplete="off" id="templateProducerFilePath" />
                                                    <input className="txt" onChange={(e) => setValuesToVariable("templateProducerFilePath", e.target.value)} value={templateDetails.templateProducerFilePath} style={{ width: "47%", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }} type="text" placeholder="Enter Upload Path(upload)" autoComplete="off" id="templateProducerFilePath" />
                                                    {inputErrorWarning.templateProducerFilePath ?
                                                        (<div className="error-message">{inputErrorWarning.templateProducerFilePath}</div>)
                                                        : ('')}
                                                </div>
                                            </div>
                                            :
                                            ""}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form__head">
                                            <h2>Consumer</h2>
                                        </div>
                                        <div class="form-group">
                                            <label for="f1" class="input-label"> Partner Will Deliver Files Through</label>
                                            <div class="input-wrap select-wrap">
                                                <select id="fileThrough" value={templateDetails.partnerDeliverVia} onChange={(e) => setValuesToVariable("partnerDeliverVia", e.target.value)} >
                                                    <option value=''>Choose Any</option>
                                                    <option value='EMAIL'>Email</option>
                                                    <option value='SFTP'>SFTP</option>
                                                    <option value='WEB'>Web</option>
                                                    <option value='S3BUCKET'>AWS S3 Bucket</option>
                                                </select>
                                                {inputErrorWarning.partnerDeliverVia ?
                                                    (<div className="error-message">{inputErrorWarning.partnerDeliverVia}</div>)
                                                    : ('')}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label for="f1" className="input-label"> File Type<span style={{ color: 'red' }}>*</span></label>
                                            <div className="input-wrap select-wrap">
                                                <select id="f1" onChange={(e) => setValuesToVariable("templateConsumerFileType", e.target.value)} value={templateDetails.templateConsumerFileType} >
                                                    {fileTypePAC.consumer.map(row => (
                                                        <option value={row.label}>{row.value}</option>
                                                    ))}
                                                </select>
                                                {inputErrorWarning.templateConsumerFileType ?
                                                    (<div className="error-message">{inputErrorWarning.templateConsumerFileType}</div>)
                                                    : ('')}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label for="templateConsumerFileNamePattern" className="input-label"> File Name Pattern<span style={{ color: 'red' }}>*</span></label>
                                            <div className="input-wrap select-wrap">
                                                <select id="templateConsumerFileNamePattern" onChange={(e) => setValuesToVariable("templateConsumerFileNamePattern", e.target.value)} value={templateDetails.templateConsumerFileNamePattern} >
                                                    <option value="">Choose Any</option> 
                                                    {filePattern.map(row => (
                                                        // templateDetails.templateConsumerFileType === "ENCRPT" && ((row.label.toLowerCase().includes("pgp") || row.label.toLowerCase().includes("gpg"))) ?
                                                            <option value={row.label}>{row.value}</option>
                                                            // :
                                                            // templateDetails.templateConsumerFileType === "UNK" && (!(row.label.toLowerCase().includes("pgp")) && !(row.label.toLowerCase().includes("gpg"))) ?
                                                            //     <option value={row.label}>{row.value}</option>
                                                            //     :
                                                            //     ""
                                                    ))}
                                                </select>
                                                {inputErrorWarning.templateConsumerFileNamePattern ?
                                                    (<div className="error-message">{inputErrorWarning.templateConsumerFileNamePattern}</div>)
                                                    : ('')}
                                            </div>
                                        </div>
                                        {templateDetails.partnerDeliverVia === "SFTP" ?
                                            <div>
                                                <div className="form-group">
                                                    <label for="f1" className="input-label">Path<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-wrap select-wrap">
                                                        <input className="txt" disabled type="text" placeholder="filehandler/partners/${consumer}/sftp/" style={{ width: "53%", borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }} autoComplete="off" id="f1" />
                                                        <input className="txt" onChange={(e) => setValuesToVariable("templateConsumerFilePath", e.target.value)} value={templateDetails.templateConsumerFilePath} style={{ width: "47%", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }} type="text" placeholder="Enter Download Path" autoComplete="off" id="f1" />
                                                        {inputErrorWarning.templateConsumerFilePath ?
                                                            (<div className="error-message">{inputErrorWarning.templateConsumerFilePath}</div>)
                                                            : ('')}
                                                    </div>
                                                    <br></br>
                                                </div>
                                                <div className="chcek-wrap">
                                                    <div className="form-check">
                                                        <input type="checkbox" id="yes" checked={templateDetails.deliverOverConsumerListen ? true : false} onChange={(e) => setValuesToVariable("deliverOverConsumerListen", templateDetails.deliverOverConsumerListen ? false : true)} />
                                                        <label for="yes">If checked, files will be delivered to consumer specified directory</label>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            ""
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--form ends here--> */}
                            <div className="btn-wrap btn-wrap--grp mt-5 justify-content-end mb-5">
                                <button className="button button--outline" type="reset" onClick={(e) => { onReset() }}>Reset</button>
                                <button className="button" type="submit">{editMode ? "Update" : "Save"}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </main>
        </React.Fragment>
    )
}



const mapDispatchToProps = dispatch => ({
    dispatchGetGroupList: (onSuccess, onError) =>
        dispatch(listGroups(onSuccess, onError)),
    dispatchOneTimeParams: (onSuccess, onError) =>
        dispatch(fetchOneTimeParams({ "keyList": ["template_producer_file_type", "template_consumer_file_type", "template_file_pattern"] }, onSuccess, onError)),
    dispatchCreateTemplate: (requestBody, onSuccess, onError) =>
        dispatch(templateCreation(requestBody, onSuccess, onError)),
    dispatchGetTemplateById: (templateId, onSuccess, onError) =>
        dispatch(fetchTemplateDetailsById(templateId, onSuccess, onError)),
    dispatchTemplateNameExist: (templateName, onSuccess, onError) =>
        dispatch(templateNameIsPresent(templateName, onSuccess, onError)),
    dispatchModifyTemplate: (requestBody, onSuccess, onError) =>
        dispatch(templateModification(requestBody, onSuccess, onError)),

})
export default connect(null, mapDispatchToProps)(TemplateCreationOrUpdationComponent)


