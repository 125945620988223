import React, { useEffect, useState } from 'react';
import { connect, useStore } from 'react-redux';
import { getAllActiveChannelsList,displayMappingData, modifyAttachedChannels } from '../../../redux/actions/auth.action.creators'
import { confirmDialog } from 'primereact/confirmdialog';
import close_icon from '../../../assets/images/icon-close.png';
import { default as ReactSelect, components } from "react-select";

const FileMappingPopupAttachChannel = (props, { dispatchListMappingAction, dispatchGetAllActiveChannelsList, dispatchModifyAttachedChannels }) => {
  const [channelListForDropDown, setChannelListForDropDown] = useState([]);
  const [attachedChannels, setAttachedChannels] = useState([]);
  const [change, setChange] = useState(false);
  useEffect(() => {
    const selectedChannels = props.attachMapping.attached_channels.split(",").map((value) => parseInt(value, 10));

    props.dispatchGetAllActiveChannelsList(({ data, message, status }) => {
      if (data) {
        const temp = data.map((ch) => ({
          value: ch.channel_id,
          label: ch.channelname
        }));
        const selected = temp.filter((ch) => selectedChannels.includes(ch.value));
        setAttachedChannels(selected);
        setChannelListForDropDown(temp);
      }
    });
  }, []);


  const editConfirm = (id, name, attached_channels) => {
    confirmDialog({
      message: 'Are you sure you want to make these changes?',
      header: 'Submit Confirmation',
      button: "button",
      acceptClassName: 'p-button-danger',
      accept() {
        handleSubmit()
      },
      reject() {
        // props.setAttachChannelsPopup(false)
      },
      acceptLabel: "Confirm",
      rejectLabel: "Cancel"
    });
  };

  const handleattachment = (e) => {
    setAttachedChannels(e)
    setChange(true)
  };

  const handleattachChannelsPopup = (activity) => {
    if (activity == "close") {
      props.setAttachChannelsPopup(false)
    }
  };
  const handleSubmit = () => {
    const attachString = attachedChannels.map(channel => channel.value).join(',');
    // console.log("ATTACH", attachString);
    props.dispatchModifyAttachedChannels(attachString, props.attachMapping.id, ({ data, message, status }) => {
      // console.log("STATUS", status);
      if (status === 'SUCCESS') {
        props.mappingTableList()
        // console.log("YES");
        props.setSuccMsg("Mapping Attached/Detached successfully");
        setTimeout(() => {
          props.setSuccMsg('')

        }, 3000);
      }
    },
      (message) => {
        if (message) {
          window.scrollTo(0, 0)
          serFailMsg("Channel Attachment Failed ")
          console.log(message);
        }
      });
    props.setAttachChannelsPopup(false)
  }

  return (
    <React.Fragment>
      <div className={`modal fade modal--addnew ${props.attachChannelsPopup ? 'show' : ''}`} aria-modal="true" data-toggle="modal" style={{ display: props.attachChannelsPopup ? 'block' : 'none' }} id="attach" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel" >Attach "<span style={{ color: 'red' }}>{props.attachMapping.mapping_name}</span>" to channels</h5>
              <button type="button" onClick={(() => { handleattachChannelsPopup("close") })} class="close" data-dismiss="modal" aria-label="Close">
                <img src={close_icon} alt="Close" />
              </button>
            </div>
            <div class="modal-body">
              <div class="form mb-5" >
                <div class="row">
                  <div class="col-lg-10 col-md-6">
                    <div className="form-group ">
                      <label htmlFor="f5" className="d-block input-label">Select Channels</label>
                      <ReactSelect
                        options={channelListForDropDown}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={true}
                        onChange={(e) => handleattachment(e)}
                        allowSelectAll={true}
                        value={attachedChannels}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" onClick={(() => { handleattachChannelsPopup("close") })} class="button button--outline button--sm" data-dismiss="modal">Cancel</button>
              <button type="button" onClick={(() => { change ? editConfirm() : handleSubmit() })} data-dismiss="modal" class="button button--sm">Save</button>
            </div>
          </div>
        </div>
      </div>
      {props.attachChannelsPopup && <div className="modal-backdrop fade show"></div>}
    </React.Fragment >
  );

}

const mapDispatchToProps = dispatch => ({
  dispatchGetAllActiveChannelsList: (onSuccess, onError) =>
    dispatch(getAllActiveChannelsList(onSuccess, onError)),
  dispatchModifyAttachedChannels: (data,onSuccess, onError) =>
    dispatch(modifyAttachedChannels(data,onSuccess, onError)),
    dispatchListMappingAction: (onSuccess, onError) => dispatch(displayMappingData(onSuccess, onError)),
})

export default connect(null, mapDispatchToProps)(FileMappingPopupAttachChannel);

