import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import close_icon from '../../assets/images/icon-close.png';

import { listPartners, idleChannelsAndPartnersReport, fetchSystemParams } from '../../redux/actions/auth.action.creators'
import CustomDropdownWidget from '../dropdown/CustomDropdownWidget';
import { DateRangePicker } from '../../utils/date-range-picker.component';
import { DownloadFileAsXLFromEncodedString } from '../../utils/DecodeToXL';
import * as constants from '../../redux/constants'




const IdleChannelsPartnersReportComponent = ({  dispatchPartnersNotSendingFilesReport, dispatchSystemParamsAction }) => {

    const [isPopupEnable, setIsPopupEnable] = useState(false)
    const [dropdownRefresher, setdropdownRefresher] = useState(true)
    const [responseData, setResponseData] = useState({});
    const [requestBody, setRequestBody] = useState({ "duration": constants.IDLE_CHANNEL_PARTNER_RETREIVELIMIT_DEFAULT, "requestType": "channel" })
    const [channelOrPartner, setChannelOrPartner] = useState('channel'); //new, exist 
    const [retreiveLimitList, setRetreiveLimitList] = useState({ "data": "", "loading": true });
    const [retreieveLimit, setRetreiveLimit] = useState(constants.IDLE_CHANNEL_PARTNER_RETREIVELIMIT_DEFAULT);
    const [waiting, setWaiting] = useState(false)
 


    useEffect(() => {
        // Update the requestBody based on the selected option whenever channelOrPartner changes
        setRequestBody(prevRequest => ({
            ...prevRequest,
            "requestType": channelOrPartner === 'channel' ? 'channel' : 'partner'
            // "partners": channelOrPartner === 'partner'
        }));

    }, [channelOrPartner]);


    useEffect(() => {
        dispatchSystemParamsAction({ "keyList": [constants.IDLE_CHANNEL_PARTNER_RETREIVELIMIT_LIST] }, ({ data, message }) => {
            if (data) {
                setRetreiveLimitList({ "data": data[constants.IDLE_CHANNEL_PARTNER_RETREIVELIMIT_LIST].paramValue.split(','), "loading": false });
            }
        }, (message) => {
            console.log(message);
        });
    }, [dispatchSystemParamsAction]);


    const waitingSpinLoader = () => {
        var ls_styles = {
            border: '5px solid red',
            borderRadius: '50%',
            borderTop: '5px solid grey',
            width: '30px',
            marginRight: '50px',
            marginBottom: '20px',
            height: '30px',
            animation: `downloadLoadingspin 1s linear infinite`

        };
        return <div><div style={ls_styles}></div><p>Downloading...</p></div>;
    }


    
    //     if (channelOrPartner !== "channel") {
    //         console.log("Please select 'Idle Channels' to proceed.");
    //         return;
    //     }

   

    const onSubmit = () => {
        // Check if a duration is selected
        if (!duration) {
            console.log("Please select a duration to proceed.");
            return;
        }
        console.log("Fetching data...");
        setWaiting(true);

        // Update the requestBody based on the selected option

        dispatchPartnersNotSendingFilesReport(requestBody, ({ data, message, status }) => {
            console.log("Data fetched.");

            setWaiting(false);
            if (data && data.length > 1) {
                console.log("Encoded String length:", data.length);

                // Assuming you have a function like DownloadFileAsXLFromEncodedString
                if (requestBody.requestType === 'channel') {

                    DownloadFileAsXLFromEncodedString(data, constants.IDLE_CHANNELS);
                } else {
                    console.log("partners:");

                    DownloadFileAsXLFromEncodedString(data, constants.IDLE_PARTNERS);

                }
            } else {
                console.log("No data found.");
            }

        });
    }



    const reset = () => {
        setdropdownRefresher(dropdownRefresher ? false : true)
        setRequestBody({ "duration": constants.IDLE_CHANNEL_PARTNER_RETREIVELIMIT_DEFAULT, "requestType": 'channel' });
        setResponseData({});
        setRetreiveLimit(constants.IDLE_CHANNEL_PARTNER_RETREIVELIMIT_DEFAULT)
        
    }

    const setLimit = (value) => {
        setRetreiveLimit(value);
        setRequestBody({ "duration": value, "requestType": requestBody.requestType });
    }


    return (
        <div class="col-lg-4 mb-4">
            <div class="widget widget--box widget--report" data-toggle="modal" data-target="#partnernotsendingfilereport" id="rprt_idlechnlprt">
                <div class="widget--report__details" onClick={(() => { setIsPopupEnable(true) })}>
                    <div class="widget--report__title">
                        <p><strong> Idle Channels and Partners</strong></p>
                    </div>
                    <div class="widget--report__desc">
                        <p> This Report lists all partners, channels who has not sent files in a selected period</p>
                    </div>
                </div>
            </div>
            <div class="modal fade modal--addnew show" aria-modal="true" style={{ display: isPopupEnable ? 'block' : 'none' }} id="partnernotsendingfilereport" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel">
                <div class="modal-dialog modal-lg modal-dialog-centered" style={{ "maxWidth": "500px" }}>
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel"> Idle Channels and Partners</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <img src={close_icon} alt="Close" />
                            </button>

                        </div>

                        <div class="modal-body">
                            <div class="form mb-5">
                                <div class="row">
                                    <div class="col d-flex justify-content-center">
                                        <ul className="radio-wrap">
                                            <li >
                                                <input type="radio" id="channels" name="radio-group" checked={channelOrPartner === "channel"} onChange={(e) => setChannelOrPartner('channel')} />
                                                <label className="input-label" for="channels">Idle Channels</label>
                                            </li>
                                            <li >
                                                <input type="radio" id="partners" name="radio-group" checked={channelOrPartner === "partner"} onChange={(e) => setChannelOrPartner('partner')} />
                                                <label className="input-label" for="partners">Idle Partners</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <br></br>

                                <div class="row">
                                    <div class="col d-flex justify-content-left">
                                        <div class="col-lg-6 col-md-6">

                                            <label for="f2" class="d-block input-label">Duration</label>
                                            <div class="input-wrap date-wrap">
                                                <select id="duration" onChange={(e) => { setLimit(e.target.value) }}>
                                                    {(retreiveLimitList.loading) ? '' :
                                                        retreiveLimitList.data.map(s => (
                                                            <option value={s} selected={retreieveLimit === s ? 'selected' : ''}>{s} {parseInt(s) > 1 ? 'Months' : 'Month'}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {waiting ?
                            <div class="modal-footer">
                                {waiting ? waitingSpinLoader() : ''}
                            </div>
                            :

                            <div class="modal-footer">
                                <button type="button" onClick={(() => { reset() })} class="button button--outline button--sm" data-dismiss="modal">Cancel</button>
                                <button type="button" onClick={(() => { onSubmit() })} class="button button--sm">Download Report</button>
                            </div>

                        }

                    </div>
                </div>
            </div>

        </div>
    )
}


const mapDispatchToProps = dispatch => ({

    dispatchListPartnersAction: (onSuccess, onError) =>
        dispatch(listPartners(onSuccess, onError)),
    dispatchPartnersNotSendingFilesReport: (data, onSuccess, onError) =>
        dispatch(idleChannelsAndPartnersReport(data, onSuccess, onError)),
    dispatchSystemParamsAction: (data, onSuccess, onError) =>
        dispatch(fetchSystemParams(data, onSuccess, onError))

});

export default connect(null, mapDispatchToProps)(IdleChannelsPartnersReportComponent);
