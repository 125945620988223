import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import close_icon from '../../assets/images/icon-close.png';
import * as constants from '../../redux/constants'
import { DownloadFileAsXLFromEncodedString } from '../../utils/DecodeToXL';
import { monthList } from "../../Bean/filestructure.js";
import { partnerFileSizeDetailsReport } from '../../redux/actions/auth.action.creators'

const ComponentForPartnerFileSizeMonitor = ({ dispatchPartnerFileSizeMonitor }) => {

   const [isPopupEnable, setIsPopupEnable] = useState(false);
   const [waiting, setWaiting] = useState(false);
   const [dropdown_yearList, setDropdown_yearList] = useState([]);

   var today_forfilevolume = new Date();

   const [fileSizeFilterRequest, setFileSizeFilterRequest] = useState({ filterBy: "MonthAndYear", year: today_forfilevolume.getFullYear(), month: today_forfilevolume.getMonth() + 1, sdate: new Date().toLocaleDateString("en-AU") + " " + "00:00:00", edate: new Date().toLocaleDateString("en-AU") + " " + "00:00:00" });

   const waitingSpinLoader = () => {
      var ls_styles = {
         border: '5px solid red',
         borderRadius: '50%',
         borderTop: '5px solid grey',
         width: '30px',
         marginRight: '50px',
         marginBottom:'20px',
         height: '30px',
         animation: `downloadLoadingspin 1s linear infinite`
      };

      return <div><div style={ls_styles}></div><p>Downloading...</p></div>;
   }

   const reset = () => {
      setFileSizeFilterRequest({ filterBy: "MonthAndYear", year: today_forfilevolume.getFullYear(), month: today_forfilevolume.getMonth() + 1, sdate: new Date().toLocaleDateString("en-AU") + " " + "00:00:00", edate: new Date().toLocaleDateString("en-AU") + " " + "00:00:00" });
      setIsPopupEnable(false);
   }

   const valueIsNull = (v1) => { return v1 === null || v1 === "" || v1.length < 1 ? true : false; }
   const valueIsNotNull = (v1) => { return v1 !== null && v1 !== "" ? true : false; }

   const isValid = () => {
      if (valueIsNull(fileSizeFilterRequest.year)) {
         return false;
      } else if (valueIsNull(fileSizeFilterRequest.month)) {
         return false;
      }
      return true;
   }

   const onSubmit = () => {
      if (isValid()) {
         console.log(fileSizeFilterRequest);
         setWaiting(true);
         if (fileSizeFilterRequest.filterBy === "Year") {
            if (fileSizeFilterRequest.year != "Choose Year" && fileSizeFilterRequest.year != "") {
              console.log("calling year wise records");
              console.log(fileSizeFilterRequest);
              dispatchPartnerFileSizeMonitor({ sdate: sdateFormater(fileSizeFilterRequest.year, 1), edate: edateFormater(fileSizeFilterRequest.year, 12), filterBy: "Year" }, ({ data, message, status }) => {
               if (data && data.length > 1) {
                  console.log("encoded String length :", data.length);
                  DownloadFileAsXLFromEncodedString(data, constants.FILE_SIZE_MONITOR_REPORT_PREFIX);
               }
               setWaiting(false);
              })
            }
         } else if (fileSizeFilterRequest.filterBy === "MonthAndYear") {
            if (valueIsNotNull(fileSizeFilterRequest.year) && valueIsNotNull(fileSizeFilterRequest.month) && valueIsNotNull(fileSizeFilterRequest.sdate) && valueIsNotNull(fileSizeFilterRequest.edate)) {
              console.log("calling year and month wise records");
              console.log(fileSizeFilterRequest);
              dispatchPartnerFileSizeMonitor({ sdate: sdateFormater(fileSizeFilterRequest.year, fileSizeFilterRequest.month), edate: edateFormater(fileSizeFilterRequest.year, fileSizeFilterRequest.month), filterBy: "MonthAndYear" }, ({ data, message, status }) => {
               if (data && data.length > 1) {
                  console.log("encoded String length :", data.length);
                  DownloadFileAsXLFromEncodedString(data, constants.FILE_SIZE_MONITOR_REPORT_PREFIX);
               }
               setWaiting(false);
              })
            }
         }
      }
   }

   const assignFilterValue = (category, targetValue) => {
      var temp = _.cloneDeep(fileSizeFilterRequest);
      console.log(category, targetValue);
      temp.filterBy = category === "filterBy" ? targetValue : fileSizeFilterRequest.filterBy
      temp.year = category === "year" ? targetValue : fileSizeFilterRequest.year
      temp.month = category === "month" ? targetValue : fileSizeFilterRequest.month
      temp.sdate = category === "month" ? sdateFormater(fileSizeFilterRequest.year, parseInt(targetValue)) : fileSizeFilterRequest.sdate
      temp.edate = category === "month" ? edateFormater(fileSizeFilterRequest.year, parseInt(targetValue)) : fileSizeFilterRequest.edate
      setFileSizeFilterRequest(temp);
    }


   const sdateFormater = (s_year, s_value) => {
      if (valueIsNotNull(s_year) && valueIsNotNull(s_value)) {
        var sd = new Date();
        sd.setFullYear(s_year, s_value - 1, 1)
        sd = sd.toLocaleDateString("en-CA") + " " + "00:00:00"
        return sd;
      }
   }

   const edateFormater = (e_year, e_value) => {
      if (valueIsNotNull(e_year) && valueIsNotNull(e_value) && e_value != 0) {
        var ed = new Date(e_year, e_value, 0)
        ed = ed.toLocaleDateString("en-CA") + " " + "23:59:59"
        return ed;
      }
   }

   useEffect(() => {
      var date = new Date();
      var dd = date.getFullYear() - 3;
      var temp = []
      temp.push("Choose Year")
      for (var i = 0; i < 10; i++) { temp.push(dd + i) }
      setDropdown_yearList(temp);
    }, [])

   return (
      <div class="col-lg-4 mb-4">
         <div class="widget widget--box widget--report" data-toggle="modal" data-target="#filesizemonitorreport" id="rprt_filesize">
            <div class="widget--report__details" onClick={(() => { setIsPopupEnable(true) })}>
               <div class="widget--report__title">
                  <p><strong> File Size Monitor Report</strong></p>
               </div>
               <div class="widget--report__desc">
                  <p> This report gives allocated and consumed file size for each partner.</p>
               </div>
            </div>
         </div>
         <div class="modal fade modal--addnew show" aria-modal="true" style={{ display: isPopupEnable ? 'block' : 'none' }} id="filesizemonitorreport" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel">
            <div class="modal-dialog modal-dialog-centered">
               <div class="modal-content">
                  <div class="modal-header">
                     <h5 class="modal-title" id="staticBackdropLabel"> File Size Monitor Report</h5>
                     <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <img src={close_icon} alt="Close" />
                     </button>
                  </div>
                  <div class="modal-body">
                     <div class="form mb-5">
                        <div class="row">
                           <div class="col-lg-2 col-md-6">
                              <div class="form-group">
                                 <strong>Filter By :<span style={{ color: 'red' }}>*</span></strong>
                              </div>
                           </div>

                           <div class="col-lg-3 col-md-6">
                              <div class="form-group">
                                 <select id="rprt_filesize_filterBy" style={{ cursor: "pointer" }} onChange={(e) => assignFilterValue("filterBy", e.target.value)}>
                                    <option id="rprt_filesize_filterbyyear" selected={fileSizeFilterRequest.filterBy === "Year" ? 'selected' : ''} value="Year">Year</option>
                                    <option id="rprt_filesize_filterbymonthandyear" selected={fileSizeFilterRequest.filterBy === "MonthAndYear" ? 'selected' : ''} value="MonthAndYear">Month and Year</option>
                                 </select>
                              </div>
                           </div>

                           <div class="col-lg-2 col-md-6">
                              <div class="form-group">
                                    <select id="rprt_filesize_year" style={{ cursor: "pointer" }} onChange={(e) => assignFilterValue("year", e.target.value)} >
                                       {dropdown_yearList.map((data, i) => (

                                          <option value={data} selected={fileSizeFilterRequest.year === data ? 'selected' : ''} >{data}</option>
                                       ))}
                                    </select>
                              </div>
                           </div>

                           {fileSizeFilterRequest.filterBy === 'MonthAndYear' ?
                              <div class="col-lg-2 col-md-6">
                                 <div class="form-group">
                                    <select id="rprt_filesize_month" style={{ cursor: "pointer" }} onChange={(e) => assignFilterValue("month", e.target.value)} >
                                       {monthList.map((data, i) => (
                                       <option value={data.value} selected={fileSizeFilterRequest.month === data.value ? 'selected' : ''}>{data.label}</option>
                                       ))}

                                    </select>
                                 </div>
                              </div>
                           :''}
                        </div>
                     </div>
                  </div>
                  {waiting ?
                     <div class="modal-footer">
                       {waiting ? waitingSpinLoader() : ''}
                     </div>
                     :
                     <div class="modal-footer">
                        <button id="rprt_filesize_cancelbtn" type="button" onClick={(() => { reset() })} class="button button--outline button--sm" data-dismiss="modal">Cancel</button>
                        <button id="rprt_filesize_downloadbtn" type="button" onClick={(() => { onSubmit() })} class="button button--sm">Download Report</button>
                     </div>
                  }
               </div>
            </div>
         </div>
      </div>

   );
}



const mapDispatchToProps = dispatch => ({
   dispatchPartnerFileSizeMonitor: (data, onSuccess, onError) =>
      dispatch(partnerFileSizeDetailsReport(data, onSuccess, onError))

});

export default connect(null, mapDispatchToProps)(ComponentForPartnerFileSizeMonitor);