export const API = 'API';
export const SET_USER_INFO = 'SET_USER_INFO';
export const TOGGLE_LOADER = 'TOGGLE_LOADER';
export const RESET_USER_INFO = 'RESET_USER_INFO';
// export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const SUCCESS_ALERT = 'SUCCESS_ALERT';
export const FAILURE_ALERT = 'FAILURE_ALERT';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_PROGRESS = 'FILE_UPLOAD_PROGRESS';
export const FILE_UPLOAD_FAILED = 'FILE_UPLOAD_FAILED';
export const FILEMONITOR_DATERANGE = 'filemonitor_daterange';
export const FILEMONITOR_RETREIVELIMIT_LIST = 'filemonitor_retreivelimit_list';
export const FILE_STATUS = 'file_status';
export const PERMIT_ARCHIVE_DWNLD = ['Administrator'];
export const FILEMONITOR_RETREIVELIMIT = 'filemonitor_retreive_limit';
export const FILEMONITOR_RETREIVELIMIT_DEFAULT = '30'
export const FILEMONITOR_DELETE_STATUS = ['UPLOADED', 'PROCESSING', 'FAILED','PROCESSED', 'DELIVERY FAILED']
export const FILEMONITOR_RETRY_STATUS = ['FAILED', 'PROCESSING', 'PROCESSED', 'DELIVERY FAILED','UPLOADED']
export const SCHEDULARSTATUS_RETRY_STATUS = ['Failed']
export const FAILED_DOWNLOAD_REPORT_PREFIX = 'FH_FailedReportDetails_'
export const AUDIT_DOWNLOAD_REPORT_PREFIX = 'FH_AuditReportDetails_'
export const AUDIT_REPORT_FILTER_LIMIT_DAY= 'audit_report_filter_limit_day'
export const FILE_CLOG_STATUS='file_clog_status'
export const FILE_CLOG_REPORT_PREFIX = 'SR_EmailClogDetails_'
export const MAILBOXES='sr_clog_mailbox'
export const FAILED_UPLOAD_REPORT_PREFIX = 'FH_FailedUploadReportDetails_'
export const OPERATIONTYPES_OVERRIDE='override_operation_types'
export const ATTRIBUTE_NAME_PARTNER_OVERRIDE='override_attribute_name'
export const OPERATION_PARTNER_OVERRIDE='override_operation'
export const BASE64_REGEX = new RegExp("^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=|[A-Za-z0-9+\/]{4})$");
export const DAILY_ALERT_REPORT = 'DailyAlertReport_'
export const FILENAMECONVERTER_DATATYPE = 'filenameconvertervariable_datatype'
export const FILENAMECONVERTER_OPERATION = 'filenameconvertervariable_operation'
export const FILENAMECONVERTER_GROUPTYPE = 'filenameconvertervariable_grouptype'
export const AUDIT_REPORT_DOWNLOADEDFILES = 'FH_AuditReport_DownloadedFiles'
export const AIRLINE_GROUP = 'airline_group';
export const WEB_LOGIN_AUDIT_DOWNLOADEDFILES = 'FH_WebLogin_Audit_DownloadedFiles'
export const USER_DELETION_THRESHOLD = "user_deletion_threshold";
export const ONETIME_REDISKEYS = "rediscache_keys";
export const TRUNCATE_TEXT = '30';
export const SSH_KEY_PATH = 'ssh_key_path';
export const PGP_KEY_PATH = 'hub_pgp_path';
export const IDLE_CHANNELS = 'FH_idle_channels_report_'
export const IDLE_PARTNERS = 'FH_idle_partners_report_'
export const IDLE_CHANNEL_PARTNER_RETREIVELIMIT_LIST = 'idle_channel_partner_retreivelimit_list';
export const IDLE_CHANNEL_PARTNER_RETREIVELIMIT_DEFAULT = '1'
export const PARTNERHEADERLISTINGMENU = ['ManagePartnerPage', 'GroupPage', 'TemplatePage', 'ChannelPage', 'ReportPage']
export const CONFIGURATIONHEADERLISTINGMENU = ['AddScheduler', 'SchedulerRunLogPage', 'FileMappingHomeComponentPage', 'PGPConfigurationPage', 'SSHKeygenerationPage', 'OneTimeParamsPage', 'FilenameConfigurationPage', 'PartnerOverridePage', 'SequenceNumberExcoPage']
export const UTILITIESHEADERLISTINGMENU = ['FileEncryptDecryptPage']
export const END_POINT_PATH = 'oauth2/authorization/azure'
export const SSO_ENDPOINT = '/ssoOnloadPage'
export const GROUP_NOTIFICATION_TYPES = 'group_notification_types'
export const FILE_SIZE_MONITOR_REPORT_PREFIX = 'FH_FileSizeMonitorDetails_'




