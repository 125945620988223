import React from 'react';
import ManageUserComponent from '../components/manager_user.component';




export default function ManageUsrPage() {


   return (<ManageUserComponent />);


}