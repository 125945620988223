import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import iconUpload from '../assets/images/icon-upload.png';
import iconClose from '../assets/images/icon-close.png';
import iconFile from '../assets/images/icon-file.png';
import { fileUpload, listPartners, isJson, sessionErrorCheck } from '../../src/redux/actions/auth.action.creators';
import * as constants from '../redux/constants';
import AlertFailure from '../utils/alert-failure.component';
import { Tooltip } from 'primereact/tooltip';
import iconInfo from '../assets/images/icon-info-round.png';
import CustomDropdownWidget from './dropdown/CustomDropdownWidget';
var CryptoJS = require("crypto-js");
var secretKey = require(process.env.REACT_APP_CONFIG_URL);

const FileUploadComponent = ({dispatchFileUploadAction, dispatchListPartnersAction, dispatchSpinnerAction}) => {
    const [partner, setPartner] = useState('');
    const [fileList, setFileList] = useState([]);
    const [partnerList, setPartnerList] = useState([]);
    const [errorConst, setErrorConst] = useState({"partner": "", "file": ""});
    const [failMsg, setFailMsg] = useState('');
    const [constrains, setConstrains] = useState({ "fieldPlaceholder": "Select partner", "isMandatory": false, "fieldLabel": "Partner", "inputErrorMessage": "" })
    const [responseData, setResponseData] = useState({});
    const [dropdownRefresher, setdropdownRefresher] = useState(true)

    const userInfos = sessionErrorCheck() !== null ? sessionErrorCheck() : window.location = '/auth';
    const userInfo = userInfos;

    useEffect(() => {
        dispatchListPartnersAction(({data, message}) => {
            if(data){
                if(userInfo.role == 'Partner'){
                    setPartner(userInfo.partnerId);
                    setPartnerList([{ "value": userInfo.partnerId, "label": `${data.filter(p => (p.partnerid == userInfo.partnerId))[0].partnercode} (${data.filter(p => (p.partnerid == userInfo.partnerId))[0].partnername})` }]);
                    //setPartnerList([{ "value": userInfo.partnerId, "label": data.filter(p => (p.partnerid == userInfo.partnerId))[0].partnercode }]);
                }
                else{
                    let arr = [{ "value": "", "label": "Any" }];
                    data.forEach(p => {
                        arr.push({
                            "value": p.partnerid,
                            "label": `${p.partnercode} (${p.partnername})`
                           
                        })
                    })
                    setPartnerList(arr);
                } 
            }
        },
        (message) => {
            console.log(message);
        }
        );
    }, [dispatchListPartnersAction]);

    function isValid(file){
        let tempError = _.cloneDeep(errorConst)
        tempError.partner = !partner ? "Please choose a partner" : "";
        tempError.file = !file ? "Please choose a file to upload" : ((fileList.length && fileList.filter(f => (f.fileStatus == constants.FILE_UPLOAD_PROGRESS)).length) ? "Please wait until file upload is completed" : ((fileList.length && fileList.filter(f => f.fileName === file.name).length) ? "File "+file.name+" already uploaded" : ""));
        setErrorConst(tempError);
        setConstrains(!partner ? { "fieldPlaceholder": "Select partner", "isMandatory": true, "fieldLabel": "Partner", "inputErrorMessage": "Please choose a partner" } : { "fieldPlaceholder": "Select partner", "isMandatory": true, "fieldLabel": "Partner", "inputErrorMessage": "" })
        return(!tempError.partner && !tempError.file)
    }

    function uploadFile(file){
        if(isValid(file)){
            setErrorConst({"partner": "", "file": ""});
            let tempList = [];
            if(fileList.length){
                tempList =  _.cloneDeep(fileList);
                tempList[fileList.length] = {
                    'fileName': file.name,
                    'fileSize': file.size/1024,
                    'fileStatus': constants.FILE_UPLOAD_PROGRESS,
                    'fileProgress': 0
                }
            }else{
                tempList[0] = {
                    'fileName': file.name,
                    'fileSize': file.size/1024,
                    'fileStatus': constants.FILE_UPLOAD_PROGRESS,
                    'fileProgress': 0
                }
            }
            setFileList([...tempList]);
            let request = new FormData();
            request.append('file', file);
            request.append('partnerId', partner);
            dispatchSpinnerAction();
            dispatchFileUploadAction(request, ({data, message, progress}) => {
                dispatchSpinnerAction();
                if(data){
                    tempList.filter(p => (p.fileName === request.get('file').name))[0].fileStatus = constants.FILE_UPLOAD_SUCCESS;
                    setFileList([...tempList]);
                }else{
                    tempList.filter(p => (p.fileName === request.get('file').name))[0].fileStatus = constants.FILE_UPLOAD_FAILED;
                    setFileList([...tempList]);
                    setFailMsg("File upload failed");
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                }
            }, (message) => {
                dispatchSpinnerAction();
                tempList.filter(p => (p.fileName === request.get('file').name))[0].fileStatus = constants.FILE_UPLOAD_FAILED;
                setFileList([...tempList]);
                setFailMsg(message);
                setTimeout(() => {
                    setFailMsg('')
                }, 3000);
            }, (progress) => {
                 tempList.filter(p => (p.fileName === request.get('file').name))[0].fileProgress = progress;
                 setFileList([...tempList]);
            });
        } 
    }

    function deleteFromList(event, progress){
        event.preventDefault();
        let temp = _.cloneDeep(fileList)
        let index = temp.findIndex(val => (val.fileName === progress.fileName))
        temp.splice(index, 1);
        setFileList([...temp])
    }

    function onDropFn(e){
        e.preventDefault();
        uploadFile(e.dataTransfer.files[0]);
    }

    useEffect(() => {
        if(JSON.stringify(responseData) !== '{}'){
           setPartner(responseData.value)
        }
    }, [responseData])

    return(
        <React.Fragment>
        <main>
        <div class="container">
            <ul class="breadcrumb">
            <li class="breadcrumb__item">
                <a class="breadcrumb__link" href="#">Home</a>
            </li>
            <li class="breadcrumb__item">
                <span class="breadcrumb__link">Upload File</span>
            </li>
            </ul>
            <div class="title">
            <h1>Upload File</h1>
            </div>
            {failMsg ? 
                    <AlertFailure message={failMsg}/> : ''}
        </div>
            <div class="container">
            <div class="row">
                <div class="col-lg-6">
                <div class="form-group">
                    <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={partnerList} constrains={constrains} setResponseData={setResponseData}></CustomDropdownWidget>
                </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                <div class="file-upload" onDrop={(e) => onDropFn(e)} onDragOver={(e) => e.preventDefault()}>
                    <div class="file-upload__icon">
                    <img src={iconUpload} alt="upload" /> </div>
                    <div class="file-upload__text">Drag & Drop to Upload File</div>
                    <span class="devider">or select a file</span>
                    <input type="file" id="file" class="file-upload__btn" onChange={(e) => uploadFile(e.target.files[0])} />
                    <label class="button" for="file"> Choose file</label>
                </div>
                {errorConst.file ? 
                    (<div class="error-message">{errorConst.file}</div>) : ''}
                </div>
                <div class="col-lg-6">
                <div class="file-progress">
                    <ul>
                    {fileList.map(progress => (
                        <li>
                        <div class="file-progress__icon">
                        <img src={iconFile} alt="file" />
                        </div>
                        <div class="file-progress__status">
                        <div class="file-progress__info">
                            <span class="file-name">{progress.fileName}</span>
                            <span class="file-size">{progress.fileSize > 1000 ? (progress.fileSize/1000).toFixed(2)+" MB" : (progress.fileSize).toFixed(2)+" KB"}</span>
                            <a href="#" class="close">
                            <img src={iconClose} alt="close" onClick={(e) => deleteFromList(e, progress)}/>
                            </a>
                        </div>
                        {progress.fileStatus == 'FILE_UPLOAD_PROGRESS' ? 
                        (<div class="progress">
                            <div class="progress-bar" style={{width: (progress.fileProgress)+"%"}}></div>
                        </div>) : ( progress.fileStatus == 'FILE_UPLOAD_SUCCESS' ? 
                        (<div class="mode text-success">
                            Completed
                        </div>) :
                        (<div id="danger" class="mode text-danger">
                            Failed to upload
                        </div>))}
                        </div>
                    </li>
                    ))}
                    </ul>
                </div>
                </div>
            </div>
            </div>
        </main>
    </React.Fragment>
    )

}

const mapDispatchToProps = dispatch => ({
    dispatchFileUploadAction: (data, uploadProgress, onSuccess, onError) =>
        dispatch(fileUpload(data, uploadProgress, onSuccess, onError)),
    dispatchListPartnersAction: (onSuccess, onError) =>
        dispatch(listPartners(onSuccess, onError)),
    dispatchSpinnerAction: () => 
    dispatch({ type: constants.TOGGLE_LOADER })
});

export default connect(null, mapDispatchToProps)(FileUploadComponent);

