import * as XLSX from 'xlsx';

export const DownloadListAsExcel = (modifiedList, filename) => {
    const curretDate = new Date();
    const options = {year: 'numeric',month: '2-digit',day: '2-digit',hour: '2-digit',minute: '2-digit',second: '2-digit',hour12: false};//24hrs format
    const formattedDate = curretDate.toLocaleString('en-CA', options).replace(/:/g, '-').replace(', ', '-');
    let outputFileName = filename+formattedDate;
       
    //Modify data
    const finalData = modifiedList.map(item => {
        const modifiedItem = {};
        for (const key in item) {
            const modifiedKey = key.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()); // Remove underscores and capitalize first letter of each word
            modifiedItem[modifiedKey] = item[key];
        }
        return modifiedItem;
    });

    // Convert modified JSON data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(finalData);

    // Create a new workbook and add the worksheet to it
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Convert workbook to binary XLSX data
    const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });

    // Create a Blob from the binary XLSX data
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    // Create a download link for the Blob
    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = outputFileName + ".xlsx"; // Change file extension to .xlsx

    // Trigger file download
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // Revoke the Object URL to free up resources
    window.URL.revokeObjectURL(url);
    
}