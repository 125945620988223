import React from 'react';

const AccessDeniedPage = () => (
    
          <div class="container text-center text-danger">
              <strong>You dont have permission to access this page</strong>
        </div>
);

export default AccessDeniedPage;
