import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, Slide } from 'react-toastify';

import AuthPage from './pages/auth.page.component';
import StatisticsPage from './pages/statistics.page.component'
import Spinner from './components/spinner/spinner.component';
import { logoutUser } from './redux/actions/auth.action.creators';
import Header from './components/header.component';
import DashboardPage from './pages/dashboard.page.component';
import ChangePwdPage from './pages/change-password.page.component';
import Footer from './components/footer.component';
import AccessDeniedPage from './pages/access-denied.page.component';
import ManageUsrPage from './pages/user.page.component';
import ManagePartnerPage from './pages/manage.partner.page';
import SetPwdPage from './pages/set-password.page.component';
import GroupPage from './pages/group.page.component';
import InitPwdPage from './pages/init_password.component.page';
import PartnerOnboardingPage from './pages/partner.onboarding.page'
import ChannelPage from './pages/channel.page.component';
import FileUploadPage from './pages/file-upload.page.component';
import FileMonitoringPage from './pages/file-monitoring.page.component';
import TemplatePage from './pages/template.page';
import PGPConfigurationPage from './pages/pgp-configuration.page.component';
import AddScheduler from './components/add-scheduler.component';
import { ReportPage } from './pages/report.landing.page'
import PartnerOverridePage from './pages/partner-override-screen.page.component';
import {FilenameConfigurationPage} from './pages/filename-configuration.page.component'
// import OneTimeParamsPage from './pages/one-time-params.page.component';
import OneTimeParamsPage from './pages/one-time-params.page.component';
import SequenceNumberExcoPage from './pages/sequence-number-exco.page.component';
import FileMappingHomeComponentPage from './pages/file.mapping.home.component.js'
import UserLockAndUnlockComponent from './components/user.lockandunlock.js'
import FileTransferStatusPage from './pages/file-transfer-status.page.component';
import FileMissingAlertPage from './pages/file-missing-alert.page.component';
import SSHKeygenerationPage from './pages/ssh-keygeneration.page.component';
import AddSchedulerRunStatus from './components/add-scheduler-run-status.component';
import FileEncryptDecryptPage from './pages/file-encryptdecrypt.page.component';
import FilehealthDashboardPage from './pages/filehealth-dashboard.page.component';
import Help from './components/help-component';

const App = ({ user, dispatchLogoutAction }) => {

  const PrivateRoute = ({ component, screenName, path, ...options }) => {
    const screens = user.screens;
    const index = screens.indexOf(screenName);
    const finalComponent = (index > -1) ? component : AccessDeniedPage;
    return <Route {...options} component={finalComponent} />;
  };

  const [helpPopup, writeHelpPopup]= useState(false);

  return (
    <React.Fragment>

      <ToastContainer position="top-right" autoClose={2000} hideProgressBar transition={Slide} />

      <Spinner />
      <Help helpPopup={helpPopup} writeHelpPopup={writeHelpPopup}/>

      <Header isLoggedIn={user.isLoggedIn} onLogout={dispatchLogoutAction} />

      {!user.isLoggedIn ?
        (<Switch>
          <Route exact path="/auth" component={AuthPage} />
          <Route exact path="/setpwd" component={SetPwdPage} />
          <Route exact path="/initPwsd" component={InitPwdPage} />
          <Route exact path="/ssoOnloadPage**" component={AuthPage} />
          <Redirect to="/auth" />
        </Switch>) :
        (<Switch>
          <PrivateRoute exact path="/dashboard" component={DashboardPage} screenName="DashboardPage"/>
          <PrivateRoute exact path="/changepwd" component={ChangePwdPage} screenName="ChangePwdPage"/>
          <PrivateRoute exact path="/user" component={ManageUsrPage} screenName="ManageUsrPage"/>
          <PrivateRoute exact path="/group" component={GroupPage} screenName="GroupPage"/>
          <PrivateRoute exact path="/partner" component={ManagePartnerPage} screenName="ManagePartnerPage"/>
          <PrivateRoute exact path="/partnerOnboarding/:id" component={PartnerOnboardingPage} screenName="PartnerOnboardingPage"/>
          <PrivateRoute exact path="/channels" component={ChannelPage} screenName="ChannelPage"/>
          <PrivateRoute exact path="/fileUpload" component={FileUploadPage} screenName="FileUploadPage"/>
          <PrivateRoute exact path="/fileMonitoring" component={FileMonitoringPage} screenName="FileMonitoringPage"/>
          <PrivateRoute exact path="/template/:id" component={TemplatePage} screenName="TemplatePage"/>
          <PrivateRoute exact path="/pgp" component={PGPConfigurationPage} screenName="PGPConfigurationPage"/>
          <PrivateRoute exact path="/sshKeygeneration" component={SSHKeygenerationPage} screenName="SSHKeygenerationPage"/>
          <PrivateRoute exact path="/statistics" component={StatisticsPage} screenName="StatisticsPage"/>
          <PrivateRoute exact path="/addScheduler" component={AddScheduler} screenName="AddScheduler" />
          <PrivateRoute exact path="/addSchedulerRunStatus" component={AddSchedulerRunStatus} screenName="SchedulerRunLogPage" />
          <PrivateRoute exact path="/report" component={ReportPage} screenName="ReportPage"/>
          <PrivateRoute exact path="/partnerOverride" component={PartnerOverridePage} screenName="PartnerOverridePage" />
          <PrivateRoute exact path="/filenameConfiguration" component={FilenameConfigurationPage} screenName="FilenameConfigurationPage" />
          <PrivateRoute exact path="/oneTimeSystemParams" component={OneTimeParamsPage} screenName="OneTimeParamsPage" />
          <PrivateRoute exact path="/FileMapping" component={FileMappingHomeComponentPage} screenName="FileMappingHomeComponentPage" />
          <PrivateRoute exact path="/sequenceNumber" component={SequenceNumberExcoPage} screenName="SequenceNumberExcoPage" />
          <PrivateRoute exact path="/fileTransferStatus" component={FileTransferStatusPage} screenName="FileTransferStatusPage" />
          <PrivateRoute exact path="/UserLockAndUnlock" component={UserLockAndUnlockComponent} screenName="UserLockAndUnlock"/>
          <PrivateRoute exact path="/fileMissingAlert" component={FileMissingAlertPage} screenName="FileMissingAlertPage" />
          <PrivateRoute exact path="/fileEncryptDecrypt" component={FileEncryptDecryptPage} screenName="FileEncryptDecryptPage" />
          <PrivateRoute exact path="/filehealthDashboard" component={FilehealthDashboardPage} screenName="FilehealthDashboardPage"/>

          <Redirect to="/dashboard" />
        </Switch>)
      }

      <Footer isLoggedIn={user.isLoggedIn} helpPopup={helpPopup} writeHelpPopup={writeHelpPopup} />

    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => ({
  dispatchLogoutAction: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
