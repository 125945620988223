import React, { useEffect, useState, useRef } from 'react';
import { findUserByUsernameAndEmail } from '../../redux/actions/auth.action.creators'
import { connect } from 'react-redux';
import {default as ReactSelect, components} from "react-select";
import {sanitizedInput} from '../../utils/stringUtils'
const PartnerUserDetailsComponent = (props, { dispatchUserEmailAndUsernameDuplicateChecker }) => {


  const [inputErrorWarning, setInputErrorWarning] = useState('')
  const [eyeSlash, setEyeSlash] = useState({ "pass": true, "confirmPass": true })
  const [isUsernameExist, setIsUsernameExist] = useState(false)
  const [isUserEmailExist, setIsUserEmailExist] = useState(false)

  const notificationOptions = [
    { value: 'UPLOADED', label: 'Uploaded' },
    { value: 'PROCESSED', label: 'Processed' },
    { value: 'SUCCESS', label: 'Success' },
    { value: 'FAILED', label: 'Failed'},
    { value: 'MISSING FILE', label: 'Missing File'}
  ];


  const setValuesToVariable = (category, keyword) => {

    props.setInputDataForUserDetails({
      "ud_Username": category === "ud_Username" ? keyword.replace(" ", "_").toLowerCase() : props.inputDataForUserDetails.ud_Username,
      "ud_Email": category === "ud_Email" ? keyword : props.inputDataForUserDetails.ud_Email,
      "ud_GivenName": category === "ud_GivenName" ? keyword : props.inputDataForUserDetails.ud_GivenName,
      "ud_SurName": category === "ud_SurName" ? keyword : props.inputDataForUserDetails.ud_SurName,
      "ud_Password": category === "ud_Password" ? keyword : props.inputDataForUserDetails.ud_Password,
      "ud_ConfirmPassword": category === "ud_ConfirmPassword" ? keyword : props.inputDataForUserDetails.ud_ConfirmPassword,
      "isAccessPortal": category === "isAccessPortal" ? keyword : props.inputDataForUserDetails.isAccessPortal,
      "isSendOrReceiveEmails": category === "isSendOrReceiveEmails" ? keyword : props.inputDataForUserDetails.isSendOrReceiveEmails,
      "acceptEmails": category === "acceptEmails" ? keyword : props.inputDataForUserDetails.acceptEmails,
      "sendEmails": category === "sendEmails" ? keyword : props.inputDataForUserDetails.sendEmails,
      "notifications": category === "notifications" ? keyword : props.inputDataForUserDetails.notifications
    })
    
  }
  // useEffect(() => {
    
  // }, []);

  const customPasswordFeildValidation = () => {
    if (props.inputDataForUserDetails.isAccessPortal) {
      if (!props.updationStatus &&
        valueIsNotNull(props.inputDataForUserDetails.ud_Password) &&
        valueIsNotNull(props.inputDataForUserDetails.ud_ConfirmPassword) &&
        props.inputDataForUserDetails.ud_Password === props.inputDataForUserDetails.ud_ConfirmPassword &&
        new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.inputDataForUserDetails.ud_Password)) {
        return true
      } else if (props.updationStatus && !props.isAccessPortalValueFromDB && props.inputDataForUserDetails.isAccessPortal && !props.passwordUpdateFlag &&
        valueIsNotNull(props.inputDataForUserDetails.ud_Password) &&
        valueIsNotNull(props.inputDataForUserDetails.ud_ConfirmPassword) &&
        props.inputDataForUserDetails.ud_Password === props.inputDataForUserDetails.ud_ConfirmPassword &&
        new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.inputDataForUserDetails.ud_Password)) {
        return true
      } else if (props.updationStatus && props.isAccessPortalValueFromDB && props.inputDataForUserDetails.isAccessPortal && !props.passwordUpdateFlag) {
        return true;
      } else if (props.updationStatus && props.inputDataForUserDetails.isAccessPortal && props.passwordUpdateFlag &&
        valueIsNotNull(props.inputDataForUserDetails.ud_Password) &&
        valueIsNotNull(props.inputDataForUserDetails.ud_ConfirmPassword) &&
        props.inputDataForUserDetails.ud_Password === props.inputDataForUserDetails.ud_ConfirmPassword &&
        new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.inputDataForUserDetails.ud_Password)) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const valueIsNull = (v1) => { return v1 === null || v1 === "" ? true : false; }
  const valueIsNotNull = (v1) => { return v1 !== null && v1 !== "" ? true : false; }

  function passwordValidationModule() {
    var result = null
    if (props.inputDataForUserDetails.isAccessPortal) {
      if (!props.updationStatus && valueIsNull(props.inputDataForUserDetails.ud_Password)) {
        result = "Enter Password."
      } else if (!props.updationStatus && valueIsNotNull(props.inputDataForUserDetails.ud_Password)) {
        if (new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.inputDataForUserDetails.ud_Password)) {
          result = null
        } else {
          result = "Enter Valid Password"
        }
      } else if (props.updationStatus && props.inputDataForUserDetails.isAccessPortal && props.passwordUpdateFlag && valueIsNotNull(props.inputDataForUserDetails.ud_Password)) {
        if (new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.inputDataForUserDetails.ud_Password)) {
          result = null
        } else {
          result = "Enter Valid Password"
        }
      } else if (props.updationStatus && props.inputDataForUserDetails.isAccessPortal && props.passwordUpdateFlag && valueIsNull(props.inputDataForUserDetails.ud_Password)) {
        result = "Enter Password.."
      } else if (props.updationStatus && props.inputDataForUserDetails.isAccessPortal && !props.isAccessPortalValueFromDB && valueIsNull(props.inputDataForUserDetails.ud_Password)) {
        result = "Enter Password..."
      } else if (props.updationStatus && props.inputDataForUserDetails.isAccessPortal && !props.isAccessPortalValueFromDB && valueIsNotNull(props.inputDataForUserDetails.ud_Password)) {
        if (new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.inputDataForUserDetails.ud_Password)) {
          result = null
        } else {
          result = "Enter Valid Password"
        }
      }
    } else { result = null }
    return result
  }

const getNotificationStatus=(StringVal)=>{
  if(StringVal.length>0){
    if (!(StringVal[0].value)){
      console.log("StringVal",typeof(StringVal[0]))
      const string_split= StringVal.toString().split(',')
      let not_list=[]
      for(let i=0;i<string_split.length;i++){
        let capitalized = string_split[i].toUpperCase()[0] + string_split[i].slice(1).toLowerCase(); 
        let selection={value: string_split[i], label: capitalized }
        console.log("selection",selection)
        console.log("notificationOptions",notificationOptions)
        not_list.push(selection)
      }
      props.inputDataForUserDetails.notifications=not_list
    }
      
} 
}

  const inputFieldValidation = () => {
    setInputErrorWarning({
      "ud_Username": valueIsNull(props.inputDataForUserDetails.ud_Username) ? "Enter Username" : !sanitizedInput(props.inputDataForUserDetails.ud_Username,"ud_Username") ? "Invalid characters found!":"",
      "ud_Email": valueIsNull(props.inputDataForUserDetails.ud_Email) ? "Enter Email Address" : emailValidation() ? null : "Enter Valid Email ",
      "ud_GivenName": valueIsNull(props.inputDataForUserDetails.ud_GivenName) ? "Enter Name" : !sanitizedInput(props.inputDataForUserDetails.ud_GivenName,"ud_GivenName") ? "Invalid characters found!":"",
      "ud_SurName": valueIsNull(props.inputDataForUserDetails.ud_SurName) ? "Enter Surname" : !sanitizedInput(props.inputDataForUserDetails.ud_SurName,"ud_SurName") ? "Invalid characters found!":"",
      "ud_Password": passwordValidationModule(),
      "ud_ConfirmPassword": valueIsNull(props.inputDataForUserDetails.ud_Password) ? null : valueIsNull(props.inputDataForUserDetails.ud_ConfirmPassword) ? "Re-enter Password" : props.inputDataForUserDetails.ud_Password === props.inputDataForUserDetails.ud_ConfirmPassword ? null : "Please ensure passwords match",
      "acceptEmails": props.inputDataForUserDetails.isSendOrReceiveEmails ? valueIsNull(props.inputDataForUserDetails.acceptEmails) ? "Enter Accept Emails" : multiEmailvalidation(props.inputDataForUserDetails.acceptEmails) ? null : "Enter Valid Email" : null,
      "sendEmails": props.inputDataForUserDetails.isSendOrReceiveEmails ? valueIsNull(props.inputDataForUserDetails.sendEmails) ? "Enter Send Emails" : multiEmailvalidation(props.inputDataForUserDetails.sendEmails) ? null : "Enter Valid Email" : null,
    })
    if (valueIsNotNull(props.inputDataForUserDetails.ud_Username) && valueIsNotNull(props.inputDataForUserDetails.ud_Email) && valueIsNotNull(props.inputDataForUserDetails.ud_GivenName) && valueIsNotNull(props.inputDataForUserDetails.ud_SurName) 
      && sanitizedInput(props.inputDataForUserDetails.ud_Username,"ud_Username")
      && sanitizedInput(props.inputDataForUserDetails.ud_GivenName,"ud_Username")
      && sanitizedInput(props.inputDataForUserDetails.ud_SurName,"ud_Username")
      && emailValidation()) {
      if (!props.inputDataForUserDetails.isAccessPortal && !props.inputDataForUserDetails.isSendOrReceiveEmails) { return true }
      else if (customPasswordFeildValidation()) {
        if (!props.inputDataForUserDetails.isSendOrReceiveEmails) {
          return true
        } else if (props.inputDataForUserDetails.isSendOrReceiveEmails &&((valueIsNotNull(props.inputDataForUserDetails.acceptEmails) && valueIsNull(props.inputDataForUserDetails.sendEmails)) || (valueIsNotNull(props.inputDataForUserDetails.sendEmails) && multiEmailvalidation(props.inputDataForUserDetails.sendEmails))) ) {
          return true
        }else if(props.inputDataForUserDetails.isSendOrReceiveEmails && ((valueIsNotNull(props.inputDataForUserDetails.sendEmails) && valueIsNull(props.inputDataForUserDetails.acceptEmails)) ||(valueIsNotNull(props.inputDataForUserDetails.acceptEmails) && multiEmailvalidation(props.inputDataForUserDetails.acceptEmails)))){
          return true
        } 
        else {
          return false
        }
      }
      else { return false }
    }
    return false
  }

  const emailValidation = () => {
    if (new RegExp(/^(\d{9}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4}))$/).test(props.inputDataForUserDetails.ud_Email)) {
      return true;
    } return false
  }

  const multiEmailvalidation = (emails) => {
    if (emails) {
      emails.trim()
      var emailArray = emails.split(';'); var resultArray = [];
      emailArray.forEach(email => {
        if (!new RegExp(/^(\d{9}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4}))$/).test(email)) {
          resultArray.push(email)
        }
      });
      if (resultArray.length >= 1) {
        return false
      } else {
        return true
      }
    }

  }



  const onSubmit = (event) => {
    event.preventDefault();
    console.log(inputErrorWarning);
    console.log(props.inputDataForUserDetails);
    if (inputFieldValidation()) {

      if (!props.updationStatus && props.inputDataForUserDetails.ud_Username !== null && props.inputDataForUserDetails.ud_Username !== "") {
        console.log("create mode - userdetails ");
        console.log(isUsernameExist);
        props.dispatchUserEmailAndUsernameDuplicateChecker(props.inputDataForUserDetails.ud_Username, "", ({ data, message, status }) => {
          if (data) {
            console.log("data found for username check - create mode");
            setIsUsernameExist(true)
          } else {
            console.log("data not found for username check - create mode");
            setIsUsernameExist(false)
            if (props.inputDataForUserDetails.ud_Email !== null && props.inputDataForUserDetails.ud_Email !== "") {
              props.dispatchUserEmailAndUsernameDuplicateChecker("", props.inputDataForUserDetails.ud_Email, ({ data, message, status }) => {
                if (data) {
                  setIsUserEmailExist(true)
                } else {
                  setIsUserEmailExist(false)
                  props.setCursor('Partner_Role')
                }
              })
            }
          }
        })
      } else if (props.updationStatus) {
        props.setCursor('Partner_Role')
      }
    }
  }
  const notificationSelect = (e) => {
    console.log('inputDataForUserDetails',props.inputDataForUserDetails)
    console.log('notificationSelect',e)
    setValuesToVariable("notifications",e)
  }

  const resetInputVariableValues = () => {
    props.setInputDataForUserDetails({
      "ud_Username": "",
      "ud_Email": "",
      "ud_GivenName": "",
      "ud_SurName": "",
      "ud_Password": "",
      "ud_ConfirmPassword": "",
      "notifications":"",
      "isAccessPortal": false,

    })
    setInputErrorWarning({
      "ud_Username": null,
      "ud_Email": null,
      "ud_GivenName": null,
      "ud_SurName": null,
      "ud_Password": null,
      "ud_ConfirmPassword": null
    })
  }


  return (
    <React.Fragment>

      <main>
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb__item">
              <a className="breadcrumb__link" href="">Manage Partner</a>
            </li>
            <li className="breadcrumb__item">
              <a className="breadcrumb__link" href='/partner'>Partner</a>
            </li>
            <li className="breadcrumb__item">
              <span className="breadcrumb__link">Onboarding</span>
            </li>
          </ul>
          <div className="title">
            <h1>Partner Onboarding</h1>
          </div>
          <div className="tab tab--partner">
            <ul className="tab--partner__list">
              <li className="tab--partner__item is-completed">
                <div className="bar"></div>
                <div className="content">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill=""><rect fill="none" height="24" width="24" /><g><path d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,2.73L18,18H6l0-1.61c0-1.18,0.68-2.26,1.76-2.73 C8.93,13.14,10.37,12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1 C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85 C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M12,6c1.66,0,3,1.34,3,3 c0,1.66-1.34,3-3,3s-3-1.34-3-3C9,7.34,10.34,6,12,6z" /></g></svg>
                  </div>
                  <div className="text">Partner Profile</div>
                </div>
              </li>
              <li className="tab--partner__item is-active">
                <div className="bar"></div>
                <div className="content">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill=""><rect fill="none" height="24" width="24" /><path d="M12,3L2,21h20L12,3z M13,8.92L18.6,19H13V8.92z M11,8.92V19H5.4L11,8.92z" /></svg>
                  </div>
                  <div className="text">User Details</div>
                </div>
              </li>
              <li className="tab--partner__item">
                <div className="bar"></div>
                <div className="content">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill=""><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><g><circle cx="10" cy="8" r="4" /><path d="M10.67,13.02C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26 C10.47,18.87,10,17.49,10,16C10,14.93,10.25,13.93,10.67,13.02z" /><path d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z" /></g></g></svg>
                  </div>
                  <div className="text">Partner Role</div>
                </div>
              </li>
              <li className="tab--partner__item">
                <div className="bar"></div>
                <div className="content">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill=""><path d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="19" r="2" /><path d="M10 3h4v12h-4z" /></svg>
                  </div>
                  <div className="text">Protocol</div>
                </div>
              </li>
            </ul>
          </div>
          <h2 className="mb-3">User Details</h2>
          {/* <!--form starts here--> */}
          <form onSubmit={onSubmit} >
            <div className="form mb-5">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label for="ud_Username" className="input-label">Username<span style={{ color: 'red' }}>*</span></label>
                    <div className="input-wrap txt-wrap">
                      <input value={props.inputDataForUserDetails.ud_Username} disabled={props.updationStatus ? true : false} className={`txt ${inputErrorWarning.ud_Username ? 'is-invalid' : ''}`} type="text" autoComplete="off" onChange={((e) => { setValuesToVariable("ud_Username", e.target.value) })} placeholder="Enter Username" id="ud_Username" />
                      {inputErrorWarning.ud_Username && !props.updationStatus ?
                        (<div className="error-message">{inputErrorWarning.ud_Username}</div>)
                        : ('')}
                      {isUsernameExist && !props.updationStatus && !inputErrorWarning.ud_Email ?
                        (<div className="error-message">Username already exist</div>)
                        : ('')}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label for="ud_Email" className="input-label">Email<span style={{ color: 'red' }}>*</span></label>
                    <div className="input-wrap txt-wrap">
                      <input value={props.inputDataForUserDetails.ud_Email} disabled={props.updationStatus ? true : false} className={`txt ${inputErrorWarning.ud_Email ? 'is-invalid' : ''}`} type="text" autoComplete="off" onChange={((e) => { setValuesToVariable("ud_Email", e.target.value) })} placeholder="Enter Email" id="ud_Email" />
                      {isUserEmailExist && !props.updationStatus && !inputErrorWarning.ud_Email ?
                        (<div className="error-message">Email already exist</div>)
                        : ('')}
                      {inputErrorWarning.ud_Email && !props.updationStatus ?
                        (<div className="error-message">{inputErrorWarning.ud_Email}</div>)
                        : ('')}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label for="ud_GivenName" className="input-label">Given Name<span style={{ color: 'red' }}>*</span></label>
                    <div className="input-wrap txt-wrap">
                      <input value={props.inputDataForUserDetails.ud_GivenName} className={`txt ${inputErrorWarning.ud_GivenName ? 'is-invalid' : ''}`} type="text" autoComplete="off" onChange={((e) => { setValuesToVariable("ud_GivenName", e.target.value) })} placeholder="Enter Name" id="ud_GivenName" />
                      {inputErrorWarning.ud_GivenName ?
                        (<div className="error-message">{inputErrorWarning.ud_GivenName}</div>)
                        : ('')}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label for="ud_SurName" className="input-label">Surname<span style={{ color: 'red' }}>*</span></label>
                    <div className="input-wrap txt-wrap">
                      <input value={props.inputDataForUserDetails.ud_SurName} className={`txt ${inputErrorWarning.ud_SurName ? 'is-invalid' : ''}`} type="text" autoComplete="off" onChange={((e) => { setValuesToVariable("ud_SurName", e.target.value) })} placeholder="Enter Surname" id="ud_SurName" />
                      {inputErrorWarning.ud_SurName ?
                        (<div className="error-message">{inputErrorWarning.ud_SurName}</div>)
                        : ('')}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 input-wrap txt-wrap">
                  <div className="form-group ">
                    <label htmlFor="f5" className="d-block input-label">Select status notifications</label>
                      {getNotificationStatus(props.inputDataForUserDetails.notifications)}
                      <ReactSelect
                        options={notificationOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={true}
                        onChange={(e) => notificationSelect(e)}
                        allowSelectAll={true}
                        value={props.inputDataForUserDetails.notifications}
                      />
                    </div>
                  </div> 
              </div>
              
              <br></br><br></br>
              <div class="questionnaire mb-6 ">
                <div class="question">
                  <div class="question-text">Enable portal access<span style={{ color: 'red' }}>*</span>? </div>
                  <div class="chcek-wrap">
                    <div class="form-check">
                      <input type="checkbox" id="yes" checked={props.inputDataForUserDetails.isAccessPortal ? true : false} onChange={(() => { setValuesToVariable("isAccessPortal", true) })} />
                      <label for="yes">Yes</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" id="no" checked={props.inputDataForUserDetails.isAccessPortal ? false : true} onChange={(() => { setValuesToVariable("isAccessPortal", false) })} />
                      <label for="no">No</label>
                    </div>
                  </div>
                </div>
                <div class="questionnaire mb-6 " style={{ display: props.inputDataForUserDetails.isAccessPortal && props.updationStatus && props.isAccessPortalValueFromDB ? "block" : "none" }}>
                  <div class="question">
                    <div class="question-text">Update Portal Password?</div>
                    <div class="chcek-wrap">
                      <div class="form-check">
                        <input type="checkbox" id="updatepass" checked={props.passwordUpdateFlag ? true : false} onChange={(() => { props.setpasswordUpdateFlag(true) })} />
                        <label for="updatepass">Yes</label>
                      </div>
                      <div class="form-check">
                        <input type="checkbox" id="updatepassno" checked={props.passwordUpdateFlag ? false : true} onChange={(() => { props.setpasswordUpdateFlag(false) })} />
                        <label for="updatepassno">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                {props.inputDataForUserDetails.isAccessPortal && (!props.updationStatus || (props.updationStatus && props.passwordUpdateFlag) || (props.updationStatus && !props.isAccessPortalValueFromDB)) ?
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="ud_Password" className="input-label"> Password<span style={{ color: 'red' }}>*</span></label>
                        <div className="input-wrap  password-wrap password-toggle">
                          <input value={props.inputDataForUserDetails.ud_Password} className={`txt ${inputErrorWarning.ud_Password ? 'is-invalid' : ''}`} type={eyeSlash.pass ? "password" : "text"} autoComplete="off" onChange={((e) => { setValuesToVariable("ud_Password", e.target.value) })} placeholder="Enter Password" id="ud_Password" />
                          <div onClick={(() => { setEyeSlash({ "pass": eyeSlash.pass ? false : true, "confirmPass": eyeSlash.confirmPass }); })}>
                            <i className={`toggle-eye fa fa-fw ${eyeSlash.pass ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                          </div>
                          {inputErrorWarning.ud_Password ? (<div className="error-message">{inputErrorWarning.ud_Password}</div>) : ('')}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="f1" className="input-label"> Re-enter Password<span style={{ color: 'red' }}>*</span></label>
                        <div className="input-wrap password-wrap  password-toggle ">
                          <input value={props.inputDataForUserDetails.ud_ConfirmPassword} onPasteCapture={((e) => { e.preventDefault(); })} className={`txt ${inputErrorWarning.ud_ConfirmPassword ? 'is-invalid' : ''}`} type={eyeSlash.confirmPass ? "password" : "text"} autoComplete="off" onChange={((e) => { setValuesToVariable("ud_ConfirmPassword", e.target.value) })} placeholder="Re-enter Password" id="f1" />
                          <div onClick={(() => { setEyeSlash({ "confirmPass": eyeSlash.confirmPass ? false : true, "pass": eyeSlash.pass }); })}>
                            <i className={`toggle-eye fa fa-fw ${eyeSlash.confirmPass ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                          </div>
                          {inputErrorWarning.ud_ConfirmPassword ?
                            (<div className="error-message">{inputErrorWarning.ud_ConfirmPassword}</div>)
                            : ('')}
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  ""}
              </div>
              <div class="questionnaire mb-6 ">
                <div class="question">
                  <div class="question-text">Send / Receive Files Over Email?</div>
                  <div class="chcek-wrap">
                    <div class="form-check">
                      <input type="checkbox" id="emailYes" checked={props.inputDataForUserDetails.isSendOrReceiveEmails ? true : false} onChange={(() => { setValuesToVariable("isSendOrReceiveEmails", props.inputDataForUserDetails.isSendOrReceiveEmails ? false : true) })} />
                      <label for="emailYes">Yes</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" id="emailNo" checked={props.inputDataForUserDetails.isSendOrReceiveEmails ? false : true} onChange={(() => { setValuesToVariable("isSendOrReceiveEmails", props.inputDataForUserDetails.isSendOrReceiveEmails ? false : true) })} />
                      <label for="emailNo">No</label>
                    </div>
                  </div>
                </div>
                {props.inputDataForUserDetails.isSendOrReceiveEmails ?
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="f1" className="input-label">Accept Emails <span style={{ color: 'red' }}>*</span></label>
                        <div className="input-wrap txt-wrap">
                          <input value={props.inputDataForUserDetails.acceptEmails} className={`txt ${inputErrorWarning.acceptEmails ? 'is-invalid' : ''}`} type="text" autoComplete="off" onChange={((e) => { setValuesToVariable("acceptEmails", e.target.value) })} placeholder="Enter Email" id="acceptEmails" />
                          <span style={{ fontSize: "10px", color: 'grey' }}>(Enter Emails With Semicolons Separate)</span>
                          {inputErrorWarning.acceptEmails ?
                            (<div className="error-message">{inputErrorWarning.acceptEmails}</div>)
                            : ('')}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="f1" className="input-label">Send Emails<span style={{ color: 'red' }}>*</span></label>
                        <div className="input-wrap txt-wrap">
                          <input value={props.inputDataForUserDetails.sendEmails} className={`txt ${inputErrorWarning.sendEmails ? 'is-invalid' : ''}`} type="text" autoComplete="off" onChange={((e) => { setValuesToVariable("sendEmails", e.target.value) })} placeholder="Enter Email" id="sendEmails" />
                          <span style={{ fontSize: "10px", color: 'grey' }}>(Enter Emails With Semicolons Separate)</span>
                          {inputErrorWarning.sendEmails ?
                            (<div className="error-message">{inputErrorWarning.sendEmails}</div>)
                            : ('')}
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  ""}
              </div>
            </div>
            <div className="btn-wrap btn-wrap--grp mt-5 justify-content-end">
              <button className="button button--outline" type='reset' onClick={() => resetInputVariableValues()}>Reset</button>
              <button className="button button--outline" onClick={() => props.setCursor('/partnerOnboarding')}>Previous</button>
              <button className="button" type='submit'>Next</button>
            </div>
          </form>
          {/* <!--form ends here--> */}
        </div>
      </main>


    </React.Fragment>


  );

}



const mapDispatchToProps = dispatch => ({
  dispatchUserEmailAndUsernameDuplicateChecker: (username, email, onSuccess, onError) =>
    dispatch(findUserByUsernameAndEmail({ "username": username, "email": email }, onSuccess, onError))
})

export default connect(null, mapDispatchToProps)(PartnerUserDetailsComponent);
