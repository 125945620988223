import React from 'react';
import SequenceNumberExcoComponent from '../components/sequence.number.exco.component';




const SequenceNumberExcoPage = () => {
    return(
        <SequenceNumberExcoComponent/>

    )
}

export default SequenceNumberExcoPage;
