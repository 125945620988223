import React, { useEffect, useState } from 'react';
import { connect, useStore } from 'react-redux';
import { fetchOneTimeParams } from '../../../redux/actions/auth.action.creators'

import close_icon from '../../../assets/images/icon-close.png';


const FileMappingFunctionTrimComponent = (props, { dispatchOneTimeParams }) => {
  const [fieldEntity, setFieldEntity] = useState({ "field_name": props.selectedFunction.item.map_to, "field_location": props.selectedFunction.item.field_type, "field_length": props.selectedFunction.parameters.split("//")[2], "trim_value": props.selectedFunction.parameters.split("//")[0], "trim_side": "L" })
  const [inputErrorWarning, setInputErrorWarning] = useState('')
  const [fieldLength, setFieldLength] = useState(0)

  useEffect(() => {
    // console.log(props.selectedFunction.fileType);
    if (props.selectedFunction.fileType == 'flat') {
      setFieldLength(props.selectedFunction.details.field_length)
    } else if ((props.selectedFunction.fileType == 'csv')) {
      setFieldLength(Number.MAX_VALUE)
    }
  }, []);

  
  const inputFieldValidation = () => {

    setInputErrorWarning({
      "field_length": (fieldEntity.field_length === undefined || fieldEntity.field_length.trim() === "") ? "Enter field length" : !inputFieldLengthValidation(fieldEntity.field_length, 4) ? "Max Length is 9999" : (fieldEntity.field_length) > fieldLength ? "The Trim length is larger than the field length" : null,
      "trim_value": (fieldEntity.trim_value === undefined || fieldEntity.trim_value.trim() === "") ? "Enter Trim Value" : inputFieldLengthValidation(fieldEntity.trim_value, fieldEntity.field_length) ? null : "Trim value length should be less than or equal to field length"
    })
    if (
      fieldEntity.trim_value !== undefined &&
      fieldEntity.field_length !== undefined &&
      fieldEntity.field_length.trim() !== "" &&
      fieldEntity.trim_value.trim() !== ""
      &&
      inputFieldLengthValidation(fieldEntity.field_length, 4) &&
      inputFieldLengthValidation(fieldEntity.trim_value, fieldEntity.field_length)&&
      ((fieldEntity.field_length)<=fieldLength)
    ) {
      return true
    }
    return false
  }

  const inputFieldLengthValidation = (data, requiredLength) => {

    let dataLength = data.length;
    if (dataLength <= requiredLength) {
      return true
    } else return false
  }



  const handlePopUP = (activity) => {
    if (activity == "close") {
      setFieldEntity({ "field_name": props.selectedFunction.item.map_to, "field_location": props.selectedFunction.item.field_type, "field_length": props.selectedFunction.parameters.split("//")[0], "trim_value": props.selectedFunction.parameters.split("//")[2], "trim_side": "L" })
      props.setSelectedFunction({ "item": "", "function": "", "details": "", "fileType": "","parameters": "" })
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFieldEntity((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  };


  const handleSubmit = () => {
    if (inputFieldValidation()) {
      const exists = props.allFields.mapping_fields.some(
        (field, index) =>
          field.map_to === fieldEntity.field_name &&
          field.field_type === fieldEntity.field_location &&
          (field.function_name == "" || field.function_name == "trim")
      )
      if (exists) {
        const updatedFields = props.allFields.mapping_fields.map((field) => {
          if (
            field.map_to === fieldEntity.field_name &&
            field.field_type === fieldEntity.field_location &&
            (field.function_name === "" || field.function_name === "trim")
          ) {
            return {
              ...field,
              function_name: "trim",
              replace: fieldEntity.trim_value + "//" + fieldEntity.trim_side + "//" + fieldEntity.field_length,
            };
          }
          return field;
        });
        props.setAllFields((prevState) => ({
          ...prevState,
          mapping_fields: updatedFields,
        }));
      }
      else {
        const newEntity = {
          field_type: fieldEntity.field_location,
          map_to: fieldEntity.field_name,
          function_params: props.selectedFunction.item.function_params.replace("\"", ""),
          replace: fieldEntity.trim_value + "//" + fieldEntity.trim_side + "//" + fieldEntity.field_length,
          pattern: "",
          function_name: "trim"
        };
        const newMappingFields = [...props.allFields.mapping_fields, newEntity];
        props.setAllFields((prevFields) => ({ ...prevFields, mapping_fields: newMappingFields }));

      }
      setFieldEntity({ field_name: props.selectedFunction.item.map_to, field_location: props.selectedFunction.item.field_type, field_length: props.selectedFunction.parameters.split("//")[0], trim_value: props.selectedFunction.parameters.split("//")[2], trim_side: "L" });
      handlePopUP("close")
    }
  }

  return (
    <React.Fragment>
      <div className={`modal fade modal--addnew ${props.selectedFunction.function == "trim" ? 'show' : ''}`} aria-modal="true" data-toggle="modal" style={{ display: props.selectedFunction.function == "trim" ? 'block' : 'none' }} id="addField" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Trim Function</h5>

              <button type="button" onClick={(() => { handlePopUP("close") })} class="close" data-dismiss="modal" aria-label="Close">
                <img src={close_icon} alt="Close" />
              </button>
            </div>

            <div class="modal-body ">
              <p>This function trims the input values and apply the result to this field</p>
              <div class="form mb-5" >
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label for="pc" class="input-label">Destination field name<span style={{ color: 'red' }}>*</span></label>
                      <input disabled="disabled" className={`txt ${inputErrorWarning.field_name ? 'is-invalid' : ''}`} value={fieldEntity.field_name} name="field_name" type="text" placeholder="Field Name" id="pc" />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label for="cc" class="input-label">Field length<span style={{ color: 'red' }}>*</span></label>
                      <input className={`txt ${inputErrorWarning.field_length ? 'is-invalid' : ''}`} value={fieldEntity.field_length} type="number" name="field_length" min={0} step={1} onChange={handleInputChange} placeholder="Field Length" id="cc" />
                    </div>
                    {inputErrorWarning.field_length ?
                      (<div className="error-message">{inputErrorWarning.field_length}</div>)
                      : ('')}
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label for="cc" class="input-label">Value<span style={{ color: 'red' }}>*</span></label>
                      <input className={`txt ${inputErrorWarning.trim_value ? 'is-invalid' : ''}`} value={fieldEntity.trim_value} type="text" name="trim_value" min={0} step={1} onChange={handleInputChange} placeholder="Enter Value to be Trimmed" id="cc" />
                    </div>
                    {inputErrorWarning.trim_value ?
                      (<div className="error-message">{inputErrorWarning.trim_value}</div>)
                      : ('')}
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label for="f2" class="d-block input-label">Field Location<span style={{ color: 'red' }}>*</span></label>
                      <div class="input-wrap date-wrap">
                        <select id="f1" onChange={handleInputChange} name="trim_side" value={fieldEntity.trim_side}>
                          <option value="L">Left</option>
                          <option value="R">Right</option>
                          <option value="LR">Left and Right</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" onClick={(() => { handlePopUP("close") })} class="button button--outline button--sm" data-dismiss="modal">Cancel</button>
                  <button type="button" onClick={(() => { handleSubmit() })} data-dismiss="modal" class="button button--sm">Save</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {(props.selectedFunction.function == "trim" ? true : false) && <div className="modal-backdrop fade show"></div>}
    </React.Fragment >
  );

}

const mapDispatchToProps = dispatch => ({
  dispatchOneTimeParams: (onSuccess, onError) =>
    dispatch(fetchOneTimeParams({ "keyList": ["preferred_mac", "preferred_cipher", "compression"] }, onSuccess, onError))
})

export default connect(null, mapDispatchToProps)(FileMappingFunctionTrimComponent);

