import React, { useEffect, useRef, useState } from 'react'
import sort_icon from '../../assets/images/icon-sort.png';
import delete_icon from '../../assets/images/icon-delete.png';
import { getTemplateDetailsList,fetchTemplateExportData, deleteTemplate, isAdmin, isPartnerAdmin } from '../../redux/actions/auth.action.creators';
import { connect } from 'react-redux';
import AlertFailure from '../../utils/alert-failure.component';
import AlertSuccess from '../../utils/alert-success.component';
import { TemplateDetailsModel } from '../../Bean/template';


const TemplateListingComponent = ({ dispatchGetTemplateDetailsList, dispatchDeleteTemplate,dispatchFetchTemplateExportData }) => {

    const [templateDetailsList, setTemplateDetailsList] = useState([])
    const [userInfoRefresh, setUserInfoRefresh] = useState(true)
    const [collapseState, setCollapseState] = useState({ "mainBlock": false, "t_details": false, "tp_details": false, "tc_details": false, "tpg_list": false, "tcg_list": false })
    const [popupVisibility, setPopupVisibility] = useState({ success: false, failed: false, message: null })
    const [collapseId, setCollapseId] = useState(0)
    const [onSearchKeyword, setOnSearchKeyword] = useState('')
    useEffect(() => {
        dispatchGetTemplateDetailsList(({ data, onSuccess, onError }) => {
            if (data) {
                setTemplateDetailsList(data)
            } else {
                setTemplateDetailsList([])
                enablePopup(false, "No template found");
            }
        }, (message) => {
        });
    }, [userInfoRefresh])


    const enablePopup = (ctgry, msg) => {
        if (ctgry) {
            setPopupVisibility({ success: true, failed: false, message: msg })
            setTimeout(() => {
                setPopupVisibility({ success: false, failed: false, message: null })
            }, 6000);
        } else {
            setPopupVisibility({ success: false, failed: true, message: msg })
            setTimeout(() => {
                setPopupVisibility({ success: false, failed: false, message: null })
            }, 6000);
        }
    }

    const onDeleteTemplate = (id) => {

        dispatchDeleteTemplate(id, ({ data, message, status }) => {
            if (status === 'SUCCESS') {
                enablePopup(true, "Deletion Successfully!");
                setUserInfoRefresh(userInfoRefresh ? false : true)
            } else if (status === 'FAILURE') {
                enablePopup(false, "Deletion Failed!");
                setUserInfoRefresh(userInfoRefresh ? false : true)
            }
        }, (message) => { console.log(message); enablePopup(false, message); });
    }

    const onExportTemplateData = (id) => {

        dispatchFetchTemplateExportData(id, ({ data, message, status }) => {
            if (status === 'SUCCESS') {
                console.log('data inside exportJsonData function:', data);
                const currentDate = new Date().toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                }).replace(/\//g, '');

                const fileName = `${data.templateName}.${currentDate}.pihub`;
                console.log('fileName inside exportJsonData function:', fileName);
                const jsonData = JSON.stringify(data,null,'\t');
                console.log(jsonData);
                const blob = new Blob([jsonData], { type: 'application/json' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
            } else if (status === 'FAILURE') {
                enablePopup(false, "Export Failed!");
                setUserInfoRefresh(userInfoRefresh ? false : true)
            }
        }, (message) => { console.log(message); enablePopup(false, message); });
    }


    const onEditTemplate = (id) => {
        window.location = '/template/' + id
    }

    const touchOrUntouchOperation = (category, templateId) => {
        setCollapseId(templateId)
        setCollapseState({
            "mainBlock": category === "mainBlock" ? collapseState.mainBlock ? false : true : collapseState.mainBlock,
            "t_details": category === "t_details" ? collapseState.t_details ? false : true : collapseState.t_details,
            "tp_details": category === "tp_details" ? collapseState.tp_details ? false : true : collapseState.tp_details,
            "tc_details": category === "tc_details" ? collapseState.tc_details ? false : true : collapseState.tc_details,
            "tpg_list": category === "tpg_list" ? collapseState.tpg_list ? false : true : collapseState.tpg_list,
            "tcg_list": category === "tcg_list" ? collapseState.tcg_list ? false : true : collapseState.tcg_list
        })
    }


    const listRenderingModule = (templateInfo) => {
        return (
            <div className="accordian__item">

                <div className={`accordian__head ${collapseId === templateInfo.templateId ? 'active' : ''}`}  >
                    <strong onClick={() => touchOrUntouchOperation("", templateInfo.templateId === collapseId ? 0 : templateInfo.templateId)}>{templateInfo.templateName}</strong>
                    {isAdmin() || isPartnerAdmin() ? <div className="dropdown">
                        <a className="" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                            <span className="sr-only">ellipsis</span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                            <a className="dropdown-item" onClick={() => onEditTemplate(templateInfo.templateId)}>
                                <i className="fas fa-edit" aria-hidden="true"></i>
                                <span className="sr-only">Edit </span> Edit</a>
                            <a className="dropdown-item" onClick={() => onDeleteTemplate(templateInfo.templateId)} >
                                <i className="far fa-trash-alt" aria-hidden="true"></i>
                                <span className="sr-only">Trash</span> Delete</a>
                            <a className="dropdown-item" onClick={() => onExportTemplateData(templateInfo.templateId)} >
                                <i className="fas fa-file-export" aria-hidden="true"></i>
                                <span className="sr-only">Export</span> Export</a>
                        </div>
                    </div> : ""}
                </div>

                <div className="accordian__content" style={{ display: collapseId === templateInfo.templateId ? "block" : "none" }} >
                    <div className="tree-strecture">
                        <ul>
                            <li className={`folder ${collapseState.t_details ? 'folder-open' : ''}`} >
                                <b onClick={(e) => touchOrUntouchOperation("t_details", templateInfo.templateId)}>  Template Details</b>
                                <ul style={{ display: collapseState.t_details ? "block" : "none" }}>
                                    <li className="file">
                                        <span className="type"> Name: </span>
                                        <span className="text">{templateInfo.templateName}</span>
                                    </li>
                                    <li className="file">
                                        <span className="type"> Type: </span>
                                        <span className="text">{templateInfo.templateType}</span>
                                    </li>
                                    <li className={`folder ${collapseState.tp_details ? 'folder-open' : ''}`}>
                                        <b onClick={() => touchOrUntouchOperation("tp_details", templateInfo.templateId)}>  Producer Details</b>
                                        <ul style={{ display: collapseState.tp_details ? "block" : "none" }}>

                                            {templateInfo.templateProducer.map((templateProducerinfo) => (

                                                <div>
                                                    <li className="file">
                                                        <span className="type"> Producer File Type : </span>
                                                        <span className="text">
                                                            {
                                                                templateProducerinfo.fileType === "DECRPT" ? "Decryption Required" :
                                                                templateProducerinfo.fileType === "ENCRPT" ? "Encryption Required": "Unknown"
                                                            }</span>
                                                    </li>
                                                    <li className="file">
                                                        <span className="type"> Producer File Path : </span>
                                                        <span className="text">{templateProducerinfo.filePath}</span>
                                                    </li>
                                                    <li className="file">
                                                        <span className="type"> Producer File Pattern : </span>
                                                        <span className="text">{templateProducerinfo.fileNamePattern}</span>
                                                    </li>
                                                    <li className="file">
                                                        <span className="type"> Partner Sends Files Through : </span>
                                                        <span className="text">{templateProducerinfo.receiveOverEmail ? "Email" : templateProducerinfo.receiveOverSftp ? "SFTP" : templateProducerinfo.receiveOverWeb ? "Web" : templateProducerinfo.receiveOverS3 ? "S3 BUCKET" : ""}</span>
                                                    </li>
                                                </div>

                                            ))}

                                            <li className={`folder ${collapseState.tpg_list ? 'folder-open' : ''}`} >
                                                <b onClick={() => touchOrUntouchOperation("tpg_list", templateInfo.templateId)}>Producer Group List</b>
                                                <ul style={{ display: collapseState.tpg_list ? "block" : "none" }}>
                                                    {
                                                        templateInfo.templateGroups.map((templateGroupInfo) => (
                                                            templateGroupInfo.groupType === "PRD" ?
                                                                <li className="text">- {templateGroupInfo.groupName}</li>
                                                                : ""
                                                        ))
                                                    }
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className={`folder ${collapseState.tc_details ? 'folder-open' : ''}`}>
                                        <b onClick={() => touchOrUntouchOperation("tc_details", templateInfo.templateId)}>  Consumer Details</b>
                                        <ul style={{ display: collapseState.tc_details ? "block" : "none" }}>
                                            {templateInfo.templateConsumer.map((templateConsumerinfo) => (
                                                <div>
                                                    <li className="file">
                                                        <span className="type"> Consumer File Type : </span>
                                                        <span className="text">
                                                            {
                                                                templateConsumerinfo.fileType === "DECRPT" ? "Decryption Required" :
                                                                templateConsumerinfo.fileType === "ENCRPT" ? "Encryption Required": "Unknown"
                                                            }</span>
                                                    </li>
                                                    <li className="file">
                                                        <span className="type"> Consumer File Path : </span>
                                                        <span className="text">{templateConsumerinfo.filePath}</span>
                                                    </li>
                                                    <li className="file">
                                                        <span className="type"> Consumer File Pattern : </span>
                                                        <span className="text">{templateConsumerinfo.fileNamePattern}</span>
                                                    </li>
                                                    <li className="file">
                                                        <span className="type"> Partner Will Deliver Files Through : </span>
                                                        <span className="text">{templateConsumerinfo.receiveOverEmail ? "Email" : templateConsumerinfo.receiveOverSftp ? "SFTP" : templateConsumerinfo.receiveOverWeb ? "Web" : ""}</span>
                                                    </li>
                                                    <li className="file">
                                                        <span className="type"> Deliver Over Consumer Listen  : </span>
                                                        <span className="text">{templateConsumerinfo.deliverOverConsumerListen ? "True" : "False"}</span>
                                                    </li>


                                                </div>
                                            ))}
                                            <li className={`folder ${collapseState.tcg_list ? 'folder-open' : ''}`}>
                                                <b onClick={() => touchOrUntouchOperation("tcg_list", templateInfo.templateId)}>  Consumer Group List</b>
                                                <ul style={{ display: collapseState.tcg_list ? "block" : "none" }}>
                                                    {
                                                        templateInfo.templateGroups.map((templateGroupInfo) => (
                                                            templateGroupInfo.groupType === "CNS" ?
                                                                <li className="text">- {templateGroupInfo.groupName}</li>
                                                                : ""
                                                        ))
                                                    }
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <React.Fragment>
            <main>
                <div className="container">
                    <ul className="breadcrumb">
                        <li className="breadcrumb__item">
                            <a className="breadcrumb__link">Manage Partner</a>
                        </li>
                        <li className="breadcrumb__item">
                            <span className="breadcrumb__link">Templates</span>
                        </li>
                    </ul>
                    <div className="title">
                        <h1>List Templates</h1>
                    </div>
                    <div >
                        {popupVisibility.failed ?
                            <AlertFailure message={popupVisibility.message} /> : ''}
                        {popupVisibility.success ?
                            <AlertSuccess message={popupVisibility.message} /> : ''}
                    </div>
                    {isAdmin() || isPartnerAdmin()
                        ? <div className="btn-wrap btn-wrap--grp zb-5">
                            <button className="button button--outline button--sm ml-0 is-active" onClick={(e) => window.location = '/template/new'}>Create Template</button>
                        </div> : ''}
                </div>


                <div className="container">
                    <div className="accordian">
                        <br></br>
                        <div className="table-block__head">
                            <h2>List of Templates
                                <span style={{ "fontWeight": "bold", "fontSize": "13px", "color": "#666" }} >
                                    &nbsp; [ Total: {templateDetailsList ? templateDetailsList.length : 0} ]
                                </span> </h2>
                            <div className="txt-wrap">
                                <input type="text" className="txt" placeholder="Search..." onChange={(e) => setOnSearchKeyword(e.target.value)} />
                                <i className="fa fa-search"></i>
                            </div>
                        </div>

                        {
                            templateDetailsList.length > 0 ?
                                templateDetailsList.map((templateInfo) => (
                                    (
                                        onSearchKeyword !== "" && templateInfo.templateName.toLowerCase().includes(onSearchKeyword.toLowerCase()) ?
                                            listRenderingModule(templateInfo)
                                            :
                                            (
                                                onSearchKeyword === "" ?
                                                    listRenderingModule(templateInfo)
                                                    :
                                                    ""
                                            )
                                    )

                                ))

                                :
                                <div className="text-center text-danger">No Template data available</div>
                        }



                    </div>
                </div>

            </main>
        </React.Fragment>
    )
}



const mapDispatchToProps = dispatch => ({
    dispatchGetTemplateDetailsList: (onSuccess, onError) =>
        dispatch(getTemplateDetailsList(onSuccess, onError)),
    dispatchDeleteTemplate: (templateId, onSuccess, onError) =>
        dispatch(deleteTemplate(templateId, onSuccess, onError)),
    dispatchFetchTemplateExportData: (templateId, onSuccess, onError) =>
        dispatch(fetchTemplateExportData(templateId, onSuccess, onError)),
})
export default connect(null, mapDispatchToProps)(TemplateListingComponent)



