import React, { useEffect, useState } from 'react';
import { connect, useStore } from 'react-redux';
import { fetchOneTimeParams } from '../../../redux/actions/auth.action.creators'

import close_icon from '../../../assets/images/icon-close.png';


const FileMappingFunctionReplaceComponent = (props, { dispatchOneTimeParams }) => {
  const [fieldEntity, setFieldEntity] = useState({ "field_name": props.selectedFunction.item.map_to, "field_location": props.selectedFunction.item.field_type, "original_value": props.selectedFunction.parameters!=null?props.selectedFunction.parameters.split("//")[0]:'', "replace_value": props.selectedFunction.parameters!=null?props.selectedFunction.parameters.split("//")[1]:'', "replace_operation": props.selectedFunction.parameters!=null?(props.selectedFunction.parameters.split("//")[2]!=""?'pos':(props.selectedFunction.parameters.split("//")[0]!="" && props.selectedFunction.parameters.split("//")[1]!='')?'val':'all'):"val", "start_position": props.selectedFunction.parameters!=null?props.selectedFunction.parameters.split("//")[2]:'', "end_position": props.selectedFunction.parameters!=null?props.selectedFunction.parameters.split("//")[3]:'' })
  const [inputErrorWarning, setInputErrorWarning] = useState('')

  const inputFieldValidation = () => {



    if(fieldEntity.replace_operation == 'pos') {
      setInputErrorWarning({
        "start_position": ((fieldEntity.start_position === undefined || fieldEntity.start_position.trim() === "") && fieldEntity.replace_operation == 'pos') ? "Enter Start Position" : inputFieldLengthValidation(fieldEntity.start_position, 4) ? null : "Max position is 9999",
        "end_position": ((fieldEntity.end_position === undefined || fieldEntity.end_position.trim() === "") && fieldEntity.replace_operation == 'pos') ? "Enter End Position" : !inputFieldLengthValidation(fieldEntity.end_position, 4) ? "Max position is 9999" : (fieldEntity.start_position > fieldEntity.end_position) ? "End position should be greater than Start Position" : ((fieldEntity.end_position-fieldEntity.start_position)>props.selectedFunction.details.field_length)?"The selected range is larger than the field length":null,
        "original_value": (fieldEntity.original_value === undefined || fieldEntity.original_value.trim() === "") ? "Enter Value to be Replaced" : inputFieldLengthValidation(fieldEntity.original_value, props.selectedFunction.details.field_length) ? null : "Value length should be less than or equal to field length",
        "replace_value": (fieldEntity.replace_value === undefined || fieldEntity.replace_value.trim() === "") ? "Enter Value to be Replaced" : inputFieldLengthValidation(fieldEntity.replace_value, props.selectedFunction.details.field_length) ? null : "Value length should be less than or equal to field length"
      })
      if (
        fieldEntity.start_position !== undefined &&
        fieldEntity.end_position !== undefined &&
        fieldEntity.original_value !== undefined &&
        fieldEntity.replace_value !== undefined &&
        fieldEntity.start_position.trim() !== "" &&
        fieldEntity.end_position.trim() !== ""&&
        inputFieldLengthValidation(fieldEntity.start_position, 4) &&
        inputFieldLengthValidation(fieldEntity.end_position, 4)&&
        ((fieldEntity.end_position-fieldEntity.start_position)<props.selectedFunction.details.field_length)
      ) {
        return true
      }
    } else if (fieldEntity.replace_operation == 'val') {
      setInputErrorWarning({
        "original_value": (fieldEntity.original_value === undefined || fieldEntity.original_value.trim() === "") ? "Enter Value to be Replaced" : inputFieldLengthValidation(fieldEntity.original_value, props.selectedFunction.details.field_length) ? null : "Value length should be less than or equal to field length",
        "replace_value": (fieldEntity.replace_value === undefined || fieldEntity.replace_value.trim() === "") ? "Enter Value to be Replaced" : inputFieldLengthValidation(fieldEntity.replace_value, props.selectedFunction.details.field_length) ? null : "Value length should be less than or equal to field length"
      })
      if (
        fieldEntity.original_value !== undefined &&
        fieldEntity.replace_value !== undefined &&
        fieldEntity.replace_value.trim() !== "" &&
        fieldEntity.original_value.trim() !== ""&&
        inputFieldLengthValidation(fieldEntity.replace_value, props.selectedFunction.details.field_length) &&
        inputFieldLengthValidation(fieldEntity.original_value, props.selectedFunction.details.field_length)
      ) {
        return true
      }
    }else if (fieldEntity.replace_operation == 'all') {
      setInputErrorWarning({
        "replace_value": fieldEntity.replace_value.trim() === "" ? "Enter Value to be Replaced" : inputFieldLengthValidation(fieldEntity.replace_value, props.selectedFunction.details.field_length) ? null : "Value length should be less than or equal to field length"
      })
      if (
        fieldEntity.replace_value !== undefined &&
        fieldEntity.replace_value.trim() !== ""&&
        inputFieldLengthValidation(fieldEntity.replace_value, props.selectedFunction.details.field_length) 
      ) {
        return true
      }
    }
    else{
      return false
    }
  }

  const inputFieldLengthValidation = (data, requiredLength) => {
    if (props.fileType.Destination=='csv'){
      return true
    }else{
      let dataLength = data.length;
      if (dataLength <= requiredLength) {
        return true
      } else return false
    }

  }



  const handlePopUP = (activity) => {
    if (activity == "close") {
      setFieldEntity({ "field_name": props.selectedFunction.item.map_to, "field_location": props.selectedFunction.item.field_type, "original_value": props.selectedFunction.parameters!=null?props.selectedFunction.parameters.split("//")[0]:'', "replace_value": props.selectedFunction.parameters!=null?props.selectedFunction.parameters.split("//")[1]:'', "replace_operation": props.selectedFunction.parameters!=null?(props.selectedFunction.parameters.split("//")[2]!=""?'pos':(props.selectedFunction.parameters.split("//")[0]!="" && props.selectedFunction.parameters.split("//")[1]!='')?'val':'all'):"val", "start_position": props.selectedFunction.parameters!=null?props.selectedFunction.parameters.split("//")[2]:'', "end_position": props.selectedFunction.parameters!=null?props.selectedFunction.parameters.split("//")[3]:'' })
      props.setSelectedFunction({ "item": "", "function": "", "details": "", "fileType": "","parameters": "" })
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFieldEntity((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  };


  const handleSubmit = () => {
    if (inputFieldValidation()) {
      const exists = props.allFields.mapping_fields.some(
        (field, index) =>
          field.map_to === fieldEntity.field_name &&
          field.field_type === fieldEntity.field_location &&
          (field.function_name == "" || field.function_name == "replace")
      )
      if (fieldEntity.replace_operation=='pos'){
        fieldEntity.original_value=""
      }
      else if(fieldEntity.replace_operation=='val'){
        fieldEntity.start_position=""
        fieldEntity.end_position=""
      }
      else if(fieldEntity.replace_operation='all'){
        fieldEntity.start_position=""
        fieldEntity.end_position=""
        fieldEntity.original_value=""
      }
      if (exists) {
        const updatedFields = props.allFields.mapping_fields.map((field) => {
          if (
            field.map_to === fieldEntity.field_name &&
            field.field_type === fieldEntity.field_location &&
            (field.function_name === "" || field.function_name === "replace")
          ) {
            return {
              ...field,
              function_name: "replace",
              replace: fieldEntity.original_value + "//" + fieldEntity.replace_value + "//" + fieldEntity.start_position + '//' + fieldEntity.end_position,
            };
          }
          return field;
        });
        props.setAllFields((prevState) => ({
          ...prevState,
          mapping_fields: updatedFields,
        }));
      }
      else {
        const newEntity = {
          field_type: fieldEntity.field_location,
          map_to: fieldEntity.field_name,
          function_params: props.selectedFunction.item.function_params,
          replace: fieldEntity.original_value + "//" + fieldEntity.replace_value + "//" + fieldEntity.start_position + '//' + fieldEntity.end_position,
          pattern: "",
          function_name: "replace"
        };
        const newMappingFields = [...props.allFields.mapping_fields, newEntity];
        props.setAllFields((prevFields) => ({ ...prevFields, mapping_fields: newMappingFields }));

      }
      setFieldEntity({ "field_name": props.selectedFunction.item.map_to, "field_location": props.selectedFunction.item.field_type, "original_value": "", "replace_value": "", "replace_type": "value", "start_position": "", "end_position": "" });
      handlePopUP("close")
    }
  }

  return (
    <React.Fragment>
      <div className={`modal fade modal--addnew ${props.selectedFunction.function == "replace" ? 'show' : ''}`} aria-modal="true" data-toggle="modal" style={{ display: props.selectedFunction.function == "replace" ? 'block' : 'none' }} id="addField" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Replace Function</h5>

              <button type="button" onClick={(() => { handlePopUP("close") })} class="close" data-dismiss="modal" aria-label="Close">
                <img src={close_icon} alt="Close" />
              </button>
            </div>

            <div class="modal-body ">
              <p>This function Replaces the input values based on parameters and apply the result to this field</p>
              <div class="form mb-5" >
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label for="pc" class="input-label">Destination field name<span style={{ color: 'red' }}>*</span></label>
                      <input disabled="disabled" className={`txt ${inputErrorWarning.field_name ? 'is-invalid' : ''}`} value={fieldEntity.field_name} name="field_name" type="text" placeholder="Field Name" id="pc" />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label for="f2" class="d-block input-label">Operation<span style={{ color: 'red' }}>*</span></label>
                      <div class="input-wrap date-wrap">
                        <select id="f1" onChange={handleInputChange} name="replace_operation" value={fieldEntity.replace_operation}>
                         z <option value="pos">Replace by Position</option>
                          <option value="val">Replace by Value</option>
                          <option value="all">Replace all</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {fieldEntity.replace_operation == 'pos' ?
                    (<div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label for="cc" class="input-label">Start Position<span style={{ color: 'red' }}>*</span></label>
                        <input className={`txt ${inputErrorWarning.field_length ? 'is-invalid' : ''}`} value={fieldEntity.start_position} type="number" name="start_position" min={0} step={1} onChange={handleInputChange} placeholder="Start position relative to the selected field" id="cc" />
                      </div>
                      {inputErrorWarning.start_position ?
                        (<div className="error-message">{inputErrorWarning.start_position}</div>)
                        : ('')}
                    </div>
                    ) : ('')}


                  {fieldEntity.replace_operation == 'pos' ?
                    (<div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label for="cc" class="input-label">End Position<span style={{ color: 'red' }}>*</span></label>
                        <input className={`txt ${inputErrorWarning.field_length ? 'is-invalid' : ''}`} value={fieldEntity.end_position} type="number" name="end_position" min={0} step={1} onChange={handleInputChange} placeholder="End position relative to the selected field" id="cc" />
                      </div>
                      {inputErrorWarning.end_position ?
                        (<div className="error-message">{inputErrorWarning.end_position}</div>)
                        : ('')}
                    </div>
                    ) : ('')}

                  {fieldEntity.replace_operation == 'val' ?
                    (<div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label for="cc" class="input-label">Value to be Replaced<span style={{ color: 'red' }}>*</span></label>
                        <input className={`txt ${inputErrorWarning.original_value ? 'is-invalid' : ''}`} value={fieldEntity.original_value} type="text" name="original_value" onChange={handleInputChange} placeholder="Enter original value to be replaced" id="cc" />
                      </div>
                      {inputErrorWarning.original_value ?
                        (<div className="error-message">{inputErrorWarning.original_value}</div>)
                        : ('')}
                    </div>
                    ) : ('')}

                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label for="cc" class="input-label">New Value<span style={{ color: 'red' }}>*</span></label>
                      <input className={`txt ${inputErrorWarning.replace_value ? 'is-invalid' : ''}`} value={fieldEntity.replace_value} type="text" name="replace_value" onChange={handleInputChange} placeholder="Enter new value" id="cc" />
                    </div>
                    {inputErrorWarning.replace_value ?
                      (<div className="error-message">{inputErrorWarning.replace_value}</div>)
                      : ('')}
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" onClick={(() => { handlePopUP("close") })} class="button button--outline button--sm" data-dismiss="modal">Cancel</button>
                  <button type="button" onClick={(() => { handleSubmit() })} data-dismiss="modal" class="button button--sm">Save</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {(props.selectedFunction.function == "replace" ? true : false) && <div className="modal-backdrop fade show"></div>}
    </React.Fragment >
  );

}

const mapDispatchToProps = dispatch => ({
  dispatchOneTimeParams: (onSuccess, onError) =>
    dispatch(fetchOneTimeParams({ "keyList": ["preferred_mac", "preferred_cipher", "compression"] }, onSuccess, onError))
})

export default connect(null, mapDispatchToProps)(FileMappingFunctionReplaceComponent);

