import React, { useState, useEffect, Component } from "react";
import { } from "../redux/actions/auth.action.creators";
import { connect } from "react-redux";
import CardImgChannel from "../assets/images/card-img-channel.png";
import CardImgExco from "../assets/images/card-img-exco.png";
import CardImgHandback from "../assets/images/card-img-handback.png";
import CardImgPartner from "../assets/images/card-img-partner.png";
import { DateRangePicker } from "../utils/date-range-picker.component";
import { listGroupDetails, channelFileDetails, failedFileDetails } from '../redux/actions/auth.action.creators';
import CustomDropdownWidget from './dropdown/CustomDropdownWidget';
import close_icon from '../assets/images/icon-close.png';
import DashboardIcon from '../assets/images/icon_dashboard.png';
import FileHealthIcon from '../assets/images/icon_fileHealth.png';
import { Tooltip } from 'primereact/tooltip';
import iconInfo from '../assets/images/icon-info-round.png';
import iconLegend from '../assets/images/icon-legend.png';
import iconExport from '../assets/images/icon-export.png';
import iconExcel from '../assets/images/icon-excel.png';
import * as XLSX from 'xlsx';

const FilehealthDashboardComponent = ({ dispatchListGroupDetails, dispatchListChannelDetails, dispatchListFailedFilesDetails }) => {

  const [dateResponseData, setDateResponseData] = useState("false");
  const [dateRange, setDateRange] = useState(30);
  const [resetCalender, setResetCalender] = useState(false);
  const [groupDetailsList, setGroupDetailsList] = useState([{}]); // raw fetch data
  const [businessDetailsList, setBusinessDetailsList] = useState([{}]); // raw fetch data
  const [activeAccordianIndexList, setActiveAccordianIndexList] = useState([0]);
  const [dropdownRefresher, setDropdownRefresher] = useState(true)
  const [constrains, setConstrains] = useState({ "fieldPlaceholder": "Group", "isMandatory": false })
  const [constrainsBuss, setConstrainsBuss] = useState({ "fieldPlaceholder": "Business function", "isMandatory": false })
  const [constrainsPartner, setConstrainsPartner] = useState({ "fieldPlaceholder": "Partner", "isMandatory": false })
  const [constrainsFilename, setConstrainsFilename] = useState({ "fieldPlaceholder": "File name", "isMandatory": false })
  const [responseDataGroup, setResponseDataGroup] = useState({});
  const [responseDataBuss, setResponseDataBuss] = useState({});
  const [responseDataPartner, setResponseDataPartner] = useState({});
  const [responseDataFilename, setResponseDataFilename] = useState({});
  const [groupList, setGroupList] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const [filePatternList, setFilePatternList] = useState([]);
  const [displayGroupList, setDisplayGroupList] = useState([{}]);
  const [businessGroupsList, setBusinessGroupsList] = useState([{}]);
  const [requestBody, setRequestBody] = useState({ "startDate": '', "endDate": '', "channelId": 0, 'groups': [] });
  const [isChannelOnclickPopupEnable, setIsChannelOnclickPopupEnable] = useState(false)
  const [channelDetails, setChannelDetails] = useState([{}]);
  const [isLegendVisible, setIsLegendVisible] = useState(false);
  const [legendColorList, setLegendColorList] = useState([{ '100%': 100 }, { '>70%': 75 }, { '<=70%': 70 }]);
  const [noFilePartners, setNoFilePartners] = useState([{}]);
  const [noHandBackFiles, setNoHandBackFiles] = useState(0);
  const [noFileChannels, setNoFileChannels] = useState([]);
  const [noFileChannelDetails, setNoFileChannelDetails] = useState([{}]);
  const [failedFiles, setFailedFiles] = useState([]);
  const [noHandBackFilesList, setNoHandBackFilesList] = useState([]);
  const [isExportVisible, setIsExportVisible] = useState(false);
  const [isChnlExportVisible, setIsChnlExportVisible] = useState(false);
  const [partnerCntOnclkPopupEnable, setPartnerCntOnclkPopupEnable] = useState(false);
  const [failedFileCntOnclkPopupEnable, setFailedFileCntOnclkPopupEnable] = useState(false);
  const [noFileChannelOnclkPopupEnable, setNoFileChannelOnclkPopupEnable] = useState(false);
  const [noFileHbOnclkPopupEnable, setNoFileHbOnclkPopupEnable] = useState(false);
  const [dateResponseDataPrev, setDateResponseDataPrev] = useState([]);
  const [sortAsc, setSortAsc] = useState({ "producerCode": false,"fileName": false, "status": false, "createdDate": false, "partnerCode": false,"partnerName": false,"channelName": false,"filePattern": false, });
  const [channelProducer, setChannelProducer] = useState();
  const [channelConsumer, setChannelConsumer] = useState();
  const [channelName, setChannelName] = useState();
  const [selectedOption, setSelectedOption] = useState('group');

  function applyFilter(processFlg,list) {

    let displayGroupListTemp = list;
    let temp = displayGroupListTemp;
    let grpFilterFlag=false;
    let ptnrFilterFlag=false;
    let bussFilterFlag=false;
    
    if (document.getElementById("inlineRadio2").checked == true) {
      //business function filter
      if (JSON.stringify(responseDataBuss) !== "{}" && responseDataBuss.value != "") {
        bussFilterFlag=true;
        temp = displayGroupListTemp.filter((g) => g.bfName === responseDataBuss.value);
      }
    } else {
      //group filter
      if (JSON.stringify(responseDataGroup) !== "{}" && responseDataGroup.value != "") {
        grpFilterFlag=true;
        temp = displayGroupListTemp.filter((g) => g.groupName === responseDataGroup.value);
      }
    }
  
    //partner filter
    if (JSON.stringify(responseDataPartner) !== "{}" && responseDataPartner.value != "") {
      if (grpFilterFlag || bussFilterFlag) {
        temp = temp.filter((g) => {
          let tempPartners = g.partners.filter(
            (p) => p.partnerCode === responseDataPartner.value
          );
          g.partners = tempPartners;
          return tempPartners.length > 0 ? g : null;
        });
      } else {
        temp = displayGroupListTemp.filter((g) => {
          let tempPartners = g.partners.filter(
            (p) => p.partnerCode === responseDataPartner.value
          );
          g.partners = tempPartners;
          return tempPartners.length > 0 ? g : null;
        });
      }
      ptnrFilterFlag = true;
    }   
    //file pattern filter 
    if ( JSON.stringify(responseDataFilename) !== "{}" && responseDataFilename.value != "") {
      if (bussFilterFlag || grpFilterFlag || ptnrFilterFlag) {
        temp = temp.filter((g) => {
          let tempPartners = g.partners.filter(
            (p) => p.filePattern === responseDataFilename.value
          );
          g.partners = tempPartners;
          return tempPartners.length > 0 ? g : null;
        });
      } else {
        temp = displayGroupListTemp.filter((g) => {
          let tempPartners = g.partners.filter(
            (p) => p.filePattern === responseDataFilename.value
          );
          g.partners = tempPartners;
          return tempPartners.length > 0 ? g : null;
        });
      }
    }
    if (processFlg === "onsearch") {
      if(document.getElementById("inlineRadio2").checked == true){
        setBusinessGroupsList(temp);
      } else{
        setDisplayGroupList(temp);
      }
      
    }
  }

  const fetchData = async (processFlg) => {
    if (dateResponseData != "false" && dateResponseData != null) {
      requestBody.startDate = new Date(dateResponseData[0]).toLocaleDateString("en-CA") +" 00:00:00";
      requestBody.endDate =   new Date(dateResponseData[1]).toLocaleDateString("en-CA") +" 23:59:59";
    } else {
      let currentDate = new Date();
      let initial = new Date();
      currentDate.setDate(currentDate.getDate() - 1);
      initial.setDate(initial.getDate() - 7);
      requestBody.startDate = initial.toLocaleDateString("en-CA") + " " + "00:00:00";
      requestBody.endDate = currentDate.toLocaleDateString("en-CA") + " " + "23:59:59";
    }
    setDateResponseDataPrev([requestBody.startDate,requestBody.endDate]);
    if((dateResponseDataPrev[0]!=requestBody.startDate || dateResponseDataPrev[1]!=requestBody.endDate) || processFlg=="onload"){
      await new Promise((resolve, reject) => {
        dispatchListGroupDetails(
          requestBody,
          ({ data, message }) => {
            if (data) {
              console.log("data: ",data);
              let no_files_partner_list = [];
              let no_files_channel_list = [];
              let no_files_channel_detail_list = [];
              let no_handback_file_count = 0;
              let no_handback_file_detail_list = [];
              let arr = [{ value: "", label: "Any" }];
              let groups = [];
              let partnerslist = [{ value: "", label: "Any" }];
              let filePatternlist = [{ value: "", label: "Any" }];
              let displayGroups = [];
              let businessGroups = [];
              let business = [{ value: "", label: "Any" }];
              data.forEach((g) => {
                if (g.healthMonitorGroup == "true") {
                  displayGroups.push(g);
                  g.partners.forEach((p) => {
                    if(businessGroups.some(bg => bg.businessFunctions === p.businessFunctions)){
                      businessGroups = businessGroups.map(item => {
                        if(item.businessFunctions === p.businessFunctions){
                          let newPartner = {};
                          newPartner.partnerCode = p.partnerCode;
                          newPartner.partnerId = p.partnerId;
                          newPartner.partnerName = p.partnerName;
                          newPartner.channelId = p.channelId;
                          newPartner.channelName = p.channelName;
                          newPartner.filePattern = p.filePattern;
                          newPartner.filecountExpectedIn = p.filecountExpectedIn;
                          newPartner.filecountReceivedIn = p.filecountReceivedIn;
                          newPartner.frequencyType = p.frequencyType;
                          newPartner.hbchannelid = p.handbackInfo.hbchannelid;
                          newPartner.hbexpectedcount = p.handbackInfo.hbexpectedcount;
                          newPartner.hbfilecount = p.handbackInfo.hbfilecount;
                          newPartner.percentageHealthHb = p.percentageHealthHb;
                          newPartner.percentageHealth = p.percentageHealth;
        
                          item.partners.push(newPartner);
                        } 
                        return item;
                      });
                      
                    } else {
                      business.push({
                        value: p.bfName,
                        label: `${p.bfName}`,
                      }); 

                      let temp = {};
                      temp.businessFunctions=p.businessFunctions ;
                      temp.bfName=p.bfName ;
                      temp.bfDescription=p.bfDescription ;
                      temp.bfCmpCode=p.bfCmpCode ;

                      let tempList = {};
                      tempList.partnerCode=p.partnerCode;
                      tempList.partnerId= p.partnerId;
                      tempList.partnerName=p.partnerName;
                      tempList.channelId=p.channelId;
                      tempList.channelName=p.channelName; 
                      tempList.filePattern=p.filePattern;
                      tempList.filecountExpectedIn=p.filecountExpectedIn;
                      tempList.filecountReceivedIn=p.filecountReceivedIn;
                      tempList.frequencyType=p.frequencyType;
                      tempList.hbchannelid=p.handbackInfo.hbchannelid;
                      tempList.hbexpectedcount=p.handbackInfo.hbexpectedcount;
                      tempList.hbfilecount=p.handbackInfo.hbfilecount;
                      tempList.percentageHealthHb=p.percentageHealthHb;
                      tempList.percentageHealth=p.percentageHealth;
                      temp.partners = [];
                      temp.partners.push(tempList);
                      businessGroups.push(temp);
                    }
                    
                    if(!partnerslist.some(partner => partner.value === p.partnerCode)){
                      partnerslist.push({
                        value: p.partnerCode,
                        label: `${p.partnerCode} (${p.partnerName})`,
                      }); 
                    }
                    if(p.filePattern!="-" && !(filePatternlist.some(filePattern1 => filePattern1.value === p.filePattern))){
                      filePatternlist.push({
                        value: p.filePattern,
                        label: p.filePattern,
                      }); 
                    }
                    if (p.filecountReceivedIn == "0") {
                      // if (!no_files_partner_list.includes(p.partnerCode)) {
                      if (!no_files_partner_list.some(partner => partner.partnerCode === p.partnerCode)) {
                        no_files_partner_list.push(p);
                      }
                      if (!no_files_channel_list.includes(p.channelId)) {
                        no_files_channel_list.push(p.channelId);
                        no_files_channel_detail_list.push(p);
                      }
                    } else {
                      let dif =
                        parseInt(p.filecountReceivedIn) -
                        parseInt(p.handbackInfo["hbfilecount"]);
                      if (dif > 0) {
                        no_handback_file_detail_list.push(p);
                        no_handback_file_count = no_handback_file_count + dif;
                      }
                    }
                  });

                  arr.push({
                    value: g.groupName,
                    label: `${g.groupDescription} (${g.groupName})`,
                  });
                  groups.push(g.groupName);

                } else {
                  // console.log("group2", g);
                }
              });
              // console.log("businessGroups:",businessGroups);
              setGroupDetailsList(displayGroups);
              setDisplayGroupList(displayGroups);
              setNoFileChannels(no_files_channel_list);
              setNoFilePartners(no_files_partner_list);
              setNoFileChannelDetails(no_files_channel_detail_list);
              setNoHandBackFiles(no_handback_file_count);
              setNoHandBackFilesList(no_handback_file_detail_list);
              setGroupList(arr);
              setBusinessList(business);
              if (partnerslist.length > 1) {
                const firstElement = partnerslist[0];
                const rest = partnerslist.slice(1);
              
                rest.sort((a, b) => {
                  if (a.label < b.label) return -1;
                  if (a.label > b.label) return 1;
                  return 0;
                });
              
                partnerslist = [firstElement, ...rest];
              }
              // if (filePatternList.length > 1) {
              //   const firstElement = partnerslist[0];
              //   const rest = partnerslist.slice(1);
              
              //   rest.sort((a, b) => {
              //     if (a.label < b.label) return -1;
              //     if (a.label > b.label) return 1;
              //     return 0;
              //   });
              
              //   filePatternList = [firstElement, ...rest];
              // }
              if (businessGroups.length > 1) {
                businessGroups = businessGroups.sort((a, b) => {
                  if (a.bfName < b.bfName) return -1;
                  if (a.bfName > b.bfName) return 1;
                  return 0;
                });
              }
              setBusinessGroupsList(businessGroups);
              setBusinessDetailsList(businessGroups);
              setPartnerList(partnerslist);
              setFilePatternList(filePatternlist);
              requestBody.groups = groups;
              if(processFlg==="onsearch"){
                if(document.getElementById("inlineRadio2").checked == true){
                  applyFilter(processFlg,businessGroups);
                } else {
                  applyFilter(processFlg,displayGroups);
                }
              }
              
            }
            resolve();
          },
        (message) => {
            // console.log(message);
          reject();
        }
      );
      
      });
  
      dispatchListFailedFilesDetails(
        requestBody,
        ({ data, message }) => {
          if (data) {
            // console.log("Failed Files data: ", data);
            setFailedFiles(data);
          }
        },
        (message) => {
          console.log(message);
        }
      );
    }else{
      if(processFlg==="onsearch"){
        let groupDetailsListClone =_.cloneDeep(groupDetailsList);
        let businessGroupsListClone =_.cloneDeep(businessDetailsList);
        if(document.getElementById("inlineRadio2").checked == true){
          
          applyFilter(processFlg,businessGroupsListClone);
        } else {
          
          applyFilter(processFlg,groupDetailsListClone);
        }
      }
    }
  };

  useEffect(() => {
    document.getElementById("inlineRadio1").checked = true;
    fetchData("onload");
  }, []);

  function onSearch(){
    fetchData("onsearch");
  }

  const truncateTime = (text) => {
    if (text && text.length > 1) {
      return text.substring(0, 19);
    }
    return text || '';
  };

  const accordianEvent = (index) => {
    var indexList = [...activeAccordianIndexList]
    if (indexList.includes(index)) {
      var ind = indexList.indexOf(index)
      indexList.splice(ind, 1)
    } else {
      indexList.push(index)
    }
    setActiveAccordianIndexList(indexList)
  }

  function clearAllOnclick() {
    setDropdownRefresher(dropdownRefresher ? false : true)
    setResetCalender(true);
    setResponseDataGroup({});
    setResponseDataBuss({});
  }

  function dateSetting() {
    let currentDate = new Date();
    let initial = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    initial.setDate(initial.getDate() - 7);
    return [initial, currentDate];
  }

  function onClickFileNameDisplay(param, partner_code, files) {
    let channelId = Number(param);
    requestBody.channelId = channelId;
    if (channelId == 0) {
      let noChannelData = [];

      failedFiles.forEach((f) => {
        if (f.producerCode == partner_code && Number(f.channelId) == channelId) {
          noChannelData.push(f);
        }
      });
      setChannelDetails(noChannelData);
    } else {
      dispatchListChannelDetails(
        requestBody,
        ({ data, message }) => {
          if (data) {
            data.some((f, index, array) => {
              setChannelProducer(f.producerCode);
              setChannelConsumer(f.consumerCode);
              setChannelName(f.channelName);
              
              const isLastItem = index === array.length - 1;
              if (f.status === 'SUCCESS' || isLastItem) {
                return true;
              }
              return false;
            });
            setChannelDetails(data);
          }
        },
        (message) => {
          console.log(message);
        }
      );

    }

    if (Number(files) > 0) {
      setIsChannelOnclickPopupEnable(true);
    } else {
      setIsChannelOnclickPopupEnable(false);
    }
  }

  function percentageClrCalc(val) {
    let clr = 'black';
    if (val >= 100) {
      clr = 'green';
    } else if (val > 70 && val < 100) {
      clr = 'orange';
    } else if (val <= 70) {
      clr = 'red';
    }
    return clr;
  }

  function exportClickExcelDownload() {
    const curretDate = new Date();
    const options = {year: 'numeric',month: '2-digit',day: '2-digit',hour: '2-digit',minute: '2-digit',second: '2-digit',hour12: false};//24hrs format
    const formattedDate = curretDate.toLocaleString('en-CA', options).replace(/:/g, '-').replace(', ', '-');
    let outputFileName = 'FileHealthMonitor'+formattedDate;
    var modifiedGroupList =[{}];
    
    console.log(channelDetails);
    if(isChnlExportVisible){
      channelDetails.forEach(element => {
        let temp ={};
        temp.channel_name=element.channelName;
        temp.producer_partner=element.producerCode;
        temp.consumer_partner=element.consumerCode;
        temp.created_date=element.createdDate;
        temp.file_name=element.fileName;
        temp.status=element.status;
        modifiedGroupList.push(temp);
        });
    } else if(document.getElementById("inlineRadio2").checked == true){
      //Modify data
      businessGroupsList.forEach(element => {
        element.partners.forEach(e => {
          let temp ={};
          temp.business_functions=element.businessFunctions;
          temp.business_function_name=element.bfName;
          temp.business_function_description=element.bfDescription;
          temp.company_code=element.bfCmpCode;
          temp.partner_code=e.partnerCode;
          temp.partner_name=e.partnerName;
          temp.filename_pattern=e.filePattern;
          temp.expected_file_count=e.filecountExpectedIn;
          temp.received_file_count=e.filecountReceivedIn;
          temp.expected_handback_count=e.hbexpectedcount;
          temp.received_handback_count=e.hbfilecount;
          modifiedGroupList.push(temp);
        });
      })
    } else{
      //Modify data
      displayGroupList.forEach(element => {
        element.partners.forEach(e => {
          let temp ={};
          temp.groupName=element.groupDescription;
          temp.group_code=element.groupName;
          temp.partner_code=e.partnerCode;
          temp.partner_name=e.partnerName;
          temp.filename_pattern=e.filePattern;
          temp.expected_file_count=e.filecountExpectedIn;
          temp.received_file_count=e.filecountReceivedIn;
          temp.expected_handback_count=e.handbackInfo.hbexpectedcount;
          temp.received_handback_count=e.handbackInfo.hbfilecount;
          modifiedGroupList.push(temp);
        });
      })
    }
       
    //Modify data
    const finalData = modifiedGroupList.map(item => {
      const modifiedItem = {};
      for (const key in item) {
        const modifiedKey = key.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()); // Remove underscores and capitalize first letter of each word
        modifiedItem[modifiedKey] = item[key];
      }
      return modifiedItem;
    });

    // Convert modified JSON data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(finalData);

    // Create a new workbook and add the worksheet to it
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Convert workbook to binary XLSX data
    const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });

    // Create a Blob from the binary XLSX data
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    // Create a download link for the Blob
    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = outputFileName + ".xlsx"; // Change file extension to .xlsx

    // Trigger file download
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // Revoke the Object URL to free up resources
    window.URL.revokeObjectURL(url);
  }

  function compareBy(key, dir) {
    return function (a, b) {
      if (a[key] < b[key]) return dir == true ? -1 : 1;
      if (a[key] > b[key]) return dir == true ? 1 : -1;
      return 0;
    };
  }

  function sortBy(key, table) {
    let arrayCopy = _.cloneDeep(table);
    let dirCopy = _.cloneDeep(sortAsc);
    dirCopy[key] = !dirCopy[key];
    setSortAsc(dirCopy);
    arrayCopy.sort(compareBy(key, dirCopy[key]));
    if(table==channelDetails){
      setChannelDetails(arrayCopy);
    }else if(table==failedFiles){
      setFailedFiles(arrayCopy);
    }else if(table==noFilePartners){
      setNoFilePartners(arrayCopy);
    }else if(table==noFileChannelDetails){
      setNoFileChannelDetails(arrayCopy);
    }else if(table==noHandBackFilesList){
      setNoHandBackFilesList(arrayCopy);
    }
  }

  useEffect(() => {
    clearAllOnclick();
  }, [selectedOption]);
  
  return (
    <React.Fragment>
      <main>
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb__item">
              <a className="breadcrumb__link" href="/dashboard">
                Home
              </a>
            </li>
            <li className="breadcrumb__item">
              <a className="breadcrumb__link" href="/filehealthDashboard">
                File Health Monitor
              </a>
            </li>
          </ul>
          <div className="title">

            <div class="widget-container widget--box">

              <a class="widget widget --dashboard dashboard dashboardNotSelected" href={"/dashboard"}> <span><img src={DashboardIcon} alt="More" /></span> Dashboard</a>
              <a class="widget widget --dashboard dashboard dashboardSelected" href={"/filehealthDashboard"}><span><img src={FileHealthIcon} alt="More" /></span> File Health Monitor</a>
            </div>
          </div>

          <div class="card-group">
            <div className="col-lg-3 mb-4">
              <img src={CardImgPartner} class="card-img-top" alt="User" />
              <div class="card-body">
                <h5 class="card-title" onClick={(e) => setPartnerCntOnclkPopupEnable(true) } data-target="#nofilepartnerlistingmodel" data-toggle="modal" style={{ cursor: 'help'}}>{noFilePartners.length}</h5>
                <p class="card-text"> Partners had no file exchange in the <strong style={{ color: "red" }}>selected period. </strong></p>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <img src={CardImgExco} class="card-img-top" alt="User" />
              <div class="card-body">
                <h5 class="card-title" onClick={(e) => setFailedFileCntOnclkPopupEnable(true) } data-target="#failedfilelistingmodel" data-toggle="modal" style={{ cursor: 'help'}}>{failedFiles.length}</h5>
                <p class="card-text"> Files are in failed status in <strong style={{ color: "red" }}>selected period. </strong></p>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <img src={CardImgChannel} class="card-img-top" alt="User" />
              <div class="card-body">
                <h5 class="card-title" onClick={(e) => setNoFileChannelOnclkPopupEnable(true) } data-target="#nofilechannellistingmodel" data-toggle="modal" style={{ cursor: 'help'}}>{noFileChannels.length}</h5>
                <p class="card-text"> Channels had no file exchange in the <strong style={{ color: "red" }}>selected period. </strong></p>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <img src={CardImgHandback} class="card-img-top" alt="User" />
              <div class="card-body">
                <h5 class="card-title" onClick={(e) => setNoFileHbOnclkPopupEnable(true) } data-target="#nofilehblistingmodel" data-toggle="modal" style={{ cursor: 'help'}}>{noHandBackFiles}</h5>
                <p class="card-text"> Files had no handback files in the <strong style={{ color: "red" }}>selected period. </strong></p>
              </div>
            </div>
          </div>
        </div>

        <div class="grey-block">
          <div class="container">
            <h2 class="mb-4">Filter</h2>
            <div class="form mb-5">
              <div class="row" style={{ padding: '15px 15px 0', borderBottom: 's1px' }}>
                <div class="col-lg-6 col-mb-6" text-right>
                  <div class="form-group">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="detailsOption" id="inlineRadio1" value="group" onChange={() => setSelectedOption('group')}/>
                      <label class="form-check-label" for="inlineRadio1">By Group</label>
                    </div>
                    <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="detailsOption" id="inlineRadio2" value="business" onChange={() => setSelectedOption('business')}/>
                    <label class="form-check-label" for="inlineRadio2">By Business Function</label>
                  </div>
                  </div>
                </div>
                <div class="col-lg-6 col-mb-6">
                  <div class="form-group">
                    <div className="btn-wrap text-right">
                      <a className="link" href="javascript:void(0)" onClick={() => { clearAllOnclick() }}>Clear all</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-mb-6">
                  <label for="f1" className="input-label"></label>
                  <div class="form-group input-wrap date-wrap">
                    <i class="far fa-calendar-alt"></i>
                    <DateRangePicker customRange={dateRange} setDateResponseData={setDateResponseData} reset={resetCalender} defaultDates={dateSetting} placeholder="Select Date Range" style={{ border: 'none' }} />
                  </div>
                </div>
                <div class="col-lg-6 col-mb-6">
                {selectedOption === 'group' ? 
                  <div class="form-group" >
                    <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={groupList} constrains={constrains} setResponseData={setResponseDataGroup}></CustomDropdownWidget>
                  </div> : 
                  <div class="form-group" >
                    <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={businessList} constrains={constrainsBuss} setResponseData={setResponseDataBuss}></CustomDropdownWidget>
                  </div>}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-mb-6">
                  <div class="form-group">
                    <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={partnerList} constrains={constrainsPartner} setResponseData={setResponseDataPartner}></CustomDropdownWidget>
                  </div>
                </div>
                <div class="col-lg-6 col-mb-6">
                  <div class="form-group">
                    <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={filePatternList} constrains={constrainsFilename} setResponseData={setResponseDataFilename}></CustomDropdownWidget>
                  </div>
                </div>
              </div>
              <div class="btn-wrap btn-wrap--grp mt-5 justify-content-end">
                <button class="button" onClick={() => onSearch()}>Search</button>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="from-group">
            <div className="row">
              <div className="col-lg-8 col-md-8">
                {selectedOption === 'group' ? 
                <p><strong>Group-wise details <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Data will be refreshed in every 6 hrs." data-pr-position="right" src={iconInfo} alt="info" /> </i> </span></strong></p> :
                <p><strong>Business Function-wise details <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Data will be refreshed in every 6 hrs." data-pr-position="right" src={iconInfo} alt="info" /> </i> </span></strong></p>
                }
              </div>
              <div className="col-lg-2 col-md-2" style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <button type="button" style={{ background: '#F8F8F8', color: 'black', border: 'none', width: '70%' }} class="btn btn-secondary btn-sm" onClick={(e) => isExportVisible ? setIsExportVisible(false) : setIsExportVisible(true)}><img src={iconExport} alt="Success" />  Export</button>
                <div>
                  {isExportVisible ? <div class="fh-legend widget" style={{ top: '25px', right: '45px' }}>
                    <p style={{ color: '#000' }}><strong>Export As</strong></p>
                    <ul>
                      <li style={{ cursor: 'help'}} onClick={(e) => exportClickExcelDownload()}><img src={iconExcel} alt="Success" /> Excel</li>
                    </ul>
                  </div> : ''}
                </div>
              </div>
              <div className="col-lg-2 col-md-2" style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <button type="button" style={{ background: '#F8F8F8', color: 'black', border: 'none', width: '70%', justify: 'left' }} class="btn btn-secondary btn-sm" onClick={(e) => isLegendVisible ? setIsLegendVisible(false) : setIsLegendVisible(true)} ><img src={iconLegend} alt="Success" /> Legend</button>
                <div>
                  {isLegendVisible ?
                    <div class="fh-legend widget">
                      <p style={{ color: '#000' }}><strong>Status</strong></p>
                      <ul>
                        {isLegendVisible && legendColorList.length > 0 ? legendColorList.map((c) => (
                          <li><i class="fh-dots" style={{ background: percentageClrCalc(Object.values(c)) }}></i> {Object.keys(c)}</li>
                        )) : ''}
                      </ul>
                    </div> : ''}
                </div>
              </div>
            </div>
          </div>
          {selectedOption === 'group' ? <div>
            {displayGroupList && displayGroupList.length>0 ? displayGroupList.map((element, index) => (
              // <div>
              <div class="accordian__item">
                <div className={`accordian__head ${activeAccordianIndexList.includes(index) ? 'active' : ''}`}>
                  <strong style={{ color: 'black' }} onClick={() => accordianEvent(index)}>
                    {displayGroupList[index].groupDescription} ( {displayGroupList[index].groupName} )
                  </strong>
                </div>
                <div
                  class="accordian__content"
                  style={{ display: activeAccordianIndexList.includes(index) ? "block" : "none", }}>
                  <div class="tree-strecture">
                    <div>
                      <div className="col-lg-12 col-md-12" key={index}>
                        <table class="table" style={{ background: '#fff', borderCollapse: 'separate', borderSpacing: '0 0.5rem', marginTop: '-1rem', marginBottom: '-1rem', border: 'none' }}>
                          <thead style={{ background: '#fff' }}>
                            <tr>
                              <th class="fh-tbl head" scope="col">No</th>
                              <th class="fh-tbl head" scope="col">Partner User</th>
                              <th class="fh-tbl head" scope="col">Partner Name</th>
                              <th class="fh-tbl head" scope="col">File Pattern</th>
                              <th class="fh-tbl head" scope="col">Incoming Health <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="The phrase “8/10” indicates that 8 files have been received out of expected 10 files." data-pr-position="right" src={iconInfo} alt="info" /> </i> </span></th>
                              <th class="fh-tbl head" scope="col"><span style={{ zIndex: 0 }}>Outgoing Health</span><span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="The phrase “8/10” indicates that 8 files have been sent out of expected 10 files." data-pr-position="right" src={iconInfo} alt="info" /> </i> </span></th>
                            </tr>
                          </thead>
                          <tbody>
                            {displayGroupList[index].partners && displayGroupList[index].partners.map((partner, index) => (
                              <tr key={index} style={{ fontWeight: 'normal', background: '#fff' }}>
                                <td class="fh-tbl body" style={{borderRadius: '6px 0px 0px 6px'}} scope="row">{index + 1}</td>
                                <td class="fh-tbl body" style={{borderLeft: 'none'}}>{partner.partnerCode}</td>
                                <td class="fh-tbl body" style={{borderLeft: 'none'}}>{partner.partnerName}</td>
                                <td class="fh-tbl body" style={{borderLeft: 'none'}}>{partner.filePattern}</td>
                                <td class="fh-tbl body" style={{borderLeft: 'none', color: percentageClrCalc(partner.percentageHealth), cursor: 'help' }} data-toggle="modal" onClick={(e) => onClickFileNameDisplay(partner.handbackInfo.channelid, partner.partnerCode, partner.filecountReceivedIn)} data-target="#channelfilelistingmodel" > <i class="fh-dots" style={{ background: percentageClrCalc(partner.percentageHealth) }}></i> {partner.filecountReceivedIn}/{partner.filecountExpectedIn}</td>
                                {!Number(partner.handbackInfo.channelid) == 0 ? <td  style={{ border: '1px solid #eee', borderLeft: 'none', borderRadius: '0px 6px 6px 0px', color: percentageClrCalc(partner.percentageHealthHb), cursor: 'help' }} data-toggle="modal" onClick={(e) => onClickFileNameDisplay(partner.handbackInfo.hbchannelid, partner.partnerCode, partner.handbackInfo.hbfilecount)} data-target="#channelfilelistingmodel" > <i class="fh-dots" style={{ background: percentageClrCalc(partner.percentageHealthHb) }}></i> {partner.handbackInfo.hbfilecount}/{partner.handbackInfo.hbexpectedcount}</td>
                                  : <td style={{ border: '1px solid #eee', borderLeft: 'none', borderRadius: '0px 6px 6px 0px'}} data-toggle="modal" data-target="#channelfilelistingmodel" onClick={(e) => onClickFileNameDisplay(partner.handbackInfo.hbchannelid, partner.partnerCode, partner.handbackInfo.hbfilecount)} >-</td>
                                }
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            // </div>
          )) :<div className="text-center text-danger"><p>No search results found</p></div>} </div> :
          <div>
            {businessGroupsList && businessGroupsList.length>0 ? businessGroupsList.map((element, index) => (
              // <div>
              <div class="accordian__item">
                <div className={`accordian__head ${activeAccordianIndexList.includes(index) ? 'active' : ''}`}>
                  <strong style={{ color: 'black' }} onClick={() => accordianEvent(index)}>
                    {businessGroupsList[index].bfName}</strong>
                </div>
                <div
                  class="accordian__content"
                  style={{ display: activeAccordianIndexList.includes(index) ? "block" : "none", }}>
                  <div class="tree-strecture">
                    <div>
                      <div className="col-lg-12 col-md-12" key={index}>
                        <table class="table" style={{ background: '#fff', borderCollapse: 'separate', borderSpacing: '0 0.5rem', marginTop: '-1rem', marginBottom: '-1rem', border: 'none' }}>
                          <thead style={{ background: '#fff' }}>
                            <tr>
                              <th class="fh-tbl head" scope="col">No</th>
                              <th class="fh-tbl head" scope="col">Partner User</th>
                              <th class="fh-tbl head" scope="col">Partner Name</th>
                              <th class="fh-tbl head" scope="col">File Pattern</th>
                              <th class="fh-tbl head" scope="col">Incoming Health <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="The phrase “8/10” indicates that 8 files have been received out of expected 10 files." data-pr-position="right" src={iconInfo} alt="info" /> </i> </span></th>
                              <th class="fh-tbl head" scope="col"><span style={{ zIndex: 0 }}>Outgoing Health</span><span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="The phrase “8/10” indicates that 8 files have been received out of expected 10 files." data-pr-position="right" src={iconInfo} alt="info" /> </i> </span></th>
                            </tr>
                          </thead>
                          <tbody>
                            {businessGroupsList[index].partners && businessGroupsList[index].partners.map((partner, index) => (
                              <tr key={index} style={{ fontWeight: 'normal', background: '#fff' }}>
                                <td class="fh-tbl body" style={{borderRadius: '6px 0px 0px 6px'}} scope="row">{index + 1}</td>
                                <td class="fh-tbl body" style={{borderLeft: 'none'}}>{partner.partnerCode}</td>
                                <td class="fh-tbl body" style={{borderLeft: 'none'}}>{partner.partnerName}</td>
                                <td class="fh-tbl body" style={{borderLeft: 'none'}}>{partner.filePattern}</td>
                                <td class="fh-tbl body" style={{borderLeft: 'none', color: percentageClrCalc(partner.percentageHealth), cursor: 'help' }} data-toggle="modal" onClick={(e) => onClickFileNameDisplay(partner.channelId, partner.partnerCode, partner.filecountReceivedIn)} data-target="#channelfilelistingmodel" > <i class="fh-dots" style={{ background: percentageClrCalc(partner.percentageHealth) }}></i> {partner.filecountReceivedIn}/{partner.filecountExpectedIn}</td>
                                {!Number(partner.channelId) == 0 ? <td  style={{ border: '1px solid #eee', borderLeft: 'none', borderRadius: '0px 6px 6px 0px', color: percentageClrCalc(partner.percentageHealthHb), cursor: 'help' }} data-toggle="modal" onClick={(e) => onClickFileNameDisplay(partner.hbchannelid, partner.partnerCode, partner.hbfilecount)} data-target="#channelfilelistingmodel" > <i class="fh-dots" style={{ background: percentageClrCalc(partner.percentageHealthHb) }}></i> {partner.hbfilecount}/{partner.hbexpectedcount}</td>
                                  : <td style={{ border: '1px solid #eee', borderLeft: 'none', borderRadius: '0px 6px 6px 0px'}} data-toggle="modal" data-target="#channelfilelistingmodel" onClick={(e) => onClickFileNameDisplay(partner.hbchannelId, partner.partnerCode, partner.hbfilecount)} >-</td>
                                }
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            // </div>
          )) :<div className="text-center text-danger"><p>No search results found</p></div>} </div> }
        </div>

        {isChannelOnclickPopupEnable ? <div class="col-lg-4 mb-4">
          <div className={`modal fade modal--addnew ${isChannelOnclickPopupEnable ? 'show' : ''}`} id="channelfilelistingmodel" data-backdrop="static" data-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-modal="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="container">
                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '10px' }}>
                    <button type="button" style={{ background: '#F8F8F8', color: 'black', border: 'none', width: '10%' }} className="btn btn-secondary btn-sm" onClick={(e) => isChnlExportVisible ? setIsChnlExportVisible(false) : setIsChnlExportVisible(true)}>
                      <img src={iconExport} alt="Success" />  Export
                    </button>
                    <div>
                      {isChnlExportVisible ? 
                        <div class='fh-legend widget' style={{ top: '40px', right: '78px' }}>
                          <p style={{ color: '#000' }}><strong>Export As</strong></p>
                          <ul>
                            <li style={{ cursor: 'help'}} onClick={(e) => exportClickExcelDownload()}><img src={iconExcel} alt="Success" /> Excel</li>
                          </ul>
                        </div> 
                      : ''}
                    </div>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ marginLeft: '10px' }}>
                      <img src={close_icon} onClick={(e) => setChannelDetails([{}])} alt="Close" />
                    </button>
                  </div>
                    <div style={{height: '500px',overflow: 'scroll',scrollBehavior: 'smooth'}}>
                      <p><strong >Producer: </strong> {channelProducer}</p>
                      <p><strong>Consumer: </strong> {channelConsumer}</p>
                      <p><strong>Channel Name: </strong> {channelName}</p>
                      <table class="table" id="filelistingtable" >
                        <thead>
                          <tr>
                            <th class="fh-tbl head" scope="col">No</th>
                            <th class="fh-tbl head" scope="col" onClick={() => sortBy('fileName',channelDetails)}>File Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                            <th class="fh-tbl head" scope="col" onClick={() => sortBy('status',channelDetails)}>Status <i className="fa fa-sort" aria-hidden="true"></i></th>
                            <th class="fh-tbl head" scope="col" onClick={() => sortBy('createdDate',channelDetails)}>Date <i className="fa fa-sort" aria-hidden="true"></i></th>
                          </tr>
                        </thead>
                        <tbody>
                          {isChannelOnclickPopupEnable && channelDetails.length > 0 ? channelDetails.map((c,index) => (
                              <tr key={index}>
                                <td style={{border:'1px solid #eee',borderRight:'none'}}>{index+1}</td>
                                <td style={{border:'1px solid #eee',borderRight:'none',borderLeft:'none'}}>{c.fileName}</td>
                                <td style={{border:'1px solid #eee',borderRight:'none',borderLeft:'none'}}>{c.status}</td>
                                <td style={{border:'1px solid #eee',borderLeft:'none'}}>{truncateTime(c.createdDate)}</td>
                              </tr>
                            )) : "No channel available"}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : ""}

        {failedFileCntOnclkPopupEnable ? <div class="col-lg-4 mb-4">
          <div className={`modal fade modal--addnew ${failedFileCntOnclkPopupEnable ? 'show' : ''}`} id="failedfilelistingmodel" data-backdrop="static" data-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-modal="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="container">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <img src={close_icon} onClick={(e) => setFailedFileCntOnclkPopupEnable(false)} alt="Close" />
                    </button>
                    <div style={{height: '500px',overflow: 'scroll',scrollBehavior: 'smooth'}}>
                    <table class="table" id="filetiletable">
                      <thead>
                        <tr>
                          <th class="fh-tbl head" scope="col">No</th>
                          <th class="fh-tbl head" scope="col" onClick={() => sortBy('producerCode',failedFiles)}>Partner Code <i className="fa fa-sort" aria-hidden="true"></i></th>
                          <th class="fh-tbl head" scope="col" onClick={() => sortBy('fileName',failedFiles)}>FileName <i className="fa fa-sort" aria-hidden="true"></i></th>
                          <th class="fh-tbl head" scope="col" onClick={() => sortBy('status',failedFiles)}>Status <i className="fa fa-sort" aria-hidden="true"></i></th>
                          <th class="fh-tbl head" scope="col" onClick={() => sortBy('createdDate',failedFiles)}>Date <i className="fa fa-sort" aria-hidden="true"></i></th>
                        </tr>
                      </thead>
                      <tbody>
                         {failedFileCntOnclkPopupEnable && failedFiles.length > 0 ? failedFiles.map((c,index) => (
                            <tr key={index}>
                              <td style={{border:'1px solid #eee',borderRight:'none'}}>{index+1}</td>
                              <td style={{border:'1px solid #eee',borderRight:'none',borderLeft: 'none'}}>{c.producerCode}</td>
                              <td style={{border:'1px solid #eee',borderRight:'none',borderLeft:'none'}}>{c.fileName}</td>
                              <td style={{border:'1px solid #eee',borderRight:'none',borderLeft:'none'}}>{c.status}</td>
                              <td style={{border:'1px solid #eee',borderLeft:'none',borderRadius: '0px 6px 6px 0px'}}>{truncateTime(c.createdDate)}</td>
                            </tr>
                          )) : "No failed files available"}
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : ""}

        {partnerCntOnclkPopupEnable ? <div class="col-lg-4 mb-4">
          <div className={`modal fade modal--addnew ${partnerCntOnclkPopupEnable ? 'show' : ''}`} id="nofilepartnerlistingmodel" data-backdrop="static" data-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-modal="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="container">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <img src={close_icon} onClick={(e) => setPartnerCntOnclkPopupEnable(false)} alt="Close" />
                    </button>
                    <div style={{height: '500px',overflow: 'scroll',scrollBehavior: 'smooth'}}>
                    <table class="table" id="partnertiletable">
                      <thead>
                        <tr>
                          <th class="fh-tbl head" scope="col">No</th>
                          <th class="fh-tbl head" scope="col" onClick={() => sortBy('partnerCode',noFilePartners)}>Partner Code <i className="fa fa-sort" aria-hidden="true"></i></th>
                          <th class="fh-tbl head" scope="col" onClick={() => sortBy('partnerName',noFilePartners)}>Partner Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                        </tr>
                      </thead>
                      <tbody>
                         {partnerCntOnclkPopupEnable && noFilePartners.length > 0 ? noFilePartners.map((c,index) => (
                            <tr key={index}>
                              <td style={{border:'1px solid #eee',borderRight:'none'}}>{index+1}</td>
                              <td style={{border:'1px solid #eee',borderRight:'none',borderLeft: 'none'}}>{c.partnerCode}</td>
                              <td style={{border:'1px solid #eee',borderLeft:'none'}}>{c.partnerName}</td>
                            </tr>
                          )) : "No partner without file available"}
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : ""}

        {noFileChannelOnclkPopupEnable ? <div class="col-lg-4 mb-4">
          <div className={`modal fade modal--addnew ${noFileChannelOnclkPopupEnable ? 'show' : ''}`} id="nofilechannellistingmodel" data-backdrop="static" data-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-modal="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="container">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <img src={close_icon} onClick={(e) => setNoFileChannelOnclkPopupEnable(false)} alt="Close" />
                    </button>
                    <div style={{height: '500px',overflow: 'scroll',scrollBehavior: 'smooth'}}>
                    <table class="table" id="channeltiletable">
                      <thead>
                        <tr>
                          <th class="fh-tbl head" scope="col">No</th>
                          <th class="fh-tbl head" scope="col" onClick={() => sortBy('partnerCode',noFileChannelDetails)}>Partner Code <i className="fa fa-sort" aria-hidden="true"></i></th>
                          <th class="fh-tbl head" scope="col" onClick={() => sortBy('partnerName',noFileChannelDetails)}>Partner Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                          <th class="fh-tbl head" scope="col" onClick={() => sortBy('channelName',noFileChannelDetails)}>Channel Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                          <th class="fh-tbl head" scope="col" onClick={() => sortBy('filePattern',noFileChannelDetails)}>File Pattern <i className="fa fa-sort" aria-hidden="true"></i></th>
                        </tr>
                      </thead>
                      <tbody>
                         {noFileChannelOnclkPopupEnable && noFileChannelDetails.length > 0 ? noFileChannelDetails.map((c,index) => (
                            <tr key={index}>
                              <td style={{border:'1px solid #eee',borderRight:'none'}}>{index+1}</td>
                              <td style={{border:'1px solid #eee',borderRight:'none',borderLeft: 'none'}}>{c.partnerCode}</td>
                              <td style={{border:'1px solid #eee',borderRight:'none',borderLeft:'none'}}>{c.partnerName}</td>
                              <td style={{border:'1px solid #eee',borderRight:'none',borderLeft:'none'}}>{c.channelName}</td>
                              <td style={{border:'1px solid #eee',borderLeft:'none'}}>{c.filePattern}</td>
                            </tr>
                          )) : "No channel available without file"}
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : ""}

        {noFileHbOnclkPopupEnable ? <div class="col-lg-4 mb-4">
          <div className={`modal fade modal--addnew ${noFileHbOnclkPopupEnable ? 'show' : ''}`} id="nofilehblistingmodel" data-backdrop="static" data-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-modal="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="container">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <img src={close_icon} onClick={(e) => setNoFileHbOnclkPopupEnable(false)} alt="Close" />
                    </button>
                    <div style={{height: '500px',overflow: 'scroll',scrollBehavior: 'smooth'}}>
                    <table class="table" id="hbtiletable">
                      <thead>
                        <tr>
                          <th class="fh-tbl head" scope="col">No</th>
                          <th class="fh-tbl head" scope="col" onClick={() => sortBy('partnerCode',noHandBackFilesList)}>Partner Code <i className="fa fa-sort" aria-hidden="true"></i></th>
                          <th class="fh-tbl head" scope="col" onClick={() => sortBy('partnerName',noHandBackFilesList)}>Partner Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                          <th class="fh-tbl head" scope="col" onClick={() => sortBy('filePattern',noHandBackFilesList)}>File Pattern <i className="fa fa-sort" aria-hidden="true"></i></th>
                          <th class="fh-tbl head" scope="col" onClick={() => sortBy('channelName',noHandBackFilesList)}>Channel Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                        </tr>
                      </thead>
                      <tbody>
                         {noFileHbOnclkPopupEnable && noHandBackFilesList.length > 0 ? noHandBackFilesList.map((c,index) => (
                            <tr key={index}>
                              <td style={{border:'1px solid #eee',borderRight:'none'}}>{index+1}</td>
                              <td style={{border:'1px solid #eee',borderRight:'none',borderLeft: 'none'}}>{c.partnerCode}</td>
                              <td style={{border:'1px solid #eee',borderRight:'none',borderLeft:'none'}}>{c.partnerName}</td>
                              <td style={{border:'1px solid #eee',borderRight:'none',borderLeft:'none'}}>{c.filePattern}</td>
                              <td style={{border:'1px solid #eee',borderLeft:'none'}}>{c.channelName}</td>
                            </tr>
                          )) : "No channel available without file"}
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : ""}

      </main>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatchListGroupDetails: (data, onSuccess, onError) =>
    dispatch(listGroupDetails(data, onSuccess, onError)),
  dispatchListChannelDetails: (data, onSuccess, onError) =>
    dispatch(channelFileDetails(data, onSuccess, onError)),
  dispatchListFailedFilesDetails: (data, onSuccess, onError) =>
    dispatch(failedFileDetails(data, onSuccess, onError)),
});

export default connect(null, mapDispatchToProps)(FilehealthDashboardComponent);