export const setFavicon = () => {
    const faviconName = process.env.REACT_APP_FAVICON_NAME;
    if (faviconName) {
      const faviconPath = require(`../assets/images/${faviconName}`).default;
      let link = document.querySelector("link[rel*='icon']");
      if (!link) {
        link = document.createElement('link');
        link.type = 'image/png';
        link.rel = 'shortcut icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = faviconPath;
    } else {
      console.error('Favicon image not found');
    }
  };
  