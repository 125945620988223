import React, { useEffect, useState } from 'react';
import { connect, useStore } from 'react-redux';
import { fetchOneTimeParams } from '../../../redux/actions/auth.action.creators'

import close_icon from '../../../assets/images/icon-close.png';


const FileMappingFunctionZerosComponent = (props, { dispatchOneTimeParams }) => {
  const [fieldEntity, setFieldEntity] = useState({ "field_name": props.selectedFunction.item.map_to, "field_location": props.selectedFunction.item.field_type, "field_length": props.selectedFunction.parameters })
  const [inputErrorWarning, setInputErrorWarning] = useState('')
  const [fieldLength,setFieldLength]=useState(0)


  useEffect(() => {
    // console.log(props.selectedFunction.fileType);
    if(props.selectedFunction.fileType=='flat'){
      setFieldLength(props.selectedFunction.details.field_length)
    }else if ((props.selectedFunction.fileType=='csv')){
      setFieldLength(Number.MAX_VALUE)
    }
    
  }, []);


  const inputFieldValidation = () => {

    setInputErrorWarning({
      "field_length": fieldEntity.field_length === null || fieldEntity.field_length.trim() === "" ? "Enter field length" : !inputFieldLengthValidation(fieldEntity.field_length, 4) ? "Max Length is 9999" : (fieldEntity.field_length)>fieldLength?"The padding is larger than the field length":null,
    })
    if (
      fieldEntity.field_length.trim() !== ""&&
      inputFieldLengthValidation(fieldEntity.field_length, 4)&&
      ((fieldEntity.field_length)<=fieldLength)
    ) {
      return true
    }
    return false
  }

  const inputFieldLengthValidation = (data, requiredLength) => {

    let dataLength = data.length;
    if (dataLength <= requiredLength) {
      return true
    } else return false
  }



  const handlePopUP = (activity) => {
    if (activity == "close") {
      setFieldEntity({ "field_name": props.selectedFunction.item.map_to, "field_length": props.selectedFunction.parameters, "field_location": props.selectedFunction.item.field_type })
      props.setSelectedFunction({ "item": "", "function": "", "details": "", "fileType": "","parameters": "" })
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFieldEntity((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  };


  const handleSubmit = () => {
    if (inputFieldValidation()) {
      const exists = props.allFields.mapping_fields.some(
        (field, index) =>
          field.map_to === fieldEntity.field_name &&
          field.field_type === fieldEntity.field_location &&
          (field.function_name == "" || field.function_name == "zeros")
      )
      if (exists) {
        const updatedFields = props.allFields.mapping_fields.map((field) => {
          if (
            field.map_to === fieldEntity.field_name &&
            field.field_type === fieldEntity.field_location &&
            (field.function_name === "" || field.function_name === "zeros")
          ) {
            return {
              ...field,
              function_name: "zeros",
              replace: fieldEntity.field_length,
            };
          }
          return field;
        });
        props.setAllFields((prevState) => ({
          ...prevState,
          mapping_fields: updatedFields,
        }));
      }
      else {
        const newEntity = {
          field_type: fieldEntity.field_location,
          map_to: fieldEntity.field_name,
          function_params: "",
          replace: fieldEntity.field_length,
          pattern: "",
          function_name: "zeros"
        };
        const newMappingFields = [...props.allFields.mapping_fields, newEntity];
        props.setAllFields((prevFields) => ({ ...prevFields, mapping_fields: newMappingFields }));

      }
      setFieldEntity({
        field_name: props.selectedFunction.item.map_to,
        field_length: props.selectedFunction.parameters,
        field_location: props.selectedFunction.item.field_type,
      });
      handlePopUP("close")
    }
  }

  return (
    <React.Fragment>
      <div className={`modal fade modal--addnew ${props.selectedFunction.function == "zeros" ? 'show' : ''}`} aria-modal="true" data-toggle="modal" style={{ display: props.selectedFunction.function == "zeros" ? 'block' : 'none' }} id="addField" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Zero Padding Function</h5>

              <button type="button" onClick={(() => { handlePopUP("close") })} class="close" data-dismiss="modal" aria-label="Close">
                <img src={close_icon} alt="Close" />
              </button>
            </div>

            <div class="modal-body ">
              <p>This function is to apply zero padding to fields </p>
              <div class="form mb-5" >
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label for="pc" class="input-label">Destination field name<span style={{ color: 'red' }}>*</span></label>
                      <input disabled="disabled" className={`txt ${inputErrorWarning.field_name ? 'is-invalid' : ''}`} value={fieldEntity.field_name} name="field_name" type="text" placeholder="Field Name" id="pc" />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label for="cc" class="input-label">Padding length<span style={{ color: 'red' }}>*</span></label>
                      <input className={`txt ${inputErrorWarning.field_length ? 'is-invalid' : ''}`} value={fieldEntity.field_length} type="number" name="field_length" min={0} step={1} onChange={handleInputChange} placeholder="Field Length" id="cc" />
                    </div>
                    {inputErrorWarning.field_length ?
                      (<div className="error-message">{inputErrorWarning.field_length}</div>)
                      : ('')}
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" onClick={(() => { handlePopUP("close") })} class="button button--outline button--sm" data-dismiss="modal">Cancel</button>
                  <button type="button" onClick={(() => { handleSubmit() })} data-dismiss="modal" class="button button--sm">Save</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {(props.selectedFunction.function == "zeros" ? true : false) && <div className="modal-backdrop fade show"></div>}
    </React.Fragment >
  );

}

const mapDispatchToProps = dispatch => ({
  dispatchOneTimeParams: (onSuccess, onError) =>
    dispatch(fetchOneTimeParams({ "keyList": ["preferred_mac", "preferred_cipher", "compression"] }, onSuccess, onError))
})

export default connect(null, mapDispatchToProps)(FileMappingFunctionZerosComponent);

