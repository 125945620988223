import React from 'react';
const appConf = require(process.env.REACT_APP_CONFIG_URL);

const Footer = (props,{ isLoggedIn, writeHelpPopup, helpPopup}) => {
    const handleHelpSupportClick = (e) => {
        e.preventDefault();
        props.writeHelpPopup(props.helpPopup?false:true)
        window.scrollTo(0, 0);
    };

    return(
    props.isLoggedIn ? 
    <React.Fragment>
        <footer className="footer">
            <div className="container d-md-flex justify-content-md-between">
                <div className="footer__copy">{appConf.constants.footerText}</div>
                <div className="footer__link">
                <a href="#" onClick={(e) => handleHelpSupportClick(e)}>Help and Support</a>
                <a href="/tnc">Terms of Use</a>
                </div>
            </div>
        </footer>
    </React.Fragment> : ''
    );
}

export default Footer;