

export const DownloadFileAsXLFromEncodedString = (encodedString, outputfileName) => {
   const linkSource = `data:application/xls;base64,${encodedString}`;
   const downloadLink = document.createElement("a");
   const d = new Date();
   const localDateString = d.toLocaleDateString();
   const fileName = outputfileName + localDateString + ".xlsx";
   downloadLink.href = linkSource;
   downloadLink.download = fileName;
   downloadLink.click();
}

