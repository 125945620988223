import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { newUserPasswordSetup } from '../redux/actions/auth.action.creators'

import AlertFailure from '../utils/alert-failure.component';
import AlertSuccess from '../utils/alert-success.component';
var appConf = require(process.env.REACT_APP_CONFIG_URL);
const logoName = appConf.constants.logoName;
const companyLogo  = require(`../assets/images/${logoName}`).default
var CryptoJS = require("crypto-js");


const InitialPassowordSetup = ({ dispatchInitialPasswordAction }) => {

    const [userInputData, setUserInputData] = useState('')
    const [popupVisibility, setPopupVisibility] = useState({ success: false, failed: false, message: null })
    const [errorToolTip, setErrorToolTip] = useState({ password: null, confirmPassword: null })
    const [request, setRequest] = useState('')
    const companyCode = appConf.switch.companyCode;
    const logoClassName = "logo_" + companyCode.toLowerCase();


	useEffect(() => {
        let decryptedToken = "";
        let token = new URLSearchParams(window.location.search).get('token');
        if (token) {
            try {
                const bytes = CryptoJS.AES.decrypt(token, CryptoJS.enc.Utf8.parse(appConf.switch.aesKey), {
                    mode: CryptoJS.mode.ECB,
                    padding: CryptoJS.pad.Pkcs7
                });
                decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
            } catch (e) {
                console.log(e);
            };
        }
        setRequest(decryptedToken);
    }, [])

    const onSubmit = (event) => {
        event.preventDefault();
        if (errorToolTip.isValidated) {
            var jsonData = { "createdTime": request.createdTime, "userId": request.userId, "email": request.email, "password": userInputData.password }
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData), CryptoJS.enc.Utf8.parse(appConf.switch.aesKey), {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            }).toString();
            dispatchInitialPasswordAction(encryptedData, ({ data, message, description }) => {
                if (data) {
                    enablePopup(true, data === "SUCCESS" ? "Password Added Successfully !" : message)
                }
            }, (message) => {
                enablePopup(false, message)
            });
        }

    }

    const passwordRegExpValidation = (pass_data) => {
        if (pass_data === null || pass_data === "") {
            setErrorToolTip({ password: "Enter new password", confirmPassword: errorToolTip.confirmPassword })
            return false;
        }
        else if (!new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(pass_data)) {
            setErrorToolTip({ password: "Invalid password pattern", confirmPassword: errorToolTip.confirmPassword })
            return false;
        } else {
            setErrorToolTip({ password: null, confirmPassword: errorToolTip.confirmPassword })
            return true
        }
    }

    const confirmPasswordValidation = (c_pass_data) => {
        console.log(c_pass_data);
        if (c_pass_data === null || c_pass_data === "") {
            setErrorToolTip({ password: null, confirmPassword: "Re-enter new password" })
            return false;
        }
        else if (userInputData.password !== c_pass_data) {
            setErrorToolTip({ password: null, confirmPassword: "Please ensure passwords match" })
            return false;
        } else {
            setErrorToolTip({ isValidated: true })
            return true;
        }
    }

    const enablePopup = (ctgry, msg) => {
        if (ctgry) {
            setPopupVisibility({ success: true, failed: false, message: msg })
            setTimeout(() => {
                setPopupVisibility({ success: false, failed: false, message: null })
            }, 3000);
        } else {
            setPopupVisibility({ success: false, failed: true, message: msg })
            setTimeout(() => {
                setPopupVisibility({ success: false, failed: false, message: null })
            }, 3000);
        }
    }

    return (
        <React.Fragment>
            <main>
                <header className="header">
                    <div className="container">
                        <div className="header__top">
                            <div className={logoClassName}>
                                <Link to="/dashboard"> <a className="navbar-brand" href="#"><img src={companyLogo} alt="Company Logo" /> </a></Link>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container">
                    {popupVisibility.failed ?
                        <AlertFailure message={popupVisibility.message} /> : ''}
                    {popupVisibility.success ?
                        <AlertSuccess message={popupVisibility.message} /> : ''}
                </div>
                <div className="password password--change">
                    <h1>Setup Your Password</h1>
                    <form noValidate onSubmit={onSubmit}>
                        <label className="label">Enter Password</label>
                        <div className="input-wrap txt-wrap">
                            <input value={userInputData.password} onChange={(e) => { setUserInputData({ password: e.target.value, confirmpassword: userInputData.confirmpassword }); passwordRegExpValidation(e.target.value) }}
                                type="password" className='txt' required />
                            <div className="policy">
                                <ul>
                                    <li>Minimum password length: 8 </li>
                                    <li> Maximum password length: 20</li>
                                    <li>The password should contain atleast 1 number</li>
                                    <li> The password should contain atleast 1 capital letter</li>
                                    <li> The password should contain atleast 1 small letter</li>
                                    <li> The password should contain atleast 1 special character</li>
                                </ul>
                            </div>
                            {errorToolTip.password ?
                                (<div className="error-message">{errorToolTip.password}</div>)
                                : ('')}

                        </div>
                        <label className="label">Re-Enter Password</label>
                        <div className="input-wrap txt-wrap">
                            <input value={userInputData.confirmpassword} onChange={(e) => { setUserInputData({ password: userInputData.password, confirmpassword: e.target.value }); confirmPasswordValidation(e.target.value) }}
                                type="password" className='txt' required />
                            {errorToolTip.confirmPassword ?
                                (<div className="error-message">{errorToolTip.confirmPassword}</div>)
                                : ('')}
                        </div>
                        <div className="btn-wrap">
                            <button type="submit" className="button">Save Password</button>
                        </div>
                    </form>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Link to="/auth"><a className="breadcrumb__link" style={{ color:"blue"}} href="/auth">Click here to Login</a></Link>
                    </div>
                </div>
            </main>
        </React.Fragment>
    );
};


const mapDispatchToProps = dispatch => ({
    dispatchInitialPasswordAction: (data, onSuccess, onError) =>
        dispatch(newUserPasswordSetup({ token: data }, onSuccess, onError))
});

export default connect(null, mapDispatchToProps)(InitialPassowordSetup);