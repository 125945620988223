import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import iconEdit from '../assets/images/icon-edit.png';
import iconDelete from '../assets/images/icon-delete.png';
import sort_icon from '../assets/images/icon-sort.png';
import close_icon from '../assets/images/icon-close.png';
import _ from "lodash";
import { Tooltip } from 'primereact/tooltip';
import iconInfo from '../assets/images/icon-info-round.png';
import { fetchOneTimeParams, listPartners, createGroup, listGroups, modifyGroup, deleteGroup, isAdmin, isOperationalUser, isPartnerAdmin } from '../redux/actions/auth.action.creators';
import AlertFailure from '../utils/alert-failure.component'
import AlertSuccess from '../utils/alert-success.component';
import { confirmDialog } from 'primereact/confirmdialog';
import { AppendingDotsToSuffix } from "../utils/InputDataCustomization";
import * as constants from '../redux/constants';
import {sanitizedInput} from '../utils/stringUtils'
import iconExport from '../assets/images/icon-export.png';
import iconExcel from '../assets/images/icon-excel.png';
import { DownloadListAsExcel } from '../utils/list-export-excel';

const GroupComponent = ({ dispatchListPartnersAction, dispatchCreateGroupAction, dispatchListGroupsAction,
    dispatchModifyGroupAction, dispatchDeleteGroupAction, dispatchGroupNotificationTypes }) => {

    const [partnerList, setPartnerList] = useState([]);
    const [selParsArray, setSelParsArray] = useState([]);
    const [allParsArray, setAllParsArray] = useState([]);
    const [filteredSelPars, setFilteredSelPars] = useState([]);
    const [filteredAllPars, setFilteredAllPars] = useState([]);
    const [selParsSearchKey, setSelParsSearchKey] = useState('');
    const [allParsSearchKey, setAllParsSearchKey] = useState('');
    const [sortDirection, setSortDirection] = useState({ "selPars": '', "allPars": '', "grpName": '', "grpDesc": '', "grpReport": '' });

    const [groupName, setGroupName] = useState('');
    const [groupDesc, setGroupDesc] = useState('');
    const [groupCode, setGroupCode] = useState('');
    const [reportingGroup, setReportingGroup] = useState(false);
    const [monitorFileHealth, setMonitorFileHealth] = useState(false);
    const [groupPartners, setGroupPartners] = useState([]);
    const [errorConst, setErrorConst] = useState({});
    const [failMsg, setFailMsg] = useState('');
    const [succMsg, setSuccMsg] = useState('');
    const [mode, setMode] = useState("create");

    const [groupList, setGroupList] = useState({ "loading": true, "data": [] });
    const [filteredGroupList, setFilteredGroupList] = useState([]);
    const [groupSearchKey, setGroupSearchKey] = useState('');
    const [isPartnerListingPopupEnable, setIsPartnerListingPopupEnable] = useState(false)
    const [partnerListForModel, setPartnerListForModel] = useState([])

    const [groupNotificationTypes, setGroupNotificationTypes] = useState([]);
    let groupNotificationModel = { "notification_type": "", "cc_email": "", "to_email": "" };
    const [groupNotificationData, setGroupNotificationData] = useState([groupNotificationModel]);
    const [selectedNotificationTypes, setSelectedNotificationTypes] = useState([]);
    const [activeAccordianIndexList, setActiveAccordianIndexList] = useState([])
    var typeList = [];
    const [isExportVisible, setIsExportVisible] = useState(false);

    let removeFileStructureWidgets = (i, e) => {
        e.preventDefault();
        let newWidgetElements = [...groupNotificationData];
        var deletedWidget = newWidgetElements.splice(i, 1);
        let temp = groupNotificationData.filter((val) =>
            val['notification_type'] === deletedWidget[0]['notification_type'] ? '' : val
        );
        setGroupNotificationData(temp);
    };

    const accordianEvent = (index) => {
        var indexList = [...activeAccordianIndexList]
        if (indexList.includes(index)) {
            var ind = indexList.indexOf(index)
            indexList.splice(ind, 1)
        } else {
            indexList.push(index)
        }
        setActiveAccordianIndexList(indexList)
    }

    function updateGroupNotificationData(index, category, value) {
        let temp = _.cloneDeep(groupNotificationData);
        if (category === "notification_type") {
            const selectedTypeCount = selectedNotificationTypes.filter(type => type === value).length;
            if (selectedTypeCount >= 1) {
                temp[index].notification_type = '';
                selectedNotificationTypes.splice(index, 1);
            } else {
                temp[index].notification_type = value;
                selectedNotificationTypes[index] = value;
            }
        } else if (category === "to_email") {
            temp[index].to_email = value;
        } else if (category === "cc_email") {
            temp[index].cc_email = value;
        }
        setGroupNotificationData(temp);
    }

    const addNewItem = () => {
        if (groupNotificationData.length < 2) {
            let temp = _.cloneDeep(groupNotificationData);
            temp.push(groupNotificationModel)
            setGroupNotificationData(temp);
        }
    };

    const addFileStructureWidgets = () => {
        return (
            <div>
                <div className="btn-wrap text-right">
                    <a className="link" href="javascript:void(0)" onClick={() => addNewItem()}>
                        <i className="fa fa-plus mr-2" aria-hidden="true"></i>Add Item</a>
                </div>
                {groupNotificationData && groupNotificationData.map((element, index) => (
                    <div>
                        <div class="accordian__item">
                            <div className={`accordian__head ${activeAccordianIndexList.includes(index) ? "active" : ""}`}>
                                <strong onClick={() => accordianEvent(index)}>
                                    Notification : {index + 1}
                                </strong>
                                <div class="dropdown">
                                    <a class="" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        <span class="sr-only">ellipsis</span>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                        <a class="dropdown-item link" href="#" onClick={(e) => removeFileStructureWidgets(index, e)}>
                                            <i class="fa fa-minus mr-2" aria-hidden="true"></i> Remove</a>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="accordian__content"
                                style={{ display: activeAccordianIndexList.includes(index) ? "block" : "none", }}>
                                <div class="tree-strecture">
                                    <div>
                                        <div className="col-lg-12 col-md-12" key={index}>
                                            <div className="form-group">
                                                <div className="row" key={index}>
                                                    <div className="col-lg-6 col-md-6">
                                                        <label for="f1" className="input-label">{" "}Notification type<span style={{ color: "red" }}>*</span></label>
                                                        <div className="input-wrap select-wrap">
                                                            <select id="f12" name="notification_type" value={element.notification_type} onChange={(e) => updateGroupNotificationData(index, "notification_type", e.target.value)}>
                                                                <option value="">Choose Any</option>
                                                                {groupNotificationTypes.length === 0 ? "" : groupNotificationTypes.map((val, i) => (
                                                                    <option key={i} value={val}> {val} </option>))}
                                                            </select>
                                                            {errorConst.groupNotificationData && errorConst.groupNotificationData[index].notification_type ? (
                                                                <div className="error-message"> {errorConst.groupNotificationData[index].notification_type}</div>) : ("")}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <label htmlFor="to_email" className="input-label">To </label>
                                                        <input className="txt" type="text" name="to_email" value={element.to_email} placeholder="Enter email" id={`to_email_${index}`}
                                                            onChange={(e) => updateGroupNotificationData(index, "to_email", e.target.value)} />
                                                        {errorConst.groupNotificationData && errorConst.groupNotificationData[index].to_email ? (
                                                            <div className="error-message">{errorConst.groupNotificationData[index].to_email}</div>) : ""}
                                                    </div>
                                                </div>
                                                <div className="row" key={index}>
                                                    <div className="col-lg-6 col-md-6"></div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <label htmlFor="cc_email" className="input-label">Cc  </label>
                                                        <input className="txt" type="text" name="cc_email" value={element.cc_email} placeholder="Enter email" id={`cc_email_${index}`}
                                                            onChange={(e) => updateGroupNotificationData(index, "cc_email", e.target.value)} />
                                                        {errorConst.groupNotificationData && errorConst.groupNotificationData[index].cc_email ? (
                                                            <div className="error-message">{errorConst.groupNotificationData[index].cc_email}</div>) : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    useEffect(() => {
        dispatchGroupNotificationTypes({ "keyList": [constants.GROUP_NOTIFICATION_TYPES] }, ({ data, message }) => {
            if (data) {
                setGroupNotificationTypes(data.group_notification_types[0].value.split(","));
            }
        }, (message) => {
            console.log(message);
        });
    }, [])

    useEffect(() => {
        const tempPartnerList = [];
        dispatchListPartnersAction(({ data, message }) => {
            if (data) {
                loadGroups();
                data.forEach((p) => {
                    if (p.status == 'A') {
                        tempPartnerList.push({
                            "partnerID": p.partnerid,
                            "partnerCode": p.partnercode,
                            "partnerName": p.partnername,
                            "category": "allPartners",
                            "selected": false
                        })
                    }
                });
                setPartnerList(tempPartnerList);
            }
        },
            (message) => {
                console.log(message);
            }
        );
    }, [dispatchListPartnersAction]);

    function loadGroups() {
        dispatchListGroupsAction(({ data, message }) => {
            if (data) {
                let tempList = [];
                data.forEach(val => {
                    if (val && val.groupStatus === "A") {
                        tempList.push(val);
                    }
                });
                setGroupList({ "loading": false, "data": tempList });
                setFilteredGroupList(tempList);
            }
        },
            (message) => {
                console.log(message);
            }
        );
    }

    useEffect(() => {
        let selParsTemp = [];
        let allParsTemp = [];
        partnerList.forEach(partner => {
            if (partner.category === "allPartners") {
                allParsTemp.push(partner);
            } else {
                selParsTemp.push(partner);
            }
        });
        setAllParsArray(allParsTemp);
        setSelParsArray(selParsTemp);
        filterSelPartners(selParsTemp);
        filterAllPartners(allParsTemp);

        if ((allParsSearchKey === '') || (allParsSearchKey == null)) {
            setFilteredAllPars(allParsTemp);
        } else if ((selParsSearchKey === '') || (selParsSearchKey == null)) {
            setFilteredSelPars(selParsTemp);
        } else {
            filterAllPartners(allParsTemp);
            filterSelPartners(selParsTemp);
        }
    }, [partnerList])

    useEffect(() => {
        filterAllPartners(allParsArray);
    }, [allParsSearchKey])

    function filterAllPartners(allParsTemp) {
        let filtered = [];
        filtered = allParsTemp.filter(p => {
            return (
                p
                    .partnerCode
                    .toLowerCase()
                    .includes(allParsSearchKey.toLowerCase())
            )
        });
        setFilteredAllPars(filtered);
    }

    useEffect(() => {
        filterSelPartners(selParsArray)
    }, [selParsSearchKey])

    function filterSelPartners(selParsTemp) {
        let filtered = [];
        filtered = selParsTemp
            .filter(p => {
                return (
                    p
                        .partnerCode
                        .toLowerCase()
                        .includes(selParsSearchKey.toLowerCase())
                )
            });
        setFilteredSelPars(filtered);
    }

    useEffect(() => {
        let filtered = _.cloneDeep(filteredGroupList);
        if (!groupList.loading) {
            filtered = groupList.data.filter(p => {
                let partnerStr = '';
                p.partners.map((partner, index) => {
                    partnerStr = `${partnerStr} ${partnerList.filter(p => (p.partnerID === partner.partnerId)).map(m => m.partnerCode)}`
                });
                return (p.groupName.toLowerCase().includes(groupSearchKey.toLowerCase())
                    || p.groupDescription.toLowerCase().includes(groupSearchKey.toLowerCase())
                    || partnerStr.toLowerCase().includes(groupSearchKey.toLowerCase()))
            });
        }
        setFilteredGroupList(filtered);
    }, [groupSearchKey])

    function addSelected(e, partnerID) {
        e.preventDefault();
        let tempPartnerList = partnerList.filter((p) => {
            if (p.partnerID === partnerID) {
                p.selected = !p.selected;
            }
            return p;
        })
        setPartnerList(tempPartnerList);
    }

    function onDragOver(e) {
        e.preventDefault();
    }

    function onDragStart(e) {
        let partnerIDList = [];
        partnerList.filter((p) => {
            if (p.selected) {
                partnerIDList.push(p.partnerID);
            }
        });
        e.dataTransfer.setData("partnerIDList", partnerIDList);
    }

    function onDrop(e, category) {
        let partnerIDList = e.dataTransfer.getData("partnerIDList").split(",");
        let list = partnerList.filter((p) => {
            if (partnerIDList.includes(p.partnerID.toString())) {
                p.category = category;
                p.selected = !p.selected;
            }
            return p;
        })
        setPartnerList(list);
    }

    function sortFn(dir, sortArr, type) {
        let result = sortArr.sort((a, b) => {
            if (a[type] < b[type]) {
                return dir === "asc" ? -1 : 1;
            }
            if (a[type] > b[type]) {
                return dir === "asc" ? 1 : -1;
            }
            return 0;
        });
        return result;
    }

    function onSort(type) {
        let dir = "asc";
        if (sortDirection[type] === "asc") {
            dir = "desc";
        }
        if (type === 'selPars') {
            setSelParsArray(sortFn(dir, selParsArray, "partnerCode"));
            setFilteredSelPars(sortFn(dir, filteredSelPars, "partnerCode"));
            setSortDirection({ "selPars": dir, "allPars": sortDirection.allPars, "grpName": sortDirection.grpName, "grpDesc": sortDirection.grpDesc, "grpReport": sortDirection.grpReport });
        } else if (type === 'allPars') {
            setAllParsArray(sortFn(dir, allParsArray, "partnerCode"));
            setFilteredAllPars(sortFn(dir, filteredAllPars, "partnerCode"));
            setSortDirection({ "selPars": sortDirection.selPars, "allPars": dir, "grpName": sortDirection.grpName, "grpDesc": sortDirection.grpDesc, "grpReport": sortDirection.grpReport });
        } else if (type === 'grpName') {
            setGroupList({ "loading": false, "data": sortFn(dir, groupList.data, "groupName") });
            setFilteredGroupList(sortFn(dir, filteredGroupList, "groupName"));
            setSortDirection({ "selPars": sortDirection.selPars, "allPars": sortDirection.allPars, "grpName": dir, "grpDesc": sortDirection.grpDesc, "grpReport": sortDirection.grpReport });
        } else if (type === 'grpDesc') {
            setGroupList({ "loading": false, "data": sortFn(dir, groupList.data, "groupDescription") });
            setFilteredGroupList(sortFn(dir, filteredGroupList, "groupDescription"));
            setSortDirection({ "selPars": sortDirection.selPars, "allPars": sortDirection.allPars, "grpName": sortDirection.grpName, "grpDesc": dir, "grpReport": sortDirection.grpReport });
        } else if (type == 'grpReport') {
            setGroupList({ "loading": false, "data": sortFn(dir, groupList.data, "reportingGroup") });
            setFilteredGroupList(sortFn(dir, filteredGroupList, "groupReporting"));
            setSortDirection({ "selPars": sortDirection.selPars, "allPars": sortDirection.allPars, "grpName": sortDirection.grpName, "grpDesc": sortDirection.grpDesc, "grpReport": dir });
        }
    }

    function onSave(event) {
        event.preventDefault();
        if (isFormValid()) {
            if (mode === "create") {
                let partners = [];
                selParsArray.forEach(val => {
                    partners.push({
                        "groupPartnerID": 0,
                        "operationFlag": "I",
                        "partnerID": val.partnerID
                    })
                })
                console.log("groupNotificationData sent to create request : ", groupNotificationData);
                let request = {
                    "groupCode": 0,
                    "groupDescription": groupDesc,
                    "groupName": groupName,
                    "partners": partners,
                    "reportingGroup": reportingGroup,
                    "monitorFileHealth": monitorFileHealth,
                    "groupNotificationData": groupNotificationData
                }
                dispatchCreateGroupAction(request, ({ data, message }) => {
                    setSuccMsg("Group created successfully");
                    resetForm();
                    setTimeout(() => {
                        setSuccMsg('')
                    }, 3000);
                },
                    (message) => {
                        window.scrollTo(0, 0);
                        setFailMsg(message);
                        setTimeout(() => {
                            setFailMsg('')
                        }, 3000);
                    });
            } else {
                let partArrGroup = [];
                let partArrSel = [];
                let partners = [];
                groupPartners.forEach(val => {
                    partArrGroup.push(val.partnerId);
                })
                selParsArray.forEach(val => {
                    partArrSel.push(val.partnerID);
                });
                groupPartners.filter(g => {
                    if (!(partArrSel.indexOf(g.partnerId) > -1)) {
                        partners.push({
                            "groupPartnerID": g.groupDetailId,
                            "operationFlag": "D",
                            "partnerID": g.partnerId
                        })
                    } else {
                        partners.push({
                            "groupPartnerID": g.groupDetailId,
                            "operationFlag": "M",
                            "partnerID": g.partnerId
                        })
                    }
                });
                selParsArray.filter(s => {
                    if (!(partArrGroup.indexOf(s.partnerID) > -1)) {
                        partners.push({
                            "groupPartnerID": 0,
                            "operationFlag": "I",
                            "partnerID": s.partnerID
                        })
                    } else {
                        partners.push({
                            "groupPartnerID": 0,
                            "operationFlag": "M",
                            "partnerID": s.partnerID
                        })
                    }
                })
                console.log(partners);
                console.log("groupNotificationData sent to modify request : ", groupNotificationData);
                let request = {
                    "groupCode": groupCode,
                    "groupDescription": groupDesc,
                    "groupName": groupName,
                    "groupStatus": "A",
                    "partners": partners,
                    "reportingGroup": reportingGroup,
                    "monitorFileHealth": monitorFileHealth,
                    "groupNotificationData": groupNotificationData
                }
                dispatchModifyGroupAction(request, ({ data, message }) => {
                    setSuccMsg("Group updated successfully");
                    resetForm();
                    setTimeout(() => {
                        setSuccMsg('')
                    }, 3000);
                },
                    (message) => {
                        window.scrollTo(0, 0);
                        setFailMsg(message);
                        setTimeout(() => {
                            setFailMsg('')
                        }, 3000);
                    });
            }
        }
    }
    const multiEmailvalidation = (emails) => {
        if (emails && !emails == '') {
            emails.trim()
            var s = emails.lastIndexOf(';');
            var emailArray = emails.split(';'); var resultArray = [];
            emailArray.forEach(email => {
                if (!new RegExp(/^(\d{9}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4}))$/).test(email)) {
                    resultArray.push(email)
                }
            });
            if (resultArray.length >= 1) {
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const notifyTypevalidation = (type) => {
        if (typeList && typeList.includes(type)) {
            typeList.push(type);
            return false;
        }
        typeList.push(type);
        return true;
    };

    const isFormValid = () => {
        const tempError = _.cloneDeep(errorConst);
        let tempData = _.cloneDeep(groupNotificationData);
        typeList = [];

        tempError.groupName = !groupName ? "Enter group name" : !sanitizedInput(groupName,"groupName") ? "Invalid characters found!":"";
        tempError.groupDesc = !groupDesc ? "Enter group description" : !sanitizedInput(groupDesc,"groupDesc") ? "Invalid characters found!":"";
        tempError.partners = (selParsArray.length === 0) ? "Add atleast 1 partner" : '';
        const validatedGroupNotificationData = tempData.map(item => {
            let newItem = {};
            newItem.notification_type = !notifyTypevalidation(item.notification_type) ? "Please check notification types selected" : '';
            newItem.to_email = !multiEmailvalidation(item.to_email) ? "Incorrect email format" : '';
            newItem.cc_email = !multiEmailvalidation(item.cc_email) ? "Incorrect email format" : '';
            return newItem;
        });
        tempError.groupNotificationData = validatedGroupNotificationData;
        setErrorConst(tempError);

        let temp = tempError.groupNotificationData && tempError.groupNotificationData.filter(e => {
            if ((e.to_email != '') || (e.cc_email != '') || (e.notification_type != '')) {
                return e;
            }
        })
        return (!tempError.groupName && !tempError.groupDesc && !tempError.partners && !temp.length > 0);
    };

    const resetForm = () => {

        window.scrollTo(0, 0);
        setGroupName('');
        setGroupDesc('');
        setSelParsSearchKey('');
        setReportingGroup(false)
        setMonitorFileHealth(false)
        setAllParsSearchKey('');
        let list = [];
        partnerList.forEach(p => {
            p.category = "allPartners";
            p.selected = false;
            list.push(p);
        });
        setPartnerList(list);
        setErrorConst({});
        setGroupNotificationData('');
        loadGroups();
        setMode("create")
    }

    function onEditClick(group) {
        window.scrollTo(0, 0);
        setMode("edit");
        let list_1 = [];
        partnerList.forEach(p => {
            p.category = "allPartners";
            p.selected = false;
            list_1.push(p);
        });
        console.log("Edit", group)
        setGroupName(group.groupName);
        setReportingGroup(group.reportingGroup)
        setMonitorFileHealth(group.monitorFileHealth)
        setGroupDesc(group.groupDescription);
        setGroupCode(group.groupCode);
        setGroupPartners(group.partners);
        let list = partnerList.filter((p) => {
            group.partners.map(val => {
                if (val.partnerId === p.partnerID) {
                    p.category = "selectedPartners";
                }
            })
            return p;
        })
        setPartnerList(list);
        const tempList = [];
        group.groupNotificationData.forEach(p => {
            let temp = {};
            temp.notification_type = p.groupNotifyType;
            temp.to_email = p.groupEmails;
            temp.cc_email = p.groupEmailsCc;
            tempList.push(temp);
        });
        setGroupNotificationData(tempList);
    }

    const deleteConfirm = (gCode, gName) => {

        confirmDialog({
            message: 'Do you want to delete ' + gName + '?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept() {
                dispatchDeleteGroupAction(gCode, ({ data, message }) => {
                    resetForm();
                    setSuccMsg("Group deleted successfully");
                    setTimeout(() => {
                        setSuccMsg('')
                    }, 3000);
                }, (message) => {
                    window.scrollTo(0, 0);
                    setFailMsg(message);
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                });
            },
            reject() {

            },
            acceptLabel: "Confirm",
            rejectLabel: "Cancel"
        });
    };

    function customText(partnerGroup, limit) {
        var temp = partnerGroup.map((partner, index) => ((partnerList.filter(p => (p.partnerID === partner.partnerId)).map(m => m.partnerCode))))
        var objectString = JSON.stringify(temp);
        objectString = objectString.replaceAll("[", "").replaceAll("]", "").replaceAll("\"", "").replaceAll(",,", ",")
        return AppendingDotsToSuffix(objectString, limit);
    }

    function enablePartnerListModel(partnerString) {
        setIsPartnerListingPopupEnable(false)
        const arr = partnerString.split(',');
        setPartnerListForModel(arr)
        setIsPartnerListingPopupEnable(true)
    }

    const handleCheckboxChange = () => {
        setReportingGroup(!reportingGroup);
        setMonitorFileHealth(true);
    };

    function exportClickExcelDownload() {
        var modifiedList =[{}];
        filteredGroupList.forEach(element => {
            let temp ={};
            temp.group_name=element.groupName;
            temp.group_description=element.groupDescription;
            temp.group_status=element.groupStatus;
            let partnerStr = '';
            partnerStr=customText(element.partners, 10000);
            temp.partners=partnerStr;
            let notificationStr = '';
            if(element.reportingGroup){
                element.groupNotificationData.forEach(e => {
                    let tempStr = '';
                    tempStr += (e.groupEmails ? e.groupEmails : '');
                    tempStr += '/';
                    tempStr += (e.groupEmailsCc ? e.groupEmailsCc : '');
                    notificationStr += tempStr;
                });
            }
            temp.contact_email=notificationStr;
            temp.reporting_group=element.reportingGroup ? "Yes" : "No";
            modifiedList.push(temp);
        });
        DownloadListAsExcel(modifiedList,'ListOfGroups');
    }

    return (
        <React.Fragment>
            <main>
                <div className="container">
                    <ul className="breadcrumb">
                        <li className="breadcrumb__item">
                            <span className="breadcrumb__link">Manage Partner</span>
                        </li>
                        <li className="breadcrumb__item">
                            <span className="breadcrumb__link">Groups</span>
                        </li>
                    </ul>
                    <div className="title">
                        <h1>Groups
                            <span style={{ paddingLeft: "5px" }}> <Tooltip target=".tosssolTip" mouseTrack style={{ maxWidth: "75rem" }} mouseTrackLeft={10} /> <i> <img className="tosssolTip" data-pr-tooltip="In the Groups screen, you have the ability to group partner profiles based on common requirements. You can create a new group or modify an existing group to add or remove the partners profiles. A partner can be a member of multiple groups" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                        </h1>

                    </div>

                    {failMsg ?
                        <AlertFailure message={failMsg} /> : ''}
                    {succMsg ?
                        <AlertSuccess message={succMsg} /> : ''}
                </div>
                {
                    (isAdmin() || isPartnerAdmin()) && !isOperationalUser() ?
                        (
                            <div className="grey-block">
                                <div className="container">
                                    <div className="form mb-5">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="f1" className="input-label"> Group Name<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-wrap txt-wrap">
                                                        <input autoComplete="off" type="text" placeholder="Enter group name" id="f1"
                                                            value={groupName} onChange={(e) => setGroupName(e.target.value.replace(" ", "_").toLowerCase())}
                                                            className={`txt ${errorConst.groupName ? 'is-invalid' : ''}`} required />
                                                        {errorConst.groupName ?
                                                            (<div className="error-message">{errorConst.groupName}</div>)
                                                            : ('')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="f2" className="d-block input-label">Group Description<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-wrap txt-wrap">
                                                        <input autoComplete="off" type="text" placeholder="Enter group description" id="f2"
                                                            value={groupDesc} onChange={(e) => setGroupDesc(e.target.value)}
                                                            className={`txt ${errorConst.groupDesc ? 'is-invalid' : ''}`} required />
                                                        {errorConst.groupDesc ?
                                                            (<div className="error-message">{errorConst.groupDesc}</div>)
                                                            : ('')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="group">
                                        <div className="form-row">
                                            <div className="col-lg-6 col-md-12" >
                                                <div className={`widget widget--partner ${errorConst.partners ? 'is-invalid' : ''}`}>
                                                    <div className="head"><h2>Selected Partners</h2></div>
                                                    <div className="input-wrap txt-wrap">
                                                        <input autoComplete="off" type="text" className="txt" placeholder="Search..."
                                                            value={selParsSearchKey} onChange={(e) => setSelParsSearchKey(e.target.value)} />
                                                        <i className="fa fa-search partnersGroup"></i>
                                                    </div>
                                                    <div className="widget--partner__title" onClick={() => onSort('selPars')} style={{ cursor: "pointer" }}>Sort <i className="fa fa-sort" aria-hidden="true"></i></div>
                                                    <div className="widget--partner__list">
                                                        <ul id="sortable1" className='droptrue' onDragOver={(e) => onDragOver(e)} style={{ minHeight: '200px', maxHeight: '300px' }}
                                                            onDrop={(e) => onDrop(e, "selectedPartners")}>
                                                            {(filteredSelPars.map((p) => (
                                                                <li onClick={(e) => addSelected(e, p.partnerID)} onDragStart={(e) => onDragStart(e)}
                                                                    draggable className={`${p.selected ? 'selected' : ''}`} key={p.partnerID.toString()}>
                                                                    <div className="drag">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 23 23" width="20px" ><path d="M0 0h24v24H0V0z" fill="none" /><path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>
                                                                    </div>
                                                                    <span className="text">{p.partnerCode + " (" + p.partnerName + ") "}</span></li>
                                                            )))}
                                                        </ul>
                                                    </div>
                                                    {errorConst.partners ?
                                                        (
                                                            <div className="error-message">{errorConst.partners}</div>
                                                        )
                                                        : ('')}
                                                </div>
                                            </div>
                                            <div className="col-md-12 d-flex d-lg-none justify-content-center">
                                                <div className="move move-right">
                                                    <a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" ><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z" /></svg></a>
                                                </div>
                                                <div className="move move-left">
                                                    <a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" ><path d="M0 0h24v24H0V0z" fill="none" /><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" /></svg></a>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="widget widget--partner">
                                                    <div className="head"><h2>All Partners</h2></div>
                                                    <div className="input-wrap txt-wrap">
                                                        <input autoComplete="off" type="text" className="txt" placeholder="Search..."
                                                            value={allParsSearchKey} onChange={(e) => setAllParsSearchKey(e.target.value)} />
                                                        <i className="fa fa-search partnersGroup"></i>
                                                    </div>
                                                    <div className="widget--partner__title" onClick={(e) => onSort('allPars')} style={{ cursor: "pointer" }}>Sort <i className="fa fa-sort"></i></div>
                                                    <div className="widget--partner__list" style={{ maxHeight: '300px', overflow: 'hidden' }}>
                                                        <ul id="sortable2" className='droptrue' onDragOver={(e) => onDragOver(e)}
                                                            onDrop={(e) => onDrop(e, "allPartners")} style={{ minHeight: '200px' }}>
                                                            {(filteredAllPars.map((p) => (
                                                                <li onClick={(e) => addSelected(e, p.partnerID)} onDragStart={(e) => onDragStart(e)}
                                                                    draggable className={`${p.selected ? 'selected' : ''}`} key={p.partnerID.toString()}>
                                                                    <div className="drag">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 23 23" width="20px" ><path d="M0 0h24v24H0V0z" fill="none" /><path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>
                                                                    </div>
                                                                    <span className="text">{p.partnerCode + " (" + p.partnerName + ") "}</span></li>
                                                            )))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" mt-5 chcek-wrap">
                                        <div className="form-check">
                                            <div className='form-row row'>
                                                <input type="checkbox" id="yes" checked={reportingGroup} onChange={handleCheckboxChange} />
                                                <label for="yes">Reporting Group</label>
                                                {reportingGroup ? <div style={{ paddingLeft: "20px" }}> <input type="checkbox" id="monitor_check" checked={monitorFileHealth} onChange={(e) => setMonitorFileHealth(monitorFileHealth ? false : true)} /><label for="monitor_check">Monitor File Health</label> </div> : ''}
                                            </div>
                                            <div style={{ padding: "10px" }}></div>
                                            {/* <div class=" form-row row" > */}
                                            <div>
                                                {reportingGroup ? addFileStructureWidgets() : ''}
                                            </div>

                                        </div>

                                    </div>
                                    <div className="btn-wrap btn-wrap--grp mt-5 justify-content-end">
                                        <button onClick={() => resetForm()} className="button button--outline">Reset</button>
                                        <button onClick={(e) => onSave(e)} className="button">{mode === "create" ? "Save" : "Update"}</button>
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        ''
                }
                <deleteConfirm></deleteConfirm>
                <div className="container">
                    <div className="table-block">
                        <div className="table-block__head">
                            <h2>List of Groups
                                <span style={{ "fontWeight": "bold", "fontSize": "13px", "color": "#666" }} >
                                    &nbsp; [ Total: {groupList.data ? groupList.data.length : 0} ]
                                </span>
                            </h2>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <button type="button" style={{ background: '#F8F8F8', color: 'black', border: 'none' }} className="btn btn-secondary btn-sm" onClick={(e) => isExportVisible ? setIsExportVisible(false) : setIsExportVisible(true)}>
                                <img src={iconExport} alt="Success" />  Export
                                </button>
                                <div>
                                {isExportVisible ? 
                                    <div class='fh-legend widget exportXl'>
                                    <p style={{ color: '#000' }}><strong>Export As</strong></p>
                                    <ul>
                                        <li style={{ cursor: 'help'}} onClick={(e) => exportClickExcelDownload()}><img src={iconExcel} alt="Success" /> Excel</li>
                                    </ul>
                                    </div> 
                                : ''}
                                </div> 
                                <div className="txt-wrap">
                                    <input autoComplete="off" type="text" className="txt" placeholder="Search..."
                                        value={groupSearchKey} onChange={(e) => setGroupSearchKey(e.target.value)} />
                                    <i className="fa fa-search"></i>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive grid" >
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" onClick={() => onSort('grpName')} style={{ cursor: "pointer" }}>Group Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => onSort('grpDesc')} style={{ cursor: "pointer" }}>Description <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" style={{ "whiteSpace": "nowrap", cursor: "pointer" }} onClick={() => onSort('grpReport')} >Reporting Group <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col">Partners
                                            <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTissp" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTissp" data-pr-tooltip="Click to view list of partners" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                        </th>

                                        {isAdmin() || isPartnerAdmin() ? <th scope="col">Action</th> : ""}
                                    </tr>
                                </thead>
                                <tbody>
                                    {(groupList.loading || groupList.data.length == 0) ? (<tr><td colSpan="100"><div className="text-center text-danger">No group data available</div></td></tr>) :
                                        filteredGroupList.map((group) =>
                                        (<tr>
                                            <td>{group.groupName}</td>
                                            <td>{group.groupDescription}</td>
                                            <td>{group.reportingGroup ? "Yes" : "No"}</td>
                                            <td style={{ cursor: 'help' }} data-toggle="modal" onClick={(e) => enablePartnerListModel(customText(group.partners, 10000))} data-target="#grouppartnerlistingmodel">
                                                {customText(group.partners, 60).length < 60 ? customText(group.partners, 60) :
                                                    <span style={{ paddingLeft: "5px" }}>  {customText(group.partners, 60)}<Tooltip target=".toolTip" /> </span>
                                                }
                                            </td>
                                            {isAdmin() || isPartnerAdmin() ? <td>
                                                <div className="table__action">
                                                    <a href="javascript:void(0);" className="utils utils--outline" onClick={(e) => onEditClick(group)} title="Edit Group"> <img src={iconEdit} alt="edit" /></a>
                                                    <a href="javascript:void(0);" className="utils utils--outline ml-2" onClick={(e) => deleteConfirm(group.groupCode, group.groupName)} title="Delete Group"> <img src={iconDelete} alt="edit" /></a>
                                                </div>
                                            </td> : ""}
                                        </tr>
                                        ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-4">
                    <div className={`modal fade modal--addnew ${isPartnerListingPopupEnable ? 'show' : ''}`} id="grouppartnerlistingmodel" data-backdrop="static" data-keyboard="false" tabindex="-1"
                        aria-labelledby="staticBackdropLabel" aria-modal="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="container">
                                        <div className="table-block">
                                            <div className="table-block__head">
                                                <h2>Partners List</h2>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <img src={close_icon} onClick={(e) => setPartnerListForModel([])} alt="Close" />
                                                </button>
                                            </div>
                                            <br />
                                            <div class="table-responsive grid">
                                                <table class="table">
                                                    <thead>
                                                        {isPartnerListingPopupEnable && partnerListForModel.length > 0 ? partnerListForModel.map((partnerModel) => (
                                                            <tr><th scope="col">{partnerModel}</th></tr>
                                                        )) : ''}
                                                    </thead>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    );
};

const mapDispatchToProps = dispatch => ({
    dispatchListPartnersAction: (onSuccess, onError) =>
        dispatch(listPartners(onSuccess, onError)),
    dispatchCreateGroupAction: (data, onSuccess, onError) =>
        dispatch(createGroup(data, onSuccess, onError)),
    dispatchListGroupsAction: (onSuccess, onError) =>
        dispatch(listGroups(onSuccess, onError)),
    dispatchModifyGroupAction: (data, onSuccess, onError) =>
        dispatch(modifyGroup(data, onSuccess, onError)),
    dispatchDeleteGroupAction: (data, onSuccess, onError) =>
        dispatch(deleteGroup(data, onSuccess, onError)),
    dispatchGroupNotificationTypes: (data, onSuccess, onError) =>
        dispatch(fetchOneTimeParams(data, onSuccess, onError)),
});

export default connect(null, mapDispatchToProps)(GroupComponent);