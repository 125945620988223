import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import _ from "lodash";
import ReCAPTCHA from "react-google-recaptcha";
import * as constants from '../redux/constants';
var CryptoJS = require("crypto-js");
import { loginUser, validateOtp, resendOtp, validateCaptcha, forgotPassword } from '../redux/actions/auth.action.creators';
import AlertFailure from '../utils/alert-failure.component';
import AlertSuccess from '../utils/alert-success.component';
import BackIcon from '../assets/images/icon-back.png';

const appConf = require(process.env.REACT_APP_CONFIG_URL);
const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const logoName = appConf.constants.logoLoginName;

const companyLogo  = require(`../assets/images/${logoName}`).default

const LoginForm = ({ dispatchLoginAction, dispatchValidateOTPAction, dispatchResendOTPAction, dispatchValidateCaptchaAction, dispatchForgotPasswordAction, history }) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [token, setToken] = useState('');
    const [captchaValid, setCaptchaValid] = useState(!appConf.switch.captcha);
    const [showOTP, setShowOTP] = useState(false);
    const [hideResend, setHideResend] = useState(false);
    const [showForgotPwd, setShowForgotPwd] = useState(false);
    const [loginError, setLoginError] = useState({});
    const [otpError, setOtpError] = useState('');
    const [failMsg, setFailMsg] = useState('');
    const [succMsg, setSuccMsg] = useState('');
    const [frgtPwdEmail, setFrgtPwdEmail] = useState('');
    const [frgtPwdCaptchaValid, setFrgtPwdCaptchaValid] = useState(!appConf.switch.captcha);
    const [frgtPwdError, setFrgtPwdError] = useState({});
    const [loginCaptcha, setLoginCaptcha] = useState({"response": "", "site": ""});
    const [frgtPwdCaptcha, setFrgtPwdCaptcha] = useState({"response": "", "site": ""});
    const loginRecaptchaRef = useRef(null);
    const fgtPwdRecaptchaRef = useRef(null);
    const otpLogin = appConf.switch.otp;
    const isSSOStatus = appConf.switch.ssoLoginStatus;


    const onLogin = (event) => {
        event.preventDefault();
        if (isLoginFormValid())
            dispatchLoginAction(email, password, loginCaptcha.response, loginCaptcha.site, ({data, message}) => {

                if (data) {
                    try {
                        const bytes = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(appConf.switch.aesKey), {
                            mode: CryptoJS.mode.ECB,
                            padding: CryptoJS.pad.Pkcs7
                        });
                        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                        data = decryptedData;
                    } catch (e) {
                        console.log(e);
                    };
                }
                if(data && otpLogin){
                    setToken(data['otpToken']);                    
                    setShowOTP(true); 
                    setSuccMsg("An OTP is sent to your registered email");                
                    setTimeout(() => {    
                        setSuccMsg('');
                    }, 3000);
                } else if(data && !otpLogin){
                    // toast.success("Logged In Successfully.")
                }
            },
            (message) => {
                setFailMsg(message);
                setTimeout(() => {
                    setFailMsg('')
                }, 3000);
                loginRecaptchaRef.current.reset();
                setCaptchaValid(false);
            });
    };
    useEffect(() => {
        if (isSSOStatus && window.location.href.includes(constants.SSO_ENDPOINT)) {
            const url = new URL(window.location.href);
            const searchParams = new URLSearchParams(url.search);
            const jsessionId = searchParams.get("JSESSIONID");
            const userResponse = searchParams.get("userResponse");
            const message = searchParams.get("message");
            const statuscode = searchParams.get("statuscode");

            if (statuscode.includes("200") && userResponse != null) {
                var userInfoData = JSON.parse(atob(userResponse));
                setSSOUserInfo(userInfoData)
            }
            else {
                setFailMsg(message)
                setTimeout(() => { setFailMsg('');}, 3000);
                setTimeout(() => { refreshBrowser(); }, 4000);
                }
        }
    }, [])

    const refreshBrowser = () => {
        window.location.href = '/auth'
    }

    const setSSOUserInfo = (userData) => {
        if (userData) {
            const userInfo = {
                userId: userData.id,
                fullName: userData.username,
                email: userData.email,
                token: userData.authToken,
                jwtToken: userData.authToken,
                role: userData.role,
                screens: userData.screens,
                partnerId: userData.partnerId,
                isSSOLogin: parsedToken.ssouser,
                isLoggedIn: true
            };
            var userInformation = CryptoJS.AES.encrypt(JSON.stringify(userInfo), appConf.switch.secretKey).toString();
            localStorage.setItem('USER_INFO', userInformation);
            window.location = '/dashboard'
        } else {
            window.location = '/auth'
        }
    };

    const validateOTP = (event) => {
        event.preventDefault();
        if (!otp) setOtpError("Enter OTP")
        else dispatchValidateOTPAction(otp, token, ({data, message}) => {
                // toast.success("Logged In Successfully.")
            },
            (message) => {
                setFailMsg(message);
                setTimeout(() => {
                    setFailMsg('')
                    if(hideResend) window.location.reload();
                }, 3000);
            });
    };

    const resendOTP = (event) => { 
        setHideResend(true);
        dispatchResendOTPAction(token, ({data, message}) => {
            if(data){
                setSuccMsg("An OTP is sent to your registered email");
                setToken(data);
                setTimeout(() => {    
                    setSuccMsg('');    
                }, 3000);
            }
        },
        (message) => {
            setFailMsg(message);
                setTimeout(() => {
                    // setFailMsg('');
                    window.location.reload();
                }, 3000);
        }
        );
    };

    const sendForgotPwdMail = (event) => {
        event.preventDefault();
        if(isForgotPwdFormValid())
        dispatchForgotPasswordAction(frgtPwdEmail, frgtPwdCaptcha.response, frgtPwdCaptcha.site, ({data, message}) => {
            if(data){
                setSuccMsg(data);
                setFrgtPwdEmail('');
                setFrgtPwdCaptchaValid(false);
                setTimeout(() => {          
                    // setShowForgotPwd(false);
                    // setSuccMsg(''); 
                    window.location.reload();
                }, 3000);
            }
        },
        (message) => {
            setFailMsg(message);
                setTimeout(() => {
                    // setFailMsg('');
                    window.location.reload();
                }, 3000);
        }
        );
    };

    const isForgotPwdFormValid = () => {
        const tempError = _.cloneDeep(frgtPwdError);        
        tempError.email = !frgtPwdEmail ? "Enter email" : (!(new RegExp(/^(\d{9}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4}))$/).test(frgtPwdEmail)) ? "Invalid email pattern" : '');
        tempError.captcha = !frgtPwdCaptchaValid ? "Please select the captcha" : '';
        setFrgtPwdError(tempError);

        return (!tempError.email && !tempError.captcha);
    };

    const isLoginFormValid = () => {
        const tempError = _.cloneDeep(loginError);        
        //tempError.email = !email ? "Enter email" : (!(new RegExp(/^(\d{9}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4}))$/).test(email)) ? "Invalid email pattern" : '');
        tempError.email = !email ? "Enter Email/Username" : '';
        tempError.password = !password ? "Enter password" : '';
        tempError.captcha = !captchaValid ? "Please select the captcha" : '';
        setLoginError(tempError);

        return (!tempError.email && !tempError.password && !tempError.captcha);
    };

    const ssoLoginHandler = (evalue) => {
        if(isSSOStatus){var time = new Date();
        console.log(time);
        console.log("Initiating SSO Login......");
        //testing purpose 
        // window.location = BASE_URL+constants.END_POINT_PATH
        window.location = BASE_URL+constants.END_POINT_PATH}
    };

    function onLoginCaptcha(value) {
        // let response = value;
        // let site = "Login page";
        setLoginCaptcha({"response": value, "site": "Login page"});
        setCaptchaValid(true);
        // dispatchValidateCaptchaAction(response, site, ({data, message}) => {
        //     if(data && (JSON.parse(data).success)){
        //         setCaptchaValid(true);
        //     }
        // },
        // (message) => toast.error(`Error: ${message}`));
    }

    function onFgtPwdCaptcha(value) {
        setFrgtPwdCaptcha({"response": value, "site": "Forgot password page"});
        setFrgtPwdCaptchaValid(true);
        // dispatchValidateCaptchaAction(response, site, ({data, message}) => {
        //     if(data && (JSON.parse(data).success)){
        //         setFrgtPwdCaptchaValid(true);
        //     }
        // },
        // (message) => toast.error(`Error: ${message}`));
    }


    return (
        <React.Fragment>
            <a className="sr-only sr-only-focusable" href="#content">Skip to main content</a>
            <div className="login">
                <div className="login__overlay">
                    <div className="login__block">
                        <div className="login__desc">
                        
                        <h1><div>{appConf.constants.loginPage.loginTitle} <strong>{appConf.constants.loginPage.loginTitle2}</strong></div><span>File Exchange Portal</span></h1>
                            <br />
                            <p> {appConf.constants.loginPage.loginDisclaimer} </p>
                        </div>

                        <div className="login__content">
                            <div className="logo-login">
                                <img src={companyLogo} alt="Company Logo" />
                            </div>
                            {failMsg ? 
                            <AlertFailure message={failMsg}/> : ''}
                            {succMsg ? 
                            <AlertSuccess message={succMsg}/> : ''}
                            <form className="login__form w-100" noValidate onSubmit={onLogin}>
                                <div className="box box--login" style={{display: (showOTP || showForgotPwd) ? 'none' : 'block'}}>
                                    <div className="input-wrap txt-wrap">
                                        <input autoComplete="off" type="text" id="username" 
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} 
                                        className={`txt ${loginError.email ? 'is-invalid' : ''}`} required />
                                        <label className="input-label" htmlFor="username">Email/Username</label>
                                        {loginError.email ?
                                        (<div className="error-message">{loginError.email}</div>)
                                        : ('')}
                                    </div>
                                    <div className="input-wrap password-wrap">
                                        <input autoComplete="off" type="password" id="password" 
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)} 
                                        className={`txt ${loginError.password ? 'is-invalid' : ''}`} required />
                                        <label className="input-label" htmlFor="password">Password</label> 
                                        <a onClick={() => setShowForgotPwd(true)} href="javascript:void(0)" className="link link--forget">Forgot</a>
                                        {loginError.password ?
                                        (<div className="error-message">{loginError.password}</div>)
                                        : ('')}
                                    </div>
                                    {appConf.switch.captcha ? 
                                    (<div className="input-wrap captcha-wrap">
                                    <ReCAPTCHA
                                        ref={loginRecaptchaRef}
                                        sitekey={appConf.constants.captcha.LOGIN_RECAPTCHA_SITEKEY}
                                        onChange={onLoginCaptcha}
                                        theme="light"
                                        onExpired={(e) => setCaptchaValid(false)}
                                        className="g-recaptcha"
                                    />
                                    {loginError.captcha ?
                                        (<div className="error-message">{loginError.captcha}</div>)
                                        : ('')}
                                    </div>) : ''}
                                    <div className="btn-wrap w-100 mt-3">
                                        <button type="submit"  style={{textTransform:'none'}} className="button">{otpLogin ? 'Generate OTP' : 'Login'}</button>
                                    </div>
                                </div>
                            </form>
                            {isSSOStatus && !showForgotPwd?
                                <div style={{width:"100%"}}>
                                    <p/>
                                        <center><b style={{color:'grey'}}>Or</b></center>
                                        <div className="btn-wrap w-100 mt-3">
                                            <button className="button" style={{textTransform:'none'}} onClick={(e) => ssoLoginHandler(e.target.value)} >Company Single Sign-On</button>
                                        </div>
                                    <p/>
                                    <p/>
                                </div>
                                : ''}
                            <form className="login__form w-100" noValidate onSubmit={validateOTP}>
                                <div className="box box--otp" style={{display: !showOTP ? 'none' : 'block'}}>
                                    <div className="input-wrap txt-wrap">
                                        <input autoComplete="off" className={`txt ${otpError ? 'is-invalid' : ''}`} type="text" id="otp"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)} required />
                                        <label className="input-label" htmlFor="otp">Enter OTP</label> 
                                        {otpError ?
                                        (<div className="error-message">{otpError}</div>)
                                        : ('')}
                                    </div>
                                    <div className="btn-wrap w-100 mt-3">
                                        <button type="submit" className="button">Login</button>
                                    </div>
                                    {!hideResend ? 
                                    (<span className="resend-otp w-100 text-center d-block help-text">The OTP has been sent to your email address. If you are not able to see the email in your inbox, please make sure to check your Spam/Junk folder
                                    <br />
                                    Didn't receive the OTP ? Click here to <a onClick={(e) => resendOTP(e.target.value)} href="javascript:void(0)" className="link">Resend OTP</a></span>)
                                     : ('')}
                                    
                                </div>
                            </form>
                            <form className="login__form w-100" noValidate onSubmit={(e) => sendForgotPwdMail(e)}>
                                <div className="box box--email" style={{display: !showForgotPwd ? 'none' : 'block'}}>
                                    <div className="input-wrap txt-wrap">
                                        <input autoComplete="off" className="txt" type="text" id="email" value={frgtPwdEmail}
                                        onChange={(e) => setFrgtPwdEmail(e.target.value)} required />
                                        <label className="input-label" htmlFor="email">Email</label>
                                        {frgtPwdError.email ?
                                        (<div className="error-message">{frgtPwdError.email}</div>)
                                        : ('')} 
                                    </div>
                                    {appConf.switch.captcha ? 
                                    (<div className="input-wrap captcha-wrap">
                                    <ReCAPTCHA
                                        ref={fgtPwdRecaptchaRef}
                                        sitekey={appConf.constants.captcha.FGTPWD_RECAPTCHA_SITEKEY}
                                        onChange={onFgtPwdCaptcha}
                                        theme="light"
                                        onExpired={(e) => setFrgtPwdCaptchaValid(false)}
                                        className="g-recaptcha"
                                    />
                                    {frgtPwdError.captcha ?
                                        (<div className="error-message">{frgtPwdError.captcha}</div>)
                                        : ('')}
                                    </div>) : ''}
                                    <div className="btn-wrap w-100 mt-3">
                                        <button type="submit" className="button">Send Mail</button>
                                    </div>
                                    {isSSOStatus ?
                                    <div style={{width:"100%"}}>
                                        <p/>
                                            <center><b style={{color:'grey'}}>Or</b></center>
                                            <div className="btn-wrap w-100 mt-3">
                                                <button className="button" style={{textTransform:'none'}} onClick={(e) => ssoLoginHandler(e.target.value)} >Company Single Sign-On</button>
                                            </div>
                                        <p/>
                                        <p/>
                                    </div>
                                    : ''}
                                    <div className="btn-wrap w-100 mt-3">
                                        <a href='/login' class="link link--back"><i> <img src={BackIcon} alt="Back" /></i>  Back </a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapDispatchToProps = dispatch => ({
    dispatchLoginAction: (email, password, response, site, onSuccess, onError) =>
        dispatch(loginUser({ email, password, response, site }, onSuccess, onError)),
    dispatchValidateOTPAction: (otp, token, onSuccess, onError) =>
        dispatch(validateOtp({ otp, token }, onSuccess, onError)),
    dispatchResendOTPAction: (token, onSuccess, onError) =>
        dispatch(resendOtp({ token }, onSuccess, onError)),
    dispatchValidateCaptchaAction: (response, site, onSuccess, onError) =>
        dispatch(validateCaptcha({ response, site }, onSuccess, onError)),
    dispatchForgotPasswordAction: (email, response, site, onSuccess, onError) =>
        dispatch(forgotPassword({ email, response, site }, onSuccess, onError))
});

export default connect(null, mapDispatchToProps)(LoginForm);