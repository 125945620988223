import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import AlertFailure from '../utils/alert-failure.component';
import AlertSuccess from '../utils/alert-success.component';
import {isAdmin, listEncryptKeys, listDecryptKeys, fileEncryptDecryptAction } from '../redux/actions/auth.action.creators';
import CustomDropdownWidget from './dropdown/CustomDropdownWidget';
import * as constants from '../redux/constants';
import iconUpload from '../assets/images/icon-upload.png';
var appConf  = require(process.env.REACT_APP_CONFIG_URL);
var CryptoJS = require("crypto-js");

const FileEncryptDecryptComponent = ({ dispatchListDecryptKeys, dispatchListEncryptKeys, dispatchFileEncryptDecryptAction, dispatchSpinnerAction}) => {

    const [fileEncryptDecryptData, setFileEncryptDecryptData] = useState({"encryptDecryptType": "","fileName": "","keyName": "","file": ""});
    const [errorConst, setErrorConst] = useState({});
    const [failMsg, setFailMsg] = useState('');
    const [succMsg, setSuccMsg] = useState('');
    const [keyCreation, setKeyCreation] = useState('D'); //D:Decrypt,E:Encrypt
    const [dropdownList, setDropdownList] = useState([]);
    const [constrains, setConstrains] = useState({ "fieldPlaceholder": "Select key", "isMandatory": true, "fieldLabel": "Select key", "inputErrorMessage": "" })
    const [responseData, setResponseData] = useState({});
    const [dropdownRefresher, setdropdownRefresher] = useState(true);

    function resetForm() {
        setFileEncryptDecryptData({"file": "","fileName": ""});
        setErrorConst({"file": "" ,"fileName": ""});
    }

    function onDropFn(e){
        e.preventDefault();
        saveForm(e.dataTransfer.files[0]);
    }

    function isValid(file){
        let tempError = _.cloneDeep(errorConst)
        tempError.file = !file ? "Please choose a file to upload" : "";
        setErrorConst(tempError);
        return(!tempError.file)
    }

    function fileDownload(data){
        console.log("Started fileDownload");
        const downloadLink = document.createElement("a");
        let fileExtension = ''
        if (keyCreation=='E') {
            fileExtension='.pgp'
        }else {
            fileExtension='.txt'
        }
        const OutputFileName = data.fileName + fileExtension;
        console.log("OutputFileName : "+OutputFileName);
        downloadLink.href = data.fileDownloadLink;
        downloadLink.download = OutputFileName;
        downloadLink.click();
        console.log("fileDownload completed");
    }

    function saveForm(file) { 
        console.log("in saveform")
        if(isValid(file)){
            var key_name = CryptoJS.AES.encrypt(responseData.label, CryptoJS.enc.Utf8.parse(appConf.switch.aesKey), {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            }).toString();
            setErrorConst({"file": "" ,"fileName": ""});
            let request = new FormData();
            request.append('file', file);
            request.append('encryptDecryptType', keyCreation);
            request.append('keyName', key_name);
            console.log("fileEncryptDecryptData test--->>  ",request)
            dispatchFileEncryptDecryptAction(request, ({ data, message }) => {
                console.log("Inside dispatchFileEncryptDecryptAction");
                if (data) {
                    try {
                        const bytes = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(appConf.switch.aesKey), {
                            mode: CryptoJS.mode.ECB,
                            padding: CryptoJS.pad.Pkcs7
                        });
                        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                        data = decryptedData;
                        console("decryptedData",data);
                    } catch (e) {
                        console.log(e);
                    };
                }
                
                if (data && data.statusCode=='200') {
                    window.scrollTo(0, 0);
                    setSuccMsg("Encrypt/Decrypt process completed successfully");
                    setTimeout(() => {
                        setSuccMsg('')
                    }, 3000);
                    console.log("data success-->  ",data)
                    fileDownload(data);
                }else {
                    window.scrollTo(0, 0);
                    setFailMsg("Encrypt/Decrypt process failed");
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                    console.log("data faild-->  ",data)
                }
            },
            (message) => {
                window.scrollTo(0, 0);
                console.log("fail message.........."+message);
                setFailMsg(message); 
                setTimeout(() => {
                setFailMsg('')
                }, 3000);
            });
			resetForm();
        } 
    }

    function fileValidation(file){
        setFileEncryptDecryptData({ "file": file});

        //Validation - file size
        if (file) {
            if (file.size > (2 * 1024 * 1024 * 1024)) {
                setErrorConst({"fileSize": "Please choose a file with size less than 2GB", "fileName": file.name});
                setFailMsg("Please choose a file with size less than 2GB"); 
                setTimeout(() => {
                    setFailMsg('')
                }, 3000);
            }
            else {
                //Validation - file extension and valid key name
                const allowedExtensions = ['pgp', 'gpg'];
                const fileExtension = file.name.split('.').pop().toLowerCase();
                if (((allowedExtensions.includes(fileExtension)&& keyCreation=='D')||(!allowedExtensions.includes(fileExtension)&& keyCreation=='E')) && responseData.label!=null && responseData.label!="") {
                    setFileEncryptDecryptData({ "file": file});
                    saveForm(file);
                    console.log("Validation success");
                } else {
                    console.log("Validation failed");
                    setFailMsg("Invalid file extesion or missing key selection"); 
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                    file = null;
                }
                setErrorConst({"file": "", "fileName": ""});
            }
        }
    }

    useEffect(() => {
        setFileEncryptDecryptData({"file": "","fileName": ""});
        let keyList = [{ "value": "", "label": "Any" }];
        if (keyCreation=='D'){
            setdropdownRefresher(dropdownRefresher ? false : true);
            dispatchListDecryptKeys(({ data, message }) => {
                if (data){
                    data.forEach(i => {
                        keyList.push({
                            "value": i.private_keyring_path,
                            "label": i.private_keyring_name
                        })
                    });
                    setDropdownList(keyList) 
                }
            }, (message) => {
                console.log(message);
            });
        }if (keyCreation=='E'){
            setdropdownRefresher(dropdownRefresher ? false : true);
            dispatchListEncryptKeys(({ data, message }) => {
                if (data){
                    data.forEach(i => {
                        keyList.push({
                            "value": i.pkeypath,
                            "label": i.pkeyname
                        })
                    setDropdownList(keyList)
                    });
                }
            }, (message) => {
                console.log(message);
            });
        }
    }, [keyCreation])

    return (
        <React.Fragment>
            <main>
                <div class="container">
                    <ul class="breadcrumb">
                        <li class="breadcrumb__item">
                            <a class="breadcrumb__link" href="#">Utilities</a>
                        </li>
                        <li class="breadcrumb__item">
                            <span class="breadcrumb__link">File Encrypt/Decrypt</span>
                        </li>
                    </ul>
                    <div class="title">
                        <h1>File Encrypt/Decrypt</h1>
                    </div>
                    {failMsg ?
                        <AlertFailure message={failMsg} /> : ''}
                    {succMsg ?
                        <AlertSuccess message={succMsg} /> : ''}
                </div>
                <div class="grey-block">
                    <div class="container">
                          
                        <div className="row">
                            <div className="col-lg-12 mb-5">
                                <label for="f1" class="input-label"> Perform </label>
                                <ul className="radio-wrap">
                                    <li>
                                        <input type="radio" id="test1" name="radio-group" checked={keyCreation === "D" ? true : false} onChange={(e) => setKeyCreation('D')} />
                                        <label className="input-label" for="test1">Decrypt</label>
                                    </li>
                                    <li>
                                        <input type="radio" id="test2" name="radio-group" checked={keyCreation === "E" ? true : false} onChange={(e) => setKeyCreation('E')} />
                                        <label className="input-label" for="test2">Encrypt</label>
                                    </li>
                                </ul>    
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <div className="input-wrap txt-wrap">
                                        <CustomDropdownWidget  dropdownRefresher={dropdownRefresher} dropdownList={dropdownList} constrains={constrains} setResponseData={setResponseData}></CustomDropdownWidget>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                            <div class="file-upload" onDrop={(e) => onDropFn(e)} onDragOver={(e) => e.preventDefault()}>
                                <div class="file-upload__icon">
                                <img src={iconUpload} alt="upload" /> </div>
                                <div class="file-upload__text">Drag & Drop to Upload File</div>
                                <span class="devider">or select a file</span>
                                <input type="file" id="file" class="file-upload__btn" onChange={(e) => {fileValidation(e.target.files[0]);e.target.value = '';}} />
                                <label class="button" for="file"> Choose file</label>
                            </div>
                            {errorConst.file ? 
                                (<div class="error-message">{errorConst.file}</div>) : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    );
}

const mapDispatchToProps = dispatch => ({
    dispatchListDecryptKeys: (onSuccess, onError) =>
        dispatch(listDecryptKeys(onSuccess, onError)),
    dispatchListEncryptKeys: (onSuccess, onError) =>
        dispatch(listEncryptKeys(onSuccess, onError)),
    dispatchFileEncryptDecryptAction: (data, onSuccess, onError) =>
        dispatch(fileEncryptDecryptAction(data, onSuccess, onError)),
    dispatchSpinnerAction: () => 
        dispatch({ type: constants.TOGGLE_LOADER })

});

export default connect(null, mapDispatchToProps)(FileEncryptDecryptComponent);