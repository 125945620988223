import React, { useEffect, useState } from 'react';
import { connect, useStore } from 'react-redux';
import { PartnerRoleModel, PartnerSSHInBoundModel } from '../../Bean/partner';
import { fetchOneTimeParams } from '../../redux/actions/auth.action.creators'
import CustomDropdownWidget from '../dropdown/CustomDropdownWidget'
import info_icon from '../../assets/images/icon-info-round.png';


const PartnerOnboardingRoleComponent = (props, { dispatchOneTimeParams }) => {

  const [inputErrorWarning, setInputErrorWarning] = useState('')
  const [authTypeInbound, setAuthTypeInbound] = useState({ "ssh": false, "pass": false })
  const [authTypeOutbound, setAuthTypeOutbound] = useState({ "ssh": false, "pass": false })
  const [sshBoundErrorWarning, setSSHBoundErrorWarning, warning] = useState('')

  const [eyeSlashIn, setEyeSlashIn] = useState({ "pass": true, "confirmPass": true })
  const [eyeSlashOut, setEyeSlashOut] = useState({ "pass": true, "confirmPass": true })
  const [passphraseEyeSlash, setPassphraseEyeSlash] = useState(true)
  var warningCache = {}
  const appConf = require(process.env.REACT_APP_CONFIG_URLS);
  const enablePartnerSize = appConf.switch.enablePartnerSize;

  //for dropdown cipher 
  const [cipherConstrains, setCipherConstrains] = useState({ "fieldPlaceholder": "Choose Any", "isMandatory": true, "fieldLabel": "Preferred Cipher", "inputErrorMessage": "" })
  const [cipherResponseData, setCipherResponseData] = useState({})
  const [cipherList, setCipherList] = useState()

  //for dropdown mac 
  const [macConstrains, setMACConstrains] = useState({ "fieldPlaceholder": "Choose Any", "isMandatory": true, "fieldLabel": "Preferred MAC Algorithm", "inputErrorMessage": "" })
  const [macResponseData, setMACResponseData] = useState({})
  const [macList, setMACList] = useState()

  //for dropdown compressions 
  const [compressionConstrains, setCompressionConstrains] = useState({ "fieldPlaceholder": "Choose Any", "isMandatory": true, "fieldLabel": "Compression", "inputErrorMessage": "" })
  const [compressionResponseData, setCompressionResponseData] = useState({})
  const [compressionList, setCompressionList] = useState()

  //for dropdown max file upload size 
  const [filesizeConstrains, setFilesizeConstrains] = useState({ "fieldPlaceholder": "Choose Any", "isMandatory": true, "fieldLabel": "Maximum file upload size (in GB)", "inputErrorMessage": "" })
  const [filesizeResponseData, setFilesizeResponseData] = useState({})
  const [filesizeList, setFilesizeList] = useState({})


  // dropdown edit values for cipher
  const [valueForCipherEdit, setValueForCipherEdit] = useState({});
  // dropdown edit values for mac
  const [valueForMACEdit, setValueForMACEdit] = useState({});
  // dropdown edit values for compression
  const [valueForCompressionEdit, setValueForCompressionEdit] = useState({});
  // dropdown edit values for file upload size
  const [valueForFilesizeEdit, setValueForFilesizeEdit] = useState({});


  const [spdErrorIndo, setSpdErrorIndo] = useState([{}])



  useEffect(() => {
    console.log(props.sshOutBound);
    if (props.updationStatus) {
      setValueForCipherEdit({
        "value": props.sshOutBound.preferredCipher,
        "label": props.sshOutBound.preferredCipher
      })
      setValueForMACEdit({
        "value": props.sshOutBound.preferredMAC,
        "label": props.sshOutBound.preferredMAC
      })
      setValueForCompressionEdit({
        "value": props.sshOutBound.compression,
        "label": props.sshOutBound.compression
      })
      setValueForFilesizeEdit({
        "value": props.inputDataForRole.maxFileSize,
        "label": props.inputDataForRole.maxFileSize + " GB"
      })
    }
  }, [props.updationStatus])

  useEffect(() => {
    if (!props.inputDataForRole.partnerRoleConsumer) {
      setValuesToVariable("partnerRoleConsumerInitiate", false)
      setValuesToVariable('partnerRoleConsumerListen', false)
    }
    if (!props.inputDataForRole.partnerRoleProducer) {
      setValuesToVariable("partnerRoleProducerInitiate", false)
      setValuesToVariable('partnerRoleProducerListen', false)
    }
  }, [props.inputDataForRole.partnerRoleConsumer, props.inputDataForRole.partnerRoleProducer])

  useEffect(() => {
    setValuesToSSHBounds("preferredCipher", cipherResponseData.value ? cipherResponseData.value : "", false)
  }, [cipherResponseData])

  useEffect(() => {
    setValuesToSSHBounds("compression", compressionResponseData.value ? compressionResponseData.value : "", false)
  }, [compressionResponseData])

  useEffect(() => {
    setValuesToSSHBounds("preferredMAC", macResponseData.value ? macResponseData.value : "", false)
  }, [macResponseData])

  useEffect(() => {
    setValuesToVariable("maxFileSize", filesizeResponseData.value ? filesizeResponseData.value : (props.inputDataForRole.maxFileSize == 0 ? 0 : props.inputDataForRole.maxFileSize))
  }, [filesizeResponseData])

  useEffect(() => {
    //for dummy 
    setValuesToSSHBounds("null", true)
  }, [])

  useEffect(() => {
    props.dispatchOneTimeParams(({ data, message, status }) => {
      if (data) {
        setCipherList(data.preferred_cipher)
        setMACList(data.preferred_mac)
        setCompressionList(data.compression)
        data.max_file_upload_size_values.sort((a, b) => parseInt(a.value) - parseInt(b.value))
        setFilesizeList(data.max_file_upload_size_values)
      }
    })
  }, [])

  const setValuesToVariable = (category, keyword) => {
    //receive : partner - consumer, router - producer
    //Partner pulls files => partnerRoleConsumerInitiate
    //Router pushes files => partnerRoleConsumerListen

    //send : partner - producer , router - consumer
    //Partner pushes files=> partnerRoleProducerInitiate
    //Router pulls files  => partnerRoleProducerListen

    props.setInputDataForRole({
      "partnerRoleConsumer": category === "partnerRoleConsumer" ? keyword : props.inputDataForRole.partnerRoleConsumer,
      "partnerRoleConsumerInitiate": category === "partnerRoleConsumerListen" ? false : category === "partnerRoleConsumerInitiate" ? keyword : props.inputDataForRole.partnerRoleConsumerInitiate,
      "partnerRoleConsumerListen": category === "partnerRoleConsumerInitiate" ? false : category === "partnerRoleConsumerListen" ? keyword : props.inputDataForRole.partnerRoleConsumerListen,
      "partnerRoleProducer": category === "partnerRoleProducer" ? keyword : props.inputDataForRole.partnerRoleProducer,
      "partnerRoleProducerInitiate": category === "partnerRoleProducerListen" ? false : category === "partnerRoleProducerInitiate" ? keyword : props.inputDataForRole.partnerRoleProducerInitiate,
      "partnerRoleProducerListen": category === "partnerRoleProducerInitiate" ? false : category === "partnerRoleProducerListen" ? keyword : props.inputDataForRole.partnerRoleProducerListen,
      "outboundConnectionType": category === "outboundConnectionType" ? keyword : props.inputDataForRole.outboundConnectionType,
      "setMaxFileSize": category === "setMaxFileSize" ? keyword : props.inputDataForRole.setMaxFileSize,
      "maxFileSize": category === "maxFileSize" ? keyword : props.inputDataForRole.maxFileSize
    })
  }

  const setValuesToSSHBounds = (category, keyword, isSSSInBound) => {
    if (isSSSInBound) {
      props.setSSHInBound({
        "sshInBoundAuthenticationType": category === "sshInBoundAuthenticationType" ? keyword.trim() : props.sshInBound.sshInBoundAuthenticationType,
        "userPublicKey": category === "userPublicKey" ? keyword : props.sshInBound.userPublicKey,
        "password": category === "password" ? keyword : props.sshInBound.password,
        "confirmPassword": category === "confirmPassword" ? keyword : props.sshInBound.confirmPassword
      })
    } else {
      props.setSHHOutBound({
        "remoteHost": category === "remoteHost" ? keyword : props.sshOutBound.remoteHost,
        "root": category === "root" ? keyword : props.sshOutBound.root,
        "port": category === "port" ? keyword : props.sshOutBound.port,
        "knownHostKey": category === "knownHostKey" ? keyword : props.sshOutBound.knownHostKey,
        "remoteUser": category === "remoteUser" ? keyword : props.sshOutBound.remoteUser,
        "downloadDirectory": category === "downloadDirectory" ? keyword : props.sshOutBound.downloadDirectory,
        "uploadDirectory": category === "uploadDirectory" ? keyword : props.sshOutBound.uploadDirectory,
        "sshOutBoundAuthenticationType": category === "sshOutBoundAuthenticationType" ? keyword.trim() : props.sshOutBound.sshOutBoundAuthenticationType,
        "sshOutBoundPassword": category === "sshOutBoundPassword" ? keyword : props.sshOutBound.sshOutBoundPassword,
        "sshOutBoundConfirmPassword": category === "sshOutBoundConfirmPassword" ? keyword : props.sshOutBound.sshOutBoundConfirmPassword,
        "shhOutUserIdentityKey": category === "shhOutUserIdentityKey" ? keyword : props.sshOutBound.shhOutUserIdentityKey,
        "responseTimeout": category === "responseTimeout" ? keyword.trim() : props.sshOutBound.responseTimeout,
        "characterEncoding": category === "characterEncoding" ? keyword : props.sshOutBound.characterEncoding,
        "preferredCipher": category === "preferredCipher" ? keyword : props.sshOutBound.preferredCipher,
        "preferredMAC": category === "preferredMAC" ? keyword : props.sshOutBound.preferredMAC,
        "compression": category === "compression" ? keyword : props.sshOutBound.compression,
        "connectionRetryCount": category === "connectionRetryCount" ? keyword : props.sshOutBound.connectionRetryCount,
        "retryDelay": category === "retryDelay" ? keyword : props.sshOutBound.retryDelay,
        "localPortRange": category === "localPortRange" ? keyword : props.sshOutBound.localPortRange,
        "passPhrase": category === "passPhrase" ? keyword : props.sshOutBound.passPhrase,

        "bucketname": category === "bucketname" ? keyword : props.sshOutBound.bucketname,
        "region": category === "region" ? keyword : props.sshOutBound.region,
        "folderpath": category === "folderpath" ? keyword : props.sshOutBound.folderpath,
        "uploadFolderPath":category === "uploadFolderPath"? keyword : props.sshOutBound.uploadFolderPath,
        "s3OutboundIAMRole": category === "s3OutboundIAMRole" ? keyword : props.sshOutBound.s3OutboundIAMRole
      })
    }
  }

  const inputFieldValidation = () => {
    setInputErrorWarning({
      "Consumer": props.inputDataForRole.partnerRoleConsumer && !props.inputDataForRole.partnerRoleConsumerInitiate && !props.inputDataForRole.partnerRoleConsumerListen ? "Choose Any Option" : null,
      "Producer": props.inputDataForRole.partnerRoleProducer && !props.inputDataForRole.partnerRoleProducerInitiate && !props.inputDataForRole.partnerRoleProducerListen ? "Choose Any Option" : null,
      "OutboundConn": (props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "" ? "Choose Any Option" : null,
    })
    if (
      (!props.inputDataForRole.partnerRoleConsumer || (props.inputDataForRole.partnerRoleConsumer && (props.inputDataForRole.partnerRoleConsumerInitiate || props.inputDataForRole.partnerRoleConsumerListen))) &&
      (!props.inputDataForRole.partnerRoleProducer || (props.inputDataForRole.partnerRoleProducer && (props.inputDataForRole.partnerRoleProducerInitiate || props.inputDataForRole.partnerRoleProducerListen)))
    ) {
      return true
    } return false
  }

  function outboundPasswordConditionCheck() {

    var result = null
    if (authTypeOutbound.pass || props.sshOutBound.sshOutBoundAuthenticationType === "Password" && (props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen)) {
      //Creation 
      if (!props.updationStatus) {
        if (valueIsNotNull(props.sshOutBound.sshOutBoundPassword)) {
          /* if (new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.sshOutBound.sshOutBoundPassword)) {
             result = sshBoundChecker("sshOutBoundPassword", "", true)
           } else if (!new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.sshOutBound.sshOutBoundPassword)) {
             result = sshBoundChecker("sshOutBoundPassword", "Invalid password match", false)
           }*/
          result = sshBoundChecker("sshOutBoundPassword", "", true)
        } else if (valueIsNull(props.sshOutBound.sshOutBoundPassword)) {
          result = sshBoundChecker("sshOutBoundPassword", "Enter Password", false)
        }
      }
      //Updation
      else if (props.updationStatus) {
        if (props.outboundAuthTypeFromDB === "Password" && !props.inOutPasswordUpdateFlag.outbnd) { //skip updation
          result = sshBoundChecker("sshOutBoundPassword", "", true)
        } else if (props.outboundAuthTypeFromDB === "SSH") { //add password instead of ssh
          if (valueIsNotNull(props.sshOutBound.sshOutBoundPassword)) {
            /*if (new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.sshOutBound.sshOutBoundPassword)) {
              result = sshBoundChecker("sshOutBoundPassword", "", true)
            } else if (!new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.sshOutBound.sshOutBoundPassword)) {
              result = sshBoundChecker("sshOutBoundPassword", "Invalid Password Match", false)
            }*/
            result = sshBoundChecker("sshOutBoundPassword", "", true)
          } else if (valueIsNull(props.sshOutBound.sshOutBoundPassword)) {
            result = sshBoundChecker("sshOutBoundPassword", "Enter Password", false)
          }
        } else if (props.outboundAuthTypeFromDB === "Password" && props.inOutPasswordUpdateFlag.outbnd) { //update password
          if (valueIsNotNull(props.sshOutBound.sshOutBoundPassword)) {
            /* if (new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.sshOutBound.sshOutBoundPassword)) {
               result = sshBoundChecker("sshOutBoundPassword", "", true)
             } else if (!new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.sshOutBound.sshOutBoundPassword)) {
               result = sshBoundChecker("sshOutBoundPassword", "Invalid Password Match", false)
             }*/
            result = sshBoundChecker("sshOutBoundPassword", "", true)
          } else if (valueIsNull(props.sshOutBound.sshOutBoundPassword)) {
            result = sshBoundChecker("sshOutBoundPassword", "Enter Password", false)
          }
        } else if (valueIsNotNull(props.sshOutBound.sshOutBoundPassword)) {
          result = sshBoundChecker("sshOutBoundPassword", "", true)
        }
      }
    } else {
      result = sshBoundChecker("sshOutBoundPassword", "", true)
    }
    return result
  }

  function inboundPasswordConditionCheck() {
    var result = null
    if ((authTypeInbound.pass || props.sshInBound.sshInBoundAuthenticationType === "Password") && (props.inputDataForRole.partnerRoleConsumerInitiate || props.inputDataForRole.partnerRoleProducerInitiate)) {
      //Creation
      if (!props.updationStatus) {
        if (props.inputDataForUserDetails.isAccessPortal && props.sshInBound.sshInBoundAuthenticationType === "Password") {
          result = sshBoundChecker("password", "", true)
        }
        else if (valueIsNotNull(props.sshInBound.password)) {
          if (new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.sshInBound.password)) {
            result = sshBoundChecker("password", "", true)
          } else if (!new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.sshInBound.password)) {
            result = sshBoundChecker("password", "Invalid password match", false)
          }
        } else if (valueIsNull(props.sshInBound.password)) {
          result = sshBoundChecker("password", "Enter Password", false)
        }
      }
      //Updation
      else if (props.updationStatus) {
        if (!props.inputDataForUserDetails.isAccessPortal) {
          if (props.sshInBound.sshInBoundAuthenticationType === "SSH") {
            result = sshBoundChecker("password", "", true)
          } else if (props.sshInBound.sshInBoundAuthenticationType === "Password" && props.inOutPasswordUpdateFlag.inbnd && props.inboundAuthTypeFromDB === "Password") {
            if (valueIsNull(props.sshInBound.password)) {
              result = sshBoundChecker("password", "Enter Password", false)
            } else if (valueIsNotNull(props.sshInBound.password)) {
              if (new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.sshInBound.password)) {
                result = sshBoundChecker("password", "", true)
              } else if (!new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.sshInBound.password)) {
                result = sshBoundChecker("password", "Invalid Password Match", false)
              }
            }
          } else if (props.sshInBound.sshInBoundAuthenticationType === "Password" && props.inboundAuthTypeFromDB === "Password" && !props.inOutPasswordUpdateFlag.inbnd) {
            result = sshBoundChecker("password", "", true)
          } else if (props.sshInBound.sshInBoundAuthenticationType === "Password" && props.inboundAuthTypeFromDB === "SSH") {
            if (valueIsNull(props.sshInBound.password)) {
              result = sshBoundChecker("password", "Enter Password", false)
            } else if (valueIsNotNull(props.sshInBound.password)) {
              if (new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.sshInBound.password)) {
                result = sshBoundChecker("password", "", true)
              } else if (!new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.sshInBound.password)) {
                result = sshBoundChecker("password", "Invalid Password Match", false)
              }
            }
          }
        } else {
          result = sshBoundChecker("password", "", true)
        }
      }
    } else {
      result = sshBoundChecker("password", "", true)
    }
    return result
  }


  const valueIsNull = (v1) => { return v1 === null || v1 === "" ? true : false; }
  const valueIsNotNull = (v1) => { return v1 !== null && v1 !== "" ? true : false; }

  const sshBoundChecker = (category, message, status) => {
    warningCache = {
      sshInBoundAuthenticationType: category === "sshInBoundAuthenticationType" ? status : warningCache.sshInBoundAuthenticationType,
      userPublicKey: category === "userPublicKey" ? status : warningCache.userPublicKey,
      password: category === "password" ? status : warningCache.password,
      confirmPassword: category === "confirmPassword" ? status : warningCache.confirmPassword,
      remoteHost: category === "remoteHost" ? status : warningCache.remoteHost,
      root: category === "root" ? status : warningCache.root,
      port: category === "port" ? status : warningCache.port,
      knownHostKey: category === "knownHostKey" ? status : warningCache.knownHostKey,
      remoteUser: category === "remoteUser" ? status : warningCache.remoteUser,
      downloadDirectory: category === "downloadDirectory" ? status : warningCache.downloadDirectory,
      uploadDirectory: category === "uploadDirectory" ? status : warningCache.uploadDirectory,
      sshOutBoundPassword: category === "sshOutBoundPassword" ? status : warningCache.sshOutBoundPassword,
      sshOutBoundConfirmPassword: category === "sshOutBoundConfirmPassword" ? status : warningCache.sshOutBoundConfirmPassword,
      sshOutBoundAuthenticationType: category === "sshOutBoundAuthenticationType" ? status : warningCache.sshOutBoundAuthenticationType,
      shhOutUserIdentityKey: category === "shhOutUserIdentityKey" ? status : warningCache.shhOutUserIdentityKey,
      responseTimeout: category === "responseTimeout" ? status : warningCache.responseTimeout,
      characterEncoding: category === "characterEncoding" ? status : warningCache.characterEncoding,
      preferredCipher: category === "preferredCipher" ? status : warningCache.preferredCipher,
      preferredMAC: category === "preferredMAC" ? status : warningCache.preferredMAC,
      compression: category === "compression" ? status : warningCache.compression,
      connectionRetryCount: category === "connectionRetryCount" ? status : warningCache.connectionRetryCount,
      retryDelay: category === "retryDelay" ? status : warningCache.retryDelay,
      localPortRange: category === "localPortRange" ? status : warningCache.localPortRange,
      passPhrase: category === "passPhrase" ? status : warningCache.passPhrase,

      bucketname: category === "bucketname" ? status : warningCache.bucketname,
      region: category === "region" ? status : warningCache.region,
      folderpath: category === "folderpath" ? status : warningCache.folderpath,
      uploadFolderPath: category === "uploadFolderPath" ? status : warningCache.uploadFolderPath,
      s3OutboundIAMRole: category === "s3OutboundIAMRole" ? status : warningCache.s3OutboundIAMRole
    };
    return message
  }


  const sshBoundFieldValidation = () => {
    setSSHBoundErrorWarning({
      "sshInBoundAuthenticationType": (props.inputDataForRole.partnerRoleConsumerInitiate || props.inputDataForRole.partnerRoleProducerInitiate) && valueIsNull(props.sshInBound.sshInBoundAuthenticationType) ? sshBoundChecker("sshInBoundAuthenticationType", "Choose Any Authentication Type ", false) : sshBoundChecker("sshInBoundAuthenticationType", "", true),
      "userPublicKey": props.sshInBound.sshInBoundAuthenticationType === "SSH" && valueIsNull(props.sshInBound.userPublicKey) ? sshBoundChecker("userPublicKey", "Enter Authorized User Key", false) : sshBoundChecker("userPublicKey", "", true),
      "password": inboundPasswordConditionCheck(),
      //props.sshInBound.sshInBoundAuthenticationType === "Password" && !props.inputDataForUserDetails.isAccessPortal ? (props.sshInBound.password === "" ? sshBoundChecker("password", "Enter Password", false) : new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9'])(?=.*?[\".*\"/<>\\\\.,;:'--\(|_+=#?!@$%{}\\[\\]^&*-]).{8,20}$").test(props.sshInBound.password) ? sshBoundChecker("password", "", true) : sshBoundChecker("password", "Invalid password match", false)) : sshBoundChecker("password", "", true),
      "confirmPassword": props.sshInBound.sshInBoundAuthenticationType === "Password" && valueIsNull(props.sshInBound.confirmPassword) && valueIsNotNull(props.sshInBound.password) ? sshBoundChecker("confirmPassword", "Re-enter Password ", false) : props.sshInBound.password === props.sshInBound.confirmPassword ? sshBoundChecker("confirmPassword", "", true) : sshBoundChecker("confirmPassword", "Please Ensure Passwords Match", false),

      "remoteHost": valueIsNull(props.sshOutBound.remoteHost) && ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") ? sshBoundChecker("remoteHost", "Enter Remote Host", false) : sshBoundChecker("remoteHost", "", true),
      "root": valueIsNull(props.sshOutBound.root) && ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") ? sshBoundChecker("root", "Enter Root", false) : sshBoundChecker("root", "", true),
      "port": ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") && valueIsNull(props.sshOutBound.port) ? sshBoundChecker("port", "Enter Port", false) : (((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") && (props.sshOutBound.port.length < 2 || props.sshOutBound.port.toString().length > 7)) ? sshBoundChecker("port", "Port Length Should Between 2 - 7", false) : sshBoundChecker("port", "", true),
      "remoteUser": valueIsNull(props.sshOutBound.remoteUser) && ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") ? sshBoundChecker("remoteUser", "Enter Remote User", false) : sshBoundChecker("remoteUser", "", true),
      "downloadDirectory": valueIsNull(props.sshOutBound.downloadDirectory) && props.inputDataForRole.partnerRoleConsumerListen ? /*sshBoundChecker("downloadDirectory", "Enter Download Directory", false)*/  sshBoundChecker("downloadDirectory", "", true) : sshBoundChecker("downloadDirectory", "", true),
      "uploadDirectory": valueIsNull(props.sshOutBound.uploadDirectory) && props.inputDataForRole.partnerRoleProducerListen ? /*sshBoundChecker("uploadDirectory", "Enter Upload Directory", false)/* - rm mandatory feature (some partner's directory is root*/ sshBoundChecker("uploadDirectory", "", true) : sshBoundChecker("uploadDirectory", "", true),
      "sshOutBoundAuthenticationType": valueIsNull(props.sshOutBound.sshOutBoundAuthenticationType) && ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") ? sshBoundChecker("sshOutBoundAuthenticationType", "Choose Any Authentication Type ", false) : sshBoundChecker("sshOutBoundAuthenticationType", "", true),
      "sshOutBoundPassword": outboundPasswordConditionCheck(),
      "sshOutBoundConfirmPassword": (authTypeOutbound.pass || props.sshOutBound.sshOutBoundAuthenticationType === "Password" && valueIsNotNull(props.sshOutBound.sshOutBoundPassword)) && valueIsNull(props.sshOutBound.sshOutBoundConfirmPassword) && ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") ? sshBoundChecker("sshOutBoundConfirmPassword", "Re-enter Password ", false) : props.sshOutBound.sshOutBoundPassword.trim() === props.sshOutBound.sshOutBoundConfirmPassword.trim() ? sshBoundChecker("sshOutBoundConfirmPassword", "", true) : sshBoundChecker("sshOutBoundConfirmPassword", "Please Ensure Passwords Match", false),
      "shhOutUserIdentityKey": (authTypeOutbound.ssh || props.sshOutBound.sshOutBoundAuthenticationType === "SSH") && valueIsNull(props.sshOutBound.shhOutUserIdentityKey) && ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") ? sshBoundChecker("shhOutUserIdentityKey", "Enter User Identity File Name", false) : sshBoundChecker("shhOutUserIdentityKey", "", true),
      "responseTimeout": (authTypeOutbound.ssh || props.sshOutBound.sshOutBoundAuthenticationType === "SSH") && valueIsNull(props.sshOutBound.responseTimeout) && ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") ? sshBoundChecker("responseTimeout", "Enter Response Timeout", false) : sshBoundChecker("responseTimeout", "", true),
      "knownHostKey": (authTypeOutbound.ssh || props.sshOutBound.sshOutBoundAuthenticationType === "SSH") && valueIsNull(props.sshOutBound.knownHostKey) && ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") ? sshBoundChecker("knownHostKey", "Enter Known Host Key", false) : sshBoundChecker("knownHostKey", "", true),
      "characterEncoding": (authTypeOutbound.ssh || props.sshOutBound.sshOutBoundAuthenticationType === "SSH") && valueIsNull(props.sshOutBound.characterEncoding) && ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") ? sshBoundChecker("characterEncoding", "Enter Character Encoding", false) : sshBoundChecker("characterEncoding", "", true),
      "preferredCipher": (authTypeOutbound.ssh || props.sshOutBound.sshOutBoundAuthenticationType === "SSH") && (props.sshOutBound.preferredCipher === null || props.sshOutBound.preferredCipher.trim() === "" || props.sshOutBound.preferredCipher === "Null") && ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") ? sshBoundChecker("preferredCipher", "Choose Any Cipher", false) : sshBoundChecker("preferredCipher", "", true),
      "preferredMAC": (authTypeOutbound.ssh || props.sshOutBound.sshOutBoundAuthenticationType === "SSH") && (props.sshOutBound.preferredMAC === null || props.sshOutBound.preferredMAC.trim() === "" || props.sshOutBound.preferredMAC === "Null") && ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") ? sshBoundChecker("preferredMAC", "Choose Any MAC Algorithem", false) : sshBoundChecker("preferredMAC", "", true),
      "compression": (authTypeOutbound.ssh || props.sshOutBound.sshOutBoundAuthenticationType === "SSH") && (props.sshOutBound.compression === null || props.sshOutBound.compression.trim() === "" || props.sshOutBound.compression === "Null") && ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") ? sshBoundChecker("compression", "Choose Any Compression", false) : sshBoundChecker("compression", "", true),
      "connectionRetryCount": (authTypeOutbound.ssh || props.sshOutBound.sshOutBoundAuthenticationType === "SSH") && valueIsNull(props.sshOutBound.connectionRetryCount) && ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") ? sshBoundChecker("connectionRetryCount", "Enter Connection Retry Count", false) : sshBoundChecker("connectionRetryCount", "", true),
      "retryDelay": (authTypeOutbound.ssh || props.sshOutBound.sshOutBoundAuthenticationType === "SSH") && valueIsNull(props.sshOutBound.retryDelay) && ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") ? sshBoundChecker("retryDelay", "Enter Retry Delay", false) : sshBoundChecker("retryDelay", "", true),
      "localPortRange": (authTypeOutbound.ssh || props.sshOutBound.sshOutBoundAuthenticationType === "SSH") && valueIsNull(props.sshOutBound.localPortRange) && ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") ? sshBoundChecker("localPortRange", "Enter Local Port Range", false) : sshBoundChecker("localPortRange", "", true),
      "passPhrase": (authTypeOutbound.ssh || props.sshOutBound.sshOutBoundAuthenticationType === "SSH") && valueIsNull(props.sshOutBound.passPhrase) && ((props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP") ? sshBoundChecker("passPhrase", "Enter PassPhrase", false) : sshBoundChecker("passPhrase", "", true),

      "bucketname": (props.inputDataForRole.outboundConnectionType === "S3BUCKET" && (props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen)) ? valueIsNull(props.sshOutBound.bucketname) ? sshBoundChecker("bucketname", "Enter Bucket Name", false) : sshBoundChecker("bucketname", "", true) : sshBoundChecker("bucketname", "", true),
      "region": (props.inputDataForRole.outboundConnectionType === "S3BUCKET" && (props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen)) ? valueIsNull(props.sshOutBound.region) ? sshBoundChecker("region", "Enter Region", false) : sshBoundChecker("region", "", true) : sshBoundChecker("region", "", true),
      "folderpath": (props.inputDataForRole.outboundConnectionType === "S3BUCKET" && (props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen)) ? valueIsNull(props.sshOutBound.folderpath) ? sshBoundChecker("folderpath", "Enter Download Folder Path", false) : sshBoundChecker("folderpath", "", true) : sshBoundChecker("folderpath", "", true),
      "uploadFolderPath": (props.inputDataForRole.outboundConnectionType === "S3BUCKET" && (props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen)) ? valueIsNull(props.sshOutBound.uploadFolderPath) ? sshBoundChecker("uploadFolderPath", "Enter Upload Folder Path", false) : sshBoundChecker("uploadFolderPath", "", true) : sshBoundChecker("uploadFolderPath", "", true),
      "s3OutboundIAMRole": (props.inputDataForRole.s3OutboundIAMRole === "S3BUCKET" && (props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen)) ? valueIsNull(props.sshOutBound.s3OutboundIAMRole) ? sshBoundChecker("s3OutboundIAMRole", "Enter Iam Role", false) : sshBoundChecker("s3OutboundIAMRole", "", true) : sshBoundChecker("s3OutboundIAMRole", "", true)
    })
    if (authTypeOutbound.ssh && (props.sshOutBound.preferredMAC === null || props.sshOutBound.preferredMAC.trim() === "" || props.sshOutBound.preferredMAC === "Null") && (props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen)) {
      setMACConstrains({ "fieldPlaceholder": "Choose Any", "isMandatory": true, "fieldLabel": "Preferred MAC Algorithm", "inputErrorMessage": "Choose Any MAC Algorithem" })
    } else {
      setMACConstrains({ "fieldPlaceholder": "Choose Any", "isMandatory": true, "fieldLabel": "Preferred MAC Algorithm", "inputErrorMessage": "" })
    }
    if (authTypeOutbound.ssh && (props.sshOutBound.preferredCipher === null || props.sshOutBound.preferredCipher.trim() === "" || props.sshOutBound.preferredCipher === "Null") && (props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen)) {
      setCipherConstrains({ "fieldPlaceholder": "Choose Any", "isMandatory": true, "fieldLabel": "Preferred Cipher", "inputErrorMessage": "Choose Any Cipher" })
    } else {
      setCipherConstrains({ "fieldPlaceholder": "Choose Any", "isMandatory": true, "fieldLabel": "Preferred Cipher", "inputErrorMessage": "" })
    } if (authTypeOutbound.ssh && (props.sshOutBound.compression === null || props.sshOutBound.compression.trim() === "" || props.sshOutBound.compression === "Null") && (props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen)) {
      setCompressionConstrains({ "fieldPlaceholder": "Choose Any", "isMandatory": true, "fieldLabel": "Compression", "inputErrorMessage": "Choose Any Compression" })
    } else {
      setCompressionConstrains({ "fieldPlaceholder": "Choose Any", "isMandatory": true, "fieldLabel": "Compression", "inputErrorMessage": "" })
    }
  }
  const onSubmit = (event) => {
    event.preventDefault();
    console.log(props.inputDataForRole);
    console.log(props.sshInBound);
    console.log(props.sshOutBound);
    console.log(props.spdElements);
    sshBoundFieldValidation()
    if (inputFieldValidation() && spdValidation()) {
      if (!props.inputDataForRole.partnerRoleConsumer && !props.inputDataForRole.partnerRoleProducer) {
        props.setCursor('Partner_Protocol')
      }
      else if (warningCache.sshInBoundAuthenticationType && warningCache.userPublicKey && warningCache.password && warningCache.confirmPassword && warningCache.remoteHost && warningCache.root && warningCache.port && warningCache.knownHostKey &&
        warningCache.remoteUser && warningCache.downloadDirectory && /*warningCache.uploadDirectory &&*/ warningCache.sshOutBoundAuthenticationType && warningCache.shhOutUserIdentityKey && warningCache.responseTimeout && warningCache.sshOutBoundConfirmPassword && warningCache.sshOutBoundPassword &&
        warningCache.characterEncoding && warningCache.preferredCipher && warningCache.preferredMAC && warningCache.compression && warningCache.connectionRetryCount && warningCache.retryDelay && warningCache.localPortRange && warningCache.passPhrase &&
        warningCache.bucketname && warningCache.region && warningCache.folderpath && warningCache.uploadFolderPath && warningCache.s3OutboundIAMRole) {
        props.setCursor('Partner_Protocol')
      }
      else {
        console.log(warningCache);
        console.log("validation failed");
      }
    }

  }

  const onReset = () => {
    setInputErrorWarning('')
    setSSHBoundErrorWarning('')
    setAuthTypeOutbound({ "ssh": false, "pass": false })
    setAuthTypeInbound({ "ssh": false, "pass": false })
    warningCache = { sshInBoundAuthenticationType: false, userPublicKey: false, password: false, confirmPassword: false, remoteHost: false,root: false, port: false, knownHostKey: false, remoteUser: false, downloadDirectory: false, uploadDirectory: false, sshOutBoundAuthenticationType: false, shhOutUserIdentityKey: false, responseTimeout: false, characterEncoding: false, preferredCipher: false, preferredMAC: false, compression: false, connectionRetryCount: false, retryDelay: false, localPortRange: false, passPhrase: false };
    props.setInputDataForRole(PartnerRoleModel)
    props.setSSHInBound({ "sshInBoundAuthenticationType": "", "userPublicKey": "", "password": "", "confirmPassword": "" })
    props.setSHHOutBound({ "remoteHost": "","root":"", "port": "", "knownHostKey": "", "remoteUser": "", "downloadDirectory": "", "uploadDirectory": "", "sshOutBoundAuthenticationType": "", "sshOutBoundPassword": "", "sshOutBoundConfirmPassword": "", "shhOutUserIdentityKey": "", "responseTimeout": "", "characterEncoding": "", "preferredCipher": "", "preferredMAC": "", "compression": "", "connectionRetryCount": "", "retryDelay": "", "localPortRange": "", "passPhrase": "" })
  }

  let handleChange = (i, e) => {
    let newspdElements = [...props.spdElements];
    newspdElements[i][e.target.name] = e.target.value;
    props.setSpdElements(newspdElements);
  }

  let addFormFields = () => {
    props.setSpdElements([...props.spdElements, { f_permission: "", f_pattern: "", r_suffix: "", r_prefix: "", sshDetailsId: 0, sshId: 0, operationFlag: "I" }])
  }

  let removeFormFields = (i) => {
    let newspdElements = [...props.spdElements];
    newspdElements.splice(i, 1);
    props.setSpdElements(newspdElements)
  }

  const spdValidation = () => {
    if(props.inputDataForRole.outboundConnectionType === "S3BUCKET"){
      console.log("Outbound Conn Type:"+props.inputDataForRole.outboundConnectionType);
      return true;
    }
    //(props.inputDataForRole.partnerRoleConsumer && props.inputDataForRole.partnerRoleConsumerListen) || 
    if ((props.inputDataForRole.partnerRoleProducer && props.inputDataForRole.partnerRoleProducerListen)) {
      let spde = [...props.spdElements];
      let tempJsonArray = []
      spde.forEach(element => {
        var tempJson = { f_permission: "", f_pattern: "", r_suffix: "", r_prefix: "" }
        if (element.f_permission === '') { tempJson.f_permission = "Choose Any"; } else { tempJson.f_permission = ""; }
        if (element.f_pattern === '') { tempJson.f_pattern = "Enter Pattern"; } else { tempJson.f_pattern = ""; }
        if (element.r_suffix === '' && element.f_permission === 'RENAME' && (element.r_suffix === '' || element.r_prefix === '')) { tempJson.r_suffix = "Enter Suffix"; } else { tempJson.r_suffix = ""; }
        if (element.r_prefix === '' && element.f_permission === 'RENAME' && (element.r_suffix === '' || element.r_prefix === '')) { tempJson.r_prefix = "Enter Prefix"; } else { tempJson.r_prefix = ""; }
        tempJsonArray.push(tempJson)
      });
      setSpdErrorIndo(tempJsonArray)
      if (spde.some(data => data.f_permission === '')) {
        return false;
      } else if (spde.some(data => data.f_pattern === '')) {
        return false
      }
      else if (spde.some(data => data.r_prefix === '' && data.f_permission === 'RENAME' && data.r_suffix === '')) {
        return false
      }
      /* else if (spde.some(data => data.r_suffix === '' && data.f_permission === 'RENAME')) {
        return false
      }*/
      else {
        return true
      }
    } else {
      return true
    }
  }
  const inboundPasswordJSXCondition = () => {
    if (!props.updationStatus && (authTypeInbound.pass || props.sshInBound.sshInBoundAuthenticationType === "Password") && !props.inputDataForUserDetails.isAccessPortal) { return true }
    else if (props.updationStatus && (authTypeInbound.pass || props.sshInBound.sshInBoundAuthenticationType === "Password") && props.inOutPasswordUpdateFlag.inbnd) { return true }
    else if (props.updationStatus && (authTypeInbound.pass || props.sshInBound.sshInBoundAuthenticationType === "Password") && props.inboundAuthTypeFromDB === "SSH") { return true }
    else { return false }
  }

  const outboundPasswordJSXCondition = () => {
    if (authTypeOutbound.pass || props.sshOutBound.sshOutBoundAuthenticationType === "Password") {
      console.log(props.outboundAuthTypeFromDB === "Password");
      if (!props.updationStatus) {
        if (authTypeOutbound.pass || props.sshOutBound.sshOutBoundAuthenticationType === "Password") { return true }
        else if (authTypeOutbound.ssh || props.sshOutBound.sshOutBoundAuthenticationType === "SSH") { return false }
      } else if (props.updationStatus) {
        if ((props.outboundAuthTypeFromDB === "Password" && props.inOutPasswordUpdateFlag.outbnd) || (props.outboundAuthTypeFromDB === "SSH")) { return true }
        if ((!props.outboundAuthTypeFromDB != "Password") && authTypeOutbound.pass) { console.log("dgdfgd"); return true }
      }
    } else { return false }
  }

  const diretoryForDownloadOutbound = () => {
    if (props.inputDataForRole.partnerRoleConsumerListen)
      return (
        <div className="col-lg-6 col-md-6">
          <div className="form-group">
            <label for="f1" className="input-label">Directory to which files should be uploaded by Router<img title='Router pushes files' src={info_icon} alt="info" /></label>
            <div className="input-wrap select-wrap">
              <input type="text" value={props.sshOutBound.downloadDirectory} placeholder="Enter Directory" onChange={((e) => setValuesToSSHBounds("downloadDirectory", e.target.value, false))} />
              <div className="input-wrap">  {sshBoundErrorWarning.downloadDirectory ? (<div className="error-message">{sshBoundErrorWarning.downloadDirectory}</div>) : ('')}</div>
            </div>
          </div>
        </div>)
  }

  const directoryForUploadOutbound = () => {
    if (props.inputDataForRole.partnerRoleProducerListen)
      return (
        <div className="col-lg-6 col-md-6">
          <div className="form-group">
            <label for="f1" className="input-label">Directory to which Partner needs to Upload the files.<img title='Router pulls files' src={info_icon} alt="info" /></label>
            <div className="input-wrap select-wrap">
              <input type="text" value={props.sshOutBound.uploadDirectory} placeholder="Enter Directory" onChange={((e) => setValuesToSSHBounds("uploadDirectory", e.target.value, false))} />
              <div className="input-wrap"> {sshBoundErrorWarning.uploadDirectory ? (<div className="error-message">{sshBoundErrorWarning.uploadDirectory}</div>) : ('')}</div>
            </div>
          </div>
        </div>
      )
  }

  const fileOperationJSX = () => {
    return <div>
      <hr />
      <div class="btn-wrap text-right" onClick={() => addFormFields()}>
        <a class="link" href="javascript:void(0)" >
          <i class="fa fa-plus mr-2" aria-hidden="true"></i>Add Item </a>
      </div>
      {props.spdElements.map((element, index) => (
        <div>
          <div className="row" key={index}>
            <div className="col-lg-6 col-md-6" key={index}>
              <div className="form-group">
                <label for="f1" className="input-label"> File Permission<span style={{ color: 'red' }}>*</span></label>
                <div className="input-wrap select-wrap">
                  <select id="f12" name="f_permission" value={element.f_permission} onChange={(e) => handleChange(index, e)}>
                    <option value=''>Choose Any</option>
                    <option value='RENAME'>Rename</option>
                    <option value='DELETE'>Delete</option>
                  </select>
                  <div className="input-wrap">  {spdErrorIndo[index] && spdErrorIndo[index].f_permission ? (<div className="error-message">{spdErrorIndo[index].f_permission}</div>) : ('')}</div>
                </div>
              </div>
            </div>
            {/* {element.f_permission === "RENAME" || element.f_permission === "DELETE" ? */}
            <div className="col-lg-6 col-md-6" key={index}>
            <div className="form-group">
              <label for="f14" className="input-label"> File Pattern<span style={{ color: 'red' }}>*</span><img title='Ex : Filename is "ABCD01.txt", then the valid patterns "ABC", "BCD", "CD0", "BCD0", "ABCD" ' src={info_icon} alt="info" /></label>
              <div className="input-wrap txt-wrap">
                <input className='txt' type="text" name="f_pattern" placeholder="Enter File Pattern" id="f14" value={element.f_pattern} onChange={e => handleChange(index, e)} />
                <div className="input-wrap">  {spdErrorIndo[index] && spdErrorIndo[index].f_pattern ? (<div className="error-message">{spdErrorIndo[index].f_pattern}</div>) : ('')}</div>
              </div>
            </div>
              </div>
               {/* : ''} */}
            {/* {element.f_permission === "RENAME" ? */}
              <div className="col-lg-6 col-md-6" key={index}>
              <div className="form-group">
                <label for="f15" className="input-label"> Rename Prefix</label>
                {/* <span style={{ color: 'red' }}>{element.f_permission === "RENAME" ? "*" : ""}</span> */}
                <div className="input-wrap txt-wrap">
                  <input className='txt' type="text" name="r_prefix" placeholder={element.f_permission === "DELETE" ? "" : "Enter Prefix"} id="f15" disabled={element.f_permission === "DELETE" ? true : false} value={element.f_permission === "DELETE" ? "" : element.r_prefix} onChange={e => handleChange(index, e)} />
                  <div className="input-wrap">  {spdErrorIndo[index] && spdErrorIndo[index].r_prefix ? (<div className="error-message">{spdErrorIndo[index].r_prefix}</div>) : ('')}</div>
                </div>
              </div>
            </div>
            {/* :''} */}
            {/* {element.f_permission === "RENAME"  ? */}
            <div className="col-lg-6 col-md-6" key={index}>
            <div className="form-group">
              <label for="f16" className="input-label"> Rename Suffix</label>
              {/* <span style={{ color: 'red' }}>{element.f_permission == "RENAME" ? "*" : ""}</span> */}
              <div className="input-wrap txt-wrap">
                <input className='txt' type="text" name="r_suffix" placeholder={element.f_permission === "DELETE" ? "" : "Enter Suffix"} id="f16" disabled={element.f_permission === "DELETE" ? true : false} value={element.f_permission === "DELETE" ? "" : element.r_suffix} onChange={e => handleChange(index, e)} />
                <div className="input-wrap">  {spdErrorIndo[index] && spdErrorIndo[index].r_suffix ? (<div className="error-message">{spdErrorIndo[index].r_suffix}</div>) : ('')}</div>
              </div>
            </div>
            </div>
          {/* :''} */}

          </div>

          {
            index ?
              <div class="btn-wrap text-right" onClick={() => removeFormFields(index)}>
                <a class="link" href="javascript:void(0)" >
                  <i class="fa fa-minus mr-2" aria-hidden="true"></i>Remove</a>
              </div>
              : null
          }</div>
      ))}
      <hr />
    </div>
  }

  return (
    <React.Fragment>

      <main>
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb__item">
              <a className="breadcrumb__link" href="">Manage Partner</a>
            </li>
            <li className="breadcrumb__item">
              <a className="breadcrumb__link" href='/partner'>Partner</a>
            </li>
            <li className="breadcrumb__item">
              <span className="breadcrumb__link" >Onboarding</span>
            </li>
          </ul>
          <div className="title">
            <h1>Partner Onboarding</h1>
          </div>
          <div className="tab tab--partner">
            <ul className="tab--partner__list">
              <li className="tab--partner__item is-completed">
                <div className="bar"></div>
                <div className="content">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill=""><rect fill="none" height="24" width="24" /><g><path d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,2.73L18,18H6l0-1.61c0-1.18,0.68-2.26,1.76-2.73 C8.93,13.14,10.37,12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1 C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85 C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M12,6c1.66,0,3,1.34,3,3 c0,1.66-1.34,3-3,3s-3-1.34-3-3C9,7.34,10.34,6,12,6z" /></g></svg>
                  </div>
                  <div className="text">Partner Profile</div>
                </div>
              </li>
              <li className="tab--partner__item is-completed">
                <div className="bar"></div>
                <div className="content">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill=""><rect fill="none" height="24" width="24" /><path d="M12,3L2,21h20L12,3z M13,8.92L18.6,19H13V8.92z M11,8.92V19H5.4L11,8.92z" /></svg>
                  </div>
                  <div className="text">User Details</div>
                </div>
              </li>
              <li className="tab--partner__item is-active">
                <div className="bar"></div>
                <div className="content">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill=""><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><g><circle cx="10" cy="8" r="4" /><path d="M10.67,13.02C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26 C10.47,18.87,10,17.49,10,16C10,14.93,10.25,13.93,10.67,13.02z" /><path d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z" /></g></g></svg>
                  </div>
                  <div className="text">Partner Role</div>
                </div>
              </li>
              <li className="tab--partner__item">
                <div className="bar"></div>
                <div className="content">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill=""><path d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="19" r="2" /><path d="M10 3h4v12h-4z" /></svg>
                  </div>
                  <div className="text">Protocol</div>
                </div>
              </li>
            </ul>
          </div>
          <h2 className="mb-3">Partner Role</h2>
          <form noValidate onSubmit={onSubmit}>
            <div className="questionnaire mb-5" >
              <div className="questionnaire__blcok" style={{ paddingBottom: props.inputDataForRole.partnerRoleConsumer ? '' : '0px' }} >
                <div className="question">
                  <div className="question-text">Does partner like to receive data ? </div>
                  <div className="chcek-wrap">
                    <div className="form-check">
                      <input type="checkbox" id="yes" checked={props.inputDataForRole.partnerRoleConsumer ? true : false} onChange={(() => { setValuesToVariable("partnerRoleConsumer", true) })} />
                      <label for="yes">Yes</label>
                    </div>
                    <div className="form-check">
                      <input type="checkbox" checked={props.inputDataForRole.partnerRoleConsumer ? false : true} id="no" onChange={(() => { setValuesToVariable("partnerRoleConsumer", false) })} />
                      <label for="no">No</label>
                    </div>
                  </div>
                </div>
                {props.inputDataForRole.partnerRoleConsumer ?
                  <div className="choose option ">
                    <ul>
                      <li>
                        <input type="radio" id="test1" name="radio-group" checked={props.inputDataForRole.partnerRoleConsumerInitiate} onChange={(() => { setValuesToVariable("partnerRoleConsumerInitiate", true); })} />
                        <label for="test1"> Partner pulls files </label>
                        {/* - partnerRoleConsumerInitiate */}
                      </li>
                      <li>
                        <input type="radio" id="test2" name="radio-group" checked={props.inputDataForRole.partnerRoleConsumerListen} onChange={(() => { setValuesToVariable("partnerRoleConsumerListen", true); })} />
                        <label for="test2"> Router(PI Hub) pushes files </label>
                        {/* - partnerRoleConsumerListen */}
                      </li>
                    </ul>
                    <div className="input-wrap">{inputErrorWarning.Consumer ? (<div className="error-message">{inputErrorWarning.Consumer}</div>) : ('')}</div>


                  </div>
                  :
                  ""
                }
              </div>
              <div className="questionnaire__blcok" style={{ paddingBottom: props.inputDataForRole.partnerRoleProducer ? '' : '0px' }}>
                <div className="question">
                  <div className="question-text">Does partner like to send data ?</div>
                  <div className="chcek-wrap">
                    <div className="form-check">
                      <input type="checkbox" id="yes1" checked={props.inputDataForRole.partnerRoleProducer ? true : false} onChange={(() => { setValuesToVariable("partnerRoleProducer", true) })} />
                      <label for="yes1">Yes</label>
                    </div>
                    <div className="form-check">
                      <input type="checkbox" id="no1" checked={props.inputDataForRole.partnerRoleProducer ? false : true} onChange={(() => { setValuesToVariable("partnerRoleProducer", false) })} />
                      <label for="no1">No</label>
                    </div>
                  </div>
                </div>
                {props.inputDataForRole.partnerRoleProducer ?
                  <div className="choose option">
                    <ul>
                      <li>
                        <input type="radio" id="test3" name="radio-group1" checked={props.inputDataForRole.partnerRoleProducerInitiate} onChange={(() => { setValuesToVariable("partnerRoleProducerInitiate", true); })} />
                        <label for="test3"> Partner pushes files</label>
                        {/* - partnerRoleProducerInitiate */}
                      </li>
                      <li>
                        <input type="radio" id="test4" name="radio-group1" checked={props.inputDataForRole.partnerRoleProducerListen} onChange={(() => { setValuesToVariable("partnerRoleProducerListen", true); })} />
                        <label for="test4"> Router(PI Hub) pulls files</label>
                        {/* - partnerRoleProducerListen */}
                      </li>
                    </ul>
                    <div className="input-wrap">{inputErrorWarning.Producer ? (<div className="error-message">{inputErrorWarning.Producer}</div>) : ('')}</div>
                  </div>
                  :
                  ""}
              </div>
              {enablePartnerSize ? <div className="questionnaire__blcok" style={{ paddingBottom: props.inputDataForRole.setMaxFileSize ? '' : '0px' }}>
                <div className="question">
                  <div className="question-text">Set maximum file upload size for partner ?</div>
                  <div className="chcek-wrap">
                    <div className="form-check">
                      <input type="checkbox" id="yes2" checked={props.inputDataForRole.setMaxFileSize ? true : false} onChange={(() => { setValuesToVariable("setMaxFileSize", true) })} />
                      <label for="yes2">Yes</label>
                    </div>
                    <div className="form-check">
                      <input type="checkbox" id="no2" checked={props.inputDataForRole.setMaxFileSize ? false : true} onChange={(() => { setValuesToVariable("setMaxFileSize", false) })} />
                      <label for="no2">No</label>
                    </div>
                  </div>
                </div>
                {props.inputDataForRole.setMaxFileSize ?
                  <div className='row'>
                    <div className="col-lg-6 col-md-6" >
                      <div className="form-group">
                        <div className="input-wrap txt-wrap">
                          <CustomDropdownWidget dropdownList={filesizeList} editValue={valueForFilesizeEdit} constrains={filesizeConstrains} setResponseData={setFilesizeResponseData} ></CustomDropdownWidget>
                        </div>
                        <div className="input-wrap">  {inputErrorWarning.OutboundConn ? (<div className="error-message">{inputErrorWarning.OutboundConn}</div>) : ('')}</div>
                      </div>
                    </div>
                  </div>
                  :
                  ""}
              </div> : ""}
              {/* SSH Inbound Connection  */}
              {(props.inputDataForRole.partnerRoleConsumerInitiate || props.inputDataForRole.partnerRoleProducerInitiate) && (props.inputDataForRole.partnerRoleProducer || props.inputDataForRole.partnerRoleConsumer) ?
                <div>
                  <h2 className="mb-3">SFTP Inbound Connection</h2>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="f1" className="input-label">Username</label>
                        <div className="input-wrap select-wrap">
                          <input type="text" value={props.inputDataForUserDetails.ud_Username} disabled />
                        </div>
                      </div>
                    </div>

                    {props.inputDataForRole.partnerRoleConsumerInitiate ?
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label for="f1" className="input-label">Directory from where Partner needs to picks the files <img title='Partner pulls files' src={info_icon} alt="info" /></label>
                          <div className="input-wrap select-wrap">
                            <input type="text" value={props.inputDataProfile.PartnerCode ? props.inputDataProfile.PartnerCode + "/Download" : "" + "/Download"} disabled />
                          </div>
                        </div>
                      </div> : ""}
                    {props.inputDataForRole.partnerRoleProducerInitiate ?
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label for="f1" className="input-label">Directory from where Router picks the files <img title='Partner pushes files' src={info_icon} alt="info" /></label>
                          <div className="input-wrap select-wrap">
                            <input type="text" value={props.inputDataProfile.PartnerCode ? props.inputDataProfile.PartnerCode + "/Upload" : "" + "/Upload"} disabled />
                          </div>
                        </div>
                      </div> : ""}
                  </div>
                  <br></br><br></br>
                  <div className="questionnaire mb-6 ">
                    <div className="input-wrap">  {sshBoundErrorWarning.sshInBoundAuthenticationType ? (<div className="error-message">{sshBoundErrorWarning.sshInBoundAuthenticationType}</div>) : ('')}</div>
                    <div className="question">
                      <div className="question-text">Authentication Type<span style={{ color: 'red' }}>*</span></div>
                      <div className="chcek-wrap">
                        <div className="form-check">
                          <input type="checkbox" id="ssh" checked={authTypeInbound.ssh || (props.sshInBound.sshInBoundAuthenticationType !== null && props.sshInBound.sshInBoundAuthenticationType.trim() === "SSH")} onChange={(() => { setAuthTypeInbound({ "ssh": true, "pass": false }) })} onClick={(() => { setValuesToSSHBounds("sshInBoundAuthenticationType", "SSH", true) })} />
                          <label for="ssh">SSH</label>
                        </div>
                        <div className="form-check">
                          <input type="checkbox" id="pass" checked={authTypeInbound.pass || (props.sshInBound.sshInBoundAuthenticationType !== null && props.sshInBound.sshInBoundAuthenticationType.trim() === "Password") ? true : false} onChange={(() => { setAuthTypeInbound({ "ssh": false, "pass": true }) })} onClick={(() => { setValuesToSSHBounds("sshInBoundAuthenticationType", "Password", true) })} />
                          <label for="pass">Password</label>
                        </div>
                      </div>
                    </div>

                    <div class="questionnaire mb-6 ">
                      {
                        props.updationStatus && (props.updationStatus && !props.inputDataForUserDetails.isAccessPortal) &&
                          !props.isAccessPortalValueFromDB &&
                          props.inboundAuthTypeFromDB === "Password" &&
                          (authTypeInbound.pass || props.sshInBound.sshInBoundAuthenticationType === "Password") ?
                          <div className="question">
                            <div className="question-text">Update InBound Password?</div>
                            <div className="chcek-wrap">
                              <div className="form-check">
                                <input type="checkbox" id="updatesshInpass" checked={props.inOutPasswordUpdateFlag.inbnd ? true : false} onClick={() => props.setInOutPasswordUpdateFlag({ "inbnd": true, "outbnd": props.inOutPasswordUpdateFlag.outbnd })} />
                                <label for="updatesshInpass">Yes</label>
                              </div>
                              <div className="form-check">
                                <input type="checkbox" id="updatesshinpassno" checked={props.inOutPasswordUpdateFlag.inbnd ? false : true} onClick={() => props.setInOutPasswordUpdateFlag({ "inbnd": false, "outbnd": props.inOutPasswordUpdateFlag.outbnd })} />
                                <label for="updatesshinpassno">No</label>
                              </div>
                            </div>
                          </div>
                          :
                          ""}
                      {/* {!props.inputDataForUserDetails.isAccessPortal && (authTypeInbound.pass || props.sshInBound.sshInBoundAuthenticationType === "Password") && (props.updationStatus && props.inOutPasswordUpdateFlag.inbnd) ?
                       (!props.updationStatus || (props.updationStatus && props.passwordUpdateFlag) || (props.updationStatus && !props.isAccessPortalValueFromDB) */}

                      {/* {(!props.updationStatus && !props.inputDataForUserDetails.isAccessPortal && (authTypeInbound.pass || props.sshInBound.sshInBoundAuthenticationType === "Password"))
                        || (props.updationStatus && (!props.isAccessPortalValueFromDB && (authTypeInbound.pass || props.sshInBound.sshInBoundAuthenticationType === "Password" || props.inOutPasswordUpdateFlag.inbnd))) ? */}
                      {/* {(!props.updationStatus && (authTypeInbound.pass || props.sshInBound.sshInBoundAuthenticationType === "Password")) || (props.updationStatus && props.inOutPasswordUpdateFlag.inbnd && props.inboundAuthTypeFromDB === "Password") || (props.inboundAuthTypeFromDB === "SSH" && (authTypeInbound.pass || props.sshInBound.sshInBoundAuthenticationType === "Password")) ? */}
                      {inboundPasswordJSXCondition() ?
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label for="f133" className="input-label"> Password<span style={{ color: 'red' }}>*</span></label>
                              <div className="input-wrap password-wrap password-toggle">
                                <input value={props.sshInBound.password} onChange={((e) => setValuesToSSHBounds("password", e.target.value, true))} className='txt' type={eyeSlashIn.pass ? "password" : "text"} autoComplete="off" placeholder="Enter Password" id="f133" />
                                <div onClick={(() => { setEyeSlashIn({ "pass": eyeSlashIn.pass ? false : true, "confirmPass": eyeSlashIn.confirmPass }); })}>
                                  <i className={`toggle-eye fa fa-fw ${eyeSlashIn.pass ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                                </div>
                                <div className="input-wrap">  {sshBoundErrorWarning.password ? (<div className="error-message">{sshBoundErrorWarning.password}</div>) : ('')}</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label for="f122" className="input-label"> Re-enter Password<span style={{ color: 'red' }}>*</span></label>
                              <div className="input-wrap password-wrap password-toggle">
                                <input value={props.sshInBound.confirmPasswords} onPasteCapture={((e) => { e.preventDefault(); })} onChange={((e) => setValuesToSSHBounds("confirmPassword", e.target.value, true))} className='txt' type={eyeSlashIn.confirmPass ? "password" : "text"} autoComplete="off" placeholder="Re-enter Password" id="f122" />
                                <div onClick={(() => { setEyeSlashIn({ "confirmPass": eyeSlashIn.confirmPass ? false : true, "pass": eyeSlashIn.pass }); })}>
                                  <i className={`toggle-eye fa fa-fw ${eyeSlashIn.confirmPass ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                                </div>
                                <div className="input-wrap">  {sshBoundErrorWarning.confirmPassword ? (<div className="error-message">{sshBoundErrorWarning.confirmPassword}</div>) : ('')}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        props.inputDataForUserDetails.isAccessPortal && (authTypeInbound.pass || (props.sshInBound.sshInBoundAuthenticationType !== null && props.sshInBound.sshInBoundAuthenticationType.trim() === "Password")) ?
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <label className="input-label"> Password <span>(Portal password will be same as sftp password)</span></label>
                              <div className="input-wrap txt-wrap">
                                <input value={props.inputDataForUserDetails.ud_Password} disabled className='txt' type="password" />
                              </div>
                            </div>
                          </div>
                          : ""}
                      {(authTypeInbound.ssh || (props.sshInBound.sshInBoundAuthenticationType !== null && props.sshInBound.sshInBoundAuthenticationType.trim() === "SSH")) ?
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label for="f1" className="input-label"> Authorized User Key<span style={{ color: 'red' }}>*</span><span> (Partner public key)</span></label>
                              <div className="input-wrap txt-wrap">
                                <input className='txt' value={props.sshInBound.userPublicKey} type="text" onChange={((e) => setValuesToSSHBounds("userPublicKey", e.target.value, true))} autoComplete="off" placeholder="Enter user key" id="f1" />
                              </div>
                              <div className="input-wrap">  {sshBoundErrorWarning.userPublicKey ? (<div className="error-message">{sshBoundErrorWarning.userPublicKey}</div>) : ('')}</div>
                            </div>
                          </div>
                        </div>
                        :
                        ""
                      }
                    </div>
                  </div>
                  <hr />
                </div>
                :
                ""}
              {/* Drop down option for outbound connection method  */}
              {props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen ?
                <div>
                  <h2 className="mb-3">Outbound Connection</h2>
                  <div className='row'>
                    <div className="col-lg-6 col-md-6" >
                      <div className="form-group">
                        <label for="f1" className="input-label"> Type <span style={{ color: 'red' }}>*</span></label>
                        <div className="input-wrap select-wrap">
                          <select id="f12" name="f_permission" value={props.inputDataForRole.outboundConnectionType} onChange={((e) => setValuesToVariable("outboundConnectionType", e.target.value))}>
                            <option value=''>Choose Any</option>
                            <option value='SFTP'>SFTP</option>
                            <option value='S3BUCKET'>AWS S3 Bucket</option>
                          </select>
                          <div className="input-wrap">  {inputErrorWarning.OutboundConn ? (<div className="error-message">{inputErrorWarning.OutboundConn}</div>) : ('')}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : ""}
              {/* SHH outbound connection  */}

              {(props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "SFTP" ?
                <div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="f1" className="input-label">Remote Host<span style={{ color: 'red' }}>*</span></label>
                        <div className="input-wrap select-wrap">
                          <input type="text" value={props.sshOutBound.remoteHost} placeholder="Enter Remote Host" onChange={((e) => setValuesToSSHBounds("remoteHost", e.target.value, false))} />
                          <div className="input-wrap">  {sshBoundErrorWarning.remoteHost ? (<div className="error-message">{sshBoundErrorWarning.remoteHost}</div>) : ('')}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="f1" className="input-label">Root<span style={{ color: 'red' }}>*</span></label>
                        <div className="input-wrap select-wrap">
                          <input type="text" value={props.sshOutBound.root} placeholder="Enter Root" onChange={((e) => setValuesToSSHBounds("root", e.target.value, false))} />
                          <div className="input-wrap">  {sshBoundErrorWarning.root ? (<div className="error-message">{sshBoundErrorWarning.root}</div>) : ('')}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="f1" className="input-label">Port<span style={{ color: 'red' }}>*</span></label>
                        <input type="number" min="0" value={props.sshOutBound.port} placeholder="Enter Port Number" onChange={((e) => { setValuesToSSHBounds("port", e.target.value, false) })} />
                        <div className="input-wrap">  {sshBoundErrorWarning.port ? (<div className="error-message">{sshBoundErrorWarning.port}</div>) : ('')}</div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="f1" className="input-label">Remote User<span style={{ color: 'red' }}>*</span></label>
                        <div className="input-wrap select-wrap">
                          <input type="text" value={props.sshOutBound.remoteUser} placeholder="Enter Remote User" onChange={((e) => setValuesToSSHBounds("remoteUser", e.target.value, false))} />
                          <div className="input-wrap">  {sshBoundErrorWarning.remoteUser ? (<div className="error-message">{sshBoundErrorWarning.remoteUser}</div>) : ('')}</div>
                        </div>
                      </div>
                    </div>
                    {true ? diretoryForDownloadOutbound() : ""}
                    {true ? directoryForUploadOutbound() : "" }
                  </div>
                  {props.inputDataForRole.partnerRoleProducerListen ? fileOperationJSX() : ""}
                  <div className="questionnaire mb-6 ">
                    <br /><br /><br />
                    <div className="input-wrap">  {sshBoundErrorWarning.sshOutBoundAuthenticationType ? (<div className="error-message">{sshBoundErrorWarning.sshOutBoundAuthenticationType}</div>) : ('')}</div>
                    <div className="question">
                      <div className="question-text">Authentication Type<span style={{ color: 'red' }}>*</span></div>
                      <div className="chcek-wrap">
                        <div className="form-check">
                          <input type="checkbox" id="sshOut" checked={authTypeOutbound.ssh || props.sshOutBound.sshOutBoundAuthenticationType.trim() === "SSH" ? true : false} onChange={(() => { setAuthTypeOutbound({ "ssh": true, "pass": false }) })} onClick={(() => { setValuesToSSHBounds("sshOutBoundAuthenticationType", "SSH", false) })} />
                          <label for="sshOut">SSH</label>
                        </div>
                        <div className="form-check">
                          <input type="checkbox" id="passOut" checked={authTypeOutbound.pass || props.sshOutBound.sshOutBoundAuthenticationType.trim() === "Password" ? true : false} onChange={(() => { setAuthTypeOutbound({ "ssh": false, "pass": true }) })} onClick={(() => { setValuesToSSHBounds("sshOutBoundAuthenticationType", "Password", false) })} />
                          <label for="passOut">Password </label>
                        </div>
                      </div>
                    </div>
                    <div class="questionnaire mb-6 ">
                      {authTypeOutbound.pass || props.sshOutBound.sshOutBoundAuthenticationType.trim() === "Password" ?
                        <div>
                          {props.updationStatus && props.outboundAuthTypeFromDB === "Password" ?
                            <div className="question">
                              <div className="question-text">Update OutBound Password?</div>
                              <div className="chcek-wrap">
                                <div className="form-check">
                                  <input type="checkbox" id="updatesshoutpass" checked={props.inOutPasswordUpdateFlag.outbnd ? true : false} onClick={() => props.setInOutPasswordUpdateFlag({ "inbnd": props.inOutPasswordUpdateFlag.inbnd, "outbnd": true })} />
                                  <label for="updatesshoutpass">Yes</label>
                                </div>
                                <div className="form-check">
                                  <input type="checkbox" id="updatesshoutpassno" checked={props.inOutPasswordUpdateFlag.outbnd ? false : true} onClick={() => props.setInOutPasswordUpdateFlag({ "inbnd": props.inOutPasswordUpdateFlag.inbnd, "outbnd": false })} />
                                  <label for="updatesshoutpassno">No</label>
                                </div>
                              </div>
                            </div>
                            : ""
                          }
                          {outboundPasswordJSXCondition() ?
                            <div className="row">
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <label for="f1" className="input-label"> Password<span style={{ color: 'red' }}>*</span></label>
                                  <div className="input-wrap password-wrap password-toggle">
                                    <input className='txt' type={eyeSlashOut.pass ? "password" : "text"} autoComplete="off" placeholder="Enter Password" id="f1" onChange={((e) => setValuesToSSHBounds("sshOutBoundPassword", e.target.value, false))} />
                                    <div onClick={(() => { setEyeSlashOut({ "pass": eyeSlashOut.pass ? false : true, "confirmPass": eyeSlashOut.confirmPass }); })}>
                                      <i className={`toggle-eye fa fa-fw ${eyeSlashOut.pass ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                                    </div>
                                    <div className="input-wrap">  {sshBoundErrorWarning.sshOutBoundPassword ? (<div className="error-message">{sshBoundErrorWarning.sshOutBoundPassword}</div>) : ('')}</div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <label for="f1" className="input-label"> Re-enter Password<span style={{ color: 'red' }}>*</span></label>
                                  <div className="input-wrap password-wrap password-toggle">
                                    <input className='txt' type={eyeSlashOut.confirmPass ? "password" : "text"} onPasteCapture={((e) => { e.preventDefault(); })} autoComplete="off" placeholder="Re-enter Password" id="f1" onChange={((e) => setValuesToSSHBounds("sshOutBoundConfirmPassword", e.target.value, false))} />
                                    <div onClick={(() => { setEyeSlashOut({ "confirmPass": eyeSlashOut.confirmPass ? false : true, "pass": eyeSlashOut.pass }); })}>
                                      <i className={`toggle-eye fa fa-fw ${eyeSlashOut.confirmPass ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                                    </div>
                                    <div className="input-wrap">  {sshBoundErrorWarning.sshOutBoundConfirmPassword ? (<div className="error-message">{sshBoundErrorWarning.sshOutBoundConfirmPassword}</div>) : ('')}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            :
                            ""}
                        </div>
                        :
                        ""}
                      {authTypeOutbound.ssh || props.sshOutBound.sshOutBoundAuthenticationType.trim() === "SSH" ?
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label for="f1" className="input-label"> User Identity Key<span style={{ color: 'red' }}>*</span></label>
                              <div className="input-wrap txt-wrap">
                                <input className="txt" disabled type="text" placeholder="filehandler/application/sftp_keys/" style={{ width: "53%", borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }} autoComplete="off" id="shhOutUserIdentityKey1" />
                                <input className="txt" onChange={((e) => setValuesToSSHBounds("shhOutUserIdentityKey", e.target.value, false))} value={props.sshOutBound.shhOutUserIdentityKey} style={{ width: "47%", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }} type="text" placeholder="Enter Filename" autoComplete="off" id="shhOutUserIdentityKey2" />
                                {/* <input className='txt' value={props.sshOutBound.shhOutUserIdentityKey} type="text" autoComplete="off" placeholder="Enter User Identity Key" id="f1" onChange={((e) => setValuesToSSHBounds("shhOutUserIdentityKey", e.target.value, false))} /> */}                              
                                <div className="input-wrap">  {sshBoundErrorWarning.shhOutUserIdentityKey ? (<div className="error-message">{sshBoundErrorWarning.shhOutUserIdentityKey}</div>) : ('')}</div>
                                <label for="f1" class="input-label"> Note:- Please add the SFTP Private Key Filename</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label for="f1" className="input-label"> Response Timeout(secs)<span style={{ color: 'red' }}>*</span></label>
                              <div className="input-wrap txt-wrap">
                                <input className='txt' value={props.sshOutBound.responseTimeout} type="number" autoComplete="off" placeholder="Response Timeout(secs)" id="f1" onChange={((e) => setValuesToSSHBounds("responseTimeout", e.target.value, false))} />
                                <div className="input-wrap">  {sshBoundErrorWarning.responseTimeout ? (<div className="error-message">{sshBoundErrorWarning.responseTimeout}</div>) : ('')}</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label for="f1" className="input-label">Known Host Key<span style={{ color: 'red' }}>*</span></label>
                              <div className="input-wrap select-wrap">
                                <input type="text" value={props.sshOutBound.knownHostKey} placeholder="Enter Known Host Key" onChange={((e) => setValuesToSSHBounds("knownHostKey", e.target.value, false))} />
                                <div className="input-wrap">  {sshBoundErrorWarning.knownHostKey ? (<div className="error-message">{sshBoundErrorWarning.knownHostKey}</div>) : ('')}</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label for="f1" className="input-label"> Character Encoding<span style={{ color: 'red' }}>*</span></label>
                              <div className="input-wrap txt-wrap">
                                <input className='txt' value={props.sshOutBound.characterEncoding} type="text" autoComplete="off" placeholder="Enter Character Encoding" id="f1" onChange={((e) => setValuesToSSHBounds("characterEncoding", e.target.value, false))} />
                                <div className="input-wrap">  {sshBoundErrorWarning.characterEncoding ? (<div className="error-message">{sshBoundErrorWarning.characterEncoding}</div>) : ('')}</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <div className="input-wrap txt-wrap">
                                <CustomDropdownWidget dropdownList={cipherList} editValue={valueForCipherEdit} constrains={cipherConstrains} setResponseData={setCipherResponseData} ></CustomDropdownWidget>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <div className="input-wrap txt-wrap">
                                <CustomDropdownWidget dropdownList={macList} editValue={valueForMACEdit} constrains={macConstrains} setResponseData={setMACResponseData} ></CustomDropdownWidget>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <div className="input-wrap txt-wrap">
                                <CustomDropdownWidget dropdownList={compressionList} editValue={valueForCompressionEdit} constrains={compressionConstrains} setResponseData={setCompressionResponseData} ></CustomDropdownWidget>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label for="f1" className="input-label"> Connection Retry Count<span style={{ color: 'red' }}>*</span></label>
                              <div className="input-wrap txt-wrap">
                                <input className='txt' value={props.sshOutBound.connectionRetryCount} type="text" autoComplete="off" placeholder="Enter Connection Retry Count" id="f1" onChange={((e) => setValuesToSSHBounds("connectionRetryCount", e.target.value, false))} />
                                <div className="input-wrap">  {sshBoundErrorWarning.connectionRetryCount ? (<div className="error-message">{sshBoundErrorWarning.connectionRetryCount}</div>) : ('')}</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label for="f1" className="input-label"> Retry Delay(secs)<span style={{ color: 'red' }}>*</span></label>
                              <div className="input-wrap txt-wrap">
                                <input className='txt' value={props.sshOutBound.retryDelay} type="number" autoComplete="off" placeholder="Retry Delay" id="f1" onChange={((e) => setValuesToSSHBounds("retryDelay", e.target.value, false))} />
                                <div className="input-wrap">  {sshBoundErrorWarning.retryDelay ? (<div className="error-message">{sshBoundErrorWarning.retryDelay}</div>) : ('')}</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label for="f1" className="input-label"> Local Port Range<span style={{ color: 'red' }}>*</span></label>
                              <div className="input-wrap txt-wrap">
                                <input className='txt' type="text" value={props.sshOutBound.localPortRange} autoComplete="off" placeholder="Local Port Range" id="f1" onChange={((e) => setValuesToSSHBounds("localPortRange", e.target.value, false))} />
                                <div className="input-wrap">  {sshBoundErrorWarning.localPortRange ? (<div className="error-message">{sshBoundErrorWarning.localPortRange}</div>) : ('')}</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label for="f1" className="input-label"> Pass Phrase<span style={{ color: 'red' }}>*</span></label>
                              <div className="input-wrap password-wrap password-toggle">
                                <input className='txt' type={passphraseEyeSlash ? "password" : "text"} value={props.sshOutBound.passPhrase} autoComplete="off" placeholder="passPhrase" id="f1" onChange={((e) => setValuesToSSHBounds("passPhrase", e.target.value, false))} />
                                <div onClick={(() => { setPassphraseEyeSlash(passphraseEyeSlash?false:true) })}>
                                  <i className={`toggle-eye fa fa-fw ${passphraseEyeSlash ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                                </div>
                                <div className="input-wrap">  {sshBoundErrorWarning.passPhrase ? (<div className="error-message">{sshBoundErrorWarning.passPhrase}</div>) : ('')}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        ""
                      }
                    </div>
                  </div>

                </div>
                :
                " "}

              {/* S3 Bucket */}
              {(props.inputDataForRole.partnerRoleConsumerListen || props.inputDataForRole.partnerRoleProducerListen) && props.inputDataForRole.outboundConnectionType === "S3BUCKET" ?
                <div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="f1" className="input-label"> Bucket Name<span style={{ color: 'red' }}>*</span></label>
                        <div className="input-wrap select-wrap">
                          <input type="text" value={props.sshOutBound.bucketname} placeholder="Enter S3 Bucket" onChange={((e) => setValuesToSSHBounds("bucketname", e.target.value, false))} />
                          <div className="input-wrap">  {sshBoundErrorWarning.bucketname ? (<div className="error-message">{sshBoundErrorWarning.bucketname}</div>) : ('')}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="f1" className="input-label"> Region <span style={{ color: 'red' }}>*</span></label>
                        <input type="text" value={props.sshOutBound.region} placeholder="Enter Region" onChange={((e) => { setValuesToSSHBounds("region", e.target.value, false) })} />
                        <div className="input-wrap">  {sshBoundErrorWarning.region ? (<div className="error-message">{sshBoundErrorWarning.region}</div>) : ('')}</div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="f1" className="input-label">S3 Download Folder Path<span style={{ color: 'red' }}>*</span></label>
                        <div className="input-wrap select-wrap">
                          <input type="text" value={props.sshOutBound.folderpath} placeholder="Enter Folder Path" onChange={((e) => setValuesToSSHBounds("folderpath", e.target.value, false))} />
                          <div className="input-wrap">  {sshBoundErrorWarning.folderpath ? (<div className="error-message">{sshBoundErrorWarning.folderpath}</div>) : ('')}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="f1" className="input-label">S3 Upload Folder Path<span style={{ color: 'red' }}>*</span></label>
                        <div className="input-wrap select-wrap">
                          <input type="text" value={props.sshOutBound.uploadFolderPath} placeholder="Enter Folder Path" onChange={((e) => setValuesToSSHBounds("uploadFolderPath", e.target.value, false))} />
                          <div className="input-wrap">  {sshBoundErrorWarning.uploadFolderPath ? (<div className="error-message">{sshBoundErrorWarning.uploadFolderPath}</div>) : ('')}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="f1" className="input-label">S3 IAM Role<span style={{ color: 'red' }}>*</span></label>
                        <div className="input-wrap select-wrap">
                          <input type="text" value={props.sshOutBound.s3OutboundIAMRole} placeholder="Enter S3 IAM Role" onChange={((e) => setValuesToSSHBounds("s3OutboundIAMRole", e.target.value, false))} />
                          <div className="input-wrap">  {sshBoundErrorWarning.s3OutboundIAMRole ? (<div className="error-message">{sshBoundErrorWarning.s3OutboundIAMRole}</div>) : ('')}</div>
                        </div>
                      </div>
                    </div>
                    {/* {true ? diretoryForDownloadOutbound() : ""}
                    {true ? directoryForUploadOutbound() : "" } */}
                  </div>
                  {true ? fileOperationJSX() : ""}
                </div>
                :
                ""}


            </div>
            <div className="btn-wrap btn-wrap--grp mt-5 justify-content-end">
              <button className="button button--outline" type='reset' onClick={(() => onReset())}>Reset</button>
              <button className="button button--outline" onClick={(() => { props.setCursor('Partner_UserProfile') })}>Previous</button>
              <button className="button" type='submit'>Next</button>
            </div>
          </form>
        </div>
      </main >
    </React.Fragment >


  );

}

const mapDispatchToProps = dispatch => ({
  dispatchOneTimeParams: (onSuccess, onError) =>
    dispatch(fetchOneTimeParams({ "keyList": ["preferred_mac", "preferred_cipher", "compression", "max_file_upload_size_values"] }, onSuccess, onError))
})

export default connect(null, mapDispatchToProps)(PartnerOnboardingRoleComponent);

