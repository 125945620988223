import React, { useEffect, useState } from 'react';
import { connect, useStore } from 'react-redux';
import ButtonToggle from '../radioButton/ButtonToggle'
import Mappingfooterbody from '../../assets/images/Mappingfooterbody.png'
import MappingheaderBodyFooter from '../../assets/images/MappingheaderBodyFooter.png'
import Mappingflat from '../../assets/images/Mappingflat.png'
import MappingheaderBody from '../../assets/images/MappingheaderBody.png'
import { isAdmin, displayMappingData, deleteMapping, getMappingConfig,getAllActiveChannelsList } from '../../redux/actions/auth.action.creators'
import iconEdit from '../../assets/images/icon-edit.png';
import iconAttach from '../../assets/images/icon-attach.png';
import iconCopy from '../../assets/images/icon-copy.png';
import iconDelete from '../../assets/images/icon-delete.png';
import { confirmDialog } from 'primereact/confirmdialog';
import AlertFailure from '../../utils/alert-failure.component';
import AlertSuccess from '../../utils/alert-success.component';
import FileMappingPopupAttachChannel from './PopUps/file.mapping.popup.attach.channel';


const FileMappingDefineComponent = (props, { dispatchListMappingAction, dispatchDeleteMappingAction, dispatchGetMappingConfigAction }) => {
  const [sourceSelectedButton, setSourceSelectedButton] = useState(0);
  const [destinationSelectedButton, setDestinationSelectedButton] = useState(0);
  const [lineBreakConfig, setLineBreakConfig] = useState("LF");
  const [isDefineDivVisible, setIsDefineDivVisible] = useState(false);
  const [mappingList, setMappingList] = useState({ "loading": true, "data": "" });
  const [filterMappingList, setFilterMappingList] = useState([]);
  const [mappingSearchKey, setMappingSearchKey] = useState('');
  const [sortDirection, setSortDirection] = useState({ "mapping_name": '' });
  const [failMsg, setFailMsg] = useState('');
  const [succMsg, setSuccMsg] = useState('');
  const [channelListForDropDown, setChannelListForDropDown] = useState('');
  const [attachChannelsPopup, setAttachChannelsPopup] = useState(false);
  const [attachMapping,setAttachMapping]=useState('')
  //-------------------------------------------------------------------------------------------------------------------------------

  const handleSourceButtonClick = (buttonIndex) => {
    setSourceSelectedButton(buttonIndex);
  };

  const toggleDefineDiv = () => {
    setIsDefineDivVisible(!isDefineDivVisible);
  };
  const handleDestinationButtonClick = (buttonIndex) => {
    setDestinationSelectedButton(buttonIndex);
  };


  const mappingTableList = () => {
    window.scrollTo(0, 0);
    props.dispatchListMappingAction(({ data, message }) => {
      if (data) {
        setMappingList({ "loading": false, "data": data });
        setFilterMappingList(data);
      }
    },
      (message) => {
        console.log("Mapping list error--  ", message);
      });
  }
  const onSubmit = (event) => {
    event.preventDefault();

  }

  const onEditClick = (mapping) => {
    // console.log(mapping.id);
    const mappingId = mapping.id
    props.dispatchGetMappingConfigAction(mappingId, ({ data, message }) => {
      if (data) {
        props.setMappingConfig(data[0]['mapping_config'])
        props.setCursor("edit")

      }
    },
      (message) => {

        console.log("Mapping Config list error--  ", message);
      });
  }

  const onAttachClick = (mapping) => {
    mappingTableList()
    const foundMapping = filterMappingList.find(item => item.id === mapping.id);
    setAttachMapping(foundMapping)
    setAttachChannelsPopup(true)

  }

  const onCopyClick = (mapping) => {
    // console.log(mapping.id);
    const mappingId = mapping.id
    props.dispatchGetMappingConfigAction(mappingId, ({ data, message }) => {
      if (data) {
        props.setMappingConfig(data[0]['mapping_config'])
        props.setCursor("copy")

      }
    },
      (message) => {

        console.log("Mapping Config list error--  ", message);
      });
  }

  const deleteMapping = (id, attached_channels) => {
    // console.log("ATTACH",attached_channels);
    if (attached_channels == '' || attached_channels == null) {
      props.dispatchDeleteMappingAction(id, ({ data, message }) => {
        setSuccMsg("Mapping deleted successfully");
        setTimeout(() => {
          setSuccMsg('')
        }, 3000);
        mappingTableList();
      }, (message) => {
        window.scrollTo(0, 0);
        setFailMsg(message);
        setTimeout(() => {
          setFailMsg('')
        }, 3000);
      });
    } else {
      window.scrollTo(0, 0);
      setFailMsg("Detach all channels attached to this mapping before deletion");
      setTimeout(() => {
        setFailMsg('')
      }, 3000);
    }

  }
  const deleteConfirm = (id, name, attached_channels) => {
    confirmDialog({
      message: 'Do you want to delete "' + name + '" mapping?',
      header: 'Submit Confirmation',
      button: "button",
      acceptClassName: 'p-button-danger',
      accept() {
        deleteMapping(id, attached_channels)
      },
      reject() {


      },
      acceptLabel: "Confirm",
      rejectLabel: "Cancel"
    });
  };

  //-----------------------------------------------------------------------------------------------------------------------------

  function sortFn(dir, sortArr, type) {
    let result = sortArr.sort((a, b) => {
      if (a[type] < b[type]) {
        return dir === "asc" ? -1 : 1;
      }
      if (a[type] > b[type]) {
        return dir === "asc" ? 1 : -1;
      }
      return 0;
    });
    // console.log("result: ", result)
    // console.log("sequencenpm start list: ", mappingList)
    return result;
  }

  function onSort(type) {
    let dir = "asc";
    if (sortDirection[type] === "asc") {
      dir = "desc";
    }

    if (type === 'mapping_name') {
      setSortDirection({ "mapping_name": dir });
    }
    setMappingList({ "loading": false, "data": sortFn(dir, mappingList.data, type) })
  }
  //-----------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    mappingTableList()
    
  }, [])

  useEffect(() => {
    let filtered = _.cloneDeep(mappingList.data);
    if (!mappingList.loading) {
      filtered = filterMappingList.filter(c => {
        return (((c.mapping_name == null || c.mapping_name == '') ? false : c.mapping_name.toLowerCase().includes(mappingSearchKey.toLowerCase())) || ((c.mapping_notes == null || c.mapping_notes == '') ? false : c.mapping_notes.toLowerCase().includes(mappingSearchKey.toLowerCase())))
      });
    }
    // console.log("filtered",filtered);
    setMappingList({ "loading": false, "data": filtered });
  }, [mappingSearchKey])
  //-----------------------------------------------------------------------------------------------------------------------------



  const defDownArrowStyle = {
    paddingTop: '2px',
    padding: '4px',
    display: isDefineDivVisible ? 'none' : 'inline-block',
    transition: 'transform 0.3s ease-in-out'
  };

  const defUpArrowStyle = {
    paddingTop: '2px',
    padding: '4px',
    display: isDefineDivVisible ? 'inline-block' : 'none',
    transition: 'transform 0.3s ease-in-out'
  };
  const sourceTemplate = [
    { title: 'Header,Body,Footer', icon: MappingheaderBodyFooter },
    { title: 'Header,Body', icon: MappingheaderBody },
    { title: 'Body,Footer', icon: Mappingfooterbody },
    { title: 'Flat', icon: Mappingflat }
  ];


  const DestinationTemplate = [
    { title: 'Header,Body,Footer', icon: MappingheaderBodyFooter },
    { title: 'Header,Body', icon: MappingheaderBody },
    { title: 'Body,Footer', icon: Mappingfooterbody },
    { title: 'Flat', icon: Mappingflat }
  ];

  //-----------------------------------------------------------------------------------------------------------------------------

  return (
    <React.Fragment>
      <main>
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb__item">
              <a className="breadcrumb__link" href='/'>Configurations</a>
            </li>
            <li className="breadcrumb__item">
              <a className="breadcrumb__link" href="/FileMapping">File Mapping</a>
            </li>
          </ul>

          <div className="title">
            <h1>File Mapping</h1>
          </div>
          {failMsg ?
            <AlertFailure message={failMsg} /> : ''}
          {succMsg ?
            <AlertSuccess message={succMsg} /> : ''}
          <div className={`widget widget--partner`}>
            <div class="form-group" style={{ "cursor": "pointer" }} onClick={toggleDefineDiv}>
              <span style={defDownArrowStyle}><i class='fa fa-chevron-down' aria-hidden="true"></i></span>
              <span style={defUpArrowStyle}><i class='fa fa-chevron-up' aria-hidden="true"></i></span>
              <span> </span>
              <span className='head'>Create Mapping</span>
            </div>

            {isDefineDivVisible ? (<div>
              <div class="container" style={{ padding: '12px' }} >
                <div class="col-lg-7 widget" style={{ padding: '20px' }} >
                  <form onSubmit={onSubmit} >
                    <div className="head"><h6>Select Source Template</h6></div>
                    <div class='form-groups'>
                      <ButtonToggle buttons={sourceTemplate} onButtonClick={handleSourceButtonClick} selectedButton={sourceSelectedButton} />
                    </div>
                    <div class='form-group'></div>
                    <div className="head"><h6>Select Destination Template</h6></div>
                    <div class='form-group'>
                      <ButtonToggle buttons={DestinationTemplate} onButtonClick={handleDestinationButtonClick} selectedButton={destinationSelectedButton} />
                    </div>
                    <div className="head"><h6>General Configurations</h6></div>
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label for="f1" class="d-block input-label">Line Break</label>
                        <div class="input-wrap select-wrap">

                          <select id="f1"
                            onChange={(e) => {
                              setLineBreakConfig(e.target.value)
                            }} value={lineBreakConfig}>
                            <option value={"LF"}>LF</option>
                            <option value={"CRLF"}>CRLF</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrap btn-wrap--grp mt-5 justify-content-end">
                      <button onClick={(() => { props.setCursor('create'); props.setDestinationType(destinationSelectedButton); props.setSourceType(sourceSelectedButton); props.setLineBreakType(lineBreakConfig) })} className="button" type='button'>Create</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>) : ""}
          </div>
          <div></div>
          <div class='widget widget--partner'>
            <div class="table-block">
              <div class="table-block__head">
                <h2>List of Mappings
                  <span style={{ "fontWeight": "bold", "fontSize": "13px", "color": "#666" }} >
                    &nbsp; [ Total: {filterMappingList.length ? filterMappingList.length : 0} &nbsp;]
                  </span>
                </h2>
                <div class="txt-wrap">
                  <input type="text" class="txt" placeholder="search..."
                    value={mappingSearchKey} onChange={(e) => setMappingSearchKey(e.target.value)} />
                  <i class="fa fa-search"></i>
                </div>
              </div>
            </div>

            <div class="table-responsive grid">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" onClick={() => onSort('mapping_name')} style={{ cursor: "pointer" }}>Mapping Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                    <th scope="col" style={{ cursor: "pointer" }}>Description</th>
                    {isAdmin() ? <th scope="col">Action</th> : ""}
                  </tr>
                </thead>
                <tbody>
                  {(mappingList.loading || mappingList.data.length == 0) ? (<tr><td colSpan="100"><div className="text-center text-danger">No Mapping data available</div></td></tr>) :
                    mappingList.data.map((mapping) =>
                    (<tr>
                      <td>{mapping.mapping_name}</td>
                      <td>{mapping.mapping_notes}</td>
                      {isAdmin() ? <td>
                        <div className="table__action">
                          <a href="javascript:void(0);" className="utils utils--outline ml-2" onClick={(e) => onEditClick(mapping)}> <img src={iconEdit} alt="edit" /></a>
                          <a href="javascript:void(0);" className="utils utils--outline ml-2" onClick={(e) => onAttachClick(mapping)}> <img src={iconAttach} alt="edit" /></a>
                          <a href="javascript:void(0);" className="utils utils--outline ml-2" onClick={(e) => onCopyClick(mapping)}> <img src={iconCopy} alt="edit" /></a>
                          <a href="javascript:void(0);" className="utils utils--outline ml-2" onClick={(e) => deleteConfirm(mapping.id, mapping.mapping_name, mapping.attached_channels)}> <img src={iconDelete} alt="delete" /></a>
                        </div>
                      </td> : ""}
                    </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* </div> */}
          {/* </div>) : ""} */}
        </div>
        {attachChannelsPopup&& <FileMappingPopupAttachChannel failMsg={failMsg}  setSuccMsg={setSuccMsg} succMsg={succMsg} setFailMsg={setFailMsg} mappingTableList={mappingTableList} setAttachMapping={setAttachMapping} attachMapping={attachMapping} attachChannelsPopup={attachChannelsPopup} channelListForDropDown={channelListForDropDown} setAttachChannelsPopup={setAttachChannelsPopup} />}
      </main>
    </React.Fragment >
  );

}

const mapDispatchToProps = dispatch => ({
  dispatchListMappingAction: (onSuccess, onError) => dispatch(displayMappingData(onSuccess, onError)),
  dispatchDeleteMappingAction: (data, onSuccess, onError) =>
    dispatch(deleteMapping(data, onSuccess, onError)),
  dispatchGetMappingConfigAction: (data, onSuccess, onError) =>
    dispatch(getMappingConfig(data, onSuccess, onError)),
})

export default connect(null, mapDispatchToProps)(FileMappingDefineComponent);
