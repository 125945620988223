import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import DatePicker from 'react-datepicker';
import iconClose from '../../assets/images/icon-close.svg';
import { downloadHandbackReportData, isAdmin, getAllActivePartnerCodeList, sessionErrorCheck, isOperationalUser, listPartners } from '../../redux/actions/auth.action.creators'
import { DownloadFileAsXLFromEncodedString } from '../../utils/DecodeToXL'
import * as constants from '../../redux/constants'
import { default as ReactSelect, components } from "react-select";

const appConf = require('../'+process.env.REACT_APP_CONFIG_URL);



const ComponentForPointHandbackReport = (props ,{ dispatchHandback, dispatchPartnerCodeList }) => {

    const [isPopupEnable, setIsPopupEnable] = useState(false)

    var maxdate = new Date();
    maxdate.setDate(maxdate.getDate() - 1);
    maxdate = maxdate.toLocaleDateString("en-CA", { timeZone: appConf.TIMEZONE });
    var initDate = new Date(maxdate);

    const [requestBody, setRequestBody] = useState({ "partners": [], "startDate": initDate, "endDate": initDate, "resetData": false, "excos": [], "hbmissingOnly": false });

    const [partnerCodeList, setPartnerCodeList] = useState({ "loading": true, "data": [{ "value": "new", "label": "neww" }] })
    const [tempPartnerDisplay, setTempPartnerDisplay] = useState([])

    const userInfos = sessionErrorCheck() !== null ? sessionErrorCheck() : window.location = '/auth';
    const userInfo = userInfos;


    useEffect(() => {
        props.dispatchPartnerCodeList(({ data, message }) => {
            if (data) {
                let tempList = [{ "value": "", "label": "Any" }];
                data.forEach(val => {
                    if (val) {
                        tempList.push({
                            "value": val.partnercode,
                            "label": val.partnercode
                        });
                    }
                });
                setPartnerCodeList({ "loading": false, "data": tempList });
            }
        },
            (message) => {
            }
        );
    }, [])

    const onSubmit = () => {
        let tempRequest = {};
        tempRequest = JSON.parse(JSON.stringify(requestBody));
        tempRequest.startDate = new Intl.DateTimeFormat('en-CA',{year:'numeric',month:'2-digit',day:'2-digit'}).format(new Date(requestBody.startDate));
        tempRequest.endDate = tempRequest.startDate;
        props.dispatchHandback(tempRequest, ({ data, message, status } ) => {           
            if (data) {
                DownloadFileAsXLFromEncodedString(data, constants.DAILY_ALERT_REPORT)
            } else {
                console.log("invalid / null value from alert report");
            }

        });
    }

    const reset = () => {
        setRequestBody({ "partners": [], "startDate": initDate, "endDate": initDate, "resetData": false, "excos": [], "hbmissingOnly": false });

    }




    const setChooseDate = (date) => {
        var sdate = date;
        setRequestBody({ "partners": requestBody.partners, "startDate": sdate, "endDate": sdate, "resetData": requestBody.resetData, "excos": [], "hbmissingOnly": requestBody.isHBMissingOnly });
 
    }


    const disableFutureDt = current => {
        return current.isBefore(today)
    }

    const setListOfFilesType = (selectedValue) => {
        selectedValue = selectedValue === "MHB" ? true : false;

        setRequestBody({ "partners": requestBody.partners, "startDate": requestBody.startDate, "endDate": requestBody.endDate, "resetData": requestBody.resetData, "excos": requestBody.excos, "hbmissingOnly": selectedValue });

    }

    const partnerSelect = (data) => {
        var tempList = []//requestBody.partners
        data.forEach(val => {
            if (val) {
                tempList.push(val.value);
            }
        })
        setTempPartnerDisplay(data)
        setRequestBody({ "partners": tempList, "startDate": requestBody.startDate, "endDate": requestBody.endDate, "resetData": requestBody.resetData, "excos": requestBody.excos, "hbmissingOnly": requestBody.isHBMissingOnly });
    }

    


   function isFormValid() {
            var now = new Date();
            if(Math.ceil(Math.abs(new Date(requestBody.endDate) - now) / (1000 * 60 * 60 * 24)) > 2){
                return(true)
            }else{
            return(false)
            }
        }       


    return (
        <div class="col-lg-4 mb-4">
            <div class="widget widget--box widget--report" data-toggle="modal" data-target="#failedhandbackreport" id="rprt_alertnotif">
                <div class="widget--report__details" onClick={(() => { setIsPopupEnable(true) })}>
                    <div class="widget--report__title">
                        <p><strong>Points to Handback Mapping Report</strong></p>
                    </div>
                    <div class="widget--report__desc">
                        <p>This report gives the lists handback files of the point file.</p>
                    </div>
                </div>
            </div>
            <div class="modal fade modal--addnew show" aria-modal="true" style={{ display: isPopupEnable ? 'block' : 'none' }} id="failedhandbackreport" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Points to Handback Mapping Report</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={(e) => { reset() }}>
                                <img src={iconClose} alt="Close" />
                            </button>
                        </div>

                        <div class="modal-body">
                            <div class="form mb-8">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div>
                                            <label for="pc" class="input-label">List</label>
                                            <select name="selectList" id="selectList" onChange={(e) => { setListOfFilesType(e.target.value)}}>
                                                <input type="text" class="txt" id={"allfiles" + 0} placeholder="Choose Files" />
                                                <option value="ALL">All files</option>
                                                <option value="MHB">Missing Handback</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="f22" class="d-block input-label">Choose Date  <span style={{ color: 'red' }}>*</span></label>
                                            <div class="input-wrap date-wrap">
                                                <DatePicker dateFormat="dd/MM/yyyy"
                                                    // selected={startDate}
                                                    // onChange={(date) => setStartDate(date)} 
                                                    selected={requestBody.endDate} 
                                                    onChange={(dvalue) => setChooseDate(dvalue)} 
                                                    maxDate={initDate} 
                                                    // placeholderText={maxdate} 
                                                    onfocus="(this.type='date')" 
                                                    isValidDate={disableFutureDt}
                                                     />
                                                {/* {errorConst.date ?
                                                    (<div className="error-message">{errorConst.date} setChooseDate= minDate? "hii"</div>)
                                                    : ('')} */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 input-wrap txt-wrap">
                                        <div className="form-group ">
                                            <label htmlFor="f5" className="d-block input-label">Select Partners</label>
                                            <ReactSelect
                                                options={partnerCodeList.data}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                onChange={(e) => partnerSelect(e)}
                                                allowSelectAll={true}
                                                value={tempPartnerDisplay}
                                            />
                                        </div>
                                    </div>
                                    



                                    {/* <div className="col-lg-6 col-md-6 input-wrap txt-wrap">
                                        {
                                            (userInfo && isOperationalUser() && isFormValid())
                                                ?
                                                <div class="col-lg-6 col-md-6 input-wrap txt-wrap">
                                                        <div className="form-group"><input type="hidden" checked={truncate ? 'selected' : ''} id='no' onChange={(() => { setTruncate(truncate ? false : true); })} />
                                                        <label for="no">* Regenerate data <span style={{ color: "red", fontWeight: "lighter"}}>{truncate?"* Regenerate data":''}</span></label>
                                                        </div>
                                                </div>
                                                :''
                                        }

                                    </div> */}




                                    <div className="col-lg-6 col-md-6 input-wrap txt-wrap">
                                        {
                                            (userInfo && isOperationalUser())
                                                ?
                                                '' :
                                                <div class="col-lg-6 col-md-6 input-wrap txt-wrap">
                                                    <div className="form-group"><input type="checkbox" id='no' onChange={((e) => {setRequestBody({ "partners": requestBody.partners, "startDate": requestBody.startDate, "endDate": requestBody.endDate, "resetData": e.target.checked, "excos": requestBody.excos, "hbmissingOnly": requestBody.isHBMissingOnly }); })} />
                                                        <label for="no">Recreate <span style={{ color: "red", fontWeight: "lighter" }}></span></label>

                                                    </div>
                                                </div>
                                        }

                                    </div>

                                </div>
                                <div class="row">
                                <div className="col-lg-6 col-md-6 input-wrap txt-wrap">
                                        {
                                            (userInfo && isOperationalUser() && isFormValid())
                                                ?
                                                <div style={{ color: "red", fontWeight: "lighter" }}>
                                                        
                                                        <label for="no">Note: Points to Handback Mapping Report data will be recreated.</label>
                                                        
                                                </div>
                                                :''
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="modal-footer">
                            <button type="reset" onClick={(() => { reset() })} class="button button--outline button--sm">Reset</button>
                            <button type="submit" onClick={(() => { onSubmit() })} class="button button--sm">Download Report</button>
                        </div>

                    </div>
                </div>


            </div>



        </div>
    )

}


const mapDispatchToProps = dispatch => ({
    dispatchHandback: (data, onSuccess, onError) =>
        dispatch(downloadHandbackReportData(data, onSuccess, onError)),
    dispatchPartnerCodeList: (onSuccess, onError) =>
        dispatch(listPartners(onSuccess, onError))
});

export default connect(null, mapDispatchToProps)(ComponentForPointHandbackReport);


