import React, { useState, useEffect, useRef, } from 'react';
import { connect } from 'react-redux';
import CustomDropdownWidget from './dropdown/CustomDropdownWidget';
import * as constants from '../redux/constants'
import { listPartners, editOneTimeParamsTableData, displayOneTimeParamsTableData, createOneTimeParams, displaySystemParamsTableData, createSystemParams, editSystemParamsTableData, isJson, deleteCache, fetchCacheKeys } from '../redux/actions/auth.action.creators';
import edit_icon from '../assets/images/icon-edit.png';
import AlertSuccess from '../utils/alert-success.component';
import AlertFailure from '../utils/alert-failure.component';

import { Tooltip } from 'primereact/tooltip';

import { confirmDialog } from 'primereact/confirmdialog';
import iconInfo from '../assets/images/icon-info-round.png';
import iconClose from '../assets/images/icon-close.svg';
import {default as ReactSelect, components} from "react-select";


const OneTimeParamsComponent = ({ dispatchOneTimeParamsTableData, dispatchCreateOneTimeParams, dispatchEditOneTimeParams, dispatchSystemParamsTableData, dispatchCreateSystemParams, dispatchEditSystemParams, dispatchDeleteCache, dispatchGetCacheKeys }) => {
    const [paramsType, setParamsType] = useState('ONETIME')


    const [failMsg, setFailMsg] = useState('');
    const [succMsg, setSuccMsg] = useState('');
    const [dropdownRefresher, setdropdownRefresher] = useState(true);
    const [requestBody, setRequestBody] = useState({ "oneTimeKey": "", "oneTimeValue": "", "oneTimeDesc": "", "oneTimeCode": "" });
    const [errorDetails, setErrorDetails] = useState({ "errorOneTimeKey": "", "errorOneTimeValue": "", "errorOneTimeDesc": "", "errorOneTimeCode": "", "errorParamKey": "", "errorParamValue": "", "errorParamDesc": "" })

    const [statusRefresh, setStatusRefresh] = useState(true)
    const [incrementValue, setIncrementValue] = useState(1);
    const [createAndUpdateStatus, setCreateAndUpdateStatus] = useState("");
    const [oneTimeKey, setOneTimeKey] = useState("");
    const [searchKey, setSearchKey] = useState('');
    const [oneTimeParamsTableList, setOneTimeParamsTableList] = useState({ "loading": false, "data": [] }); //loading : false
    const [tableData, setTableData] = useState([]);
    const [systemTableData, setSystemTableData] = useState([])
    const [confirmCreate, setConfirmCreate] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [redisKeys, setRedisKeys] = useState({ "loading": true, "data": [] });
    const [invalidateKeys, setInvalidateKeys] = useState([])
    ///////systemparams///////

    const [systemParamsTableList, setSystemParamsTableList] = useState({ "loading": false, "data": [] }); //loading :false
    const [paramKey, setParamKey] = useState("");
    const [createAndUpdateParamStatus, setCreateAndUpdateParamStatus] = useState("")

    const [sortAsc, setSortAsc] = useState({ "one_time_key": true, "one_time_value": true, "one_time_desc": true, "one_time_code": true, "param_key": true, "param_value": true, "param_desc": true });
    const [sortDirection, setSortDirection] = useState({ "one_time_key": '', "one_time_value": '', "one_time_desc": '', "one_time_code": '', "param_key": '', "param_value": '', "param_desc": '' });
    const [errorMessage, setErrorMessage] = React.useState("");




    const dataToLowerCase = (data) => {
        try {
            data = data.toLowerCase()
            return data
        } catch (error) {
            console.log(error);
            console.log(data);
            return ""
        }
    }



    useEffect(() => {
        let filtered = ''
        if (paramsType === 'ONETIME') {
            filtered = _.cloneDeep(oneTimeParamsTableList);
            if (oneTimeParamsTableList.loading) {
                filtered.data = tableData.filter(p => {
                    return (dataToLowerCase(p.one_time_key).includes(searchKey.toLowerCase())
                        || dataToLowerCase(p.one_time_value).includes(searchKey.toLowerCase())
                        || dataToLowerCase(p.one_time_desc).includes(searchKey.toLowerCase())
                        || dataToLowerCase(p.one_time_code).includes(searchKey.toLowerCase()))

                });
            }
            setOneTimeParamsTableList({ loading: true, data: filtered.data });

        } else if (paramsType === 'SYSTEM') {
            filtered = _.cloneDeep(systemParamsTableList);

            if (systemParamsTableList.loading) {
                filtered.data = tableData.filter(p => {

                    return (dataToLowerCase(p.param_key).includes(searchKey.toLowerCase())
                        || dataToLowerCase(p.param_value).includes(searchKey.toLowerCase())
                        || dataToLowerCase(p.param_desc).includes(searchKey.toLowerCase()))

                });
            }
            setSystemParamsTableList({ loading: true, data: filtered.data });
        }
    }, [searchKey])


    useEffect(() => {
        if (paramsType === 'ONETIME') {
            dispatchOneTimeParamsTableData(({ data, message }) => {
                if (data) {
                    setTableData(data)
                    setOneTimeParamsTableList({ loading: true, data: data });
                }
            },
                (message) => {
                    console.log(" list error--  ", message);
                });

        } else if (paramsType === 'SYSTEM') {

            dispatchSystemParamsTableData(({ data, message }) => {
                if (data) {
                    setTableData(data)
                    setSystemParamsTableList({ loading: true, data: data });
                }
            },
                (message) => {
                    console.log("data", data)
                    console.log("Override list error--  ", message);
                });

        } else {
            dispatchSystemParamsTableData(({ data, message }) => {
                if (data) {
                    setTableData(data)
                    setSystemParamsTableList({ loading: true, data: data });
                }
            },
                (message) => {
                    console.log("data", data)
                    console.log("Override list error--  ", message);
                });

        }
    }, [statusRefresh])


    useEffect(() => {
        if (redisKeys.loading) {
            dispatchGetCacheKeys(({ data, message }) => {
                if (data) {
                    setRedisKeys({ "loading": false, "data": data})
                }
            },
                (message) => {
                    console.log(" list error--  ", message);
                });
        }
    },[])

    ////////////////onetimeparam///////////////////////

    const clickSubmitDetails = () => {


        var oneTimeKey = (document.getElementById("oneTimeKey" + 0) || {}).value;
        var oneTimeValue = (document.getElementById("oneTimeValue" + 0) || {}).value;
        var oneTimeDesc = (document.getElementById("oneTimeDesc" + 0) || {}).value;
        var oneTimeCode = (document.getElementById("oneTimeCode" + 0) || {}).value;
        let tempError = _.cloneDeep(errorDetails);


        if (oneTimeKey == "") {
            setErrorDetails({ "errorOneTimeKey": "Enter the key", "errorOneTimeValue": "", "errorOneTimeDesc": "", "errorOneTimeCode": "" });
        } else if (oneTimeValue == "") {
            setErrorDetails({ "errorOneTimeKey": "", "errorOneTimeValue": "Enter the Value", "errorOneTimeDesc": "", "errorOneTimeCode": "" });
        } else if (oneTimeDesc == "") {
            setErrorDetails({ "errorOneTimeKey": "", "errorOneTimeValue": "", "errorOneTimeDesc": "Enter the Description", "errorOneTimeCode": "" });
        } else if (oneTimeCode == "") {
            setErrorDetails({ "errorOneTimeKey": "", "errorOneTimeValue": "", "errorOneTimeDesc": "", "errorOneTimeCode": "Enter the Code" });
        } else {
            setErrorDetails({ "errorOneTimeKey": "", "errorOneTimeValue": "", "errorOneTimeDesc": "", "errorOneTimeCode": "" });

            onPopupActions(oneTimeCode);
        }
        setStatusRefresh(statusRefresh ? false : true)


    }


    const submitDetails = () => {
        var request = new FormData();
        var pushOneTimeKey = [];
        var pushOneTimeValue = [];
        var pushOneTimeDesc = [];
        var pushOneTimeCode = [];

        var pushkeyAndvalue = [];
        var pushdescAndcode = [];

        for (let index = 0; index < incrementValue; index++) {
            var oneTimeKey = (document.getElementById("oneTimeKey" + index) || {}).value;
            var oneTimeValue = (document.getElementById("oneTimeValue" + index) || {}).value;
            var oneTimeDesc = (document.getElementById("oneTimeDesc" + index) || {}).value;
            var oneTimeCode = (document.getElementById("oneTimeCode" + index) || {}).value;
            if (oneTimeKey !== undefined && oneTimeValue !== undefined && oneTimeDesc !== undefined && oneTimeCode !== undefined) {

                pushOneTimeKey.push(oneTimeKey);
                pushOneTimeValue.push(oneTimeValue);
                pushOneTimeDesc.push(oneTimeDesc);
                pushOneTimeCode.push(oneTimeCode);

                pushkeyAndvalue.push({ 'oneTimeKey': oneTimeKey, 'oneTimeValue': oneTimeValue });
                pushdescAndcode.push({ 'oneTimeDesc': oneTimeDesc, 'oneTimeCode': oneTimeCode })
            }
        }
        request.append("oneTimeKey", pushOneTimeKey.toString())
        request.append("oneTimeValue", pushOneTimeValue.toString())
        request.append("oneTimeDesc", pushOneTimeDesc.toString())
        request.append("oneTimeCode", pushOneTimeCode.toString())

        if (createAndUpdateStatus === "UPDATE") {
            dispatchEditOneTimeParams(request, ({ data, message }) => {
                setSuccMsg("Configuration Updated successfully");

                resetSubmitDetails();
                window.scrollTo(0, 0);
                setTimeout(() => {
                    setSuccMsg('')
                }, 3000);
            },
                (message) => {
                    console.log("request-message--  ", message)
                    window.scrollTo(0, 0);
                    setFailMsg(message);
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                });

        } else {
            dispatchCreateOneTimeParams(request, ({ data, message }) => {
                setSuccMsg("Values created successfully");
                resetSubmitDetails();
                window.scrollTo(0, 0);
                setTimeout(() => {
                    setSuccMsg('')
                }, 3000);

            },
                (message) => {
                    console.log("request-message-create-  ", message)
                    window.scrollTo(0, 0);
                    setFailMsg(message);
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                });

        }



    }



    ////////////////////////system param//////////////////

    const clickSubmitSystemParamDetails = () => {

        var paramKey = (document.getElementById("paramKey" + 0) || {}).value;
        var paramValue = (document.getElementById("paramValue" + 0) || {}).value;
        var paramDesc = (document.getElementById("paramDesc" + 0) || {}).value;


        if (paramKey == "") {
            setErrorDetails({ "errorParamKey": "Enter the key", "errorParamValue": errorDetails.errorParamValue, "errorParamDesc": errorDetails.errorParamDesc });
        } else if (paramValue == "") {
            setErrorDetails({ "errorParamKey": errorDetails.errorParamKey, "errorParamValue": "Enter the Value", "errorParamDesc": errorDetails.errorParamDesc });
        } else if (paramDesc == "") {
            setErrorDetails({ "errorParamKey": errorDetails.errorParamKey, "errorParamValue": errorDetails.errorParamValue, "errorParamDesc": "Enter the Description" });

        } else {
            setErrorDetails({ "errorParamKey": "", "errorParamValue": "", "errorParamDesc": "" });
            onPopupActions(paramKey);
        }

        setStatusRefresh(statusRefresh ? false : true)
    }




    const submitSystemDetails = () => {

        var request = new FormData();
        var pushParamKey = [];
        var pushParamValue = [];
        var pushParamDesc = [];

        var pushkeyAndvalue = [];
        var pushdesc = [];



        for (let index = 0; index < incrementValue; index++) {
            var paramKey = (document.getElementById("paramKey" + index) || {}).value;
            var paramValue = (document.getElementById("paramValue" + index) || {}).value;
            var paramDesc = (document.getElementById("paramDesc" + index) || {}).value;

            if (paramKey !== undefined && paramValue !== undefined && paramDesc !== undefined) {

                pushParamKey.push(paramKey);
                pushParamValue.push(paramValue);
                pushParamDesc.push(paramDesc);


                pushkeyAndvalue.push({ 'paramKey': paramKey, 'paramValue': paramValue });
                pushdesc.push({ 'paramDesc': paramDesc })
            }
        }
        request.append("paramKey", pushParamKey.toString())
        request.append("paramValue", pushParamValue.toString())
        request.append("paramDesc", pushParamDesc.toString())

        if (createAndUpdateParamStatus === "UPDATED") {
            dispatchEditSystemParams(request, ({ data, message }) => {
                setSuccMsg("Configuration Updated successfully");
                resetSubmitParamDetails();
                window.scrollTo(0, 0);
                setTimeout(() => {
                    setSuccMsg('')
                }, 3000);
            },
                (message) => {
                    console.log("request-message--  ", message)
                    window.scrollTo(0, 0);
                    setFailMsg(message);
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                });

        } else {
            dispatchCreateSystemParams(request, ({ data, message }) => {
                setSuccMsg("param created successfully");
                resetSubmitParamDetails();
                window.scrollTo(0, 0);
                setTimeout(() => {
                    setSuccMsg('')
                }, 3000);


            },
                (message) => {
                    console.log("request-message-create-  ", message)
                    window.scrollTo(0, 0);
                    setFailMsg(message);
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                });
        }

    }

    /////////////////////////system param//////////////


    const editOneTimeParamsTableData = (data) => {

        window.scrollTo(0, 290);
        setErrorMessage("Kindly make sure all the reference related to this key is updated")
        setTimeout(() => {
            setErrorMessage("")
        }, 3000);
        setCreateAndUpdateStatus("UPDATE");
        setOneTimeKey(data.one_time_key);

        for (let index = 0; index < incrementValue; index++) {
            if (document.getElementById("deleteOneTimeKey" + (index + 1)) !== null) {
                document.getElementById("deleteOneTimeKey" + (index + 1)).remove();
            }

            if (document.getElementById("deleteOneTimeValue" + (index + 1)) !== null) {
                document.getElementById("deleteOneTimeValue" + (index + 1)).remove();
            }
            if (document.getElementById("oneTimeKey" + index) !== null && document.getElementById("oneTimeValue" + index) !== null && document.getElementById("oneTimeDesc" + index) !== null && document.getElementById("oneTimeCode" + index) !== null) {
                document.getElementById("oneTimeKey" + index).value = "";
                document.getElementById("oneTimeValue" + index).value = "";
                document.getElementById("oneTimeDesc" + index).value = "";
                document.getElementById("oneTimeCode" + index).value = "";


            }
        }



        document.getElementById("oneTimeKey" + 0).value = data.one_time_key;

        document.getElementById("oneTimeValue" + 0).value = data.one_time_value;
        document.getElementById("oneTimeDesc" + 0).value = data.one_time_desc;

        document.getElementById("oneTimeCode" + 0).value = data.one_time_code;

    }





    const editSystemParamsTableData = (data) => {

        window.scrollTo(0, 290);
        setErrorMessage("Kindly make sure all the reference related to this key is updated")
        setTimeout(() => {
            setErrorMessage("")
        }, 3000);
        setCreateAndUpdateParamStatus("UPDATED");
        setParamKey(data.param_key);

        for (let index = 0; index < incrementValue; index++) {
            if (document.getElementById("deleteParamKey" + (index + 1)) !== null) {
                document.getElementById("deleteParamKey" + (index + 1)).remove();
            }

            // if(document.getElementById("deleteAttValue"+(index+1)) !== null && document.getElementById("deleteButton"+(index+1)) !== null) {
            if (document.getElementById("deleteParamValue" + (index + 1)) !== null) {
                document.getElementById("deleteParamValue" + (index + 1)).remove();
                // document.getElementById("deleteButton"+(index+1)).remove();
            }
            if (document.getElementById("paramKey" + index) !== null && document.getElementById("paramValue" + index) !== null && document.getElementById("paramDesc" + index) !== null) {
                document.getElementById("paramKey" + index).value = "";
                document.getElementById("paramValue" + index).value = "";
                document.getElementById("paramDesc" + index).value = "";


            }
        }



        document.getElementById("paramKey" + 0).value = data.param_key;

        document.getElementById("paramValue" + 0).value = data.param_value;
        document.getElementById("paramDesc" + 0).value = data.param_desc;
    }



    const resetSubmitDetails = () => {
        setCreateAndUpdateStatus("");
        // setStatusRefresh(statusRefresh ? false : true)
        for (let index = 0; index < incrementValue; index++) {
            if (document.getElementById("deleteOneTimeKey" + (index + 1)) !== null) {

                document.getElementById("deleteOneTimeKey" + (index + 1)).remove();
                document.getElementById("deleteOneTimeValue" + (index + 1)).remove();
                document.getElementById("deleteOneTimeDesc" + (index + 1)).remove();
                document.getElementById("deleteOneTimeCode" + (index + 1)).remove();


            }
            if (document.getElementById("oneTimeKey" + index) !== null && document.getElementById("oneTimeValue" + index) !== null && document.getElementById("oneTimeDesc" + index) !== null && document.getElementById("oneTimeCode" + index) !== null) {
                document.getElementById("oneTimeKey" + index).value = "";
                document.getElementById("oneTimeValue" + index).value = "";
                document.getElementById("oneTimeDesc" + index).value = "";
                document.getElementById("oneTimeCode" + index).value = "";



            }
        }

    }



    const resetSubmitParamDetails = () => {


        setCreateAndUpdateParamStatus("");
        // setStatusRefresh(statusRefresh ? false : true)
        for (let index = 0; index < incrementValue; index++) {
            if (document.getElementById("deleteParamKey" + (index + 1)) !== null) {

                document.getElementById("deleteParamKey" + (index + 1)).remove();
                document.getElementById("deleteParamValue" + (index + 1)).remove();
                document.getElementById("deleteParamDesc" + (index + 1)).remove();



            }
            if (document.getElementById("paramKey" + index) !== null && document.getElementById("paramValue" + index) !== null && document.getElementById("paramDesc" + index) !== null) {
                document.getElementById("paramKey" + index).value = "";
                document.getElementById("paramValue" + index).value = "";
                document.getElementById("paramDesc" + index).value = "";




            }
        }

    }


    const eventFromConfigDropdown = (param_name) => {
        if (param_name === 'ONETIME') {
            setStatusRefresh(statusRefresh ? false : true)
            setParamsType('ONETIME')
            setSearchKey('')
        } else if (param_name === 'SYSTEM') {
            setStatusRefresh(statusRefresh ? false : true)
            setParamsType('SYSTEM')
            setSearchKey('')
        }

    }



    const onPopupActions = (paramVal) => {
        confirmDialog({

            message: 'Do you want to Submit ' + paramVal + '?',
            header: 'Submit Confirmation',
            button: "button",
            acceptClassName: 'p-button-danger',
            accept() {
                if (paramsType === "ONETIME") {
                    submitDetails()
                } else {
                    submitSystemDetails()
                }
            },
            reject() {

            },
            acceptLabel: "Confirm",
            rejectLabel: "Cancel"
        });
    };



    function sortFn(dir, sortArr, type) {
        if (paramsType === "ONETIME") {
            let result = sortArr.sort((a, b) => {
                if (a[type] < b[type]) {
                    return dir === "asc" ? -1 : 1;
                }
                if (a[type] > b[type]) {
                    return dir === "asc" ? 1 : -1;
                }
                return 0;
            });
            return result;
        }
        else if (paramsType === "SYSTEM") {
            let result = sortArr.sort((a, b) => {
                if (a[type] < b[type]) {
                    return dir === "asc" ? -1 : 1;
                }
                if (a[type] > b[type]) {
                    return dir === "asc" ? 1 : -1;
                }
                return 0;
            });
            return result;

        }
    }





    function sortBy(type) {
        let dir = "asc";
        if (sortDirection[type] === "asc") {
            dir = "desc";
        }

        if (paramsType === "ONETIME") {
            if (type === 'one_time_key') {
                setOneTimeParamsTableList({ "loading": false, "data": sortFn(dir, oneTimeParamsTableList.data, type) });
                setSortDirection({ "one_time_key": dir, "one_key_value": sortDirection.one_time_value, "one_key_desc": sortDirection.one_time_desc, "one_key_code": sortDirection.one_time_code });
            } else if (type === 'one_key_value') {
                setOneTimeParamsTableList({ "loading": false, "data": sortFn(dir, oneTimeParamsTableList.data, type) });
                setSortDirection({ "one_time_key": sortDirection.one_time_key, "one_key_value": dir, "one_key_desc": sortDirection.one_time_desc, "one_key_code": sortDirection.one_time_code });
            } else if (type === 'one_time_desc') {
                setOneTimeParamsTableList({ "loading": false, "data": sortFn(dir, oneTimeParamsTableList.data, type) });
                setSortDirection({ "one_time_key": sortDirection.one_time_key, "one_key_value": sortDirection.one_time_value, "one_key_desc": dir, "one_key_code": sortDirection.one_time_code });
            } else if (type === 'one_time_code') {
                setOneTimeParamsTableList({ "loading": false, "data": sortFn(dir, oneTimeParamsTableList.data, type) });
                setSortDirection({ "one_time_key": sortDirection.one_time_key, "one_key_value": sortDirection.one_time_value, "one_key_desc": sortDirection.one_time_desc, "one_key_code": dir });
            }
        }
        else if (paramsType === "SYSTEM") {

            if (type === 'param_key') {
                setSystemParamsTableList({ "loading": false, "data": sortFn(dir, systemParamsTableList.data, type) });
                setSortDirection({ "param_key": dir, "param_value": sortDirection.param_value, "param_desc": sortDirection.param_desc });
            } else if (type === 'param_value') {
                setSystemParamsTableList({ "loading": false, "data": sortFn(dir, oneTimeParamsTableList.data, type) });
                setSortDirection({ "param_key": sortDirection.param_key, "param_value": dir, "param_desc": sortDirection.param_desc });
            } else if (type === 'param_desc') {
                setSystemParamsTableList({ "loading": false, "data": sortFn(dir, oneTimeParamsTableList.data, type) });
                setSortDirection({ "param_key": sortDirection.param_key, "param_value": sortDirection.param_value, "param_desc": dir });
            }

        }
    }

    function sendInvalidateRequest() {
        let requestBody = []
        invalidateKeys.forEach(keys => {
            requestBody.push(keys.value)
        })
        dispatchDeleteCache({
            "keys": requestBody
        }, ({ data, message }) => {
                setInvalidateKeys([])
                window.scrollTo(0, 0);
                setSuccMsg("Cache cleared successfully.");
                setTimeout(() => {
                    setSuccMsg('')
                }, 3000);
        }, (message) => {
            window.scrollTo(0, 0);
            setFailMsg(message);
            setTimeout(() => {
                setFailMsg('')
            }, 3000);
        });
    }

    return (
        <React.Fragment>
            <main>
                <div class="container">
                    <ul class="breadcrumb">
                        <li class="breadcrumb__item">
                            <a class="breadcrumb__link" href="#">Configurations</a>
                        </li>

                        <li class="breadcrumb__item">
                            <span class="breadcrumb__link">System Configurations</span>
                        </li>
                    </ul>
                    <div class="title">
                        <h1>System Configurations</h1>
                    </div>
                    {failMsg ?
                        <AlertFailure message={failMsg} /> : ''}
                    {succMsg ?
                        <AlertSuccess message={succMsg} /> : ''}
                </div>

                <div class="grey-block">
                    <div class="container">
                        <div class="form mb-5">
                            <h2 class="mb-4">Add One Time and System Parameter</h2>
                        
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="pc" class="input-label">Configuration Tables</label>
                                        <select name="selectList" id="selectList" onChange={(e) => { eventFromConfigDropdown(e.target.value) }}>
                                            <input type="text" class="txt" id={"allfiles" + 0} placeholder="Choose Files" />
                                            <option value="ONETIME">One Time Parameter</option>
                                            <option value="SYSTEM">System Parameter</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        



                            <div class="row">



                                {paramsType === "ONETIME" ? (
                                    <div class="col-lg-6 col-md-6" >
                                        <div class="form-group">
                                            <label for={"oneTimeKey" + 0} class="input-label">One Time Key<span style={{ color: 'red' }}>*</span>
                                                <span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} />
                                                    <i>
                                                        <img className="toolTip" data-pr-tooltip="Enter One Time Key" data-pr-position="bottom" src={iconInfo} alt="info" />
                                                    </i></span>
                                            </label>
                                            <div class="input-wrap date-wrap">
                                                <input type="text" class={`txt ${errorDetails.oneTimeKey ? 'is-invalid' : ''}`} id={"oneTimeKey" + 0} placeholder="One Time Key" />
                                                {errorDetails.errorOneTimeKey !== "" ?
                                                    <div className="error-message">{errorDetails.errorOneTimeKey} </div>
                                                    : ''}

                                                <div id={"errorOneTimeKey" + 0}></div>
                                            </div>
                                        </div>
                                    </div>
                                ) : ''}
                                {paramsType === "ONETIME" ? (
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for={"oneTimeValue" + 0} class="input-label">One Time Value<span style={{ color: 'red' }}>*</span>
                                                <span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} />
                                                    <i>
                                                        <img className="toolTip" data-pr-tooltip="Enter One Time Value" data-pr-position="right" src={iconInfo} alt="info" />
                                                    </i>
                                                </span>
                                            </label>
                                            <div class="input-wrap txt-wrap">
                                                <textarea className='textarea textarea-resize' type="text" id={"oneTimeValue" + 0} placeholder="One Time Value" />
                                                {errorDetails.errorOneTimeValue !== "" ?
                                                    <div className="error-message">{errorDetails.errorOneTimeValue}</div>
                                                    : ''}
                                                <div id={"errorOneTimeValue" + 0}></div>
                                            </div>
                                        </div>
                                    </div>) : ""}





                                {paramsType === "ONETIME" ? (
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for={"oneTimeKey" + 0} class="input-label">One Time Desc<span style={{ color: 'red' }}>*</span>
                                                <span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} />
                                                    <i>
                                                        <img className="toolTip" data-pr-tooltip="Enter One Time Description" data-pr-position="right" src={iconInfo} alt="info" />
                                                    </i>
                                                </span>

                                            </label>
                                            <div class="input-wrap date-wrap">
                                                <input type="text" class="txt" id={"oneTimeDesc" + 0} placeholder="One Time Desc" />
                                                {errorDetails.errorOneTimeDesc !== "" ?
                                                    <div className="error-message">{errorDetails.errorOneTimeDesc}</div>
                                                    : ''}
                                                <div id={"errorOneTimeDesc" + 0}></div>
                                            </div>
                                        </div>
                                    </div>) : ''}
                                {paramsType === "ONETIME" ? (
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for={"oneTimeKey" + 0} class="input-label">One Time Code <span style={{ color: 'red' }}>*</span>
                                                <span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} />
                                                    <i>
                                                        <img className="toolTip" data-pr-tooltip="Enter One Time Code" data-pr-position="right" src={iconInfo} alt="info" />
                                                    </i>
                                                </span>
                                            </label>
                                            <div class="input-wrap date-wrap">
                                                {/* <input type="text" class="txt" id={"oneTimeCode" + 0} disabled={dispatchEditOneTimeParams === 'UPDATE' ? true : false} placeholder="One Time Code" /> */}

                                                <input type="text" class="txt" id={"oneTimeCode" + 0} disabled={createAndUpdateStatus === 'UPDATE' ? true : false} placeholder="One Time Code" />
                                                {errorDetails.errorOneTimeCode !== "" ?
                                                    <div className="error-message">{errorDetails.errorOneTimeCode}</div>
                                                    : ''}
                                                <div id={"errorOneTimeCode" + 0}></div>
                                            </div>
                                        </div>
                                    </div>) : ''}



                                {paramsType === "SYSTEM" ? (
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for={"paramKey" + 0} class="input-label">Param Key <span style={{ color: 'red' }}>*</span>
                                                <span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} />
                                                    <i>
                                                        <img className="toolTip" data-pr-tooltip="Enter Parameter Key" data-pr-position="right" src={iconInfo} alt="info" />
                                                    </i>
                                                </span>
                                            </label>
                                            <div class="input-wrap date-wrap">
                                                <input type="text" class="txt" id={"paramKey" + 0} disabled={createAndUpdateParamStatus === 'UPDATED' ? true : false} placeholder="Param Key" />
                                                {errorDetails.errorParamKey !== "" ?
                                                    <div className="error-message">{errorDetails.errorParamKey}</div>
                                                    : ''}
                                                <div id={"errorParamKey" + 0}></div>
                                            </div>
                                        </div>
                                    </div>) : ''}

                                {paramsType === "SYSTEM" ? (
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for={"paramValue" + 0} class="input-label">Param Value <span style={{ color: 'red' }}>*</span>
                                                <span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} />
                                                    <i>
                                                        <img className="toolTip" data-pr-tooltip="Enter Parameter Value" data-pr-position="right" src={iconInfo} alt="info" />
                                                    </i>
                                                </span>
                                            </label>
                                            <div class="input-wrap date-wrap">
                                                <input type="text" class="txt" id={"paramValue" + 0} placeholder="Param Value" />
                                                {errorDetails.errorParamValue !== "" ?
                                                    <div className="error-message">{errorDetails.errorParamValue}</div>
                                                    : ''}
                                                <div id={"errorParamValue" + 0}></div>
                                            </div>
                                        </div>
                                    </div>) : ''}
                                {paramsType === "SYSTEM" ? (
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for={"paramDesc" + 0} class="input-label">Param Desc <span style={{ color: 'red' }}>*</span>
                                                <span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} />
                                                    <i>
                                                        <img className="toolTip" data-pr-tooltip="Enter Parameter Description" data-pr-position="right" src={iconInfo} alt="info" />
                                                    </i>
                                                </span>
                                            </label>
                                            <div class="input-wrap date-wrap">
                                                <input type="text" class="txt" id={"paramDesc" + 0} placeholder="Param Desc" />
                                                {errorDetails.errorParamDesc !== "" ?
                                                    <div className="error-message">{errorDetails.errorParamDesc}</div>
                                                    : ''}
                                                <div id={"errorParamDesc" + 0}></div>
                                            </div>
                                        </div>
                                    </div>
                                ) : ''}

                            </div>

                        </div>


                        {paramsType === "ONETIME" ? (


                            <div class="btn-wrap btn-wrap--grp mt-5" style={{ justifyContent: "space-between" }}>
                                {errorMessage != "" ? <div className="error" style={{ color: 'red' }}> {errorMessage} </div> : ""}
                                {/* <div class="col-lg-2 col-md-2 input-wrap txt-wrap" >
                                </div> */}
                                <div>
                                    <a style={{ textDecoration: "none" }}>
                                        <button type='button' className="button" onClick={() => setOpenModal(true)}>Clear Cache</button>&nbsp;<img title='Use this feature to clear cache using keys' src={iconInfo} alt="info" />
                                    </a>
                                </div>
                                <div>
                                <button class="button button--outline" onClick={() => resetSubmitDetails()}>Reset</button>
                                <button class="button" onClick={() => clickSubmitDetails()} >Save</button>
                                </div>
                            </div>
                        ) : <div class="btn-wrap btn-wrap--grp mt-5" style={{ justifyContent: "space-between" }}>
                            {errorMessage != "" ? <div className="error" style={{ color: 'red' }}> {errorMessage} </div> : ""}
                            {/* <div class="col-lg-2 col-md-2 input-wrap txt-wrap" >
                            </div> */}
                            <div>
                                    <a style={{ textDecoration: "none" }}>
                                        <button type='button' className="button" onClick={() => setOpenModal(true)}>Clear Cache</button>&nbsp;<img title='Use this feature to clear cache using keys' src={iconInfo} alt="info" />
                                    </a>
                            </div>
                            <div>
                            <button wa class="button button--outline" onClick={() => resetSubmitParamDetails()}>Reset</button>
                            <button class="button" onClick={() => clickSubmitSystemParamDetails()}>Save</button>
                            </div>
                        </div>}

                    </div>
                </div>


                <div class="container">

                    {paramsType === "ONETIME" ? (

                        <div class="table-block">
                            <div class="table-block__head">
                                <h2>List of One Time Params
                                    <span style={{ "fontWeight": "bold", "fontSize": "13px", "color": "#666" }} >
                                        &nbsp; [ Total: {tableData.length ? tableData.length : 0} &nbsp;]

                                    </span> </h2>
                                <div class="txt-wrap">
                                    <input type="text" class="txt" placeholder="Search..."
                                        value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                    <i class="fa fa-search"></i>
                                </div>
                            </div>
                        </div>
                    ) : ''}

                    {paramsType === "SYSTEM" ? (
                        <div class="table-block">
                            <div class="table-block__head">
                                <h2>List of System Params
                                    <span style={{ "fontWeight": "bold", "fontSize": "13px", "color": "#666" }} >
                                        &nbsp; [ Total: {tableData.length ? tableData.length : 0} &nbsp;]
                                    </span>
                                </h2>
                                <div class="txt-wrap">
                                    <input type="text" class="txt" placeholder="Search..."
                                        value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                    <i class="fa fa-search"></i>
                                </div>
                            </div>
                        </div>
                    ) : ''}



                    {paramsType === "ONETIME" ? (
                        <div className="table-responsive grid" style={{ overflowX: 'hidden' }}>
                            <table class="table" id="OneTimeParamsTable" style={{ tableLayout: 'fixed' }}>
                                <thead>
                                    <tr>
                                        <th scope="col" onClick={() => sortBy('one_time_key')}>One Time Key<i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" style={{ "wordWrap": "break-word" }} onClick={() => sortBy('one_time_value')}>One Time Value<i className="fa fa-sort" aria-hidden="true" ></i></th>
                                        <th scope="col" style={{ "wordWrap": "break-word" }} onClick={() => sortBy('one_time_desc')}>One Time Desc<i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" style={{ "wordWrap": "break-word" }} onClick={() => sortBy('one_time_code')}>One Time Code<i className="fa fa-sort" aria-hidden="true"></i></th>

                                        {/* <th scope="col" onClick={() => sortBy('file_name')}>One Time Code<i className="fa fa-sort" aria-hidden="true"></i></th> */}
                                        <th scope="col" className="no-sort">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(oneTimeParamsTableList.data && oneTimeParamsTableList.data.length <= 0) ? (<tr><td colSpan="6"><div className="text-center text-danger">No search results found</div></td></tr>) :
                                        oneTimeParamsTableList.data.map(data => (
                                            <tr>
                                                <td scope="col">{data.one_time_key}</td>
                                                <td style={{ "wordWrap": "break-word" }} scope="col">{data.one_time_value}</td>
                                                <td style={{ "wordWrap": "break-word" }} scope="col">{data.one_time_desc}</td>
                                                <td style={{ "wordWrap": "break-word" }} scope="col">{data.one_time_code}</td>
                                                {/* <td scope="col">{data.one_time_code}</td> */}


                                                <td scope="col">
                                                    <div className="table__action">
                                                        <span>
                                                            <div style={{ color: "red", fontWeight: "lighter" }}>
                                                                <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} />
                                                                <a id="error" onClick={((e) => editOneTimeParamsTableData(data))} className="utils utils--outline" style={{ cursor: 'pointer' }}><img className='toolTip' data-pr-tooltip="Edit" data-pr-position="bottom" src={edit_icon} alt="edit" /></a>

                                                            </div>

                                                        </span>
                                                    </div>
                                                </td>


                                            </tr>
                                        ))}

                                </tbody>

                            </table>
                        </div>

                    ) : ''}


                    {paramsType === "SYSTEM" ? (
                        <div className="table-responsive grid" style={{ overflowX: 'hidden' }}>
                            <table class="table" id="SystemParamsTable" style={{ tableLayout: 'fixed' }}>
                                <thead>
                                    <tr>
                                        <th scope="col" onClick={() => sortBy('param_key')}>Param Key<i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" style={{ "wordWrap": "break-word" }} onClick={() => sortBy('param_value')}>Param Value<i className="fa fa-sort" aria-hidden="true" ></i></th>
                                        <th scope="col" style={{ "wordWrap": "break-word" }} onClick={() => sortBy('param_desc')}>Param Desc<i className="fa fa-sort" aria-hidden="true"></i></th>

                                        {/* <th scope="col" onClick={() => disabled=sortBy('file_name')}>null <i className="fa fa-sort" aria-hidden="false"></i></th> */}

                                        <th scope="col" className="no-sort">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(systemParamsTableList.data && systemParamsTableList.data.length <= 0) ? (<tr><td colSpan="6"><div className="text-center text-danger">No search results found</div></td></tr>) :
                                        systemParamsTableList.data.map(data => (
                                            <tr>
                                                <td scope="col">{data.param_key}</td>
                                                <td style={{ "wordWrap": "break-word" }} scope="col">{data.param_value}</td>

                                                <td style={{ "wordWrap": "break-word" }} scope="col">{data.param_desc}</td>

                                                {/* <td scope="col">{data.param_code}</td> */}


                                                <td scope="col">
                                                    <div className="table__action">
                                                        <span>
                                                            <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} />
                                                            <a onClick={(e) => editSystemParamsTableData(data)} className="utils utils--outline" style={{ cursor: 'pointer' }}><img className='toolTip' data-pr-tooltip="Edit" data-pr-position="bottom" src={edit_icon} alt="edit" /></a>
                                                        </span>
                                                    </div>
                                                </td>

                                            </tr>
                                        ))}

                                </tbody>

                            </table>
                        </div>

                    ) : ''}

                </div>

            </main>
            <div class={`modal fade modal--monitoring ${openModal ? 'show' : ''}`} id="staticBackdrop" style={{ display: openModal ? 'block' : 'none' }}
                data-backdrop="static" data-keyboard="false" tabindex="-1"
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Clear Cache</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={(e) => { setOpenModal(false);}}>
                                <img src={iconClose} alt="Close" />
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div className="col-lg-12 col-md-12 input-wrap txt-wrap">
                                    <div className="form-group ">
                                        <label htmlFor="f5" className="d-block input-label">Select keys to clear &nbsp;<img title='Please select all cache keys you want to clear from the dropdown.' src={iconInfo} alt="info" /></label>
                                        <ReactSelect
                                            options={redisKeys.data}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={true}
                                            onChange={(e) => setInvalidateKeys(e)}
                                            allowSelectAll={true}
                                            value={invalidateKeys}
                                        />
                                    </div>
                                </div> 
                        </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="button button--outline button--sm" data-dismiss="modal" onClick={(e) => { setOpenModal(false);}}>Cancel</button>
                            <button type="button" class="button button--sm" onClick={(e) => { sendInvalidateRequest(); setOpenModal(false); }}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}

const mapDispatchToProps = dispatch => ({

    dispatchOneTimeParamsTableData: (onSuccess, onError) => dispatch(displayOneTimeParamsTableData(onSuccess, onError)),
    dispatchCreateOneTimeParams: (data, onSuccess, onError) => dispatch(createOneTimeParams(data, onSuccess, onError)),
    dispatchEditOneTimeParams: (data, onSuccess, onError) => dispatch(editOneTimeParamsTableData(data, onSuccess, onError)),
    dispatchSystemParamsTableData: (onSuccess, onError) => dispatch(displaySystemParamsTableData(onSuccess, onError)),
    dispatchCreateSystemParams: (data, onSuccess, onError) => dispatch(createSystemParams(data, onSuccess, onError)),
    dispatchEditSystemParams: (data, onSuccess, onError) => dispatch(editSystemParamsTableData(data, onSuccess, onError)),
    dispatchDeleteCache: (data, onSuccess, onError) => dispatch(deleteCache(data, onSuccess, onError)),
    dispatchGetCacheKeys: (onSuccess, onError) => dispatch(fetchCacheKeys(onSuccess, onError)),
})



export default connect(null, mapDispatchToProps)(OneTimeParamsComponent)
