import React from 'react';
import IconError from '../assets/images/icon-error.png';

const AlertFailure = ({ message }) => (
    <div className="alert alert--error">
        <div className="alert__icon"> <img src={IconError} alt="Success" /></div>
        <div className="alert__message">
        {/* <strong>Error!</strong> */}
         {message}
        </div>
        {/* <a href="#" class="close">
        <img src={IconClose} alt="close" />
        </a> */}
  </div>
);

export default AlertFailure;
