import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ButtonToggle = ({ buttons, onButtonClick, selectedButton }) => {
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);

  const handleButtonClick = (buttonIndex) => {
    setActiveButtonIndex(buttonIndex);
    onButtonClick(buttonIndex);
  };

  const buttonToggleStyle = {
    display: 'flex',
    justifyContent:'center'
  };

  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
    padding: '5px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    outline: 'none',
    fontSize: '14px'
  };

  const activeButtonStyle = {
    backgroundColor: '#ddd'
  };

  const iconStyle = {
    width: '90px',
    height: '90px',
    marginRight: '5px',
  };

  return (
    <div className="button-toggle btn-wrap" style={buttonToggleStyle}>
      {buttons.map((button, index) => (
        <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <button onClick={() =>  handleButtonClick(index)} style={index === activeButtonIndex ? {...buttonStyle, ...activeButtonStyle} : buttonStyle}>
            <img src={button.icon} alt={button.title} style={iconStyle} />
          </button>
          <h2 style={{ fontSize: '12px' , padding:'5px',margin:'5px', justify:'center'}}>{button.title}</h2>
        </div>
      ))}
    </div>
  );
}

ButtonToggle.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
    })
  ).isRequired,
  onButtonClick: PropTypes.func.isRequired,
  selectedButton: PropTypes.string,
};

export default ButtonToggle;