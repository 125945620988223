import React from 'react';
import FileMappingHomeComponent from '../components/FileMapping/file.mapping.home.component';




const FileMappingHomeComponentPage = () => {
    return(
        <FileMappingHomeComponent/>

    )
}

export default FileMappingHomeComponentPage;