import React from 'react';
import DashboardComponent from '../components/dashboard.component';


const DashboardPage = () => (
        
    <DashboardComponent />
);

export default DashboardPage;
