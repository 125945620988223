import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { getSrUnreadEmailReportData } from '../../redux/actions/auth.action.creators'
import close_icon from '../../assets/images/icon-close.png';


const ComponentForSRUnreadEmail = ({ dispatchGetSrUnreadEmailReportData }) => {

   const [isPopupEnable, setIsPopupEnable] = useState(false)
   const [unreadEmailData, setUnreadEmailData] = useState([])


   const getUnreadEmailInfo = () =>{
      dispatchGetSrUnreadEmailReportData(({ data, onSuccess, onError }) => {
         if (data) {
            setUnreadEmailData(data)
            setIsPopupEnable(true)
         } else {
            console.log("data : ", data);
         }
      }, (message) => {
         console.log(message);
      });
   }



   return (
      <div class="col-lg-4 mb-4">
         <div class="widget widget--box widget--report" data-toggle="modal" data-target="#srunreademail" id="rprt_unreademail">
            <div class="widget--report__details" onClick={(() => { getUnreadEmailInfo() })}>
               <div class="widget--report__title">
                  <p><strong>SR Manager Unread Email Report</strong></p>
               </div>
               <div class="widget--report__desc">
                  <p>This report lists all the unread mails in SR Manager mailboxes.
                  </p>
               </div>
            </div>
         </div>
         <div class="modal fade modal--addnew show" id="srunreademail" data-backdrop="static" data-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" style={{ display: isPopupEnable ? 'block' : 'none' }} aria-modal="true">
            <div class="modal-dialog modal-dialog-centered">
               <div class="modal-content">
                  <div class="modal-body">
                     <div class="container">
                        <div className="table-block">
                           <div className="table-block__head">
                              <h2>Unread Email Count</h2>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                 <img src={close_icon} alt="Close" />
                              </button> 
                           </div>
                           <br/>
                           <div class="table-responsive grid">
                              <table class="table">
                                 <thead>
                                    <tr>
                                       <th scope="col">Email </th>
                                       <th scope="col">Created At </th>
                                       <th scope='col'>Mail Count</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {isPopupEnable && unreadEmailData.length > 0 ? unreadEmailData.map((info) => (
                                       <tr>
                                          <td>{info.email_address}</td>
                                          <td>{info.created_at}</td>
                                          <td>{info.mail_count}</td>
                                       </tr>
                                    )) : ""}

                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="modal-footer">
                     <button type="button" class="button button--outline button--sm" data-dismiss="modal" aria-label="Close" onClick={(() => { setIsPopupEnable(false) })} >Cancel</button> 
                  </div>
               </div>
            </div>
         </div>
      </div>

   )
}


const mapDispatchToProps = dispatch => ({
   dispatchGetSrUnreadEmailReportData: (onSuccess, onError) =>
      dispatch(getSrUnreadEmailReportData(onSuccess, onError))
})

export default connect(null, mapDispatchToProps)(ComponentForSRUnreadEmail) 