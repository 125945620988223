
export const stripHtmlTags = (input) => {
    return input.replace(/<\/?[^>]+(>|$)/g, "");
};

export const sanitizedInput=(input,fieldName)=>{
    const sanitizedInput = stripHtmlTags(input);
    var result = true;
    var regex = new RegExp('^[a-zA-Z0-9]+$');
    if(input && fieldName){
      switch(fieldName){
        case 'partnerCode':
          regex = new RegExp('^[a-zA-Z0-9_]+$');
          break;
        case 'partnerName':
        case 'address':
        case 'city':
        case 'state':
        case 'country':
        case 'ud_GivenName':
        case 'ud_SurName':
          regex = new RegExp('^[a-zA-Z0-9_\\-.()&/ ]*$');
          break;
        case 'pincode':
          regex = new RegExp('^[0-9]+$');
          break;
        case 'mobile':
          regex = new RegExp('^[0-9]+$');
          break;
        case 'ud_Username':
        case 'templateName':
        case 'groupName':
            regex = new RegExp('^[A-Za-z0-9_-]*$');
            break;
        case 'groupDesc':
            regex = new RegExp('^[A-Za-z0-9_\\-\\.\\(\\)& ]*$');
            break;
      }
      result = regex.test(input); 
    }
    if (sanitizedInput !== input || !result) {
        return false;
    } 
    return true;
  }