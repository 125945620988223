import React, { useState, useEffect, useRef } from 'react';

import { Link } from 'react-router-dom';
import UserImg from '../assets/images/image-user.png';
import * as constants from '../redux/constants';
import { isJson, sessionErrorCheck } from '../../src/redux/actions/auth.action.creators';
var CryptoJS = require("crypto-js");
var appConf = require(process.env.REACT_APP_CONFIG_URL);
const logoName = appConf.constants.logoName;
const companyLogo  = require(`../assets/images/${logoName}`).default

const Header = ({ isLoggedIn, onLogout }) => {
    const userInfos = sessionErrorCheck();
    var decryptByte = userInfos === null ? null : CryptoJS.AES.decrypt(userInfos, appConf.switch.secretKey);
    const userInfo = userInfos;
    const screens = isLoggedIn && userInfo ? userInfo.screens : '';
    const [togglePopup, setTogglePopup] = useState(false);
    const ref = useRef();
    const companyCode = appConf.switch.companyCode;
    const logoClassName = "logo_" + companyCode.toLowerCase();

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (togglePopup && ref.current && !ref.current.contains(e.target)) {
                setTogglePopup(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [togglePopup])

    return (
        isLoggedIn ?
            <React.Fragment>
                <header className="header">
                    <div className="container">
                        <div className="header__top">
                            <div className={logoClassName}>
                                <Link to="/dashboard">
                                    <a className="navbar-brand" href="#"><img src={companyLogo} alt="Company Logo" /> </a>
                                </Link>
                            </div>
                            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <div className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </button>
                            <div className="header__user" onClick={() => setTogglePopup(!togglePopup)}>
                                <i className="user-thumb"><img src={UserImg} alt="User" /></i>
                                <div className="user-info d-none d-lg-block">
                                    <span className="user-name">{userInfo ? userInfo.fullName : null}</span>
                                    <span className="user-role">{userInfo ? userInfo.role : null}</span>
                                </div>
                                <div className="user-popover" ref={ref} style={{ display: togglePopup ? 'block' : 'none' }}>
                                    <div className="user-info d-lg-none">
                                        <span className="user-name">{userInfo ? userInfo.fullName : null}</span>
                                        <span className="user-role">{userInfo ? userInfo.role : null}</span>
                                    </div>
                                    <a className="logout" onClick={onLogout}>Sign Out</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header__menu">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg">
                                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a className="nav-link active" href="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                                        </li>
                                        <li className="nav-item" style={{ display: (userInfo && !(constants.PARTNERHEADERLISTINGMENU.some(ph => screens.includes(ph)))) ? 'none' : 'block' }}>
                                            <a className="nav-link"><i class="fas fa-hands-helping"></i> Manage Partner</a>
                                            <div className="nav-dropdown">
                                                <a href="/partner" style={{ display: (screens.indexOf('ManagePartnerPage') > -1) ? 'block' : 'none' }}>Partners</a>
                                                <a href="/group" style={{ display: (screens.indexOf('GroupPage') > -1) ? 'block' : 'none' }}>Groups</a>
                                                <a href="/template/listing" style={{ display: (screens.indexOf('TemplatePage') > -1) ? 'block' : 'none' }}>Templates</a>
                                                <a href="/channels" style={{ display: (screens.indexOf('ChannelPage') > -1) ? 'block' : 'none' }}>Channels</a>
                                                <a href="/report" style={{ display: (screens.indexOf('ReportPage') > -1) ? 'block' : 'none' }}>Reports</a>
                                                {/* <a href="/fileMissingAlert" style={{ display: (screens.indexOf('FileMissingAlertPage') > -1) ? 'block' : 'none' }}>Missed File Alert</a> */}

                                            </div>
                                        </li>
                                        <li className="nav-item" style={{ display: (userInfo && !(constants.CONFIGURATIONHEADERLISTINGMENU.some(ph => screens.includes(ph)))) ? 'none' : 'block' }}>
                                            <a className="nav-link"><i class="fas fa-cog"></i> Configurations</a>
                                            <div className="nav-dropdown">
                                                <a href="/addScheduler" style={{ display: (screens.indexOf('AddScheduler') > -1) ? 'block' : 'none' }}>Scheduler</a>
                                                <a href="/addSchedulerRunStatus" style={{ display: (screens.indexOf('SchedulerRunLogPage') > -1) ? 'block' : 'none' }}>Scheduler Run Log</a>
                                                <a href="/FileMapping" style={{ display: (screens.indexOf('FileMappingHomeComponentPage') > -1) ? 'block' : 'none' }}>File Mapping</a>
                                                <a href="/pgp" style={{ display: (screens.indexOf('PGPConfigurationPage') > -1) ? 'block' : 'none' }}>PGP Key Management</a>
                                                <a href="/sshKeygeneration" style={{ display: (screens.indexOf('SSHKeygenerationPage') > -1) ? 'block' : 'none' }}>SSH Key Management</a>
                                                <a href="/oneTimeSystemParams" style={{ display: (screens.indexOf('OneTimeParamsPage') > -1) ? 'block' : 'none' }}>System Configurations</a>
                                                <a href="/filenameConfiguration" style={{ display: (screens.indexOf('FilenameConfigurationPage') > -1) ? 'block' : 'none' }}>Filename Transformation</a>
                                                <a href="/partnerOverride" style={{ display: (screens.indexOf('PartnerOverridePage') > -1) ? 'block' : 'none' }}>Partner Override Config</a>
                                                <a href="/sequenceNumber" style={{ display: (screens.indexOf('SequenceNumberExcoPage') > -1) ? 'block' : 'none' }}>Sequence Number Update</a>
                                            </div>
                                        </li>
                                        <li className="nav-item" style={{ display: (userInfo && !(constants.UTILITIESHEADERLISTINGMENU.some(ph => screens.includes(ph)))) ? 'none' : 'block' }}>
                                            <a className="nav-link"><i class="fas fa-tools"></i> Utilities</a>
                                            <div className="nav-dropdown">
                                                <a href="/fileEncryptDecrypt" style={{ display: (screens.indexOf('FileEncryptDecryptPage') > -1) ? 'block' : 'none' }}>File Encrypt-Decrypt</a>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/fileUpload" style={{ display: (screens.indexOf('FileUploadPage') > -1) ? 'block' : 'none' }}><i class="fas fa-file-upload"></i> File Upload</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/fileMonitoring" style={{ display: (screens.indexOf('FileMonitoringPage') > -1) ? 'block' : 'none' }}><i class="fas fa-eye"></i> File Monitoring</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/user" style={{ display: (screens.indexOf('ManageUsrPage') > -1) ? 'block' : 'none' }}><i class="fas fa-user-cog"></i> Manage User</a>
                                        </li>
                                        {/* <li className="nav-item">
                            <a className="nav-link" href="/filenameConfiguration" style={{display: (screens.indexOf('FilenameConfigurationPage') > -1) ? 'block' : 'none'}}>Filename Configuration</a>
                            </li> */}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
            </React.Fragment> : ''
    );

}

export default Header;