import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import close_icon from '../../assets/images/icon-close.png';
import * as constants from '../../redux/constants'
import { fetchOneTimeParams, downlaodAuditReportAsExcelFile, fetchSystemParams ,listPartners} from '../../redux/actions/auth.action.creators'
import { DateRangePicker } from '../../utils/date-range-picker.component'
import { DownloadFileAsXLFromEncodedString } from '../../utils/DecodeToXL';
import CustomDropdownWidget from '../dropdown/CustomDropdownWidget';

const ComponentForAuditReport = ({ dispatchOneTimeParamsAction, dispatchAuditReport, dispatchSystemParamsAction,dispatchListPartnersAction }) => {
   const [selectedValue, setSelectedValue] = useState([]);
   const [isPopupEnable, setIsPopupEnable] = useState(false)
   const [dateRange, setDateRange] = useState(3);
   const [resetCalender, setResetCalender] = useState(false);
   const [errorsForDateFeild, setErrorsForDateFeild] = useState('');
   const [requestBody, setRequestBody] = useState({ "producerCode": "", "consumerCode": "", "fileName": "", "status": "", "startDate": "", "endDate": "" })
   const [dateResponseData, setDateResponseData] = useState("false");
   const [dropdownRefresher, setdropdownRefresher] = useState(true)
   const [partnerList, setPartnerList] = useState([]);
   const [statusList, setStatusList] = useState({ "data": "", "loading": true });
   
   const [responseData, setResponseData] = useState({});
   const [responseData1, setResponseData1] = useState({});
   const [constrains, setConstrains] = useState({ "fieldPlaceholder": "Select partner", "isMandatory": false, "fieldLabel": "Producer", "inputErrorMessage": "" })
   const [constrains1, setConstrains1] = useState({ "fieldPlaceholder": "Select partner", "isMandatory": false, "fieldLabel": "Consumer", "inputErrorMessage": "" })


   useEffect(() => {
      dispatchOneTimeParamsAction({ "keyList": [constants.FILE_STATUS] }, ({ data, message }) => {
         setStatusList({ "data": data[constants.FILE_STATUS], "loading": false });
      }, (message) => {
         console.log(message);
      });
   }, [dispatchOneTimeParamsAction])

   useEffect(() => {
      dispatchSystemParamsAction({ "keyList": [constants.AUDIT_REPORT_FILTER_LIMIT_DAY] }, ({ data, message }) => {
         if (data) {
            setDateRange(data[constants.AUDIT_REPORT_FILTER_LIMIT_DAY].paramValue);
         }
      }, (message) => {
         console.log(message);
      });
   }, [dispatchSystemParamsAction]);

   useEffect(() => {
      dispatchListPartnersAction(({data, message}) => {
         if(data){
            
            let arr = [{ "value": "", "label": "Any" }];
                  data.forEach(p => {
                      arr.push({
                          "value": p.partnercode,
                          "label": `${p.partnercode} (${p.partnername})`
                         
                      })
                  })
                  setPartnerList(arr);
         
              
              
         }
      },
         (message) => {
            console.log(message);
         });
   }, [dispatchListPartnersAction]);

   const setValueToRequestBody = (target, targetValue) => {
      setRequestBody({
        // "producerCode": target === "producerCode" ? targetValue : requestBody.producerCode,
        // "consumerCode": target === "consumerCode" ? targetValue : requestBody.consumerCode,
         "fileName": target === "fileName" ? targetValue : requestBody.fileName,
         "status": target === "status" ? targetValue : requestBody.status,
         "startDate": target === "startDate" ? targetValue : requestBody.startDate,
         "endDate": target === "endDate" ? targetValue : requestBody.endDate
      })
   }

   const reset = () => {
      setdropdownRefresher(dropdownRefresher ? false : true)
      setResponseData({});
      setResponseData1({});
      setRequestBody({ "producerCode": "", "consumerCode": "", "fileName": "", "status": "", "startDate": "", "endDate": "" });
      setResetCalender(true);
      
      setTimeout(() => {
         setResetCalender(false)
      }, 1000);
   }

  
  


   

   const valueIsNull = (v1) => { return v1 === null || v1 === "" || v1.length < 1 ? true : false; }
   const valueIsNotNull = (v1) => { return v1 !== null && v1 !== "" ? true : false; }

   const isValid = () => {
      if (valueIsNull(requestBody.startDate)) {
         return false;
      } else if (valueIsNull(requestBody.endDate)) {
         return false;
      } else if (valueIsNotNull(requestBody.startDate) && valueIsNotNull(requestBody.endDate)) { 
         var tempError = Math.ceil(Math.abs(new Date(requestBody.endDate) - new Date(requestBody.startDate)) / (1000 * 60 * 60 * 24)) > dateRange ? "Maximum date range is " + dateRange : ""; 
         console.log(tempError);
         setErrorsForDateFeild(tempError)
         if (valueIsNull(tempError)) {
            return true;
         } else {
            return false;
         }
      }
   }

   
   const onSubmit = () => { 
      console.log("validating.....");
      if (isValid()) {
         console.log("validation pass");
         console.log(requestBody);
         console.log(responseData);
         console.log(responseData1);
         console.log(responseData.value);
         let tempauditRequest  = _.cloneDeep(requestBody);
         tempauditRequest.producerCode = responseData.value || '';
         tempauditRequest.consumerCode = responseData1.value || '';
         console.log("requestBody sample test--->>  ",tempauditRequest);
         dispatchAuditReport(tempauditRequest, ({ data, message, status }) => {
            if (data && data.length > 1) {
               console.log("encoded String length :", data.length);
               DownloadFileAsXLFromEncodedString(data, constants.AUDIT_DOWNLOAD_REPORT_PREFIX);
            }
         })
      }
   } 
  

   useEffect(() => {
      let endDate = new Date();
      let startDate = new Date();
      if (dateResponseData != "false" && dateResponseData != null) {
         startDate = new Date(dateResponseData[0]);
         endDate = new Date(dateResponseData[1]);
      } else startDate.setDate(endDate.getDate() - (dateRange - 1));
      endDate = endDate.toLocaleDateString("en-CA") + " " + "23:59:59";
      startDate = startDate.toLocaleDateString("en-CA") + " " + "00:00:00";
      setRequestBody({ "producerCode": requestBody.producerCode, "consumerCode": requestBody.consumerCode, "fileName": requestBody.fileName, "status": requestBody.status, "startDate": startDate, "endDate": endDate })

   }, [dateResponseData])

   return (
      <div class="col-lg-4 mb-4">
         <div class="widget widget--box widget--report" data-toggle="modal" data-target="#auditreport" id="rprt_audit">
            <div class="widget--report__details" onClick={(() => { setIsPopupEnable(true) })}>
               <div class="widget--report__title">
                  <p><strong>Audit Report</strong></p>
               </div>
               <div class="widget--report__desc">
                  <p>This report lists all file transactions in a given date range.</p>
               </div> 
            </div>
         </div>
         <div class="modal fade modal--addnew show" aria-modal="true" style={{ display: isPopupEnable ? 'block' : 'none' }} id="auditreport" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel">
            <div class="modal-dialog modal-dialog-centered">
               <div class="modal-content">
                  <div class="modal-header">
                     <h5 class="modal-title" id="staticBackdropLabel">Audit Report</h5>
                     <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                 <img src={close_icon} alt="Close" />
                              </button>
                  </div>
                  <div class="modal-body">
                     <div class="form mb-5">
                        <div class="row">
                           <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                 
                                 
                                 <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={partnerList} constrains={constrains} setResponseData={setResponseData} ></CustomDropdownWidget>
                             
                                 
                                 
                              </div>
                           </div>
                           <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                 
                                 <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={partnerList} constrains={constrains1} setResponseData={setResponseData1} ></CustomDropdownWidget>
                                   
                                   
                                 
                              </div>
                           </div>
                           {/*  commenting file name filter 
                           <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                 <label for="fn" class="input-label">File Name</label>
                                 <input class="txt" type="text" onChange={((e) => setValueToRequestBody("fileName", e.target.value))} placeholder="File Name" id="fn" />
                              </div>
                           </div> */}
                           <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                 <label for="f2" class="d-block input-label">Status</label>
                                 <div class="input-wrap date-wrap">
                                    <select id="f1" onChange={((e) => setValueToRequestBody("status", e.target.value))} >
                                       <option value="">Any</option>
                                       {(statusList.loading) ? '' :
                                          statusList.data.map(s => (
                                             <option value={s.value}>{s.value}</option>
                                          ))}
                                    </select>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                 <label for="f2" class="d-block input-label">Date<span style={{ color: 'red' }}>*</span></label>
                                 <div class="input-wrap date-wrap">
                                    <i class="far fa-calendar-alt"></i>
                                    <span className='spinner-fade' style={{ zIndex: 2000 }}>
                                       <DateRangePicker customRange={dateRange} setDateResponseData={setDateResponseData} reset={resetCalender} />
                                    </span>
                                    {errorsForDateFeild && errorsForDateFeild != "" ?
                                       (<div className="error-message">{errorsForDateFeild}</div>)
                                       : ('')}

                                 </div>
                                 <div style={{ fontWeight: "lighter" }}>
                                 <label for="f1" class="input-label"> Note:- Please select a date range of maximum 6 months</label>
                                 </div>
                              </div>
                           </div>


                        </div>
                     </div>
                  </div>
                  <div class="modal-footer">
                     <button type="button" onClick={(() => { reset() })} class="button button--outline button--sm" data-dismiss="modal">Cancel</button>
                     <button type="button" onClick={(() => { onSubmit() })} class="button button--sm">Download Report</button>
                  </div>

               </div>
            </div>
         </div>
      </div>

   )
}


const mapDispatchToProps = dispatch => ({
   dispatchOneTimeParamsAction: (data, onSuccess, onError) =>
      dispatch(fetchOneTimeParams(data, onSuccess, onError)),
   dispatchAuditReport: (data, onSuccess, onError) =>
      dispatch(downlaodAuditReportAsExcelFile(data, onSuccess, onError)),
   dispatchSystemParamsAction: (data, onSuccess, onError) =>
      dispatch(fetchSystemParams(data, onSuccess, onError)),
   dispatchListPartnersAction: (onSuccess, onError) =>
      dispatch(listPartners(onSuccess, onError))

});

export default connect(null, mapDispatchToProps)(ComponentForAuditReport);