import React, { useEffect, useState } from "react";
import ComponentForAuditReport from "./report.audit";
import ComponentForFileDownloadAuditReport from "./report.filedownloadaudit"
import ComponentForFailedReport from "./report.failed.downloads";
import ComponentForSRUnreadEmail from "./report.sr.unread.email"
import ComponentForSrEmailCloggingReport from './report.sr.email.clogging'
import ComponentForUploadReport from "./report.failed.uploads";
import AlertFailure from '../../utils/alert-failure.component'
import AlertSuccess from '../../utils/alert-success.component'
import ComponentForPointHandbackReport from "./report.alertnotif.point.handback";
import WebPortalLoginAuditReportComponent from "./report.web.portal.login.audit"
import IdleChannelsPartnersReportComponent from "./report.idle.channels.partners.file"
import ComponentForPartnerFileSizeMonitor from "./report.partnerfilesizemonitor"

import CustomDropdownWidget from "../dropdown/CustomDropdownWidget";
import { isAdmin } from '../../redux/actions/auth.action.creators';

var configInfo = require(process.env.REACT_APP_CONFIG_URLS);
const reportConf = configInfo.switch.reports;
var companyCode = configInfo.switch.companyCode;

const ReportComponent = () => {

    const [popupVisibility, setPopupVisibility] = useState({ success: false, failed: false, message: null })


    const enablePopup = (isTrue, msg) => {
        if (isTrue) {
            setPopupVisibility({ success: true, failed: false, message: msg })
            setTimeout(() => {
                setPopupVisibility({ success: false, failed: false, message: null })
            }, 7000);
        } else {
            setPopupVisibility({ success: false, failed: true, message: msg })
            setTimeout(() => {
                setPopupVisibility({ success: false, failed: false, message: null })
            }, 7000);
        }
    }

    return (
        <React.Fragment>
            <main>
                <div class="container">
                    <ul className="breadcrumb">
                        <li className="breadcrumb__item">
                            <a className="breadcrumb__link" href="">Manage Partner</a>
                        </li>
                        <li className="breadcrumb__item">
                            <span className="breadcrumb__link" >Reports</span>
                        </li>
                    </ul>
                    <div >
                        {popupVisibility.failed ?
                            <AlertFailure message={popupVisibility.message} /> : ''}
                        {popupVisibility.success ?
                            <AlertSuccess message={popupVisibility.message} /> : ''}
                    </div>
                    <div class="title">
                        <h1>Reports</h1>
                        
                    </div>
                    <div class="form-row">
                        {reportConf.enable && reportConf.ComponentForFailedReport ? (<ComponentForFailedReport enablePopup={enablePopup}></ComponentForFailedReport>) : ''}
                        {reportConf.enable && reportConf.ComponentForUploadReport ? (<ComponentForUploadReport enablePopup={enablePopup}></ComponentForUploadReport>) : ''}
                        {reportConf.enable && reportConf.ComponentForAuditReport ? (<ComponentForAuditReport />) : ''}
                        {reportConf.enable && reportConf.ComponentForFileDownloadAuditReport ? (<ComponentForFileDownloadAuditReport />) : ''}
                        {reportConf.enable && reportConf.WebPortalLoginAuditReportComponent ? (<WebPortalLoginAuditReportComponent/> ) : ''}
                        {reportConf.enable && reportConf.ComponentForPointHandbackReport ? (<ComponentForPointHandbackReport />) : ''}
                        {reportConf.enable && reportConf.IdleChannelsPartnersReportComponent ? (<IdleChannelsPartnersReportComponent/> ) : ''}
                        {reportConf.enable && reportConf.ComponentForSRUnreadEmail && isAdmin()? (<ComponentForSRUnreadEmail />) : ''}
                        {reportConf.enable && reportConf.ComponentForSrEmailCloggingReport && isAdmin()? (<ComponentForSrEmailCloggingReport />) : ''}
                        {reportConf.enable && reportConf.ComponentForPartnerFileSizeMonitor ? (<ComponentForPartnerFileSizeMonitor />) : ''}
                    </div>
                </div>
            </main>
        </React.Fragment>
    )
};

export default ReportComponent;

