import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { DateRangePicker } from "../utils/date-range-picker.component";
import CustomDropdownWidget from './dropdown/CustomDropdownWidget';
import { listPartners, sessionErrorCheck, fileMissingSearch, displayFileMissingAlert, fetchSystemParams, findByGroupName, fetchOneTimeParams, isOperationalUser, isAdmin, displayfileMissingAlertNotificationList, closeIssueButton, editSequenceNumberTableData } from '../redux/actions/auth.action.creators';

import * as constants from '../redux/constants';
import AlertSuccess from '../utils/alert-success.component';
import AlertFailure from '../utils/alert-failure.component';
import close_icon from '../assets/images/icon-close.png';
import iconInfo from '../assets/images/icon-info-round.png';
import { Tooltip } from 'primereact/tooltip';
import { DownloadJsonAsExcel } from '../utils/JsonTOXLDownload';




const FileMissingAlertComponent = ({ dispatchListPartnersAction, dispatchFileMissingSearchAction, dispatchSystemParamsAction, dispatchFindByGroup, dispatchOneTimeParamsAction, dispatchdisplayfileMissingAlertNotificationList, dispatchCloseIssueButton }) => {

    const [failMsg, setFailMsg] = useState('');
    const [succMsg, setSuccMsg] = useState('');
    const [requestBody, setRequestBody] = useState({ "filePattern": "", "status": "", "partnerCode": "", "periodFrom": "", "periodTo": "", "limit": "30" });
    const [dateRange, setDateRange] = useState(30);
    const [dateResponseData, setDateResponseData] = useState("false");
    const [resetCalender, setResetCalender] = useState(false);
    const [errorConst, setErrorConst] = useState({ "date": "" });
    const [dropdownRefresher, setdropdownRefresher] = useState(true)
    const [partnerList, setPartnerList] = useState([]);
    const [constrains, setConstrains] = useState({ "fieldPlaceholder": "Select partner", "isMandatory": false, "fieldLabel": "Partner", "inputErrorMessage": "" })
    const [responsePartnerData, setResponsePartnerData] = useState({});
    const [statusList,] = useState({ "data": "", "loading": true });

    const [filteredFileMissingList, setFilteredFileMissingList] = useState({ "loading": true, "data": [] });
    const [tableData, setTableData] = useState([]);
    const [sortDirection, setSortDirection] = useState({ "partner_code": '', "file_pattern": '', "period_from": '', "period_to": '', "expected_batch": '', "last_received_file": '', "last_received_date": '', "status": '' });

    const userInfos = sessionErrorCheck() !== null ? sessionErrorCheck() : window.location = '/auth';
    const userInfo = userInfos;

    const [fileMissingSearchKey, setFileMissingSearchKey] = useState('');
    const [fileMissingList, setFileMissingList] = useState([]);
    const [retreiveLimitList, setRetreiveLimitList] = useState({ "data": "", "loading": true });
    const [retreieveLimit, setRetreiveLimit] = useState(constants.FILEMONITOR_RETREIVELIMIT_DEFAULT);
    const [loggedin_Ptr, setLoggedin_Ptr] = useState('');
    const [airlineGroup, setAirlineGroup] = useState("");

    const [group, setGroup] = useState('ALL')
    const [statusRefresh, setStatusRefresh] = useState(true)
    const [airlineList, setAirlineList] = useState([]);
    const [NonAirlineList, setNonAirlineList] = useState([]);
    const [airlineListTrigger, setAirlineListTrigger] = useState(null);
    const [partnerListTrigger, setPartnerListTrigger] = useState(null);
    const [filteredFileMissingNotificationList, setFilteredFileMissingNotificationList] = useState({ "loading": true, "data": [] });
    const [notificationTableData, setNotificationTableData] = useState([]);

    // const [details, setDetails] = useState({ "status": "", "remarks": "", "fileMissingTrackId": "" });
    const [requestBodyClose, setRequestBodyClose] = useState({ "remarks": "", "status": "", "fileMissingTrackId": "" });
    const [remarks, setRemarks] = useState("");
    const [errorDetails, setErrorDetails] = useState({ "errorRemarks": "" })
    const [inputValue, setInputValue] = useState('');
    const [incrementValue, setIncrementValue] = useState(1);

    const [status, setStatus] = useState('');
    const [modalVisible, setModalVisible] = useState(true);
    const initialDetails = {
        status: '',
        remarks: '',
        fileMissingTrackId: '',
    };
    const [details, setDetails] = useState(initialDetails);



    useEffect(() => {
        dispatchSystemParamsAction({ "keyList": [constants.FILEMONITOR_DATERANGE, constants.FILEMONITOR_RETREIVELIMIT_LIST] }, ({ data, message }) => {
            if (data) {
                setDateRange(data[constants.FILEMONITOR_DATERANGE].paramValue);

                setRetreiveLimitList({ "data": data[constants.FILEMONITOR_RETREIVELIMIT_LIST].paramValue.split(','), "loading": false });
            }
        }, (message) => {
            console.log(message);
        });
    }, [dispatchSystemParamsAction]);

    useEffect(() => {
        dispatchOneTimeParamsAction({ "keyList": [constants.AIRLINE_GROUP] }, ({ data, message }) => {
            setAirlineGroup(data[constants.AIRLINE_GROUP][0].value);
            // console.log('data', data[constants.AIRLINE_GROUP][0].value)
        }, (message) => {
            console.log(message);
        });
    }, [dispatchOneTimeParamsAction])

    useEffect(() => {
        // console.log("responsePartnerData", responsePartnerData)
        if (responsePartnerData != 'false' && responsePartnerData != null) {

            setRequestBody({ "partnerCode": responsePartnerData.value, "filePattern": requestBody.filePattern, "status": requestBody.status, "periodFrom": requestBody.periodFrom, "periodTo": requestBody.periodTo, "limit": requestBody.limit });
        }
    }, [responsePartnerData])

    useEffect(() => {
        if (userInfo.role != 'Partner') {
            let partnerSearchList = [];
            if (group === 'AIRLINE') {
                partnerSearchList = airlineList.filter(p => (p.value != "")).map(v => v.value)
            } else if (group === 'NONAIRLINE') {
                partnerSearchList = NonAirlineList.filter(p => (p.value != "")).map(v => v.value)
            }
            setRequestBody({ "filePattern": requestBody.filePattern, "partnerCode": partnerSearchList, "status": requestBody.status, "periodFrom": requestBody.periodFrom, "periodTo": requestBody.periodTo, "limit": requestBody.limit })

        }
    }, [group])

    useEffect(() => {
        if (airlineListTrigger == 2 && partnerListTrigger == 2) {
            if (userInfo.role != 'Partner') {

                let nonAirlineArr = [{ "value": "", "label": "Any" }];
                let AirlineArr = [];
                partnerList.forEach(p => {
                    if (!airlineList.some(item => item.value === p.value)) {
                        nonAirlineArr.push(p)
                    }
                })
                setNonAirlineList(nonAirlineArr);
                airlineList.forEach(p => {
                    if (partnerList.some(item => item.value === p.value)) {
                        AirlineArr.push(p)
                    }
                })
                setAirlineList(AirlineArr);

            }
        }
    }, [airlineListTrigger, partnerListTrigger])
    useEffect(() => { airlineListTrigger < 3 ? setAirlineListTrigger(airlineListTrigger + 1) : null }, [airlineList])
    useEffect(() => { partnerListTrigger < 3 ? setPartnerListTrigger(partnerListTrigger + 1) : null }, [partnerList])
    useEffect(() => {
        if (airlineGroup != "") {
            let partnerArr = [{ "value": "", "label": "Any" }];
            let airlineArr = [{ "value": "", "label": "Any" }];

            dispatchListPartnersAction(({ data, message }) => {
                if (data) {
                    if (userInfo.role == 'Partner') {
                        let currentPartner = data.filter(p => (p.partnerid == userInfo.partnerId))[0].partnercode;
                        setPartnerList([{ "value": currentPartner, "label": currentPartner }]);
                        setLoggedin_Ptr(currentPartner);
                        setGroup('ALL')
                        setRequestBody({ "filePattern": requestBody.filePattern, "partnerCode": requestBody.partnerCode, "status": requestBody.status, "periodFrom": requestBody.periodFrom, "periodTo": requestBody.periodTo, "limit": requestBody.limit })
                    }
                    else {
                        data.forEach(p => {
                            partnerArr.push({
                                "value": p.partnercode,
                                "label": p.partnercode
                            })
                        })
                        setPartnerList(partnerArr);
                    }
                }
            });

            if (userInfo.role != 'Partner') {
                dispatchFindByGroup(airlineGroup, ({ data, message }) => {
                    // console.log('airlineGroup', airlineGroup)
                    // dispatchListPartnersAction(({ data, message }) => {
                    if (data) {

                        data.forEach(p => {
                            airlineArr.push({
                                "value": p.partner_code,
                                "label": p.partner_code
                            })
                        })
                        setAirlineList(airlineArr);
                        let partnerSearchList = [];

                        if (group === 'AIRLINE') {
                            partnerSearchList = airlineArr.filter(p => (p.value != "")).map(v => v.value)
                        }
                        setRequestBody({ "filePattern": requestBody.filePattern, "partnerCode": partnerSearchList, "status": requestBody.status, "periodFrom": requestBody.periodFrom, "periodTo": requestBody.periodTo, "limit": requestBody.limit })
                    }
                });
            }
        }
    }, [airlineGroup])

    useEffect(() => {
        let filtered = _.cloneDeep(filteredFileMissingList.data);
        if (!filteredFileMissingList.loading) {
            // console.log("search");
            filtered = tableData.filter(c => {
                var file_count = '' + c.file_count;
                return (c.partner_code.toLowerCase().includes(fileMissingSearchKey.toLowerCase())
                    || c.file_pattern.toLowerCase().includes(fileMissingSearchKey.toLowerCase())
                    || c.status.toLowerCase().includes(fileMissingSearchKey.toLowerCase()))
                    || c.expected_batch.toLowerCase().includes(fileMissingSearchKey.toLowerCase())
                    || c.last_received_file.toLowerCase().includes(fileMissingSearchKey.toLocaleLowerCase())
                    || file_count.includes(fileMissingSearchKey)
            });
        }
        setFilteredFileMissingList({ "loading": false, "data": filtered });
    }, [fileMissingSearchKey])


    const setLimitToResponsedataAndRetrivelimit = (value) => {
        setRetreiveLimit(value);
        setRequestBody({ "filePattern": requestBody.filePattern, "partnerCode": requestBody.partnerCode, "status": requestBody.status, "periodFrom": requestBody.periodFrom, "periodTo": requestBody.periodTo, "limit": value });

    }

    useEffect(() => {
        let periodFrom = new Date();
        let periodTo = new Date();
        if (dateResponseData != "false" && dateResponseData != null) {
            periodFrom = new Date(dateResponseData[0]);
            periodTo = new Date(dateResponseData[1]);

            // } else periodFrom.setDate(periodTo.getDate() - (dateRange - 1));
        }
        periodTo = periodTo.toLocaleDateString("en-CA") + " " + "23:59:59";
        periodFrom = periodFrom.toLocaleDateString("en-CA") + " " + "00:00:00";
        setRequestBody({ "filePattern": requestBody.filePattern, "partnerCode": requestBody.partnerCode, "status": requestBody.status, "periodFrom": periodFrom, "periodTo": periodTo, "limit": requestBody.limit })

    }, [dateResponseData])

    const onSearch = () => {
        if (isFormValid()) {
            let temprequest = _.cloneDeep(requestBody);

            if (!Array.isArray(requestBody.partnerCode)) {
                let prtarr = [''];
                if (requestBody.partnerCode != '' && requestBody.partnerCode != null) {
                    prtarr = [requestBody.partnerCode]

                }
                temprequest.partnerCode = prtarr
            }
            dispatchFileMissingSearchAction(temprequest, ({ data, message }) => {
                if (data) {
                    let tempList = [];
                    let index = 0;
                    var plist = [];
                    for (var i = 0; i < partnerList.length; i++) {
                        var partner = partnerList[i];
                        plist.push(partner.value);
                    }
                    data.forEach((val, i) => {
                        if (plist.includes(val.partner_code)) {
                            tempList.push(val);
                            index++;
                        }
                    });
                    setTableData(tempList);

                    setFilteredFileMissingList({ "loading": false, "data": tempList });
                    if (index == retreieveLimit) {
                        window.scrollTo(0, 0);
                        setSuccMsg("We have set a limit of " + retreieveLimit + " results per search. Please refine your search to get more accurate results.");
                        setTimeout(() => {
                            setSuccMsg('')
                        }, 5000);
                    }
                }
            }, (message) => {
                console.log(message);
            });
        }
    }

    const onSearchAndDownload = () => {
        if (isFormValid()) {
            let temprequest = _.cloneDeep(requestBody);

            if (!Array.isArray(requestBody.partnerCode)) {
                let prtarr = [''];
                if (requestBody.partnerCode != '' && requestBody.partnerCode != null) {
                    prtarr = [requestBody.partnerCode]
                }
                temprequest.partnerCode = prtarr
            }
            dispatchFileMissingSearchAction(temprequest, ({ data, message }) => {
                if (data) {
                    let tempList = [];
                    let index = 0;
                    var plist = [];
                    for (var i = 0; i < partnerList.length; i++) {
                        var partner = partnerList[i];
                        plist.push(partner.value);
                    }
                    data.forEach((val, i) => {
                        if (plist.includes(val.partner_code)) {
                            tempList.push(val);
                            index++;
                        }
                    });
                    setTableData(tempList);

                    setFilteredFileMissingList({ "loading": false, "data": tempList });
                    if (index == retreieveLimit) {
                        window.scrollTo(0, 0);
                        setSuccMsg("We have set a limit of " + retreieveLimit + " results per search. Please refine your search to get more accurate results.");
                        setTimeout(() => {
                            setSuccMsg('')
                        }, 5000);
                    }
                    console.log("tempList",tempList);
                    DownloadJsonAsExcel(formattedData(tempList), "File_Missing_Alert_Report");

                }
            }, (message) => {
                console.log(message);
            });
        }
    }

    const formattedData = (data) => {
        var temp = data.map(item => {
            const date = item.last_received_date ? new Date(item.last_received_date) : null;
            const date1 = new Date(item.period_to);
            const date2 = new Date(item.period_from);

            const formattedDate = date ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}` : null;
            const formattedDate1 = date1.getTime() === 0 ? '' : `${date1.getFullYear()}-${(date1.getMonth() + 1).toString().padStart(2, '0')}-${date1.getDate().toString().padStart(2, '0')} ${date1.getHours().toString().padStart(2, '0')}:${date1.getMinutes().toString().padStart(2, '0')}:${date1.getSeconds().toString().padStart(2, '0')}`;
            const formattedDate2 = date2.getTime() === 0 ? '' :  `${date2.getFullYear()}-${(date2.getMonth() + 1).toString().padStart(2, '0')}-${date2.getDate().toString().padStart(2, '0')} ${date2.getHours().toString().padStart(2, '0')}:${date2.getMinutes().toString().padStart(2, '0')}:${date2.getSeconds().toString().padStart(2, '0')}`;
            const { file_missing_track_id, ...rest } = item;
            console.log("rest",rest);
            
        const rearrangedItem = {
            partner_code: rest.partner_code,
            Partner_name: rest.partner_name,
            file_pattern: rest.file_pattern,
            period_from: formattedDate2,
            period_to: formattedDate1,
            expected_batch: rest.expected_batch,
            last_received_file: rest.last_received_file,
            last_received_date: formattedDate,
            file_count: rest.file_count,
            status: rest.status,
            remarks: rest.remarks
        };
        
        return rearrangedItem;
        });
        return temp;
    } 





    const eventFromConfigDropdown = (group) => {
        if (group === 'ALL') {
            setStatusRefresh(statusRefresh ? false : true)
            setGroup('ALL')
        } else if (group === 'AIRLINE') {
            // console.log('group', group)
            setStatusRefresh(statusRefresh ? false : true)
            setGroup('AIRLINE')
        } else if (group === 'NONAIRLINE') {
            setStatusRefresh(statusRefresh ? false : true)
            setGroup('NONAIRLINE')

        }

    }


    function sortFn(dir, sortArr, type) {
        let result = sortArr.sort((a, b) => {
            if (a[type] < b[type]) {
                return dir === "asc" ? -1 : 1;
            }
            if (a[type] > b[type]) {
                return dir === "asc" ? 1 : -1;
            }
            return 0;
        });
        return result;
    }


    function onSort(type) {
        let dir = "asc";
        if (sortDirection[type] === "asc") {
            dir = "desc";
        }
        if (type === 'partner_code') {
            setFilteredFileMissingList({ "loading": false, "data": sortFn(dir, filteredFileMissingList.data, type) });
            setSortDirection({ "partner_code": dir, "file_pattern": sortDirection.file_pattern, "period_from": sortDirection.period_from, "period_to": sortDirection.period_to, "expected_batch": sortDirection.expected_batch, "last_received_file": sortDirection.last_received_file, "last_received_date": sortDirection.last_received_date, "status": sortDirection.status });
        } else if (type === 'file_pattern') {
            setFilteredFileMissingList({ "loading": false, "data": sortFn(dir, filteredFileMissingList.data, type) });
            setSortDirection({ "partner_code": sortDirection.partner_code, "file_pattern": dir, "period_from": sortDirection.period_from, "period_to": sortDirection.period_to, "expected_batch": sortDirection.expected_batch, "last_received_file": sortDirection.last_received_file, "last_received_date": sortDirection.last_received_date, "status": sortDirection.status });
        } else if (type === 'period_from') {
            setFilteredFileMissingList({ "loading": false, "data": sortFn(dir, filteredFileMissingList.data, type) });
            setSortDirection({ "partner_code": sortDirection.partner_code, "file_pattern": sortDirection.file_pattern, "period_from": dir, "period_to": sortDirection.period_to, "expected_batch": sortDirection.expected_batch, "last_received_file": sortDirection.last_received_file, "last_received_date": sortDirection.last_received_date, "status": sortDirection.status });
        } else if (type === 'period_to') {
            setFilteredFileMissingList({ "loading": false, "data": sortFn(dir, filteredFileMissingList.data, type) });
            setSortDirection({ "partner_code": sortDirection.partner_code, "file_pattern": sortDirection.file_pattern, "period_from": sortDirection.period_from, "period_to": dir, "expected_batch": sortDirection.expected_batch, "last_received_file": sortDirection.last_received_file, "last_received_date": sortDirection.last_received_date, "status": sortDirection.status });
        } else if (type === 'expected_batch') {
            setFilteredFileMissingList({ "loading": false, "data": sortFn(dir, filteredFileMissingList.data, type) });
            setSortDirection({ "partner_code": sortDirection.partner_code, "file_pattern": sortDirection.file_pattern, "period_from": sortDirection.period_from, "period_to": sortDirection.period_to, "expected_batch": dir, "last_received_file": sortDirection.last_received_file, "last_received_date": sortDirection.last_received_date, "status": sortDirection.status });
        } else if (type === 'last_received_file') {
            setFilteredFileMissingList({ "loading": false, "data": sortFn(dir, filteredFileMissingList.data, type) });
            setSortDirection({ "partner_code": sortDirection.partner_code, "file_pattern": sortDirection.file_pattern, "period_from": sortDirection.period_from, "period_to": sortDirection.period_to, "expected_batch": sortDirection.expected_batch, "last_received_file": dir, "last_received_date": sortDirection.last_received_date, "status": sortDirection.status });
        } else if (type === 'last_received_date') {
            setFilteredFileMissingList({ "loading": false, "data": sortFn(dir, filteredFileMissingList.data, type) });
            setSortDirection({ "partner_code": sortDirection.partner_code, "file_pattern": sortDirection.file_pattern, "period_from": sortDirection.period_from, "period_to": sortDirection.period_to, "expected_batch": sortDirection.expected_batch, "last_received_file": sortDirection.last_received_file, "last_received_date": dir, "status": sortDirection.status });
        } else if (type === 'status') {
            setFilteredFileMissingList({ "loading": false, "data": sortFn(dir, filteredFileMissingList.data, type) });
            setSortDirection({ "partner_code": sortDirection.partner_code, "file_pattern": sortDirection.file_pattern, "period_from": sortDirection.period_from, "period_to": sortDirection.period_to, "expected_batch": sortDirection.expected_batch, "last_received_file": sortDirection.last_received_file, "last_received_date": sortDirection.last_received_date,"file_count":dir, "status": dir });
        } else if( type == 'file_count'){
            setFilteredFileMissingList({ "loading": false, "data": sortFn(dir, filteredFileMissingList.data, type) });
            setSortDirection({ "partner_code": sortDirection.partner_code, "file_pattern": sortDirection.file_pattern, "period_from": sortDirection.period_from, "period_to": sortDirection.period_to, "expected_batch": sortDirection.expected_batch, "last_received_file": sortDirection.last_received_file, "last_received_date": sortDirection.last_received_date,"file_count":dir, "status": dir });
    
        }
    }




    function resetForm() {
        setdropdownRefresher(dropdownRefresher ? false : true)
        // console.log("loggedin_Ptr", loggedin_Ptr)
        setRequestBody({ "filePattern": "", "status": "", "partnerCode": loggedin_Ptr, "periodFrom": "", "periodTo": "", "limit": constants.FILEMONITOR_RETREIVELIMIT_DEFAULT });
        setRetreiveLimit(constants.FILEMONITOR_RETREIVELIMIT_DEFAULT)
        setResetCalender(true);
        // setFilteredFileMissingList(false);
        setGroup('ALL')
        setTimeout(() => {
            setResetCalender(false)
        }, 1000);

    }


    function isFormValid() {
        let tempError = _.cloneDeep(errorConst);
        tempError.date = Math.ceil(Math.abs(new Date(requestBody.endDate) - new Date(requestBody.startDate)) / (1000 * 60 * 60 * 24)) > dateRange ? "Maximum date range is " + dateRange : "";
        setErrorConst(tempError);
        return !tempError.date;
    }


    const setValueToRequestBody = (target, targetValue) => {

    }

    const notificationList = (details) => {

        dispatchdisplayfileMissingAlertNotificationList(details.file_missing_track_id, ({ data, message }) => {
            // For hiding previously displayed error
            if(errorConst && errorConst.remarks){ 
                errorConst.remarks = "";
            }

            if (data) {
                // console.log("list", data.emailDate)

                let tempList = [];
                let index = 0;
                data.forEach(val => {
                    val.expected_batch = details.expected_batch;
                    val.partner_code = details.partner_code;
                    val.period_from = details.period_from.substring(0, 19).replace('T', ' ');
                    val.period_to = details.period_to;
                    val.file_pattern = details.file_pattern;
                    val.file_count = details.file_count;

                    // console.log("val", val)
                    tempList.push(val);
                    val.remarks;
                    index++;
                });
                setNotificationTableData(tempList);
                setFilteredFileMissingNotificationList({ "loading": false, "data": tempList });
                setDetails(details)


            }
        }, (message) => {
            console.log(message);

        });


    }



    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        let tempError = _.cloneDeep(errorConst);
        tempError.remarks = isRemarksValid() ? "" : "Unsupported characters found in Remarks!";
        setErrorConst(tempError);
    };

    function isRemarksValid(){
        var regex = new RegExp('^[a-zA-Z0-9_\\- .()&]+$');
        const isValidRemarks = regex.test(inputValue); 
        return isValidRemarks;
    }

    function handleSubmit() {
        // Implement your form submission logic here
        var tempData = new FormData();
        var pushRemarks = [];
        for (let index = 0; index < incrementValue; index++) {
            var remarks = (document.getElementById("remarks" + index) || {}).value;
            if (remarks !== undefined) {
                pushRemarks.push(remarks);

            }
        }
        // console.log("fileMissingAlertDetails", details)
        // console.log("value", inputValue)
        let tempError = _.cloneDeep(errorConst);
        tempError.remarks = isRemarksValid() ? "" : "Unsupported characters found in Remarks!";
        setErrorConst(tempError);
        if (isRemarksValid()) {
            if (details.file_missing_track_id) 
            tempData.append('fileMissingTrackId', details.file_missing_track_id);
            tempData.append('remarks', inputValue);
            tempData.append('status', "Closed");
            dispatchCloseIssueButton(tempData, ({ data, status , code , description }) => {
                
                if(status === 'SUCCESS'){
                    setRequestBodyClose({ "remarks": requestBodyClose.remarks, "status": requestBodyClose.status, "fileMissingTrackId": requestBodyClose.file_missing_track_id });
                    setSuccMsg("Issue Closed Successfully");
                    onSearch()
                }else if(status === 'FAILURE'){
                    let tempError = _.cloneDeep(errorConst);
                    tempError.remarks = description;
                    setErrorConst(tempError);
                }
                
            }, (message) => {
                console.log(message);
                let tempError = _.cloneDeep(errorConst);
                tempError.remarks = message;
                setErrorConst(tempError);
            });

            setInputValue('');
            setDetails({ "status": "", "remarks": "", "fileMissingTrackId": "" })
        }
    };

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    }

    const closeModal = () => {
        setModalVisible(false);
    };



    const handleReset = () => {
        setDetails(initialDetails);

    };







    return (
        <React.Fragment>

            <main>
                <div class="container">
                    <ul class="breadcrumb">
                        <li class="breadcrumb__item">
                            <a class="breadcrumb__link" href="#">Configurations</a>
                        </li>
                        <li class="breadcrumb__item">
                            <span class="breadcrumb__link">Missed File Alert</span>
                        </li>
                    </ul>
                    <div class="title">
                        <h1>Missed File Alert</h1>
                    </div>
                    {failMsg ?
                        <AlertFailure message={failMsg} /> : ''}
                    {succMsg ?
                        <AlertSuccess message={succMsg} /> : ''}
                </div>

                <div class="grey-block">
                    <div class="container">
                        <div class="form mb-5">
                            <h2 class="mb-4">Search File</h2>
                            {isAdmin() || isOperationalUser() ? (
                                <div class="row">
                                    {airlineList.length > 1 && NonAirlineList.length > 1 ? <div class="col-lg-6 col-md-6">
                                        <div>
                                            <label for="pc" class="input-label">Partner Type</label>
                                            <select name="selectList" id="selectList" value={group} onChange={(e) => { eventFromConfigDropdown(e.target.value) }}>
                                                <input type="text" class="txt" id={"allfiles" + 0} placeholder="Choose Files" />
                                                <option value="ALL">All</option>
                                                <option value="AIRLINE">Airline Partners</option>
                                                <option value="NONAIRLINE">Non-Airline Partners</option>
                                            </select>
                                        </div>
                                    </div> : ""}
                                </div>
                            ) : ''}
                            <div class="row">

                                {group === "ALL" ? (

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={partnerList} constrains={constrains} setResponseData={setResponsePartnerData}></CustomDropdownWidget>
                                        </div>
                                    </div>
                                ) : ''}

                                {group === "AIRLINE" ? (

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={airlineList} constrains={constrains} setResponseData={setResponsePartnerData}></CustomDropdownWidget>
                                        </div>
                                    </div>
                                ) : ''}

                                {group === "NONAIRLINE" ? (

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={NonAirlineList} constrains={constrains} setResponseData={setResponsePartnerData}></CustomDropdownWidget>
                                        </div>
                                    </div>
                                ) : ''}

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f2" class="d-block input-label">Date</label>
                                        <div class="input-wrap date-wrap">
                                            <i class="far fa-calendar-alt"></i>
                                            <DateRangePicker customRange={dateRange} setDateResponseData={setDateResponseData} defaultDates={[new Date(),new Date()]} reset={resetCalender} />
                                            {errorConst.date ?
                                                (<div className="error-message">{errorConst.date}</div>)
                                                : ('')}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f1" class="input-label"> File Name</label>
                                        <div class="input-wrap select-wrap">
                                            <input type="text" class="txt" placeholder="File name" value={requestBody.filePattern} onChange={(e) => setRequestBody({ "filePattern": e.target.value, "status": requestBody.status, "partnerCode": requestBody.partnerCode, "periodFrom": requestBody.periodFrom, "periodTo": requestBody.periodTo, "limit": retreieveLimit })} />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f2" class="d-block input-label">Status</label>
                                        <select id="f1" onChange={(e) => { setRequestBody({ "filePattern": requestBody.filePattern, "partnerCode": requestBody.partnerCode, "status": e.target.value, "periodFrom": requestBody.periodFrom, "periodTo": requestBody.periodTo, "limit": retreieveLimit }) }}
                                            value={requestBody.status}>
                                            <option value="">Any</option>
                                            <option value="O">Open</option>
                                            <option value="N">Notified</option>
                                            <option value="E">Escalated</option>
                                            <option value="C">Closed</option>
                                            {(statusList.loading) ? '' :
                                                statusList.data.map(s => (
                                                    <option value={s.value}>{s.value}</option>
                                                ))}


                                        </select>
                                    </div>
                                </div>


                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="f2" class="d-block input-label">Search Result Limit</label>
                                        <div class="input-wrap date-wrap">
                                            <select id="limit" onChange={(e) => { setLimitToResponsedataAndRetrivelimit(e.target.value) }}>
                                                {(retreiveLimitList.loading) ? '' :
                                                    retreiveLimitList.data.map(s => (
                                                        <option value={s} selected={retreieveLimit === s ? 'selected' : ''}>{s} Records</option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>

                        <div className="btn-wrap btn-wrap--grp mt-5" style={{ justifyContent: "space-between" }}>
                            <div>
                                <button onClick={() => onSearchAndDownload()} class="button">Download Report</button>
                            </div>

                            <div>
                                <button class="button button--outline" onClick={() => resetForm()}>Reset</button>
                                <button class="button" onClick={() => onSearch()}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="container">
                    <div class="table-block">
                        <div class="table-block__head">
                            <h2>List of Files
                                <span style={{ "fontWeight": "bold", "fontSize": "13px", "color": "#666" }} >
                                    &nbsp; [ Total: {filteredFileMissingList ? filteredFileMissingList.data.length : 0} ]

                                </span></h2>

                            <div class="txt-wrap">
                                <input type="text" class="txt" placeholder="Search..."
                                    value={fileMissingSearchKey} onChange={(e) => setFileMissingSearchKey(e.target.value)} />
                                <i class="fa fa-search"></i>
                            </div>
                        </div>
                        <div className="table-responsive grid">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" onClick={() => onSort('partner_code')} style={{ cursor: "pointer" }}>Producer <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => onSort('file_pattern')} style={{ cursor: "pointer" }}>File Pattern <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => onSort('period_from')} style={{ cursor: "pointer" }}>Period From <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => onSort('period_to')} style={{ cursor: "pointer" }}>Period To <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => onSort('expected_batch')} style={{ cursor: "pointer" }}>Expected Batch <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th style={{ "wordWrap": "break-word" }} scope="col" onClick={() => onSort('last_received_file')} >Last Received FileName <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => onSort('last_received_date')} style={{ cursor: "pointer" }}>Last Received Date <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => onSort('file_count')} style={{ cursor: "pointer" }}>File Count <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => onSort('status')} style={{ cursor: "pointer" }}>Status <i className="fa fa-sort" aria-hidden="true"></i></th>
                                        <th scope="col" onClick={() => onSort('')} style={{ cursor: "pointer" }}>Notification Send Details <i className="fa fa-sort" aria-hidden="true"></i></th>

                                    </tr>
                                </thead>

                                <tbody>
                                    {filteredFileMissingList.loading || (!filteredFileMissingList.loading && filteredFileMissingList.data.length == 0) ? (<tr><td colSpan="100"><div className="text-center text-danger">No search results found</div></td></tr>) :
                                        filteredFileMissingList.data.map(data =>

                                        (<tr>
                                            <td>{data.partner_code}</td>
                                            <td>{data.file_pattern}</td>
                                            <td>{data.period_from.substring(0, 19).replace('T', ' ')}</td>
                                            <td>{data.period_to.substring(0, 19).replace('T', ' ')}</td>
                                            <td>{data.expected_batch}</td>
                                            <td>{data.last_received_file}</td>
                                            <td>{(data.last_received_date == null || data.last_received_date == '') ? data.last_received_date : data.last_received_date.substring(0, 19).replace('T', ' ')}</td>
                                            <td>{data.file_count}</td>
                                            <td>{data.status}</td>
                                            <td><a class="link" href='#notificationDetailsModal' data-toggle="modal" data-target="#notificationDetailsModal" onClick={({ e }) => notificationList(data)}>Notification Details</a></td>
                                            {/* <td >{data.file_missing_track_id}</td> */}
                                        </tr>
                                        ))}
                                </tbody>

                            </table>
                        </div>



                        <div class="modal fade modal--addnew show" id="notificationDetailsModal" tabindex="-1" aria-labelledby="notificationDetailsModal" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="notificationDetailsModal">Notification Details</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={handleReset}>
                                            <img src={close_icon} alt="Close" />
                                        </button>
                                    </div>

                                    <div class="modal-body">
                                        <div class="form mb-5">
                                            <div class="row" >

                                                <div class="col-lg-4 col-md-4" >
                                                    <div class="form-group">
                                                        <label for={"remarks"} class="input-label">Remarks<span style={{ color: 'red' }}>*</span>
                                                            <span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} />
                                                                <i>
                                                                    <img className="toolTip" data-pr-tooltip="Please enter the remarks" data-pr-position="right" src={iconInfo} alt="info" />
                                                                </i>
                                                            </span>
                                                        </label>

                                                        <div className="input-wrap txt-wrap">
                                                            <textarea rows="4" className='textarea textarea-resize' type="text" id={"remarks"} placeholder="Remarks" value={inputValue} value={details.remarks}
                                                                onChange={(e) => {
                                                                    
                                                                    setDetails({
                                                                        ...details, // Preserve the existing properties
                                                                        remarks: e.target.value, // Update only the "status" property
                                                                    });
                                                                }} onChange={handleInputChange} readOnly={details.status === 'Closed'} />
                                                                {errorConst.remarks ?
                                                                    (<div className="error-message">{errorConst.remarks}</div>)
                                                                    : ('')}
                                                            {/* <input className='textarea textarea-resize' type="text" id={"remarks"} placeholder="Remarks" value={inputValue} onChange={handleInputChange} /> */}


                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-4">
                                                    <div class="form-group">
                                                        <label for="f2" class="d-block input-label">Status
                                                            <span style={{ color: "red", fontWeight: "lighter" }}></span>

                                                        </label>
                                                        {filteredFileMissingList.status}
                                                        <input
                                                            className="txt"
                                                            type="text"
                                                            value={details.status}
                                                            onChange={(e) => {
                                                                setDetails({
                                                                    ...details, // Preserve the existing properties
                                                                    status: e.target.value, // Update only the "status" property
                                                                });
                                                            }}
                                                            id="status"
                                                            size="30"
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>


                                                <div class="col-lg-4 col-md-4" >
                                                    <div class="form-group">
                                                        <label for="f2" class="d-block input-label">Partner
                                                            <span style={{ color: "red", fontWeight: "lighter" }}></span>

                                                        </label>

                                                        <div className="input-wrap txt-wrap">
                                                            <input rows="4" className='textarea textarea-resize' type="text" id={"partner"} value={inputValue} value={details.partner_code}
                                                                onChange={(e) => {
                                                                    setDetails({
                                                                        ...details, // Preserve the existing properties
                                                                        partner: e.target.value, // Update only the "status" property
                                                                    });
                                                                }} readOnly />
                                                            {/* <input className='textarea textarea-resize' type="text" id={"remarks"} placeholder="Remarks" value={inputValue} onChange={handleInputChange} /> */}


                                                        </div>

                                                    </div>
                                                </div>

                                            </div>


                                            <div class="row" >
                                                <div class="col-lg-4 col-md-4">
                                                    <div class="form-group">
                                                        <label for="f2" class="d-block input-label">File Pattern
                                                            <span style={{ color: "red", fontWeight: "lighter" }}></span>

                                                        </label>
                                                        <div className="input-wrap txt-wrap">
                                                            <input rows="4" className='textarea textarea-resize' type="text" id={"filePattern"} value={inputValue} value={details.file_pattern}
                                                                onChange={(e) => {
                                                                    setDetails({
                                                                        ...details, // Preserve the existing properties
                                                                        filePattern: e.target.value, // Update only the "status" property
                                                                    });
                                                                }} readOnly />
                                                            {/* <input className='textarea textarea-resize' type="text" id={"remarks"} placeholder="Remarks" value={inputValue} onChange={handleInputChange} /> */}
                                                        </div>

                                                    </div>
                                                </div>



                                                <div class="col-lg-4 col-md-4">
                                                    <div class="form-group">
                                                        <label for="f2" class="d-block input-label">Expected Batch
                                                            <span style={{ color: "red", fontWeight: "lighter" }}></span>

                                                        </label>
                                                        <div className="input-wrap txt-wrap">
                                                            <input rows="4" className='textarea textarea-resize' type="text" id={"expectedBatch"} value={inputValue} value={details.expected_batch}
                                                                onChange={(e) => {
                                                                    setDetails({
                                                                        ...details, // Preserve the existing properties
                                                                        expected_batch: e.target.value, // Update only the "status" property
                                                                    });
                                                                }} readOnly />
                                                            {/* <input className='textarea textarea-resize' type="text" id={"remarks"} placeholder="Remarks" value={inputValue} onChange={handleInputChange} /> */}
                                                        </div>

                                                    </div>
                                                </div>


                                                <div class="col-lg-4 col-md-4">
                                                    <div class="form-group">
                                                        <label for="f2" class="d-block input-label">Date
                                                            <span style={{ color: "red", fontWeight: "lighter" }}></span>

                                                        </label>
                                                        <div className="input-wrap txt-wrap">
                                                            <input rows="4" className='textarea textarea-resize' type="text" id={"periodFrom"} value={details.period_from ? details.period_from.substring(0, 19).replace('T', ' ') : ''}
                                                                onChange={(e) => {
                                                                    setDetails({
                                                                        ...details, // Preserve the existing properties
                                                                        period_from: e.target.value, // Update only the "status" property
                                                                    });
                                                                }} readOnly />
                                                            {/* <input className='textarea textarea-resize' type="text" id={"remarks"} placeholder="Remarks" value={inputValue} onChange={handleInputChange} /> */}
                                                        </div>

                                                    </div>
                                                </div>




                                            </div>
                                            <div class="row">
                                                <div class="col-lg-4 col-md-4">
                                                        <div class="form-group">
                                                            <label for="f2" class="d-block input-label">File Count
                                                                <span style={{ color: "red", fontWeight: "lighter" }}></span>

                                                            </label>
                                                            <div className="input-wrap txt-wrap">
                                                                <input rows="4" className='textarea textarea-resize' type="text" id={"fileCount"} value={inputValue} value={details.file_count}
                                                                    onChange={(e) => {
                                                                        setDetails({
                                                                            ...details, // Preserve the existing properties
                                                                            file_count: e.target.value, // Update only the "status" property
                                                                        });
                                                                    }} readOnly />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                      </div>

                                    {/* buttons */}
                                    <div class="modal-body">
                                        <div class="btn-wrap btn-wrap--grp mt-5 justify-content-end">
                                            {/* <button class="button" type="close" disabled onClick={() => closeForm()}>Close Issue</button> */}
                                            <button class="button" aria-label="Close" data-dismiss={ isRemarksValid() ? "modal" : ""} disabled={!inputValue} onClick={handleSubmit}>Close Issue</button>
                                            <button class="button" onClick={() => onSearch()}>Escalate</button>
                                        </div>
                                    </div>

                                    <div class="modal-body">
                                        <div className="table-responsive grid">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        {/* <th scope="col" onClick={() => onSort('partner_code')} style={{ cursor: "pointer" }}>Partner <i className="fa fa-sort" aria-hidden="true"></i></th> */}
                                                        {/* <th scope="col" onClick={() => onSort('file_pattern')} style={{ cursor: "pointer" }}>File Pattern <i className="fa fa-sort" aria-hidden="true"></i></th> */}
                                                        <th scope="col" onClick={() => onSort('period_from')} style={{ cursor: "pointer" }}>Period From <i className="fa fa-sort" aria-hidden="true"></i></th>
                                                        <th scope="col" onClick={() => onSort('period_to')} style={{ cursor: "pointer" }}>Period To <i className="fa fa-sort" aria-hidden="true"></i></th>
                                                        <th scope="col" onClick={() => onSort('expected_batch')} style={{ cursor: "pointer" }}>Expected Batch <i className="fa fa-sort" aria-hidden="true"></i></th>
                                                        <th scope="col" onClick={() => onSort('emailDate')} style={{ cursor: "pointer" }}>Email Date <i className="fa fa-sort" aria-hidden="true"></i></th>
                                                        <th scope="col" onClick={() => onSort('emailList')} style={{ cursor: "pointer" }}>Email list <i className="fa fa-sort" aria-hidden="true"></i></th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {filteredFileMissingNotificationList.loading || (!filteredFileMissingNotificationList.loading && filteredFileMissingNotificationList.data.length == 0) ? (<tr><td colSpan="100"><div className="text-center text-danger">No search results found</div></td></tr>) :
                                                        filteredFileMissingNotificationList.data.map(data =>
                                                        (<tr>
                                                            {/* <td>{data.partner_code}</td>
                                                            <td>{data.file_pattern}</td> */}
                                                            <td>{data.period_from.substring(0, 19).replace('T', ' ')}</td>
                                                            <td>{data.period_to.substring(0, 19).replace('T', ' ')}</td>
                                                            <td>{data.expected_batch}</td>
                                                            <td>{data.emailDate.substring(0, 19).replace('T', ' ')}</td>
                                                            <td>{data.emailList}</td>


                                                        </tr>
                                                        ))}
                                                </tbody>

                                            </table>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        </React.Fragment >

    )
}

const mapDispatchToProps = dispatch => ({
    dispatchListPartnersAction: (onSuccess, onError) =>
        dispatch(listPartners(onSuccess, onError)),
    dispatchFileMissingSearchAction: (data, onSuccess, onError) =>
        dispatch(fileMissingSearch(data, onSuccess, onError)),
    // dispatchFileMissingAlertData: (onSuccess, onError) => dispatch(displayFileMissingAlert(onSuccess, onError)),
    dispatchSystemParamsAction: (data, onSuccess, onError) =>
        dispatch(fetchSystemParams(data, onSuccess, onError)),
    dispatchFindByGroup: (data, onSuccess, onError) =>
        dispatch(findByGroupName(data, onSuccess, onError)),
    dispatchOneTimeParamsAction: (data, onSuccess, onError) =>
        dispatch(fetchOneTimeParams(data, onSuccess, onError)),

    dispatchOneTimeParamsTableData: (onSuccess, onError) => dispatch(displayOneTimeParamsTableData(onSuccess, onError)),

    dispatchdisplayfileMissingAlertNotificationList: (data, onSuccess, onError) =>
        dispatch(displayfileMissingAlertNotificationList(data, onSuccess, onError)),


    dispatchCloseIssueButton: (data, onSuccess, onError) =>
        dispatch(closeIssueButton(data, onSuccess, onError)),




});

export default connect(null, mapDispatchToProps)(FileMissingAlertComponent)

 
