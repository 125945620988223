import React from 'react';
import FileTransferStatusComponent from '../components/file.transfer.status.component';

const FileTransferStatusPage = () => {
    return(
        <FileTransferStatusComponent/>

    )
}

export default FileTransferStatusPage;



