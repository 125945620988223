import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CustomDropdownWidget from './dropdown/CustomDropdownWidget';
import { FileStructureConfigurationModel } from "../Bean/filestructure.js";
import AlertFailure from '../utils/alert-failure.component'
import AlertSuccess from '../utils/alert-success.component'
import iconEdit from '../assets/images/icon-edit.png';
import iconInfo from '../assets/images/icon-info-round.png';
import { Tooltip } from 'primereact/tooltip';
import sort_icon from '../assets/images/icon-sort.png';
import iconDelete from '../assets/images/icon-delete.png';
import _ from "lodash";
import { SortObjectArray } from "../utils/ArraySort";
import * as constants from '../redux/constants';
import { fetchOneTimeParams, getAllActiveChannelsList, createFilenameConfiguration, getAllFilenameConverterList, getFilenameConverterInfo, deleteByFilenameConverterID } from "../redux/actions/auth.action.creators";



const FilenameConfigurationComponent = ({ dispatchOneTimeParamsAction, dispatchGetAllActiveChannelsList, dispatchCreateFilenameConfiguration, dispatchgetAllFilenameConverterList, dispatchgetFilenameConverterInfo, dispatchDeleteByFilenameConverterID }) => {
  const [requestbody, setRequestbody] = useState(FileStructureConfigurationModel);
  const [inputErrorWarning, setInputErrorWarning] = useState({ channel: "", partner_type: "", partner_name: "" });
  const [fleStructureErrorWarning, setFleStructureErrorWarning] = useState([{}])

  const [filenameConverterList, setFilenameConverterList] = useState({ loading: false, info: [{}] })
  const [filenameConverterListForListing, setFilenameConverterListForListing] = useState({ loading: true, info: [{}] })

  const [dropdownRefresher, setdropdownRefresher] = useState(true)
  const [constrains, setConstrains] = useState({ "fieldPlaceholder": "Select Channel", "isMandatory": true, "fieldLabel": "Channel", "inputErrorMessage": "" })
  const [channelListForDropDown, setChannelListForDropDown] = useState([{}])
  const [channelListWithPartner, setChannelListWithPartner] = useState([{}])
  const [responseData, setResponseData] = useState({});
  const [popupVisibility, setPopupVisibility] = useState({ success: false, failed: false, message: null })
  const [channel_edit, setChannel_edit] = useState({ label: "", value: "" })
  const [pageRefresher, setPageRefresher] = useState(true)
  const [fc_variable_datatype, setfc_variable_datatype] = useState([])
  const [fc_variable_grouptype, setfc_variable_grouptype] = useState([])
  const [fc_variable_operation, setfc_variable_operation] = useState([])
  const [activeAccordianIndexList, setActiveAccordianIndexList] = useState([])
  const [onSearchKeyword, setOnSearchKeyword] = useState('.')
  var fileStructure_temp = ""

  const [collapseFileStructureJSX, setCollapseFileStructureJSX] = useState(true)

  // useEffects 

  useEffect(() => {
    dispatchGetAllActiveChannelsList(({ data, message, status }) => {
      if (data) {
        setChannelListWithPartner(data)
        var temp = []
        data.map((ch) =>
          temp.push({ value: ch.channelname, label: ch.channelname })
        )
        setChannelListForDropDown(temp)
      }
    });
  }, []);

  useEffect(() => {
    try {
      if (filenameConverterList.loading) {
        var tempMemory = filenameConverterList.info.filter(a =>
          a.channel_name.toLowerCase().includes(onSearchKeyword.toLowerCase())
          || a.partner_type.toLowerCase().includes(onSearchKeyword.toLowerCase())
          || a.to_file_structure.toLowerCase().includes(onSearchKeyword.toLowerCase())
        );
        setFilenameConverterListForListing({ loading: true, info: tempMemory })
      }

    } catch (error) {
      console.log(error);
    }
  }, [onSearchKeyword])


  useEffect(() => {
    dispatchOneTimeParamsAction({ "keyList": [constants.FILENAMECONVERTER_DATATYPE, constants.FILENAMECONVERTER_OPERATION, constants.FILENAMECONVERTER_GROUPTYPE] }, ({ data, message }) => {
      if (data) {
        setfc_variable_datatype(data.filenameconvertervariable_datatype[0].value.split(","))
        setfc_variable_grouptype(data.filenameconvertervariable_grouptype[0].value.split(","))
        setfc_variable_operation(data.filenameconvertervariable_operation[0].value.split(","))
      }
    }, (message) => {
      console.log(message);
    });
  }, [])

  useEffect(() => {
    dispatchgetAllFilenameConverterList(({ data, message, status }) => {
      if (data) {
        setFilenameConverterList({ loading: true, info: data })
        setFilenameConverterListForListing({ loading: true, info: data })
        setOnSearchKeyword('')
      }
    });
  }, [pageRefresher]);

  useEffect(() => {
    var selectedChannel = responseData.value
    assignValueToRequestBody("channel", selectedChannel)
  }, [responseData]);
  { }
  useEffect(() => {
    var selectedPartner = {}
    selectedPartner = channelListWithPartner.find(ch => { return ch.channelname === requestbody.channel })
    assignValueToRequestBody("partner_name", requestbody.partner_type === 'CNS' ? selectedPartner.consumer_partnercode : requestbody.partner_type === 'PRD' ? selectedPartner.producer_partnercode : '')
  }, [requestbody.partner_type]);


  // function 
  let handleChangeFileStructureWidget = (i, e) => {
    e.preventDefault()
    console.log(e.target.name);
    console.log(e.target.value);
    let newWidgetElements = [...requestbody.filename_converter_detail];
    newWidgetElements[i][e.target.name] = e.target.value;
    assignValueToRequestBody("filename_converter_detail", newWidgetElements);
    formating_fileStructure()
  };

  let addFileStructureWidgets = () => {
    console.log("Inside Add widgets");
    var isValidForm = fileStructureValidator();
    console.log("isValidForm"+isValidForm);
    if (isValidForm) {
      let newWidgetElements = [...requestbody.filename_converter_detail];
      newWidgetElements.push({
        content_type: "",
        content_value: "",
        variable_name: "temp",
        start: "",
        end: "",
        group_name: "",
        group_type: "",
        group_value: "",
        data_type: "",
        operation: "",
        widget_operation: "I",
        filename_converter_detail_Id: ""
      });
      assignValueToRequestBody("filename_converter_detail", newWidgetElements);
    }
  };

  let removeFileStructureWidgets = (i) => {
    let newWidgetElements = [...requestbody.filename_converter_detail];
    var deletedWidget = newWidgetElements.splice(i, 1);
    deletedWidget = deletedWidget[0]
    if (deletedWidget.widget_operation === "M") {
      deletedWidget.widget_operation = "D"
      newWidgetElements.push(deletedWidget)
    }
    assignValueToRequestBody("filename_converter_detail", newWidgetElements);
  };

  const getChannelIdByName = (name) => {
    var data = channelListWithPartner.find(ch => { return ch.channelname === name })
    return data.channel_id
  }

  const getChannelNameById = (id) => {
    id = parseInt(id)
    console.log(channelListWithPartner);
    var data = channelListWithPartner.find(ch => { return ch.channel_id === id })
    return data.channelname
  }

  const getFilenameConverter = (id) => {
    console.log("get info of :", id);
    dispatchgetFilenameConverterInfo(id, ({ data, message, status }) => {
      if (data) {
        resetForm()
        window.scrollTo(0, 0)
        data.channel = getChannelNameById(data.channel)
        setChannel_edit({ "label": data.channel, "value": data.channel })
        setRequestbody(data)
      } else {
        window.scrollTo(0, 0)
        console.log("failed");
        setPageRefresher(pageRefresher ? false : true);
      }
    }, (message) => {
      console.log("message : ", message);
      setPageRefresher(pageRefresher ? false : true);
    });
  }

  const submitFormInputData = () => {
    if (formating_fileStructure()) {
      if (isDuplicate()) {
        window.scrollTo(0, 0)
        setPopupVisibility({ success: false, failed: true, message: "Filename converter duplicate entry found." })
        setTimeout(() => { setPopupVisibility({ success: false, failed: false, message: null }) }, 5000);
      } else {
        if (inputFeildValidator() && fileStructureValidator()) {
          console.log("validation success");
          var requestBodytemp = _.cloneDeep(requestbody);
          console.log(requestBodytemp);
          requestBodytemp.channel = getChannelIdByName(requestbody.channel)
          requestBodytemp.to_file_structure = fileStructure_temp
          dispatchCreateFilenameConfiguration(requestBodytemp, ({ data, message, status }) => {
            if (data) {
              resetForm()
              window.scrollTo(0, 0)
              console.log("success");
              setPageRefresher(pageRefresher ? false : true);
              setPopupVisibility({ success: true, failed: false, message: "Filename converter successfully created." })
              setTimeout(() => { setPopupVisibility({ success: false, failed: false, message: null }) }, 5000);
            } else {
              window.scrollTo(0, 0)
              console.log("failed");
              setPageRefresher(pageRefresher ? false : true);
              setPopupVisibility({ success: false, failed: true, message: "Filename converter creation failed." })
              setTimeout(() => { setPopupVisibility({ success: false, failed: false, message: null }) }, 5000);
            }
          }, (message) => {
            console.log("message : ", message);
            window.scrollTo(0, 0)
            setPopupVisibility({ success: false, failed: true, message: "Filename converter creation failed! " + message })
            setTimeout(() => { setPopupVisibility({ success: false, failed: false, message: null }) }, 10000);
            setPageRefresher(pageRefresher ? false : true);
          });
        } else {
          console.log("Validation Failed");
        }
      }
    }
  }

  const formatFinder = (dataString) => {
    var charArray = dataString.split("")
    var feildRecord = []
    var feildValue = []
    var strValue = ""
    var isText = true;

    for (var x in charArray) {

      if (charArray[x].includes('{')) {
        if (strValue != "") {
          feildValue.push(strValue)
        }
        strValue = ""
        isText = false;
        feildRecord.push("VARIABLE")
        strValue = strValue + charArray[x]
      } else if (charArray[x].includes('}')) {
        isText = true;
        strValue = strValue + charArray[x]
        feildValue.push(strValue)
        strValue = ""
      } else if (!charArray[x].includes('{') && !charArray[x].includes('}') && !isText) {
        strValue = strValue + charArray[x]
      } else if (!charArray[x].includes('{') && !charArray[x].includes('}') && isText) {
        strValue = strValue + charArray[x]
        var len_fei = feildRecord.length
        var lastData = feildRecord[len_fei - 1]
        if (lastData != "TEXT") {
          feildRecord.push("TEXT")
        }
      } else if (charArray.length - 1 == x) {
        feildValue.push(strValue)
      }
    }
    return feildRecord
  }


  const deleteFilenameConverter = (id) => {
    console.log("delete id details :", id);
    dispatchDeleteByFilenameConverterID(id, ({ data, message, status }) => {
      if (data) {
        resetForm()
        window.scrollTo(0, 0);
        setPageRefresher(pageRefresher ? false : true);
        setPopupVisibility({ success: true, failed: false, message: "Filename converter details deletion success" })
        setTimeout(() => { setPopupVisibility({ success: false, failed: false, message: null }) }, 5000);
      } else {
        window.scrollTo(0, 0)
        console.log("failed");
        setPageRefresher(pageRefresher ? false : true);
        setPopupVisibility({ success: false, failed: true, message: "Filename converter details deletion failed" })
        setTimeout(() => { setPopupVisibility({ success: false, failed: false, message: null }) }, 5000);
      }
    }, (message) => {
      console.log("message : ", message);
      setPageRefresher(pageRefresher ? false : true);
    });
  }


  const fileStructureValidator = () => {
    let fs = [...requestbody.filename_converter_detail];
    let tempJsonArray = []
    fs.forEach(element => {
      var tempJson = { content_type: "", content_value: "", variable_name: "", start: "", end: "", group_name: "", group_type: "", group_value: "", data_type: "", operation: "" }
      if (element.content_type === '') { tempJson.content_type = "Choose Any"; } else { tempJson.content_type = ""; }
      if (element.content_type === 'TEXT' && element.content_value === "") { tempJson.content_value = "Enter text value"; } else { tempJson.content_value = ""; }
      if (element.content_type === 'VARIABLE' && element.variable_name === "") { tempJson.variable_name = "Enter variable value"; } else { tempJson.variable_name = ""; }
      // if (element.content_type === 'VARIABLE' && element.start === "") { tempJson.start = "Enter start index"; } else { if (element.start >= element.end) { tempJson.start = "Please provide valid data (less than end index" } else { tempJson.start = ""; } }
      // if (element.content_type === 'VARIABLE' && element.end === "") { tempJson.end = "Enter end index"; } else { if (element.start >= element.end) { tempJson.end = "Please provide valid data(greater than start index) " } else { tempJson.end = ""; } }
      if (element.content_type === 'VARIABLE' && element.start === "") { tempJson.start = "Enter start index"; } else { tempJson.start = ""; }
      if (element.content_type === 'VARIABLE' && element.end === "") { tempJson.end = "Enter end index"; } else { tempJson.end = ""; }
      if (element.content_type === 'VARIABLE' && element.group_name === "") { tempJson.group_name = "Enter group name"; } else { if (element.content_type === 'VARIABLE') { if (element.group_name.includes("{") || element.group_name.includes("}")) { tempJson.group_name = "{ } Character not allowed"; } else { tempJson.group_name = ""; } } else { tempJson.group_name = ""; } }
      if (element.content_type === 'VARIABLE' && element.group_type === "") { tempJson.group_type = "Enter group type"; } else { tempJson.group_type = ""; }
      if (element.content_type === 'VARIABLE' && element.group_value === "") { tempJson.group_value = "Enter group value"; } else { tempJson.group_value = ""; }
      if (element.content_type === 'VARIABLE' && element.data_type === "") { tempJson.data_type = "Enter data_type"; } else { tempJson.data_type = ""; }
      if (element.content_type === 'VARIABLE' && element.operation === "") { tempJson.operation = "Enter Operation Value"; } else { tempJson.operation = ""; }
      tempJsonArray.push(tempJson)
    });
    setFleStructureErrorWarning(tempJsonArray)
    console.log("Test temp json array =>",tempJsonArray.some(data => data.content_type === 'Choose Any' && data.content_value === ''));
    if(tempJsonArray.some(data => data.content_type === 'Choose Any' && data.content_value === "")){
      return false;
    } else if (tempJsonArray.some(data => data.content_type === 'TEXT' && data.content_value === "")) {
      return false;
    } else if (tempJsonArray.some(data => data.content_type === 'VARIABLE' &&
      (
        isNullOrEmpty(data.variable_name) || isNullOrEmpty(data.start) || isNullOrEmpty(data.end) || isNullOrEmpty(data.group_name)
        || isNullOrEmpty(data.group_type) || isNullOrEmpty(data.group_value) || isNullOrEmpty(data.data_type) || isNullOrEmpty(data.operation)
      ))) {
      return false
    } else if (tempJsonArray.length === 0) {
      setPopupVisibility({ success: false, failed: true, message: "Filename converter details are mandatory" })
      setTimeout(() => { setPopupVisibility({ success: false, failed: false, message: null }) }, 5000);
      return false
    } else {
      return true;
    }
  }

  const inputFeildValidator = () => {
    var temp = {
      channel: "",
      partner_type: isNullOrEmpty(requestbody.partner_type) ? "Choose partner type" : "",
      partner_name: ""
    }
    setConstrains({ "fieldPlaceholder": constrains.fieldPlaceholder, "isMandatory": constrains.isMandatory, "fieldLabel": constrains.fieldLabel, "inputErrorMessage": requestbody.channel === "" ? "Choose any channel" : "" })
    setInputErrorWarning(temp)
    if (isNullOrEmpty(fileStructure_temp)) {
      console.log("Validation failed in input feild validation function file structure empty");
      setPopupVisibility({ success: false, failed: true, message: "Filename converter details are mandatory" })
      setTimeout(() => { setPopupVisibility({ success: false, failed: false, message: null }) }, 5000);
      return false
    }
    else if (isNullOrEmpty(requestbody.channel) || isNullOrEmpty(requestbody.partner_type) || isNullOrEmpty(requestbody.partner_name)) {
      console.log("Validation failed in input feild validation function");
      return false
    }
    return true
  }

  const isNullOrEmpty = (data) => {
    console.log("adataaaaa : ", data);
    if (!data || (data === "") || (data == null)) {
      return true;
    } else {
      return false;
    }
  }

  function resetForm() {
    setRequestbody(FileStructureConfigurationModel)
    setdropdownRefresher(dropdownRefresher ? false : true)
  }

  const formating_fileStructure = () => {
    var formatValue = ""
    let element = [...requestbody.filename_converter_detail];
    console.log("element");
    console.log(element);
    element.forEach(data => {
      if (data.content_type === "TEXT" && (data.widget_operation === "I" || data.widget_operation === "M")) {
        formatValue = formatValue + data.content_value
      } else if (data.content_type == "VARIABLE" && (data.widget_operation === "I" || data.widget_operation === "M")) {
        formatValue = formatValue + "{" + data.group_name + "}"
      }
    })
    console.log("new format : ", formatValue);
    fileStructure_temp = formatValue
    assignValueToRequestBody("to_file_structure", formatValue);
    return true;
  }

  const isDuplicate = () => {
    const data_body = _.cloneDeep(requestbody);
    var responseArr = []
    if (filenameConverterList.loading) {
      filenameConverterList.info.forEach(data => {
        if (data.channel_name === data_body.channel && "CNS" === data_body.partner_type && data.consumer_partnercode === data_body.partner_name && data.to_file_structure === fileStructure_temp) {
          responseArr.push("true")
        } else if (data.channel_name === data_body.channel && "PRD" === data_body.partner_type && data.producer_partnercode === data_body.partner_name && data.to_file_structure === fileStructure_temp) {
          responseArr.push("true2")
        }
      })
      console.log(responseArr);
      if (responseArr.length > 0) {
        return true;
      } else {
        return false;
      }
      // var data = responseArr.find(data => { return data === true })
    } else {
      console.log("Unable to perform duplication check... filenameconverter list data is : ", filenameConverterList);
      return false
    }

  }

  const assignValueToRequestBody = (feild, value) => {
    console.log("feild : ", feild);
    console.log("value : ", value);
    var temp_requestbody = {};
    temp_requestbody = {
      channel: feild === "channel" ? value : requestbody.channel,
      partner_type: feild === "partner_type" ? value : requestbody.partner_type,
      partner_name: feild === "partner_name" ? value : requestbody.partner_name,
      filename_converter_detail: feild === "filename_converter_detail" ? value : requestbody.filename_converter_detail,
      filename_converter_id: requestbody.filename_converter_id,
      to_file_structure: feild === "to_file_structure" ? value : requestbody.to_file_structure
    }
    setRequestbody(temp_requestbody);
  };

  const accordianEvent = (index) => {
    var indexList = [...activeAccordianIndexList]
    if (indexList.includes(index)) {
      var ind = indexList.indexOf(index)
      indexList.splice(ind, 1)
    } else {
      indexList.push(index)
    }
    setActiveAccordianIndexList(indexList)

  }



  const [sortColumConfig, setSortColumConfig] = useState({ channel_name: "asc", to_file_structure: "asc" })

  const onSort = (sortBy) => {
    if (sortBy === "channel_name") {
      setFilenameConverterListForListing({ loading: true, info: SortObjectArray("channel_name", false, sortColumConfig.channel_name === "asc" ? true : false, filenameConverterListForListing.info) })
      setSortColumConfig({ channel_name: sortColumConfig.channel_name === "asc" ? "desc" : "asc", to_file_structure: sortColumConfig.to_file_structure })
    } else if (sortBy === "to_file_structure") {
      setFilenameConverterListForListing({ loading: true, info: SortObjectArray("to_file_structure", false, sortColumConfig.to_file_structure === "asc" ? true : false, filenameConverterListForListing.info) })
      setSortColumConfig({ channel_name: sortColumConfig.channel_name, to_file_structure: sortColumConfig.to_file_structure === "asc" ? "desc" : "asc" })
    }

  }

  const fileStructureJSXWidget = () => {
    return <div>
      <hr />
      <div class="btn-wrap text-right">
        <a class="link" href="javascript:void(0)" onClick={() => addFileStructureWidgets()}>
          <i class="fa fa-plus mr-2" aria-hidden="true"></i>Add Item </a>
        <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Add filename structure" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
      </div>


      {requestbody.filename_converter_detail.map((element, index) => (
        <div>
          <div class="accordian__item">
            <div className={`accordian__head ${activeAccordianIndexList.includes(index) ? 'active' : ''}`} >
              <strong onClick={() => accordianEvent(index)}>File Structure : {index + 1}</strong>
              <div class="dropdown">
                <a class="" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                  <span class="sr-only">ellipsis</span>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">

                  <a class="dropdown-item link" href="#" onClick={() => removeFileStructureWidgets(index)}><i class="fa fa-minus mr-2" aria-hidden="true"></i>Remove</a>
                </div>
              </div>
            </div>
            <div class="accordian__content" style={{ display: activeAccordianIndexList.includes(index) ? "block" : "none" }}>
              <div class="tree-strecture">
                {element.widget_operation != "D" ?
                  (
                    <div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6" key={index}>
                          <div className="form-group">
                            <label for="f1" className="input-label"> Content Type<span style={{ color: 'red' }}>*</span></label>
                            <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Choose filename structure type" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                            <div className="input-wrap select-wrap">
                              <select id="f12" name="content_type" value={element.content_type} onChange={(e) => handleChangeFileStructureWidget(index, e)}>
                                <option value=''>Choose Any</option>
                                <option value='TEXT'>Text</option>
                                <option value='VARIABLE'>Variable</option>
                              </select>
                              <div className="input-wrap">  {fleStructureErrorWarning[index] && fleStructureErrorWarning[index].content_type ? (<div className="error-message">{fleStructureErrorWarning[index].content_type}</div>) : ('')}</div>
                            </div>
                          </div>
                        </div>
                        {
                          element.content_type == "TEXT" ?
                            (
                              <div className="col-lg-6 col-md-6" key={index}>
                                <div className="form-group">
                                  <label for="f14" className="input-label"> Content Value<span style={{ color: 'red' }}>*</span></label>
                                  <div className="input-wrap txt-wrap">
                                    <input className='txt' type="text" name="content_value" placeholder="Enter Text Value" id="f14" value={element.content_value} onChange={e => handleChangeFileStructureWidget(index, e)} />
                                  </div>
                                  <div className="input-wrap">  {fleStructureErrorWarning[index] && fleStructureErrorWarning[index].content_value ? (<div className="error-message">{fleStructureErrorWarning[index].content_value}</div>) : ('')}</div>
                                </div>
                              </div>
                            ) :
                            ''
                        }
                      </div>
                      {
                        element.content_type == "VARIABLE" && collapseFileStructureJSX ?
                          (
                            <div className="row">
                              <div className="col-lg-6 col-md-6" key={index}>
                                <div className="form-group">
                                  <label for="f16" className="input-label"> Start Index<span style={{ color: 'red' }}>*</span></label>
                                  <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Enter Starting Index. (Must below end index)" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                  <div className="input-wrap txt-wrap">
                                    <input className='txt' type="number" name="start" placeholder="Enter start index" id="f16" value={element.start} onChange={e => handleChangeFileStructureWidget(index, e)} />
                                  </div>
                                  <div className="input-wrap">  {fleStructureErrorWarning[index] && fleStructureErrorWarning[index].start ? (<div className="error-message">{fleStructureErrorWarning[index].start}</div>) : ('')}</div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6" key={index}>
                                <div className="form-group">
                                  <label for="f17" className="input-label"> End Index<span style={{ color: 'red' }}>*</span></label>
                                  <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Enter End index. (Must above start index)" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                  <div className="input-wrap txt-wrap">
                                    <input className='txt' type="number" name="end" placeholder="Enter end index" id="f17" value={element.end} onChange={e => handleChangeFileStructureWidget(index, e)} />
                                  </div>
                                  <div className="input-wrap">  {fleStructureErrorWarning[index] && fleStructureErrorWarning[index].end ? (<div className="error-message">{fleStructureErrorWarning[index].end}</div>) : ('')}</div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6" key={index}>
                                <div className="form-group">
                                  <label for="f18" className="input-label"> Group Name<span style={{ color: 'red' }}>*</span></label>
                                  <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Allow only AlphaNumeric Characters" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                  <div className="input-wrap txt-wrap">
                                    <input className='txt' type="text" name="group_name" placeholder="Enter group name" id="f18" value={element.group_name} onChange={e => handleChangeFileStructureWidget(index, e)} />
                                  </div>
                                  <div className="input-wrap">  {fleStructureErrorWarning[index] && fleStructureErrorWarning[index].group_name ? (<div className="error-message">{fleStructureErrorWarning[index].group_name}</div>) : ('')}</div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6" key={index}>
                                <div className="form-group">
                                  <label for="f19" className="input-label"> Group Type<span style={{ color: 'red' }}>*</span></label>
                                  <div className="input-wrap txt-wrap">
                                    <select placeholder="Enter group type" name="group_type" id="f19" value={element.group_type} onChange={e => handleChangeFileStructureWidget(index, e)} >
                                      <option value="">Choose Any</option>
                                      {(fc_variable_grouptype.length === 0) ? '' :
                                        fc_variable_grouptype.map(s => (
                                          <option value={s}>{s}</option>
                                        )
                                        )}
                                    </select>
                                  </div>
                                  <div className="input-wrap">  {fleStructureErrorWarning[index] && fleStructureErrorWarning[index].group_type ? (<div className="error-message">{fleStructureErrorWarning[index].group_type}</div>) : ('')}</div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6" key={index}>
                                <div className="form-group">
                                  <label for="f20" className="input-label"> Group Value<span style={{ color: 'red' }}>*</span></label>
                                  <div className="input-wrap txt-wrap">
                                    <input className='txt' type="text" name="group_value" placeholder="Enter group value" id="f20" value={element.group_value} onChange={e => handleChangeFileStructureWidget(index, e)} />
                                  </div>
                                  <div className="input-wrap">  {fleStructureErrorWarning[index] && fleStructureErrorWarning[index].group_value ? (<div className="error-message">{fleStructureErrorWarning[index].group_value}</div>) : ('')}</div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6" key={index}>
                                <div className="form-group">
                                  <label for="f21" className="input-label"> Data Type<span style={{ color: 'red' }}>*</span></label>
                                  <div className="input-wrap txt-wrap">
                                    {/* <input className='txt' type="text" name="data_type" placeholder="Enter data type" id="f21" value={element.data_type} onChange={e => handleChangeFileStructureWidget(index, e)} /> */}
                                    <select name="data_type" placeholder="Enter data type" id="f21" value={element.data_type} onChange={e => handleChangeFileStructureWidget(index, e)} >
                                      <option value="">Choose Any</option>
                                      {(fc_variable_datatype.length === 0) ? '' :
                                        fc_variable_datatype.map(s => (
                                          <option value={s}>{s}</option>
                                        )
                                        )}
                                    </select>
                                  </div>
                                  <div className="input-wrap">  {fleStructureErrorWarning[index] && fleStructureErrorWarning[index].data_type ? (<div className="error-message">{fleStructureErrorWarning[index].data_type}</div>) : ('')}</div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6" key={index}>
                                <div className="form-group">
                                  <label for="f22" className="input-label"> Operation<span style={{ color: 'red' }}>*</span></label>
                                  <div className="input-wrap txt-wrap">
                                    {/* <input className='txt' type="text" name="operation" placeholder="Enter operation" id="f22" value={element.operation} onChange={e => handleChangeFileStructureWidget(index, e)} /> */}
                                    <select name="operation" placeholder="Enter operation" id="f22" value={element.operation} onChange={e => handleChangeFileStructureWidget(index, e)} >
                                      <option value="">Choose Any</option>
                                      {(fc_variable_operation.length === 0) ? '' :
                                        fc_variable_operation.map(s => (
                                          <option value={s}>{s}</option>
                                        )
                                        )}
                                    </select>
                                  </div>
                                  <div className="input-wrap">  {fleStructureErrorWarning[index] && fleStructureErrorWarning[index].operation ? (<div className="error-message">{fleStructureErrorWarning[index].operation}</div>) : ('')}</div>
                                </div>
                              </div>

                            </div>

                          )
                          :
                          ''
                      }
                    </div>
                  )
                  :
                  ""}
              </div>
            </div>
          </div>
        </div>
      ))}

      <hr />
    </div>
  }

  return (
    <React.Fragment>
      <main>
        <div class="container">
          <ul class="breadcrumb">
            <li class="breadcrumb__item">
              <a class="breadcrumb__link" href="#">
                Configurations
              </a>
            </li>
            <li class="breadcrumb__item">
              <span class="breadcrumb__link">Filename Transformation</span>
            </li>
          </ul>
          <div class="title">
            <h1>Filename Transformation</h1>
          </div>
          <div >
            {popupVisibility.failed ?
              <AlertFailure message={popupVisibility.message} /> : ''}
            {popupVisibility.success ?
              <AlertSuccess message={popupVisibility.message} /> : ''}
          </div>
        </div>
        <div class="grey-block">
          <div class="container">
            <div class="form mb-5">
              <h2 class="mb-4">Configuration</h2>
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <CustomDropdownWidget dropdownRefresher={dropdownRefresher} editValue={channel_edit} dropdownList={channelListForDropDown} constrains={constrains} setResponseData={setResponseData}></CustomDropdownWidget>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label for="f1" className="input-label"> Partner Type<span style={{ color: 'red' }}>*</span></label>
                    <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Choose partner type" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                    <div className="input-wrap txt-wrap">
                      <select placeholder="Choose Partner Type" value={requestbody.partner_type} onChange={(e) => { assignValueToRequestBody("partner_type", e.target.value) }}>
                        <option value=''>Choose Any</option>
                        <option value='CNS'>Consumer</option>
                        <option value='PRD'>Producer</option>
                      </select>
                    </div>
                    <div className="input-wrap">  {inputErrorWarning.partner_type != "" ? (<div className="error-message">{inputErrorWarning.partner_type}</div>) : ('')}</div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label for="f1" className="input-label"> Partner Name<span style={{ color: 'red' }}>*</span></label>
                    <div className="input-wrap txt-wrap">
                      <input disabled className='txt' value={requestbody.partner_name} type="text" />
                    </div>
                  </div>
                </div>
              </div>
              {true ? fileStructureJSXWidget() : ''}
              <div style={{ padding: "20px" }}></div>
              <div className="btn-wrap btn-wrap--grp mt-5 justify-content-end">
                <button className="button button--outline" type='reset' onClick={(() => { resetForm() })}>Reset</button>
                <button className="button" type='submit' onClick={(() => { submitFormInputData() })}>Save</button>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="table-block">
            <div class="table-block__head">
              <h2>List of Details 
              <span style={{ "fontWeight": "bold", "fontSize": "13px", "color": "#666" }} >
                                    &nbsp; [ Total: {filenameConverterList.info ? filenameConverterList.info.length : 0} ]
                                </span>
              </h2>
              <div class="txt-wrap">
                <input type="text" class="txt" value={onSearchKeyword} placeholder="Search..."
                  onChange={(e) => setOnSearchKeyword(e.target.value)} />
                <i class="fa fa-search"></i>
              </div>
            </div>
            <div class="table-responsive grid">
              <table class="table">
                <thead>
                  <tr>
                    <th style={{ "whiteSpace": "nowrap" }}>Sl No.</th>
                    <th onClick={(() => onSort("channel_name"))} style={{ "whiteSpace": "nowrap" }} scope="col">Channel Name <i className="sort"><img src={sort_icon} /></i></th>
                    <th style={{ "whiteSpace": "nowrap" }} scope="col">Partner Name </th>
                    <th style={{ "whiteSpace": "nowrap" }} scope="col">Partner Type </th>
                    {/* <th scope='col'>File Format</th> */}
                    <th onClick={(() => onSort("to_file_structure"))} style={{ "whiteSpace": "nowrap" }} scope="col">File Structure <i className="sort"><img src={sort_icon} /></i></th>
                    <th scope="col">Action </th>
                  </tr>
                </thead>
                <tbody>
                  {filenameConverterList.loading && filenameConverterList.info.length > 0 && filenameConverterListForListing.loading && filenameConverterListForListing.info.length > 0 ? filenameConverterListForListing.info.map((infodata, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{infodata.channel_name}</td>
                      <td>{infodata.partner_type === "PRD" ? infodata.producer_partnercode : infodata.consumer_partnercode}</td>
                      <td>{infodata.partner_type}</td>
                      {/* <td>{formatFinder(infodata.to_file_structure)}</td> */}
                      <td>{infodata.to_file_structure}</td>
                      <td>
                        <div className="table__action">
                          <a href="javascript:void(0);" className="utils utils--outline" onClick={(e) => getFilenameConverter(infodata.filename_converter_id)}> <img src={iconEdit} alt="edit" /></a>
                          <a href="javascript:void(0);" className="utils utils--outline ml-2" onClick={(e) => deleteFilenameConverter(infodata.filename_converter_id)}> <img src={iconDelete} alt="delete" /></a>
                        </div>
                      </td>
                    </tr>
                  )) : <p>No records avaiable</p>}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};




const mapDispatchToProps = (dispatch) => ({
  dispatchGetAllActiveChannelsList: (onSuccess, onError) =>
    dispatch(getAllActiveChannelsList(onSuccess, onError)),
  dispatchCreateFilenameConfiguration: (data, onSuccess, onError) =>
    dispatch(createFilenameConfiguration(data, onSuccess, onError)),
  dispatchgetAllFilenameConverterList: (onSuccess, onError) =>
    dispatch(getAllFilenameConverterList(onSuccess, onError)),
  dispatchgetFilenameConverterInfo: (id, onSuccess, onError) =>
    dispatch(getFilenameConverterInfo(id, onSuccess, onError)),
  dispatchDeleteByFilenameConverterID: (id, onSuccess, onError) =>
    dispatch(deleteByFilenameConverterID(id, onSuccess, onError)),
  dispatchOneTimeParamsAction: (data, onSuccess, onError) =>
    dispatch(fetchOneTimeParams(data, onSuccess, onError)),

});

export default connect(null, mapDispatchToProps)(FilenameConfigurationComponent);