import React, { useState, useEffect, useRef } from 'react';
import Select from "react-select";

/* How to use---- 
*
*
 : create xthe following hooks method variables on parent class and pass thru props.

1. const [constrains, setConstrains] = useState({ "fieldPlaceholder": "", "isMandatory": false, "fieldLabel": "" })
2. const [responseData, setResponseData] = useState({})
3. const [dropdownList, setDropdownList] = useState()

// dropdownList variable format : [ { "label":"aa", "value":"bb"}, { "label":"cc", "value":"dd"},..]
// responseData response format : { "label":"cc", "value":"dd"}

*
*
*/



const CustomDropdownWidget = (props) => {
    const [isOpen, setIsOpen] = useState(false)
    const [valueData, setValueData] = useState()
    const ref = useRef();
    // const [constrains, setConstrains] = useState({ "fieldPlaceholder": "", "isMandatory": false, "fieldLabel": "" })
    // const [responseData, setResponseData] = useState({})

    useEffect(() => {
        const checkIfClickedOutside = e => {
          if (isOpen && ref.current && !ref.current.contains(e.target)) {
            setIsOpen(false)
          }
        }    
        document.addEventListener("mousedown", checkIfClickedOutside)    
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
      }, [isOpen])

    const selectStyles = { control: (provided) => ({ ...provided, minWidth: 250, margin: 8, color: "red" }) };

    const Menu = (props) => {
        return (
            <div
                css={{
                    backgroundColor: "red",
                    borderRadius: 4,
                    marginTop: 8,
                    position: "absolute",
                    zIndex: 2
                }}
                {...props}
            />
        );
    };

    useEffect(()=>{
        setValueData(null)
    },[props.dropdownRefresher])


    const Blanket = (props) => (
        <div
            css={{
                bottom: 0,
                left: 0,
                top: 0,
                right: 0,
                position: "fixed",
                zIndex: 1
            }}
            {...props}
        />
    );

    const onSelectChange = (value) => {
        setValueData(value.label)
        props.setResponseData(value)
        setIsOpen(false)
    }
    const Svg = (p) => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            focusable="false"
            role="presentation"
            {...p}
        />
    );
    const ChevronDown = () => (
        <Svg style={{ marginRight: -6 }}>
            <path
                d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </Svg>
    );

    const Dropdown = ({ children, isOpen, target, onClose }) => (
        <div css={{ position: "relative" }}>
            {target}
            {isOpen ? <Menu>{children}</Menu> : null}
            {isOpen ? <Blanket onClick={setIsOpen(onClose)} /> : null}
        </div>
    );
    return (
        <div ref={ref}>
            <Dropdown
                isOpen={isOpen}
                onClose={isOpen}
                target={
                    <div className="form-group">
                        <label for={props.constrains.id?props.constrains.id:"customDropDown_default_id"} className="input-label">  {props.constrains.fieldLabel} {props.constrains.isMandatory ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                         <div className="input-wrap txt-wrap">
                         {/* <ChevronDown /> */}
                            <input className='txt' value={valueData ? valueData : props.editValue ? props.editValue.label : ''} onClick={(() => { setIsOpen(isOpen ? false : true) })} type="text" autoComplete="off" placeholder={props.constrains.fieldPlaceholder } id={props.constrains.id?props.constrains.id:"customDropDown_default_id"}  />
                            {props.constrains.isMandatory && props.constrains.inputErrorMessage !== "" ? (<div className="error-message">{props.constrains.inputErrorMessage}</div>) : ('')}
                        </div>
                    </div>
                }
            >
                <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    controlShouldRenderValue={false}
                    isClearable={false}
                    menuIsOpen={isOpen}
                    onChange={onSelectChange}
                    options={props.dropdownList}
                    placeholder="Search..."
                    styles={selectStyles}
                />
            </Dropdown>
        </div>

    );

}




export default CustomDropdownWidget