import React, { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import AlertFailure from '../utils/alert-failure.component';
import AlertSuccess from '../utils/alert-success.component';
import CustomDropdownWidget from './dropdown/CustomDropdownWidget';
import CustomRadioButton from './radioButton/CustomRadioButton';
import DatePicker from 'react-datepicker';
import {createScheduler, listPartners, displaySchedulerData, deleteSchedulerData, editSchedulerData, isJson, sessionErrorCheck, schedulerCount} from '../redux/actions/auth.action.creators'
import 'react-datepicker/dist/react-datepicker.css';
import delete_icon from '../assets/images/icon-delete.png';
import edit_icon from '../assets/images/icon-edit.png';
import 'primeflex/primeflex.css';
import { confirmDialog } from 'primereact/confirmdialog';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import * as constants from '../redux/constants';
import close_icon from '../assets/images/icon-close.png';
import { Tooltip } from 'primereact/tooltip';
import { AppendingDotsToSuffix } from "../utils/InputDataCustomization";
import iconExport from '../assets/images/icon-export.png';
import iconExcel from '../assets/images/icon-excel.png';
import { DownloadListAsExcel } from '../utils/list-export-excel';

var CryptoJS = require("crypto-js");
var secretKey = require(process.env.REACT_APP_CONFIG_URL);

const AddScheduler = ({dispatchListPartnersAction, dispatchCreateScheduler, dispatchSchedulerDataList, dispatchDeleteSchedulerData, dispatchEditSchedulerData, dispatchSchedulerCount}) => {
    const [schedulerArr, setSchedulerArr] = useState([{"value": "","label": "Select List"}]);
    const [constrains, setConstrains] = useState({ "fieldPlaceholder": "Select List", "isMandatory": true, "fieldLabel": "Partner Code", "inputErrorMessage": "" });
    const [responseData, setResponseData] = useState("false");
    const [editTempVal, setEditTempVal] = useState({});
    const [radioActionArr, setRadioActionArr] = useState([{"value": "Push","id": "actionPush"},{"value": "Pull","id": "actionPull"}]);
    const [radioFlowArr, setRadioFlowArr] = useState([{"value": "Internal","id": "flowInternal"},{"value": "External","id": "flowExternal"}]);
    const [actionValue, setActionValue] = useState("");
    const [flowValue, setFlowValue] = useState("");
    const [incrementValue, setIncrementValue] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [incrementPadding, setIncrementPadding] = useState(11);
    const [deletedValues, setDeletedValues] = useState([]);
    const [backgroundColor, setBackgroundColor] = useState("#fff");
    const [color, setColor] = useState("#cd0000");
    const [dayList, setDayList] = useState([{'id': 0, 'day': 'S', 'fullDay': 'Sunday'},{'id': 1, 'day': 'M', 'fullDay': 'Monday'},
    {'id': 2, 'day': 'T', 'fullDay': 'Tuesday'},{'id': 3, 'day': 'W', 'fullDay': 'Wednesday'},
    {'id': 4, 'day': 'T', 'fullDay': 'Thursday'},{'id': 5, 'day': 'F', 'fullDay': 'Friday'},{'id': 6, 'day': 'S', 'fullDay': 'Saturday'}]);
    const [timeList, setTimeList] = useState(['01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30',
    '05:00','05:30','06:00','06:30','07:00','07:30','08:00','08:30','09:00','09:30','10:00','10:30','11:00',
    '11:30','12:00','12:30']);
    const [timeListCheck, setTimeListCheck] = useState([{'amTime': '00:00', 'pmTime': '12:00', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '12:00 PM', 'amTimeCheck': '12:00 AM'},
    {'amTime': '00:30', 'pmTime': '12:30', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '12:30 PM', 'amTimeCheck': '12:30 AM'},{'amTime': '01:00', 'pmTime': '13:00', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '01:00 PM', 'amTimeCheck': '01:00 AM'},
    {'amTime': '01:30', 'pmTime': '13:30', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '01:30 PM', 'amTimeCheck': '01:30 AM'},{'amTime': '02:00', 'pmTime': '14:00', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '02:00 PM', 'amTimeCheck': '02:00 AM'},
    {'amTime': '02:30', 'pmTime': '14:30', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '02:30 PM', 'amTimeCheck': '02:30 AM'},{'amTime': '03:00', 'pmTime': '15:00', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '03:00 PM', 'amTimeCheck': '03:00 AM'},
    {'amTime': '03:30', 'pmTime': '15:30', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '03:30 PM', 'amTimeCheck': '03:30 AM'},{'amTime': '04:00', 'pmTime': '16:00', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '04:00 PM', 'amTimeCheck': '04:00 AM'},
    {'amTime': '04:30', 'pmTime': '16:30', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '04:30 PM', 'amTimeCheck': '04:30 AM'},{'amTime': '05:00', 'pmTime': '17:00', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '05:00 PM', 'amTimeCheck': '05:00 AM'},
    {'amTime': '05:30', 'pmTime': '17:30', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '05:30 PM', 'amTimeCheck': '05:30 AM'},{'amTime': '06:00', 'pmTime': '18:00', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '06:00 PM', 'amTimeCheck': '06:00 AM'},
    {'amTime': '06:30', 'pmTime': '18:30', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '06:30 PM', 'amTimeCheck': '06:30 AM'},{'amTime': '07:00', 'pmTime': '19:00', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '07:00 PM', 'amTimeCheck': '07:00 AM'},
    {'amTime': '07:30', 'pmTime': '19:30', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '07:30 PM', 'amTimeCheck': '07:30 AM'},{'amTime': '08:00', 'pmTime': '20:00', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '08:00 PM', 'amTimeCheck': '08:00 AM'},
    {'amTime': '08:30', 'pmTime': '20:30', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '08:30 PM', 'amTimeCheck': '08:30 AM'},{'amTime': '09:00', 'pmTime': '21:00', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '09:00 PM', 'amTimeCheck': '09:00 AM'},
    {'amTime': '09:30', 'pmTime': '21:30', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '09:30 PM', 'amTimeCheck': '09:30 AM'},{'amTime': '10:00', 'pmTime': '22:00', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '10:00 PM', 'amTimeCheck': '10:00 AM'},
    {'amTime': '10:30', 'pmTime': '22:30', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '10:30 PM', 'amTimeCheck': '10:30 AM'},{'amTime': '11:00', 'pmTime': '23:00', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '11:00 PM', 'amTimeCheck': '11:00 AM'},
    {'amTime': '11:30', 'pmTime': '23:30', 'am': 'AM', 'pm': 'PM', 'pmTimeCheck': '11:30 PM', 'amTimeCheck': '11:30 AM'}]);
    const [incrementTimeValue, setIncrementTimeValue] = useState(1);
    // const userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
    // const userInfos = localStorage.getItem('USER_INFO');
    const userInfos = sessionErrorCheck() !== null ? sessionErrorCheck() : window.location = '/auth';
    // var decryptByte = CryptoJS.AES.decrypt(userInfos, secretKey.switch.secretKey);
    // const userInfo = JSON.parse(decryptByte.toString(CryptoJS.enc.Utf8));
    // const userInfo = JSON.parse(window.atob(localStorage.getItem('USER_INFO')));
    // const userInfo = localStorage.getItem('USER_INFO') === null ? null : (!isJson(localStorage.getItem('USER_INFO')) ? JSON.parse(decryptByte.toString(CryptoJS.enc.Utf8)) : null) ;
    const userInfo = userInfos;
    const [partnerList, setPartnerList] = useState([]);
    const [schedulerName, setSchedulerName] = useState("");
    const [times, setTimes] = useState("");
    const [days, setDays] = useState("");
    const [status, setStatus] = useState("");
    const [schedulerList, setSchedulerList] = useState([]);
    const [createAndUpdateStatus, setCreateAndUpdateStatus] = useState("");
    const [schedulerId, setSchedulerId] = useState("");
    const [failMsg, setFailMsg] = useState("");
    const [succMsg, setSuccMsg] = useState("");
    const [errorMessageForAttribute, setErrorMessageForAttribute] = useState(false);
    const [errorDetails, setErrorDetails] = useState({ "errorSchedulerName": "", "errorPartnerCode": "", "errorAttributeName": "", "errorAttributeValue": "", "errorStatus": "", "errorDays": "", "errorTime": "", "errorTimeAMPM": "", "errorStartDate": "", "errorEndDate": "" });
    const [statusCount, setStatusCount] = useState({})
    const [dropdownRefresher, setdropdownRefresher] = useState(true)
    const [isDayListingPopupEnable, setIsDayListingPopupEnable] = useState(false)
    const [dayListForModel, setDayListForModel] = useState([])
    const [isTimeListingPopupEnable, setIsTimeListingPopupEnable] = useState(false)
    const [timeListForModel, setTimeListForModel] = useState([])
    const [searchKey, setSearchKey] = useState('');
    const [filteredSchedulerList, setFilteredSchedulerList] = useState([]);
    const [isExportVisible, setIsExportVisible] = useState(false);

    useEffect(() => {
        dispatchListPartnersAction(({data, message}) => {
            if(data){
                if(userInfo.role == 'Partner'){
                    setPartnerList(data.filter(p => (p.partnerid == userInfo.partnerId)));
                }
                else {
                    var pushData = [];
                    for (let index = 0; index < data.length; index++) {
                        pushData.push({ "value": data[index].partnerid, "label": `${data[index].partnercode} (${data[index].partnername})` });
                        
                    }
                    setPartnerList([{"value": "", "label": "Select partner"}].concat(pushData));
                }
            }
        },
        (message) => {
            console.log("partner list error--  ",message);
        });
        schedulerDataList();
    },[])

    useEffect(() => {
        let filtered = _.cloneDeep(schedulerList);
        if (searchKey.trim() !== '') {
            filtered = filtered.filter(p => {
                return (
                    p.scheduler_name.toLowerCase().includes(searchKey.toLowerCase()) ||
                    p.partner_code.toLowerCase().includes(searchKey.toLowerCase()) ||
                    p.attribute_value.toLowerCase().includes(searchKey.toLowerCase()) ||
                    p.days.toLowerCase().includes(searchKey.toLowerCase()) 
                );
            });
        }
        setFilteredSchedulerList(filtered);
    }, [searchKey, schedulerList]);

    const schedulerDataList = () => {
        dispatchSchedulerDataList(({data, message}) => {
            if(data){
                setSchedulerList(data);
            }
        },
        (message) => {
            console.log("scheduler list error--  ",message);
        });
    }

    useEffect(() => {
        dispatchSchedulerCount(({ data, message }) => {
            if (data) {
                setStatusCount(data)
            }
        }, (message) => { });
    }, [])

    const clickSubmitDetails = () => {
        var request = new FormData();
        var attValue = (document.getElementById("attValue"+0) || {}).value;
        var attName = (document.getElementById("attName"+0) || {}).value;
        if(schedulerName === "") {
            setErrorDetails({"errorSchedulerName": "Enter scheduler name", "errorPartnerCode": "", "errorAttributeName": "", 
            "errorAttributeValue": "", "errorStatus": "", "errorDays": "", "errorTime": "", "errorTimeAMPM": "", "errorStartDate": "", "errorEndDate": ""});
        }else if(responseData.value === undefined || responseData.value === "") {
            setErrorDetails({"errorSchedulerName": "", "errorPartnerCode": "Enter partner code", "errorAttributeName": "", 
            "errorAttributeValue": "", "errorStatus": "", "errorDays": "", "errorTime": "", "errorTimeAMPM": "", "errorStartDate": "", "errorEndDate": ""});
        }
        /*else if(attName === "") {
            setErrorDetails({"errorSchedulerName": "", "errorPartnerCode": "", "errorAttributeName": "Enter attribute name", 
            "errorAttributeValue": "", "errorStatus": "", "errorDays": "", "errorTime": "", "errorTimeAMPM": "", "errorStartDate": "", "errorEndDate": ""});
        }*/
        else if(attValue === "") {
            setErrorDetails({"errorSchedulerName": "", "errorPartnerCode": "", "errorAttributeName": "", 
            "errorAttributeValue": "Enter Attribute Value", "errorStatus": "", "errorDays": "", "errorTime": "", "errorTimeAMPM": "", "errorStartDate": "", "errorEndDate": ""});
        }else if(days === "") {
            setErrorDetails({"errorSchedulerName": "", "errorPartnerCode": "", "errorAttributeName": "", 
            "errorAttributeValue": "", "errorStatus": "", "errorDays": "Enter Day's", "errorTime": "", "errorTimeAMPM": "", "errorStartDate": "", "errorEndDate": ""});
        }else if(times === "") {
            setErrorDetails({"errorSchedulerName": "", "errorPartnerCode": "", "errorAttributeName": "", 
            "errorAttributeValue": "", "errorStatus": "", "errorDays": "", "errorTime": "Enter Time", "errorTimeAMPM": "Enter Time", "errorStartDate": "", "errorEndDate": ""});
        }else if(status === "") {
            setErrorDetails({"errorSchedulerName": "", "errorPartnerCode": "", "errorAttributeName": "", 
            "errorAttributeValue": "", "errorStatus": "Enter Status", "errorDays": "", "errorTime": "", "errorTimeAMPM": "", "errorStartDate": "", "errorEndDate": ""});
        }else {
            setErrorDetails({"errorSchedulerName": "", "errorPartnerCode": "", "errorAttributeName": "", 
            "errorAttributeValue": "", "errorStatus": "", "errorDays": "", "errorTime": "", "errorTimeAMPM": "", "errorStartDate": "", "errorEndDate": ""});
            var pushValue = [];
            var pushName = [];
            var pushNameAndValue = [];
            for (let index = 0; index < incrementValue; index++) {
                var attValue = (document.getElementById("attValue"+index) || {}).value;
                var attName = "OPERATION"
                //var attName = (document.getElementById("attName"+index) || {}).value;
                if(attValue !== undefined && attName !== undefined) {
                    pushName.push(attName.toUpperCase());
                    pushValue.push(attValue.toUpperCase());
                    pushNameAndValue.push({'attributeName': attName, 'attributeValue': attValue});
                }
            }
            if(startDate !== null && endDate !== null) {
                var monthStart = (startDate.getMonth() + 1) > 9 ? (startDate.getMonth() + 1) : "0"+(startDate.getMonth() + 1);
                var monthEnd = (endDate.getMonth() + 1) > 9 ? (endDate.getMonth() + 1) : "0"+(endDate.getMonth() + 1);
                var dateStart = startDate.getDate() > 9 ? startDate.getDate() : "0"+startDate.getDate();
                var dateEnd = endDate.getDate() > 9 ? endDate.getDate() : "0"+endDate.getDate();
                var startDates = startDate.getFullYear()+'-'+monthStart+'-'+dateStart;
                var endDates = endDate.getFullYear()+'-'+monthEnd+'-'+dateEnd;
            }else {
                var startDates = "";
                var endDates = "";
            }

            request.append("schedulerName", schedulerName)
            request.append("partnerId", responseData.value)
            request.append("startDate", startDates)
            request.append("endDate", endDates)
            request.append("time", times)
            request.append("days", days)
            request.append("status", status)
            request.append("attributeName", pushName.toString())
            request.append("attributeValue", pushValue.toString())
            if(createAndUpdateStatus === "UPDATE") {
                request.append("schedulerId", schedulerId)
                dispatchEditSchedulerData(request, ({ data, message }) => {
                    schedulerDataList();
                    setSuccMsg("Scheduler Updated successfully");
                    resetSubmitDetails();
                    window.scrollTo(0, 0);
                    setTimeout(() => {
                        setSuccMsg('')
                    }, 3000);
                },
                    (message) => {
                        console.log("request-message--  ",message)
                        window.scrollTo(0, 0);
                        setFailMsg(message);
                        setTimeout(() => {
                            setFailMsg('')
                        }, 3000);
                    });
            }else {
                dispatchCreateScheduler(request, ({ data, message }) => {
                    schedulerDataList();
                    setSuccMsg("Scheduler created successfully");
                    resetSubmitDetails();
                    window.scrollTo(0, 0);
                    setTimeout(() => {
                        setSuccMsg('')
                    }, 3000);
                },
                    (message) => {
                        console.log("request-message-create-  ",message)
                        window.scrollTo(0, 0);
                        setFailMsg(message);
                        setTimeout(() => {
                            setFailMsg('')
                        }, 3000);
                    });
            }
        }
    }

    const resetSubmitDetails = () => {
        setdropdownRefresher(dropdownRefresher ? false : true)
        setSchedulerName("");
        setStatus("");
        setSchedulerId("");
        setEditTempVal({});
        setStartDate(null);
        setEndDate(null);
        setResponseData({});
        setCreateAndUpdateStatus("");
        for (let index = 0; index < incrementValue; index++) { 
            if(document.getElementById("deleteAttValue"+(index+1)) !== null) {
                document.getElementById("deleteAttValue"+(index+1)).remove();
                document.getElementById("deleteAttName"+(index+1)).remove();
                document.getElementById("deleteButton"+(index+1)).remove();
            }
            if(document.getElementById("attName"+index) !== null && document.getElementById("attValue"+index) !== null) {
                document.getElementById("attName"+index).value = "";
                document.getElementById("attValue"+index).value = "";
            }
            if(document.getElementById("attValue"+index) !== null) {
                document.getElementById("attValue"+index).value = "";
            }
        }
        for (let index = 0; index < dayList.length; index++) {
            document.getElementById("dayId"+dayList[index].id).style.backgroundColor = '#fff'
            document.getElementById("dayId"+dayList[index].id).style.color = '#cd0000'
            document.getElementById("dayId"+dayList[index].id).value = "";
        }
        for (let index = 0; index < incrementTimeValue; index++) {
            if(document.getElementById("removeTimeDiv"+(index+1)) !== null) {
                document.getElementById("removeTimeDiv"+(index+1)).remove();
            }
            if(document.getElementById("time"+index) !== null && document.getElementById("timeAMPM"+index) !== null) {
                document.getElementById("time"+index).value = "";
                document.getElementById("timeAMPM"+index).value = "";
            }
        }
    }

    const deleteSchedulerData = (id, name) => {
        var payload = {"id":id,"status":"D"};
        confirmDialog({
            message: 'Do you want to delete ' + name + '?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept() {
                dispatchDeleteSchedulerData(payload, ({ data, message }) => {
                    window.scrollTo(0, 0);
                    setSuccMsg("Schedule deleted successfully");
                    setTimeout(() => {
                        setSuccMsg('')
                    }, 3000);
                    schedulerDataList();
                },  
                (message) => {
                    console.log("request-message--  ",message)
                    window.scrollTo(0, 0);
                    setFailMsg(message);
                    setTimeout(() => {
                        setFailMsg('')
                    }, 3000);
                });
            },
            reject() {
      
            },
            acceptLabel: "Confirm",
            rejectLabel: "Cancel"
          });
    }

    const editSchedulerData = (data) => {
        window.scrollTo(0, 290);
        setCreateAndUpdateStatus("UPDATE");
        setSchedulerName(data.scheduler_name);
        setStatus(data.status);
        setSchedulerId(data.scheduler_id);
        //Partner List
        setEditTempVal({});

        console.log("incrementValue---  ",incrementValue)
        for (let index = 0; index < incrementValue; index++) {
            if(document.getElementById("deleteAttName"+(index+1)) !== null) {
                document.getElementById("deleteAttName"+(index+1)).remove();
            }
            if(document.getElementById("deleteAttValue"+(index+1)) !== null && document.getElementById("deleteButton"+(index+1)) !== null) {
                document.getElementById("deleteAttValue"+(index+1)).remove();
                document.getElementById("deleteButton"+(index+1)).remove();
            }
            if(document.getElementById("attName"+index) !== null && document.getElementById("attValue"+index) !== null) {
                document.getElementById("attName"+index).value = "";
                document.getElementById("attValue"+index).value = "";
            }
        }
        
        for (let index3 = 0; index3 < incrementTimeValue; index3++) {
            if(document.getElementById("removeTimeDiv"+(index3+1)) !== null) {
                document.getElementById("removeTimeDiv"+(index3+1)).remove();
            }
            if(document.getElementById("time"+index3) !== null && document.getElementById("timeAMPM"+index3) !== null) {
                document.getElementById("time"+index3).value = "";
                document.getElementById("timeAMPM"+index3).value = "";
            }
        }

        for (let index4 = 0; index4 < partnerList.length; index4++) {
            if(partnerList[index4].value === data.partner_id) {
                setEditTempVal({
                    "value": partnerList[index4].value,
                    "label": partnerList[index4].label
                })
            }
        }

        //attribute_name
        if(data.attribute_name.includes(',')) {
            const attributeNames = data.attribute_name.split(',');
            for (let index = 0; index < attributeNames.length; index++) {
                if(index === 0) {
                    var option = document.getElementById("attName"+index).options
                    for (var option1 of option) {
                        var optionCheck = option1.value;
                        if (optionCheck === attributeNames[index]) {
                            option1.selected = true;
                            break;
                        }
                    }
                }else {
                    var dom = document.createElement('div');
                    dom.classList.add('form-group');
                    dom.setAttribute("id", "deleteAttName"+index);
                    var appendChildData = '<label for="attName'+index+'" class="d-block input-label">Attribute Name</label>'+
                            '<div class="input-wrap date-wrap">'+
                                '<select id="attName'+index+'" >'+
                                    '<option value="">select Attribute Name</option>'+
                                    '<option value="Operation">Operation</option>'+
                                    // '<option value="Source">Source</option>'+
                                '</select>'+
                            '</div>'
                    dom.innerHTML = appendChildData;
                    var appendDiv = document.getElementById("appendDivAttributeName")
                    appendDiv.append(dom);
                    var option = document.getElementById("attName"+index).options
                    for (var option1 of option) {
                        var optionCheck = option1.value;
                        if (optionCheck === attributeNames[index]) {
                            option1.selected = true;
                            break;
                        }
                    }
                }
            }
        }else {
            document.getElementById("attName"+0).value = data.attribute_name;
            var option = document.getElementById("attName"+0).options
            for (var option1 of option) {
                var optionCheck = option1.value.toUpperCase();
                if (optionCheck === data.attribute_name) {
                    option1.selected = true;
                    break;
                }
            }
        }

        //attribute_value
        if(data.attribute_value.includes(',')) {
            const attributeValues = data.attribute_value.split(',');
            for (let index = 0; index < attributeValues.length; index++) {
                if(index === 0) {
                    document.getElementById("attValue"+0).value = attributeValues[index];
                }else {
                    var dom1 = document.createElement('div');
                    dom1.classList.add('form-group');
                    dom1.setAttribute("id", "deleteAttValue"+index);
                    var appendChildData1 = '<label for="attValue'+index+'" class="d-block input-label">Attribute Value</label>'+
                            '<div class="input-wrap date-wrap">'+
                                '<input type="text" id="attValue'+index+'" value="'+attributeValues[index]+'" class="txt" placeholder="Attribute Value" />'+
                            '</div>'
                    dom1.innerHTML = appendChildData1;
                    var appendDiv1 = document.getElementById("appendDivAttributeValue")
                    appendDiv1.append(dom1);
                    
                    // Remove Data
                    var dom2 = document.createElement('div');
                    dom2.classList.add('form-group');
                    dom2.setAttribute("id", "deleteButton"+index);
                    dom2.style.paddingTop = incrementPadding+'px';
                    var appendChildData2 = '<label for="deleteEvent'+index+'" class="input-label"></label>'+
                            '<div class="input-wrap select-wrap">'+
                                '<input type="button" id="deleteEvent'+index+'" class="txt" style="width: 100%;height: 33px;background-color: #cd0000;border: 1px solid #cd0000;color: #fff;border-radius: 4px;font-weight: bold;font-size: 13px;" value="Remove"/>'+
                            '</div>'
                    dom2.innerHTML = appendChildData2;
                    var appendDiv2 = document.getElementById("appendDivDelete")
                    appendDiv2.append(dom2);
                    document.getElementById("deleteEvent"+index).addEventListener("click", ()=>deleteInputDetails(index));
                }
                
            }
            setIncrementPadding(22);
            setIncrementValue(attributeValues.length+1);
        }else {
            document.getElementById("attValue"+0).value = data.attribute_value;
        }

        //Dropdown List
        //Start Date And End Date
        var reduceStartMonth = new Date(data.start_date);
        reduceStartMonth.setHours(reduceStartMonth.getHours() - 4.30);
        var reduceEndMonth = new Date(data.end_date);
        reduceEndMonth.setHours(reduceEndMonth.getHours() - 4.30);
        setStartDate(reduceStartMonth);
        setEndDate(reduceEndMonth);
        setDays(data.days);
        setTimes(data.time);
        setResponseData({'value': data.partner_id, 'label': data.partner_id});
        //Day's
        if(data.days.includes(',')) {
            const dayNames = data.days.split(',');
            for (let index = 0; index < dayList.length; index++) {
                document.getElementById("dayId"+dayList[index].id).style.backgroundColor = '#fff'
                document.getElementById("dayId"+dayList[index].id).style.color = '#cd0000'
                document.getElementById("dayId"+dayList[index].id).value = "";
                for (let index1 = 0; index1 < dayNames.length; index1++) {
                    if(dayList[index].fullDay === dayNames[index1]) {
                        document.getElementById("dayId"+dayList[index].id).style.backgroundColor = '#cd0000'
                        document.getElementById("dayId"+dayList[index].id).style.color = '#fff'
                        document.getElementById("dayId"+dayList[index].id).value = dayNames[index1]
                    }
                }
            }
        }else {
            for (let index = 0; index < dayList.length; index++) {
                document.getElementById("dayId"+dayList[index].id).style.backgroundColor = '#fff'
                document.getElementById("dayId"+dayList[index].id).style.color = '#cd0000'
                document.getElementById("dayId"+dayList[index].id).value = "";
                if(dayList[index].fullDay === data.days) {
                    document.getElementById("dayId"+dayList[index].id).style.backgroundColor = '#cd0000'
                    document.getElementById("dayId"+dayList[index].id).style.color = '#fff'
                    document.getElementById("dayId"+dayList[index].id).value = data.days
                }
            }
        }
        //times
        if(data.time.includes(',')) {
            var addOption ="", i;
            for (i=0; i<timeList.length; i++) {
                addOption = addOption + '<option value="'+timeList[i]+'">'+timeList[i]+'</option>';
            }
            const times = data.time.split(',');
            for (let index = 0; index < times.length; index++) {
                if(index === 0) {
                    if(times[index] !== null) {
                        var option = document.getElementById("time"+0).options
                        var option2 = document.getElementById("timeAMPM"+0).options
                        for (let index1 = 0; index1 < timeListCheck.length; index1++) {
                            if(timeListCheck[index1].pmTime === times[index]) {
                                document.getElementById("time"+0).value = timeListCheck[index1].amTime;
                                document.getElementById("timeAMPM"+0).value = timeListCheck[index1].pm;
                                for (var option1 of option) {
                                    var optionCheck = option1.value;
                                    if (optionCheck === timeListCheck[index1].amTime) {
                                        option1.selected = true;
                                        break;
                                    }
                                }
                                for (var option3 of option2) {
                                    var optionCheck = option3.value;
                                    if (optionCheck === timeListCheck[index1].pm) {
                                        option3.selected = true;
                                        break;
                                    }
                                }
                            }else if(timeListCheck[index1].amTime === times[index]) {
                                document.getElementById("time"+0).value = timeListCheck[index1].amTime;
                                document.getElementById("timeAMPM"+0).value = timeListCheck[index1].am;
                                for (var option1 of option) {
                                    var optionCheck = option1.value;
                                    if (optionCheck === timeListCheck[index1].amTime) {
                                        option1.selected = true;
                                        break;
                                    }
                                }
                                for (var option3 of option2) {
                                    var optionCheck = option3.value;
                                    if (optionCheck === timeListCheck[index1].am) {
                                        option3.selected = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }else {
                    var dom = document.createElement('div');
                    dom.setAttribute("id", "removeTimeDiv"+(index));
                    var appendChildData = '<label class="d-block input-label">Select Time</label>'+
                    '<div class="row">'+
                        '<div class="col-lg-5 col-md-5">'+
                            '<div class="form-group">'+
                                '<label for="time'+index+'" class="d-block input-label"></label>'+
                                '<div class="input-wrap date-wrap">'+
                                    '<select id="time'+index+'">'+
                                        '<option value="">Select Time</option>'+
                                        ''+addOption+''+
                                    '</select>'+
                                '</div>'+
                            '</div>'+
                        '</div>'+
                        '<div class="col-lg-5 col-md-5">'+
                            '<div class="form-group">'+
                                '<label for="timeAMPM'+index+'" class="d-block input-label"></label>'+
                                '<div class="input-wrap date-wrap">'+
                                    '<select id="timeAMPM'+index+'">'+
                                        '<option value="">Select Time</option>'+
                                        '<option value="AM">AM</option>'+
                                        '<option value="PM">PM</option>'+
                                    '</select>'+
                                '</div>'+
                            '</div>'+
                        '</div>'+
                        '<div class="col-lg-2 col-md-2">'+
                            '<div class="form-group">'+
                                '<label class="input-label"></label>'+
                                '<div class="input-wrap select-wrap">'+
                                    '<input type="button" id="deleteTime'+index+'" class="txt" style="width: 100%;height: 25px;font-size: 10px;background-color: #cd0000;border: 1px solid #cd0000;color: #fff;border-radius: 4px;font-weight: bold;" value="Remove" />'+
                                '</div>'+
                            '</div>'+
                        '</div>'+
                    '</div>'
                    dom.innerHTML = appendChildData;
                    var appendDiv = document.getElementById("appendTimeList")
                    appendDiv.append(dom);
                    document.getElementById("deleteTime"+index).addEventListener("click", ()=>deleteTimeDetails(index));
                    if(times[index] !== null) {
                        var option = document.getElementById("time"+index).options
                        var option2 = document.getElementById("timeAMPM"+index).options
                        for (let index2 = 0; index2 < timeListCheck.length; index2++) {
                            if(timeListCheck[index2].pmTime === times[index]) {
                                document.getElementById("time"+index).value = timeListCheck[index2].amTime;
                                document.getElementById("timeAMPM"+index).value = timeListCheck[index2].pm;
                                for (var option1 of option) {
                                    var optionCheck = option1.value;
                                    if (optionCheck === timeListCheck[index2].amTime) {
                                        option1.selected = true;
                                        break;
                                    }
                                }
                                for (var option3 of option2) {
                                    var optionCheck = option3.value;
                                    if (optionCheck === timeListCheck[index2].pm) {
                                        option3.selected = true;
                                        break;
                                    }
                                }
                            }else if(timeListCheck[index2].amTime === times[index]) {
                                document.getElementById("time"+index).value = timeListCheck[index2].amTime;
                                document.getElementById("timeAMPM"+index).value = timeListCheck[index2].am;
                                for (var option1 of option) {
                                    var optionCheck = option1.value;
                                    if (optionCheck === timeListCheck[index2].amTime) {
                                        option1.selected = true;
                                        break;
                                    }
                                }
                                for (var option3 of option2) {
                                    var optionCheck = option3.value;
                                    if (optionCheck === timeListCheck[index2].am) {
                                        option3.selected = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            setIncrementTimeValue(times.length+1);
        }else {
            if(data.time !== null) {
                var option = document.getElementById("time"+0).options
                var option2 = document.getElementById("timeAMPM"+0).options
                for (let index = 0; index < timeListCheck.length; index++) {
                    if(timeListCheck[index].pmTime === data.time) {
                        document.getElementById("time"+0).value = timeListCheck[index].amTime;
                        document.getElementById("timeAMPM"+0).value = timeListCheck[index].pm;
                        for (var option1 of option) {
                            var optionCheck = option1.value;
                            if (optionCheck === timeListCheck[index].amTime) {
                                option1.selected = true;
                                break;
                            }
                        }
                        for (var option3 of option2) {
                            var optionCheck = option3.value;
                            if (optionCheck === timeListCheck[index].pm) {
                                option3.selected = true;
                                break;
                            }
                        }
                    }else if(timeListCheck[index].amTime === data.time) {
                        document.getElementById("time"+0).value = timeListCheck[index].amTime;
                        document.getElementById("timeAMPM"+0).value = timeListCheck[index].am;
                        for (var option1 of option) {
                            var optionCheck = option1.value;
                            if (optionCheck === timeListCheck[index].amTime) {
                                option1.selected = true;
                                break;
                            }
                        }
                        for (var option3 of option2) {
                            var optionCheck = option3.value;
                            if (optionCheck === timeListCheck[index].am) {
                                option3.selected = true;
                                break;
                            }
                        }
                    }
                }
            }
        }



        // if(data.time.includes(',')) {
        //     const times = data.time.split(',');
        //     for (let index = 0; index < times.length; index++) {
        //         if(index === 0) {
        //             if(times[index] !== null) {
        //                 var splitTime = times[index].split(' ');
        //                 document.getElementById("time"+index).value = splitTime[0];
        //                 var option = document.getElementById("time"+index).options
        //                 document.getElementById("timeAMPM"+index).value = splitTime[1];
        //                 var option2 = document.getElementById("timeAMPM"+index).options
        //                 for (var option1 of option) {
        //                     var optionCheck = option1.value;
        //                     if (optionCheck === splitTime[0]) {
        //                         option1.selected = true;
        //                         break;
        //                     }
        //                 }
        //                 for (var option3 of option2) {
        //                     var optionCheck = option3.value;
        //                     if (optionCheck === splitTime[0]) {
        //                         option3.selected = true;
        //                         break;
        //                     }
        //                 }
        //             }
        //         }else {
        //             var dom = document.createElement('div');
        //             dom.setAttribute("id", "removeTimeDiv"+incrementTimeValue);
        //             var addOption ="", i;
        //             for (i=0; i<timeList.length; i++) {
        //                 addOption = addOption + '<option value="'+timeList[i]+'">'+timeList[i]+'</option>';
        //             }
        //             var appendChildData = '<label class="d-block input-label">Select Time</label>'+
        //             '<div class="row">'+
        //                 '<div class="col-lg-5 col-md-5">'+
        //                     '<div class="form-group">'+
        //                         '<label for="time'+index+'" class="d-block input-label"></label>'+
        //                         '<div class="input-wrap date-wrap">'+
        //                             '<select id="time'+index+'">'+
        //                                 '<option value="">Select Time</option>'+
        //                                 ''+addOption+''+
        //                             '</select>'+
        //                         '</div>'+
        //                     '</div>'+
        //                 '</div>'+
        //                 '<div class="col-lg-5 col-md-5">'+
        //                     '<div class="form-group">'+
        //                         '<label for="timeAMPM'+index+'" class="d-block input-label"></label>'+
        //                         '<div class="input-wrap date-wrap">'+
        //                             '<select id="timeAMPM'+index+'">'+
        //                                 '<option value="">Select Time</option>'+
        //                                 '<option value="AM">AM</option>'+
        //                                 '<option value="PM">PM</option>'+
        //                             '</select>'+
        //                         '</div>'+
        //                     '</div>'+
        //                 '</div>'+
        //                 '<div class="col-lg-2 col-md-2">'+
        //                     '<div class="form-group">'+
        //                         '<label class="input-label"></label>'+
        //                         '<div class="input-wrap select-wrap">'+
        //                             '<input type="button" id="deleteTime'+index+'" class="txt" style="width: 100%;height: 25px;font-size: 10px;background-color: #cd0000;border: 1px solid #cd0000;color: #fff;border-radius: 4px;font-weight: bold;" value="Remove" />'+
        //                         '</div>'+
        //                     '</div>'+
        //                 '</div>'+
        //             '</div>'
        //             dom.innerHTML = appendChildData;
        //             var appendDiv = document.getElementById("appendTimeList")
        //             appendDiv.append(dom);
        //             document.getElementById("deleteTime"+index).addEventListener("click", ()=>deleteTimeDetails(index));
        //             if(times[index] !== null) {
        //                 var splitTime = times[index].split(' ');
        //                 document.getElementById("time"+index).value = splitTime[0];
        //                 var option = document.getElementById("time"+index).options
        //                 document.getElementById("timeAMPM"+index).value = splitTime[1];
        //                 var option2 = document.getElementById("timeAMPM"+index).options
        //                 for (var option1 of option) {
        //                     var optionCheck = option1.value;
        //                     if (optionCheck === splitTime[0]) {
        //                         option1.selected = true;
        //                         break;
        //                     }
        //                 }
        //                 for (var option3 of option2) {
        //                     var optionCheck = option3.value;
        //                     if (optionCheck === splitTime[0]) {
        //                         option3.selected = true;
        //                         break;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        //     setIncrementTimeValue(times.length+1);
        // }else {
        //     if(data.time !== null) {
        //         var splitTime = data.time.split(' ');
        //         document.getElementById("time"+0).value = splitTime[0];
        //         var option = document.getElementById("time"+0).options
        //         document.getElementById("timeAMPM"+0).value = splitTime[1];
        //         var option2 = document.getElementById("timeAMPM"+0).options
        //         for (var option1 of option) {
        //             var optionCheck = option1.value;
        //             if (optionCheck === splitTime[0]) {
        //                 option1.selected = true;
        //                 break;
        //             }
        //         }
        //         for (var option3 of option2) {
        //             var optionCheck = option3.value;
        //             if (optionCheck === splitTime[0]) {
        //                 option3.selected = true;
        //                 break;
        //             }
        //         }
        //     }
        // }
        
    }
    
    const deleteInputDetails = (event) => {
        document.getElementById("deleteAttValue"+event).remove();
        document.getElementById("deleteAttName"+event).remove();
        document.getElementById("deleteButton"+event).remove();
    }

    const increaseInputDetails = () => {
        var attValue = (document.getElementById("attValue"+(incrementValue - 1)) || {}).value;
        var attName = (document.getElementById("attName"+(incrementValue - 1)) || {}).value;
        if(attName === "" || attValue === "") {
            document.getElementById("errorAttName"+(incrementValue - 1)).classList.add("error-message");
            document.getElementById("errorAttValue"+(incrementValue - 1)).classList.add("error-message");
            document.getElementById("errorAttName"+(incrementValue - 1)).innerHTML = "Enter attribute name";
            document.getElementById("errorAttValue"+(incrementValue - 1)).innerHTML = "Enter attribute value";
        }else {
            if(document.getElementById("errorAttName"+(incrementValue - 1)) !== null) {
                document.getElementById("errorAttName"+(incrementValue - 1)).classList.remove("error-message");
                document.getElementById("errorAttValue"+(incrementValue - 1)).classList.remove("error-message");
                document.getElementById("errorAttName"+(incrementValue - 1)).innerHTML = "";
                document.getElementById("errorAttValue"+(incrementValue - 1)).innerHTML = "";
            }
            var dom = document.createElement('div');
            dom.classList.add('form-group');
            dom.setAttribute("id", "deleteAttName"+incrementValue);
            // dom.classList.add('deleteAttName'+incrementValue);
            var appendChildData = '<label for="attName'+incrementValue+'" class="d-block input-label">Attribute Name</label>'+
                    '<div class="input-wrap date-wrap">'+
                        '<select id="attName'+incrementValue+'" >'+
                            '<option value="">select Attribute Name</option>'+
                            '<option value="Operation">Operation</option>'+
                            '<option value="Source">Source</option>'+
                        '</select>'+
                        '<div id="errorAttName'+(incrementValue)+'"></div>'+
                    '</div>'
            dom.innerHTML = appendChildData;
            var appendDiv = document.getElementById("appendDivAttributeName")
            appendDiv.append(dom);
            var dom1 = document.createElement('div');
            dom1.classList.add('form-group');
            dom1.setAttribute("id", "deleteAttValue"+incrementValue);
            // dom.classList.add('deleteAttValue'+incrementValue);
            var appendChildData1 = '<label for="attValue'+incrementValue+'" class="d-block input-label">Attribute Value</label>'+
                    '<div class="input-wrap date-wrap">'+
                        '<input type="text" id="attValue'+incrementValue+'" class="txt" placeholder="Attribute Value" />'+
                        '<div id="errorAttValue'+(incrementValue)+'"></div>'+
                    '</div>'
            dom1.innerHTML = appendChildData1;
            var appendDiv1 = document.getElementById("appendDivAttributeValue")
            appendDiv1.append(dom1);

            var dom2 = document.createElement('div');
            dom2.classList.add('form-group');
            dom2.setAttribute("id", "deleteButton"+incrementValue);
            dom2.style.paddingTop = incrementPadding+'px';
            // dom2.style.paddingBottom = '5px';
            var appendChildData2 = '<label for="deleteEvent'+incrementValue+'" class="input-label"></label>'+
                    '<div class="input-wrap select-wrap">'+
                        '<input type="button" id="deleteEvent'+incrementValue+'" class="txt" style="width: 100%;height: 33px;background-color: #cd0000;border: 1px solid #cd0000;color: #fff;border-radius: 4px;font-weight: bold;font-size: 13px;" value="Remove"/>'+
                    '</div>'
            
            dom2.innerHTML = appendChildData2;
            var appendDiv2 = document.getElementById("appendDivDelete")
            appendDiv2.append(dom2);
            document.getElementById("deleteEvent"+incrementValue).addEventListener("click", ()=>deleteInputDetails(incrementValue));
            setIncrementPadding(22);
            setIncrementValue(incrementValue+1);
        }
    }

    const deleteTimeDetails = (event) => {
        document.getElementById("removeTimeDiv"+event).remove();
    }

    const addTimeList = () => {
        var addTime = (document.getElementById("time"+(incrementTimeValue - 1)) || {}).value;
        var addTime1 = (document.getElementById("timeAMPM"+(incrementTimeValue - 1)) || {}).value;
        if(addTime === "" || addTime1 === "") {
            document.getElementById("errorAddTime"+(incrementTimeValue - 1)).classList.add("error-message");
            document.getElementById("errorAddTimeAmPm"+(incrementTimeValue - 1)).classList.add("error-message");
            document.getElementById("errorAddTime"+(incrementTimeValue - 1)).innerHTML = "Enter Time";
            document.getElementById("errorAddTimeAmPm"+(incrementTimeValue - 1)).innerHTML = "Enter Time";
        }else {
            if(document.getElementById("errorAddTime"+(incrementTimeValue - 1)) !== null) {
                document.getElementById("errorAddTime"+(incrementTimeValue - 1)).classList.remove("error-message");
                document.getElementById("errorAddTimeAmPm"+(incrementTimeValue - 1)).classList.remove("error-message");
                document.getElementById("errorAddTime"+(incrementTimeValue - 1)).innerHTML = "";
                document.getElementById("errorAddTimeAmPm"+(incrementTimeValue - 1)).innerHTML = "";
            }
            var dom = document.createElement('div');
            dom.setAttribute("id", "removeTimeDiv"+incrementTimeValue);
            var addOption ="", i;
            for (i=0; i<timeList.length; i++) {
                addOption = addOption + '<option value="'+timeList[i]+'">'+timeList[i]+'</option>';
            }
            var appendChildData = '<label class="d-block input-label">Select Time</label>'+
            '<div class="row">'+
                '<div class="col-lg-5 col-md-5">'+
                    '<div class="form-group">'+
                        '<label for="time'+incrementTimeValue+'" class="d-block input-label"></label>'+
                        '<div class="input-wrap date-wrap">'+
                            '<select id="time'+incrementTimeValue+'">'+
                                '<option value="">Select Time</option>'+
                                ''+addOption+''+
                            '</select>'+
                            '<div id="errorAddTime'+incrementTimeValue+'"></div>'+
                        '</div>'+
                    '</div>'+
                '</div>'+
                '<div class="col-lg-5 col-md-5">'+
                    '<div class="form-group">'+
                        '<label for="timeAMPM'+incrementTimeValue+'" class="d-block input-label"></label>'+
                        '<div class="input-wrap date-wrap">'+
                            '<select id="timeAMPM'+incrementTimeValue+'">'+
                                '<option value="">Select Time</option>'+
                                '<option value="AM">AM</option>'+
                                '<option value="PM">PM</option>'+
                            '</select>'+
                            '<div id="errorAddTimeAmPm'+incrementTimeValue+'"></div>'+
                        '</div>'+
                    '</div>'+
                '</div>'+
                '<div class="col-lg-2 col-md-2">'+
                    '<div class="form-group">'+
                        '<label class="input-label"></label>'+
                        '<div class="input-wrap select-wrap">'+
                            '<input type="button" id="deleteTime'+incrementTimeValue+'" class="txt" style="width: 100%;height: 25px;font-size: 10px;background-color: #cd0000;border: 1px solid #cd0000;color: #fff;border-radius: 4px;font-weight: bold;" value="Remove" />'+
                        '</div>'+
                    '</div>'+
                '</div>'+
            '</div>'
            dom.innerHTML = appendChildData;
            var appendDiv = document.getElementById("appendTimeList")
            appendDiv.append(dom);
            document.getElementById("deleteTime"+incrementTimeValue).addEventListener("click", ()=>deleteTimeDetails(incrementTimeValue));
            setIncrementTimeValue(incrementTimeValue+1);
        }
    }

    const getSavedDetails = () => {
        var pushTime = []
        var pushTime1 = []
        var pushTime2 = []
        var dayLists = []
        for (let index = 0; index < incrementTimeValue; index++) {
            var timeValue = (document.getElementById("time"+index) || {}).value;
            var timeName = (document.getElementById("timeAMPM"+index) || {}).value;
            if(timeValue !== undefined && timeName !== undefined) {
                if(timeValue !== "" && timeName !== "") {
                    pushTime.push(timeValue);
                    pushTime1.push(timeName);
                    pushTime2.push(timeValue+" "+timeName);
                }
            }
        }
        // setTimes(pushTime2.toString());

        var newPushData = [];
        for (let index = 0; index < timeListCheck.length; index++) {
            for (let index1 = 0; index1 < pushTime2.length; index1++) {
                if(timeListCheck[index].pmTimeCheck === pushTime2[index1]) {
                    newPushData.push(timeListCheck[index].pmTime);
                }else if(timeListCheck[index].amTimeCheck === pushTime2[index1]) {
                    newPushData.push(timeListCheck[index].amTime);
                }
            }
        }
        setTimes(newPushData.toString());

        for (let index1 = 0; index1 < dayList.length; index1++) {
            var dayValue = (document.getElementById("dayId"+index1) || {}).value;
            if(dayValue !== undefined) {
                if(dayValue !== "") {
                    dayLists.push(dayValue);
                }
            }
        }
        setDays(dayLists.toString());

    }

    const clickDaysButton = (id, fullDay) => {
        var val = document.getElementById("dayId"+id).value
        if(val === "") {
            document.getElementById("dayId"+id).style.backgroundColor = '#cd0000'
            document.getElementById("dayId"+id).style.color = '#fff'
            document.getElementById("dayId"+id).value = fullDay
        }else {
            document.getElementById("dayId"+id).style.backgroundColor = '#fff'
            document.getElementById("dayId"+id).style.color = '#cd0000'
            document.getElementById("dayId"+id).value = ""
        }
    }

    function sortTable(n) {
        var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById("schedulerTable");
        switching = true;
        dir = "asc"; 
        while (switching) {
          switching = false;
          rows = table.rows;
          for (i = 1; i < (rows.length - 1); i++) {
            shouldSwitch = false;
            x = rows[i].getElementsByTagName("TD")[n];
            y = rows[i + 1].getElementsByTagName("TD")[n];
            if (dir == "asc") {
              if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                shouldSwitch= true;
                break;
              }
            } else if (dir == "desc") {
              if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                shouldSwitch = true;
                break;
              }
            }
          }
          if (shouldSwitch) {
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
            switchcount ++;      
          } else {
            if (switchcount == 0 && dir == "asc") {
              dir = "desc";
              switching = true;
            }
          }
        }
      }

    function enableDayListModel(dayString) {
        setIsDayListingPopupEnable(false)
        const arr = dayString.split(',');
        setDayListForModel(arr)
        setIsDayListingPopupEnable(true)
    }

    function enableTimeListModel(timeString) {
        setIsTimeListingPopupEnable(false)
        const arr = timeString.split(',');
        setTimeListForModel(arr)
        setIsTimeListingPopupEnable(true)
    }

    function customText(daytimeGroup, limit) {
        var objectString = JSON.stringify(daytimeGroup);
        objectString = objectString.replaceAll("[", "").replaceAll("]", "").replaceAll("\"", "").replaceAll(",,", ",")
        return AppendingDotsToSuffix(objectString, limit);
    }

     const changeStatusScheduler = (data) => {
        var statusSet = ""
        if(data.status === 'A') {
            statusSet = "I"
        }else {
            statusSet = "A"
        }
        dispatchDeleteSchedulerData(data.scheduler_id, statusSet, ({ data, message }) => {
            schedulerDataList();
        }, (message) => {
            window.scrollTo(0, 0);
        });
     }

    function exportClickExcelDownload() {
        var modifiedList =[{}];
        filteredSchedulerList.forEach(element => {
            let temp ={};
            temp.scheduler_name=element.scheduler_name;
            temp.partner_code=element.partner_code;
            temp.attribute_value=element.attribute_value;
            temp.days=element.days;
            temp.time=element.time;
            temp.start_date=element.start_date;
            temp.end_date=element.end_date;
            temp.status=element.status;
            modifiedList.push(temp);
        }); 
        DownloadListAsExcel(modifiedList,'ListOfSchedules');
    }

    return (
        <Fragment>
            <main>
                <div class="container">
                    <ul class="breadcrumb">
                        <li class="breadcrumb__item">
                            <a class="breadcrumb__link" href="#">Configurations</a>
                        </li>
                        <li class="breadcrumb__item">
                            <span class="breadcrumb__link">Scheduler</span>
                        </li>
                    </ul>
                    <div class="title">
                        <h1>Scheduler</h1>
                    </div>
                    {failMsg ? 
                    <AlertFailure message={failMsg}/> : ''}
                    {succMsg ? 
                    <AlertSuccess message={succMsg}/> : ''}
                </div>
                <div class="grey-block">
                    <div class="container">
                        <div class="form mb-5">
                            <h2 class="mb-4">Scheduler</h2>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f1" class="input-label">Scheduler Name <span style={{ color: 'red' }}>*</span></label>
                                        <div class="input-wrap select-wrap">
                                            <input type="text" class="txt" value={schedulerName} placeholder="Scheduler Name" onChange={(e) => setSchedulerName(e.target.value)} />
                                            {errorDetails.errorSchedulerName !== "" ?
                                                <div className="error-message">{errorDetails.errorSchedulerName}</div>
                                            : ''}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={partnerList} constrains={constrains} setResponseData={setResponseData} editValue ={editTempVal}></CustomDropdownWidget>
                                        {errorDetails.errorPartnerCode !== "" ?
                                            <div className="error-message">{errorDetails.errorPartnerCode}</div>
                                        : ''}
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6" style={{ display: "none" }}>
                                    <div class="form-group">
                                        <label for={"attName"+0} class="d-block input-label">Attribute Name <span style={{ color: 'red' }}>*</span></label>
                                        <div class="input-wrap date-wrap">
                                            <select id={"attName"+0}>
                                                <option value="">Select Attribute Name</option>
                                                <option value="Operation">Operation</option>
                                                <option value="Source">Source</option>
                                            </select>
                                            {errorDetails.errorAttributeName !== "" ?
                                                <div className="error-message">{errorDetails.errorAttributeName}</div>
                                            : ''}
                                            <div id={"errorAttName"+0}></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for={"attValue"+0} class="input-label">Operation Type <span style={{ color: 'red' }}>*</span></label>
                                        <div class="input-wrap date-wrap">
                                            <select id={"attValue"+0}>
                                                <option value="">Select Operation Type</option>
                                                <option value="PULL">PULL</option>
                                                <option value="PUSH">PUSH</option>
                                            </select>
                                            {errorDetails.errorAttributeValue !== "" ?
                                                <div className="error-message">{errorDetails.errorAttributeValue}</div>
                                            : ''}
                                            <div id={"errorAttValue"+0}></div>
                                        </div>
                                        
                                        {/*<div class="input-wrap select-wrap">
                                            <input type="text" class="txt" id={"attValue"+0} placeholder="Operation Type" />
                                            {errorDetails.errorAttributeValue !== "" ?
                                                <div className="error-message">{errorDetails.errorAttributeValue}</div>
                                            : ''}
                                            <div id={"errorAttValue"+0}></div>
                                        </div>*/}
                                    </div>
                                </div>
                                <div class="col-lg-1 col-md-1" style={{ display: "none" }}>
                                    <div class="form-group">
                                        <label for="f1" class="input-label"></label>
                                        <div class="input-wrap select-wrap" style={{marginTop: "15px"}}>
                                            <input type="button" class="txt" style={{width: "100%",height: "33px",backgroundColor: '#cd0000',border: '1px solid #cd0000',color: '#fff',borderRadius: '4px',fontWeight: 'bold',fontSize: '13px'}} onClick={() => increaseInputDetails()} placeholder="Attribute Value" value="Add" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f1" class="input-label">Start and End Date <span style={{ color: 'red' }}>*</span></label>
                                        <div class="input-wrap select-wrap">
                                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter" style={{color: "#5f5e5e", backgroundColor: "#f6f7f9", borderColor: "#e1e3e4", width: "100%", height: "47px"}}>
                                            Select Start and End Date
                                        </button>
                                        {errorDetails.errorDays !== "" ?
                                            <div className="error-message">{errorDetails.errorDays}</div>
                                        : ''}
                                        {errorDetails.errorTime !== "" ?
                                            <div className="error-message">{errorDetails.errorTime}</div>
                                        : ''}
                                        {errorDetails.errorTimeAMPM !== "" ?
                                            <div className="error-message">{errorDetails.errorTimeAMPM}</div>
                                        : ''}

                                        {/* Modal */}
                                        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered" role="document">
                                            <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalCenterTitle">Select Start and End Date</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label for="startdate" class="input-label">Start Date</label>
                                                            <div class="input-wrap select-wrap">
                                                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} placeholderText="Start Date" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label for="enddate" class="input-label">End Date</label>
                                                            <div class="input-wrap select-wrap">
                                                                <DatePicker
                                                                    selected={endDate}
                                                                    onChange={(date) => setEndDate(date)}
                                                                    minDate={new Date()}
                                                                    placeholderText="End Date"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12">
                                                        <div class="form-group">
                                                            <label for="startdate" class="input-label">Day's <span style={{ color: 'red' }}>*</span></label>
                                                            <div class="input-wrap select-wrap" style={{textAlign: 'center'}}>
                                                                {dayList.map(days => (
                                                                    <button id={"dayId"+days.id} onClick={() => clickDaysButton(days.id, days.fullDay)} style={{borderRadius: '50%', width: '40px', height: '40px', margin: '10px', backgroundColor: backgroundColor, color: color, fontWeight: '700', border: '1px solid #cd0000'}}>{days.day}</button>
                                                                ))}  
                                                                {errorDetails.errorDays !== "" ?
                                                                    <div className="error-message">{errorDetails.errorDays}</div>
                                                                : ''}
                                                                {/* onMouseOver={() => colorChange(days.id, "over")} onMouseLeave={() => colorChange(days.id, "leave")}   */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12">
                                                        <label class="d-block input-label">Select Time <span style={{ color: 'red' }}>*</span></label>
                                                        <div class="row">
                                                            <div class="col-lg-5 col-md-5">
                                                                <div class="form-group">
                                                                    <label for={"time"+0} class="d-block input-label"></label>
                                                                    <div class="input-wrap date-wrap">
                                                                        <select id={"time"+0}>
                                                                            <option value="">Select Time</option>
                                                                            {timeList.map((time) => 
                                                                                <option value={time}>{time}</option>
                                                                            )}
                                                                        </select>
                                                                        {errorDetails.errorTime !== "" ?
                                                                            <div className="error-message">{errorDetails.errorTime}</div>
                                                                        : ''}
                                                                        <div id={"errorAddTime"+0}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-5 col-md-5">
                                                                <div class="form-group">
                                                                    <label for={"timeAMPM"+0} class="d-block input-label"></label>
                                                                    <div class="input-wrap date-wrap">
                                                                        <select id={"timeAMPM"+0}>
                                                                            <option value="">Select Time</option>
                                                                            <option value="AM">AM</option>
                                                                            <option value="PM">PM</option>
                                                                        </select>
                                                                        {errorDetails.errorTimeAMPM !== "" ?
                                                                            <div className="error-message">{errorDetails.errorTimeAMPM}</div>
                                                                        : ''}
                                                                        <div id={"errorAddTimeAmPm"+0}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-md-2">
                                                                <div class="form-group">
                                                                    <label class="input-label"></label>
                                                                    <div class="input-wrap select-wrap">
                                                                        <input type="button" class="txt" style={{width: "100%",height: "25px",backgroundColor: '#cd0000',border: '1px solid #cd0000',color: '#fff',borderRadius: '4px',fontWeight: 'bold'}} onClick={() => addTimeList()} value="Add" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12" id="appendTimeList"></div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                {/* <button class="button button--outline" onClick={() => resetDetails()}>Reset</button> */}
                                                <button type="button" class="button" onClick={() => getSavedDetails()} data-dismiss="modal">Save</button>
                                            </div>
                                            </div>
                                        </div>
                                        </div>

                                            {/* <input type="text" class="txt" placeholder="Pull & Push Date" value="" /> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6" id="appendDivAttributeName"></div>
                                <div class="col-lg-5 col-md-5" id="appendDivAttributeValue"></div>
                                <div class="col-lg-1 col-md-1" id="appendDivDelete"></div>                                                
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="statusList" class="d-block input-label">Status <span style={{ color: 'red' }}>*</span></label>
                                        <div class="input-wrap date-wrap">
                                            <select id="statusList" onChange={(e) => setStatus(e.target.value)}>
                                                <option value="" selected={status === ""}>Select Status</option>
                                                <option value="A" selected={status === "A"}>Active</option>
                                                {/* <option value="D">Deactive</option> */}
                                                <option value="I" selected={status === "I"}>Inactive</option>
                                            </select>
                                            {errorDetails.errorStatus !== "" ?
                                                <div className="error-message">{errorDetails.errorStatus}</div>
                                            : ''}
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f2" class="d-block input-label">Action</label>
                                        <CustomRadioButton radioType="radio" radioName="action" radioListArray={radioActionArr} clickValue={setActionValue} />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="f2" class="d-block input-label">Flow</label>
                                        <CustomRadioButton radioType="radio" radioName="flow" radioListArray={radioFlowArr} clickValue={setFlowValue} />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div class="btn-wrap btn-wrap--grp mt-5 justify-content-end">
                            <button class="button button--outline" onClick={() => resetSubmitDetails()}>Reset</button>
                            <button class="button" onClick={() => clickSubmitDetails()}>Submit</button>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="table-block">
                        <div class="table-block__head">
                            <h2>List of Schedules
                            <span style={{ "fontWeight": "bold", "fontSize": "13px", "color": "#666" }} >
                                    &nbsp; [ Total: {statusCount.active_count + statusCount.inactive_count ? statusCount.active_count + statusCount.inactive_count : 0} &nbsp; Active: {statusCount.active_count ? statusCount.active_count : 0} &nbsp; Inactive: {statusCount.inactive_count ? statusCount.inactive_count : 0} ]
                                </span>
                            </h2>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <button type="button" style={{ background: '#F8F8F8', color: 'black', border: 'none' }} className="btn btn-secondary btn-sm" onClick={(e) => isExportVisible ? setIsExportVisible(false) : setIsExportVisible(true)}>
                                <img src={iconExport} alt="Success" />  Export
                                </button>
                                <div>
                                {isExportVisible ? 
                                    <div class='fh-legend widget exportXl'>
                                    <p style={{ color: '#000' }}><strong>Export As</strong></p>
                                    <ul>
                                        <li style={{ cursor: 'help'}} onClick={(e) => exportClickExcelDownload()}><img src={iconExcel} alt="Success" /> Excel</li>
                                    </ul>
                                    </div> 
                                : ''}
                                </div> 
                                <div class="txt-wrap">
                                    <input type="text" class="txt" placeholder="Search..."
                                        value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                    <i class="fa fa-search"></i>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive grid">
                            <table class="table" id="schedulerTable">
                            <thead>
                                <tr>
                                    <th scope="col" onClick={() => sortTable(0)}>Scheduler Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                                    <th scope="col" onClick={() => sortTable(1)}>Partner Code <i className="fa fa-sort" aria-hidden="true"></i></th>
                                    <th scope="col" onClick={() => sortTable(2)}>Operation Type <i className="fa fa-sort" aria-hidden="true"></i></th>
                                    <th scope="col" className="no-sort">Day's</th>
                                    <th scope="col" className="no-sort">Time</th>
                                    <th scope="col" onClick={() => sortTable(5)}>Start Date <i className="fa fa-sort" aria-hidden="true"></i></th>
                                    <th scope="col" onClick={() => sortTable(6)}>End Date <i className="fa fa-sort" aria-hidden="true"></i></th>
                                    <th scope="col" className="no-sort">Status</th>
                                    <th scope="col" className="no-sort">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredSchedulerList.length < 0 ? (<tr><td colSpan="6"><div className="text-center text-danger">No search results found</div></td></tr>) :
                                filteredSchedulerList.map(data => (
                                    <tr>
                                    <td>{data.scheduler_name}</td>
                                    <td>{data.partner_code}</td>
                                    <td>{data.attribute_value}</td>
                                    <td style={{ cursor: 'help' }} data-toggle="modal" onClick={(e) => enableDayListModel(customText(data.days, 10000))} data-target="#schedulerdaylistingmodel">
                                                {customText(data.days, 22).length < 22 ? customText(data.days, 22) :
                                                    <span style={{ paddingLeft: "5px" }}>  {customText(data.days, 22)}<Tooltip target=".toolTip" /> </span>
                                                }
                                    </td>
                                    <td style={{ cursor: 'help' }} data-toggle="modal" onClick={(e) => enableTimeListModel(customText(data.time, 10000))} data-target="#schedulertimelistingmodel">
                                                {customText(data.time, 22).length < 22 ? customText(data.time, 22) :
                                                    <span style={{ paddingLeft: "5px" }}>  {customText(data.time, 22)}<Tooltip target=".toolTip" /> </span>
                                                }
                                    </td>
                                    <td>{data.start_date !== null ? data.start_date.split('T')[0] : ''}</td>
                                    <td>{data.end_date !== null ? data.end_date.split('T')[0] : ''}</td>
                                    <td>
                                        {/* {data.status === 'A' ? 
                                            <span className="success">ACTIVE</span> 
                                        : 
                                            <span className="danger">INACTIVE</span>
                                        } */}
                                        <label class="switch" style={{marginTop: '12px'}}>
                                            <input type="checkbox" checked={data.status === "A" ? true : false} onChange={(e) => changeStatusScheduler(data)} />
                                            <span class="slider round" ></span>
                                        </label>
                                    </td>
                                    <td>
                                        <div className="table__action">
                                            <a onClick={(e) => editSchedulerData(data)} className="utils utils--outline" style={{cursor: 'pointer'}}><img src={edit_icon} alt="edit" /></a>
                                            <a onClick={(e) => deleteSchedulerData(data.scheduler_id, data.scheduler_name)} className="utils utils--outline ml-2" style={{cursor: 'pointer'}}><img src={delete_icon} alt="delete" /></a>
                                        </div>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-4">
                    <div className={`modal fade modal--addnew ${isDayListingPopupEnable ? 'show' : ''}`} id="schedulerdaylistingmodel" data-backdrop="static" data-keyboard="false" tabindex="-1"
                        aria-labelledby="staticBackdropLabel" aria-modal="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="container">
                                        <div className="table-block">
                                            <div className="table-block__head">
                                                <h2>Day's List</h2>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <img src={close_icon} onClick={(e) => setDayListForModel([])} alt="Close" />
                                                </button>
                                            </div>
                                            <br />
                                            <div class="table-responsive grid">
                                                <table class="table">
                                                    <thead>
                                                        {isDayListingPopupEnable && dayListForModel.length > 0 ? dayListForModel.map((dayModel) => (
                                                            <tr><th scope="col">{dayModel}</th></tr>
                                                        )) : ''}
                                                    </thead>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-4">
                    <div className={`modal fade modal--addnew ${isTimeListingPopupEnable ? 'show' : ''}`} id="schedulertimelistingmodel" data-backdrop="static" data-keyboard="false" tabindex="-1"
                        aria-labelledby="staticBackdropLabel" aria-modal="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="container">
                                        <div className="table-block">
                                            <div className="table-block__head">
                                                <h2>Time List</h2>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <img src={close_icon} onClick={(e) => setTimeListForModel([])} alt="Close" />
                                                </button>
                                            </div>
                                            <br />
                                            <div class="table-responsive grid">
                                                <table class="table">
                                                    <thead>
                                                        {isTimeListingPopupEnable && timeListForModel.length > 0 ? timeListForModel.map((timeModel) => (
                                                            <tr><th scope="col">{timeModel}</th></tr>
                                                        )) : ''}
                                                    </thead>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Fragment>
    )
}

const mapDispatchToProps = dispatch => ({
    dispatchListPartnersAction: (onSuccess, onError) => dispatch(listPartners(onSuccess, onError)),
    dispatchSchedulerDataList: (onSuccess, onError) => dispatch(displaySchedulerData(onSuccess, onError)),
    dispatchCreateScheduler: (data, onSuccess, onError) => dispatch(createScheduler(data, onSuccess, onError)),
    dispatchDeleteSchedulerData: (data, onSuccess, onError) => dispatch(deleteSchedulerData(data, onSuccess, onError)),
    dispatchEditSchedulerData: (data, onSuccess, onError) => dispatch(editSchedulerData(data, onSuccess, onError)),
    dispatchSchedulerCount: (onSuccess, onError) =>
        dispatch(schedulerCount(onSuccess, onError)),
});

export default  connect(null, mapDispatchToProps)(AddScheduler);