import React, { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import AlertFailure from '../utils/alert-failure.component';
import AlertSuccess from '../utils/alert-success.component';
import iconRetry from '../assets/images/icon-retry.png';
import {isJson,sessionErrorCheck,isAdmin,displaySchedulerStatusData,listPartners,retryFileProcessing} from '../redux/actions/auth.action.creators'
import 'react-datepicker/dist/react-datepicker.css';
import { Tooltip } from 'primereact/tooltip';
import 'primeflex/primeflex.css';
import { confirmDialog } from 'primereact/confirmdialog';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import * as constants from '../redux/constants';
import { AppendingDotsToSuffix } from "../utils/InputDataCustomization";
import iconInfo from '../assets/images/icon-info-round.png';
import close_icon from '../assets/images/icon-close.png';


const AddSchedulerRunStatus = ({ dispatchSchedulerStatusDataList,dispatchListPartnersAction,dispatchRetryFileProcessing}) => {
    
    const userInfos = sessionErrorCheck() !== null ? sessionErrorCheck() : window.location = '/auth';
    const userInfo = userInfos;
    const [partnerList, setPartnerList] = useState([]);
    const [schedulerName, setSchedulerName] = useState("");
    const [times, setTimes] = useState("");
    const [days, setDays] = useState("");
    const [status, setStatus] = useState("");
    const [schedulerStatusList, setSchedulerStatusList] = useState({ "data": "", "loading": true });
    //const [schedulerStatusList, setSchedulerStatusList] = useState([]);
    const [createAndUpdateStatus, setCreateAndUpdateStatus] = useState("");
    const [schedulerId, setSchedulerId] = useState("");
    const [failMsg, setFailMsg] = useState("");
    const [succMsg, setSuccMsg] = useState("");
    const [errorMessageForAttribute, setErrorMessageForAttribute] = useState(false);
    const [errorDetails, setErrorDetails] = useState({ "errorSchedulerName": "", "errorPartnerCode": "", "errorAttributeName": "", "errorAttributeValue": "", "errorStatus": "", "errorDays": "", "errorTime": "", "errorTimeAMPM": "", "errorStartDate": "", "errorEndDate": "" });
    const [statusCount, setStatusCount] = useState({})
    const [searchKey, setSearchKey] = useState('');
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [isLogListingPopupEnable, setIsLogListingPopupEnable] = useState(false)
    const [logListForModel, setLogListForModel] = useState([])
    const [sortAsc, setSortAsc] = useState({ "scheduler_name": false, "partner_code": false,"run_start_date_time": false,"run_end_date_time": false,"status": false,"operation_type": false });


    useEffect(() => {
        dispatchListPartnersAction(({data, message}) => {
            if(data){
                if(userInfo.role == 'Partner'){
                    setPartnerList(data.filter(p => (p.partnerid == userInfo.partnerId)));
                }
                else {
                    var pushData = [];
                    for (let index = 0; index < data.length; index++) {
                        pushData.push({"value": data[index].partnerid, "label": data[index].partnercode})
                    }
                    setPartnerList([{"value": "", "label": "Select partner"}].concat(pushData));
                }
            }
        },
        (message) => {
            console.log("partner list error--  ",message);
        });
        schedulerStatusDataList();
    },[])

    const schedulerStatusDataList = () => {
        dispatchSchedulerStatusDataList(({data, message}) => {
            if(data){
                //setSchedulerStatusList(data);
                setSchedulerStatusList({ "data": data, "loading": false });
                setFilteredTableData(data);
            }
        },
        (message) => {
            console.log("scheduler list error--  ",message);
        });
    }


    const onPopupActions = (monitoringId, schedulername) => {
        confirmDialog({
            message: 'Do you want to retry ' + schedulername + ' ?',
            header: 'Retry Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept() {
                doRetry(monitoringId, schedulername);
            },
            reject() {
    
            },
            acceptLabel: "Confirm",
            rejectLabel: "Cancel"
        });
    };



    const doRetry = (monitoringId, schedulername) => {

        console.log(monitoringId);
        console.log(schedulername);
        dispatchRetryFileProcessing(monitoringId, ({ data, message, status }) => {
            if (data) {
                window.scrollTo(0, 0)
                setSuccMsg("Scheduler reprocessing Initiated.")
                setTimeout(() => {
                    setSuccMsg('')
                }, 5000);
                setTimeout(() => {
                    onSearch()
                }, 10);
            } else {
                window.scrollTo(0, 0)
                setFailMsg("Scheduler reprocessing Failed.")
                setTimeout(() => { setFailMsg('') }, 5000);
            }
        }, (message) => {
            console.log("schedulername : " + schedulername + " message :" + message);
        });
    }

    const formatDate = (dateString) => {
        if (!dateString) {
          return ''; // or any default value you prefer
        }
      
        // Remove 'T' and everything after '.'
        const formattedDate = dateString.replace('T', ' ').split('.')[0];
      
        return formattedDate;
      };
       

 
   

    function compareBy(key, dir) {
        return function (a, b) {
            if(key == "run_start_date_time" || key == "run_end_date_time"){
                if (new Date(a[key]) < new Date(b[key])) return dir == true ? -1 : 1;
                if (new Date(a[key]) > new Date(b[key])) return dir == true ? 1 : -1;
            }else{
                if (a[key] < b[key]) return dir == true ? -1 : 1;
                if (a[key] > b[key]) return dir == true ? 1 : -1;                
            }
            return 0;            
        };
    }
    
    function getStatusColor(status) {
        switch (status) {
          case 'Failed':
            return 'red';
          case 'Success':
            return 'darkgreen';
          case 'Scheduled':
            return 'blue';
          default:
            return 'black'; 
        }
      }

    function sortBy(key) {
        let arrayCopy = _.cloneDeep(filteredTableData);
        let dirCopy = _.cloneDeep(sortAsc);
        dirCopy[key] = !dirCopy[key];
        setSortAsc(dirCopy);
        arrayCopy.sort(compareBy(key, dirCopy[key]));
        setFilteredTableData(arrayCopy);
    }

    function customText(logGroup, limit) {
        var objectString = JSON.stringify(logGroup);
        objectString = objectString.replace(/\\/g, '').replace(/"/g, '');
        objectString = objectString.replaceAll("},{", "},\n{");
        return AppendingDotsToSuffix(objectString, limit);
    }

    function enableLogListModel(logString) {
        setIsLogListingPopupEnable(false)
        const cleanedLogString = logString.replace(/\\/g, '').replace(/"/g, '');
        const arr = cleanedLogString.split('\n');
        setLogListForModel(arr)
        setIsLogListingPopupEnable(true)
    }

    useEffect(() => {
        let filtered = _.cloneDeep(filteredTableData);
        if (!schedulerStatusList.loading) {
            filtered = schedulerStatusList.data.filter(p => {
                return (p.scheduler_name.toLowerCase().includes(searchKey.toLowerCase())
                    || p.partner_code.toLowerCase().includes(searchKey.toLowerCase()))
                    
            });
        }
        setFilteredTableData(filtered);
    }, [searchKey])

     

    return (
        <Fragment>
            <main>
                <div class="container">
                    <ul class="breadcrumb">
                        <li class="breadcrumb__item">
                            <a class="breadcrumb__link" href="#">Configurations</a>
                        </li>
                        <li class="breadcrumb__item">
                            <span class="breadcrumb__link">Scheduler Run Log</span>
                        </li>
                    </ul>
                    <div class="title">
                        <h1>Scheduler Run Log</h1>
                    </div>
                    {failMsg ? 
                    <AlertFailure message={failMsg}/> : ''}
                    {succMsg ? 
                    <AlertSuccess message={succMsg}/> : ''}
                </div>
               
                <div class="container">
                    <div class="table-block">
                        <div class="table-block__head">
                            <h2>List of Schedules
                            <span style={{ "fontWeight": "bold", "fontSize": "13px", "color": "#666" }} >
                                    &nbsp; [ Total: {filteredTableData ? filteredTableData.length : 0} ]
                                </span>
                            </h2>
                            <div class="txt-wrap">
                                <input type="text" class="txt" placeholder="Search..." 
                                    value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                <i class="fa fa-search"></i>
                            </div>
                        </div>
                        <div className="table-responsive grid"   style={{ overflowY: 'auto', maxHeight: '500px'}}>
                            <table class="table" id="schedulerTable">
                            <colgroup>
                                    <col style={{ width: '150px' }} /> 
                                    <col style={{ width: '150px' }} />
                                    <col style={{ width: '200px' }} /> 
                                    <col style={{ width: '200px' }} /> 
                                    <col style={{ width: '100px' }} /> 
                                    <col style={{ width: '100px' }} /> 
                                    <col style={{ width: '300px' }} />  
                                    <col style={{ width: '100px' }} /> 
                                   
                            </colgroup>
                            <thead>
                                <tr>
                                
                                    <th style={{ "whiteSpace": "nowrap" }}  scope="col" onClick={() => sortBy('scheduler_name')}>Scheduler Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                                    <th style={{ "whiteSpace": "nowrap" }} scope="col" onClick={() => sortBy('partner_code')}>Patner Code<i className="fa fa-sort" aria-hidden="true"></i></th>
                                    <th  style={{ "whiteSpace": "nowrap" }} scope="col" className="no-sort">Scheduled Time</th>
                                    <th style={{ "whiteSpace": "nowrap" }} scope="col" onClick={() => sortBy('run_start_date_time')} className="wide-column" >Run Start Time <i className="fa fa-sort" aria-hidden="true"></i></th>
                                    <th style={{ "whiteSpace": "nowrap" }} scope="col" onClick={() => sortBy('run_end_date_time')}>Run End Time <i className="fa fa-sort" aria-hidden="true"></i></th>
                                    <th style={{ "whiteSpace": "nowrap" }} scope="col" onClick={() => sortBy('status')}>Status<i className="fa fa-sort" aria-hidden="true"></i></th>
                                    <th style={{ "whiteSpace": "nowrap" }} scope="col" onClick={() => sortBy('operation_type')}>Operation Type<i className="fa fa-sort" aria-hidden="true"></i></th>
                                    <th style={{ "whiteSpace": "nowrap" }} scope="col" className="no-sort">Log</th>
                                    {isAdmin() ? <th scope="col">Action</th> : ""}
                                    
                                </tr>
                            </thead>
                            <tbody>
                            {(schedulerStatusList.loading || schedulerStatusList.data.length == 0) ? (<tr><td colSpan="200"><div className="text-center text-danger">No search results found</div></td></tr>) : 
                                (filteredTableData.map(data => (
                                    <tr key={data.id}>
                                    <td>{data.scheduler_name}</td>
                                    <td>{data.partner_code}</td>
                                    <td>{data.time}</td>
                                    <td style={{ "whiteSpace": "nowrap" }} >{formatDate(data.run_start_date_time)}</td>
                                    <td style={{ "whiteSpace": "nowrap" }}>{formatDate(data.run_end_date_time)}</td>
                                    <td style={{ color: getStatusColor(data.status),fontWeight: 'bold' }}>{data.status}</td>
                                    <td>{data.operation_type}</td>
                                    <td style={{ cursor: 'help' }} data-toggle="modal" onClick={(e) => enableLogListModel(customText(data.log, 10000))} data-target="#grouploglistingmodel">
                                        {customText(data.log, 10).split('\n').map((log, index) => (
                                            <div key={index}>
                                                {log.length < 10 ? log :
                                                    <span style={{ paddingLeft: "5px" }}>  {log}<Tooltip target=".toolTip" /> </span>
                                                 }
                                            </div>
                                        ))}

                                    </td>
                                    {isAdmin() ? <td>
                                        <div className="table__action" >
                                            {constants.SCHEDULARSTATUS_RETRY_STATUS.includes(data.status) ? <a href="javascript:void(0);" className="utils utils--outline" onClick={(e) => onPopupActions(data.monitor_id, data.scheduler_name, true)}>
                                                <span>
                                                    <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} />
                                                    <img className="toolTip" data-pr-tooltip="Retry" data-pr-position="bottom" src={iconRetry} alt="retry" />
                                                </span></a> : " "}
                                           

                                        </div>
                                    </td> : ""}
                                   
                                   
                                </tr>
                                ))
                            )}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 mb-8">
                    <div className={`modal fade modal--addnew ${isLogListingPopupEnable ? 'show' : ''}`} id="grouploglistingmodel" data-backdrop="static" data-keyboard="false" tabindex="-1"
                        aria-labelledby="staticBackdropLabel" aria-modal="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="container">
                                        <div className="table-block">
                                            <div className="table-block__head">
                                                <h2>Log List</h2>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <img src={close_icon} onClick={(e) => setLogListForModel([])} alt="Close" />
                                                </button>
                                            </div>
                                            <br />
                                            <div class="table-responsive grid">
                                                <table class="table">
                                                    <thead>
                                                        {isLogListingPopupEnable && logListForModel.length > 0 ? logListForModel.map((logModel) => (
                                                            <tr><th scope="col">{logModel}</th></tr>
                                                        )) : ''}
                                                    </thead>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Fragment>
    )
}

const mapDispatchToProps = dispatch => ({
    dispatchSchedulerStatusDataList: (onSuccess, onError) => dispatch(displaySchedulerStatusData(onSuccess, onError)),
    dispatchListPartnersAction: (onSuccess, onError) => dispatch(listPartners(onSuccess, onError)),
    dispatchRetryFileProcessing: (monitoringId, onSuccess, onError) =>
    dispatch(retryFileProcessing(monitoringId, onSuccess, onError))
   
});

export default  connect(null, mapDispatchToProps)(AddSchedulerRunStatus);