import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import iconInfo from '../assets/images/icon-info-round.png';
import { Tooltip } from 'primereact/tooltip';
import CustomDropdownWidget from './dropdown/CustomDropdownWidget';

import { listPartners, sessionErrorCheck, fileTransferSearch } from '../redux/actions/auth.action.creators';






const FileTransferStatusComponent = ({ dispatchListPartnersAction, dispatchFileTransferSearchAction }) => {

    const [failMsg, setFailMsg] = useState('');
    const [succMsg, setSuccMsg] = useState('');
    // const [searchKey, setSearchKey] = useState('');
    const [dropdownRefresher, setdropdownRefresher] = useState(true)
    const [filteredFileTransferList, setFilteredFileTransferList] = useState({ "loading": true, "data": [] });
    const [partnerList, setPartnerList] = useState([]);
    const [partnerConstrains,] = useState({ "fieldPlaceholder": "Partner", "isMandatory": false, "inputErrorMessage": "", "id":"FTS_partner_customDropdown" })
    const [consumerConstrains,] = useState({ "fieldPlaceholder": "Partner", "isMandatory": false, "inputErrorMessage": "","id":"FTS_consumer_customDropdown" })
    const [responsePartnerData, setResponsePartnerData] = useState("");
    const [responseConsumerData, setResponseConsumerData] = useState("");

    const [requestBody, setRequestBody] = useState({ "partnerCode": "", "consumerCode": "" });
    // const [sortDirection, setSortDirection] = useState({ "partnerCode": '', "consumerCode": '', "status": '', "fileName": '', "createdAt": '' });
    const [loggedin_Ptr, setLoggedin_Ptr] = useState('');
    const [tableData, setTableData] = useState([]);
    const [producer, setProducer] = useState(false)
    const [consumer, setConsumer] = useState(false)
    const userInfos = sessionErrorCheck() !== null ? sessionErrorCheck() : window.location = '/auth';
    const userInfo = userInfos;
    const [filteredTableData,setFilteredTableData] = useState({ "loading": true, "data": [] });


 

    useEffect(() => {
        console.log("responsePartnerData", responsePartnerData)
        if (responsePartnerData != '' && responsePartnerData != null) {
            
            setRequestBody({ "partnerCode": responsePartnerData.value, "consumerCode": requestBody.consumerCode });
        }
    }, [responsePartnerData])

    useEffect(() => {
        console.log("responseConsumerData", responseConsumerData)
        if (responseConsumerData != '' && responseConsumerData != null) {
            setRequestBody({ "partnerCode": requestBody.partnerCode, "consumerCode": responseConsumerData.value });
        }
    }, [responseConsumerData])



    useEffect(() => {
        if (!producer) {
            setResponsePartnerData("")
            setRequestBody({ "partnerCode": "", "consumerCode": requestBody.consumerCode })
        }
    }, [producer])

    useEffect(() => {
        if (!consumer) {
            setResponseConsumerData("")

            setRequestBody({ "partnerCode": requestBody.partnerCode, "consumerCode": "" })
        }
    }, [consumer])

   

    useEffect(() => {
        dispatchListPartnersAction(({ data, message }) => {
            if (data) {
                // if (userInfo.role == 'Partner') {
                //     console.log("YES");
                //     setResponsePartnerData({"value":userInfo.partnerId,"label":userInfo.partnerId});
                //     setPartnerList(data.filter(p => (p.partnerid == userInfo.partnerId)));
                // }  

                if (userInfo.role == 'Partner') {
                    let currentPartnerWithName = `${data.filter(p => (p.partnerid == userInfo.partnerId))[0].partnercode}  (${data.filter(p => (p.partnerid == userInfo.partnerId))[0].partnername})`;
                    let currentPartner = data.filter(p => (p.partnerid == userInfo.partnerId))[0].partnercode;
                    setPartnerList([{ "value": currentPartner, "label": currentPartnerWithName }]);


                    setLoggedin_Ptr(currentPartner);
                    // setRequestBody({ "partnerCode": currentPartner, "consumerCode": requestBody.consumerCode })
                }
                else {
                    let arr = [{ "value": "", "label": "Any" }];
                    data.forEach(p => {
                        arr.push({
                            "value": p.partnercode,
                            "label": `${p.partnercode} (${p.partnername})`
                        })
                    })
                    setPartnerList(arr);
                }
            }
        });
    }, [dispatchListPartnersAction])


    const onSearch = () => {
        // if (isFormValid()) {
            console.log("loggedinuser", loggedin_Ptr)
            dispatchFileTransferSearchAction(requestBody, ({ data, message }) => {
                if(data){
                    setTableData(data);
                    let tempList = [];
                    let index = 0;
                    data.forEach((val, i) => {
                        tempList.push(val);
                        index++;
                    });
                    setFilteredTableData({ "loading": false, "data": tempList });
                }
                
                   
            }, (message) => {
                console.log(message);
            });
        // }
    }

    

    // function sortFn(dir, sortArr, type) {
    //     let result = sortArr.sort((a, b) => {
    //         if (a[type] < b[type]) {
    //             return dir === "asc" ? -1 : 1;
    //         }
    //         if (a[type] > b[type]) {
    //             return dir === "asc" ? 1 : -1;
    //         }
    //         return 0;
    //     });
    //     return result;
    // }


    // function onSort(type) {
    //     let dir = "asc";
    //     if (sortDirection[type] === "asc") {
    //         dir = "desc";
    //     }

    //     if (type === 'partnerCode') {
    //         setFilteredFileTransferList({ "loading": false, "data": sortFn(dir, filteredFileTransferList.data, type) });
    //         setSortDirection({ "partnerCode": dir, "consumerCode": sortDirection.consumerCode, "status": sortDirection.status, "fileName": sortDirection.fileName, "createdAt": sortDirection.createdAt });
    //     } else if (type === 'consumerCode') {
    //         setFilteredFileTransferList({ "loading": false, "data": sortFn(dir, filteredFileTransferList.data, type) });
    //         setSortDirection({ "partnerCode": sortDirection.partnerCode, "consumerCode": dir, "status": sortDirection.status, "fileName": sortDirection.fileName, "createdAt": sortDirection.createdAt });
    //     } else if (type === 'status') {
    //         setFilteredFileTransferList({ "loading": false, "data": sortFn(dir, filteredFileTransferList.data, type) });
    //         setSortDirection({ "partnerCode": sortDirection.partnerCode, "consumerCode": sortDirection.consumerCode, "status": dir, "fileName": sortDirection.fileName, "createdAt": sortDirection.createdAt });
    //     } else if (type === 'fileName') {
    //         setFilteredFileTransferList({ "loading": false, "data": sortFn(dir, filteredFileTransferList.data, type) });
    //         setSortDirection({ "partnerCode": sortDirection.partnerCode, "consumerCode": sortDirection.consumerCode, "status": sortDirection.status, "fileName": dir, "createdAt": sortDirection.createdAt });
    //     } else if (type === 'createdAt') {
    //         setFilteredFileTransferList({ "loading": false, "data": sortFn(dir, filteredFileTransferList.data, type) });
    //         setSortDirection({ "partnerCode": sortDirection.partnerCode, "consumerCode": sortDirection.consumerCode, "status": sortDirection.status, "fileName": sortDirection.fileName, "createdAt": dir });
    //     }

    // }


    function resetForm() {
        setRequestBody({ "partnerCode": "", "consumerCode": "" })
        window.scrollTo(0, 0);
        setdropdownRefresher(dropdownRefresher ? false : true)
        setFilteredTableData({"loading": true, "data": []})
        setProducer(false);
        setConsumer(false);

        
    }
    return (
        <React.Fragment>
            <main>
                <div className="container">
                    <ul className="breadcrumb">
                        <li className="breadcrumb__item">
                            <a className="breadcrumb__link" href="/dashboard">Home</a>
                        </li>
                        <li className="breadcrumb__item">
                            <a className="breadcrumb__link" href="/fileTransferStatus">File Tansfer Status</a>
                        </li>
                    </ul>
                    {/* <div style={{ textAlign: 'center' }}> */}
                    <div class="title">
                        <h1>File Transfer Status
                            <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="This shows the details of the file last send by a partner or last send to partner based on below selection" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                        </h1>
                    </div>
                    {failMsg ?
                        <AlertFailure message={failMsg} /> : ''}
                    {succMsg ?
                        <AlertSuccess message={succMsg} /> : ''}
                </div>


                <div class="grey-block">
                    <div class="container">
                        <div class="form mb-5" >
                            <h2 class="mb-4">Search File</h2>
                            <div class="row">

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <div class="row">
                                            <div className="form-group">
                                                <input type="checkbox" id='producer' checked={producer ? true : false} onChange={((e) => { setProducer(!producer) })} />
                                                <label for="producer">Last File send by <span style={{ color: "red", fontWeight: "lighter" }}></span></label>
                                                <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Producer" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                            </div>

                                        </div>
                                        {producer ?
                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group" >
                                                    <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={partnerList} constrains={partnerConstrains} setResponseData={setResponsePartnerData}></CustomDropdownWidget>
                                                </div>
                                            </div>

                                            : ''}
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <div class="row">
                                            <div className="form-group">
                                                <input type="checkbox" id='consumer' checked={consumer ? true : false} onChange={((e) => { setConsumer(!consumer) })} />
                                                <label for="consumer">Last File sent to <span style={{ color: "red", fontWeight: "lighter" }}></span></label>
                                                <span style={{ paddingLeft: "5px" }}> <Tooltip target=".toolTip" mouseTrack mouseTrackLeft={10} /> <i> <img className="toolTip" data-pr-tooltip="Consumer" data-pr-position="right" src={iconInfo} alt="info" /> </i> </span>
                                            </div>
                                        </div>
                                        {consumer ?
                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <CustomDropdownWidget dropdownRefresher={dropdownRefresher} dropdownList={partnerList} constrains={consumerConstrains} setResponseData={setResponseConsumerData}></CustomDropdownWidget>
                                                </div>
                                            </div> : ''}

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="btn-wrap btn-wrap--grp mt-5 justify-content-end">
                            <button class="button button--outline" onClick={() => resetForm()}>Reset</button>
                            <button class="button" onClick={() => onSearch()}>Search</button>
                        </div>
                    </div>
                </div>


                <div class="container">
                    <div class="table-block">
                        <div class="table-block__head">
                            <h2>List of files</h2>
                            {/* <div class="txt-wrap">
                                <input type="text" class="txt" placeholder="Search..."
                                    value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                <i class="fa fa-search"></i>
                            </div> */}
                        </div>

                        <div className="table-responsive grid">
                            <table class="table">
                                <thead>
                                    <tr>

                                        <th scope="col"  >Producer Code </th>
                                        <th scope="col" >Consumer Code </th>
                                        <th scope="col" >Status </th>
                                        <th scope="col" >File Name </th>
                                        <th scope="col" >Send Date </th>

                                        {/* <th scope="col" onClick={() => sortBy('file_name')}>File Name <i className="fa fa-sort" aria-hidden="true"></i></th> */}
                                        {/* <th scope="col" onClick={() => onSort('updated_at')} style={{ cursor: "pointer" }}>Updated_at<i className="fa fa-sort" aria-hidden="true"></i></th> */}
                                        {/* <th scope="col" onClick={() => onSort('channel_id')} style={{ cursor: "pointer" }}>Channel_id<i className="fa fa-sort" aria-hidden="true"></i></th> */}

                                    </tr>
                                </thead>

                                <tbody>

                                    {/* {tableData ? (tableData.length == 0) ? (<tr><td colSpan="6"><div className="text-center text-danger"></div></td></tr>) : */}
                                    {filteredTableData.loading || (!filteredTableData.loading && filteredTableData.data.length == 0) ? (<tr><td colSpan="6"><div className="text-center text-danger">No search results found</div></td></tr>) :
                                        filteredTableData.data.map(data => (
                                        <tr>
                                            <td>{data.producercode}</td>
                                            <td>{data.consumercode}</td>
                                            <td>{data.status}</td>
                                            <td>{data.filename}</td>
                                            <td>{data.createdat.substring(0, 19).replace('T', " ")}</td>
                                        </tr>

                                        ))}

                                        
                                </tbody>
                            </table>
                        </div>

                        <div class='items' style={{ "display": 'inine-block' }} >
                            <div style={{ textAlign: 'center' }}>
                                <label className="label"> For more details use the
                                    <a className="link" href="/fileMonitoring"> "File Monitoring"</a> screen</label>
                            </div>
                        </div>

                    </div>
                </div>




            </main>
        </React.Fragment >

    )
}

const mapDispatchToProps = dispatch => ({
    dispatchListPartnersAction: (onSuccess, onError) =>
        dispatch(listPartners(onSuccess, onError)),
    dispatchFileTransferSearchAction: (data, onSuccess, onError) =>
        dispatch(fileTransferSearch(data, onSuccess, onError)),
});

export default connect(null, mapDispatchToProps)(FileTransferStatusComponent);

