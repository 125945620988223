import axios from 'axios';
var CryptoJS = require("crypto-js");
import { isJson } from '../redux/actions/auth.action.creators';
var secretKey = require(process.env.REACT_APP_CONFIG_URL);


import * as constants from './constants';
import { resetUserInfo,setToken } from './actions/auth.action.creators';

export const apiMiddleware = ({ dispatch, getState }) => next => action => {
    
    if (action.type !== constants.API) return next(action);

    dispatch({ type: constants.TOGGLE_LOADER });

    const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

    const AUTH_TOKEN = getState().user.token;

    const JWT_TOKEN = getState().user.jwtToken;

    const default_header = {};

    if (AUTH_TOKEN)
        default_header['Authorization'] = `Bearer ${JWT_TOKEN}`;
        default_header['Refreshtoken'] = `Bearer ${AUTH_TOKEN}`;

    const { url, method, headers, success, data, postProcessSuccess, postProcessError, onUploadProgress } = action.payload;

    if (headers){
        Object.entries(headers).map(([key, value]) => {
            default_header[key] = value;
        })
    }
    axios({
        method,
        url: BASE_URL + url,
        data: data ? data : null,
        headers: default_header,
        onUploadProgress: progress => {if(onUploadProgress){let val = Math.round(progress.loaded*100 / progress.total); onUploadProgress(val);}}
    }).then((response) => {
        dispatch({ type: constants.TOGGLE_LOADER });
        if(response.headers.refreshtoken && !getState().user.isSSOLogin) setToken(response.headers.refreshtoken);
        if (success) dispatch(success(response.data));
        if (postProcessSuccess) postProcessSuccess(response.data);
    }).catch(err => {
        dispatch({ type: constants.TOGGLE_LOADER });
        if (!err.response) dispatch(resetUserInfo());
        else {
            if (err.response && err.response.status === 403) dispatch(resetUserInfo());
            if (err.response && err.response.status === 302) dispatch(resetUserInfo());
            if (err.response.data.description) {
                if (postProcessError) postProcessError(err.response.data.description);
            }
        }
    })
};
