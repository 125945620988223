import 'primeflex/primeflex.css';
import { confirmDialog } from 'primereact/confirmdialog';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import delete_icon from '../assets/images/icon-delete.png';
import edit_icon from '../assets/images/icon-edit.png';
import unlock_icon from '../assets/images/unlock.png';
import info_icon from '../assets/images/icon-info-round.png';
import { userDataModel, rolesModel } from '../Bean/user';
import { fetchOneTimeParams, createUser, deleteUser, fetchAllUsers, fetchRolesList, updateUser, isAdmin, getGroupNames, unlockUser, userStatusCount, isPartnerAdmin } from '../redux/actions/auth.action.creators';
import AlertFailure from '../utils/alert-failure.component';
import AlertSuccess from '../utils/alert-success.component';
import ReactLoading from "react-loading";
import { default as ReactSelect, components } from "react-select";
import { Tooltip } from 'primereact/tooltip';
import * as constants from '../redux/constants';
import _ from "lodash";

const ManageUserComponent = ({ dispatchCreateUser, dispatchUnlockUser, dispatchfetchAllUsers, dispatchUpdateUsers, dispatchfetchRolesList, dispatchDeleteUser, history, dispatchListGroupsAction, dispatchUserStatusCount, dispatchOneTimeParamsAction }) => {


  const [userInputDatas, setUserInputData] = useState({ username: null, email: null, roles: null, groups: [], inactiveStatus: false, is_sso_user: false })
  const [visible, setVisible] = useState(false)
  const [errorToolTip_email, setErrorToolTipEmail] = useState("")
  const [errorToolTip_username, setErrorToolTipUsername] = useState("")
  const [errorToolTip_role, setErrorToolTipRole] = useState("")
  const [usersInfo, setUsersInfo] = useState({ status: false, info: userDataModel })
  const [usersInfoTempMemory, setUsersInfoTempMemory] = useState({ status: false, info: userDataModel })
  const [onSearchKeyword, setOnSearchKeyword] = useState('')
  const [editInfo, setEditInfo] = useState(null)
  const [userInfoRefresh, setUserInfoRefresh] = useState(true)
  const [rolesDatas, setRolesDatas] = useState({ status: false, info: rolesModel })
  const [gridHeight, setGridHeight] = useState(0)
  const [groupList, setGroupList] = useState({ "loading": true, "data": [] })
  const gridRef = useRef()
  const [statusCount, setStatusCount] = useState({})
  const [statusDisable, setStatusDisable] = useState(0)
  const [sortAsc, setSortAsc] = useState({ "username": false, "email": false, "one_time_value": false, "status": false });
  const [bannerMessage, setBannerMessage] = useState({message:"", category:false, isActive: false})

  // Read One Time Params
  useEffect(() => {
    dispatchOneTimeParamsAction({ "keyList": [constants.USER_DELETION_THRESHOLD] }, ({ data, message }) => {
      // setStatusList({ "data": data[constants.FILE_STATUS], "loading": false });
      setStatusDisable(data[constants.USER_DELETION_THRESHOLD][0].value);
    }, (message) => {
      console.log(message);
    });
  }, [dispatchOneTimeParamsAction])

  useEffect(() => {
    dispatchfetchAllUsers(({ data, message }) => {
      if (data) {
        setUsersInfo({ status: true, info: data });
        setUsersInfoTempMemory({ status: true, info: data })
      }
    }, (message) => {
      setUsersInfo({ status: true, info: message })
    });
  }, [userInfoRefresh])

  useEffect(() => {
    dispatchfetchRolesList(({ data, message }) => {
      if (data) { setRolesDatas({ status: true, info: data }) }
    }, (message) => {
      setRolesDatas({ status: true, info: message })
    });
  }, [])


  useEffect(() => {
    dispatchListGroupsAction(({ data, message }) => {
      if (data) {
        let tempList = [{}];
        data.forEach(val => {
          if (val && val.group_status === "A") {
            tempList.push({
              "value": val.group_code,
              "label": val.group_name
            });
          }
        });
        setGroupList({ "loading": false, "data": tempList });
      }
    },
      (message) => {
        console.log(message);
      }
    );
  }, [])

  useEffect(() => {
    try {
      if (usersInfo.status && usersInfoTempMemory.status) {
        var tempMemory = usersInfo.info.filter(a =>
          a.email.toLowerCase().includes(onSearchKeyword.toLowerCase())
          || a.username.toLowerCase().includes(onSearchKeyword.toLowerCase())
          || a.one_time_value.toLowerCase().includes(onSearchKeyword.toLowerCase())
          || a.status.toLowerCase().includes(onSearchKeyword.toLowerCase())
        );
        setUsersInfoTempMemory({ status: true, info: tempMemory })
      }
    } catch (err) {
    }
  }, [onSearchKeyword])

  useEffect(() => {
    dispatchUserStatusCount(({ data, message }) => {
      if (data) {
        setStatusCount(data)
      }
    }, (message) => { });
  }, [])

  const inActiveCheck = (stas) => {
    var status = false;
    if (stas === false) {
      status = true
    } else if (stas === true) {
      status = false
    }
    setUserInputData({ inactiveStatus: status, email: userInputDatas.email, roles: userInputDatas.roles, username: userInputDatas.username, groups: userInputDatas.groups, is_sso_user: userInputDatas.is_sso_user   });
  }

  const inSSOLoginCheck = (stas) => {
    var status = false;
    if(stas === false) {
      status = true
    }else if(stas === true) {
      status = false
    }
    setUserInputData({ is_sso_user:status, inactiveStatus: userInputDatas.inactiveStatus, email: userInputDatas.email, roles: userInputDatas.roles, username: userInputDatas.username, groups: userInputDatas.groups });
  }

  const onSubmit = (event) => {
    event.preventDefault();
    let tempGroups = []
    if (userInputDatas.groups.length > 0 && userInputDatas.roles === "OperationsUser") {
      userInputDatas.groups.forEach(g => {
        tempGroups.push(g.value)
      })
    }
    if ( editInfo == null &&  emailValidation() && usernameValidation() && roleFeildValidation() ) {
      dispatchCreateUser(userInputDatas.username, userInputDatas.email, userInputDatas.roles, tempGroups, userInputDatas.inactiveStatus,userInputDatas.is_sso_user, ({ data, message }) => {
        console.log(data);
        if (data) {
          window.scrollTo(0, 0)
          console.log(data);
          enablePopup(true, "Added Successfully!")
          setUserInfoRefresh(userInfoRefresh ? false : true)
          onReset();
        }
      }, (message) => {
        enablePopup(false, message)
      });
    }
    else if ( isNullOrEmpty(errorToolTip_role) && !isNullOrEmpty(userInputDatas.roles)  && editInfo != null) {
      dispatchUpdateUsers(userInputDatas.username, userInputDatas.email, userInputDatas.roles, tempGroups, userInputDatas.inactiveStatus,userInputDatas.is_sso_user, editInfo, ({ data, message }) => {
        if (data) {
          window.scrollTo(0, 0)
          enablePopup(true, "Update Successfully!");
          setUserInfoRefresh(userInfoRefresh ? false : true)
          onReset();
        }
      }, (message) => { enablePopup(false, message) });
    }
    
  }



  const enablePopup = (ctgry, msg) => {
    setBannerMessage({ message: msg, category: ctgry, isActive: true })
    // setUserInfoRefresh(userInfoRefresh ? false : true)
    setTimeout(() => {
      setBannerMessage({ message: "", category: ctgry, isActive: false })
    }, 4000)
  }


  const onReset = () => {
    setUserInputData({ username: null, email: null, roles: null, groups: [], inactiveStatus: false, is_sso_user: false })
    setEditInfo(null);
    document.getElementById("f1").disabled = false;
    document.getElementById("f2").disabled = false;
    var roleCheckBoxes = document.querySelectorAll('[id^="test"]');
    roleCheckBoxes.forEach(function (el) {
        el.checked = false;
    });
  }

  const roleFeildValidation = () => {
     
    if (isNullOrEmpty(userInputDatas.roles)) {
      setErrorToolTipRole("Select any role")
      return false;
    } else {
      setErrorToolTipRole("")
      return true;
    }
  }
  
  const emailValidation = () => {
    
    if (isNullOrEmpty(userInputDatas.email)) {
      setErrorToolTipEmail("Enter email")
      return false;
    } else if (!new RegExp(/^(\d{9}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4}))$/).test(userInputDatas.email)) {
      setErrorToolTipEmail("Invalid email")
      return false;
    } else if (isKeyValuePresent(usersInfo.info, "email", userInputDatas.email)) {
      setErrorToolTipEmail("Email address already exist")
      return false;
    } else if(!isNullOrEmpty(userInputDatas.email)) {
      setErrorToolTipEmail("")
      return true;
    }
     
  }

  const usernameValidation = () => {
    if (isNullOrEmpty(userInputDatas.username)) {
      setErrorToolTipUsername("Enter Username/ Staff Id")
      return false;
    } else if (!new RegExp(/^[A-Za-z0-9_-]*$/).test(userInputDatas.username)) {
      setErrorToolTipUsername("Inavlid Character ")
      return false;
    } else if (userInputDatas.username.length > 24) {
      setErrorToolTipUsername("Length should be between 1 - 24.")
      return false;
    } else if (isKeyValuePresent(usersInfo.info, "username", userInputDatas.username)) {
      setErrorToolTipUsername("Username/ Staff Id already exist.")
      return false;
    } else {
      setErrorToolTipUsername("")
      return true
    }
  }

  function isKeyValuePresent(array, key, value) {
    return array.some(obj => obj[key] === value);
}

  const isNullOrEmpty = (res) =>
  {
    if (res == null || res == "") { return true }
    else {return false}
  }


  const setEditValue = (id, username, email, roles, groups, inactiveStatus, is_sso_uservalue) => {
    let tempList = [];
    if (groups != [] || groups != "") {
      groups.forEach(groupCode => {
        tempList.push(groupList.data.filter(group => (group.value == groupCode))[0]);
      });
    }
    setUserInputData({ username: username, email: email, roles: roles, groups: tempList, inactiveStatus: inactiveStatus, is_sso_user: is_sso_uservalue })
    setEditInfo(id)
    document.getElementById("f1").disabled = true;
    document.getElementById("f2").disabled = true;
    var roleCheckBoxes = document.querySelectorAll('[id^="test"]');
    roleCheckBoxes.forEach(function (el) {
        el.checked = false;
    });

    if (roles === "Administrator") {
      document.getElementById("test1").checked = true;
    } else if (roles === "OperationsUser") {
      document.getElementById("test2").checked = true;
    } else if (roles === "PartnerAdmin") {
      document.getElementById("test3").checked = true;
    } else if (roles === "Custom") {
      document.getElementById("test4").checked = true;
    }
  }

  const deleteConfirm = (userId, name) => {

    confirmDialog({
      message: 'Do you want to delete ' + name + '?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept() {
        dispatchDeleteUser(userId, ({ data, message }) => {
          if (data) {
            enablePopup(true, "Deleted Successfully!");
            setUserInfoRefresh(userInfoRefresh ? false : true)
          }
        }, (message) => { enablePopup(false, message) });
        setVisible(false)
        setEditInfo(null);
      },
      reject() {

      },
      acceptLabel: "Confirm",
      rejectLabel: "Cancel"
    });
  };

  const unlockConfirm = (userId, name) => {
    confirmDialog({
      message: 'Do you want to unlock ' + name + '?',
      header: 'Unlock Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept() {
        dispatchUnlockUser(userId, ({ data, message }) => {
          if (data) {
            enablePopup(true, "Unlock Successfully!");
            setUserInfoRefresh(userInfoRefresh ? false : true)
          }
        }, (message) => { enablePopup(false, message) });
        setVisible(false)
        setEditInfo(null);
      },
      reject() {

      },
      acceptLabel: "Confirm",
      rejectLabel: "Cancel"
    });
  };

  const onScrolling = () => {
    if (onSearchKeyword === '') {
      if (gridRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = gridRef.current;
        if (Math.round(scrollTop) + clientHeight === scrollHeight) {
          if (gridHeight + 10 >= usersInfo.info.length) {
            setGridHeight(usersInfo.info.length)
          } else {
            setGridHeight(gridHeight + 10)
          }
        }
      }
    }
  }

  const onScrollingDiv = () => {
    if (gridRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = gridRef.current;
      if (Math.round(scrollTop) + clientHeight === scrollHeight) {
        if (gridHeight + 10 >= usersInfo.info.length) {
          setGridHeight(usersInfo.info.length)
        } else {
          setGridHeight(gridHeight + 10)
        }
      }
    }
  }

  const groupSelect = (e) => {
    setUserInputData({ username: userInputDatas.username, email: userInputDatas.email, roles: userInputDatas.roles, groups: e, inactiveStatus: userInputDatas.inactiveStatus, is_sso_user:userInputDatas.is_sso_user })
  }

  function compareBy(key, dir) {
    return function (a, b) {
      if (a[key] < b[key]) return dir == true ? -1 : 1;
      if (a[key] > b[key]) return dir == true ? 1 : -1;
      return 0;
    };
  }

  function sortBy(key) {
    let arrayCopy = _.cloneDeep(usersInfoTempMemory);
    let dirCopy = _.cloneDeep(sortAsc);
    dirCopy[key] = !dirCopy[key];
    setSortAsc(dirCopy);
    arrayCopy.info.sort(compareBy(key, dirCopy[key]));
    setUsersInfoTempMemory(arrayCopy);
  }


  return (

    <React.Fragment>
      <main>
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb__item">
              <Link to="/user"><a className="breadcrumb__link" href="#">Home</a></Link>
            </li>
            <li className="breadcrumb__item">
              <Link to="/user"><a className="breadcrumb__link" href="#">Manage User</a></Link>
            </li>
          </ul>
          <div className="title">
            <h1>Manage User</h1>
          </div>
          <div >
            {
              bannerMessage.isActive ?
                <div>
                  {bannerMessage.category ? <AlertSuccess message={bannerMessage.message} /> : <AlertFailure message={bannerMessage.message} />}
                </div>
                :
                ''
            }
          </div>
        </div>

        <div className="grey-block">
          <div className="container">
            {/* <!--form starts here--> */}
            <form noValidate >
              <div className="form mb-1 ">
                <div className="row">
                  <div className="col-lg-6 col-md-6 input-wrap txt-wrap">
                    <div className="form-group">
                      <label htmlFor="f1" className="input-label"> Username/ Staff Id</label>
                      <input value={userInputDatas.username == null ? "" : userInputDatas.username}
                        className={`txt ${userInputDatas.username == "" ? 'is-invalid' : ''}`} type="text"
                        onChange={(e) => setUserInputData({ username: e.target.value, email: userInputDatas.email, roles: userInputDatas.roles, groups: userInputDatas.groups, inactiveStatus: userInputDatas.inactiveStatus, is_sso_user:userInputDatas.is_sso_user  })}
                        placeholder="Enter username/staff id" id="f1" />
                      {errorToolTip_username != "" ? (<div className="error-message">{errorToolTip_username}</div>) : ''}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 input-wrap txt-wrap">
                    <div className="form-group ">
                      <label htmlFor="f2" className="d-block input-label">Email
                      <span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTips" mouseTrack mouseTrackLeft={10} />
                            <i>
                              <img className="toolTips" data-pr-tooltip={"Email should be the corporate email id "} data-pr-position="bottom" src={info_icon} alt="info" />
                            </i>
                        </span>
                      </label>
                      <input value={userInputDatas.email == null ? "" : userInputDatas.email} className={`txt ${userInputDatas.email === "" ? 'is-invalid' : ''}`} type="text"
                        onChange={(e) => setUserInputData({ email: e.target.value, roles: userInputDatas.roles, username: userInputDatas.username, groups: userInputDatas.groups, inactiveStatus: userInputDatas.inactiveStatus,is_sso_user:userInputDatas.is_sso_user  })}
                        placeholder="Enter email" id="f2" />
                      {errorToolTip_email != "" ? (<div className="error-message">{errorToolTip_email}</div>) : ''}
                    </div>
                  </div>
                  
                  {(userInputDatas.roles === "OperationsUser" && !groupList.loading) ? (
                    <div className="col-lg-6 col-md-6 input-wrap txt-wrap">
                      <div className="form-group ">
                        <label htmlFor="f5" className="d-block input-label">Select Group
                          <span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTip" className="toolTipGroup" autoHide={false} mouseTrack mouseTrackLeft={10} />
                            <i>
                              <img className="toolTip" style={{ cursor: 'pointer' }} data-pr-tooltip={"Selecting group(s) will restrict the access of data and files specific to partners of the selected group(s)"} data-pr-position="right" src={info_icon} alt="info" />
                            </i>
                          </span>
                        </label>
                        <ReactSelect
                          options={groupList.data}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          onChange={(e) => groupSelect(e)}
                          allowSelectAll={true}
                          value={userInputDatas.groups}
                        />
                      </div>
                    </div>
                  ) : ''}

                  <div className="col-lg-6 col-md-6 input-wrap txt-wrap">
                    <div className="form-group" style={userInputDatas.roles === "OperationsUser" ? { paddingTop: '28px' } : { paddingTop: '0px' }}>
                      <label htmlFor="f5" className="d-block input-label"></label>
                      <label className="switch" style={{ marginTop: '12px', width: '42%' }}>
                        <input onChange={(e) => inActiveCheck(userInputDatas.inactiveStatus)} type="checkbox" id="inactiveUser" checked={userInputDatas.inactiveStatus} />
                        <span htmlFor="inactiveUser" className="slider round" style={{ width: '51px' }}></span>
                        <label htmlFor="inactiveUser" style={{ paddingLeft: '59px', cursor: 'pointer' }}>Delete User if Inactive
                          <span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTips" mouseTrack mouseTrackLeft={10} />
                            <i>
                              <img className="toolTips" data-pr-tooltip={"The User Account will be deleted if the portal is not accessed for " + statusDisable + " days"} data-pr-position="bottom" src={info_icon} alt="info" />
                            </i>
                          </span>
                        </label>
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 input-wrap txt-wrap">
                    <div className="form-group" style={userInputDatas.roles === "OperationsUser" ? { paddingTop: '28px' } : { paddingTop: '0px' }}>
                      <label htmlFor="f5" className="d-block input-label"></label>
                      <label className="switch" style={{ marginTop: '12px', width: '42%' }}>
                        <input onChange={(e) => inSSOLoginCheck(userInputDatas.is_sso_user)} type="checkbox" id="is_sso_user" checked={userInputDatas.is_sso_user} />
                        <span htmlFor="is_sso_user" className="slider round" style={{ width: '51px' }}></span>
                        <label htmlFor="is_sso_user" style={{ paddingLeft: '59px', cursor: 'pointer' }}>Single Sign-On
                          <span style={{ paddingLeft: "5px" }}><Tooltip target=".toolTips" mouseTrack mouseTrackLeft={10} />
                            <i>
                              <img className="toolTips" data-pr-tooltip={"Enable to grant SSO login access for the User."} data-pr-position="bottom" src={info_icon} alt="info" />
                            </i>
                          </span>
                        </label>
                      </label>
                    </div>
                  </div>

                </div>
              </div>

              {/* <!--form ends here--> */}
              <h2 className="mb-4">Available Roles</h2>

              <div className="form-row">
                <div className="col-md-4" style={{ height: '247px' }}>
                  <div className={userInputDatas.roles === "Administrator" ? "widget widget--access is-active" : "widget widget--access"}>
                    <div className="head">Admin</div>
                    <div className="widget--access__list role-scroll" style={{ display: 'flow', height: '347px' }} onScroll={() => onScrollingDiv()}>
                      {rolesDatas.status ?
                        (rolesDatas.info.Administrator.map((result) => (
                          <div ><li style={{ fontSize: '0.72857rem' }}>{result}</li></div>
                        ))) : ""
                      }
                    </div>
                    <div className="radio-wrap">
                      <input type="radio" id="test1" name="radio-group" />
                      <label onClick={(e) => setUserInputData({ username: userInputDatas.username, email: userInputDatas.email, roles: "Administrator", groups: userInputDatas.groups, inactiveStatus: userInputDatas.inactiveStatus,is_sso_user:userInputDatas.is_sso_user})} htmlFor="test1">Select</label>
                    </div>
                  </div>
                </div>

                <div className="col-md-4" style={{ height: '247px' }}>
                  <div className={userInputDatas.roles === "PartnerAdmin" ? "widget widget--access is-active" : "widget widget--access"}>
                    <div className="head">Partner Admin</div>
                    <div className="widget--access__list role-scroll" style={{ display: 'flow', height: '347px' }} onScroll={() => onScrollingDiv()}>
                      {rolesDatas.status ?
                        (rolesDatas.info.PartnerAdmin.map((result) => (
                          <div ><li style={{ fontSize: '0.72857rem' }}>{result}</li></div>
                        ))) : ""
                      }
                    </div>
                    <div className="radio-wrap">
                      <input type="radio" id="test3" name="radio-group" />
                      <label onClick={(e) => setUserInputData({ username: userInputDatas.username, email: userInputDatas.email, roles: "PartnerAdmin", groups: userInputDatas.groups, inactiveStatus: userInputDatas.inactiveStatus,is_sso_user:userInputDatas.is_sso_user })} htmlFor="test3">Select</label>
                    </div>
                  </div>
                </div>

                <div className="col-md-4" style={{ height: '247px' }}>
                  <div className={userInputDatas.roles === "OperationsUser" ? "widget widget--access is-active" : "widget widget--access"}>
                    <div className="head">Operation User</div>
                    <div className="widget--access__list role-scroll" style={{ display: 'flow', height: '347px' }} onScroll={() => onScrollingDiv()}>
                      {rolesDatas.status ?
                        (rolesDatas.info.OperationUsers.map((result) => (
                          <div ><li style={{ fontSize: '0.72857rem' }}>{result}</li></div>
                        ))) : ""
                      }
                    </div>
                    <div className="radio-wrap">
                      <input type="radio" id="test2" name="radio-group" />
                      <label onClick={(e) => setUserInputData({ username: userInputDatas.username, email: userInputDatas.email, roles: "OperationsUser", groups: userInputDatas.groups, inactiveStatus: userInputDatas.inactiveStatus,is_sso_user:userInputDatas.is_sso_user })} htmlFor="test2">Select</label>
                    </div>
                  </div>
                </div>

               {/* <div className="col-md-3" style={{ height: '247px' }}>
                  <div className={userInputDatas.roles === "Custom" ? "widget widget--access is-active" : "widget widget--access"}>
                    <div className="head">Custom</div>
                    <div className="widget--access__list role-scroll" style={{ display: 'flow', height: '347px' }} onScroll={() => onScrollingDiv()}>
                      {rolesDatas.status ?
                        (rolesDatas.info.Custom.map((result) => (
                          <div ><li style={{ fontSize: '0.72857rem' }}>{result}</li></div>
                        ))) : ""
                      }
                    </div>
                    <div className="radio-wrap">
                      <input type="radio" id="test4" name="radio-group" />
                      <label onClick={(e) => setUserInputData({ username: userInputDatas.username, email: userInputDatas.email, roles: "Custom", groups: userInputDatas.groups, inactiveStatus: userInputDatas.inactiveStatus,is_sso_user:userInputDatas.is_sso_user })} htmlFor="test4">Select</label>
                    </div>
                  </div>
                </div> */}
              </div>
              <br/>
              <div className="input-wrap txt-wrap"> {errorToolTip_role != "" ? (<div className="error-message">{errorToolTip_role}</div>) : ('')} </div>
              <br/>

              <div className="btn-wrap btn-wrap--grp mt-5" style={{ justifyContent: "space-between" }}>
                <div>
                  {isAdmin() || isPartnerAdmin() ?<a style={{ textDecoration: "none" }} href="/UserLockAndUnlock">
                    <button type='button' className="button">Partner Users</button>&nbsp;<img title='Use this feature to Lock or UnLock a partner user.' src={info_icon} alt="info" />
                  </a>:''}
                </div>
                <div>
                  <button onClick={(e) => onReset()} type="reset" className="button button--outline">Cancel</button>
                  <button type="button" onClick={(e) => onSubmit(e)} className="button">{editInfo != null ? "Update" : "Save"}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <deleteConfirm/>
        <div className="container">
          <div className="table-block">
            <div className="table-block__head">
              <h2>List of Users
                <span style={{ "fontWeight": "bold", "fontSize": "13px", "color": "#666" }} >
                  &nbsp; [ Total: {statusCount.active_count + statusCount.inactive_count ? statusCount.active_count + statusCount.inactive_count : 0} &nbsp; Active: {statusCount.active_count ? statusCount.active_count : 0} &nbsp; Inactive: {statusCount.inactive_count ? statusCount.inactive_count : 0} &nbsp; Locked: {statusCount.locked_count ? statusCount.locked_count : 0} ]
                </span>
              </h2>
              <div className="txt-wrap">
                <input type="text" onChange={(event) => setOnSearchKeyword(event.target.value)} className="txt" placeholder="Search..." />
                <i className="fa fa-search"></i>
              </div>
            </div>
            <div className="grid" onScroll={() => onScrolling()}>
              {usersInfoTempMemory.status ?
                (<table className="table"  >
                  <thead>
                    <tr>
                      <th scope="col" onClick={() => sortBy('username')}>Username <i className="fa fa-sort" aria-hidden="true"></i></th>
                      <th scope="col" onClick={() => sortBy('email')}>Email <i className="fa fa-sort" aria-hidden="true"></i></th>
                      <th scope="col" onClick={() => sortBy('one_time_value')}>Role <i className="fa fa-sort" aria-hidden="true"></i></th>
                      <th onClick={() => sortBy('status')} title="ACTIVE      -Account activated &#013;INACTIVE  -Account not activated yet &#013;LOCK  -Account is Locked " scope="col">Status <img src={info_icon} alt="info" /> <i className="fa fa-sort" aria-hidden="true"></i></th>
                      <th onClick={() => sortBy('is_sso_user')} title="ACTIVE      -SSO Login Active &#013;INACTIVE  -SSO Login InActive " scope="col">SSO User <img src={info_icon} alt="info" /><i className="fa fa-sort" aria-hidden="true"></i></th>
                      {isAdmin() ? <th scope="col">Action</th> : ""}
                    </tr>
                  </thead>
                  <tbody>
                    {usersInfoTempMemory.info.map((result) => (

                      <tr>
                        <td>{result.username}</td>
                        <td>
                          {result.email}
                        </td>
                        <td>
                          {result.one_time_value}
                        </td>
                        <td>
                          {result.status === 'INACTV' ?
                            <span className="danger"> INACTIVE</span>
                            :
                            result.status === 'LOCK' ? <span className="success" style={{ color: 'orange' }}> LOCKED</span> : <span className="success">ACTIVE</span>
                          }
                        </td>
                        <td>
                          {result.is_sso_user?
                            <span className="success">ACTIVE</span>
                            :
                            <span className="danger">INACTIVE</span>
                          }
                        </td>
                        {isAdmin() ? <td>
                          <div className="table__action">
                            <a href="#" onClick={(e) => setEditValue(result.id, result.username, result.email, result.one_time_value, result.groups, result.delete_if_inactive, result.is_sso_user)} className="utils utils--outline"> <img src={edit_icon} alt="edit" /></a>
                            {result.status === 'LOCK' ? <a href="#" onClick={(e) => unlockConfirm(result.id, result.username)} className="utils utils--outline ml-2"> <img src={unlock_icon} alt="delete" /></a> : ''}
                            <a href="#" onClick={(e) => deleteConfirm(result.id, result.username)} className="utils utils--outline ml-2"> <img src={delete_icon} alt="delete" /></a>
                          </div>
                        </td> : ""}
                      </tr>
                    ))}
                  </tbody>
                </table>)
                :
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><ReactLoading type="balls" color="#E0EEEE" height={50} width={75} /></div>
              }
            </div>

          </div>
        </div>
        {/* <!--table ends here--> */}
      </main>
    </React.Fragment>
  );
}
const mapDispatchToProps = dispatch => ({
  dispatchCreateUser: (username, email, roles, groups, deleteInactiveStatus,isSsoUser, onSuccess, onError) =>
    dispatch(createUser({ username, email, roles, groups, deleteInactiveStatus,isSsoUser }, onSuccess, onError)),

  dispatchfetchAllUsers: (onSuccess, onError) =>
    dispatch(fetchAllUsers(onSuccess, onError)),

  dispatchUpdateUsers: (username, email, roles, groups, deleteInactiveStatus,isSsoUser, id, onSuccess, onError) =>
    dispatch(updateUser({ username, email, roles, groups, deleteInactiveStatus,isSsoUser }, { userId: id }, onSuccess, onError)),

  dispatchfetchRolesList: (onSuccess, onError) =>
    dispatch(fetchRolesList(onSuccess, onError)),

  dispatchDeleteUser: (id, onSuccess, onError) =>
    dispatch(deleteUser({ userId: id }, onSuccess, onError)),

  dispatchUnlockUser: (id, onSuccess, onError) =>
    dispatch(unlockUser({ userId: id }, onSuccess, onError)),

  dispatchListGroupsAction: (onSuccess, onError) =>
    dispatch(getGroupNames(onSuccess, onError)),

  dispatchUserStatusCount: (onSuccess, onError) =>
    dispatch(userStatusCount(onSuccess, onError)),

  dispatchOneTimeParamsAction: (data, onSuccess, onError) =>
    dispatch(fetchOneTimeParams(data, onSuccess, onError)),
})
export default connect(null, mapDispatchToProps)(ManageUserComponent);

