

export const SortObjectArray = (keyname, isIntegerType, isSortByDescOrder, dataArray) => { 
   // isIntegerType : value is integer or not
   //dataArray : eg: [{name:"nv",prefix:"v"}]
   //keyname : keynae eg: name, prefix
   if (isIntegerType) {
      dataArray.sort(function (a, b) {
         if (isSortByDescOrder) return a[keyname] - b[keyname]
         else return b[keyname] - a[keyname]
      })
   } else {
      dataArray.sort(function (a, b) { 
         const first = a[keyname].toUpperCase();
         const second = b[keyname].toUpperCase();
         if (isSortByDescOrder) {
            if (first < second) return -1;
            if (first > second) return 1;
            else return 1;
         } else {
            if (first < second) return 1;
            if (first > second) return -1;
            else return 1;
         }
      })
   } 
   return dataArray
}