import React from 'react';
import ManagePartnerComponent from '../components/manage_partner.component';


const ManagePartnerPage = () => (
        

   <ManagePartnerComponent/>
);

export default ManagePartnerPage;
