
/*

Example :

const arr = [
    {type:"aa", name:"11"},
    {type:"bb", name:"22"},
    {type:"aa", name:"34"}, 
    {type:"bb", name:"56"}
];
    
const grouped = groupBy(arr, array => array.type);
    
console.log(grouped.get("aa")); 
console.log(grouped.get("bb"));


*/
export const GroupArrayObject = (list, keyGetter) => { 

    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
