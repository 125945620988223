import React from 'react';

import ManageTemplateComponent  from "../components/template/manage.template.component";

const TemplatePage = () => (
        

   <ManageTemplateComponent/> 
);

export default TemplatePage;